<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
	<div class="col-md-4 col-sm-6 mb-3" *ngIf="identity.permisos && identity.permisos.rptCoberturaDia">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/cobertura.png"
											ancho="180px"
											largo="170px"
											title="Cobertura operacional por dia" 
											subTitle="En este reporte se encuentran las capturas de los indicadores operativos." 
											url="operaciones/cobertura/dia"></app-reporte-menu>
	</div>
	<div class="col-md-4 col-sm-6 mb-3" *ngIf="identity.permisos && identity.permisos.rptCobertura">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/cobertura.png"
											ancho="180px"
											largo="170px"  
											title="Cobertura operacional" 
											subTitle="En este reporte se muestra la cobertura operacional." 
											url="operaciones/cobertura"></app-reporte-menu>
	</div>
	<div class="col-md-4 col-sm-6 mb-3" *ngIf="identity.permisos && identity.permisos.rptServicioCop">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/cobertura.png"
											ancho="180px"
											largo="170px"  
											title="Cobertura operacional por servicio" 
											subTitle="En este reporte se muestra la cobertura operacional divididos por servicios." 
											url="operaciones/cobertura/servicio"></app-reporte-menu>
	</div>
	<div class="col-md-4 col-sm-6 mb-3" *ngIf="identity.permisos && identity.permisos.rptServicioInicia">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/servicios.png"
											ancho="180px"
											largo="170px"  
											title="Servicios iniciados en" 
											subTitle="En este reporte se gestiona los servicios que fueron iniciados en un rango de fechas." 
											url="operaciones/servicios/iniciado"></app-reporte-menu>
	</div>
	<div class="col-md-4 col-sm-6 mb-3" *ngIf="identity.permisos && identity.permisos.rptServicioTermina">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/servicios.png"
											ancho="180px"
											largo="170px"  
											title="Servicios finalizados en" 
											subTitle="En este reporte se gestiona los servicios que fueron finalizados en un rango de fechas." 
											url="operaciones/servicios/finalizado"></app-reporte-menu>
	</div>
	<div class="col-md-4 col-sm-6 mb-3" *ngIf="identity.permisos && identity.permisos.rptBajaVacantes">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/indicadores.png"
											ancho="180px"
											largo="170px"  
											title="Bajas y vacantes" 
											subTitle="En este reporte se gestiona las bajas y vacantes de los indicadores operativos." 
											url="operaciones/bajas/vacantes"></app-reporte-menu>
	</div>
</div>
