import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity, Permisos, UserType, Suscripciones } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import { Location } from '@angular/common';
import { MESSAGES } from 'src/app/models/messages';
import Swal from 'sweetalert2';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { Pagination } from 'src/app/models/pagination';

@Component({
  selector: 'app-usuarios-config',
  templateUrl: './usuarios-config.component.html',
  styleUrls: ['./usuarios-config.component.sass']
})
export class UsuariosConfigComponent implements OnInit, OnDestroy {
  public identity: Identity;
  public title: string;
  private subscribeAuth: Subscription = new Subscription();
  private subscribeTipos: Subscription = new Subscription();
  public tipos: UserType[] = [];
  public loading = false;
  public url: string;
  public paginacion = new Pagination(null);
  public filterActive = false;
  public orderType = [  
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+nombre', text: 'Nombre'},
  { id: '-nombre', text: 'Nombre desc'}];
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private locate: Location,
    public usuarioService: UsuariosService,
  ) { 
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;
    this.tipos = [];
    this.paginacion.sortType = '+nombre';
  }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.subscribeTipos = this.store.select('catalogos').subscribe(
      (state) => {
        this.tipos = state.tipos;
      }
    );
    this.title = 'Configuración de usuario';
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeTipos.unsubscribe();
  }

  inicializa() {
    this.store.dispatch(new usuariosActions.LoadTypeUsers());
    setTimeout( () => {
      if (this.identity.tipo !== 1) {
        this.locate.back();
      }
    }, 5);
  }

  
  statusUsuario(user: UserType) {
    Swal.fire({
      title: '¿Deseas actualizar el tipo de usuario?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
       showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.usuarioService.actualizaEstatusUsuario(user).subscribe(
            (respuesta) => {
              if (respuesta.respond) {
                this.store.dispatch(new usuariosActions.UpdateTypeUsersStatusSuccess(user));
                resolve(respuesta.respond);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.UpdateTypeUsersStatusFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result: any) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Tipo de usuario actualizado.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }
  









  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchUser").focus();
  }

  closeSearch() {
    this.paginacion.isActive = false;
    this.paginacion.textSearch = '';
  }








}
