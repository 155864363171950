import { Action } from '@ngrx/store';
import { User } from 'src/app/models/user';
import { SeriesBar, FunnelGraphColors } from 'src/app/models/graph';
// import { Serie } from 'src/app/models/dashboard-resumen';

export const CARGAR_DASHBOARD_USUARIOS_CONECTADOS = '[Dashboard] Cargar dashboard usuarios conectados';
export const CARGAR_DASHBOARD_USUARIOS_CONECTADOS_FAIL = '[Dashboard] Cargar dashboard usuarios conectados FAIL';
export const CARGAR_DASHBOARD_USUARIOS_CONECTADOS_SUCCESS = '[Dashboard] Cargar dashboard usuarios conectados SUCCESS';
export const CARGAR_DASHBOARD_LINE_CONTACTOS = '[Dashboard] Cargar dashboard line contactos';
export const CARGAR_DASHBOARD_LINE_CONTACTOS_FAIL = '[Dashboard] Cargar dashboard line contactos FAIL';
export const CARGAR_DASHBOARD_LINE_CONTACTOS_SUCCESS = '[Dashboard] Cargar dashboard line contactos SUCCESS';
export const CARGAR_DASHBOARD_LINE_EMPRESAS = '[Dashboard] Cargar dashboard line empresas';
export const CARGAR_DASHBOARD_LINE_EMPRESAS_FAIL = '[Dashboard] Cargar dashboard line empresas FAIL';
export const CARGAR_DASHBOARD_LINE_EMPRESAS_SUCCESS = '[Dashboard] Cargar dashboard line empresas SUCCESS';
export const CARGAR_DASHBOARD_LINE_OPORTUNIDADES = '[Dashboard] Cargar dashboard line oportunidades';
export const CARGAR_DASHBOARD_LINE_OPORTUNIDADES_FAIL = '[Dashboard] Cargar dashboard line oportunidades FAIL';
export const CARGAR_DASHBOARD_LINE_OPORTUNIDADES_SUCCESS = '[Dashboard] Cargar dashboard line oportunidades SUCCESS';

export const CARGAR_DASHBOARD_CANTIDAD_ACTIVIDADES = '[Dashboard] Cargar dashboard cantidad actividades';
export const CARGAR_DASHBOARD_CANTIDAD_ACTIVIDADES_FAIL = '[Dashboard] Cargar dashboard cantidad actividades FAIL';
export const CARGAR_DASHBOARD_CANTIDAD_ACTIVIDADES_SUCCESS = '[Dashboard] Cargar dashboard cantidad actividades SUCCESS';


export const CARGAR_DASHBOARD_FUNNEL_OPORTUNIDADES = '[KpisVendedores] Cargar dashboard funnel oportunidades';
export const CARGAR_DASHBOARD_FUNNEL_OPORTUNIDADES_FAIL = '[KpisVendedores] Cargar dashboard funnel oportunidades FAIL';
export const CARGAR_DASHBOARD_FUNNEL_OPORTUNIDADES_SUCCESS = '[KpisVendedores] Cargar dashboard funnel oportunidades SUCCESS';



export class CargarDashboardUsuariosConectados implements Action {
  readonly type = CARGAR_DASHBOARD_USUARIOS_CONECTADOS;
}

export class CargarDashboardUsuariosConectadosFail implements Action {
  readonly type = CARGAR_DASHBOARD_USUARIOS_CONECTADOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardUsuariosConectadosSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_USUARIOS_CONECTADOS_SUCCESS;

  constructor( public usuarios: User[]) {}
}

export class CargarDashboardLineContactos implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_CONTACTOS;
}

export class CargarDashboardLineContactosFail implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_CONTACTOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardLineContactosSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_CONTACTOS_SUCCESS;

  constructor( public labels: string[], public series: SeriesBar[], public hoy: number) {}
}

export class CargarDashboardLineEmpresas implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_EMPRESAS;
}

export class CargarDashboardLineEmpresasFail implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_EMPRESAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardLineEmpresasSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_EMPRESAS_SUCCESS;

  constructor( public labels: string[], public series: SeriesBar[], public hoy: number) {}
}

export class CargarDashboardLineOportunidades implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_OPORTUNIDADES;
}

export class CargarDashboardLineOportunidadesFail implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardLineOportunidadesSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_OPORTUNIDADES_SUCCESS;

  constructor( public labels: string[], public series: SeriesBar[], public hoy: number) {}
}


export class CargarDashboardCantidadActividades implements Action {
  readonly type = CARGAR_DASHBOARD_CANTIDAD_ACTIVIDADES;

  constructor( public dia: string ) {}
}

export class CargarDashboardCantidadActividadesFail implements Action {
  readonly type = CARGAR_DASHBOARD_CANTIDAD_ACTIVIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardCantidadActividadesSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_CANTIDAD_ACTIVIDADES_SUCCESS;

  constructor( public cantidad: number, public hoy: number) {}
}

export class CargarDashboardFunnelOportunidades implements Action {
  readonly type = CARGAR_DASHBOARD_FUNNEL_OPORTUNIDADES;
}

export class CargarDashboardFunnelOportunidadesFail implements Action {
  readonly type = CARGAR_DASHBOARD_FUNNEL_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardFunnelOportunidadesSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_FUNNEL_OPORTUNIDADES_SUCCESS;

  constructor( public datas: FunnelGraphColors[]) {}
}





export type dashboardAcciones =   CargarDashboardUsuariosConectados
                                | CargarDashboardUsuariosConectadosFail
                                | CargarDashboardUsuariosConectadosSuccess
                                | CargarDashboardLineContactos
                                | CargarDashboardLineContactosFail
                                | CargarDashboardLineContactosSuccess
                                | CargarDashboardLineEmpresas
                                | CargarDashboardLineEmpresasFail
                                | CargarDashboardLineEmpresasSuccess
                                | CargarDashboardLineOportunidades
                                | CargarDashboardLineOportunidadesFail
                                | CargarDashboardLineOportunidadesSuccess
                                | CargarDashboardCantidadActividades
                                | CargarDashboardCantidadActividadesFail
                                | CargarDashboardCantidadActividadesSuccess
                                | CargarDashboardFunnelOportunidades
                                | CargarDashboardFunnelOportunidadesFail
                                | CargarDashboardFunnelOportunidadesSuccess;
