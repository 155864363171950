<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <div class="col-sm-12 col-md-6 col-xl-6 pull-right">
    <div class="card-shadow-primary card-border mb-3 profile-responsive card">
      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner bg-alternate">
          <div class="menu-header opacity-2 dd-header-bg-3"></div>
          <div class="menu-header-content btn-pane-right">
            <div class="avatar-icon-wrapper mr-3 avatar-icon-xl btn-hover-shine">
              <div class="avatar-icon rounded">
                <img [src]="url + '/usuarios/perfiles/' + identity.id + '/imagenes'" alt="Imagen de perfil">
              </div>
            </div>
            <div>
              <h5 class="menu-header-title">{{identity.nombre + ' ' + identity.apellidos}}</h5>
              <!-- <h6 class="menu-header-subtitle">{{identity.desTipo}}</h6> -->
            </div>
          </div>
        </div>
      </div>
      <form (ngSubmit)="onSubmit( f.value )" #f="ngForm">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="widget-content pt-2 pl-0 pb-2 pr-0">
              <div class="row">
                <div class="col-md-12">
                  <fieldset class="form-group" id="__BVID__132">
                    <div tabindex="-1" role="group">
                      <label for="nombrePerfil">
                        <span class="text-danger">*</span>
                        Nombre
                      </label>
                      <input id="nombrePerfil" name="nombrePerfil" #apellidoPaternoPerfil="ngModel" type="text" placeholder="Nombres..." class="form-control" [(ngModel)]="usuario.nombre" required>
                    </div>
                  </fieldset>
                </div>
                <div class="col-md-6">
                  <fieldset class="form-group" id="__BVID__134">
                    <div tabindex="-1" role="group">
                      <label for="apellidoPaternoPerfil">
                        <span class="text-danger">*</span>
                        Apellido Paterno
                      </label>
                      <input id="apellidoPaternoPerfil" name="apellidoPaternoPerfil" #apellidoPaternoPerfil="ngModel" type="text" placeholder="Apellido paterno..." class="form-control" [(ngModel)]="usuario.apellidoPaterno" required>
                    </div>
                  </fieldset>
                </div>
                <div class="col-md-6">
                  <fieldset class="form-group" id="__BVID__134">
                    <div tabindex="-1" role="group">
                      <label for="apellidoMaternoPerfil">Apellido Materno</label>
                      <input id="apellidoMaternoPerfil" name="apellidoMaternoPerfil" #apellidoMaternoPerfil="ngModel" type="text" placeholder="Apellido materno..." class="form-control" [(ngModel)]="usuario.apellidoMaterno">
                    </div>
                  </fieldset>
                </div>
                <div class="col-md-12">
                  <fieldset class="form-group" id="__BVID__132">
                    <div tabindex="-1" role="group">
                      <label for="emailPerfil">
                        <span class="text-danger">*</span>
                        Email
                      </label>
                      <input id="emailPerfil" name="emailPerfil" #emailPerfil="ngModel" type="email" placeholder="Email..." class="form-control" [(ngModel)]="usuario.email" required>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="">
                <label>Elije una nueva imagen de perfil</label>
                <div class="custom-file mb-3">
                  <input type="file" class="custom-file-input"  lang="es" (change)="fileChangeEvent($event)" title="Seleccione una imagen." placeholder="Elije una imagen..." required accept="image/png, image/jpeg, image/jpg" id="validatedCustomFile">
                  <label class="custom-file-label" for="validatedCustomFile">Elije un archivo...</label>
                  <div class="invalid-feedback">Archivo invalido</div>
                </div>
                <image-cropper
                  [imageChangedEvent]="imageChangedEvent"
                  [maintainAspectRatio]="true"
                  [aspectRatio]="4 / 3"
                  [resizeToWidth]="128"
                  [cropperMinWidth]="128"
                  [onlyScaleDown]="true"
                  [roundCropper]="false"
                  format="png"
                  outputType="base64"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (cropperReady)="cropperReady()"
                  (loadImageFailed)="loadImageFailed()"
                  [style.display]="showCropper ? null : 'none'"
                ></image-cropper>
                <div class="card-footer" *ngIf="imageChangedEvent">
                  <div class="text-center">
                    <button type="button" class="btn btn-sm m-2 btn-primary" (click)="rotateLeft()">
                      <i class="fa fa-undo"></i> Girar izquierda
                    </button>
                    <button type="button" class="btn btn-sm m-2 btn-primary" (click)="rotateRight()">
                      <i class="fa fa-undo fa-flip-horizontal"></i> Girar derecha
                    </button>
                    <button type="button" class="btn btn-sm m-2 btn-primary" (click)="flipHorizontal()">
                      <i class="fab fa-flipboard fa-rotate-270"></i> Invertir horizontal
                    </button>
                    <button type="button" class="btn btn-sm m-2 btn-primary" (click)="flipVertical()">
                      <i class="fab fa-flipboard fa-flip-horizontal"></i> Invertir vertical
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="p-0 list-group-item">
            <div class="grid-menu grid-menu-2col overflow-hidden">
              <div class="no-gutters row">
                <div class="col-sm-6">
                  <button type="button" (click)="backLink()" class="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-danger">
                    <i class="lnr-chevron-left btn-icon-wrapper btn-icon-lg mb-3"></i>Cancelar
                  </button>
                </div>
                <div class="col-sm-6">
                  <button type="submit" class="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-success" [disabled]="!f.valid">
                    <i class="lnr-download btn-icon-wrapper btn-icon-lg mb-3"></i>Registrar los cambios
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </form>
    </div>
  </div>
</div>




<!-- <form> -->
  <!-- <div class="form-group">
    <label for="exampleFormControlFile1">Example file input</label>
    <input type="file" class="form-control-file" id="exampleFormControlFile1">
  </div>
</form> -->


<!-- <div class="custom-file">
  <input type="file" class="custom-file-input" id="validatedCustomFile" required>
  <label class="custom-file-label" for="validatedCustomFile">Choose file...</label>
  <div class="invalid-feedback">Example invalid custom file feedback</div>
</div> -->

<!-- 
<div class="custom-file">
  <input type="file" class="custom-file-input" id="customFile">
  <label class="custom-file-label" for="customFile">Choose file</label>
</div>


<div class="custom-file">
  <input type="file" class="custom-file-input" id="customFileLang" lang="es">
  <label class="custom-file-label" for="customFileLang">Seleccionar Archivo</label>
</div> -->


<!-- <input type="file"  > -->
