import * as fromUsuario from '../actions';
import { Area, Departamento } from 'src/app/models/departamento';

export interface AreaState {
  areas: Area[];
  departamentos: Departamento[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: AreaState = {
  areas: [],
  departamentos: [],
  loaded: false,
  loading: false,
  error: null
};

export function areasReducer( state = estadoInicial, action: fromUsuario.areasAcciones ): AreaState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_AREAS_USUARIO:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_AREAS_USUARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        areas: [...action.areas]
        };
    case fromUsuario.CARGAR_AREAS_USUARIO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.CARGAR_AREAS:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_AREAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        departamentos: [...action.areas]
        };
    case fromUsuario.CARGAR_AREAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.ACTUALIZA_AREA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_AREA_SUCCESS:
      const itm = state.departamentos.find( item => item.id === action.area.id);
      if (itm && itm.id) {
        state.departamentos = state.departamentos.map( item => {
          if (item.id === action.area.id) {
            item = {...action.area};
          }
          return item;
        });
      } else {
        state.departamentos.push(action.area);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_AREA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ELIMINAR_AREA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ELIMINAR_AREA_SUCCESS:
      state.departamentos = state.departamentos.filter( item => item.id !== action.id);
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ELIMINAR_AREA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

