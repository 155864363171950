export class Dianolaboral{
	public id: number;
	public descripcion: string;
	public dia: any;


	constructor(
		obj: DataObjDianolaboral
	){
		this.id = obj && obj.id? obj.id: 0;
		this.descripcion = obj && obj.descripcion? obj.descripcion : '';
		this.dia = obj && obj.dia? obj.dia :null;

	}
}
interface DataObjDianolaboral{
	id: number;
	descripcion: string;
	dia: any;
}