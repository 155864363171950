import { Action } from '@ngrx/store';
import { ReporteDia, CoberturaOperacional, CoberturaOperacionalServicio, DivisionIniFinaEn, ServicioIniFinaEn, DivisionBajaVacan } from 'src/app/models/reporte-asignacion';
import { Fechas } from 'src/app/models/fechas';

export const CARGAR_REPORTE_ASIGNACION_DIA = '[ReporteDia] Cargar reporte asignaciones por dia';
export const CARGAR_REPORTE_ASIGNACION_DIA_FAIL = '[ReporteDia] Cargar reporte asignaciones por dia FAIL';
export const CARGAR_REPORTE_ASIGNACION_DIA_SUCCESS = '[ReporteDia] Cargar reporte asignaciones por dia SUCCESS';
export const CARGAR_REPORTE_COBERTURA_OPERACIONAL = '[ReporteDia] Cargar reporte cobertura operacional';
export const CARGAR_REPORTE_COBERTURA_OPERACIONAL_FAIL = '[ReporteDia] Cargar reporte cobertura operacional FAIL';
export const CARGAR_REPORTE_COBERTURA_OPERACIONAL_SUCCESS = '[ReporteDia] Cargar reporte cobertura operacional SUCCESS';
export const CARGAR_REPORTE_COBERTURA_OPERACIONAL_SERVICIO = '[ReporteDia] Cargar reporte cobertura operacional por servicio';
export const CARGAR_REPORTE_COBERTURA_OPERACIONAL_SERVICIO_FAIL = '[ReporteDia] Cargar reporte cobertura operacional por servicio FAIL';
export const CARGAR_REPORTE_COBERTURA_OPERACIONAL_SERVICIO_SUCCESS = '[ReporteDia] Cargar reporte cobertura operacional por servicio SUCCESS';
export const CARGAR_REPORTE_SERVICIO_INICIO = '[ReporteDia] Cargar reporte servicios iniciados';
export const CARGAR_REPORTE_SERVICIO_INICIO_FAIL = '[ReporteDia] Cargar reporte servicios iniciados FAIL';
export const CARGAR_REPORTE_SERVICIO_INICIO_SUCCESS = '[ReporteDia] Cargar reporte servicios iniciados SUCCESS';
export const CARGAR_REPORTE_SERVICIO_FINALIZO = '[ReporteDia] Cargar reporte servicios finalizados';
export const CARGAR_REPORTE_SERVICIO_FINALIZO_FAIL = '[ReporteDia] Cargar reporte servicios finalizados FAIL';
export const CARGAR_REPORTE_SERVICIO_FINALIZO_SUCCESS = '[ReporteDia] Cargar reporte servicios finalizados SUCCESS';
export const CARGAR_REPORTE_BAJA_VACANTES = '[ReporteDia] Cargar reporte bajas y vacantes';
export const CARGAR_REPORTE_BAJA_VACANTES_FAIL = '[ReporteDia] Cargar reporte bajas y vacantes FAIL';
export const CARGAR_REPORTE_BAJA_VACANTES_SUCCESS = '[ReporteDia] Cargar reporte bajas y vacantes SUCCESS';
export const LIMPIAR_REPORTE_ASIGNACION_DIA = '[ReporteDia] Limpiar reporte asignaciones por dia';

export class CargarReporteAsignacionesDia implements Action {
  readonly type = CARGAR_REPORTE_ASIGNACION_DIA;

  constructor( public fecha: Fechas ) {}
}

export class CargarReporteAsignacionesDiaFail implements Action {
  readonly type = CARGAR_REPORTE_ASIGNACION_DIA_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteAsignacionesDiaSuccess implements Action {
  readonly type = CARGAR_REPORTE_ASIGNACION_DIA_SUCCESS;

  constructor( public reporte: ReporteDia[] ) {}
}


export class CargarReporteCoberturaOperacional implements Action {
  readonly type = CARGAR_REPORTE_COBERTURA_OPERACIONAL;

  constructor( public fecha: Fechas ) {}
}

export class CargarReporteCoberturaOperacionalFail implements Action {
  readonly type = CARGAR_REPORTE_COBERTURA_OPERACIONAL_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteCoberturaOperacionalSuccess implements Action {
  readonly type = CARGAR_REPORTE_COBERTURA_OPERACIONAL_SUCCESS;

  constructor( public reporte: CoberturaOperacional[] ) {}
}

export class CargarReporteCoberturaOperacionalServicio implements Action {
  readonly type = CARGAR_REPORTE_COBERTURA_OPERACIONAL_SERVICIO;

  constructor( public fecha: Fechas ) {}
}

export class CargarReporteCoberturaOperacionalServicioFail implements Action {
  readonly type = CARGAR_REPORTE_COBERTURA_OPERACIONAL_SERVICIO_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteCoberturaOperacionalServicioSuccess implements Action {
  readonly type = CARGAR_REPORTE_COBERTURA_OPERACIONAL_SERVICIO_SUCCESS;

  constructor( public reporte: CoberturaOperacionalServicio[] ) {}
}

export class CargarReporteServicioInicio implements Action {
  readonly type = CARGAR_REPORTE_SERVICIO_INICIO;

  constructor( public fecha: Fechas ) {}
}

export class CargarReporteServicioInicioFail implements Action {
  readonly type = CARGAR_REPORTE_SERVICIO_INICIO_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteServicioInicioSuccess implements Action {
  readonly type = CARGAR_REPORTE_SERVICIO_INICIO_SUCCESS;

  constructor( public reporte: ServicioIniFinaEn[], public division: DivisionIniFinaEn[] ) {}
}

export class CargarReporteServicioFinalizo implements Action {
  readonly type = CARGAR_REPORTE_SERVICIO_FINALIZO;

  constructor( public fecha: Fechas ) {}
}

export class CargarReporteServicioFinalizoFail implements Action {
  readonly type = CARGAR_REPORTE_SERVICIO_FINALIZO_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteServicioFinalizoSuccess implements Action {
  readonly type = CARGAR_REPORTE_SERVICIO_FINALIZO_SUCCESS;

  constructor( public reporte: ServicioIniFinaEn[], public division: DivisionIniFinaEn[] ) {}
}

export class CargarReporteBajaVacante implements Action {
  readonly type = CARGAR_REPORTE_BAJA_VACANTES;

  constructor( public fecha: Fechas ) {}
}

export class CargarReporteBajaVacanteFail implements Action {
  readonly type = CARGAR_REPORTE_BAJA_VACANTES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteBajaVacanteSuccess implements Action {
  readonly type = CARGAR_REPORTE_BAJA_VACANTES_SUCCESS;

  constructor( public reporte: DivisionBajaVacan[]) {}
}

export class LimpiarReporteAsignacionesDia implements Action {
  readonly type = LIMPIAR_REPORTE_ASIGNACION_DIA;

  constructor() {}
}

export type reporteAsignacionDiaAcciones = CargarReporteAsignacionesDia 
                                        | CargarReporteAsignacionesDiaFail 
                                        | CargarReporteAsignacionesDiaSuccess 
                                        | CargarReporteCoberturaOperacional 
                                        | CargarReporteCoberturaOperacionalFail 
                                        | CargarReporteCoberturaOperacionalSuccess 
                                        | CargarReporteCoberturaOperacionalServicio 
                                        | CargarReporteCoberturaOperacionalServicioFail 
                                        | CargarReporteCoberturaOperacionalServicioSuccess 
                                        | CargarReporteServicioInicio 
                                        | CargarReporteServicioInicioFail 
                                        | CargarReporteServicioInicioSuccess 
                                        | CargarReporteServicioFinalizo 
                                        | CargarReporteServicioFinalizoFail 
                                        | CargarReporteServicioFinalizoSuccess 
                                        | CargarReporteBajaVacante 
                                        | CargarReporteBajaVacanteFail 
                                        | CargarReporteBajaVacanteSuccess 
                                        | LimpiarReporteAsignacionesDia;

