import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService } from './../../services/auth/auth-guard.service';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { SettingProfileComponent } from './users/setting-profile/setting-profile.component';
import { ListUsersComponent } from './users/list-users/list-users.component';
import { EditComponent } from './users/edit/edit.component';
import { MetaGuard } from '@ngx-meta/core';
import { AccessUserService } from 'src/app/services/auth/access-user.service';
import { NotAuthorizationComponent } from './not-authorization/not-authorization.component';
import { NotFoundComponent } from 'src/app/layout/components/not-found/not-found.component';
// import { NotificacionesComponent } from './notificaciones/notificaciones.component';



import { ActividadesComponent } from './actividades/actividades/actividades.component';
import { ListActividadesComponent } from './actividades/list-actividades/list-actividades.component';
import { ReporteActividadesComponent } from './actividades/reportes/reporte-actividades.component';
import { CumplimientoComponent } from './actividades/cumplimiento/cumplimiento.component';
import { DivisionComponent } from './catalogos/divisiones/division.component';
import { ServiciosComponent } from './catalogos/servicios/servicios.component';
import { SucursalesComponent } from './catalogos/sucursales/sucursales.component';
import { IndicadorComponent } from './indicadores/indicador/indicador.component';
import { ColoresComponent } from './catalogos/colores/colores.component';
import { ListIndicadoresComponent } from './indicadores/list-indicadores/list-indicadores.component';
import { CapturaIndicadorComponent } from './indicadores/captura-indicador/captura-indicador.component';
import { TableroKpisAreaComponent } from './indicadores/tablero-kpi/tablero-kpis-area/tablero-kpis-area.component'
import { TableroKpisIndicadorComponent } from './indicadores/tablero-kpi/tablero-kpis-indicador/tablero-kpis-indicador.component';
import { TableroKpisUsuarioComponent } from './indicadores/tablero-kpi/tablero-kpis-usuario/tablero-kpis-usuario.component';
import { AreasComponent } from './catalogos/areas/areas.component';
import { LisAsignacionesComponent } from './asignaciones/lis-asignaciones/lis-asignaciones.component';
import { EditAsignacionComponent } from './asignaciones/edit/edit.component';
import { ListClientesComponent } from './catalogos/clientes/list-clientes.component';
import { TipoServicioComponent } from './catalogos/tipo-servicio/tipo-servicio.component';
import { CapturaAsignacionComponent } from './asignaciones/captura-asignacion/captura-asignacion.component';
import { HistorialAsignacionComponent } from './historial/historial-asignacion/historial-asignacion.component';
import { ReporteAsignacionDiasComponent } from './reportes/reporte-asignacion-dias/reporte-asignacion-dias.component';
import { ReporteAsignacionComponent } from './reportes/reporte-asignacion/reporte-asignacion.component';
import { ReporteCoberturaServicioComponent } from './reportes/reporte-cobertura-servicio/reporte-cobertura-servicio.component';
import { ReporteAsignacionIniciaComponent } from './reportes/reporte-asignacion-inicia/reporte-asignacion-inicia.component';
import { ReporteAsignacionFinalizaComponent } from './reportes/reporte-asignacion-finaliza/reporte-asignacion-finaliza.component';
import { ReporteAsignacionBajasVacantesComponent } from './reportes/reporte-asignacion-bajas-vacantes/reporte-asignacion-bajas-vacantes.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { EmpresasEditComponent } from './empresas/empresas-edit/empresas-edit.component';
import { PaisesComponent } from './catalogos/paises/paises.component';
import { OrigenContactosComponent } from './catalogos/origen-contactos/origen-contactos.component';
import { IndustriasComponent } from './catalogos/industrias/industrias.component';
import { ContactosComponent } from './contactos/contactos.component';
import { EditContactoComponent } from './contactos/edit-contacto/edit-contacto.component';
import { CargaContactosComponent } from './contactos/carga-contactos/carga-contactos.component';
import { OportunidadesComponent } from './oportunidades/oportunidades.component';
import { OportunidadesEditComponent } from './oportunidades/oportunidades-edit/oportunidades-edit.component';
import { OportunidadProfileComponent } from './oportunidades/oportunidad-profile/oportunidad-profile.component';
import { EmpresaProfileComponent } from './empresas/empresa-profile/empresa-profile.component';
import { ContactoProfileComponent } from './contactos/contacto-profile/contacto-profile.component';
import { ReporteCumpleannosComponent } from './reportes/reporte-cumpleannos/reporte-cumpleannos.component';
import { ReporteKpisVendedoresComponent } from './reportes/reporte-kpis-vendedores/reporte-kpis-vendedores.component';
import { ReporteVentasEtapasComponent } from './reportes/reporte-ventas-etapas/reporte-ventas-etapas.component';
import { ReporteVentasPropietariosComponent } from './reportes/reporte-ventas-propietarios/reporte-ventas-propietarios.component';
import { KpisVendedoresComponent } from './kpis-vendedores/kpis-vendedores.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketsEditComponent } from './tickets/tickets-edit/tickets-edit.component';
import { DetailsTicketsComponent } from './tickets/details-tickets/details-tickets.component';
import { ReportesComponent } from './reportes/reportes.component';
import { UsuariosConfigComponent } from './config/usuarios-config/usuarios-config.component';
import { ReportesOperacionesComponent } from './reportes/reportes-operaciones/reportes-operaciones.component';
import { ReportesListCrmComponent } from './reportes/reportes-list-crm/reportes-list-crm.component';
import { UsuarioConfigEditComponent } from './config/usuario-config-edit/usuario-config-edit.component';




export const authRoutes: Routes = [
  { path: 'dashboard', component: DashboardComponent, data: {extraParameter: 'dashboardsMenu'}, canActivate: [ AuthGuardService ]},
  { path: 'change-password', component: ChangePasswordComponent, data: {extraParameter: 'dashboardsMenu'},
    canActivate: [ AuthGuardService ]},
  { path: 'setting-profile', component: SettingProfileComponent, data: {extraParameter: 'dashboardsMenu'},
    canActivate: [ AuthGuardService ]},
  { path: 'kpis-vendedores', component: KpisVendedoresComponent, data: {extraParameter: 'dashboardsMenu'},
    canActivate: [ AuthGuardService ]},

  { path: 'users', component: ListUsersComponent, data: {extraParameter: 'dashboardsMenu'}, canActivate: [ AuthGuardService ]},
  { path: 'users/register/:id', component: EditComponent, data: {extraParameter: 'dashboardsMenu'}, canActivate: [ AuthGuardService ]},
  { path: 'usuarios', component: ListUsersComponent, canActivate: [AccessUserService], canActivateChild: [MetaGuard],
    data: { validate: [ 'usuariosListVer', 'usuariosListEdit' ] } },
  { path: 'usuarios/register/:id', component: EditComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'usuariosRegVer', 'usuariosRegEdit' ] } },
  { path: 'profile', component: SettingProfileComponent, canActivate: [AccessUserService], canActivateChild: [MetaGuard] },
  // { path: 'notificaciones', component: NotificacionesComponent, canActivate: [AccessUserService],
  //   canActivateChild: [MetaGuard] },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AccessUserService] },
  { path: 'not-authorization', component: NotAuthorizationComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'config/usuarios', component: UsuariosConfigComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard] },
  { path: 'config/usuarios/register/:id', component: UsuarioConfigEditComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard] },

  { path: 'contactos/register/:id', component: EditContactoComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'contactosRegVer', 'contactosRegEdit' ] } },
  { path: 'contactos/load/register', component: CargaContactosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'contactosRegVer', 'contactosRegEdit' ] } },
  { path: 'contactos', component: ContactosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'contactosListVer', 'contactosListEdit' ] } },
  { path: 'contactos/profile/:id', component: ContactoProfileComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'actividadesMultipleEdit', 'actividadesMultipleVer' ] } },

  { path: 'empresas', component: EmpresasComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'empresasListEdit', 'empresasListVer' ] } },
  { path: 'empresas/register/:id', component: EmpresasEditComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'empresasRegEdit', 'empresasRegVer' ] } },
  { path: 'empresas/profile/:id', component: EmpresaProfileComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'actividadesMultipleEdit', 'actividadesMultipleVer' ] } },



  { path: 'oportunidades', component: OportunidadesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'oportunidadesListEdit', 'oportunidadesListVer' ] } },
  { path: 'oportunidades/register/:id', component: OportunidadesEditComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'oportunidadesRegEdit', 'oportunidadesRegVer' ] } },
  { path: 'oportunidades/profile/:id', component: OportunidadProfileComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'actividadesMultipleVer', 'actividadesMultipleEdit' ] } },




  { path: 'catalogos/origen-contactos', component: OrigenContactosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catOrigenesVer', 'catOrigenesEdit' ] } },
  { path: 'catalogos/paises', component: PaisesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catPaisesVer', 'catPaisesEdit' ] } },
  { path: 'catalogos/industrias', component: IndustriasComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catIndustriasVer', 'catIndustriasEdit' ] } },
  { path: 'catalogos/divisiones', component: DivisionComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catDivisionesVer', 'catDivisionesEdit' ] } },
  { path: 'catalogos/areas', component: AreasComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catDepartamentosVer', 'catDepartamentosEdit' ] } },
  { path: 'catalogos/sucursales', component: SucursalesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catSucursalesVer', 'catSucursalesEdit' ] } },
  { path: 'catalogos/colores', component: ColoresComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catColoresVer', 'catColoresEdit' ] } },
  { path: 'catalogos/servicios', component: ServiciosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catServiciosVer', 'catServiciosEdit' ] } },
  { path: 'catalogos/clientes', component: ListClientesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catClienteVer', 'catClienteEdit' ] } },
  { path: 'catalogos/tipo-servicios', component: TipoServicioComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catTipoServicioVer', 'catTipoServicioEdit' ] } },


  { path: 'tickets', component: TicketsComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'ticketsVer', 'ticketsEdit' ] } },
  { path: 'tickets/detail/:id', component: DetailsTicketsComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'ticketsVer', 'ticketsEdit' ] } },
  { path: 'tickets/register/:id', component: TicketsEditComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'ticketsVer', 'ticketsEdit' ] } },


    
  { path: 'asignacion', component: LisAsignacionesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'asignacionListVer', 'asignacionListEdit' ] } },
  { path: 'asignacion/register/:id', component: EditAsignacionComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'asignacionRegVer', 'asignacionRegEdit' ] } },


  { path: 'actividades', component: ListActividadesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'actividadesListVer', 'actividadesListEdit' ] } },
  { path: 'actividades/register/:id', component: ActividadesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'actividadesRegVer', 'actividadesRegEdit' ] } },


  { path: 'indicador', component: ListIndicadoresComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'indicadorListVer', 'indicadorListEdit' ] } },
  { path: 'indicador/register/:id', component: IndicadorComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'indicadorRegVer', 'indicadorRegEdit' ] } },
  { path: 'indicador/captura/administrativo', component: CapturaIndicadorComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'indicadorCapturar' ] } },
  { path: 'indicador/captura/operativo', component: CapturaAsignacionComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'asignacionCapturar' ] } },

  { path: 'cumplimiento', component: CumplimientoComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'seguimientoCumplimientoVer', 'seguimientoCumplimientoEdit' ] } },

    
  { path: 'reportes/crm', component: ReportesListCrmComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptKpiVendedores', 'rptCumpleannos', 'rptOportunidadEtapas', 'rptOportunidadPropietarios' ] } },
  { path: 'reportes/crm/oportunidad/etapas', component: ReporteVentasEtapasComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptOportunidadEtapas' ] } },
  { path: 'reportes/crm/oportunidad/propietarios', component: ReporteVentasPropietariosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptOportunidadPropietarios' ] } },
  { path: 'reportes/crm/cumpleannos', component: ReporteCumpleannosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptCumpleannos' ] } },
  { path: 'reportes/crm/kpis-vendedores', component: ReporteKpisVendedoresComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptKpiVendedores' ] } },
 


  { path: 'reportes', component: ReportesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptIndicador', 'rptIndicadorArea', 'rptIndicadorUsuario', 'rptActividades' ] } },
  { path: 'reportes/actividades', component: ReporteActividadesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptActividades' ] } },
  { path: 'reportes/tablero-kpi/area', component: TableroKpisAreaComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptIndicadorArea' ] } },
  { path: 'reportes/tablero-kpi/indicador', component: TableroKpisIndicadorComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptIndicador' ] } },
  { path: 'reportes/tablero-kpi/usario', component: TableroKpisUsuarioComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptIndicadorUsuario' ] } },

    
  

  { path: 'operaciones', component: ReportesOperacionesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptCoberturaDia', 'rptCobertura', 'rptServicioCop', 'rptBajaVacantes', 'rptServicioInicia', 'rptServicioTermina' ] } },
  { path: 'operaciones/cobertura/dia', component: ReporteAsignacionDiasComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptCoberturaDia' ] } },
  { path: 'operaciones/cobertura', component: ReporteAsignacionComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptCobertura' ] } },
  { path: 'operaciones/cobertura/servicio', component: ReporteCoberturaServicioComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptServicioCop' ] } },
  { path: 'operaciones/bajas/vacantes', component: ReporteAsignacionBajasVacantesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptBajaVacantes' ] } },
  { path: 'operaciones/servicios/iniciado', component: ReporteAsignacionIniciaComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptServicioInicia' ] } },
  { path: 'operaciones/servicios/finalizado', component: ReporteAsignacionFinalizaComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptServicioTermina' ] } },

  { path: '**', component: NotFoundComponent }
];
