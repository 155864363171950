import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity } from 'src/app/models/user';
// import { Notificacion } from 'src/app/models/notificacion';
import { Subscription, timer } from 'rxjs';
import { GLOBAL } from 'src/app/models/global';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import * as usuariosActions from 'src/app/store/actions';
// import { AngularFireList } from 'angularfire2/database';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { take } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-dots',
  templateUrl: './dots.component.html',
})
export class DotsComponent implements OnInit, OnDestroy {
  public identity: Identity;
  public token: string;
  public url: string;
  // public notificaciones: Notificacion[];
  public loading: boolean;
  public total: number;
  public sinLeer: number;
  public otro: any;
  public afList: any;
  public listObservable: any;
  public changeNotification: string;

  // public items: AngularFireList<any>;
  public name: any;
  public msgVal = '';
  public count = 0;
  private subscriptionAuth: Subscription = new Subscription();
  private subscriptionNotificacion: Subscription = new Subscription();

  constructor(
    private notificacionesService: NotificacionesService,
    private store: Store<AppState>
  ) {
    this.identity = new Identity(null);
    this.url = GLOBAL.url;
    // this.notificaciones = [];
    this.loading = false;
    this.total = 0;
    this.sinLeer = 0;
  }

  ngOnInit() {
    this.subscriptionAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    // this.subscriptionNotificacion = this.store.select('notificacionMini').subscribe(
    //   (state) => {
    //     this.notificaciones = state.notificaciones;
    //     this.sinLeer = state.sinLeer;
    //     this.total = state.total;
    //   }
    // );
    console.log('Inicializa componente Header');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscriptionAuth.unsubscribe();
    this.subscriptionNotificacion.unsubscribe();
  }

  inicializa() {
    // this.store.dispatch(new usuariosActions.CargarNotificacionesMini(1, 20, 0));
  }

  setLeido(notification ) { // : Notificacion
    // if ( !notification.leido ) {
    //   notification.leido = true;
    //   this.sinLeer--;
    //   this.notificacionesService.setLeido(notification.id).subscribe(
    //     (response) => {}, (err) => {}
    //   );
    // }
  }
}
