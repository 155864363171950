import { Action } from '@ngrx/store';
import { ArchivoConcepto, Archivo } from 'src/app/models/archivo';

export const CARGAR_ARCHIVOS = '[Archivo] Cargar archivos';
export const CARGAR_ARCHIVOS_FAIL = '[Archivo] Cargar archivos FAIL';
export const CARGAR_ARCHIVOS_SUCCESS = '[Archivo] Cargar archivos SUCCESS';
export const REGISTRAR_ARCHIVO = '[Archivo] Registrar archivo';
export const REGISTRAR_ARCHIVO_FAIL = '[Archivo] Registrar archivo FAIL';
export const REGISTRAR_ARCHIVO_SUCCESS = '[Archivo] Registrar archivo SUCCESS';
export const ELIMINAR_ARCHIVO = '[Archivo] Elimina archivo';
export const ELIMINAR_ARCHIVO_FAIL = '[Archivo] Elimina archivo FAIL';
export const ELIMINAR_ARCHIVO_SUCCESS = '[Archivo] Elimina archivo SUCCESS';
export const LIMPIAR_ARCHIVOS = '[Archivo] Limpiar archivo';

export class CargarArchivos implements Action {
  readonly type = CARGAR_ARCHIVOS;

  constructor( public id: number, public tipo: number) {}
}

export class CargarArchivosFail implements Action {
  readonly type = CARGAR_ARCHIVOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarArchivosSuccess implements Action {
  readonly type = CARGAR_ARCHIVOS_SUCCESS;

  constructor( public archivos: ArchivoConcepto[] ) {}
}

export class RegistrarArchivo implements Action {
  readonly type = REGISTRAR_ARCHIVO;

  // tslint:disable-next-line:max-line-length
  constructor( public url: string, public params: any, public files: File[], public token: string, public name: string, public archivo?: ArchivoConcepto | Archivo ) { }
}

export class RegistrarArchivoFail implements Action {
  readonly type = REGISTRAR_ARCHIVO_FAIL;

  constructor( public payload: any ) {}
}

export class RegistrarArchivoSuccess implements Action {
  readonly type = REGISTRAR_ARCHIVO_SUCCESS;

  constructor( public archivo: ArchivoConcepto ) {}
}

export class EliminarArchivo implements Action {
  readonly type = ELIMINAR_ARCHIVO;

  constructor( public id: number, public archivo: ArchivoConcepto ) { }
}

export class EliminarArchivoFail implements Action {
  readonly type = ELIMINAR_ARCHIVO_FAIL;

  constructor( public payload: any ) {}
}

export class EliminarArchivoSuccess implements Action {
  readonly type = ELIMINAR_ARCHIVO_SUCCESS;

  constructor( public id: number ) {}
}


export class LimpiarArchivos implements Action {
  readonly type = LIMPIAR_ARCHIVOS;

  constructor() {}
}

export type actividadesArchivosAcciones = CargarArchivos
                                        | CargarArchivosFail
                                        | CargarArchivosSuccess
                                        | RegistrarArchivo
                                        | RegistrarArchivoFail
                                        | RegistrarArchivoSuccess
                                        | EliminarArchivo
                                        | EliminarArchivoFail
                                        | EliminarArchivoSuccess
                                        | LimpiarArchivos;

