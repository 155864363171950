import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Identity, Permisos, UserType, Suscripciones } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';

@Component({
  selector: 'app-usuario-config-smie',
  templateUrl: './usuario-config-smie.component.html',
  styleUrls: ['./usuario-config-smie.component.scss']
})
export class UsuarioConfigSmieComponent implements OnInit, OnDestroy {
  public identity: Identity;
  private subscribeAuth: Subscription = new Subscription();
  public loading = false;
  public url: string;
  public suscripciones: Suscripciones[] = [];
  public todo = false;
  public todoSuscripciones = false;
  public filterPermisos: number = 1;

  @Input() permisos: Permisos;
  @Input() tipoSelected: UserType;
  @Output() messageEvent = new EventEmitter<Permisos>();
  constructor(
    private store: Store<AppState>,
  ) {
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;

    // filterPermisos = 1 TODOS
    // filterPermisos = 2 Consulta
    // filterPermisos = 3 Registros
    // filterPermisos = 6 Reportes 
    // filterPermisos = 9 Catálogos 
    // filterPermisos = 10 Indicadores
    // filterPermisos = 11 Asignaciones
    // filterPermisos = 13 Actividades
   }

  ngOnInit(): void {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.filterSelecionar();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
  }

  sendMessage() {
    this.messageEvent.emit(this.permisos);
  }

  validarTodo() {
    if(this.filterPermisos !== 3 ){
      setTimeout( () => {
        // tslint:disable-next-line:forin
        for (const field in this.permisos) {
          if (field.search('Edit')) {
            if (this.permisos[field]) {
              const atributo = field.substr(0, field.length - 4) + 'Ver';
              if (atributo in this.permisos) {
                this.permisos[atributo] = true;
              }
            }
          }
        }
        const elements = Object.keys(this.permisos);
        this.todo = elements.every( (key) => this.permisos[key]);
      }, 1);
    }
    this.sendMessage();
  }

  validarTodoSuscripciones() {
    this.todoSuscripciones = this.suscripciones.every( (item) => item.checked);
  }


  seleccionar() {
    switch ( this.filterPermisos ) {
      case 1:
        this.seleccionarTodo();
      break;
      case 2:
        this.seleccionarConsulta();
      break;
      case 3:
        this.seleccionarRegistro();
      break;
      case 6:
        this.seleccionarReporte();
      break;
      case 9:
        this.seleccionarCatalogo();
      break;
      case 10:
        this.seleccionarIndicador();
      break;
      case 11:
        this.seleccionarAsignacion();
      break;
      case 13:
        this.seleccionarActividad();
      break;
    }
  }

  seleccionarTodo() {
    if (this.todo) {
      this.permisos.indicadorListEdit = true;
      this.permisos.indicadorListVer = true;
      this.permisos.indicadorRegEdit = true;
      this.permisos.indicadorRegVer = true;
      this.permisos.indicadorCapturar = true;
      this.permisos.asignacionListEdit = true;
      this.permisos.asignacionListVer = true;
      this.permisos.asignacionRegEdit = true;
      this.permisos.asignacionRegVer = true;
      this.permisos.asignacionCapturar = true;
      this.permisos.actividadesListEdit = true;
      this.permisos.actividadesListVer = true;
      this.permisos.actividadesRegEdit = true;
      this.permisos.actividadesRegVer = true;
      this.permisos.catDepartamentosEdit = true;
      this.permisos.catDepartamentosVer = true;
      this.permisos.catDivisionesEdit = true;
      this.permisos.catDivisionesVer = true;
      this.permisos.catSucursalesEdit = true;
      this.permisos.catSucursalesVer = true;
      this.permisos.catColoresEdit = true;
      this.permisos.catColoresVer = true;
      this.permisos.catServiciosEdit = true;
      this.permisos.catServiciosVer = true;
      this.permisos.catClienteEdit = true;
      this.permisos.catClienteVer = true;
      this.permisos.catTipoServicioEdit = true;
      this.permisos.catTipoServicioVer = true;
      this.permisos.rptCobertura = true;
      this.permisos.rptCoberturaDia = true;
      this.permisos.rptActividades = true;
      this.permisos.rptServicioCop = true;
      this.permisos.rptServicioInicia = true;
      this.permisos.rptServicioTermina = true;
      this.permisos.rptBajaVacantes = true;
      this.permisos.rptIndicador = true;
      this.permisos.rptIndicadorArea = true;
      this.permisos.rptIndicadorUsuario = true;
    } else {
      this.permisos.indicadorListEdit = false;
      this.permisos.indicadorListVer = false;
      this.permisos.indicadorRegEdit = false;
      this.permisos.indicadorRegVer = false;
      this.permisos.indicadorCapturar = false;
      this.permisos.asignacionListEdit = false;
      this.permisos.asignacionListVer = false;
      this.permisos.asignacionRegEdit = false;
      this.permisos.asignacionRegVer = false;
      this.permisos.asignacionCapturar = false;
      this.permisos.actividadesListEdit = false;
      this.permisos.actividadesListVer = false;
      this.permisos.actividadesRegEdit = false;
      this.permisos.actividadesRegVer = false;
      this.permisos.catDepartamentosEdit = false;
      this.permisos.catDepartamentosVer = false;
      this.permisos.catDivisionesEdit = false;
      this.permisos.catDivisionesVer = false;
      this.permisos.catSucursalesEdit = false;
      this.permisos.catSucursalesVer = false;
      this.permisos.catColoresEdit = false;
      this.permisos.catColoresVer = false;
      this.permisos.catServiciosEdit = false;
      this.permisos.catServiciosVer = false;
      this.permisos.catClienteEdit = false;
      this.permisos.catClienteVer = false;
      this.permisos.catTipoServicioEdit = false;
      this.permisos.catTipoServicioVer = false;
      this.permisos.rptCobertura = false;
      this.permisos.rptCoberturaDia = false;
      this.permisos.rptActividades = false;
      this.permisos.rptServicioCop = false;
      this.permisos.rptServicioInicia = false;
      this.permisos.rptServicioTermina = false;
      this.permisos.rptBajaVacantes = false;
      this.permisos.rptIndicador = false;
      this.permisos.rptIndicadorArea = false;
      this.permisos.rptIndicadorUsuario = false;
    }
  }

  seleccionarConsulta() {
    if (this.todo) {
      this.permisos.indicadorListVer = true;
      this.permisos.indicadorRegVer = true;
      this.permisos.asignacionListVer = true;
      this.permisos.asignacionRegVer = true;
      this.permisos.actividadesListVer = true;
      this.permisos.actividadesRegVer = true;
      this.permisos.catDepartamentosVer = true;
      this.permisos.catDivisionesVer = true;
      this.permisos.catSucursalesVer = true;
      this.permisos.catColoresVer = true;
      this.permisos.catServiciosVer = true;
      this.permisos.catClienteVer = true;
      this.permisos.catTipoServicioVer = true;
      this.permisos.rptActividades = true;
      this.permisos.rptServicioCop = true;
      this.permisos.rptServicioInicia = true;
      this.permisos.rptServicioTermina = true;
      this.permisos.rptBajaVacantes = true;
      this.permisos.rptIndicador = true;
      this.permisos.rptIndicadorArea = true;
      this.permisos.rptIndicadorUsuario = true;
      this.permisos.rptCobertura = true;
      this.permisos.rptCoberturaDia = true;
    } else {
      this.permisos.indicadorListVer = false;
      this.permisos.indicadorRegVer = false;
      this.permisos.asignacionListVer = false;
      this.permisos.asignacionRegVer = false;
      this.permisos.actividadesListVer = false;
      this.permisos.actividadesRegVer = false;
      this.permisos.catDepartamentosVer = false;
      this.permisos.catDivisionesVer = false;
      this.permisos.catSucursalesVer = false;
      this.permisos.catColoresVer = false;
      this.permisos.catServiciosVer = false;
      this.permisos.catClienteVer = false;
      this.permisos.catTipoServicioVer = false;
      this.permisos.rptActividades = false;
      this.permisos.rptServicioCop = false;
      this.permisos.rptServicioInicia = false;
      this.permisos.rptServicioTermina = false;
      this.permisos.rptBajaVacantes = false;
      this.permisos.rptIndicador = false;
      this.permisos.rptIndicadorArea = false;
      this.permisos.rptIndicadorUsuario = false;
      this.permisos.rptCobertura = false;
    }
  }


  seleccionarRegistro() {
    if (this.todo) {
      this.permisos.indicadorListEdit = true;
      this.permisos.indicadorRegEdit = true;
      this.permisos.indicadorCapturar = true;
      this.permisos.asignacionListEdit = true;
      this.permisos.asignacionRegEdit = true;
      this.permisos.asignacionCapturar = true;
      this.permisos.actividadesListEdit = true;
      this.permisos.actividadesRegEdit = true;
      this.permisos.catDepartamentosEdit = true;
      this.permisos.catDivisionesEdit = true;
      this.permisos.catSucursalesEdit = true;
      this.permisos.catColoresEdit = true;
      this.permisos.catServiciosEdit = true;
      this.permisos.catClienteEdit = true;
      this.permisos.catTipoServicioEdit = true;
    } else {
      this.permisos.indicadorListEdit = false;
      this.permisos.indicadorRegEdit = false;
      this.permisos.indicadorCapturar = false;
      this.permisos.asignacionListEdit = false;
      this.permisos.asignacionRegEdit = false;
      this.permisos.asignacionCapturar = false;
      this.permisos.actividadesListEdit = false;
      this.permisos.actividadesRegEdit = false;
      this.permisos.catDepartamentosEdit = false;
      this.permisos.catDivisionesEdit = false;
      this.permisos.catSucursalesEdit = false;
      this.permisos.catColoresEdit = false;
      this.permisos.catServiciosEdit = false;
      this.permisos.catClienteEdit = false;
      this.permisos.catTipoServicioEdit = false;
    }
  }


  seleccionarReporte() {
    if (this.todo) {
      this.permisos.rptActividades = true;
      this.permisos.rptServicioCop = true;
      this.permisos.rptServicioInicia = true;
      this.permisos.rptServicioTermina = true;
      this.permisos.rptBajaVacantes = true;
      this.permisos.rptIndicador = true;
      this.permisos.rptIndicadorArea = true;
      this.permisos.rptIndicadorUsuario = true;
      this.permisos.rptCobertura = true;
      this.permisos.rptCoberturaDia = true;
    } else {
      this.permisos.rptActividades = false;
      this.permisos.rptServicioCop = false;
      this.permisos.rptServicioInicia = false;
      this.permisos.rptServicioTermina = false;
      this.permisos.rptBajaVacantes = false;
      this.permisos.rptIndicador = false;
      this.permisos.rptIndicadorArea = false;
      this.permisos.rptIndicadorUsuario = false;
      this.permisos.rptCobertura = false;
      this.permisos.rptCoberturaDia = false;
    }
  }


  seleccionarCatalogo() {
    if (this.todo) {
      this.permisos.catDepartamentosEdit = true;
      this.permisos.catDepartamentosVer = true;
      this.permisos.catDivisionesEdit = true;
      this.permisos.catDivisionesVer = true;
      this.permisos.catSucursalesEdit = true;
      this.permisos.catSucursalesVer = true;
      this.permisos.catColoresEdit = true;
      this.permisos.catColoresVer = true;
      this.permisos.catServiciosEdit = true;
      this.permisos.catServiciosVer = true;
      this.permisos.catClienteEdit = true;
      this.permisos.catClienteVer = true;
      this.permisos.catTipoServicioEdit = true;
      this.permisos.catTipoServicioVer = true;
    } else {
      this.permisos.catDepartamentosEdit = false;
      this.permisos.catDepartamentosVer = false;
      this.permisos.catDivisionesEdit = false;
      this.permisos.catDivisionesVer = false;
      this.permisos.catSucursalesEdit = false;
      this.permisos.catSucursalesVer = false;
      this.permisos.catColoresEdit = false;
      this.permisos.catColoresVer = false;
      this.permisos.catServiciosEdit = false;
      this.permisos.catServiciosVer = false;
      this.permisos.catClienteEdit = false;
      this.permisos.catClienteVer = false;
      this.permisos.catTipoServicioEdit = false;
      this.permisos.catTipoServicioVer = false;
    }
  }

  seleccionarIndicador() {
    if (this.todo) {
      this.permisos.indicadorListEdit = true;
      this.permisos.indicadorListVer = true;
      this.permisos.indicadorRegEdit = true;
      this.permisos.indicadorRegVer = true;
      this.permisos.indicadorCapturar = true;
    } else {
      this.permisos.indicadorListEdit = false;
      this.permisos.indicadorListVer = false;
      this.permisos.indicadorRegEdit = false;
      this.permisos.indicadorRegVer = false;
      this.permisos.indicadorCapturar = false;
    }
  }

  seleccionarAsignacion() {
    if (this.todo) {
      this.permisos.asignacionListEdit = true;
      this.permisos.asignacionListVer = true;
      this.permisos.asignacionRegEdit = true;
      this.permisos.asignacionRegVer = true;
      this.permisos.asignacionCapturar = true;
    } else {
      this.permisos.asignacionListEdit = false;
      this.permisos.asignacionListVer = false;
      this.permisos.asignacionRegEdit = false;
      this.permisos.asignacionRegVer = false;
      this.permisos.asignacionCapturar = false;
    }
  }


  seleccionarActividad() {
    if (this.todo) {
      this.permisos.actividadesListEdit = true;
      this.permisos.actividadesListVer = true;
      this.permisos.actividadesRegEdit = true;
      this.permisos.actividadesRegVer = true;
    } else {
      this.permisos.actividadesListEdit = false;
      this.permisos.actividadesListVer = false;
      this.permisos.actividadesRegEdit = false;
      this.permisos.actividadesRegVer = false;
    }
  }




  filterSelecionar(){
    switch ( this.filterPermisos ) {
      case 1:
        this.filterSeleccionarTodo();
      break;
      case 2:
        this.filterSeleccionarConsulta();
      break;
      case 3:
        this.filterSeleccionarRegistro();
      break;
      case 6:
        this.filterSeleccionarReporte();
      break;
      case 9:
        this.filterSeleccionarCatalogo();
      break;
      case 10:
        this.filterSeleccionarIndicador();
      break;
      case 11:
        this.filterSeleccionarAsignacion();
      break;
      case 13:
        this.filterSeleccionarActividad();
      break;
    }
  }


  filterSeleccionarTodo() {
    if ( this.permisos.indicadorListEdit &&
      this.permisos.indicadorListVer &&
      this.permisos.indicadorRegEdit &&
      this.permisos.indicadorRegVer &&
      this.permisos.indicadorCapturar &&
      this.permisos.asignacionListEdit &&
      this.permisos.asignacionListVer &&
      this.permisos.asignacionRegEdit &&
      this.permisos.asignacionRegVer &&
      this.permisos.asignacionCapturar &&
      this.permisos.actividadesListEdit &&
      this.permisos.actividadesListVer &&
      this.permisos.actividadesRegEdit &&
      this.permisos.actividadesRegVer &&
      this.permisos.catDepartamentosEdit &&
      this.permisos.catDepartamentosVer &&
      this.permisos.catDivisionesEdit &&
      this.permisos.catDivisionesVer &&
      this.permisos.catSucursalesEdit &&
      this.permisos.catSucursalesVer &&
      this.permisos.catColoresEdit &&
      this.permisos.catColoresVer &&
      this.permisos.catServiciosEdit &&
      this.permisos.catServiciosVer &&
      this.permisos.catClienteEdit &&
      this.permisos.catClienteVer &&
      this.permisos.catTipoServicioEdit &&
      this.permisos.catTipoServicioVer &&
      this.permisos.rptCobertura &&
      this.permisos.rptCoberturaDia &&
      this.permisos.rptActividades &&
      this.permisos.rptServicioCop &&
      this.permisos.rptServicioInicia &&
      this.permisos.rptServicioTermina &&
      this.permisos.rptBajaVacantes &&
      this.permisos.rptIndicador &&
      this.permisos.rptIndicadorArea &&
      this.permisos.rptIndicadorUsuario ) {
  this.todo = true;
} else {
  this.todo = false;
}
  }

  filterSeleccionarConsulta() {
    if (  this.permisos.indicadorListVer &&
          this.permisos.indicadorRegVer &&
          this.permisos.asignacionListVer &&
          this.permisos.asignacionRegVer &&
          this.permisos.actividadesListVer &&
          this.permisos.actividadesRegVer &&
          this.permisos.catDepartamentosVer &&
          this.permisos.catDivisionesVer &&
          this.permisos.catSucursalesVer &&
          this.permisos.catColoresVer &&
          this.permisos.catServiciosVer &&
          this.permisos.catClienteVer &&
          this.permisos.catTipoServicioVer &&
          this.permisos.rptActividades &&
          this.permisos.rptServicioCop &&
          this.permisos.rptServicioInicia &&
          this.permisos.rptServicioTermina &&
          this.permisos.rptBajaVacantes &&
          this.permisos.rptIndicador &&
          this.permisos.rptIndicadorArea &&
          this.permisos.rptIndicadorUsuario) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }


  filterSeleccionarRegistro() {
    if (  this.permisos.indicadorListEdit &&
          this.permisos.indicadorRegEdit &&
          this.permisos.indicadorCapturar &&
          this.permisos.asignacionListEdit &&
          this.permisos.asignacionRegEdit &&
          this.permisos.asignacionCapturar &&
          this.permisos.actividadesListEdit &&
          this.permisos.actividadesRegEdit &&
          this.permisos.catDepartamentosEdit &&
          this.permisos.catDivisionesEdit &&
          this.permisos.catSucursalesEdit &&
          this.permisos.catColoresEdit &&
          this.permisos.catServiciosEdit &&
          this.permisos.catClienteEdit &&
          this.permisos.catTipoServicioEdit) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }


  filterSeleccionarReporte() {
    if (  this.permisos.rptActividades &&
          this.permisos.rptServicioCop &&
          this.permisos.rptServicioInicia &&
          this.permisos.rptServicioTermina &&
          this.permisos.rptBajaVacantes &&
          this.permisos.rptIndicador &&
          this.permisos.rptIndicadorArea &&
          this.permisos.rptIndicadorUsuario) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }


  filterSeleccionarCatalogo() {
    if (  this.permisos.catDepartamentosEdit &&
          this.permisos.catDepartamentosVer &&
          this.permisos.catDivisionesEdit &&
          this.permisos.catDivisionesVer &&
          this.permisos.catSucursalesEdit &&
          this.permisos.catSucursalesVer &&
          this.permisos.catColoresEdit &&
          this.permisos.catColoresVer &&
          this.permisos.catServiciosEdit &&
          this.permisos.catServiciosVer &&
          this.permisos.catClienteEdit &&
          this.permisos.catClienteVer  &&
          this.permisos.catTipoServicioEdit &&
          this.permisos.catTipoServicioVer) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarIndicador() {
    if (  this.permisos.indicadorListEdit &&
          this.permisos.indicadorListVer &&
          this.permisos.indicadorRegEdit &&
          this.permisos.indicadorRegVer &&
          this.permisos.indicadorCapturar ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarAsignacion() {
    if (  this.permisos.asignacionListEdit &&
          this.permisos.asignacionListVer &&
          this.permisos.asignacionRegEdit &&
          this.permisos.asignacionRegVer &&
          this.permisos.asignacionCapturar ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarActividad() {
    if (  this.permisos.actividadesListEdit &&
          this.permisos.actividadesListVer &&
          this.permisos.actividadesRegEdit &&
          this.permisos.actividadesRegVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }


}
