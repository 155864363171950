import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReporteDia } from 'src/app/models/reporte-asignacion';
import { Pagination } from 'src/app/models/pagination';
import { Fechas } from 'src/app/models/fechas';
import { DatePipe } from '@angular/common';
import { Identity } from 'src/app/models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import {ExcelService} from 'src/app/services/excel.service';
import * as usuariosActions from 'src/app/store/actions';
import * as moment from 'moment';
@Component({
  selector: 'app-reporte-asignacion-dias',
  templateUrl: './reporte-asignacion-dias.component.html',
  styleUrls: ['./reporte-asignacion-dias.component.sass']
})
export class ReporteAsignacionDiasComponent implements OnInit, OnDestroy {
  heading = 'Resumen de lo capturado en los indicadores operativos';
  subheading = 'Cambia de opción a TNC, Vacantes y Bajas';
  icon = ' pe-7s-note2 icon-gradient bg-happy-fisher';
  public datePipe = new DatePipe("en-US");
  public dates: Date[] = [];
  public anio: string;
  public resultados: ReporteDia[] = [];
  public resultadoTotal: ReporteDia = new ReporteDia (null);
  public paginacion = new Pagination(null);
  public fechas = [];
  public chips = [];
  public removable: boolean;
  public selectable: boolean;
  public loading: boolean;
  public dias: Fechas;
  public filter_tipo: number;
  public identity: Identity;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionReporteAsignacion: Subscription = new Subscription();
  public seriesTnc = [];
  public seriesBaja = [];
  public seriesVacante = [];
  public seriesPorcentaje = [];
  public seriesPieBaja = [];
  public seriesPieVacantes = [];
  public seriesPieTnc = [];
  public seriesPiePorcentaje = [];
  public categories = [];
  public selectionInicio;
  public selectionFin;
  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private excelService: ExcelService,
  ) {
  const fecha = new Date();
  this.removable = true;
  this.selectable = true;
  fecha.setDate(fecha.getDate() - 15);
  this.paginacion.sortType = '-id'
	this.dates = [ fecha,new Date() ];
	this.dias = new Fechas(null);
  this.filter_tipo = 1;
  this.loading = false;
   }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionReporteAsignacion = this.store.select('reporteAsignacion').subscribe(
      (state) => {
        this.resultadoTotal = new ReporteDia (null); 
        this.resultadoTotal.nombre = 'Total';
        let cantidad = 0;
        this.fechas = [];
        this.seriesTnc = [];
        this.seriesBaja = [];
        this.seriesVacante = [];
        this.seriesPorcentaje = [];
        this.resultados = [...state.reporte];
        this.resultados.find(item => {
          if(item.resultado && item.resultado.length > this.fechas.length){
            this.fechas = item.resultado.map( itm => {
              return itm.dia;
            });
          }
          this.seriesTnc.push({name: item.nombre, data : []});
          this.seriesTnc.find( itm =>{
            if(itm.name === item.nombre){
              itm.data = item.resultado.map( it => {
                return it.faltas
              });
            }
          });
          this.seriesBaja.push({name: item.nombre, data : []});
          this.seriesBaja.find( itm =>{
            if(itm.name === item.nombre){
              itm.data = item.resultado.map( it => {
                return it.bajas
              });
            }
          });
          this.seriesVacante.push({name: item.nombre, data : []});
          this.seriesVacante.find( itm =>{
            if(itm.name === item.nombre){
              itm.data = item.resultado.map( it => {
                return it.vacantes
              });
            }
          });
          this.seriesPorcentaje.push({name: item.nombre, data : []});
          this.seriesPorcentaje.find( itm =>{
            if(itm.name === item.nombre){
              itm.data = item.resultado.map( it => {
                return it.porcentaje
              });
            }
          });
        });

        this.fechas.find( item =>{
          this.resultadoTotal.resultado.push({dia: item, faltas: 0, bajas: 0, vacantes: 0, porcentaje: 0, turnos: 0});
        });
         this.resultados.find(item => {
          cantidad = cantidad + 1;
          this.resultadoTotal.bajas = this.resultadoTotal.bajas + item.bajas ;
          this.resultadoTotal.cop = this.resultadoTotal.cop + item.cop;
          this.resultadoTotal.elementos = this.resultadoTotal.elementos + item.elementos;
          this.resultadoTotal.faltas = this.resultadoTotal.faltas + item.faltas;
          this.resultadoTotal.turnos = this.resultadoTotal.turnos + item.turnos;
          this.resultadoTotal.vacantes = this.resultadoTotal.vacantes + item.vacantes;
          item.resultado.find(itm =>{
            this.resultadoTotal.resultado.map( it => {
              if(itm.dia === it.dia){
                it.bajas = it.bajas + itm.bajas;
                it.faltas = it.faltas + itm.faltas;
                it.porcentaje = it.porcentaje + itm.porcentaje;
                it.turnos = it.turnos + itm.turnos;
                it.vacantes = it.vacantes + itm.vacantes;
              }
              return it;
            });
          });
         });
         this.resultadoTotal.cop = (this.resultadoTotal.cop / cantidad);
         this.resultadoTotal.resultado.find(item => {
           item.porcentaje = (item.porcentaje / cantidad);
         });
         this.categories = this.resultados.map(item => {
          return item.nombre 
        });
        this.seriesPieBaja = this.resultados.map(item => {
          return item.bajas 
        });
        this.seriesPieVacantes = this.resultados.map(item => {
          return item.vacantes 
        });
        this.seriesPieTnc = this.resultados.map(item => {
          return item.faltas 
        });
        this.seriesPiePorcentaje = this.resultados.map(item => {
          return item.cop 
        });
      }
    );
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionReporteAsignacion.unsubscribe();
  }

  fecha(fecha:Date[]){
    if(fecha && fecha.length === 2){
      const fecha1= this.datePipe.transform(fecha["0"], 'yyyy-MM-dd');
      const fecha2= this.datePipe.transform(fecha["1"], 'yyyy-MM-dd');
      const anio1 = this.datePipe.transform(fecha["0"], 'yyyy');
      const anio2 = this.datePipe.transform(fecha["1"], 'yyyy');
      
      // const semana = new Date(fecha1);
      // semana.setDate(semana.getDate() + 7);
      // this.selectionInicio = new Date(fecha1).getTime();
      // this.selectionFin  = new Date(fecha1).getTime() + 80000000;


      // const semana = new Date(fecha1);
      // semana.setDate(semana.getDate() + 7);

      this.selectionInicio = new Date(fecha1).getTime() - 21600000;
      // this.selectionFin  = new Date(fecha1).getTime() + (new Date(fecha1).getTime() - semana.getTime());

      // this.selectionFin  = new Date(fecha1).getTime() + 86400000 ;

      this.selectionFin  = new Date(fecha1).getTime() + 604800000 ;
      

      if(anio1 === anio2){
        this.anio = anio1;
      }else{
        this.anio = (anio1 + '-' + anio2);
      }
      this.dias = {fechaInicio: fecha1, fechaFinal: fecha2};
      this.store.dispatch(new usuariosActions.CargarReporteAsignacionesDia(this.dias));
    }
  }
    
   


  async crearExcel() {
    this.loading = true;
    await this.valida().then(
      (resultado: boolean) => {
      let tnc;
      let vacantes;
      let baja;
      let porcentaje;

      tnc = this.resultados.map( item => {
        const obj: any = {};
        obj.DIVISION= item.nombre;
        item.resultado.find(itm => {
          obj[moment(itm.dia).format('DD/MM/YYYY')] = itm.faltas;
        });
        obj.TOTAL_TNCS = item.faltas;
        return obj;
      });

      vacantes = this.resultados.map( item => {
        const obj: any = {};
        obj.DIVISION= item.nombre;
        item.resultado.find(itm => {
          obj[moment(itm.dia).format('DD/MM/YYYY')] = itm.vacantes;
        });
        obj.TOTAL_VACANTES = item.vacantes;
        return obj;
      });

      baja = this.resultados.map( item => {
        const obj: any = {};
        obj.DIVISION= item.nombre;
        item.resultado.find(itm => {
          obj[moment(itm.dia).format('DD/MM/YYYY')] = itm.bajas;
        });
        obj.TOTAL_BAJAS = item.bajas;
        return obj;
      });

      porcentaje = this.resultados.map( item => {
        const obj: any = {};
        obj.DIVISION= item.nombre;
        item.resultado.find(itm => {
          obj[moment(itm.dia).format('DD/MM/YYYY')] = itm.porcentaje;
        });
        obj.COP = item.cop;
        return obj;
      });

      this.excelService.exportAsExcelFileResumenAsignacion(tnc, vacantes, baja, porcentaje, 'Resumen de capturas de indicadores operativos por division');
      this.loading = resultado;
    }, (error) => { }
  );
    
  }


  textSearchChips(){
    if(this.paginacion.textSearch.length > 0){
      this.chips = [...this.paginacion.textSearch.split(' ')];
    }
    
  }

  remove(chips){
    this.chips = [...this.chips.filter(item => item !== chips)];
    this.paginacion.textSearch = this.chips.join(' ');
  }


  valida() {
    const deferred = new Promise((resolve, reject) => {
      if (this.loading) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
    return deferred;
  }

}
