import * as fromUsuario from '../actions';
import { Reporte, Excel } from 'src/app/models/reporte-actividades';
import { Dianolaboral } from 'src/app/models/dianolaboral';

export interface DiaNoLaboralState {
  dias: Dianolaboral[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: DiaNoLaboralState = {
  dias: [],
  loaded: false,
  loading: false,
  error: null
};

export function diaNoLaboralReducer( state = estadoInicial, action: fromUsuario.diaNoLaboralAcciones ): DiaNoLaboralState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_DIAS_NO_LABORABLES:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_DIAS_NO_LABORABLES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        dias: [...action.dianolaboral],
        };
    case fromUsuario.CARGAR_DIAS_NO_LABORABLES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.ACTUALIZA_DIAS_NO_LABORABLES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_DIAS_NO_LABORABLES_SUCCESS:
      const itm = state.dias.find( item => item.id === action.dianolaboral.id);
      if (itm && itm.id) {
        state.dias = state.dias.map( item => {
          if (item.id === action.dianolaboral.id) {
            item = {...action.dianolaboral};
          }
          return item;
        });
      } else {
        state.dias.push(action.dianolaboral);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_DIAS_NO_LABORABLES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ELIMINA_DIAS_NO_LABORABLES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ELIMINA_DIAS_NO_LABORABLES_SUCCESS:
      state.dias = state.dias.filter( itm => itm.id !== action.id)
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ELIMINA_DIAS_NO_LABORABLES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

