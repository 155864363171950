import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { Cliente } from 'src/app/models/clientes';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  obtenerClientes(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/clientes', {headers});
  }

  actualizaEstatusCliente(cliente: Cliente): Observable<any> {
    const param = JSON.stringify({cliente});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/clientes/estatus', param, {headers});
  }

  registraCliente(cliente: Cliente): Observable<any> {
    const param = JSON.stringify({cliente});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/clientes', param, {headers});
  }

  obtenerClientesActivos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
  
    return this.http.get(this.url + '/clientes/activas', {headers});
   }


}
