import { Pipe, PipeTransform } from '@angular/core';
import { ReporteIndicador} from '../models/tablero-kpi';

interface ObjFiltroReporteIndicador {
  indicador: string;
  tipo: string;
}

@Pipe({
  name: 'filtroIndicadorIndicador'
})
export class FiltroIndicadorIndicadorPipe implements PipeTransform {

  transform(value: ReporteIndicador[], args: ObjFiltroReporteIndicador): any {
    if (!value.length) {
      return [];
    }
    if (!args) {
      return value;
    }
    const listaFiltrada = value.filter( (item: ReporteIndicador) => {
      if (  
        (!args.tipo || (args.tipo && item.tipo === args.tipo)) && 
        (!args.indicador || (args.indicador && item.indicador === args.indicador))){
        return true;
      } else {
        return false;
      }
    });
    return listaFiltrada;
  }

}
