import { Component, OnInit } from '@angular/core';
import { UserChangePassword } from 'src/app/models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.sass'],
  providers: []
})
export class RecoveryComponent implements OnInit {

  public title: string;
  public usuario: UserChangePassword;
  private tokenId: string;
  public loading: boolean;

  constructor(
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.usuario = new UserChangePassword(0, '', '', '');
  }

  ngOnInit() {
    this.title = 'Recuperar usuario';
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        if (id && id !== '' && id !== undefined && id != null) {
          this.tokenId = id;
        } else {
          this.router.navigate(['/public/login']);
        }
      }
    );
  }

  validaCampos() {
    const deferred = new Promise( (resolve, reject) => {
      if (!this.usuario.contrasena) {
        // this._alert.warn('La contraseña no es valida');
        // angular.element('#contrasenaUsuario').focus();
        resolve(false);
      } else if (this.usuario.contrasena !== this.usuario.confirmaContrasena) {
        // this._alert.warn('Contraseña no coincide');
        // angular.element('#confirmaContrasenaUsuario').focus();
        resolve(false);
      } else {
        resolve(true);
      }
    });
    return deferred;
  }
  onSubmit(form) {
    this.loading = true;
    this.validaCampos().then(
      (resolve) => {
        this.userService.recoveryPassword(this.usuario.contrasena, this.tokenId).subscribe(
          (respuesta) => {
            this.loading = false;
            if (respuesta.usuario) {
              // this._alert.success('Contraseña actualizada.');
              this.router.navigate(['/public/login']);
            } else {
            // this._alert.warn('Ocurrió un error con la información, le solicitamos vuelva a solicitar la recuperación de su contraseña');
            }
          },
          (error) => {
            if (error.code && error.code < 500) {
              // this._alert.warn(error.message);
            } else {
              // this._alert.error('Ocurrió un error al intentar actualizar contraseña');
            }
            this.loading = false;
          }
        );
      },
      (error) => {
        this.loading = false;
      }
    );
  }
}
