import {Component, OnInit, OnDestroy} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Identity, User } from 'src/app/models/user';
import * as usuariosActions from 'src/app/store/actions';
import { GLOBAL } from 'src/app/models/global';
import { Fechas } from 'src/app/models/fechas';


@Component({
  selector: 'app-dashboard-clientes',
  templateUrl: './dashboard-clientes.component.html',
  styleUrls: ['./dashboard-clientes.component.scss']
})
export class DashboardClientesComponent implements OnInit {
  heading = 'Dashboard';
  subheading = '';
  icon = 'pe-7s-display1 icon-gradient bg-happy-fisher';
  public loading: boolean;
  public identity: Identity;
  public url: string;
  private subscriptionAuth: Subscription = new Subscription();
  private subcriptionReporteAsignacion: Subscription = new Subscription();
  public dias: Fechas;
  public fechas = [];
  public filter_tipo: number;
  public seriesTnc = [];
  public seriesBaja = [];
  public seriesVacante = [];
  public seriesPorcentaje = [];
  public seriesPieBaja = [];
  public seriesPieVacantes = [];
  public seriesPieTnc = [];
  public seriesPiePorcentaje = [];
  public categories = [];
  public selectionInicio;
  public selectionFin;
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {
    this.store.dispatch(new usuariosActions.LimpiarResultados());
    this.identity = new Identity(null);
    this.url = GLOBAL.url;
    this.dias = new Fechas(null);
    this.filter_tipo = 1;
   }

  ngOnInit(): void {
    this.subscriptionAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionReporteAsignacion = this.store.select('reporteAsignacion').subscribe(
      (state) => {
        this.seriesTnc = [];
        this.seriesBaja = [];
        this.seriesVacante = [];
        this.seriesPorcentaje = [];
        state.reporte.find(item => {
          if(item.resultado && item.resultado.length > this.fechas.length){
            this.fechas = item.resultado.map( itm => {
              return itm.dia;
            });
          }
          this.seriesTnc.push({name: item.nombre, data : []});
          this.seriesTnc.find( itm =>{
            if(itm.name === item.nombre){
              itm.data = item.resultado.map( it => {
                return it.faltas
              });
            }
          });
          this.seriesBaja.push({name: item.nombre, data : []});
          this.seriesBaja.find( itm =>{
            if(itm.name === item.nombre){
              itm.data = item.resultado.map( it => {
                return it.bajas
              });
            }
          });
          this.seriesVacante.push({name: item.nombre, data : []});
          this.seriesVacante.find( itm =>{
            if(itm.name === item.nombre){
              itm.data = item.resultado.map( it => {
                return it.vacantes
              });
            }
          });
          this.seriesPorcentaje.push({name: item.nombre, data : []});
          this.seriesPorcentaje.find( itm =>{
            if(itm.name === item.nombre){
              itm.data = item.resultado.map( it => {
                return it.porcentaje
              });
            }
          });
        });

        this.categories = state.reporte.map(item => {
          return item.nombre 
        });
        this.seriesPieBaja = state.reporte.map(item => {
          return item.bajas 
        });
        this.seriesPieVacantes = state.reporte.map(item => {
          return item.vacantes 
        });
        this.seriesPieTnc = state.reporte.map(item => {
          return item.faltas 
        });
        this.seriesPiePorcentaje = state.reporte.map(item => {
          return item.cop 
        });
      }
    );
    this.inicializa();
  }


  inicializa() {
    setTimeout( () => {
      const fecha = new Date();
      fecha.setDate(fecha.getDate() - 15);

      
      this.selectionInicio = new Date(fecha).getTime() - 21600000;
      this.selectionFin  = new Date(fecha).getTime() + 604800000 ;
      this.dias = {fechaInicio: fecha, fechaFinal: new Date()};
      this.store.dispatch(new usuariosActions.CargarReporteAsignacionesDia(this.dias));
    }, 10);
  }



  ngOnDestroy() {
    this.subscriptionAuth.unsubscribe();
    this.subcriptionReporteAsignacion.unsubscribe();
  }

  openActividad(){
    this.router.navigate(['/auth/actividades/register/0']);
  }

  openCaptura(){
    this.router.navigate(['/auth/captura/indicador/administrativo']);
  }

}
