<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <div class="col-md-12" >
    <div class="main-card mb-3 card">
			<div class="card-header">
        <!-- <div class="card-header-title">Actividades</div> -->
					<div class="row">
						<div class="col-md-6">
							<select class="custom-select" id="pageSizeCotizacion" name="pageSizeCotizacion" #pageSizeCotizacion="ngModel" [(ngModel)]="paginacion.pageSize">
								<option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
							</select>
						</div>
						<div class="col-md-6">
							<select class="custom-select mb-2 mr-1" id="mesSelectedCotizacion" name="mesSelectedCotizacion" #mesSelectedCotizacion="ngModel" [(ngModel)]="mesSelected" (change)="changeFecha()">
								<option *ngFor="let opt of months" [value]="opt.id">{{opt.mesCorte}}</option>
							</select>
						</div>
					</div>
        <div class="btn-actions-pane-right mr-4">
          ({{(actividades   | anyScope: paginacion.textSearch).length}} de {{(actividades).length}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchActividad" id="searchActividad" #searchActividad="ngModel" [(ngModel)]="paginacion.textSearch">
                <button class="search-icon" (click)="searchClick()" for="searchActividad"><span></span></button>
              </div>
              <button class="close" (click)="closeSearch()"></button>
            </div>
            <a [routerLink]="['/auth/actividades/register', 0]" [routerLinkActive]="" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3">
              Registrar nuevo
              <span class="pl-2 align-middle opacity-7">
                <i class="fa fa-angle-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
			<div class="card-body">
				<div class="table-responsive d-none d-md-block" >
					<table class="align-middle mb-0 table table-borderless table-striped table-hover">
						<thead>
							<tr>
								<th >Actividad</th>
								<th class="text-center" width="130">Fecha</th>
								<th class="text-center" width="150">Acciones</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let actividad of actividades | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType]  | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize">
								<td class=" text-muted"><span [innerHTML]="actividad.descripcion"></span></td>
								<td class="text-center text-muted">{{actividad.fec_registro | amDateFormat: 'DD/MM/YYYY HH:mm'}}</td>
								<td class="text-center">
									<a [routerLink]="['/auth/actividades/register/', actividad.id]" class="mr-2 btn-wide btn btn-primary" *ngIf="actividad.permiso >= fecha">
										<i class="fa fa-edit mr-1"></i>
										Detalle
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="mt-2">
				<div class="row p-2">
					<div class="col-sm-6">
						<div class="text-left">
							<ngb-pagination
							[collectionSize]="(actividades | anyScope: paginacion.textSearch).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="3">
							<ng-template ngbPaginationFirst>First</ng-template>
							<ng-template ngbPaginationLast>Last</ng-template>
							<ng-template ngbPaginationPrevious>Prev</ng-template>
							<ng-template ngbPaginationNext>Next</ng-template>
							<ng-template ngbPaginationEllipsis>...</ng-template>
							<ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
						</ngb-pagination>
						</div>
					</div>
				</div>
			</div>
    </div>
  </div>
</div>