<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" *ngIf="permiso">
	<div class="col-sm-12 col-md-12 col-lg-6">
		<form #registerForm="ngForm" (ngSubmit)="onSubmit(registerForm)">
			<div class="main-card mb-3 card">
				<div class="card-header">
					Captura la información del actividad
				</div>
				<div class="card-body">
					<fieldset class="form-group required">
						<label  class="control-label">Actividad</label>
						<!-- <textarea type="text" name="descripcion" #descripcion="ngModel" [(ngModel)]="actividad.descripcion" class="form-control" rows="5" placeholder="Descripción" minlength="10" title="Capture la descripcion." required ></textarea>
						<span *ngIf="!descripcion.valid && descripcion.touched"> La descripción es obligatorio</span> -->
						<angular-editor  name="descripcion" #descripcion="ngModel" [(ngModel)]="actividad.descripcion"  [config]="config"></angular-editor>
						<span *ngIf="!descripcion.valid && descripcion.touched"> La descripción es obligatorio</span>
					</fieldset>
				</div>
				<div class="grid-menu grid-menu-2col border-top">
					<div class="no-gutters row">
						<div class="col-sm-6" *ngIf="identity.permisos.actividadesListVer">
							<a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger" [routerLink]="['/auth/actividades']">
								<i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
							</a>
						</div>
						<div class="col-sm-6" *ngIf="identity.permisos && identity.permisos.actividadesRegEdit">
							<button type="submit" class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" *ngIf="!loading" [disabled]="!registerForm.form.valid || !identity.permisos.actividadesRegEdit || !actividad.descripcion || (actividad | json) === (actividadInicial | json)">
								<i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
							</button>
							<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="loading">
								<span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
								<br>
								Loading...
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>

	<div class="col-lg-6">
		<div class="main-card card">
			<div class="card-header">
				<div class="card-header-title">Registro de adjuntos</div>
			</div>
			<div class="card-body">
				<div class="form-group">
					<div class="row">
						<div class="col-sm-3 col-md-3 col-lg-3">
							<label for="nombreAnexo" class="control-label">Nombre</label>
						</div>
						<div class="col-sm-9 col-md-9 col-lg-9">
							<input type="text" class="form-control" id="nombreAnexo" name="nombreAnexo" #nombreAnexo="ngModel" [(ngModel)]="adjunto.nombre">
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<div class="col-sm-3 col-md-3 col-lg-3">
							<label for="fileAnexo" class="control-label">Subir archivo</label>
						</div>
						<div class="col-sm-9 col-md-9 col-lg-9">
							<div class="box box-file">
								<input type="file" id="fileArchivo" name="fileAdjunto" #fileArchivo="ngModel" [(ngModel)]="textFile" class="inputfile avatar-file" (change)="fileChangeEvent($event)" accept="image/jpeg,image/gif,image/png,application/pdf" title="Seleccione un archivo tipo imagen o pdf como evidencia." data-max-size="2048"/>
								<label for="fileArchivo"><span class="fa fa-file"></span><span *ngIf="!textFile"> Elige un archivo&hellip;</span><span *ngIf="textFile"> {{textFile}}</span></label>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="ml-auto mr-2 mb-2">
						<button type="button" class="btn-icon btn btn-primary btn-flat" (click)="addFile()" [disabled]="!adjunto.nombre || !textFile || !identity.permisos.actividadesRegEdit">
							<span class="pr-2 align-middle opacity-7">
								<i class="far fa-save"></i>
							</span>
							Agregar archivo
						</button>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 attachment-mail">
						<span class="help-block">Información de los archivos adjuntos registrados.</span>
						<h2 class="empty-content" *ngIf="!archivos.length">Sin Archivos</h2>


						<div class="row">
							<div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 col-10 mb-3" *ngFor="let arc of archivos; let i= index">
								<div class="card" style="background-color: #ebebeb;">
									<div id="over" class="gallery-list">
										<img *ngIf="arc.tipo != 'pdf'" [src]="url + '/actividades/' + actividadId + '/anexos/' + arc.nameFile" [alt]="arc.nombre" (click)="openLarge(arc, content)" class="thumbnail-gallery">
										<img *ngIf="arc.tipo == 'pdf'" src="assets/images/actividades/pdf.png" [alt]="arc.nombre" (click)="openLarge(arc,content)" class="thumbnail-gallery">
										<div class="btn-transition only-hover icon-helper" ngbDropdown  placement="left-top">
											<i type="button" ngbDropdownToggle class="fa fa-ellipsis-h"></i>
											<div ngbDropdownMenu class="dropdown-menu-hover-link dropdown-menu-left-rounded">
												<a class="dropdown-item" [href]="url + '/actividades/' + actividadId + '/anexosdown/' + arc.nameFile"><span>Descargar</span></a>
												<button class="dropdown-item" (click)="deleteFile(arc)" *ngIf="identity.permisos"><span>Eliminar</span></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- <ul class="mb-1">
							<li *ngFor="let arc of archivos">
								<div class="card m-3" style="width: 18rem;">
									<span class=" atch-thumb text-center" [ngClass]="{'has-img': arc.tipo != 'pdf'}">
										<img *ngIf="arc.tipo != 'pdf'" [src]="url + '/actividades/' + actividadId + '/anexos/' + arc.nameFile" [alt]="arc.nombre" class="card-img-top" >
										<img *ngIf="arc.tipo == 'pdf'" src="assets/images/actividades/pdf.png" alt="arc.nombre" class="card-img-top" >
									</span>
									<ng-template #content6 let-c="close" let-d="dismiss">
										<div class="modal-header">
											<h4 class="modal-title">{{arc.nombre + '.' + arc.tipo}}</h4>
											<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img *ngIf="arc.tipo != 'pdf'" [src]="url + '/actividades/' + actividadId + '/anexos/' + arc.nameFile" [alt]="arc.nombre"  class="img-rounded" alt="Cinque Terre" width="700" height="600">
											<ngx-extended-pdf-viewer *ngIf="arc.tipo == 'pdf'"[src]="url + '/actividades/' + actividadId + '/anexos/' + arc.nameFile" [useBrowserLocale]="false" height="80vh"></ngx-extended-pdf-viewer>
										</div>
										<div class="modal-footer">
											<button type="button" class="btn btn-secondary" (click)="c('Close click')">Cerrar</button>
										</div>
									</ng-template>
									<div class="card-body">
										<div class=" name">
											<button class="mb-2 mr-2 border-0 btn-transition btn btn-outline-link" (click)="openLarge(content6)">{{arc.nombre + '.' + arc.tipo}}</button>
										</div>
										<div class=" link pull-left">
											<a [href]="url + '/actividades/' + actividadId + '/anexosdown/' + arc.nameFile" class="btn btn-default btn-xs pull-right" download="{{arc.nombre}}.{{arc.tipo}}">
												<i class="ion ion-md-download"> </i>
											</a>
											<button type="button" class="mr-2 btn-icon btn-icon-only btn btn-outline-danger" (click)="deleteFile(arc)">
												<i class="pe-7s-trash btn-icon-wrapper"> </i>
												<span class="sr-only">Eliminar</span>
											</button> 
										</div>
									</div>
								</div>
							</li>
						</ul> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="mt-3" *ngIf="!permiso">
	<div class="col-12">
		<div class="main-card mb-3 card">
			<div class="card-header">
				<h3>Se acabo el tiempo</h3>
			</div>
		</div>
	</div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{adjuntoModal.nombre + '.' + adjuntoModal.tipo}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body text-center">
		<img *ngIf="adjuntoModal.tipo != 'pdf'" [src]="url + '/actividades/' + actividadId + '/anexos/' + adjuntoModal.nameFile" [alt]="adjuntoModal.nombre"  class="img-rounded" alt="Cinque Terre" width="700" height="600">
		<ngx-extended-pdf-viewer *ngIf="adjuntoModal.tipo == 'pdf'"[src]="url + '/actividades/' + actividadId + '/anexos/' + adjuntoModal.nameFile" [useBrowserLocale]="false" height="80vh"></ngx-extended-pdf-viewer>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="c('Close click')">Cerrar</button>
	</div>
</ng-template>

