import { AuthEffects } from './auth.effects';
import { UsuariosEffects } from './usuarios.effects';
import { UsuarioEffects } from './usuario.effects';
import { CatalogoEffects } from './catalogos.effects';
import { AsignacionEffects } from './asignaciones.effects';
import { DireccionEffects } from './direccion.effects';
import { DivisionEffects } from './divisiones.effects';
import { SucursalesEffects } from './sucursales.effects';
import { ClienteEffects } from './clientes.effects';
import { ServicioEffects } from './servicios.effects';
import { TipoServicioEffects } from './tipo-servicio.effects';
import { TipoSolicitudEffects } from './tipo-solicitud.effects';
import { IndicadoresEffects } from './indicadores.effects';
import { TableroKpiEffects } from './tablero-kpi.effects';
import { CapturasEffects } from './captura-resultado-indicador.effects';
import { ColoresEffects } from './colores.effects';
import { AreasEffects } from './areas.effects';
import { CumplimientoEffects } from './cumplimineto.effects';
import { DiaNoLaboralEffects } from './dia-no-laboral.effects';
import { CapturasAsignacionEffects } from './captura-asignacion.effects';
import { ReporteAsignacionEffects } from './reporte-asignacion.effects';
import { EmpresaEffects } from './empresa.effects';
import { IndustriaEffects } from './industria.effects';
import { OrigenContactoEffects } from './origen-contacto.effects';
import { PaisEffects } from './paises.effects';
import { ConsultarEffects } from './consultas.effects';
import { ContactoEffects } from './contacto.effects';
import { OportunidadEffects } from './oportunidad.effects';
import { ActividadesArchivosEffects } from './actividades-archivos.effects';
import { ActividadesMultipleEffects } from './actividades-multiples.effects';
import { ReportesEffects } from './reportes.effects';
import { ReporteKPIsVendedoresEffects } from './reporte-kpis-vendedores.effects';
import { KPIsVendedoresEffects } from './kpis-vendedores.effects';
import { TicketEffects } from './tickets.effects';
import { ConsultarCatalogosEffects } from './consulta-catalogos.effects';
import { DashboardEffects } from './dashboard.effects';

export const effectsArr: any[] = [ AuthEffects,
    UsuariosEffects,
    UsuarioEffects,
    CatalogoEffects,
    AsignacionEffects,
    DireccionEffects,
    DivisionEffects,
    SucursalesEffects,
    ClienteEffects,
    ServicioEffects,
    TipoServicioEffects,
    TipoSolicitudEffects,
    IndicadoresEffects,
    TableroKpiEffects,
    CapturasEffects,
    ColoresEffects,
    AreasEffects,
    CumplimientoEffects,
    DiaNoLaboralEffects,
    CapturasAsignacionEffects,
    ReporteAsignacionEffects,
    EmpresaEffects,
    IndustriaEffects,
    OrigenContactoEffects,
    PaisEffects,
    ConsultarEffects,
    ContactoEffects,
    OportunidadEffects,
    ActividadesArchivosEffects,
    ActividadesMultipleEffects,
    ReportesEffects,
    ReporteKPIsVendedoresEffects,
    KPIsVendedoresEffects,
    TicketEffects,
    ConsultarCatalogosEffects,
    DashboardEffects
];

export * from './auth.effects';
export * from './usuarios.effects';
export * from './usuario.effects';
export * from './catalogos.effects';
export * from './asignaciones.effects';
export * from './direccion.effects';
export * from './divisiones.effects';
export * from './sucursales.effects';
export * from './clientes.effects';
export * from './servicios.effects';
export * from './tipo-servicio.effects';
export * from './tipo-solicitud.effects';
export * from './indicadores.effects';
export * from './tablero-kpi.effects';
export * from './captura-resultado-indicador.effects';
export * from './colores.effects';
export * from './areas.effects';
export * from './cumplimineto.effects';
export * from './dia-no-laboral.effects';
export * from './captura-asignacion.effects';
export * from './reporte-asignacion.effects';
export * from './empresa.effects';
export * from './industria.effects';
export * from './origen-contacto.effects';
export * from './paises.effects';
export * from './consultas.effects';
export * from './contacto.effects';
export * from './oportunidad.effects';
export * from './actividades-archivos.effects';
export * from './actividades-multiples.effects';
export * from './kpis-vendedores.effects';
export * from './reporte-kpis-vendedores.effects';
export * from './reportes.effects';
export * from './tickets.effects';
export * from './consulta-catalogos.effects';
export * from './dashboard.effects';