<div class="col-12 mt-3">
	<div class="form-group">
		<div class="row">
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 1}" (click)="filterPermisos = 1; filterSelecionar()" title="Ver y editar todos los permisos">TODOS</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 2}" (click)="filterPermisos = 2; filterSelecionar()" title="Ver y editar todos los permisos de consulta">Consulta</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 3}" (click)="filterPermisos = 3; filterSelecionar()" title="Ver y editar todos los permisosd de registros">Registros</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 4}" (click)="filterPermisos = 4; filterSelecionar()" title="Ver y editar empresas">Empresas</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 5}" (click)="filterPermisos = 5; filterSelecionar()" title="Ver y editar oportunidades">Oportunidades</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 6}" (click)="filterPermisos = 6; filterSelecionar()" title="Ver los reportes">Reportes</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 7}" (click)="filterPermisos = 7; filterSelecionar()" title="Ver y editar contactos">Contactos</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 9}" (click)="filterPermisos = 9; filterSelecionar()" title="Configuración de los catálogos de países, origenes de contactos, industrias, tipos de empresas y tipos de servicios">Catálogos</button>
			</div>
		</div>
	</div>
</div>
<ngb-tabset class="tabs-animated tabs-animated-line" justify="justified">
	<ngb-tab>
		<ng-template ngbTabTitle><span>Permisos</span></ng-template>
		<ng-template ngbTabContent>
			<ul class="todo-list-wrapper list-group list-group-flush">
				<li class="list-group-item">
					<div class="widget-content p-0">
						<div class="widget-content-wrapper">
							<div class="widget-content-left mr-2">
								<div class="custom-checkbox custom-control">
									<input type="checkbox" id="allPermisosForm" name="allPermisosForm" #allPermisosForm="ngModel" [(ngModel)]="todo" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="seleccionar()">
									<label class="custom-control-label" for="allPermisosForm">&nbsp; Seleccionar todo</label>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<hr class="p-0 m-0">
			<div class="scroll-area-lg">
				<div class="scrollbar-container">
					<perfect-scrollbar [autoPropagation]="true">
						<ul class="todo-list-wrapper list-group list-group-flush">
							<!-- 4 - Empresas -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormEmpresasListEdit" name="permisoFormEmpresasListEdit" #permisoFormEmpresasListEdit="ngModel" [(ngModel)]="permisos.empresasListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormEmpresasListEdit">&nbsp; Editar lista de empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormempresasListVer" name="permisoFormempresasListVer" #permisoFormempresasListVer="ngModel" [(ngModel)]="permisos.empresasListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormempresasListVer">&nbsp; Ver lista de empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormempresasRegEdit" name="permisoFormempresasRegEdit" #permisoFormempresasRegEdit="ngModel" [(ngModel)]="permisos.empresasRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormempresasRegEdit">&nbsp; Editar detalle de empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormempresasRegVer" name="permisoFormempresasRegVer" #permisoFormempresasRegVer="ngModel" [(ngModel)]="permisos.empresasRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormempresasRegVer">&nbsp; Ver detalle de empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormEmpresasDownload" name="permisoFormEmpresasDownload" #permisoFormEmpresasDownload="ngModel" [(ngModel)]="permisos.empresasDownload" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormEmpresasDownload">&nbsp; Descargar empresas en XLSX</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 7 - Contactos -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 7 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormcontactosListEdit" name="permisoFormcontactosListEdit" #permisoFormcontactosListEdit="ngModel" [(ngModel)]="permisos.contactosListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormcontactosListEdit">&nbsp; Editar lista de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 7 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormcontactosListVer" name="permisoFormcontactosListVer" #permisoFormcontactosListVer="ngModel" [(ngModel)]="permisos.contactosListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormcontactosListVer">&nbsp; Ver lista de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 7 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormcontactosRegEdit" name="permisoFormcontactosRegEdit" #permisoFormcontactosRegEdit="ngModel" [(ngModel)]="permisos.contactosRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormcontactosRegEdit">&nbsp; Editar detalle de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 7 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormcontactosRegVer" name="permisoFormcontactosRegVer" #permisoFormcontactosRegVer="ngModel" [(ngModel)]="permisos.contactosRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormcontactosRegVer">&nbsp; Ver detalle de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 7 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormContactosDownload" name="permisoFormContactosDownload" #permisoFormContactosDownload="ngModel" [(ngModel)]="permisos.contactosDownload" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormContactosDownload">&nbsp; Descargar contactos en XLSX</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 5 - Oportunidades -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 5 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormoportunidadesListEdit" name="permisoFormoportunidadesListEdit" #permisoFormoportunidadesListEdit="ngModel" [(ngModel)]="permisos.oportunidadesListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormoportunidadesListEdit">&nbsp; Editar lista de oportunidades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 5 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormoportunidadesListVer" name="permisoFormoportunidadesListVer" #permisoFormoportunidadesListVer="ngModel" [(ngModel)]="permisos.oportunidadesListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormoportunidadesListVer">&nbsp; Ver lista de oportunidades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 5 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormoportunidadesRegEdit" name="permisoFormoportunidadesRegEdit" #permisoFormoportunidadesRegEdit="ngModel" [(ngModel)]="permisos.oportunidadesRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormoportunidadesRegEdit">&nbsp; Editar detalle de oportunidades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 5 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormoportunidadesRegVer" name="permisoFormoportunidadesRegVer" #permisoFormoportunidadesRegVer="ngModel" [(ngModel)]="permisos.oportunidadesRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormoportunidadesRegVer">&nbsp; Ver detalle de oportunidades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 5 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormOportunidadesDownload" name="permisoFormOportunidadesDownload" #permisoFormOportunidadesDownload="ngModel" [(ngModel)]="permisos.oportunidadesDownload" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormOportunidadesDownload">&nbsp; Descargar oportunidades en XLSX</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 5 || filterPermisos === 7 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesMultipleVer" name="permisoFormActividadesMultipleVer" #permisoFormActividadesMultipleVer="ngModel" [(ngModel)]="permisos.actividadesMultipleVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesMultipleVer">&nbsp; Ver lista de actividades de contactos, oportunidades y empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 5 || filterPermisos === 7 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesMultipleEdit" name="permisoFormActividadesMultipleEdit" #permisoFormActividadesMultipleEdit="ngModel" [(ngModel)]="permisos.actividadesMultipleEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesMultipleEdit">&nbsp; Editar detalle de actividades de contactos, oportunidades y empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 9 - Catálogos -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormOrigenesContactoRegEdit" name="permisoFormOrigenesContactoRegEdit" #permisoFormOrigenesContactoRegEdit="ngModel" [(ngModel)]="permisos.catOrigenesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormOrigenesContactoRegEdit">&nbsp; Editar detalle del catálogo orígenes de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormOrigenesContactoVer" name="permisoFormOrigenesContactoVer" #permisoFormOrigenesContactoVer="ngModel" [(ngModel)]="permisos.catOrigenesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormOrigenesContactoVer">&nbsp; Ver detalle del catálogo orígenes de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormIndustriasRegEdit" name="permisoFormIndustriasRegEdit" #permisoFormIndustriasRegEdit="ngModel" [(ngModel)]="permisos.catIndustriasEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormIndustriasRegEdit">&nbsp; Editar detalle del catálogo industrias</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormIndustriasVer" name="permisoFormIndustriasVer" #permisoFormIndustriasVer="ngModel" [(ngModel)]="permisos.catIndustriasVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormIndustriasVer">&nbsp; Ver detalle del catálogo industrias</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormPaisesRegEdit" name="permisoFormPaisesRegEdit" #permisoFormPaisesRegEdit="ngModel" [(ngModel)]="permisos.catPaisesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormPaisesRegEdit">&nbsp; Editar detalle del catálogo países</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormPaisesVer" name="permisoFormPaisesVer" #permisoFormPaisesVer="ngModel" [(ngModel)]="permisos.catPaisesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormPaisesVer">&nbsp; Ver detalle del catálogo países</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 6 - Reportes -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptKpisVendedoresVer" name="permisoFormRptKpisVendedoresVer" #permisoFormRptKpisVendedoresVer="ngModel" [(ngModel)]="permisos.rptKpiVendedores" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptKpisVendedoresVer">&nbsp; Ver reporte de KPIs de vendedores</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptCumpleanios" name="permisoFormRptCumpleanios" #permisoFormRptCumpleanios="ngModel" [(ngModel)]="permisos.rptCumpleannos" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptCumpleanios">&nbsp; Ver reporte de cumpleaños</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptOportunidadesXEtapas" name="permisoFormRptOportunidadesXEtapas" #permisoFormRptOportunidadesXEtapas="ngModel" [(ngModel)]="permisos.rptOportunidadEtapas" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptOportunidadesXEtapas">&nbsp; Ver reporte de oportunidades por etapas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptOportunidadesXPropietario" name="permisoFormRptOportunidadesXPropietario" #permisoFormRptOportunidadesXPropietario="ngModel" [(ngModel)]="permisos.rptOportunidadPropietarios" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptOportunidadesXPropietario">&nbsp; Ver reporte de oportunidades por propietarios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</perfect-scrollbar>
				</div>
			</div>
		</ng-template>
	</ngb-tab>
</ngb-tabset>