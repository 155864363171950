import {Component, OnInit, OnDestroy} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.reducer';
import * as usuariosActions from 'src/app/store/actions';
import { Subscription } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'Proyecto SMIE';
  subscrition: Subscription = new Subscription();
  public Editor = ClassicEditor;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.store.dispatch(new usuariosActions.GetAuthAction());
  }

  ngOnDestroy() {
    this.subscrition.unsubscribe();
  }

}
