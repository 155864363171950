import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mimeImages'
})
export class MimeImagesPipe implements PipeTransform {

  transform(mimeType: string): string {
    return this.getImageMIME(mimeType);
  }

  getImageMIME(mimeType: string) {
    // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
    const path = '/assets/images/tipos-archivos/';
    const iconClasses = {
      // Media
      'image/png': 'tipo-png.png',
      'image/jpeg': 'tipo-jpg.png',
      'image/gif': 'tipo-gif.png',
      'image/jpg': 'tipo-jpg.png',
      'image/ico': 'tipo-ico.png',
      'image/tif': 'tipo-tif.png',
      'image/svg': 'tipo-svf.png',

      'audio/mp3': 'tipo-mp3',
      'audio/wav': 'tipo-wav',
      'audio/ogg': 'tipo-ogg',

      'video/mp4': 'tipo-mp4',
      'video/mov': 'tipo-mov',
      'video/avi': 'tipo-avi',
      'video/flv': 'tipo-flv',
      'video/mkv': 'tipo-mkv',
      // Documents
      'application/pdf': 'tipo-pdf.png',
      'application/msword': 'tipo-doc.png',
      'application/vnd.ms-word': 'tipo-doc.png',
      'application/vnd.oasis.opendocument.text': 'tipo-doc.png',
      'application/vnd.openxmlformatsfficedocument.wordprocessingml': 'tipo-doc.png',
      'application/vnd.ms-excel': 'tipo-xls.png',
      'application/vnd.openxmlformatsfficedocument.spreadsheetml': 'tipo-xls.png',
      'application/vnd.oasis.opendocument.spreadsheet': 'tipo-xls.png',
      'application/vnd.ms-powerpoint': 'tipo-ppt.png',
      'application/vnd.openxmlformatsfficedocument.presentationml':'tipo-ppt.png',
      'application/vnd.oasis.opendocument.presentation': 'tipo-ppt.png',
      'text/plain': 'tipo-txt.png',
      'text/xml': 'tipo-txt.png',
      // 'text/html': 'tipo-',
      // 'application/json': 'tipo-txt.png',
      'application/vnd.openxmlformats-officedocument.wordprocessingml': 'tipo-doc.png',
      'application/vnd.openxmlformats-officedocument.spreadsheetml': 'tipo-xls.png',
      'application/vnd.openxmlformats-officedocument.presentationml': 'tipo-ppt.png',
      // Archives
      'application/gzip': 'tipo-zip.png',
      'application/x-gzip': 'tipo-zip.png',
      'application/zip': 'tipo-zip.png',
      'application/x-zip-compressed': 'tipo-zip.png',
      'application/rar': 'tipo-rar.png',
      'application/7z': 'tipo-7z.png',

      // Otros
      psd: 'tipo-psd.png',
      ai: 'tipo-ai.png',
      apk: 'tipo-apk.png',
      bin: 'tipo-bin.png',
      cdr: 'tipo-cdr.png',
      eps: 'tipo-eps.png',
      ipa: 'tipo-ipa.png'
    };

    if (mimeType) {
      for (const key in iconClasses) {
        if (iconClasses.hasOwnProperty(key)) {
          if (mimeType.search(key) === 0) {
            return path + iconClasses[key];
          }
        } else {
          return path + 'tipo-default.png';
        }
      }
    }
    return path + 'tipo-default.png';
  }

}
