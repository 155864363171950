import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { Fechas } from 'src/app/models/fechas';

@Injectable({
  providedIn: 'root'
})
export class ReporteAsignacionService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  obtenerReporteAsignacionDia(dias: Fechas): Observable<any> {
    const params = JSON.stringify({dias});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/reporte/asignacion/dia', params, {headers});
  }


  obtenerReporteCoberturaOperacional(dias: Fechas): Observable<any> {
    const params = JSON.stringify({dias});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/reporte/cobertura/operacional', params, {headers});
  }


  obtenerReporteCoberturaOperacionalServicio(dias: Fechas): Observable<any> {
    const params = JSON.stringify({dias});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/reporte/cobertura/operacional/servicio', params, {headers});
  }

  obtenerReporteServicioInicia(dias: Fechas): Observable<any> {
    const params = JSON.stringify({dias});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/reporte/servicio/inicia', params, {headers});
  }

  obtenerReporteServicioFinaliza(dias: Fechas): Observable<any> {
    const params = JSON.stringify({dias});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/reporte/servicio/finaliza', params, {headers});
  }

  obtenerReporteBajasVacantes(dias: Fechas): Observable<any> {
    const params = JSON.stringify({dias});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/reporte/baja/vacante', params, {headers});
  }



}