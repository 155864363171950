<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" *ngIf="identity.permisos">
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="p-3">
          <div class="form-group">
            <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="" class="control-label">Ordenar por</label>
            <select class="form-control custom-select" name="sortTypeProducto" #sortTypeProducto="ngModel" [(ngModel)]="paginacion.sortType">
              <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9" [ngClass]="{'col-md-12': !filterActive }">
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="card-header-title">Usuarios Activos</div>
        <div class="btn-actions-pane-right mr-4">
          ({{(users | anyScope: paginacion.textSearch).length}} de {{(users).length}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchUser" id="searchUser" #searchUser="ngModel" [(ngModel)]="paginacion.textSearch">
                <button class="search-icon" (click)="searchClick()" for="searchUser"><span></span></button>
              </div>
              <button class="close" (click)="closeSearch()"></button>
            </div>
            <a *ngIf="identity.permisos.contactosRegEdit" [routerLink]="['/auth/users/register', 0]" [routerLinkActive]="" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3">
              Registrar nuevo
              <span class="pl-2 align-middle opacity-7">
                <i class="fa fa-angle-right"></i>
              </span>
            </a>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="card-body p-0">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let user of users | anyScope: paginacion.textSearch 
                                                                | orderBy: [paginacion.sortType]
                                                                | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize
                                                                ; let i = index" (dblclick)="editarUsuario(i, user)" title="Presione clic sobre nombre de usuario para ver y editar información de usuario.">
            <div class="no-gutters row">
              <div class="col-sm-6 col-md-4 col-xl-4">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-3">#{{user.id}}</div>
                      <div class="widget-content-left mr-3">
                        <!-- <img width="40" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + user.id + '/imagenes'" alt="Imagen perfil de usuario"> -->
                        <div style="position: relative">
                          <img width="40" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + user.id + '/imagenes'" alt="Imagen perfil de usuario">
                          <div style="position: absolute; top: 0; left: 0;" *ngIf="user.status !== 1">
                            <img  src="assets/images/ban-3.png" width="300" height="300" />
                          </div>
                        </div>                        
                      </div>
                      <div class="widget-content-left">
                        <div class="widget-heading" [ngClass]="{'text-danger': user.status !== 1}">{{user.nombre + ' ' + user.apellidoPaterno + ' ' + user.apellidoMaterno }}</div>
                        <div class="widget-subheading"><b>{{user.desTipo}}</b></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-xl-3">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                      <div class="widget-heading">Email:  {{user.email}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-xl-3">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                      <div class="widget-heading">Área(s)</div>
                      <div class="widget-description opacity-8 text-focus">
                        <ul *ngIf="user.departamento.length">
                          <li *ngFor="let area of user.departamento">
                            {{area.nombre}}
                          </li>
                        </ul>
                        <ul *ngIf="!user.departamento.length">
                          <li>
                            Sin área asignada 
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-2 col-xl-2">
                <div class="d-inline-block" ngbDropdown placement="left">
                  <button type="button" class="btn-pill btn btn-outline-primary" ngbDropdownToggle>
                    Acciones
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu-xl">
                    <div class="dropdown-menu-header">
                      <div class="dropdown-menu-header-inner bg-sunny-morning">
                        <div class="menu-header-content text-dark">
                          <h5 class="menu-header-title">ACCIONES</h5>
                        </div>
                      </div>
                    </div>
                    <div class="grid-menu grid-menu-xl grid-menu-3col">
                      <div class="no-gutters row">
                        <div class="col-sm-6 col-xl-4" *ngIf="identity.permisos.usuariosRegVer || identity.permisos.usuariosRegEdit">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary" [routerLink]="['/auth/users/register', user.id]" title="Editar usuario">
                            <i class="fa fa-edit btn-icon-wrapper btn-icon-lg mb-3"> </i> Editar
                          </button>
                        </div>
                        <div class="col-sm-6 col-xl-4" *ngIf="identity.permisos.usuariosListEdit">
                          <button class="btn-icon-vertical btn-square btn-transition btn"  [ngClass]="{'btn-outline-secondary': user.status === 2, 'btn-outline-success': user.status === 1}" (click)="statusUsuario(user)" title="Presione para activar/supender la user.">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 "[ngClass]="{'fa-ban': user.status === 2, 'fa-check': user.status === 1}">  </i> {{ (user.status === 1 ? 'Activo' : 'Suspendido') }}
                          </button>
                        </div>
                        <div class="col-sm-6 col-xl-4" *ngIf="identity.permisos.usuariosListEdit">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-danger" (click)="eliminarUsuario(user)"  title="Presione para eliminar el usuario.">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 fa-trash-alt"></i> Eliminar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="card no-shadow bg-transparent text-left">
              <div class="widget-chart-content">
                <ngb-pagination class="mb-2" [boundaryLinks]="true"
                  [collectionSize]="(users  | anyScope: paginacion.textSearch).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                  <ng-template ngbPaginationFirst>First</ng-template>
                  <ng-template ngbPaginationLast>Last</ng-template>
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


