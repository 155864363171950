<app-page-title [heading]="title" [subheading]="'Listado de asignaciones registrados en el sistema'" [icon]="'pe-7s-check icon-gradient bg-dark'"></app-page-title>
<div class="row">
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card mb-3">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">  
        <div class="p-3">
          <div class="form-group">
            <label for="pageSizeVehiculo">Mostrar por hoja</label>
            <select class="custom-select" id="pageSizeVehiculo" name="pageSizeVehiculo" #pageSizeVehiculo="ngModel" [(ngModel)]="paginacion.pageSize">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="sortTypeVehiculos">Ordenar por</label>
            <select class="custom-select mb-2 mr-1" id="sortTypeVehiculos" name="sortTypeVehiculos" #sortTypeVehiculos="ngModel" [(ngModel)]="paginacion.sortType">
              <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9" [ngClass]="{'col-lg-12': !filterActive }">
    <div class="main-card mb-3 card card-shadow-primary">
      <!-- <div class="card-header">
        <div class="card-header-title">
          Listado actual
        </div>
        <div class="btn-actions-pane-left ml-3">
          <div class="search-wrapper" [class.active]="paginacion.isActive">
            <div class="input-holder">
              <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchAsignacion" id="searchAsignacion" #searchAsignacion="ngModel" [(ngModel)]="paginacion.textSearch">
              <button class="search-icon" (click)="paginacion.isActive = true"><span></span></button>
            </div>
            <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = '';"></button>
          </div>
        </div>
        <div class="btn-actions-pane-right">
          ({{(asignaciones | anyScope: paginacion.textSearch | filterParamInObject: [{search: usuarioSearch, param: 'usuarioModifico'}, {search: usuarioSearch, param: 'usuarioRegistro'}] ).length}} de {{(asignaciones).length}})
          <div class="btn-group ml-2">
            <button type="button" *ngIf="identity.permisos.asignacionListEdit" [routerLink]="['/auth/asignacion/register', 0]" [routerLinkActive]="" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-2 mt-2">
              Registrar nuevo
              <span class="pl-2 align-middle opacity-7">
                <fa name="angle-right"></fa>
              </span>
            </button>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div> -->
      <div class="card-header">
        <div class="card-header-title">Listado actual</div>
        <div class="btn-actions-pane-right mr-4">
          ({{(asignaciones  | anyScope: paginacion.textSearch 
                            | filterParamInObject: [{search: usuarioSearch, param: 'usuarioModifico'}, {search: usuarioSearch, param: 'usuarioRegistro'}] ).length}} de {{(asignaciones).length}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchAsignacion" id="searchAsignacion" #searchAsignacion="ngModel" [(ngModel)]="paginacion.textSearch">
                <button class="search-icon" (click)="searchClick()" for="searchAsignacion"><span></span></button>
              </div>
              <button class="close" (click)="closeSearch()"></button>
            </div>
            <a *ngIf="identity.permisos.asignacionRegVer" [routerLink]="['/auth/asignacion/register', 0]" [routerLinkActive]="" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3">
              Registrar nuevo
              <span class="pl-2 align-middle opacity-7">
                <i class="fa fa-angle-right"></i>
              </span>
            </a>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>


      <div class="card-body p-0">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let asignacion of asignaciones  | anyScope: paginacion.textSearch
                                                                              | filterParamInObject: [{search: usuarioSearch, param: 'usuarioModifico'}, {search: usuarioSearch, param: 'usuarioRegistro'}] 
                                                                              | orderBy: [paginacion.sortType] 
                                                                              | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize">
            <div class="no-gutters row">
              <div class="col-sm-6 col-md-4 col-xl-4">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-3">#{{asignacion.id}}</div>
                      <div class="widget-content-left mr-3">
                        <i *ngIf="asignacion.estatus" class="rounded fas fa-handshake fa-2x text-success"
                        [ngClass]="{'text-warning': asignacion.fecTermino < fecha && !asignacion.opcFin}"></i>
                        <i *ngIf="!asignacion.estatus" class="rounded fas fa-handshake-slash fa-2x text-success"
                        [ngClass]="{'text-warning': asignacion.fecTermino < fecha && !asignacion.opcFin }"></i>
                      </div>
                      <div class="widget-content-left">
                        <div class="widget-heading">{{asignacion.numeroOrden}}</div>
                        <div class="widget-subheading"><b>Cliente: {{asignacion.desCliente}}</b></div>
                        <div class="widget-description text-muted"> 
                          <span>Servicio: {{asignacion.desServicio}}<br></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-xl-3">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                      <div class="widget-heading">Localidad:  {{asignacion.localidad}}</div>
                      <div class="widget-subheading"><b>Sucursal: {{asignacion.desSucursal}}</b></div>
                      <div class="widget-description text-muted"> 
                        <span>Tipo de solicitud: {{asignacion.desTipoSolicitud}}<br></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-xl-3">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                      <div class="widget-heading">Tipo de servicio: {{asignacion.desTipoServicio}}</div>
                      <div class="widget-description opacity-8 text-focus">
                        <i class="ml-1 text-muted">
                          Inicio: {{asignacion.fecInicio | amDateFormat: 'DD/MM/YYYY'}}
                          <br></i>
                        <i class="ml-1 text-muted" [ngClass]="{'text-danger': asignacion.fecTermino <= limiteFecha, '': asignacion.fecTermino >= limiteFecha }"  *ngIf="!asignacion.opcFin">
                          Termino: {{asignacion.fecTermino | amDateFormat: 'DD/MM/YYYY'}}
                          <br></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-2 col-xl-2">



                <div class="d-inline-block" ngbDropdown placement="left">
                  <button type="button" class="btn-pill btn btn-outline-primary" ngbDropdownToggle>
                    Acciones
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu-xl">
                    <div class="dropdown-menu-header">
                      <div class="dropdown-menu-header-inner bg-sunny-morning">
                        <div class="menu-header-content text-dark">
                          <h5 class="menu-header-title">ACCIONES</h5>
                        </div>
                      </div>
                    </div>
                    <div class="grid-menu grid-menu-xl grid-menu-3col">
                      <div class="no-gutters row">
                        <div class="col-sm-6 col-xl-6" *ngIf="identity.permisos.asignacionRegVer || identity.permisos.asignacionRegEdit">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary" [routerLink]="['/auth/asignacion/register', asignacion.id]" title="Editar la asignacion">
                            <i class="fa fa-edit btn-icon-wrapper btn-icon-lg mb-3"> </i> Editar
                          </button>
                        </div>
                        <div class="col-sm-6 col-xl-6" *ngIf="identity.permisos.asignacionRegEdit">
                          <button class="btn-icon-vertical btn-square btn-transition btn"  [ngClass]="{'btn-outline-secondary': !asignacion.estatus, 'btn-outline-success': asignacion.estatus}" (click)="editarEstado(asignacion)"  title="Presione para activar/desactivar la asignacion.">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 "[ngClass]="{'fa-ban': !asignacion.estatus, 'fa-check': asignacion.estatus}">  </i> {{ (asignacion.estatus ? 'Activo' : 'Desactivado') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="text-left">
              <ngb-pagination
                [collectionSize]="(asignaciones | anyScope: paginacion.textSearch | filterParamInObject: [{search: usuarioSearch, param: 'usuarioModifico'}, {search: usuarioSearch, param: 'usuarioRegistro'}] ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="3">
                <ng-template ngbPaginationFirst>First</ng-template>
                <ng-template ngbPaginationLast>Last</ng-template>
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="pull-right">
              <button type="button" (click) = "crearExcel()" class="btn btn-primary" *ngIf="identity.permisos.asignacionListEdit">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>