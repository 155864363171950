import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { TipoServicioService } from 'src/app/services/tipo-servicio.service';

@Injectable()
export class TipoServicioEffects {

  constructor(
    private actions$: Actions,
    public tipoServiciosService: TipoServicioService
  ) {}

  @Effect()
  cargarTipoServicio$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_TIPO_SERVICIOS ),
    switchMap( () => {
      return this.tipoServiciosService.obtenerTipoServicios()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarTipoServiciosSuccess(respuesta.servicios);
          }),
          catchError( error => of(new usuariosActions.CargarTipoServiciosFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusTipoServicio$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_TIPO_SERVICIO_ESTATUS ),
    switchMap( (action: any) => {
      return this.tipoServiciosService.actualizaEstatusTipoServicio(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaTipoServicioEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaTipoServicioEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaTipoServicio$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_TIPO_SERVICIO ),
    switchMap( (action: any) => {
      return this.tipoServiciosService.registraTipoServicio(action.servicio)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaTipoServicioSuccess(action.servicio);
            }),
            catchError( error => of(new usuariosActions.ActualizaTipoServicioFail(error))  )
        );
    })
  );

}
