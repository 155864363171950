import { Action } from '@ngrx/store';
import { Reporte, Excel } from 'src/app/models/reporte-actividades';
import { Departamento } from 'src/app/models/departamento';
import { Fechas } from 'src/app/models/fechas';
import { Cumplimiento, Justificacion, Labels } from 'src/app/models/cumplimiento';

export const CARGAR_REPORTE_ACTIVIDADES = '[Cumplimineto] Cargar reporte actividades';
export const CARGAR_REPORTE_ACTIVIDADES_FAIL = '[Cumplimineto] Cargar reporte actividades FAIL';
export const CARGAR_REPORTE_ACTIVIDADES_SUCCESS = '[Cumplimineto] Cargar reporte actividades SUCCESS';
export const CARGAR_CUMPLIMIENTO = '[Cumplimineto] Cargar cumplimineto';
export const CARGAR_CUMPLIMIENTO_FAIL = '[Cumplimineto] Cargar cumplimineto FAIL';
export const CARGAR_CUMPLIMIENTO_SUCCESS = '[Cumplimineto] Cargar cumplimineto SUCCESS';
export const ACTUALIZAR_CUMPLIMIENTO = '[Cumplimineto] Actualiza cumplimiento';
export const ACTUALIZAR_CUMPLIMIENTO_FAIL = '[Cumplimineto] Actualiza cumplimiento FAIL';
export const ACTUALIZAR_CUMPLIMIENTO_SUCCESS = '[Cumplimineto] Actualiza cumplimiento SUCCESS';



export class CargarReporteActividades implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES;

  constructor( public fecha: Fechas ) {}
}
  
export class CargarReporteActividadesFail implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_FAIL;
  
  constructor( public payload: any ) {}
}
  
export class CargarReporteActividadesSuccess implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_SUCCESS;
  
  constructor( public reporte: Reporte[], public excel: Excel[], public areas: Departamento[] ) {}
}

export class CargarCumplimiento implements Action {
  readonly type = CARGAR_CUMPLIMIENTO;

  constructor( public fecha: Fechas, public opciones: any ) {}
}
  
export class CargarCumplimientoFail implements Action {
  readonly type = CARGAR_CUMPLIMIENTO_FAIL;
  
  constructor( public payload: any ) {}
}
  
export class CargarCumplimientoSuccess implements Action {
  readonly type = CARGAR_CUMPLIMIENTO_SUCCESS;
  
  constructor( public cumplimiento: Cumplimiento[], public labels: Labels[], public totalFiltro: number, public total: number ) {}
}

export class ActualizaCumplimiento implements Action {
  readonly type = ACTUALIZAR_CUMPLIMIENTO;

  constructor( public justificacion: Justificacion ) {}
}
  
export class ActualizaCumplimientoFail implements Action {
  readonly type = ACTUALIZAR_CUMPLIMIENTO_FAIL;
  
  constructor( public payload: any ) {}
}
  
export class ActualizaCumplimientoSuccess implements Action {
  readonly type = ACTUALIZAR_CUMPLIMIENTO_SUCCESS;
  
  constructor( public justificacion: Justificacion ) {}
}


export type cumpliminetoAcciones = CargarReporteActividades
                                | CargarReporteActividadesFail
                                | CargarReporteActividadesSuccess
                                | CargarCumplimiento
                                | CargarCumplimientoFail
                                | CargarCumplimientoSuccess
                                | ActualizaCumplimiento
                                | ActualizaCumplimientoFail
                                | ActualizaCumplimientoSuccess;