import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from 'src/app/models/global';
import { TipoServicio } from '../models/tipo-servicio';

@Injectable({
  providedIn: 'root'
})
export class TipoServicioService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  obtenerTipoServicios(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/tipo-servicio', {headers});
  }

  actualizaEstatusTipoServicio(servicio: TipoServicio): Observable<any> {
    const param = JSON.stringify({servicio});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/tipo-servicio/estatus', param, {headers});
  }

  registraTipoServicio(servicio: TipoServicio): Observable<any> {
    const param = JSON.stringify({servicio});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/tipo-servicio', param, {headers});
  }

}
