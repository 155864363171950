import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Identity, Permisos, UserType, Suscripciones } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';

@Component({
  selector: 'app-usuario-config-ticket',
  templateUrl: './usuario-config-ticket.component.html',
  styleUrls: ['./usuario-config-ticket.component.scss']
})
export class UsuarioConfigTicketComponent implements OnInit, OnDestroy {
  public identity: Identity;
  private subscribeAuth: Subscription = new Subscription();
  public loading = false;
  public url: string;
  public suscripciones: Suscripciones[] = [];
  public todo = false;
  public todoSuscripciones = false;
  public filterPermisos: number = 1;

  @Input() permisos: Permisos;
  @Input() tipoSelected: UserType;
  @Output() messageEvent = new EventEmitter<Permisos>();
  constructor(
    private store: Store<AppState>,
  ) {
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;

    // filterPermisos = 1 TODOS
    // filterPermisos = 2 Consulta
    // filterPermisos = 3 Registros
    // filterPermisos = 4 Empresas
    // filterPermisos = 5 Oportunidades
    // filterPermisos = 6 Reportes 
    // filterPermisos = 7 Contactos
    // filterPermisos = 8 Tickets
    // filterPermisos = 9 Catálogos 
    // filterPermisos = 10 Indicadores
    // filterPermisos = 11 Asignaciones
    // filterPermisos = 12 Usuarios
    // filterPermisos = 13 Actividades
    // filterPermisos = 14 Control
   }

  ngOnInit(): void {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.filterSelecionar();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
  }

  sendMessage() {
    this.messageEvent.emit(this.permisos);
  }

  validarTodo() {
    if(this.filterPermisos !== 3 ){
      setTimeout( () => {
        // tslint:disable-next-line:forin
        for (const field in this.permisos) {
          if (field.search('Edit')) {
            if (this.permisos[field]) {
              const atributo = field.substr(0, field.length - 4) + 'Ver';
              if (atributo in this.permisos) {
                this.permisos[atributo] = true;
              }
            }
          }
        }
        const elements = Object.keys(this.permisos);
        this.todo = elements.every( (key) => this.permisos[key]);
      }, 1);
    }
    this.sendMessage();
  }

  validarTodoSuscripciones() {
    this.todoSuscripciones = this.suscripciones.every( (item) => item.checked);
  }


  seleccionar() {
    switch ( this.filterPermisos ) {
      case 1:
        this.seleccionarTodo();
      break;
      case 2:
        this.seleccionarConsulta();
      break;
      case 3:
        this.seleccionarRegistro();
      break;
      case 4:
        this.seleccionarTicket();
      break;
    }
  }

  seleccionarTodo() {
    if (this.todo) {
      this.permisos.ticketsEdit = true;
      this.permisos.ticketsVer = true;
    } else {
      this.permisos.ticketsEdit = false;
      this.permisos.ticketsVer = false;
    }
  }

  seleccionarConsulta() {
    if (this.todo) {
      this.permisos.ticketsVer = true;
    } else {
      this.permisos.ticketsVer = false;
    }
  }


  seleccionarRegistro() {
    if (this.todo) {
      this.permisos.ticketsEdit = true;
    } else {
      this.permisos.ticketsEdit = false;
    }
  }

  seleccionarTicket() {
    if (this.todo) {
      this.permisos.ticketsEdit = true;
      this.permisos.ticketsVer = true;
    } else {
      this.permisos.ticketsEdit = false;
      this.permisos.ticketsVer = false;
    }
  }



  filterSelecionar(){
    switch ( this.filterPermisos ) {
      case 1:
        this.filterSeleccionarTodo();
      break;
      case 2:
        this.filterSeleccionarConsulta();
      break;
      case 3:
        this.filterSeleccionarRegistro();
      break;
      case 4:
        this.filterSeleccionarTicket();
      break;
    }
  }


  filterSeleccionarTodo() {
    if (  this.permisos.ticketsEdit &&
          this.permisos.ticketsVer) {
  this.todo = true;
} else {
  this.todo = false;
}
  }

  filterSeleccionarConsulta() {
    if (  this.permisos.ticketsVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }


  filterSeleccionarRegistro() {
    if (  this.permisos.ticketsEdit ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarTicket() {
    if (  this.permisos.ticketsEdit &&
          this.permisos.ticketsVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

}
