<app-page-title [heading]="title" [subheading]="'Lista de servicios utilizadas y registrados en el sistema'" [icon]="'lnr-list icon-gradient bg-happy-fisher'"></app-page-title>
<div class="row">
  <!-- <div class="col-lg-1"></div> -->
  <div class="col-lg-3" *ngIf="filterActive">
    <div class="card mb-3" >
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">  
        <div class="p-3">
          <div class="form-group">
            <label for="pageSizeVehiculo">Mostrar por hoja</label>
            <select class="custom-select" id="pageSizeVehiculo" name="pageSizeVehiculo" #pageSizeVehiculo="ngModel" [(ngModel)]="paginacion.pageSize">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="sortTypeVehiculos">Ordenar por</label>
            <select class="custom-select mb-2 mr-1" id="sortTypeVehiculos" name="sortTypeVehiculos" #sortTypeVehiculos="ngModel" [(ngModel)]="paginacion.sortType">
              <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="card-header-title">Listado de contenido</div>
        <div class="btn-actions-pane-right">
          <div class="btn-group ml-2">
            <div class="search-wrapper" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchTipoServicio" id="searchTipoServicio" #searchTipoServicio="ngModel" [(ngModel)]="paginacion.textSearch">
                <button class="search-icon" (click)="paginacion.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = '';"></button>
            </div>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="align-middle mb-0 table table-borderless table-striped table-hover">
            <thead>
              <tr>
                <th (click)="sort('nombre')">Nombres
                  <span *ngIf="paginacion.sortType === '-nombre'" class="fa fa-caret-down"></span>
                  <span *ngIf="paginacion.sortType === '+nombre'" class="fa fa-caret-up"></span>
                </th>
                <th width="110px">({{(servicios | anyScope: paginacion.textSearch ).length}} de {{(servicios).length}})</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let servicio of servicios | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize" (dblclick)="editarTipoServicio(servicio)" title="Doble clic para editar descripción del tipo de servicio.">
                <td class="v-center">
                  <a (click)="editarTipoServicio(servicio)" title="Clic aquí para ver y editar contenido del tipo de servicio.">{{servicio.nombre}}</a>
                </td>
                <td class="padding-btn-table text-center">
                  <button type="button" class="btn btn-flat" [ngClass]="{'btn-secondary': !servicio.status, 'btn-success': servicio.status}" *ngIf="identity.permisos && identity.permisos.catTipoServicioEdit"
                    title="Presione para activar/desactivar el tipo de servicio." (click)="estatusTipoServicio(servicio)">
                    {{ (servicio.status ? 'Activo' : 'Desactivado') }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="text-left">
              <ngb-pagination
                [collectionSize]="(servicios | anyScope: paginacion.textSearch).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="3">
                <ng-template ngbPaginationFirst>First</ng-template>
                <ng-template ngbPaginationLast>Last</ng-template>
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3" [ngClass]="{'col-lg-6': !filterActive }"  *ngIf="identity.permisos && identity.permisos.catTipoServicioEdit">
    <form id="TipoServicioForm" name="TipoServicioForm" #TipoServicioForm="ngForm" (ngSubmit)="onSubmit(TipoServicioForm)">
      <div class="main-card mb-3 card card-shadow-primary">
        <div class="card-header with-border">
          <div class="card-header-title">Registrar Tipo de servicio</div>
        </div>
        <div class="card-body">
          <div class="form-group required">
            <label for="nombreTipoServicio" class="control-label">Nombre</label>
            <input type="text" id="nombreTipoServicio" name="nombreTipoServicio" #nombreTipoServicio="ngModel"
              [(ngModel)]="servicio.nombre" class="form-control" placeholder="Servicio" title="Capture el nombre del tipo de servicio."
              required>
          </div>
        </div>
        <div class="grid-menu grid-menu-2col border-top">
          <div class="no-gutters row">
            <div class="col-sm-6" *ngIf="identity.permisos && (identity.permisos || identity.permisos.catTipoServicioVer)">
              <a class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" (click)="limpiar(TipoServicioForm)">
                <i class="lnr-undo btn-icon-wrapper"></i> Cancelar cambios
              </a>
            </div>
            <div class="col-sm-6" *ngIf="identity.permisos && identity.permisos.catTipoServicioEdit">
              <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" *ngIf="!loading" [disabled]="!TipoServicioForm.valid || (servicio | json) === (servicioInicial | json)">
                <i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
              </button>
              <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="loading">
                <span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
                <br>
                Loading...
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- <div class="col-lg-1"></div> -->
</div>
