import * as fromUsuario from '../actions';
import { Sucursal } from 'src/app/models/sucursales';
import { Division } from 'src/app/models/division';

export interface SucursalState {
  sucursales: Sucursal[];
  divisiones: Division[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: SucursalState = {
  sucursales: [],
  divisiones: [],
  loaded: false,
  loading: false,
  error: null
};

export function sucursalesReducer( state = estadoInicial, action: fromUsuario.sucursalesAcciones ): SucursalState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_SUCURSALES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_SUCURSALES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        sucursales: [...action.sucursales],
        divisiones: [...action.divisiones]
      };
    case fromUsuario.CARGAR_SUCURSALES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_SUCURSAL:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_SUCURSAL_SUCCESS:
      const itm = state.sucursales.find( item => item.id === action.sucursal.id);
      if (itm && itm.id) {
        state.sucursales = state.sucursales.map( item => {
          if (item.id === action.sucursal.id) {
            item = {...action.sucursal};
          }
          return item;
        });
      } else {
        state.sucursales.push(action.sucursal);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_SUCURSAL_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_SUCURSAL_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_SUCURSAL_ESTATUS_SUCCESS:
      state.sucursales = state.sucursales.map( item => {
        if (item.id === action.id) {
          item.status = !item.status;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_SUCURSAL_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}
