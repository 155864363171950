import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {BaseLayoutComponent} from './layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './layout/pages-layout/pages-layout.component';
import { publicRoutes } from './components/public/public.routing';
import { authRoutes } from './components/auth/auth.routing';
import { NotFoundComponent } from './layout/components/not-found/not-found.component';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';
import { layoutRoutes } from './layout/layout.routing';

const routes: Routes = [
  {
    path: 'auth',
    component: BaseLayoutComponent,
    children: authRoutes,
    data: { preload: true, delay: 10000 }
  },
  {
    path: 'public',
    component: PagesLayoutComponent,
    children: publicRoutes,
    data: { preload: true, delay: 5000 }
  },
  {
    path: 'layout',
    component: PagesLayoutComponent,
    children: layoutRoutes,
    data: { preload: true, delay: 5000 }
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  // {
  //   path: 'shared',
  //   component: SharedComponent,
  //   children: sharedRoutes,
  //   data: { preload: true, delay: 5000 }
  // },
  { path: '**', redirectTo: 'public/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      useHash: true,
      enableTracing: false, // <-- debugging purposes only
      preloadingStrategy: SelectivePreloadingStrategyService,
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
