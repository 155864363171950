import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Cliente, ClienteSortType } from 'src/app/models/clientes';
import { Pagination } from 'src/app/models/pagination';
import { ClientesService } from 'src/app/services/cliente.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { ToastrService } from 'ngx-toastr';
import { GENERALES } from 'src/app/models/generales';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-list-clientes',
  templateUrl: './list-clientes.component.html',
  styleUrls: ['./list-clientes.component.sass']
})
export class ListClientesComponent implements OnInit, OnDestroy {
  public title: string;
  public identity: Identity;
  private subscribeAuth: Subscription = new Subscription();
  private subscribeCliente: Subscription = new Subscription();
  public cliente: Cliente;
  public clienteInicial: Cliente = new Cliente(null);
  public clientes: Cliente[];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public filterActive: boolean = false;
  public orderType = [...ClienteSortType];
  constructor(
    private clientesService: ClientesService,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) { 
    this.paginacion.sortType = '+nombre';
    this.identity = new Identity(null);
    this.cliente = new Cliente(null);
    this.clientes = [];
  }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeCliente = this.store.select('clientes').subscribe(
      (state) => {
        this.clientes = [...state.clientes];
      }
    );
    this.title = 'Catálogo de clientes';
    console.log('Inicializa component Clientes...');
    this.store.dispatch(new usuariosActions.CargarClientes());
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeCliente.unsubscribe();
  }

  editarCliente(zon: Cliente) {
    this.cliente = {...zon};
    this.clienteInicial = {...zon};
  }

  estatusCliente(cliente: Cliente) {
    if (cliente.id > 0) {
      this.loading = true;
      this.clientesService.actualizaEstatusCliente(cliente).subscribe(
        (response) => {
          this.store.dispatch(new usuariosActions.ActualizaClienteEstatusSuccess(cliente.id));
          this.toastr.success('Actualización correctamente.', 'Exito');
          this.loading = false;
        }, (error) => {
          this.toastr.error('Ocurrió un error.', 'Error');
          this.loading = false;
        }
      );
    }
  }

  onSubmit(form) {
    Swal.fire({
      title: '¿Deseas registrar el cliente?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.clientesService.registraCliente(this.cliente).subscribe(
            (respuesta) => {
              if (respuesta.cliente) {
                this.store.dispatch(new usuariosActions.ActualizaClienteSuccess(respuesta.cliente));
                resolve(respuesta.cliente);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaClienteFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          form.reset();
          this.cliente = new Cliente(null);
          this.clienteInicial = new Cliente(null);
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Cliente registrado.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    ); 
  }

  limpiar(form) {
    this.cliente = new Cliente(null);
    this.clienteInicial = new Cliente(null);
    form.reset();
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }
  
}
