import { Action } from '@ngrx/store';
import { ReporteUsuario, ReporteArea, ReporteIndicador, Nombre } from 'src/app/models/tablero-kpi';
import { Fechas } from 'src/app/models/fechas';

export const CARGAR_REPORTE_KPI_INDICADORES = '[ReporteKpi] Cargar reporte kpi indicadores';
export const CARGAR_REPORTE_KPI_INDICADORES_FAIL = '[ReporteKpi] Cargar reporte kpi indicadores FAIL';
export const CARGAR_REPORTE_KPI_INDICADORES_SUCCESS = '[ReporteKpi] Cargar reporte kpi indicadores SUCCESS';
export const CARGAR_REPORTE_KPI_AREAS = '[ReporteKpi] Cargar reporte kpi areas';
export const CARGAR_REPORTE_KPI_AREAS_FAIL = '[ReporteKpi] Cargar reporte kpi areas FAIL';
export const CARGAR_REPORTE_KPI_AREAS_SUCCESS = '[ReporteKpi] Cargar reporte kpi areas SUCCESS';
export const CARGAR_REPORTE_KPI_USUARIOS = '[ReporteKpi] Cargar reporte kpi usuarios';
export const CARGAR_REPORTE_KPI_USUARIOS_FAIL = '[ReporteKpi] Cargar reporte kpi usuarios FAIL';
export const CARGAR_REPORTE_KPI_USUARIOS_SUCCESS = '[ReporteKpi] Cargar reporte kpi usuarios SUCCESS';
export const LIMPIAR_REPORTE_KPI_USUARIOS = '[ReporteKpi] Limpiar reporte kpi usuarios';
export const LIMPIAR_REPORTE_KPI_AREAS = '[ReporteKpi] Limpiar reporte kpi areas';
export const LIMPIAR_REPORTE_KPI_INDICADORES = '[ReporteKpi] Limpiar reporte kpi indicadores';



export class CargarReporteKpiIndicadores implements Action {
  readonly type = CARGAR_REPORTE_KPI_INDICADORES;

  constructor( public dias: Fechas ) {}
}

export class CargarReporteKpiIndicadoresFail implements Action {
  readonly type = CARGAR_REPORTE_KPI_INDICADORES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteKpiIndicadoresSuccess implements Action {
  readonly type = CARGAR_REPORTE_KPI_INDICADORES_SUCCESS;

  constructor( public ReporteIndicador: ReporteUsuario[] , public Indicador: Nombre[] , public tipo: Nombre[]) {}
}

 export class CargarReporteKpiAreas implements Action {
    readonly type = CARGAR_REPORTE_KPI_AREAS;

    constructor( public dias: Fechas ) {}
  }
  
  export class CargarReporteKpiAreasFail implements Action {
    readonly type = CARGAR_REPORTE_KPI_AREAS_FAIL;
  
    constructor( public payload: any ) {}
  }
  
  export class CargarReporteKpiAreasSuccess implements Action {
    readonly type = CARGAR_REPORTE_KPI_AREAS_SUCCESS;
  
    constructor( public ReporteArea: ReporteArea[], public area: Nombre[] , public Indicador: Nombre[] , public tipo: Nombre[] ) {}
  }

  export class CargarReporteKpiUsuario implements Action {
    readonly type = CARGAR_REPORTE_KPI_USUARIOS;

    constructor( public dias: Fechas ) {}
  }
  
  export class CargarReporteKpiUsuarioFail implements Action {
    readonly type = CARGAR_REPORTE_KPI_USUARIOS_FAIL;
  
    constructor( public payload: any ) {}
  }
  
  export class CargarReporteKpiUsuarioSuccess implements Action {
    readonly type = CARGAR_REPORTE_KPI_USUARIOS_SUCCESS;
  
    constructor( public ReporteUsuario: ReporteUsuario[], public area: Nombre[], public Indicador: Nombre[], public tipo: Nombre[], public usuario: Nombre[] ) {}
  }

  export class LimpiarReporteKpiIndicadores implements Action {
    readonly type = LIMPIAR_REPORTE_KPI_USUARIOS;

    constructor() {}  
  }

  export class LimpiarReporteKpiAreas implements Action {
    readonly type = LIMPIAR_REPORTE_KPI_AREAS;

    constructor() {}  
  }

  export class LimpiarReporteKpiUsuario implements Action {
    readonly type = LIMPIAR_REPORTE_KPI_INDICADORES;

    constructor() {}  
  }

export type tableroKpiAcciones =  CargarReporteKpiIndicadores 
                                | CargarReporteKpiIndicadoresFail 
                                | CargarReporteKpiIndicadoresSuccess 
                                | CargarReporteKpiAreas 
                                | CargarReporteKpiAreasFail 
                                | CargarReporteKpiAreasSuccess 
                                | CargarReporteKpiUsuario 
                                | CargarReporteKpiUsuarioFail 
                                | CargarReporteKpiUsuarioSuccess
                                | LimpiarReporteKpiIndicadores
                                | LimpiarReporteKpiAreas
                                | LimpiarReporteKpiUsuario;

