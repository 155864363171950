import { Action } from '@ngrx/store';
import { Empresa } from 'src/app/models/empresa';

export const CARGAR_EMPRESAS = '[Empresa] Cargar empresas';
export const CARGAR_EMPRESAS_FAIL = '[Empresa] Cargar empresas FAIL';
export const CARGAR_EMPRESAS_SUCCESS = '[Empresa] Cargar empresas SUCCESS';
export const CARGAR_EMPRESA = '[Empresa] Cargar empresa';
export const CARGAR_EMPRESA_FAIL = '[Empresa] Cargar empresa FAIL';
export const CARGAR_EMPRESA_SUCCESS = '[Empresa] Cargar empresa SUCCESS';
export const ACTUALIZA_EMPRESA = '[Empresa] Actualiza empresa';
export const ACTUALIZA_EMPRESA_FAIL = '[Empresa] Actualiza empresa FAIL';
export const ACTUALIZA_EMPRESA_SUCCESS = '[Empresa] Actualiza empresa SUCCESS';
export const ELIMINAR_EMPRESA = '[Empresa] Actualiza empresa estatus';
export const ELIMINAR_EMPRESA_FAIL = '[Empresa] Actualiza empresa estatus FAIL';
export const ELIMINAR_EMPRESA_SUCCESS = '[Empresa] Actualiza empresa estatus SUCCESS';
export const LIMPIAR_EMPRESA = '[Empresa] Limpiar empresa';
export const ACTUALIZA_EMPRESAS_DUPLICADOS = '[Empresa] Actualiza duplicados de empresas';
export const ACTUALIZA_EMPRESAS_DUPLICADOS_FAIL = '[Empresa] Actualiza duplicados de empresas FAIL';
export const ACTUALIZA_EMPRESAS_DUPLICADOS_SUCCESS = '[Empresa] Actualiza duplicados de empresas SUCCESS';


export class CargarEmpresas implements Action {
  readonly type = CARGAR_EMPRESAS;

  constructor(public opciones: any) {}
}

export class CargarEmpresasFail implements Action {
  readonly type = CARGAR_EMPRESAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarEmpresasSuccess implements Action {
  readonly type = CARGAR_EMPRESAS_SUCCESS;

  constructor( public empresas: Empresa[], public totalFiltro: number, public total: number ) {}
}

export class CargarEmpresa implements Action {
  readonly type = CARGAR_EMPRESA;

  constructor(public id: number) {}
}

export class CargarEmpresaFail implements Action {
  readonly type = CARGAR_EMPRESA_FAIL;

  constructor( public payload: any ) {}
}

export class CargarEmpresaSuccess implements Action {
  readonly type = CARGAR_EMPRESA_SUCCESS;

  constructor( public empresa: Empresa ) {}
}

export class ActualizaEmpresa implements Action {
  readonly type = ACTUALIZA_EMPRESA;

  constructor( public empresa: Empresa ) { }
}

export class ActualizaEmpresaFail implements Action {
  readonly type = ACTUALIZA_EMPRESA_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaEmpresaSuccess implements Action {
  readonly type = ACTUALIZA_EMPRESA_SUCCESS;

  constructor( public empresa: Empresa ) {}
}

export class EliminarEmpresa implements Action {
  readonly type = ELIMINAR_EMPRESA;

  constructor( public id: number ) { }
}

export class EliminarEmpresaFail implements Action {
  readonly type = ELIMINAR_EMPRESA_FAIL;

  constructor( public payload: any ) {}
}

export class EliminarEmpresaSuccess implements Action {
  readonly type = ELIMINAR_EMPRESA_SUCCESS;

  constructor( public id: number ) {}
}

export class LimpiarEmpresa implements Action {
  readonly type = LIMPIAR_EMPRESA;

  constructor() {}
}

export class ActualizaEmpresasDuplicados implements Action {
  readonly type = ACTUALIZA_EMPRESAS_DUPLICADOS;

  constructor( public empresa: Empresa, public duplicados: Empresa[] ) { }
}

export class ActualizaEmpresasDuplicadosFail implements Action {
  readonly type = ACTUALIZA_EMPRESAS_DUPLICADOS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaEmpresasDuplicadosSuccess implements Action {
  readonly type = ACTUALIZA_EMPRESAS_DUPLICADOS_SUCCESS;

  constructor( public empresa: Empresa, public duplicados: Empresa[] ) {}
}

export type empresaAcciones =   CargarEmpresas
                              | CargarEmpresasFail
                              | CargarEmpresasSuccess
                              | CargarEmpresa
                              | CargarEmpresaFail
                              | CargarEmpresaSuccess
                              | ActualizaEmpresa
                              | ActualizaEmpresaFail
                              | ActualizaEmpresaSuccess
                              | EliminarEmpresa
                              | EliminarEmpresaFail
                              | EliminarEmpresaSuccess
                              | LimpiarEmpresa
                              | ActualizaEmpresasDuplicados
                              | ActualizaEmpresasDuplicadosFail
                              | ActualizaEmpresasDuplicadosSuccess;
