import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../services/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import * as usuariosActions from 'src/app/store/actions';
import { Identity, User } from 'src/app/models/user';
import { GLOBAL } from 'src/app/services/global';
import { UsuarioState, AuthState } from 'src/app/store/reducers';
import { Location } from '@angular/common';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';


@Component({
  selector: 'app-setting-profile',
  templateUrl: './setting-profile.component.html',
  styles: []
})
export class SettingProfileComponent implements OnInit, OnDestroy {

  subheading = 'Edita el contenido de tu información de usuario';
  heading = 'Mi perfil';
  icon = 'pe-7s-user';
  usuario: User;
  identity: Identity;
  url: string;
  imageChangedEvent: any ;
  files: any[] = [];
  showCropper = false;
  @ViewChild(ImageCropperComponent, {static: false}) imageCropper: ImageCropperComponent;


  cargando: boolean;
  subscription: Subscription = new Subscription();
  subscriptionIdentity: Subscription = new Subscription();
  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: false,
  };

  constructor(
    public authService: AuthService,
    public store: Store<AppState>,
    private location: Location
  ) {
    this.usuario = new User(null);
    this.url = GLOBAL.url;
    this.identity = new Identity(null);
    this.cargando = false;
  }

  ngOnInit() {
    this.subscription = this.store.select('usuario')
      .subscribe( (state: UsuarioState) => {
        this.usuario = {...state.usuario};
        this.cargando = state.loading;
      }
    );
    this.subscriptionIdentity = this.store.select('auth')
      .subscribe( (state: AuthState) => {
        this.identity = {...state.identity};
        // this.store.dispatch(new usuariosActions.CargarUsuario(this.identity.id));
      }
    );
    this.inicializa();
  }

  inicializa() {
    this.store.dispatch(new usuariosActions.CargarUsuario(this.identity.id));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionIdentity.unsubscribe();
  }

  onSubmit( data: any ) {
    // this.authService.crearUsuario( data.nombre, data.email, data.password );
    this.store.dispatch(new usuariosActions.EditarUsuarioPerfil(this.usuario));
  }



backLink() {
  this.location.back();
}

fileChangeEvent(event: any): void {
  // this.files = event.target.files as Array<File>;
  this.imageChangedEvent = event;
}

imageCropped(event: ImageCroppedEvent) {
  this.usuario.image = event.base64;
  // console.log(event);
}

imageLoaded() {
  this.showCropper = true;
  // console.log('Image loaded');
}

cropperReady() {
  // console.log('Cropper ready');
}

loadImageFailed() {
  // console.log('Load failed');
}

rotateLeft() {
  this.imageCropper.canvasRotation = 1;

  // this.imageCropper.rotateLeft;
}

rotateRight() {
  this.imageCropper.canvasRotation = 3;

  // this.imageCropper.rotateRight
}

flipHorizontal() {
  this.imageCropper.transform.flipH = !this.imageCropper.transform.flipH;

  // this.imageCropper.flipHorizontal;
}

flipVertical() {
  this.imageCropper.transform.flipV = !this.imageCropper.transform.flipV;

  // this.imageCropper.flipVertical;
}





}
