export class Archivo {
	public id:			number;
	public folio:		number;
	public nombre:		string;
	public tipo:		string;
	public size:		number;
	public nameFile:	string;
	public fecha:		Date;
	public file: 		any;

	constructor(
		obj: DataObjArchivo
	) {
		this.id			=obj && obj.id? obj.id : 0;
		this.folio		=obj && obj.folio? obj.folio : 0;
		this.nombre		=obj && obj.nombre? obj.nombre :'';
		this.tipo		=obj && obj.tipo? obj.tipo :'';
		this.size		=obj && obj.size? obj.size :0;
		this.nameFile	=obj && obj.nameFile? obj.nameFile :'';
		this.fecha		=obj && obj.fecha? obj.fecha :null;
		this.file		=obj && obj.file? obj.file: null;
	}
}
interface DataObjArchivo{
	id:			number;
	folio:		number;
	nombre:		string;
	tipo:			string;
	size:			number;
	nameFile:	string;
	fecha:		Date;
	file:			any;
}


export class ArchivoConcepto {
	public id: number;
	public folio: number;
	public nombre: string;
	public tipo: string;
	public size: number;
	public fecha: Date;
	public file: any;
	public concepto: number;

	constructor(
		obj: DataObjArchivoConcepto
	) {
		this.id = obj && obj.id ? obj.id : 0;
		this.folio = obj && obj.folio ? obj.folio : 0;
		this.nombre = obj && obj.nombre ? obj.nombre : '';
		this.tipo = obj && obj.tipo ? obj.tipo : '';
		this.size = obj && obj.size ? obj.size : 0;
		this.fecha = obj && obj.fecha ? obj.fecha : null;
		this.file = obj && obj.file ? obj.file : null;
		this.concepto = obj && obj.concepto ? obj.concepto : 0;
	}
}
interface DataObjArchivoConcepto{
	id: number;
	folio: number;
	nombre: string;
	tipo: string;
	size: number;
	fecha: Date;
	file: any;
	concepto: number;
}