import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Captura } from '../models/captura-resultado-indicador';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CapturasService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

   obtenerIndicadoresCapturar(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
  
    return this.http.get(this.url + '/captura/indicador/', {headers});
   }

   actualizaResultado(id: number, resultado:number,indicador:number ): Observable<any> {

		const params = JSON.stringify({resultado:resultado, indicador:indicador});
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		
		return this.http.put(this.url + '/captura/indicador/' + id, params, {headers});
   }

}