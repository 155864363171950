import * as fromDireccion from '../actions/divisiones.actions';
import { Division } from 'src/app/models/division';

export interface DivisionState {
  divisiones: Division[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: DivisionState = {
  divisiones: [],
  loaded: false,
  loading: false,
  error: null
};

export function divisionReducer( state = initState, action: fromDireccion.divisionAcciones ): DivisionState {
  switch ( action.type ) {
    case fromDireccion.CARGAR_DIVISIONES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromDireccion.CARGAR_DIVISIONES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        divisiones: [...action.divisiones]
      };
    case fromDireccion.CARGAR_DIVISIONES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromDireccion.ACTUALIZA_DIVISION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromDireccion.ACTUALIZA_DIVISION_SUCCESS:
      const itm = state.divisiones.find( item => item.id === action.division.id);
      if (itm && itm.id) {
        state.divisiones = state.divisiones.map( item => {
          if (item.id === action.division.id) {
            item = {...action.division};
          }
          return item;
        });
      } else {
        state.divisiones.push(action.division);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromDireccion.ACTUALIZA_DIVISION_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromDireccion.ACTUALIZA_DIVISION_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromDireccion.ACTUALIZA_DIVISION_ESTATUS_SUCCESS:
      state.divisiones = state.divisiones.map( item => {
        if (item.id === action.id) {
          item.estatus = !item.estatus;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromDireccion.ACTUALIZA_DIVISION_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}
