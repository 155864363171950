import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Identity } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GLOBAL } from 'src/app/models/global';
import { ArchivoConcepto } from 'src/app/models/archivo';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import * as usuariosActions from 'src/app/store/actions';
import { Oportunidad, NegocioCatalogo } from 'src/app/models/oportunidades';
import Swal from 'sweetalert2';
import { OportunidadesService } from 'src/app/services/oportunidades.service';
import { MESSAGES } from 'src/app/models/messages';
import { Catalogo } from 'src/app/models/catalogo';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';

@Component({
  selector: 'app-empresa-profile-actions',
  templateUrl: './empresa-profile-actions.component.html',
  styleUrls: ['./empresa-profile-actions.component.sass']
})
export class EmpresaProfileActionsComponent implements OnInit, OnDestroy {
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subcriptionArchivos: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private token: string = '';
  public filesToUpload:	File[] = [];
  public textFile: string = '';
  public archivos: ArchivoConcepto[] = [];
  public adjunto:	ArchivoConcepto;
  public url: string = '';
  public id: number = 0;
  public concepto: number = 0;
  public contactos: Catalogo[] = [];
  public oportunidades: Oportunidad[] = [];
  public oportunidadesGandas: number;
  public oportunidadesPerdidas: number;
  public oportunidad: Oportunidad  = new Oportunidad(null);
  public propietarios: Catalogo[];
  public etapas: Catalogo[];
  public negocios = [...NegocioCatalogo];
  public contactosFilter: Catalogo[];
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'nombre',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Sin datos'
  };
  public currencySettings = {
    digitGroupSeparator: ',',
    decimalCharacter: '.',
    decimalCharacterAlternative: '.',
    currencySymbol: '$ ',
    currencySymbolPlacement: 'p',
    roundingMethod: 'U',
    minimumValue: '0'
  };
  constructor(
    private authService: AuthService,
		private store: Store<AppState>,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private oportunidadesService: OportunidadesService,
  ) { 
    this.url = GLOBAL.url;
    this.adjunto = new ArchivoConcepto(null);
    this.store.dispatch(new usuariosActions.LimpiarArchivos());
    this.concepto = 1;
    this.oportunidades = [];
    this.contactos = [];
    this.oportunidadesGandas = 0;
    this.oportunidadesPerdidas = 0;
  }

  ngOnInit() {
    this.authService.getToken().subscribe(
      (request) => {
        this.token = request;
      }
    );
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
		    this.identity = {...state.identity};
      }
    );
    this.subcriptionArchivos = this.store.select('actividadArchivos').subscribe(
      (state) => {
        this.archivos = [...state.archivos];
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.contactos = [...state.contactosEmpresa];
        this.oportunidades = [...state.oportunidadesEmpresa.filter(item => item.status)];
        this.oportunidadesGandas = state.oportunidadesGandas;
        this.oportunidadesPerdidas = state.oportunidadesPerdidas;

        this.contactosFilter = [...state.contactosEmpresa.filter(item => item.status).map( item => {
          return {id: item.id, nombre: item.nombre, status: item.status};
        })]; 
        this.propietarios = [...state.usuarios.map( item => {
          return {id: item.id, nombre: item.descripcion, status: true};
        })];
        this.etapas = [...state.etapas.filter(item => item.status)];
      }
    );
    this.route.params.subscribe(
      (params) => {
        this.id = params.id;
        if (this.id > 0) {
          this.store.dispatch(new usuariosActions.CargarArchivos(this.id, this.concepto));
          this.store.dispatch(new usuariosActions.ConsultaContactosEmpresa(this.id));
          this.store.dispatch(new usuariosActions.ConsultaOportunidadesEmpresa(this.id));
          this.store.dispatch(new usuariosActions.ConsultaOportunidadesGanadasPerdidasEmpresa(this.id));
          this.store.dispatch(new usuariosActions.ConsultaUsuarios());
          this.store.dispatch(new usuariosActions.ConsultaEtapas());
        }
    });
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionArchivos.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  fileChangeEvent(event: any) {
    this.filesToUpload = <Array<File>>event.target.files;
    this.adjunto.file = <Array<File>>event.target.files;
    if (this.filesToUpload.length) {
      this.adjunto.tipo = this.filesToUpload[0].type.split('/').pop();
      this.adjunto.size = this.filesToUpload[0].size;
      if (!(this.adjunto.tipo === 'jpeg' || this.adjunto.tipo === 'png' || this.adjunto.tipo === 'gif' || this.adjunto.tipo === 'pdf')) {
        this.adjunto.tipo = '';
        this.textFile = '';
        this.filesToUpload = [] ;
      }
    } else {
      this.adjunto.tipo = '';
      this.filesToUpload = [];
    }
  }

  addFile() {
    if (this.adjunto.nombre !== '' && this.filesToUpload.length && this.adjunto.tipo !== '') {
      const itm = this.archivos.find( item => item.nombre === this.adjunto.nombre && item.tipo === this.adjunto.tipo);
      this.adjunto.folio = this.id;
      this.adjunto.concepto = this.concepto;
      if (!(itm && itm.id)) {
        this.store.dispatch(new usuariosActions.RegistrarArchivo(this.url + '/archivos/', [], this.filesToUpload, this.token, 'files', this.adjunto));
        this.modalService.dismissAll('Close');
      }
    }
  }

  deleteFile(file: ArchivoConcepto) {
    this.store.dispatch(new usuariosActions.EliminarArchivo(this.id, file));
  }

  open(content) {
    this.textFile = '';
    this.adjunto = new ArchivoConcepto(null); 
    this.modalService.open(content, {
      size: 'lg'
    });
  }

  openOportunidad(content) {
    this.oportunidad.empresa = this.id;
    this.oportunidad.propietario = this.identity.id;
    this.modalService.open(content, {
      size: 'lg'
    });
  }

  async onSubmit() {
    await this.validaOportunidad().then(
      (resultado) => {
        Swal.fire({
          title: '¿Deseas registrar la oportunidad?',
          showCancelButton: true,
          confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
          cancelButtonColor: MESSAGES.C_BTN_CANCEL,
          confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
          cancelButtonText: MESSAGES.M_BTN_CANCEL,
          focusConfirm: false,
          focusCancel: true,
          animation: true,
          showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
          showLoaderOnConfirm: true,
          preConfirm:  ()  => {
            return new Promise( (resolve, reject)  => {
              this.oportunidadesService.registraOportunidad(this.oportunidad).subscribe(
                (respuesta) => {
                  if (respuesta.oportunidad) {
                    this.oportunidades.push({...respuesta.oportunidad});
                    resolve(respuesta.oportunidad);
                  } else {
                    Swal.showValidationMessage('Ocurrió un error en la operación');
                    resolve(false);
                  }
                }, (error) => {
                  Swal.showValidationMessage('Ocurrió un error en la operación');
                  resolve(false);
                }
              );
            });
          },
          allowOutsideClick: false
        }).then(
          (result) => {
            if (result.value) {
              Swal.fire({
                title: MESSAGES.M_TIT_CONFIRM,
                text: 'Oportunidad registrada.',
                icon: 'success'
              });
              this.modalService.dismissAll('Close');
            } else {
              Swal.fire({
                title: MESSAGES.M_TIT_CANCELADO,
                timer: 1200,
                showConfirmButton: false,
                icon: 'error',
                onOpen: () =>  {}
              }).then(() => {}, () => {});
            }
          }, () => {
            Swal.fire({
              title: MESSAGES.M_TIT_CANCELADO,
              timer: 1200,
              showConfirmButton: false,
              icon: 'error',
              onOpen: () => {}
            }).then( () => {},  () => {});
          }
        );
    }, (error) => { }
    );
  }

  validaOportunidad() {
    const deferred = new Promise((resolve, reject) => {
      this.etapas.find( item => { if( item.id ===  this.oportunidad.etapa ){ this.oportunidad.desEtapa = item.nombre } });
        resolve(true);
    });
    return deferred;
  }

  onItemSelect(item: any) {
    // console.log(item);
  }

  onSelectAll(items: any) {
    // console.log(items);
  }

}
