<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
	<div class="col-md-4 col-sm-6" *ngIf="identity.permisos && identity.permisos.rptIndicador">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/indicadores.png"
											ancho="180px"
											largo="170px"  
											title="Indicador" 
											subTitle="En este reporte se gestiona las capturas de los indicadores." 
											url="reportes/tablero-kpi/indicador"></app-reporte-menu>
	</div>
	<div class="col-md-4 col-sm-6" *ngIf="identity.permisos && identity.permisos.rptIndicadorArea">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/indicadores.png"
											ancho="180px"
											largo="170px"  
											title="Indicador por área" 
											subTitle="En este reporte se gestiona las capturas de los indicadores que tiene como responsable por cada área." 
											url="reportes/tablero-kpi/area"></app-reporte-menu>
	</div>
	<div class="col-md-4 col-sm-6" *ngIf="identity.permisos && identity.permisos.rptIndicadorUsuario">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/indicadores.png"
											ancho="180px"
											largo="170px"  
											title="Indicador por usuario" 
											subTitle="En este reporte se gestiona las capturas de los indicadores que tiene como responsable por cada usuario." 
											url="reportes/tablero-kpi/usario"></app-reporte-menu>
	</div>
	<div class="col-md-4 col-sm-6" *ngIf="identity.permisos && identity.permisos.rptActividades">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/actividades.png"
											ancho="180px"
											largo="170px"  
											title="Actividades" 
											subTitle="En este reporte se gestiona las actividades registradas por cada usuario." 
											url="reportes/actividades"></app-reporte-menu>
	</div>
</div>
