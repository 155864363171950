import { Component, OnInit, OnDestroy } from '@angular/core';
import { IndicadoresService } from 'src/app/services/indicadores.service';
import { EcuacionesService } from 'src/app/services/ecuacion.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Identity } from 'src/app/models/user';
import { AuthState } from 'src/app/store/reducers';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { Captura, Variables } from 'src/app/models/captura-resultado-indicador';
import * as usuariosActions from 'src/app/store/actions';



@Component({
  selector: 'app-captura-indicador',
  templateUrl: './captura-indicador.component.html',
  styleUrls: ['./captura-indicador.component.sass']
})
export class CapturaIndicadorComponent implements OnInit, OnDestroy {
  heading = 'Captura de los indicadores administrativos';
  subheading = 'indicadores actuales';
  icon = ' pe-7s-calculator icon-gradient bg-happy-fisher';
  public indicadores: Captura[] = [];
  public idc: Captura[] = [];
  public variables: Variables[] = [];
  public peso: {} = {};
  public unidad: {} = {};
  public id:number;
  public tipo:number = 1;
  public indicador:number;
  public formula: string;
  public nombre: string;
  public mostrarFormulario: boolean;
  public identity: Identity;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionCaptura: Subscription = new Subscription();
  constructor(
    private ecuacionesService: EcuacionesService,
    private store: Store<AppState>,
  ) { 
    this.mostrarFormulario = false;
    this.store.dispatch(new usuariosActions.LimpiarResultados());

    this.peso = {
      prefix: '$',
      suffix: '',
      duration: 1.7
    };
    this.unidad = {
      suffix: '',
      duration: 1.7
    };
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionCaptura = this.store.select('captura').subscribe(
      (state) => {
        this.idc = [...state.captura];
        this.indetificarIndicadores();
      }
    );
    
    this.store.dispatch(new usuariosActions.CargarResultadosIndicadores());
   
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionCaptura.unsubscribe();
  }

  indicadorCapturar(indicador:Captura){
    this.mostrarFormulario = true;
    this.id = indicador.id;
    this.indicador = indicador.indicador
    this.formula = indicador.formula;
    this.variables = indicador.variables;
    this.nombre = indicador.nombre;
  }

  onSubmit(){
    var resultado = this.ecuacionesService.resolverFormula(this.formula,this.variables);
    this.store.dispatch(new usuariosActions.ActualizaResultadoIndicador(this.id, resultado, this.indicador ));
    this.mostrarFormulario = false;
    this.variables = []
  }


  indetificarIndicadores(){
    if(this.tipo === 1){
      this.indicadores = [...this.idc];
    }
    if(this.tipo === 2){
      this.indicadores = [...this.idc.filter( item => item.edito == true)];
    }
    if(this.tipo === 3){
      this.indicadores = [...this.idc.filter( item => item.edito == false)];
    }
    

  }



}
