export class Indicador{
	public id: number;
    public nombre: string;
    public tipo: string;
    public objetivo: number;
    public unidad: string;
    public resultado: string;
    public asignados: AsignadosIndicadores[];
    public variable: Variable_indicadores[];
    public opcion: string;
    public valor: number;
    public dia: Date;
    public formula: string;
    public latex: string;
    public colores: Color[];
    public fechaLimite: Date;
    public estado: boolean;

	constructor(
		obj: DataObjIndicador
	){
		this.id = obj && obj.id? obj.id :0;
        this.nombre = obj && obj.nombre? obj.nombre :'';
        this.tipo = obj && obj.tipo? obj.tipo :'';
        this.objetivo = obj && obj.objetivo? obj.objetivo :null;
        this.unidad = obj && obj.unidad? obj.unidad :'';
        this.resultado = obj && obj.resultado? obj.resultado :'';
        this.opcion = obj && obj.opcion? obj.opcion : '';
        this.dia = obj && obj.dia? obj.dia : new Date ();
        this.valor = obj && obj.valor? obj.valor: null;
        this.asignados = obj && obj.asignados? obj.asignados :[];
        this.variable = obj && obj.variable? obj.variable :[];
        this.formula = obj && obj.formula? obj.formula :'';
        this.latex = obj && obj.latex? obj.latex :'';
        this.colores = obj && obj.colores? obj.colores :[];
        this.fechaLimite = obj && obj.fechaLimite? obj.fechaLimite: null;
        this.estado = obj && obj.estado? obj.estado : false;

	}
}
interface DataObjIndicador{
    id: number;
    nombre: string;
    tipo: string;
    objetivo: number;
    opcion: string;
    valor: number;
    dia:Date;
    unidad: string;
    resultado: string;
    asignados: AsignadosIndicadores[];
    variable: Variable_indicadores[];
    formula: string;
    latex: string;
    colores: Color[];
    fechaLimite: Date;
    estado: boolean;
}


//condicionales(colores) de los indicadores
export class Color{
    public id: number;
    public maximo: number;
    public minimo: number;
    public color: number;


	constructor(
		obj: DataObjColor
	){
        this.id = obj && obj.id? obj.id :0;
        this.maximo = obj && obj.maximo? obj.maximo :0;
        this.minimo = obj && obj.minimo? obj.minimo :0;
        this.color = obj && obj.color? obj.color : null;
	}
}
interface DataObjColor{
    id: number;
    maximo: number;
    minimo: number;
    color: number;
}

//usuarios asignados al indicador
export class AsignadosIndicadores{
    public id: number;
    public idUsuario: number;
    public idArea: number;
    public usuario: string;


	constructor(
		obj: DataObjAsignadosIndicadores
	){
        this.id = obj && obj.id? obj.id :0;
        this.idUsuario = obj && obj.idUsuario? obj.idUsuario: 0;
        this.idArea = obj && obj.idArea? obj.idArea: 0;
        this.usuario = obj && obj.usuario? obj.usuario : '';
	}
}
interface DataObjAsignadosIndicadores{
    id: number;
    idArea: number;
    idUsuario: number;
    usuario: string;
}


//variables
export class Variable_indicadores{
	public id: number;
    public indicador: number;
    public variable: string;
    public nombre: string;
    public constante: number;
    public valor: number;



	constructor(
		obj: DataObjVariable_indicadores
	){
		this.id = obj && obj.id? obj.id :0;
		this.indicador = obj && obj.indicador? obj.indicador :0;
        this.variable = obj && obj.variable? obj.variable :'';
        this.nombre = obj && obj.nombre? obj.nombre :'';
		this.constante = obj && obj.constante? obj.constante :0;
        this.valor = obj && obj.valor? obj.valor :0;
	}
}
interface DataObjVariable_indicadores{
    id: number;
    indicador: number;
    variable: string;
    nombre: string;
    constante: number;
    valor: number;
}

//area
export class Area_indicadores{
	public id: number;



	constructor(
		obj: DataObjArea_indicadores
	){
		this.id = obj && obj.id? obj.id :null;
	}
}
interface DataObjArea_indicadores{
    id: number;
}

export class LisIndicador{
	public id: number;
    public nombre: string;
    public dia: Date;
    public estado: boolean;

	constructor(
		obj: DataObjLisIndicador
	){
		this.id = obj && obj.id? obj.id :0;
        this.nombre = obj && obj.nombre? obj.nombre :'';
        this.estado = obj && obj.estado? obj.estado : true;
        this.dia = obj && obj.dia? obj.dia :null;

	}
}
interface DataObjLisIndicador{
    id: number;
    nombre: string;
    dia:Date;
    estado: boolean;
}



