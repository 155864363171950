<div class="col-12 mt-3">
	<div class="form-group">
		<div class="row">
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 1}" (click)="filterPermisos = 1; filterSelecionar()" title="Ver y editar todos los permisos">TODOS</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 2}" (click)="filterPermisos = 2; filterSelecionar()" title="Ver y editar todos los permisos de consulta">Consulta</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 3}" (click)="filterPermisos = 3; filterSelecionar()" title="Ver y editar todos los permisosd de registros">Registros</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 6}" (click)="filterPermisos = 6; filterSelecionar()" title="Ver los reportes">Reportes</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 9}" (click)="filterPermisos = 9; filterSelecionar()" title="Configuración de los catálogos de áreas, divisiones, sucursales, colores, servicios y clientes">Catálogos</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 10}" (click)="filterPermisos = 10; filterSelecionar()" title="Ver y editar indicadores">Indicadores</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 11}" (click)="filterPermisos = 11; filterSelecionar()" title="Ver y editar asignaciones">Asignaciones</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 13}" (click)="filterPermisos = 13; filterSelecionar()" title="Ver y editar actividades">Actividades</button>
			</div>
		</div>
	</div>
</div>
<ngb-tabset class="tabs-animated tabs-animated-line" justify="justified">
	<ngb-tab>
		<ng-template ngbTabTitle><span>Permisos</span></ng-template>
		<ng-template ngbTabContent>
			<ul class="todo-list-wrapper list-group list-group-flush">
				<li class="list-group-item">
					<div class="widget-content p-0">
						<div class="widget-content-wrapper">
							<div class="widget-content-left mr-2">
								<div class="custom-checkbox custom-control">
									<input type="checkbox" id="allPermisosForm" name="allPermisosForm" #allPermisosForm="ngModel" [(ngModel)]="todo" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="seleccionar()">
									<label class="custom-control-label" for="allPermisosForm">&nbsp; Seleccionar todo</label>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<hr class="p-0 m-0">
			<div class="scroll-area-lg">
				<div class="scrollbar-container">
					<perfect-scrollbar [autoPropagation]="true">
						<ul class="todo-list-wrapper list-group list-group-flush">
								<!-- 10 - Indicadores -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 10 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormindicadorListEdit" name="permisoFormindicadorListEdit" #permisoFormindicadorListEdit="ngModel" [(ngModel)]="permisos.indicadorListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormindicadorListEdit">&nbsp; Editar lista de indicador</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 10 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormindicadorListVer" name="permisoFormindicadorListVer" #permisoFormindicadorListVer="ngModel" [(ngModel)]="permisos.indicadorListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormindicadorListVer">&nbsp; Ver lista de indicador</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 10 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormindicadorRegEdit" name="permisoFormindicadorRegEdit" #permisoFormindicadorRegEdit="ngModel" [(ngModel)]="permisos.indicadorRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormindicadorRegEdit">&nbsp; Editar detalle de indicador</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 10 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormindicadorRegVer" name="permisoFormindicadorRegVer" #permisoFormindicadorRegVer="ngModel" [(ngModel)]="permisos.indicadorRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormindicadorRegVer">&nbsp; Ver detalle de indicador</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 10 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormindicadorCapturar" name="permisoFormindicadorCapturar" #permisoFormindicadorCapturar="ngModel" [(ngModel)]="permisos.indicadorCapturar" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormindicadorCapturar">&nbsp; Captura indicadores</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 11 - Asignaciones -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 11 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormAsignacionListEdit" name="permisoFormAsignacionListEdit" #permisoFormAsignacionListEdit="ngModel" [(ngModel)]="permisos.asignacionListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormAsignacionListEdit">&nbsp; Editar lista de asignacion</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 11 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormAsignacionListVer" name="permisoFormAsignacionListVer" #permisoFormAsignacionListVer="ngModel" [(ngModel)]="permisos.asignacionListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormAsignacionListVer">&nbsp; Ver lista de asignacion</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 11 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormAsignacionRegEdit" name="permisoFormAsignacionRegEdit" #permisoFormAsignacionRegEdit="ngModel" [(ngModel)]="permisos.asignacionRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormAsignacionRegEdit">&nbsp; Editar detalle de asignacion</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 11 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormAsignacionRegVer" name="permisoFormAsignacionRegVer" #permisoFormAsignacionRegVer="ngModel" [(ngModel)]="permisos.asignacionRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormAsignacionRegVer">&nbsp; Ver detalle de asignacion</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 11 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormAsignacionCapturar" name="permisoFormAsignacionCapturar" #permisoFormaasignacionCapturar="ngModel" [(ngModel)]="permisos.asignacionCapturar" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormAsignacionCapturar">&nbsp; Captura indicadores operativos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 13 - Actividades -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 13 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesListEdit" name="permisoFormActividadesListEdit" #permisoFormActividadesListEdit="ngModel" [(ngModel)]="permisos.actividadesListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesListEdit">&nbsp; Editar lista de actividades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 13 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesListVer" name="permisoFormActividadesListVer" #permisoFormActividadesListVer="ngModel" [(ngModel)]="permisos.actividadesListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesListVer">&nbsp; Ver lista de actividades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 13 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesRegEdit" name="permisoFormActividadesRegEdit" #permisoFormActividadesRegEdit="ngModel" [(ngModel)]="permisos.actividadesRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesRegEdit">&nbsp; Editar detalle de actividades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 13 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesRegVer" name="permisoFormActividadesRegVer" #permisoFormActividadesRegVer="ngModel" [(ngModel)]="permisos.actividadesRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesRegVer">&nbsp; Ver detalle de actividades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 9 - Catálogos -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDepartamentosRegEdit" name="permisoFormDepartamentosRegEdit" #permisoFormDepartamentosRegEdit="ngModel" [(ngModel)]="permisos.catDepartamentosEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDepartamentosRegEdit">&nbsp; Editar detalle del catálogo áreas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDepartamentosVer" name="permisoFormDepartamentosVer" #permisoFormDepartamentosVer="ngModel" [(ngModel)]="permisos.catDepartamentosVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDepartamentosVer">&nbsp; Ver detalle del catálogo áreas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDivisionesEdit" name="permisoFormDivisionesEdit" #permisoFormDivisionesEdit="ngModel" [(ngModel)]="permisos.catDivisionesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDivisionesEdit">&nbsp; Editar detalle del catálogo divisiones</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDivisionesRegVer" name="permisoFormDivisionesRegVer" #permisoFormDivisionesRegVer="ngModel" [(ngModel)]="permisos.catDivisionesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDivisionesRegVer">&nbsp; Ver detalle del catálogo divisiones</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormSucursalesRegEdit" name="permisoFormSucursalesRegEdit" #permisoFormSucursalesRegEdit="ngModel" [(ngModel)]="permisos.catSucursalesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormSucursalesRegEdit">&nbsp; Editar detalle del catálogo sucursales</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormSucursalesVer" name="permisoFormSucursalesVer" #permisoFormSucursalesVer="ngModel" [(ngModel)]="permisos.catSucursalesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormSucursalesVer">&nbsp; Ver detalle del catálogo sucursales</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormColoresRegEdit" name="permisoFormColoresRegEdit" #permisoFormColoresRegEdit="ngModel" [(ngModel)]="permisos.catColoresEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormColoresRegEdit">&nbsp; Editar detalle del catálogo colores</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormColoresVer" name="permisoFormColoresVer" #permisoFormColoresVer="ngModel" [(ngModel)]="permisos.catColoresVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormColoresVer">&nbsp; Ver detalle del catálogo colores</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormServiciosRegEdit" name="permisoFormServiciosRegEdit" #permisoFormServiciosRegEdit="ngModel" [(ngModel)]="permisos.catServiciosEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormServiciosRegEdit">&nbsp; Editar detalle del catálogo servicios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormServiciosVer" name="permisoFormServiciosVer" #permisoFormServiciosVer="ngModel" [(ngModel)]="permisos.catServiciosVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormServiciosVer">&nbsp; Ver detalle del catálogo servicios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormClientesRegEdit" name="permisoFormClientesRegEdit" #permisoFormClientesRegEdit="ngModel" [(ngModel)]="permisos.catClienteEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormClientesRegEdit">&nbsp; Editar detalle del catálogo clientes</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormClientesVer" name="permisoFormClientesVer" #permisoFormClientesVer="ngModel" [(ngModel)]="permisos.catClienteVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormClientesVer">&nbsp; Ver detalle del catálogo clientes</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormTiposServicioRegEdit" name="permisoFormTiposServicioRegEdit" #permisoFormTiposServicioRegEdit="ngModel" [(ngModel)]="permisos.catTipoServicioEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormTiposServicioRegEdit">&nbsp; Editar detalle del catálogo tipos de servicio</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormTiposServicioVer" name="permisoFormTiposServicioVer" #permisoFormTiposServicioVer="ngModel" [(ngModel)]="permisos.catTipoServicioVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormTiposServicioVer">&nbsp; Ver detalle del catálogo tipos de servicio</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 6 - Reportes -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptCobertura" name="permisoFormRptCobertura" #permisoFormRptCobertura="ngModel" [(ngModel)]="permisos.rptCobertura" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptCobertura">&nbsp; Ver reporte de cobertura operacional</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptCoberturaXDia" name="permisoFormRptCoberturaXDia" #permisoFormRptCoberturaXDia="ngModel" [(ngModel)]="permisos.rptCoberturaDia" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptCoberturaXDia">&nbsp; Ver reporte de cobertura operacional por día</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptActividades" name="permisoFormRptActividades" #permisoFormRptActividades="ngModel" [(ngModel)]="permisos.rptActividades" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptActividades">&nbsp; Ver reporte de actividades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptServicioCop" name="permisoFormRptServicioCop" #permisoFormRptServicioCop="ngModel" [(ngModel)]="permisos.rptServicioCop" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptServicioCop">&nbsp; Ver reporte de cobertura operacional por servicio</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptServicioInicia" name="permisoFormRptServicioInicia" #permisoFormRptServicioInicia="ngModel" [(ngModel)]="permisos.rptServicioInicia" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptServicioInicia">&nbsp; Ver reporte de servicios que inician en</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptServicioTermina" name="permisoFormRptServicioTermina" #permisoFormRptServicioTermina="ngModel" [(ngModel)]="permisos.rptServicioTermina" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptServicioTermina">&nbsp; Ver reporte de servicios que finaliza en</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptBajaVacantes" name="permisoFormRptBajaVacantes" #permisoFormRptBajaVacantes="ngModel" [(ngModel)]="permisos.rptBajaVacantes" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptBajaVacantes">&nbsp; Ver reporte de bajas y vacantes</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptIndicador" name="permisoFormRptIndicador" #permisoFormRptIndicador="ngModel" [(ngModel)]="permisos.rptIndicador" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptIndicador">&nbsp; Ver reporte de KPIs</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptIndicadorXArea" name="permisoFormRptIndicadorXArea" #permisoFormRptIndicadorXArea="ngModel" [(ngModel)]="permisos.rptIndicadorArea" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptIndicadorXArea">&nbsp; Ver reporte de KPIs por área</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptIndicadorXUsuario" name="permisoFormRptIndicadorXUsuario" #permisoFormRptIndicadorXUsuario="ngModel" [(ngModel)]="permisos.rptIndicadorUsuario" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptIndicadorXUsuario">&nbsp; Ver reporte de KPIs por usuario</label>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</perfect-scrollbar>
				</div>
			</div>
		</ng-template>
	</ngb-tab>
</ngb-tabset>