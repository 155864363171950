import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity } from 'src/app/models/user';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { Ticket, TicketsSortType } from 'src/app/models/ticket';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import { TicketsService } from 'src/app/services/tickets.services';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import { FiltroCheck } from 'src/app/pipes/option-check-multiple.pipe';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.sass']
})
export class TicketsComponent implements OnInit, OnDestroy {
  toggleMobileSidebar: any;
  public heading = 'Tickets';
  public identity: Identity = new Identity(null);
  private subscriptionAuth: Subscription = new Subscription();
  private subscriptionTickets: Subscription = new Subscription();
  public filterActive = false;
  public paginacion: Pagination = new Pagination(null);
  public tickets: Ticket[] = [];
  public url = '';
  public listSearch: string[] = [];
  public orderType: any[] = [...TicketsSortType];
  public statusFilter: FiltroCheck[] = [
    { value: 1, text: 'Nuevo', parametro: 'status', checked: false},
    { value: 2, text: 'Actualizado/Respondido', parametro: 'status', checked: false},
    { value: 3, text: 'Cerrado', parametro: 'status', checked: false}
  ];
  public existeFilter: FiltroCheck[] = [
    { value: 1, text: 'Creados', parametro: 'existe', checked: false},
    { value: 2, text: 'Recibidos', parametro: 'existe', checked: false}
  ];
  constructor(
    private store: Store<AppState>,
    private ticketesService: TicketsService,
    // private titleService: Title
  ) {
    // this.titleService.setTitle(GLOBAL.title + this.heading);
    this.url = GLOBAL.url;
    this.paginacion.sortType = '-fecModificacion';
   }

  ngOnInit() {
    this.subscriptionAuth = this.store.select('auth').subscribe( state => {
      this.identity = {...state.identity};
      
      if(this.identity.tipo === 1 || this.identity.tipo === 2 ){
        this.existeFilter = [
          { value: 0, text: 'Solo lectura', parametro: 'existe', checked: false},
          { value: 1, text: 'Creados', parametro: 'existe', checked: false},
          { value: 2, text: 'Recibidos', parametro: 'existe', checked: false}
        ];
      }else{
        this.existeFilter = [
          { value: 1, text: 'Creados', parametro: 'existe', checked: false},
          { value: 2, text: 'Recibidos', parametro: 'existe', checked: false}
        ];
      }
     
    });
    this.subscriptionTickets = this.store.select('ticket').subscribe(
      (state) => {
        this.tickets = [...state.tickets];
      }
    );
    this.store.dispatch(new usuariosActions.CargarTickets());
    console.log('Inicializa component tickets...');
  }

  ngOnDestroy() {
    this.subscriptionAuth.unsubscribe();
    this.subscriptionTickets.unsubscribe();
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(' ');
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
  }

  editarEstado(ticket: Ticket){
    const text = ticket.status === 1 ? '¿Deseas eliminar el ticket?' : '¿Deseas cerrar el ticket?';
    Swal.fire({
      title: text,
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.ticketesService.actualizaEstatusTicket(ticket).subscribe(
            (response) => {
              if (response.respond) {
                this.store.dispatch(new usuariosActions.ActualizaTicketEstatusSuccess(ticket.id, ticket.status));
                resolve(true);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaTicketEstatusFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Actualización correctamente.',
            icon: 'success'
          });
        } else {
          Swal.close();
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  changeStatus() {
    this.statusFilter = [...this.statusFilter];
  }

  changeExiste() {
    this.existeFilter = [...this.existeFilter];
  }

}
