import {Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: []
})
export class RegisterComponent implements OnInit, OnDestroy {

  cargando: boolean;
  subscription: Subscription = new Subscription();
	slides = [
		{img: "", title: "Perfect Balance", text: "ArchitectUI is like a dream. Some think it's too good to be true! Extensive collection of unified Vue Bootstrap Components and Elements."}
  ];
	slideConfig = {
		className: 'center',
		centerMode: true,
		infinite: true,
		centerPadding: '0',
		slidesToShow: 1,
		speed: 500,
		dots: true,
		slidesToScroll : 1,
		autoplay : true,
		autoplaySpeed : 4000
	};

  constructor( public authService: AuthService,
               public store: Store<AppState> ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSubmit( data: any ) {
   //  this.authService.crearUsuario( data.nombre, data.email, data.password );
  }

  slickInit(e) {
    // console.log('slick initialized');
  }
  
  breakpoint(e) {
    // console.log('breakpoint');
  }
  
  afterChange(e) {
    // console.log('afterChange');
  }
  
  beforeChange(e) {
    // console.log('beforeChange');
  }

}
