import { Departamento } from './departamento';
import { Catalogo } from './catalogo';

export class User {
    public id: number;
    public usuario: string;
    public nombre: string;
	public tipo: number;
    // public division: number;
    // public desDivision: string;
	public desTipo: string;
	public departamento: Departamento[];
    public session: string;
    public apellidoPaterno: string;
    public apellidoMaterno: string;
    public email: string;
    public permisos: any;
    public registro: string;
    public descripcion: string;
    public password?: string;
    public passwordConfirmar?: string;
    public image?: string;
	public captcha?: string;
	public vacaciones?: number;
    public status?: number;
    public clientes: Catalogo[];
    constructor(
        obj: DataObjUser
    ) {
        this.id = obj && obj.id? obj.id : null;
        this.usuario = obj && obj.usuario? obj.usuario : '';
        this.nombre = obj && obj.nombre? obj.nombre : '';
        this.tipo = obj && obj.tipo? obj.tipo : 0;
        // this.division = obj && obj.division? obj.division : 1;
        // this.desDivision = obj && obj.desDivision? obj.desDivision : '';
        this.desTipo = obj && obj.desTipo? obj.desTipo : '';
        this.departamento = obj && obj.departamento? obj.departamento: [];
        this.session = obj && obj.session? obj.session : '';
        this.apellidoPaterno = obj && obj.apellidoPaterno? obj.apellidoMaterno : '';
        this.apellidoMaterno = obj && obj.apellidoMaterno? obj.apellidoMaterno : '';
        this.email = obj && obj.email? obj.email : '';
        this.permisos = obj && obj.permisos? obj.permisos : '';
        this.registro = obj && obj.registro? obj.registro : '';
        this.descripcion = obj && obj.descripcion? obj.descripcion : '';
        this.password = obj && obj.password? obj.password : '';
        this.passwordConfirmar = obj && obj.passwordConfirmar? obj.passwordConfirmar : '';
        this.image = obj && obj.image? obj.image : '';
        this.captcha = obj && obj.captcha? obj.captcha : '';
        this.vacaciones = obj && obj.vacaciones ? obj.vacaciones : 0;
        this.status = obj && obj.status ? obj.status : 0;
        this.clientes = obj && obj.clientes? obj.clientes: [];
    }
}

interface DataObjUser {
    id: number;
    usuario: string;
    nombre: string;
    tipo: number;
    // division: number;
    // desDivision: string;
    desTipo: string;
    departamento: Departamento[];
    session: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    email: string;
    permisos: any;
    registro: string;
    descripcion: string;
    password?: string;
    passwordConfirmar?: string;
    image?: string;
    captcha?: string;
    vacaciones?: number;
    status?: number;
    clientes: Catalogo[];
}

export class UserType {
    public id: number;
    public nombre: string;
    public checked: boolean;
    constructor(
      obj: ObjUserType
    ) {
      this.id = obj && obj.id ? obj.id : 0;
      this.nombre = obj && obj.nombre ? obj.nombre : '';
      this.checked = obj && obj.checked ? obj.checked : false;
    }
  }
  
  interface ObjUserType {
    id?: number;
    nombre?: string;
    checked?: boolean;
    descripcion?: string;
  }

export class UserChangePassword {
    constructor(
        public id: number,
        public contrasenaActual: string,
        public contrasena: string,
        public confirmaContrasena: string
    ) {}
}

export class Permisos {
    public usuariosListEdit: boolean;
    public usuariosListVer: boolean;
    public usuariosRegEdit: boolean;
    public usuariosRegVer: boolean;
    public usuarios: boolean;
    public home: boolean;
    public web: boolean;
    public catDepartamentosEdit: boolean;
    public catDepartamentosVer: boolean;
    public catDepartamentos: boolean;
    public actividadesRegVer: boolean;
    public actividadesRegEdit: boolean;
    public actividadesListEdit: boolean;
    public actividadesListVer: boolean; 
    public actividades: boolean; 
    public vacacionesEdit: boolean; 
    public vacacionesVer: boolean; 
    public seguimientoCumplimientoVer: boolean;
    public seguimientoCumplimientoEdit: boolean;
	public catDivisionesEdit: boolean;
	public catDivisionesVer: boolean;
    public catDivisiones: boolean;
	public catSucursalesEdit: boolean;
	public catSucursalesVer: boolean;
	public catSucursales: boolean;
	public catColoresEdit: boolean;
	public catColoresVer: boolean;
	public catColores: boolean;
	public catServiciosEdit: boolean;
	public catServiciosVer: boolean;
	public catServicios: boolean;
	public indicadorRegVer: boolean;
	public indicadorRegEdit: boolean;
	public indicadorListEdit: boolean;
	public indicadorListVer: boolean;
    public indicador: boolean;
    public indicadorCapturar: boolean;
    public catClienteEdit: boolean;
    public catClienteVer: boolean;
    public catCliente: boolean;
    public asignacionRegVer: boolean;
    public asignacionRegEdit: boolean;
    public asignacionListEdit: boolean;
    public asignacionListVer: boolean;
    public asignacion: boolean;
    public asignacionCapturar: boolean;
    public catTipoServicioEdit: boolean;
    public catTipoServicioVer: boolean;
    public catTipoServicio: boolean;
    public rptCoberturaDia: boolean;
    public rptCobertura: boolean;
    public catIndustriasEdit: boolean;
    public catIndustriasVer: boolean;
    public catIndustrias: boolean;
    public oportunidadesListEdit: boolean;
    public oportunidadesListVer: boolean;
    public oportunidadesRegEdit: boolean;
    public oportunidadesRegVer: boolean;
    public oportunidades: boolean;
    public contactosListEdit: boolean;
    public contactosListVer: boolean;
    public contactosRegEdit: boolean;
    public contactosRegVer: boolean;
    public contactos: boolean;
    public empresasListEdit: boolean;
    public empresasListVer: boolean;
    public empresasRegEdit: boolean;
    public empresasRegVer: boolean;
    public empresas: boolean;
    public catOrigenesEdit: boolean;
    public catOrigenesVer: boolean;
    public catOrigenes: boolean;
    public catPaisesEdit: boolean;
    public catPaisesVer: boolean;
    public catPaises: boolean;
    public contactosDownload: boolean;
    public empresasDownload: boolean;
    public oportunidadesDownload: boolean;
    public ticketsEdit: boolean;
    public ticketsVer: boolean;
    public rptKpiVendedores?: boolean;
    public rptCumpleannos?: boolean;
    public rptActividades?: boolean;
    public rptOportunidadEtapas?: boolean;
    public rptOportunidadPropietarios?: boolean;
    public rptServicioCop?: boolean;
    public rptServicioInicia?: boolean;
    public rptServicioTermina?: boolean;
    public rptBajaVacantes?: boolean;
    public rptIndicadorUsuario?: boolean;
    public rptIndicadorArea?: boolean;
    public rptIndicador?: boolean;
    public actividadesMultipleVer: boolean;
    public actividadesMultipleEdit: boolean;
    public actividadesMultiple: boolean; 

    constructor(
        obj: ObjDataPermisos
    ) {
        this.usuariosListEdit = obj && obj.usuariosListEdit ? obj.usuariosListEdit : false;
        this.usuariosListVer = obj && obj.usuariosListVer ? obj.usuariosListVer : false;
        this.usuariosRegEdit = obj && obj.usuariosRegEdit ? obj.usuariosRegEdit : false;
        this.usuariosRegVer = obj && obj.usuariosRegVer ? obj.usuariosRegVer : false;
        this.usuarios = obj && obj.usuarios ? obj.usuarios : false;
        this.home = obj && obj.home ? obj.home : false;
        this.web = obj && obj.web ? obj.web : false;
        this.catDepartamentosEdit= obj && obj.catDepartamentosEdit ? obj.catDepartamentosEdit : false;
        this.catDepartamentosVer = obj && obj.catDepartamentosVer ? obj.catDepartamentosVer : false;
        this.catDepartamentos= obj && obj.catDepartamentos ? obj.catDepartamentos : false;
        this.actividadesRegVer 	= obj && obj.actividadesRegVer ? obj.actividadesRegVer : false;
        this.actividadesRegEdit = obj && obj.actividadesRegEdit ? obj.actividadesRegEdit : false;
        this.actividadesListEdit = obj && obj.actividadesListEdit ? obj.actividadesListEdit : false;
        this.actividadesListVer = obj && obj.actividadesListVer ? obj.actividadesListVer : false; 
        this.actividades = obj && obj.actividades ? obj.actividades : false; 
        this.vacacionesEdit = obj && obj.vacacionesEdit ? obj.vacacionesEdit : false; 
        this.vacacionesVer 	= obj && obj.vacacionesVer ? obj.vacacionesVer : false; 
        this.seguimientoCumplimientoVer= obj && obj.seguimientoCumplimientoVer ? obj.seguimientoCumplimientoVer : false;
        this.seguimientoCumplimientoEdit= obj && obj.seguimientoCumplimientoEdit ? obj.seguimientoCumplimientoEdit : false;
        this.catDivisionesEdit = obj && obj.catDivisionesEdit ? obj.catDivisionesEdit : false;
        this.catDivisionesVer = obj && obj.catDivisionesVer ? obj.catDivisionesVer : false;
        this.catDivisiones = obj && obj.catDivisiones ? obj.catDivisiones : false;
        this.catSucursalesEdit = obj && obj.catSucursalesEdit ? obj.catSucursalesEdit : false;
        this.catSucursalesVer = obj && obj.catSucursalesVer ? obj.catSucursalesVer : false;
        this.catSucursales = obj && obj.catSucursales ? obj.catSucursales : false;
        this.catColoresEdit = obj && obj.catColoresEdit ? obj.catColoresEdit : false;
        this.catColoresVer = obj && obj.catColoresVer ? obj.catColoresVer : false;
        this.catColores = obj && obj.catColores ? obj.catColores : false;
        this.catServiciosEdit = obj && obj.catServiciosEdit ? obj.catServiciosEdit : false;
        this.catServiciosVer = obj && obj.catServiciosVer ? obj.catServiciosVer : false;
        this.catServicios = obj && obj.catServicios ? obj.catServicios : false;
        this.indicadorRegVer = obj && obj.indicadorRegVer ? obj.indicadorRegVer : false;
        this.indicadorRegEdit = obj && obj.indicadorRegEdit ? obj.indicadorRegEdit : false;
        this.indicadorListEdit = obj && obj.indicadorListEdit ? obj.indicadorListEdit : false;
        this.indicadorListVer = obj && obj.indicadorListVer ? obj.indicadorListVer : false;
        this.indicador = obj && obj.indicador ? obj.indicador : false;
        this.indicadorCapturar = obj && obj.indicadorCapturar ? obj.indicadorCapturar : false;
        this.catClienteEdit = obj && obj.catClienteEdit ? obj.catClienteEdit : false;
        this.catClienteVer = obj && obj.catClienteVer ? obj.catClienteVer : false;
        this.catCliente = obj && obj.catCliente ? obj.catCliente : false;
        this.asignacionRegVer = obj && obj.asignacionRegVer ? obj.asignacionRegVer : false;
        this.asignacionRegEdit = obj && obj.asignacionRegEdit ? obj.asignacionRegEdit : false;
        this.asignacionListEdit = obj && obj.asignacionListEdit ? obj.asignacionListEdit : false;
        this.asignacionListVer = obj && obj.asignacionListVer ? obj.asignacionListVer : false;
        this.asignacion = obj && obj.asignacion ? obj.asignacion : false;
        this.asignacionCapturar = obj && obj.asignacionCapturar ? obj.asignacionCapturar : false;
        this.catTipoServicioEdit = obj && obj.catTipoServicioEdit ? obj.catTipoServicioEdit : false;
        this.catTipoServicioVer = obj && obj.catTipoServicioVer ? obj.catTipoServicioVer : false;
        this.catTipoServicio = obj && obj.catTipoServicio ? obj.catTipoServicio : false;
        this.rptCoberturaDia = obj && obj.rptCoberturaDia ? obj.rptCoberturaDia : false;
        this.rptCobertura = obj && obj.rptCobertura ? obj.rptCobertura : false;
        this.catIndustriasEdit = obj && obj.catIndustriasEdit ? obj.catIndustriasEdit : false;
        this.catIndustriasVer = obj && obj.catIndustriasVer ? obj.catIndustriasVer : false;
        this.catIndustrias = obj && obj.catIndustrias ? obj.catIndustrias : false;
        this.oportunidadesListEdit = obj && obj.oportunidadesListEdit ? obj.oportunidadesListEdit : false;
        this.oportunidadesListVer = obj && obj.oportunidadesListVer ? obj.oportunidadesListVer : false;
        this.oportunidadesRegEdit = obj && obj.oportunidadesRegEdit ? obj.oportunidadesRegEdit : false;
        this.oportunidadesRegVer = obj && obj.oportunidadesRegVer ? obj.oportunidadesRegVer : false;
        this.oportunidades = obj && obj.oportunidades ? obj.oportunidades : false;
        this.contactosListEdit = obj && obj.contactosListEdit ? obj.contactosListEdit : false;
        this.contactosListVer = obj && obj.contactosListVer ? obj.contactosListVer : false;
        this.contactosRegEdit = obj && obj.contactosRegEdit ? obj.contactosRegEdit : false;
        this.contactosRegVer = obj && obj.contactosRegVer ? obj.contactosRegVer : false;
        this.contactos = obj && obj.contactos ? obj.contactos : false;
        this.empresasListEdit = obj && obj.empresasListEdit ? obj.empresasListEdit : false;
        this.empresasListVer = obj && obj.empresasListVer ? obj.empresasListVer : false;
        this.empresasRegEdit = obj && obj.empresasRegEdit ? obj.empresasRegEdit : false;
        this.empresasRegVer = obj && obj.empresasRegVer ? obj.empresasRegVer : false;
        this.empresas = obj && obj.empresas ? obj.empresas : false;
        this.catOrigenesEdit = obj && obj.catOrigenesEdit ? obj.catOrigenesEdit : false;
        this.catOrigenesVer = obj && obj.catOrigenesVer ? obj.catOrigenesVer : false;
        this.catOrigenes = obj && obj.catOrigenes ? obj.catOrigenes : false;
        this.catPaisesEdit = obj && obj.catPaisesEdit ? obj.catPaisesEdit : false;
        this.catPaisesVer = obj && obj.catPaisesVer ? obj.catPaisesVer : false;
        this.catPaises = obj && obj.catPaises ? obj.catPaises : false;
        this.contactosDownload = obj && obj.contactosDownload ? obj.contactosDownload : false;
        this.empresasDownload = obj && obj.empresasDownload ? obj.empresasDownload : false;
        this.oportunidadesDownload = obj && obj.oportunidadesDownload ? obj.oportunidadesDownload : false;
        this.ticketsEdit = obj && obj.ticketsEdit ? obj.ticketsEdit : false;
        this.ticketsVer = obj && obj.ticketsVer ? obj.ticketsVer : false;
        this.rptKpiVendedores = obj && obj.rptKpiVendedores ? obj.rptKpiVendedores : false;
        this.rptCumpleannos = obj && obj.rptCumpleannos ? obj.rptCumpleannos : false;
        this.rptActividades = obj && obj.rptActividades ? obj.rptActividades : false;
        this.rptOportunidadEtapas = obj && obj.rptOportunidadEtapas ? obj.rptOportunidadEtapas : false;
        this.rptOportunidadPropietarios = obj && obj.rptOportunidadPropietarios ? obj.rptOportunidadPropietarios : false;
        this.rptServicioCop = obj && obj.rptServicioCop ? obj.rptServicioCop : false;
        this.rptServicioInicia = obj && obj.rptServicioInicia ? obj.rptServicioInicia : false;
        this.rptServicioTermina = obj && obj.rptServicioTermina ? obj.rptServicioTermina : false;
        this.rptBajaVacantes = obj && obj.rptBajaVacantes ? obj.rptBajaVacantes : false;
        this.rptIndicadorUsuario = obj && obj.rptIndicadorUsuario ? obj.rptIndicadorUsuario : false;
        this.rptIndicadorArea = obj && obj.rptIndicadorArea ? obj.rptIndicadorArea : false;
        this.rptIndicador = obj && obj.rptIndicador ? obj.rptIndicador : false;
        this.actividadesMultipleVer = obj && obj.actividadesMultipleVer ? obj.actividadesMultipleVer : false;
        this.actividadesMultipleEdit = obj && obj.actividadesMultipleEdit ? obj.actividadesMultipleEdit : false;
        this.actividadesMultiple = obj && obj.actividadesMultiple ? obj.actividadesMultiple : false;
    }
}

interface ObjDataPermisos {
    usuariosListEdit: boolean;
    usuariosListVer: boolean;
    usuariosRegEdit: boolean;
    usuariosRegVer: boolean;
    usuarios: boolean;
    home: boolean;
    web: boolean;
    catDepartamentosEdit: boolean;
    catDepartamentosVer: boolean;
    catDepartamentos: boolean;
    actividadesRegVer: boolean;
    actividadesRegEdit: boolean;
    actividadesListEdit: boolean;
    actividadesListVer: boolean; 
    actividades: boolean; 
    vacacionesEdit: boolean; 
    vacacionesVer: boolean; 
    seguimientoCumplimientoVer: boolean;
    seguimientoCumplimientoEdit: boolean;
	catDivisionesEdit: boolean;
	catDivisionesVer: boolean;
    catDivisiones: boolean;
	catSucursalesEdit: boolean;
	catSucursalesVer: boolean;
	catSucursales: boolean;
	catColoresEdit: boolean;
	catColoresVer: boolean;
	catColores: boolean;
	catServiciosEdit: boolean;
	catServiciosVer: boolean;
	catServicios: boolean;
	indicadorRegVer: boolean;
	indicadorRegEdit: boolean;
	indicadorListEdit: boolean;
	indicadorListVer: boolean;
    indicador: boolean;
    indicadorCapturar: boolean;
    catClienteEdit: boolean;
    catClienteVer: boolean;
    catCliente: boolean;
    asignacionRegVer: boolean;
    asignacionRegEdit: boolean;
    asignacionListEdit: boolean;
    asignacionListVer: boolean;
    asignacion: boolean;
    asignacionCapturar: boolean;
    catTipoServicioEdit: boolean;
    catTipoServicioVer: boolean;
    catTipoServicio: boolean;
    rptCoberturaDia: boolean;
    rptCobertura: boolean;
    catIndustriasEdit: boolean;
    catIndustriasVer: boolean;
    catIndustrias: boolean;
    oportunidadesListEdit: boolean;
    oportunidadesListVer: boolean;
    oportunidadesRegEdit: boolean;
    oportunidadesRegVer: boolean;
    oportunidades: boolean;
    contactosListEdit: boolean;
    contactosListVer: boolean;
    contactosRegEdit: boolean;
    contactosRegVer: boolean;
    contactos: boolean;
    empresasListEdit: boolean;
    empresasListVer: boolean;
    empresasRegEdit: boolean;
    empresasRegVer: boolean;
    empresas: boolean;
    catOrigenesEdit: boolean;
    catOrigenesVer: boolean;
    catOrigenes: boolean;
    catPaisesEdit: boolean;
    catPaisesVer: boolean;
    catPaises: boolean;
    contactosDownload: boolean;
    empresasDownload: boolean;
    oportunidadesDownload: boolean;
    ticketsEdit: boolean;
    ticketsVer: boolean;
    rptKpiVendedores?: boolean;
    rptCumpleannos?: boolean;
    rptActividades?: boolean;
    rptOportunidadEtapas?: boolean;
    rptOportunidadPropietarios?: boolean;
    rptServicioCop?: boolean;
    rptServicioInicia?: boolean;
    rptServicioTermina?: boolean;
    rptBajaVacantes?: boolean;
    rptIndicadorUsuario?: boolean;
    rptIndicadorArea?: boolean;
    rptIndicador?: boolean;
    actividadesMultipleVer: boolean;
    actividadesMultipleEdit: boolean;
    actividadesMultiple: boolean; 
}

export class Identity {
    public id: number;
    public usuario: string;
    public apellidos: string;
    public nombre: string;
    public email: string;
    public session: number;
    public tipo: number;
    public desTipo: string;
    public registro: Date;
    public permisos: Permisos;
    public departamentos: Departamento[];
    public clientes: Catalogo[];
    constructor(
        obj: ObjDataIdentity
    ) {
        this.id = obj && obj.id ? obj.id : null;
        this.usuario = obj && obj.usuario ? obj.usuario : null;
        this.apellidos = obj && obj.apellidos ? obj.apellidos : null;
        this.nombre = obj && obj.nombre ? obj.nombre : null;
        this.email = obj && obj.email ? obj.email : null;
        this.session = obj && obj.session ? obj.session : null;
        this.tipo = obj && obj.tipo ? obj.tipo : null;
        this.desTipo = obj && obj.desTipo ? obj.desTipo : null;
        this.registro = obj && obj.registro ? obj.registro : null;
        this.permisos = obj && obj.permisos ? obj.permisos : new Permisos(null);
        this.departamentos = obj && obj.departamentos? obj.departamentos: [];
        this.clientes = obj && obj.clientes? obj.clientes: [];
    }
}

interface ObjDataIdentity {
    id?: number;
    usuario?: string;
    apellidos?: string;
    nombre?: string;
    email?: string;
    session?: number;
    tipo?: number;
    desTipo?: string;
    registro?: Date;
    permisos?: Permisos;
    departamentos: Departamento[];
    clientes: Catalogo[];
}

export class FilterType {
    public id: number;
    public nombre: string;
    public checked: boolean;
    constructor(
      obj: ObjFilterType
    ) {
      this.id = obj && obj.id ? obj.id : 0;
      this.nombre = obj && obj.nombre ? obj.nombre : '';
      this.checked = obj && obj.checked ? obj.checked : false;
    }
  }
  
  interface ObjFilterType {
    id: number;
    nombre: string;
    checked: boolean;
  }

export class Suscripciones {
    public id: number;
    public nombre: string;
    public checked: boolean;
    constructor( obj: ObjSuscripciones) {
      this.id = obj && obj.id ? obj.id : 0;
      this.nombre = obj && obj.nombre ? obj.nombre : '';
      this.checked = obj && obj.checked ? obj.checked : false;
    }
  }
  
  interface ObjSuscripciones {
    id: number;
    nombre: string;
    checked: boolean;
  }
