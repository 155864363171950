
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { SettingProfileComponent } from './users/setting-profile/setting-profile.component';
// import { EditUserComponent } from './users/edit-user/edit-user.component';
import { ListUsersComponent } from './users/list-users/list-users.component';
// import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
// import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LayoutModule } from 'src/app/layout/layout.module';
import { DecorationsModule } from 'src/app/decorations/decorations.module';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { MomentModule } from 'ngx-moment';
// import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
// import { FlatpickrModule } from 'angularx-flatpickr';
// import { CalendarModule } from 'angular-calendar';

// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { SortablejsModule } from 'ngx-sortablejs';
import { CountUpModule } from 'ngx-countup';
// import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment.prod';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { TextMaskModule } from 'angular2-text-mask';
// import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
// import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { effectsArr } from 'src/app/store/effects';
import { appReducers } from 'src/app/store/app.reducer';
import { MetaModule } from '@ngx-meta/core';
import { StorageModule } from '@ngx-pwa/local-storage';
// import { CookieService } from 'ngx-cookie-service';
import { EncrDecrService } from 'src/app/services/encr-decr.service';
import { ConfigActions } from 'src/app/themeoptions/store/config.actions';
import { TokenInterceptorService } from 'src/app/services/token-interceptor.service';
import { NgApexchartsModule } from 'ng-apexcharts';
// import { GaugeModule } from 'angular-gauge';
// import { TrendModule } from 'ngx-trend';
import { ChartsModule } from 'ng2-charts';

import { NotAuthorizationComponent } from './not-authorization/not-authorization.component';
// import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MaterialModule } from 'src/app/modules/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from 'ckeditor4-angular';
import { TrendModule } from 'ngx-trend';
import { AutonumericModule } from '@angularfy/autonumeric';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

/* Custom Hammer configuration */
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

import { AccumulationChartModule, AccumulationDataLabelService, AccumulationLegendService, AccumulationTooltipService, FunnelSeriesService } from '@syncfusion/ej2-angular-charts';
import { NgxPrintModule } from 'ngx-print';






export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: Hammer.DIRECTION_ALL,
    }
  }
}




import { NotFoundRecordComponent } from './layout/not-found-record/not-found-record.component';
import { OptionFilterCollapseComponent } from './layout/option-filter-collapse/option-filter-collapse.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditComponent } from './users/edit/edit.component';
import { ActividadesComponent } from './actividades/actividades/actividades.component';
import { ListActividadesComponent } from './actividades/list-actividades/list-actividades.component';
import { ReporteActividadesComponent } from './actividades/reportes/reporte-actividades.component';
import { CumplimientoComponent } from './actividades/cumplimiento/cumplimiento.component';
import { DivisionComponent } from './catalogos/divisiones/division.component';
import { ServiciosComponent } from './catalogos/servicios/servicios.component';
import { SucursalesComponent } from './catalogos/sucursales/sucursales.component';
import { IndicadorComponent } from './indicadores/indicador/indicador.component';
import { ColoresComponent } from './catalogos/colores/colores.component';
import { ListIndicadoresComponent } from './indicadores/list-indicadores/list-indicadores.component';
import { CapturaIndicadorComponent } from './indicadores/captura-indicador/captura-indicador.component';
import { TableroKpisAreaComponent } from './indicadores/tablero-kpi/tablero-kpis-area/tablero-kpis-area.component'
import { TableroKpisIndicadorComponent } from './indicadores/tablero-kpi/tablero-kpis-indicador/tablero-kpis-indicador.component';
import { TableroKpisUsuarioComponent } from './indicadores/tablero-kpi/tablero-kpis-usuario/tablero-kpis-usuario.component';
import { AreasComponent } from './catalogos/areas/areas.component';
import { LisAsignacionesComponent } from './asignaciones/lis-asignaciones/lis-asignaciones.component';
import { EditAsignacionComponent } from './asignaciones/edit/edit.component';
import { ListClientesComponent } from './catalogos/clientes/list-clientes.component';
import { TipoServicioComponent } from './catalogos/tipo-servicio/tipo-servicio.component';
import { CapturaAsignacionComponent } from './asignaciones/captura-asignacion/captura-asignacion.component';
import { HistorialAsignacionComponent } from './historial/historial-asignacion/historial-asignacion.component';
import { ReporteAsignacionDiasComponent } from './reportes/reporte-asignacion-dias/reporte-asignacion-dias.component';
import { ReporteAsignacionComponent } from './reportes/reporte-asignacion/reporte-asignacion.component';
import { ReporteCoberturaServicioComponent } from './reportes/reporte-cobertura-servicio/reporte-cobertura-servicio.component';
import { ReporteAsignacionIniciaComponent } from './reportes/reporte-asignacion-inicia/reporte-asignacion-inicia.component';
import { ReporteAsignacionFinalizaComponent } from './reportes/reporte-asignacion-finaliza/reporte-asignacion-finaliza.component';
import { ReporteAsignacionBajasVacantesComponent } from './reportes/reporte-asignacion-bajas-vacantes/reporte-asignacion-bajas-vacantes.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { EmpresasEditComponent } from './empresas/empresas-edit/empresas-edit.component';
import { PaisesComponent } from './catalogos/paises/paises.component';
import { OrigenContactosComponent } from './catalogos/origen-contactos/origen-contactos.component';
import { IndustriasComponent } from './catalogos/industrias/industrias.component';
import { ContactosComponent } from './contactos/contactos.component';
import { EditContactoComponent } from './contactos/edit-contacto/edit-contacto.component';
import { CargaContactosComponent } from './contactos/carga-contactos/carga-contactos.component';
import { OportunidadesComponent } from './oportunidades/oportunidades.component';
import { OportunidadesEditComponent } from './oportunidades/oportunidades-edit/oportunidades-edit.component';
import { OportunidadProfileComponent } from './oportunidades/oportunidad-profile/oportunidad-profile.component';
import { OportunidadProfileActionsComponent } from './oportunidades/oportunidad-profile-actions/oportunidad-profile-actions.component';
import { EmpresaProfileComponent } from './empresas/empresa-profile/empresa-profile.component';
import { EmpresaProfileActionsComponent } from './empresas/empresa-profile-actions/empresa-profile-actions.component';
import { ContactoProfileComponent } from './contactos/contacto-profile/contacto-profile.component';
import { ContactoProfileActionsComponent } from './contactos/contacto-profile-actions/contacto-profile-actions.component';
import { ReporteCumpleannosComponent } from './reportes/reporte-cumpleannos/reporte-cumpleannos.component';
import { ReporteKpisVendedoresComponent } from './reportes/reporte-kpis-vendedores/reporte-kpis-vendedores.component';
import { ReporteVentasEtapasComponent } from './reportes/reporte-ventas-etapas/reporte-ventas-etapas.component';
import { ReporteVentasPropietariosComponent } from './reportes/reporte-ventas-propietarios/reporte-ventas-propietarios.component';
import { KpisVendedoresComponent } from './kpis-vendedores/kpis-vendedores.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketsEditComponent } from './tickets/tickets-edit/tickets-edit.component';
import { ItemTicketComponent } from './tickets/item-ticket/item-ticket.component';
import { DetailsTicketsComponent } from './tickets/details-tickets/details-tickets.component';
import { ReportesComponent } from './reportes/reportes.component';
import { ReporteMenuComponent } from './reportes/reporte-menu/reporte-menu.component';
import { UsuariosConfigComponent } from './config/usuarios-config/usuarios-config.component';





// import {NgxCurrencyModule} from "ngx-currency";
import { NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {ColorPickerModule} from 'ngx-color-picker';



// import {PdfViewerModule} from 'ng2-pdf-viewer';
// import {GaugeModule} from 'angular-gauge';
// import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
// import {AngularFontAwesomeModule} from 'angular-font-awesome';
// import {LaddaModule} from 'angular2-ladda';
// import {CalendarModule, DateAdapter} from 'angular-calendar';
// import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
// import {AgmCoreModule} from '@agm/core';
// import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
// import {AngularStickyThingsModule} from '@w11k/angular-sticky-things';
// import {NouisliderModule} from 'ng2-nouislider';
// import {TextMaskModule} from 'angular2-text-mask';
// import {ClipboardModule} from 'ngx-clipboard';
// import {TextareaAutosizeModule} from 'ngx-textarea-autosize';
// import {DropzoneModule} from 'ngx-dropzone-wrapper';
// import {DROPZONE_CONFIG} from 'ngx-dropzone-wrapper';
// import {DropzoneConfigInterface} from 'ngx-dropzone-wrapper';


// const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
//   // Change this to your upload POST address:
//   url: 'https://httpbin.org/post',
//   maxFilesize: 50,
//   acceptedFiles: 'image/*'
// };



//LOCATE
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-MX';
import { ReportesOperacionesComponent } from './reportes/reportes-operaciones/reportes-operaciones.component';
import { ReportesListCrmComponent } from './reportes/reportes-list-crm/reportes-list-crm.component';
import { UsuarioConfigEditComponent } from './config/usuario-config-edit/usuario-config-edit.component';
import { UsuarioConfigSmieComponent } from './config/usuario-config-smie/usuario-config-smie.component';
import { UsuarioConfigAdminComponent } from './config/usuario-config-admin/usuario-config-admin.component';
import { UsuarioConfigCrmComponent } from './config/usuario-config-crm/usuario-config-crm.component';
import { UsuarioConfigTodosComponent } from './config/usuario-config-todos/usuario-config-todos.component';
import { DashboardClientesComponent } from './dashboard/dashboard-clientes/dashboard-clientes.component';
import { DashboardUsuariosComponent } from './dashboard/dashboard-usuarios/dashboard-usuarios.component';
import { UsuarioConfigTicketComponent } from './config/usuario-config-ticket/usuario-config-ticket.component';
registerLocaleData(localeEs, 'es');
















@NgModule({
  declarations: [
    DashboardComponent,
    // User, Pages,
    ChangePasswordComponent,
    SettingProfileComponent,
    EditComponent,
    ListUsersComponent,
    // NotificacionesComponent,
    NotAuthorizationComponent,
    UsuariosConfigComponent,
    NotFoundRecordComponent,
    OptionFilterCollapseComponent,
    ActividadesComponent,
    ListActividadesComponent,
    ReporteActividadesComponent,
    CumplimientoComponent,
    DivisionComponent,
    ServiciosComponent,
    SucursalesComponent,
    IndicadorComponent,
    ColoresComponent,
    ListIndicadoresComponent,
    CapturaIndicadorComponent,
    TableroKpisAreaComponent,
    TableroKpisIndicadorComponent,
    TableroKpisUsuarioComponent,
    AreasComponent,
    LisAsignacionesComponent,
    EditAsignacionComponent,
    ListClientesComponent,
    TipoServicioComponent,
    CapturaAsignacionComponent,
    HistorialAsignacionComponent,
    ReporteAsignacionDiasComponent,
    ReporteAsignacionComponent,
    ReporteCoberturaServicioComponent,
    ReporteAsignacionIniciaComponent,
    ReporteAsignacionFinalizaComponent,
    ReporteAsignacionBajasVacantesComponent,
    EmpresasComponent,
    EmpresasEditComponent,
    PaisesComponent,
    OrigenContactosComponent,
    IndustriasComponent,
    ContactosComponent,
    EditContactoComponent,
    CargaContactosComponent,
    OportunidadesComponent,
    OportunidadesEditComponent,
    OportunidadProfileComponent,
    OportunidadProfileActionsComponent,
    EmpresaProfileComponent,
    EmpresaProfileActionsComponent,
    ContactoProfileComponent,
    ContactoProfileActionsComponent,
    ReporteCumpleannosComponent,
    ReporteKpisVendedoresComponent,
    ReporteVentasEtapasComponent,
    ReporteVentasPropietariosComponent,
    KpisVendedoresComponent,
    TicketsComponent,
    TicketsEditComponent,
    ItemTicketComponent,
    DetailsTicketsComponent,
    ReportesComponent,
    ReporteMenuComponent,
    ReportesOperacionesComponent,
    ReportesListCrmComponent,
    UsuarioConfigEditComponent,
    UsuarioConfigSmieComponent,
    UsuarioConfigAdminComponent,
    UsuarioConfigCrmComponent,
    UsuarioConfigTodosComponent,
    DashboardClientesComponent,
    DashboardUsuariosComponent,
    UsuarioConfigTicketComponent
  ],
  imports: [
    FormsModule,
    CKEditorModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    // LoadingBarRouterModule,
    LayoutModule,
    DecorationsModule,
    PipesModule,
    // NgxExtendedPdfViewerModule,
    MaterialModule,
    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    // AngularFontAwesomeModule,
      // LaddaModule,
    ReactiveFormsModule,
    // NgBootstrapFormValidationModule.forRoot(),
    LoadingBarRouterModule,
    BsDatepickerModule.forRoot(),
    AutonumericModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
      fullScreenBackdrop: true,
    }),
    RoundProgressModule,
    MomentModule,
    // SweetAlert2Module.forRoot({
    //   buttonsStyling: false,
    //   customClass: 'modal-content',
    //   confirmButtonClass: 'btn btn-primary',
    //   cancelButtonClass: 'btn btn-secondary'
    // }),
    ToastrModule,
    SlickCarouselModule,
    // FlatpickrModule.forRoot(),
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory
    // }),
    // SortablejsModule.forRoot({ animation: 150 }),
    CountUpModule,
    // AgmCoreModule.forRoot({
    //   apiKey: environment.apiKeyMaps
    // }),
    ImageCropperModule,
      // AngularStickyThingsModule,
      // NouisliderModule,
    NgSelectModule,
    SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    AngularEditorModule,
    HttpClientModule,


    // TextMaskModule,
    // ClipboardModule,
    // TextareaAutosizeModule,
    ColorPickerModule,
    // DropzoneModule,

    //   // Charts

    ChartsModule,
    NgApexchartsModule,
    // GaugeModule.forRoot(),
    TrendModule,
    StoreModule,
    StoreDevtoolsModule,
    EffectsModule,
    EffectsModule.forRoot(effectsArr),
    MetaModule,
    StorageModule,
    NgxPrintModule,
    FontAwesomeModule,
    AccumulationChartModule,
    NgxPrintModule,

    NgxExtendedPdfViewerModule
  ],
  providers: [
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,

    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    ConfigActions,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    EncrDecrService,
    // { provide: SAVER, useFactory: getSaver },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    // CookieService,


    AccumulationDataLabelService,
    AccumulationLegendService,
    AccumulationTooltipService,
    FunnelSeriesService
  ],
  exports: [
    // User Pages
    ChangePasswordComponent,
    SettingProfileComponent,
    EditComponent,
    ListUsersComponent,
    // NotificacionesComponent,
    NotAuthorizationComponent,
    UsuariosConfigComponent,
    NotFoundRecordComponent,
    OptionFilterCollapseComponent,
    ActividadesComponent,
    ListActividadesComponent,
    ReporteActividadesComponent,
    CumplimientoComponent,
    DivisionComponent,
    ServiciosComponent,
    SucursalesComponent,
    IndicadorComponent,
    ColoresComponent,
    ListIndicadoresComponent,
    CapturaIndicadorComponent,
    TableroKpisAreaComponent,
    TableroKpisIndicadorComponent,
    TableroKpisUsuarioComponent,
    AreasComponent,
    LisAsignacionesComponent,
    EditAsignacionComponent,
    ListClientesComponent,
    TipoServicioComponent,
    CapturaAsignacionComponent,
    HistorialAsignacionComponent,
    ReporteAsignacionDiasComponent,
    ReporteAsignacionComponent,
    ReporteCoberturaServicioComponent,
    ReporteAsignacionIniciaComponent,
    ReporteAsignacionFinalizaComponent,
    ReporteAsignacionBajasVacantesComponent,
    EmpresasComponent,
    EmpresasEditComponent,
    PaisesComponent,
    OrigenContactosComponent,
    IndustriasComponent,
    ContactosComponent,
    EditContactoComponent,
    CargaContactosComponent,
    OportunidadesComponent,
    OportunidadesEditComponent,
    OportunidadProfileComponent,
    OportunidadProfileActionsComponent,
    EmpresaProfileComponent,
    EmpresaProfileActionsComponent,
    ContactoProfileComponent,
    ContactoProfileActionsComponent,
    ReporteCumpleannosComponent,
    ReporteKpisVendedoresComponent,
    ReporteVentasEtapasComponent,
    ReporteVentasPropietariosComponent,
    KpisVendedoresComponent,
    TicketsComponent,
    TicketsEditComponent,
    ItemTicketComponent,
    DetailsTicketsComponent,
    ReportesComponent,
    ReporteMenuComponent
  ],
  bootstrap: [
    // ModalListaCampanaComponent,
  ],
  entryComponents: [
    // ModalListaCampanaComponent
  ]
})
export class AuthModule { }
