import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { SucursalesService } from 'src/app/services/sucursales.service';

@Injectable()
export class SucursalesEffects {

  constructor(
    private actions$: Actions,
    public sucursalesService: SucursalesService
  ) {}

  @Effect()
  cargarSucursales$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_SUCURSALES ),
    switchMap( () => {
      return this.sucursalesService.obtenerSucursales()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarSucursalesSuccess(respuesta.sucursales , respuesta.divisiones);
          }),
          catchError( error => of(new usuariosActions.CargarSucursalesFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusSucursales$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_SUCURSAL_ESTATUS ),
    switchMap( (action: any) => {
      return this.sucursalesService.actualizaEstatusSucursal(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaSucursalEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.CargarSucursalesFail(error))  )
        );
    })
  );

  @Effect()
  actualizaSucursales$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_SUCURSAL ),
    switchMap( (action: any) => {
      return this.sucursalesService.registraSucursal(action.sucursal)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaSucursalSuccess(action.sucursal);
            }),
            catchError( error => of(new usuariosActions.CargarSucursalesFail(error))  )
        );
    })
  );

}
