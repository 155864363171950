import { Component, OnInit, OnDestroy } from '@angular/core';
import { Actividad } from 'src/app/models/actividades';
import { ActividadesService } from 'src/app/services/actividades.service';
import { ActivatedRoute,Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Identity } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GENERALES } from 'src/app/models/generales';
// import { UploadService } from 'src/app/Services/upload.service';
import { GLOBAL } from 'src/app/models/global';
import { Archivo } from 'src/app/models/archivo';
import { Anexo } from 'src/app/models/anexo';
import { Imagen } from 'src/app/models/imagen';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html',
  styleUrls: ['./actividades.component.sass']
})
export class ActividadesComponent implements OnInit, OnDestroy {
	heading = 'Detalle de actividad';
  	subheading = 'Descripción y archivos de la actividad registrada en el sistema';
  	icon = ' pe-7s-id icon-gradient bg-happy-fisher';
	public page: number;
	public actividad: Actividad;
	public actividadInicial: Actividad;
	public status: string;
	public identity: Identity;
	public subcriptionIdentity:	Subscription ;
	public filesToUpload: Array<File> = [];
	public textFile: string = '';
	public images: Imagen[] = [];
	public anexo: Anexo ;
	public archivos: Archivo[] = [];
	public adjunto: Archivo;
	public adjuntoModal: Archivo;
	public actividadId = 0;
	public loading = false;
	private token = '';
	public url = '';
	public closeResult: string; 
	public fecha: string;
	public permiso : boolean;
	public datePipe = new DatePipe("en-US");
	// public config: AngularEditorConfig = {
	// 	editable: true,
	// 	spellcheck: true,
	// 	height: '15rem',
	// 	minHeight: '5rem',
	// 	placeholder: 'Enter text here...',
	// 	translate: 'no',
	// 	customClasses: [
	// 	  {
	// 		name: 'quote',
	// 		class: 'quote',
	// 	  },
	// 	  {
	// 		name: 'redText',
	// 		class: 'redText'
	// 	  },
	// 	  {
	// 		name: 'titleText',
	// 		class: 'titleText',
	// 		tag: 'h1',
	// 	  },
	// 	]
	//   };
	  public config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '15rem',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		placeholder: 'Descripción...',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
		  {class: 'arial', name: 'Arial'},
		  {class: 'times-new-roman', name: 'Times New Roman'},
		  {class: 'calibri', name: 'Calibri'},
		  {class: 'comic-sans-ms', name: 'Comic Sans MS'}
		],
		customClasses: [
		{
		  name: 'quote',
		  class: 'quote',
		},
		{
		  name: 'redText',
		  class: 'redText'
		},
		{
		  name: 'titleText',
		  class: 'titleText',
		  tag: 'h1',
		},
	  ],
	//   uploadUrl: 'v1/image',
	//   uploadWithCredentials: false,
	//   sanitize: true,
	//   toolbarPosition: 'top',
	//   toolbarHiddenButtons: [
	// 	['bold', 'italic'],
	// 	['fontSize']
	//   ]
	  };


	  
  constructor(
		private _actividadesService: ActividadesService,
		private authService: AuthService,
		// private uploadService: UploadService,
		private _route: ActivatedRoute,
		private store: Store<AppState>,
		private modalService: NgbModal,
		private toastrService: ToastrService,
		private router: Router
  ){
	this.actividad = new Actividad(null);
	this.actividadInicial = new Actividad(null);
	this.url = GLOBAL.url;
	this.subcriptionIdentity = new Subscription(); 
	this.anexo = new Anexo(null);
	this.adjunto = new Archivo(null);
}
	
  ngOnInit() {
	this.fecha= this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:ss');
	this.authService.getToken().subscribe(
		(request) => {
			this.token = request;
		}
	);
	this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
		this.identity = {...state.identity};
      }
    );
	this._route.params.subscribe(params => {
		const id = params.id;
		this.actividadId = id;
		if (id > 0) {
         this._actividadesService.getActividad(id).subscribe(
			response =>{
				if(!response.actividad){
		 		  this.status ='error';
				  
				}else{
					 this.actividad = {...response.actividad};
					 this.actividadInicial = {...response.actividad};
					 if(this.actividad["fecha"] >= this.fecha){
						this.permiso = true;
						this.status = 'success';
					 }else{
						this.permiso = false;
					 }
		 			
			  	}
			},error =>{
		 		var errorMenssage = <any>error;
		 		console.log(errorMenssage);
	
		 		if(errorMenssage != null){
		 			this.status ='error';
		 		}
			});
		}else{
			this.actividad = new Actividad (null);
		}
	});
	this._route.params.subscribe(params => {
		const id = params.id;
		if (id > 0) {
         this._actividadesService.getAnexo(id).subscribe(
			response =>{
				if(!response){
		 		  this.status ='error';
				  
				}else{
		 			this.archivos = response.anexo;
		 			this.status = 'success';
			  	}
			},error =>{
		 		var errorMenssage = <any>error;
		 		console.log(errorMenssage);
	
		 		if(errorMenssage != null){
		 			this.status ='error';
		 		}
			});
		}else{
			this.permiso = true;
			this.anexo = new Anexo (null);
		}
	});
	
	}

	ngOnDestroy() {
		this.subcriptionIdentity.unsubscribe();
	}

  onSubmit(form){
	this._route.params.subscribe(params => {
		const id = params.id;
		this.actividadId = id;
		if (id > 0) {
			this._actividadesService.updateActividad(this.actividad).subscribe(
				response => {
					if(response){
						this.status = 'success';
						this.router.navigate(['/auth/actividades']);
						this.toastrService.success('Se actualizó la activdad','Actividad actualizada');
						form.reset();
					}else{
						this.status = 'error';
					}
				},error =>{
					console.log(<any>error);
				});
		}else{
			this._actividadesService.registerActividad(this.actividad).subscribe(
				response => {
					if(response){
						this.status = 'success';
						this.router.navigate(['/auth/actividades']);
						this.toastrService.success('Se registró la actividad','Actividad registrada');
						form.reset();
					} else{
						this.status = 'error';
					}
				},error =>{
					console.log(<any>error);
			});
		}
	
	});
 }

 fileChangeEvent(event: any) {
	this.filesToUpload = <Array<File>>event.target.files;
	this.adjunto.file = <Array<File>>event.target.files;
	if (this.filesToUpload.length) {
	  this.adjunto.tipo = this.filesToUpload[0].type.split('/').pop();
	  this.adjunto.size = this.filesToUpload[0].size;
	  if (this.adjunto.tipo === 'jpeg' || this.adjunto.tipo === 'png' || this.adjunto.tipo === 'gif' || this.adjunto.tipo === 'pdf') {

	  } else {
		//  this._alert.warn('El formato del archivo no es compatible, agregue una imagen o pdf.');
		 this.adjunto.tipo = '';
		 this.textFile = '';
		 this.filesToUpload = [];
	  }
	} else {
	  this.adjunto.tipo = '';
	  this.filesToUpload = [];
	}
 }

 addFile() {
	if (this.adjunto.nombre !== '' && this.filesToUpload.length && this.adjunto.tipo !== '') {
		const item = this.archivos.find( element => element.nombre === this.adjunto.nombre);
		if (item && item.id) {
			//  this._alert.warn('El nombre de archivo ya existe');
		} else {
		this.loading = true;
		 const value = GENERALES.getMaxOfObjectArray(this.archivos, 'id');
		 this.adjunto.id = value ;
		 this.adjunto.folio = this.actividadId;
		 this.store.dispatch(new usuariosActions.RegistrarArchivo(this.url + '/actividades/anexos', [],
		 this.filesToUpload, this.token, 'files', this.adjunto));
		 this.adjunto = new Archivo(null);
		 this.textFile = '';

			setTimeout( () => {
				this._actividadesService.getAnexo(this.actividadId).subscribe(
					response =>{
						if(!response){
							 this.status ='error';
						}else{
							 this.archivos = response.anexo;
							 this.status = 'success';
							}
							this.loading = false;
					},error =>{
						 var errorMenssage = <any>error;
						 console.log(errorMenssage);
			
						 if(errorMenssage != null){
							 this.status ='error';
						 }
						 this.loading = false;
					});
			}, 2000 );



	  // const index = GENERALES.indexOfObject(this.archivos, 'nombre', this.adjunto.nombre);
	  // if (index <= -1) {
		//  this.loading = true;
		//  const value = GENERALES.getMaxOfObjectArray(this.archivos, 'id');
		//  this.adjunto.id = value ;
		//  this.adjunto.folio = this.actividadId;
		//  this.uploadService.makeFileRequest(this.url + '/actividades/anexos', [],
		//  this.filesToUpload, this.token, 'files', this.adjunto).then( (uploadImage: any) => {
		//  this.adjunto.nameFile = uploadImage.archivo;
		//  this.archivos.push(this.adjunto);
		//  this.adjunto = new Archivo(null);
		//  this.adjunto.folio = this.actividadId;
		//  this.textFile = '';
		//  this.loading = false;
		//  }).catch((err: any) => {
		// 	  console.log(err);
		// 	  this.loading = false;
		//  });
	  // } else {
		//  this._alert.warn('El nombre de archivo ya existe');
	  }
	}
 }


 deleteFile(file: Archivo) {
	this.loading = true;
	this._actividadesService.deleteFile(this.actividadId, file.id, file.nameFile).subscribe(
	  (response) => {
		 const index = GENERALES.indexOfObject(this.archivos, 'id', file.id);
		 if (index > -1) {
			this.archivos.splice(index, 1);
		 }
		 this.loading = false;
	  }, (error) => {
		//  this._alert.error('Ocurrió un error al eliminar archivo');
		 this.loading = false;
	  }
	);
 }

 openLarge(archivo: Archivo, content) {
	this.adjuntoModal = {...archivo};
	this.modalService.open(content, {
	  size: 'lg'
	});
 }

 private getDismissReason(reason: any): string {
	if (reason === ModalDismissReasons.ESC) {
	  return 'by pressing ESC';
	} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
	  return 'by clicking on a backdrop';
	} else {
	  return `with: ${reason}`;
	}
 }

}
