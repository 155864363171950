<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <!-- <div class="col-lg-1"> col-sm-7</div> -->
  <div class="col-lg-6">
		<form id="registerEmpresaForm" name="registerEmpresaForm" #registerEmpresaForm="ngForm" (ngSubmit)="onSubmit()" autocomplete="off">
			<div class="card main-card mb-3">
				<div class="card-header">
					Captura la información de la empresa
				</div>
				<div class="card-body">
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="nombreEmpresa" class="control-label">Nombre</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="nombreEmpresa" name="nombreEmpresa" #nombreEmpresa="ngModel" [(ngModel)]="empresa.nombre"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el nombre de la empresa." autocomplete="off" required />
								<!-- <span *ngIf="!nombreEmpresa.valid && nombreEmpresa.touched"> El nombre es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="imagenEmpresa" class="control-label">Logotipo</label>
							</div>
							<div class="col-sm-8">
								<div class="custom-file">
									<input type="file" class="custom-file-input"  lang="es" (change)="fileChangeEvent($event)" title="Seleccione una imagen." placeholder="Elige una imagen..." required accept="image/png, image/jpeg, image/jpg" id="validatedCustomFile">
									<label class="custom-file-label" for="validatedCustomFile">Elige un archivo...</label>
									<div class="invalid-feedback">Archivo invalido</div>
								</div>
								<image-cropper
									class="mt-3"
									[imageChangedEvent]="imageChangedEvent"
									[maintainAspectRatio]="true"
									[aspectRatio]="4 / 4"
									[resizeToWidth]="512"
									[cropperMinWidth]="512"
									[onlyScaleDown]="true"
									[roundCropper]="false"
									format="png"
									outputType="base64"
									(imageCropped)="imageCropped($event)"
									(imageLoaded)="imageLoaded()"
									(cropperReady)="cropperReady()"
									(loadImageFailed)="loadImageFailed()"
									[style.display]="showCropper ? null : 'none'"
								></image-cropper>


								<!-- <image-cropper
								class="mt-3"
								[imageChangedEvent]="imageChangedEvent"
								[maintainAspectRatio]="true"
								[aspectRatio]="4 / 3"
								[resizeToWidth]="128"
								[cropperMinWidth]="128"
								[onlyScaleDown]="true"
								[roundCropper]="false"
								format="png"
								outputType="base64"
								(imageCropped)="imageCropped($event)"
								(imageLoaded)="imageLoaded()"
								(cropperReady)="cropperReady()"
								(loadImageFailed)="loadImageFailed()"
								[style.display]="showCropper ? null : 'none'"
							></image-cropper> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required" *ngIf="identity.tipo <= 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="propietarioEmpresa" class="control-label">Propietario</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="propietarios" [disabled]="!identity.permisos.empresasRegEdit"
                  bindLabel="descripcion"
                  bindValue="id"
                  placeholder="Propietario de la empresa."
                  id="propietarioEmpresa"  name="propietarioEmpresa" #propietarioEmpresa= "ngModel" [(ngModel)]="empresa.propietario" required>
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="industriaEmpresa" class="control-label">Industria</label>
							</div>
							<div class="col-sm-8">
								<input list="industriaEmpresaList" type="text" id="industriaEmpresa" name="industriaEmpresa" #industriaEmpresa="ngModel" [(ngModel)]="empresa.desIndustria" class="form-control"
									title="Modelo de industrias" maxlength="100" placeholder="" [disabled]="!identity.permisos.empresasRegEdit" required />
								<datalist id="industriaEmpresaList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
									<option *ngFor="let item of industriasFiltradas" class="list-group-item" [ngValue]="item.nombre" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
								</datalist>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="urlEmpresa" class="control-label">Página web</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="urlEmpresa" name="urlEmpresa" #urlEmpresa="ngModel" [(ngModel)]="empresa.url"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture la página web." />
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group ">
						<div class="row">
							<div class="col-sm-4">
								<label for="telefonoUnoEmpresa" class="control-label">Teléfono</label>
							</div>
							<div class="col-sm-8">

								<div [ngClass]="{'form-group': telefonos > 1 }">
									<div class="input-group">
										<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="telefonoUnoEmpresa" name="telefonoUnoEmpresa" #telefonoUnoEmpresa="ngModel" [(ngModel)]="empresa.telefonoUno"
										class="form-control" maxlength="50" title="Capture un número de teléfono."  />
										<div class="input-group-append">
											<button type="button" class="btn btn-secondary"  (click)="cantidadTelefono(2)" [disabled]="!identity.permisos.empresasRegEdit || telefonos === 1">
												<i class="fas fa-minus"></i>
											</button>
											<button type="button" class="btn btn-primary" (click)="cantidadTelefono(1)" [disabled]="!identity.permisos.empresasRegEdit || telefonos === 3">
												<i class="fas fa-plus"></i>
											</button>
										</div>
									</div>
									<!-- <span *ngIf="!telefonoUnoEmpresa.valid && telefonoUnoEmpresa.touched"> El número de teléfono tiene un formato incorrecto</span> -->
								</div>
								<fieldset class="form-group " *ngIf="telefonos > 1">
									<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="telefonoDosEmpresa" name="telefonoDosEmpresa" #telefonoDosEmpresa="ngModel" [(ngModel)]="empresa.telefonoDos"
									class="form-control" maxlength="50" title="Capture un número de teléfono."  />
									<!-- <span *ngIf="!telefonoDosEmpresa.valid && telefonoDosEmpresa.touched"> El número de teléfono tiene un formato incorrecto</span> -->
								</fieldset>
								<fieldset class="" *ngIf="telefonos > 2">
									<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="telefonoTresEmpresa" name="telefonoTresEmpresa" #telefonoTresEmpresa="ngModel" [(ngModel)]="empresa.telefonoTres"
									class="form-control" maxlength="50" title="Capture un número de teléfono."  />
									<!-- <span *ngIf="!telefonoTresEmpresa.valid && telefonoTresEmpresa.touched"> El número de teléfono tiene un formato incorrecto</span> -->
								</fieldset>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="fundacionEmpresa" class="control-label">Fecha de fundación </label>
							</div>
							<div class="col-sm-8">
								<input type="text"class="form-control" id="fundacionEmpresa" name="fundacionEmpresa" #fundacionEmpresa="ngModel" bsDatepicker [(ngModel)]="empresa.fundacion" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}"  autocomplete="off" [disabled]="!identity.permisos.empresasRegEdit">
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="paisEmpresa" class="control-label">País</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="paises" (blur)="consultaCodigoPostal()"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Capture el país."
                  id="paisEmpresa" name="paisEmpresa" #paisEmpresa= "ngModel" [(ngModel)]="empresa.pais" [disabled]="!identity.permisos.empresasRegEdit">
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="codigoPostalEmpresa" class="control-label">Código postal</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="codigoPostalEmpresa"
									name="codigoPostalEmpresa" #codigoPostalEmpresa="ngModel" [(ngModel)]="empresa.codigoPostal" class="form-control" (blur)="consultaCodigoPostal()"
									placeholder="Número código postal" maxlength="100" minlength="1" title="Capture el código postal de la ubicación."/>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.pais === 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="estadoEmpresa" class="control-label">Estado</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="estados" (blur)="consultaMunicipio()"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Capture el estado."
                  id="estadoEmpresa"  name="estadoEmpresa" #estadoEmpresa= "ngModel" [(ngModel)]="empresa.estado" 
									[disabled]="!identity.permisos.empresasRegEdit || !editar">
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.pais === 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="municipioEmpresa" class="control-label">Municipio/Ciudad</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="municipios"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Capture el municipio."
                  id="municipioEmpresa"  name="municipioEmpresa" #municipioEmpresa= "ngModel" [(ngModel)]="empresa.municipio" 
									[disabled]="!identity.permisos.empresasRegEdit || !editarMunicipio || !editar">
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.pais !== 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="estadoEmpresa" class="control-label">Estado</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="estadoEmpresa" name="estadoEmpresa" #estadoEmpresa="ngModel" [(ngModel)]="empresa.desEstado"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el estado de la empresa."/>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.pais !== 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="municipioEmpresa" class="control-label">Municipio/Ciudad</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="municipioEmpresa" name="municipioEmpresa" #municipioEmpresa="ngModel" [(ngModel)]="empresa.desMunicipio"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el municipio de la empresa."  />
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="coloniaEmpresa" class="control-label">Colonia</label>
							</div>
							<div class="col-sm-8">
								<input list="coloniaEmpresaList" type="text" id="coloniaEmpresa" name="coloniaEmpresa" #coloniaEmpresa="ngModel" [(ngModel)]="empresa.colonia" class="form-control"
									title="Modelo de colonias" maxlength="100" placeholder="" [disabled]="!identity.permisos.empresasRegEdit"/>
								<datalist id="coloniaEmpresaList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
									<option *ngFor="let item of colonias" class="list-group-item" [ngValue]="item.nombre" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
								</datalist>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="calleEmpresa" class="control-label">Calle y número</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="calleEmpresa" name="calleEmpresa" #calleEmpresa="ngModel" [(ngModel)]="empresa.calle"
									class="form-control" maxlength="100" minlength="1" placeholder="" title="Capture la calle." />
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="opcDatoFiscalEmpresa" class="control-label">Datos fiscales</label>
							</div>
							<div class="col-sm-8">
								<bSwitch
									id="opcDatoFiscalEmpresa"
									name="opcDatoFiscalEmpresa"
									#opcDatoFiscalEmpresa="ngModel"
									[(ngModel)]="empresa.opcDatoFiscal"
									[switch-size]="'normal'"
									[switch-on-text]="'Si'"
									[switch-off-text]="'No'"
									[switch-on-color]="'primary'"
									[switch-off-color]="'danger'"
								></bSwitch>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="rfcEmpresa" class="control-label">RFC</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="rfcEmpresa" name="rfcEmpresa" #rfcEmpresa="ngModel" [(ngModel)]="empresa.rfc"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el RFC de la empresa." required />
								<!-- <span *ngIf="!rfcEmpresa.valid && rfcEmpresa.touched"> El RFC es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="razonSocialEmpresa" class="control-label">Razón social</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="razonSocialEmpresa" name="razonSocialEmpresa" #razonSocialEmpresa="ngModel" [(ngModel)]="empresa.razonSocial"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture la razón social de la empresa." required />
								<!-- <span *ngIf="!razonSocialEmpresa.valid && razonSocialEmpresa.touched"> La razón social es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="cuentasPagarEmpresa" class="control-label">Contacto cuentas por pagar</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="cuentasPagarEmpresa" name="cuentasPagarEmpresa" #cuentasPagarEmpresa="ngModel" [(ngModel)]="empresa.contactoPagar"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el nombre completo de la persona que apoya en las cuentas por pagar de esta empresa." />
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="emailCuentasPagarEmpresa" class="control-label">Email cuentas por pagar</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="email" id="emailCuentasPagarEmpresa" name="emailCuentasPagarEmpresa" #emailCuentasPagarEmpresa="ngModel" [(ngModel)]="empresa.emailContactoPagar"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el correo del contacto de cuentas por pagar." />
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="tipoContribuyenteEmpresa" class="control-label">Tipo Contribuyente</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="tiposContribuyentes"
                  bindLabel="nombre"
                  bindValue="nombre"
                  placeholder="Capture el tipo de contribuyente."
                  id="tipoContribuyenteEmpresa" name="tipoContribuyenteEmpresa" #tipoContribuyenteEmpresa= "ngModel" [(ngModel)]="empresa.tipoContribuyente" [disabled]="!identity.permisos.empresasRegEdit">
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="usoCfdiEmpresa" class="control-label">Uso CFDI</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="usosCfdis"
                  bindLabel="nombre"
                  bindValue="clave"
                  placeholder="Capture el uso cfdi."
                  id="usoCfdiEmpresa" name="usoCfdiEmpresa" #usoCfdiEmpresa= "ngModel" [(ngModel)]="empresa.usoCfdi" [disabled]="!identity.permisos.empresasRegEdit">
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="direccionDatoFiscalEmpresa" class="control-label">¿Es la misma dirección?</label>
							</div>
							<div class="col-sm-8">
								<bSwitch
									id="direccionDatoFiscalEmpresa"
									name="direccionDatoFiscalEmpresa"
									#direccionDatoFiscalEmpresa="ngModel"
									[(ngModel)]="empresa.direccionDatoFiscal"
									[switch-size]="'normal'"
									[switch-on-text]="'Si'"
									[switch-off-text]="'No'"
									[switch-on-color]="'primary'"
									[switch-off-color]="'danger'"
								></bSwitch>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="paisDatoFiscalEmpresa" class="control-label">País</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="paises" (blur)="consultaCodigoPostalDatoFiscal()"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Capture el pais."
                  id="paisDatoFiscalEmpresa" name="paisDatoFiscalEmpresa" #paisDatoFiscalEmpresa= "ngModel" [(ngModel)]="empresa.paisDatoFiscal" [disabled]="!identity.permisos.empresasRegEdit" required>
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="codigoPostalDatoFiscalEmpresa" class="control-label">Código postal</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="codigoPostalDatoFiscalEmpresa"
								name="codigoPostalDatoFiscalEmpresa" #codigoPostalDatoFiscalEmpresa="ngModel" [(ngModel)]="empresa.codigoPostalDatoFiscal" class="form-control" (blur)="consultaCodigoPostalDatoFiscal()"
								placeholder="Número código postal" maxlength="100" minlength="1" title="Capture el código postal de la ubicación."/>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal && empresa.paisDatoFiscal === 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="estadoDatoFiscalEmpresa" class="control-label">Estado</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="estadosDatoFiscal" (blur)="consultaMunicipioDatoFiscal()"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Capture el estado."
                  id="estadoDatoFiscalEmpresa"  name="estadoDatoFiscalEmpresa" #estadoDatoFiscalEmpresa= "ngModel" [(ngModel)]="empresa.estadoDatoFiscal" 
									[disabled]="!identity.permisos.empresasRegEdit || !editarDatoFiscal">
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal && empresa.paisDatoFiscal === 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="municipioDatoFiscalEmpresa" class="control-label">Municipio/Ciudad</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="municipiosDatoFiscal"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Capture el municipio."
                  id="municipioDatoFiscalEmpresa"  name="municipioDatoFiscalEmpresa" #municipioDatoFiscalEmpresa= "ngModel" 
									[(ngModel)]="empresa.municipioDatoFiscal" 
									[disabled]="!identity.permisos.empresasRegEdit || !editarMunicipioDatoFiscal || !editarDatoFiscal">
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal && empresa.paisDatoFiscal !== 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="estadoDatoFiscalEmpresa" class="control-label">Estado</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="estadoDatoFiscalEmpresa" name="estadoDatoFiscalEmpresa" #estadoDatoFiscalEmpresa="ngModel" [(ngModel)]="empresa.desEstadoDatoFiscal"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el estado de la empresa."/>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal && empresa.paisDatoFiscal !== 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="municipioDatoFiscalEmpresa" class="control-label">Municipio/Ciudad</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="municipioDatoFiscalEmpresa" name="municipioDatoFiscalEmpresa" #municipioDatoFiscalEmpresa="ngModel" [(ngModel)]="empresa.desMunicipioDatoFiscal"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el municipio de la empresa."  />
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="coloniaDatoFiscalEmpresa" class="control-label">Colonia</label>
							</div>
							<div class="col-sm-8">
								<input list="coloniaDatoFiscalEmpresaList" type="text" id="coloniaDatoFiscalEmpresa" name="coloniaDatoFiscalEmpresa" #coloniaDatoFiscalEmpresa="ngModel" [(ngModel)]="empresa.coloniaDatoFiscal" class="form-control"
									title="Modelo de colonias" maxlength="100" placeholder="" [disabled]="!identity.permisos.empresasRegEdit" />
								<datalist id="coloniaDatoFiscalEmpresaList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
									<option *ngFor="let item of coloniasDatoFiscal" class="list-group-item" [ngValue]="item.nombre" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
								</datalist>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="calleDatoFiscalEmpresa" class="control-label">Calle</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="calleDatoFiscalEmpresa" name="calleDatoFiscalEmpresa" #calleDatoFiscalEmpresa="ngModel" [(ngModel)]="empresa.calleDatoFiscal"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture la calle." />
							</div>
						</div>
					</fieldset>
				</div>
				<div class="grid-menu grid-menu-2col border-top">
					<div class="no-gutters row">
						<div class="col-sm-6" *ngIf="identity.permisos.empresasListVer || identity.permisos.empresasListEdit">
							<a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger" [routerLink]="['/auth/empresas']">
								<i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
							</a>
						</div>
						<div class="col-sm-6" *ngIf="identity.permisos && identity.permisos.empresasRegEdit">
							<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" *ngIf="!loading" [disabled]="!registerEmpresaForm.valid || (empresa | json) === (empresaInicial | json)">
								<i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
							</button>
							<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="loading">
								<span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
								<br>
								Loading...
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
  </div>
</div>
