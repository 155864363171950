import { SortOrder } from './sort';

export class Departamento{
	public id: number;
	public nombre: string;
	constructor(
		obj: DataObjDepartamento
	){
		this.id = obj && obj.id? obj.id :0;
		this.nombre = obj && obj.nombre ? obj.nombre : '';

	}
}
interface DataObjDepartamento{
	id: number;
	nombre: string;
}


export class Usuarios{
	public id: number;
	public nombre: string;

	constructor(
		obj: DataObjUsuarios
	){
		this.id = obj && obj.id? obj.id :0;
		this.nombre = obj && obj.nombre ? obj.nombre : '';

	}
}
interface DataObjUsuarios{
	id: number;
	nombre: string;
}

export class Area{
	public id: number;
	public nombre: string;
	public usuarios: Usuarios[];

	constructor(
		obj: DataObjArea
	){
		this.id = obj && obj.id? obj.id : 0;
		this.nombre = obj && obj.nombre ? obj.nombre : '';
		this.usuarios = obj && obj.usuarios ? obj.usuarios : [];

	}
}
interface DataObjArea{
	id: number;
	nombre: string;
	usuarios:  Usuarios[];
}


export const DepartamentoSortType: SortOrder[] = [
	{ id: '+id', text: 'ID'},
	{ id: '-id', text: 'ID desc'},
	{ id: '+nombre', text: 'Nombre'},
	{ id: '-nombre', text: 'Nombre desc'}
  ];

