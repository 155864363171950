import { Action } from '@ngrx/store';
import { Division } from 'src/app/models/division';

export const CARGAR_DIVISIONES = '[Division] Cargar divisiones';
export const CARGAR_DIVISIONES_SUCCESS = '[Division] Cargar divisiones success';
export const CARGAR_DIVISIONES_FAIL = '[Division] Cargar divisiones fail';
export const ACTUALIZA_DIVISION = '[Division] Actualiza division';
export const ACTUALIZA_DIVISION_FAIL = '[Division] Actualiza division FAIL';
export const ACTUALIZA_DIVISION_SUCCESS = '[Division] Actualiza division SUCCESS';
export const ACTUALIZA_DIVISION_ESTATUS = '[Division] Actualiza division estatus';
export const ACTUALIZA_DIVISION_ESTATUS_FAIL = '[Division] Actualiza division estatus FAIL';
export const ACTUALIZA_DIVISION_ESTATUS_SUCCESS = '[Division] Actualiza division estatus SUCCESS';


export class CargarDivisiones implements Action {
  readonly type = CARGAR_DIVISIONES;
}

export class CargarDivisionesFail implements Action {
  readonly type = CARGAR_DIVISIONES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDivisionesSuccess implements Action {
  readonly type = CARGAR_DIVISIONES_SUCCESS;

  constructor(public divisiones: Division[]) {}
}

export class ActualizaDivision implements Action {
  readonly type = ACTUALIZA_DIVISION;

  constructor( public division: Division ) { }
}

export class ActualizaDivisionFail implements Action {
  readonly type = ACTUALIZA_DIVISION_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaDivisionSuccess implements Action {
  readonly type = ACTUALIZA_DIVISION_SUCCESS;

  constructor( public division: Division ) {}
}

export class ActualizaDivisionEstatus implements Action {
  readonly type = ACTUALIZA_DIVISION_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaDivisionEstatusFail implements Action {
  readonly type = ACTUALIZA_DIVISION_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaDivisionEstatusSuccess implements Action {
  readonly type = ACTUALIZA_DIVISION_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export type divisionAcciones = CargarDivisiones 
                                | CargarDivisionesFail 
                                | CargarDivisionesSuccess
                                | ActualizaDivision 
                                | ActualizaDivisionFail 
                                | ActualizaDivisionSuccess 
                                | ActualizaDivisionEstatus 
                                | ActualizaDivisionEstatusFail 
                                | ActualizaDivisionEstatusSuccess;
