import { Action } from '@ngrx/store';
import { KpisVendedores } from 'src/app/models/kpis-vendedores';
import { SeriesBar, FunnelGraphColors } from 'src/app/models/graph';
import { Fechas } from 'src/app/models/fechas';

export const CARGAR_REPORTE_ACTIVIDADES_MULTIPLES = '[ReporteKpisVendedores] Cargar actividades multiples';
export const CARGAR_REPORTE_ACTIVIDADES_MULTIPLES_SUCCESS = '[ReporteKpisVendedores] Cargar actividades multiples success';
export const CARGAR_REPORTE_ACTIVIDADES_MULTIPLES_FAIL = '[ReporteKpisVendedores] Cargar actividades multiples fail';
export const CARGAR_REPORTE_ACTIVIDADES_MULTIPLES_PENDIENTES = '[ReporteKpisVendedores] Cargar actividades multiples pendientes';
export const CARGAR_REPORTE_ACTIVIDADES_MULTIPLES_PENDIENTES_FAIL = '[ReporteKpisVendedores] Cargar actividades multiples pendientes FAIL';
export const CARGAR_REPORTE_ACTIVIDADES_MULTIPLES_PENDIENTES_SUCCESS = '[ReporteKpisVendedores] Cargar actividades multiples pendientes SUCCESS';
export const CARGAR_REPORTE_EMPRESAS_REGISTRADAS = '[ReporteKpisVendedores] Cargar empresas registradas';
export const CARGAR_REPORTE_EMPRESAS_REGISTRADAS_FAIL = '[ReporteKpisVendedores] Cargar empresas registradas FAIL';
export const CARGAR_REPORTE_EMPRESAS_REGISTRADAS_SUCCESS = '[ReporteKpisVendedores] Cargar empresas registradas SUCCESS';
export const CARGAR_REPORTE_CONTACTOS_REGISTRADAS = '[ReporteKpisVendedores] Cargar contactos registradas';
export const CARGAR_REPORTE_CONTACTOS_REGISTRADAS_FAIL = '[ReporteKpisVendedores] Cargar contactos registradas FAIL';
export const CARGAR_REPORTE_CONTACTOS_REGISTRADAS_SUCCESS = '[ReporteKpisVendedores] Cargar contactos registradas SUCCESS';
export const CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS = '[ReporteKpisVendedores] Cargar oportunidades abiertas';
export const CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS_FAIL = '[ReporteKpisVendedores] Cargar oportunidades abiertas FAIL';
export const CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS_SUCCESS = '[ReporteKpisVendedores] Cargar oportunidades abiertas SUCCESS';
export const CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES = '[ReporteKpisVendedores] Cargar clasificaciones oportunidades';
export const CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES_FAIL = '[ReporteKpisVendedores] Cargar clasificaciones oportunidades FAIL';
export const CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES_SUCCESS = '[ReporteKpisVendedores] Cargar clasificaciones oportunidades SUCCESS';
export const CARGAR_REPORTE_CLASES_OPORTUNIDADES = '[ReporteKpisVendedores] Cargar clases oportunidades';
export const CARGAR_REPORTE_CLASES_OPORTUNIDADES_FAIL = '[ReporteKpisVendedores] Cargar clases oportunidades FAIL';
export const CARGAR_REPORTE_CLASES_OPORTUNIDADES_SUCCESS = '[ReporteKpisVendedores] Cargar clases oportunidades SUCCESS';
export const CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES = '[ReporteKpisVendedores] Cargar industrias oportunidades';
export const CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES_FAIL = '[ReporteKpisVendedores] Cargar industrias oportunidades FAIL';
export const CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES_SUCCESS = '[ReporteKpisVendedores] Cargar industrias oportunidades SUCCESS';
export const CARGAR_REPORTE_FUNNEL_OPORTUNIDADES = '[ReporteKpisVendedores] Cargar funnel oportunidades';
export const CARGAR_REPORTE_FUNNEL_OPORTUNIDADES_FAIL = '[ReporteKpisVendedores] Cargar funnel oportunidades FAIL';
export const CARGAR_REPORTE_FUNNEL_OPORTUNIDADES_SUCCESS = '[ReporteKpisVendedores] Cargar funnel oportunidades SUCCESS';

export class CargarReporteActividadesMultiples implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_MULTIPLES;

  constructor( public fecha: Fechas, public opciones: any ) { }
}

export class CargarReporteActividadesMultiplesFail implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_MULTIPLES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteActividadesMultiplesSuccess implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_MULTIPLES_SUCCESS;

  constructor( public series: SeriesBar[], public categories: string[], public total: number) {}
}

export class CargarReporteActividadesMultiplesPendientes implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_MULTIPLES_PENDIENTES;

  constructor( public fecha: Fechas ) { }
}

export class CargarReporteActividadesMultiplesPendientesFail implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_MULTIPLES_PENDIENTES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteActividadesMultiplesPendientesSuccess implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_MULTIPLES_PENDIENTES_SUCCESS;

  constructor(  public series: number[], public categories: string[] ) {}
}

export class CargarReporteEmpresasRegistradas implements Action {
  readonly type = CARGAR_REPORTE_EMPRESAS_REGISTRADAS;

  constructor( public fecha: Fechas ) { }
}

export class CargarReporteEmpresasRegistradasFail implements Action {
  readonly type = CARGAR_REPORTE_EMPRESAS_REGISTRADAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteEmpresasRegistradasSuccess implements Action {
  readonly type = CARGAR_REPORTE_EMPRESAS_REGISTRADAS_SUCCESS;

  constructor( public empresas: KpisVendedores[] ) {}
}

export class CargarReporteContactosRegistradas implements Action {
  readonly type = CARGAR_REPORTE_CONTACTOS_REGISTRADAS;

  constructor( public fecha: Fechas ) { }
}

export class CargarReporteContactosRegistradasFail implements Action {
  readonly type = CARGAR_REPORTE_CONTACTOS_REGISTRADAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteContactosRegistradasSuccess implements Action {
  readonly type = CARGAR_REPORTE_CONTACTOS_REGISTRADAS_SUCCESS;

  constructor( public contactos: KpisVendedores[] ) {}
}

export class CargarReporteOportunidadesAbiertas implements Action {
  readonly type = CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS;

  constructor( public fecha: Fechas ) { }
}

export class CargarReporteOportunidadesAbiertasFail implements Action {
  readonly type = CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteOportunidadesAbiertasSuccess implements Action {
  readonly type = CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS_SUCCESS;

  constructor( public oportunidades: KpisVendedores[] ) {}
}

export class CargarReporteClasificacionOportunidades implements Action {
  readonly type = CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES;

  constructor( public fecha: Fechas ) { }
}

export class CargarReporteClasificacionOportunidadesFail implements Action {
  readonly type = CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteClasificacionOportunidadesSuccess implements Action {
  readonly type = CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES_SUCCESS;

  constructor( public series: SeriesBar[], public labels: string[] ) {}
}

export class CargarReporteClasesOportunidades implements Action {
  readonly type = CARGAR_REPORTE_CLASES_OPORTUNIDADES;

  constructor( public fecha: Fechas ) { }
}

export class CargarReporteClasesOportunidadesFail implements Action {
  readonly type = CARGAR_REPORTE_CLASES_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteClasesOportunidadesSuccess implements Action {
  readonly type = CARGAR_REPORTE_CLASES_OPORTUNIDADES_SUCCESS;

  constructor( public series: number[], public labels: string[] ) {}
}

export class CargarReporteIndustriasOportunidades implements Action {
  readonly type = CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES;

  constructor( public fecha: Fechas ) { }
}

export class CargarReporteIndustriasOportunidadesFail implements Action {
  readonly type = CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteIndustriasOportunidadesSuccess implements Action {
  readonly type = CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES_SUCCESS;

  constructor( public series: number[], public categories: string[] ) {}
}

export class CargarReporteFunnelOportunidades implements Action {
  readonly type = CARGAR_REPORTE_FUNNEL_OPORTUNIDADES;

  constructor( public fecha: Fechas ) { }
}

export class CargarReporteFunnelOportunidadesFail implements Action {
  readonly type = CARGAR_REPORTE_FUNNEL_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteFunnelOportunidadesSuccess implements Action {
  readonly type = CARGAR_REPORTE_FUNNEL_OPORTUNIDADES_SUCCESS;

  constructor( public datas: FunnelGraphColors[] ) {}
}

export type reporteKpisVendedoresAcciones = CargarReporteActividadesMultiples 
                                          | CargarReporteActividadesMultiplesFail 
                                          | CargarReporteActividadesMultiplesSuccess
                                          | CargarReporteActividadesMultiplesPendientes 
                                          | CargarReporteActividadesMultiplesPendientesFail 
                                          | CargarReporteActividadesMultiplesPendientesSuccess
                                          | CargarReporteEmpresasRegistradas 
                                          | CargarReporteEmpresasRegistradasFail 
                                          | CargarReporteEmpresasRegistradasSuccess
                                          | CargarReporteContactosRegistradas 
                                          | CargarReporteContactosRegistradasFail 
                                          | CargarReporteContactosRegistradasSuccess
                                          | CargarReporteOportunidadesAbiertas 
                                          | CargarReporteOportunidadesAbiertasFail 
                                          | CargarReporteOportunidadesAbiertasSuccess
                                          | CargarReporteClasificacionOportunidades 
                                          | CargarReporteClasificacionOportunidadesFail 
                                          | CargarReporteClasificacionOportunidadesSuccess
                                          | CargarReporteClasesOportunidades 
                                          | CargarReporteClasesOportunidadesFail 
                                          | CargarReporteClasesOportunidadesSuccess
                                          | CargarReporteIndustriasOportunidades 
                                          | CargarReporteIndustriasOportunidadesFail 
                                          | CargarReporteIndustriasOportunidadesSuccess
                                          | CargarReporteFunnelOportunidades 
                                          | CargarReporteFunnelOportunidadesFail 
                                          | CargarReporteFunnelOportunidadesSuccess;
