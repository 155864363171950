import { Injectable } from '@angular/core';
import { GLOBAL } from '../models/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User, Permisos, UserType, Suscripciones } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  login(user: any, captcha: string): Observable<any> {
    user.captcha = captcha;
    const params = JSON.stringify(user);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/accesos', params, { headers } );
  }

  logout(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.delete(this.url + '/accesos/' + (id || 0), {headers} );
  }

  registerUser(userToRegister: User): Observable<any> {
    const params = JSON.stringify({usuario: userToRegister});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/usuarios', params, {headers});
  }

  updateUser(userToUpdate: User): Observable<any> {
    const params = JSON.stringify({usuario: userToUpdate});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.put(this.url + '/usuarios/' + userToUpdate.id, params, {headers});
  }

  updateUserPerfil(userToUpdate: User): Observable<any> {
    const params = JSON.stringify({usuario: userToUpdate });
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.put(this.url + '/usuarios/perfil', params, {headers});
  }

  getProfile(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.get(this.url + '/usuarios/profile', {headers});
  }

  fileRequest(link: string, parametro: any, files: any, token: string, name: string): Observable<any> {
    const params = JSON.stringify({files, image: name, params: parametro});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(link, params, {headers});
  }

  deleteUser(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.delete(this.url + '/usuarios/' + id, {headers});
  }

  getUsers(page = null): Observable<any> {
    let text = '';
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    if (page != null) {
      text = '/pages/' + page;
    }
    return this.http.get(this.url + '/usuarios', {headers});
  }

  getUser(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.get(this.url + '/usuarios/' + id, {headers});
  }

  existsUser(user: User): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.get(this.url + '/usuarios/' + user + '/registrados', {headers});
  }

  existsEmail(email: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.get(this.url + '/usuarios/email/' + email, {headers});
  }

  verificateEmail(id: number, email: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.get(this.url + '/usuarios/' + (id ? id : '0') + '/email/' + email, {headers});
  }

  changePassword(actual: string, password: string): Observable<any> {
    const obj = {current: actual, pass: password};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.put(this.url + '/usuarios/pass', params, {headers});
  }

  recoveryPassword(contrasena: string, token: string): Observable<any> {
    const obj = {pass: contrasena, token };
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.put(this.url + '/usuarios/pass/recovery', params, {headers});
  }

  recoveryUser(email: string): Observable<any> {
    const obj = { email };
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.post(this.url + '/usuarios/recovery', params, {headers});
  }

  getTypeUser(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.get(this.url + '/usuarios/tipos', {headers});
  }

  getTypePermsiosUser(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.get(this.url + '/usuarios/permisos/' + id, {headers});
  }

  saveTypePermsiosUser(tipo: UserType, permisos: Permisos, suscripciones: Suscripciones[], tiposVer: UserType[], tipos: UserType[]): Observable<any> {
    const params = JSON.stringify({tipo, permisos, suscripciones, tiposVer, tipos});
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.post(this.url + '/usuarios/permisos/' + tipo.id, params, {headers});
  }

  getSubscriptionsUser(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.get(this.url + '/usuarios/suscripciones/' + id, {headers});
  }

  saveSusbcriptionUser(tipo: UserType, suscripciones: Suscripciones): Observable<any> {
    const params = JSON.stringify({tipo, suscripciones});
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.post(this.url + '/usuarios/suscripciones/' + tipo.id, params, {headers});
  }

  actualizaEstatusUsuario(tipo: UserType): Observable<any> {
    const param = JSON.stringify({tipo});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/usuarios/tipo/estatus', param, {headers});
  }

  getTypeUserVer(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.get(this.url + '/usuarios/tipos/' + id, {headers});
  }

  getTypeUserEdit(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.get(this.url + '/usuarios/tipo/' + id, {headers});
  }


}
