import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { ConsultaCatalogossService } from 'src/app/services/consulta-catalogos.service';


@Injectable()
export class ConsultarCatalogosEffects {

  constructor(
    private actions$: Actions,
    public consultaCatalogossService: ConsultaCatalogossService
  ) {}

  @Effect()
  consultarCatalogoDivisiones$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_CATALOGO_DIVISIONES ),
    switchMap( () => {
      return this.consultaCatalogossService.obtenerDivisiones()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaCatalogoDivisionesSuccess(respuesta.division);
          }),
          catchError( error => of(new usuariosActions.ConsultaCatalogoDivisionesFail(error))  )
        );
    })
  );


  @Effect()
  consultarCatalogoDepartamentos$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_CATALOGO_DEPARTAMENTOS ),
    switchMap( () => {
      return this.consultaCatalogossService.obtenerDepartamentos()
        .pipe(
            map( (respuesta: any) => {
              return new usuariosActions.ConsultaCatalogoDepartamentosSuccess(respuesta.departamentos);
            }),
            catchError( error => of(new usuariosActions.ConsultaCatalogoDepartamentosFail(error))  )
        );
    })
  );


  @Effect()
  consultarCatalogoSucursales$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_CATALOGO_SUCURSALES ),
    switchMap( () => {
      return this.consultaCatalogossService.obtenerSucursales()
        .pipe(
            map( (respuesta: any) => {
              return new usuariosActions.ConsultaCatalogoSucursalesSuccess(respuesta.sucursales);
            }),
            catchError( error => of(new usuariosActions.ConsultaCatalogoSucursalesFail(error))  )
        );
    })
  );


  @Effect()
  consultarCatalogoColores$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_CATALOGO_COLORES ),
    switchMap( () => {
      return this.consultaCatalogossService.obtenerColores()
        .pipe(
            map( (respuesta: any) => {
              return new usuariosActions.ConsultaCatalogoColoresSuccess(respuesta.colores);
            }),
            catchError( error => of(new usuariosActions.ConsultaCatalogoColoresFail(error))  )
        );
    })
  );


  @Effect()
  consultarCatalogoServicios$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_CATALOGO_SERVICIOS ),
    switchMap( () => {
      return this.consultaCatalogossService.obtenerServicios()
        .pipe(
            map( (respuesta: any) => {
              return new usuariosActions.ConsultaCatalogoServiciosSuccess(respuesta.servicios);
            }),
            catchError( error => of(new usuariosActions.ConsultaCatalogoServiciosFail(error))  )
        );
    })
  );


  @Effect()
  consultarCatalogoClientes$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_CATALOGO_CLIENTES ),
    switchMap( () => {
      return this.consultaCatalogossService.obtenerClientes()
        .pipe(
            map( (respuesta: any) => {
              return new usuariosActions.ConsultaCatalogoClientesSuccess(respuesta.clientes);
            }),
            catchError( error => of(new usuariosActions.ConsultaCatalogoClientesFail(error))  )
        );
    })
  );


  @Effect()
  consultarCatalogoTipoServicios$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_CATALOGO_TIPO_SERVICIOS ),
    switchMap( () => {
      return this.consultaCatalogossService.obtenerTipoServicios()
        .pipe(
            map( (respuesta: any) => {
              return new usuariosActions.ConsultaCatalogoTipoServiciosSuccess(respuesta.servicios);
            }),
            catchError( error => of(new usuariosActions.ConsultaCatalogoTipoServiciosFail(error))  )
        );
    })
  );

}
