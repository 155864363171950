import { SortOrder } from './sort';

export class Cliente {
	public id: number;
	public nombre: string;
	public razonSocial: string;
    public status: boolean;
    	
	constructor(
		 obj: DataObjCliente
	) {
		this.id	=obj && obj.id? obj.id: 0;
		this.nombre =obj && obj.nombre? obj.nombre : '';
		this.razonSocial =obj && obj.razonSocial? obj.razonSocial : '';
        this.status	=obj && obj.status? obj.status: false;
	}
}

interface DataObjCliente{
	id:	number;
	nombre:	string;
	razonSocial: string;
    status: boolean;
}


export const ClienteSortType: SortOrder[] = [
	{ id: '+id', text: 'ID'},
	{ id: '-id', text: 'ID desc'},
	{ id: '+nombre', text: 'Nombre comercial'},
	{ id: '-nombre', text: 'Nombre comercial desc'},
	{ id: '+razonSocial', text: 'Razón social'},
	{ id: '-razonSocial', text: 'Razón social desc'}
  ];
