import * as fromUsuario from '../actions';
import { Servicio } from 'src/app/models/servicios';

export interface ServicioState {
  servicios: Servicio[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: ServicioState = {
  servicios: [],
  loaded: false,
  loading: false,
  error: null
};

export function servicioReducer( state = estadoInicial, action: fromUsuario.servicioAcciones ): ServicioState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_SERVICIOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_SERVICIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        servicios: [...action.servicios]
      };
    case fromUsuario.CARGAR_SERVICIOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_SERVICIO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_SERVICIO_SUCCESS:
      const itm = state.servicios.find( item => item.id === action.servicio.id);
      if (itm && itm.id) {
        state.servicios = state.servicios.map( item => {
          if (item.id === action.servicio.id) {
            item = {...action.servicio};
          }
          return item;
        });
      } else {
        state.servicios.push(action.servicio);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_SERVICIO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_SERVICIO_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_SERVICIO_ESTATUS_SUCCESS:
      state.servicios = state.servicios.map( item => {
        if (item.id === action.id) {
          item.status = !item.status;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_SERVICIO_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

