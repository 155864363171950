import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from 'src/app/models/global';
import { Catalogo } from 'src/app/models/catalogo';
import { Origen } from 'src/app/models/origen-contacto';
import { PostalEmpresa } from 'src/app/models/direccion';

@Injectable({
  providedIn: 'root'
})
export class CatalogosService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  validaRazonSocialRegistrados(nombre: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/catalogos/datos-fiscales/registrados/validas/' + nombre, {headers});
  }


  obtenerIndustrias(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/catalogos/industrias', {headers});
  }

  actualizaEstatusIndustria(industria: Catalogo): Observable<any> {
    const param = JSON.stringify({industria});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/catalogos/industrias/estatus', param, {headers});
  }

  registraIndustria(industria: Catalogo): Observable<any> {
    const param = JSON.stringify({industria});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/catalogos/industrias', param, {headers});
  }

  obtenerEmpresas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/catalogos/empresas', {headers});
  }

  registraPostalEmpresa(postal: PostalEmpresa): Observable<any> {
    const param = JSON.stringify({postal});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/catalogos/postales', param, {headers});
  }

  obtenerOrigenContactos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/catalogos/origen/contacto', {headers});
  }

  actualizaEstatusOrigenContacto(origen: Origen): Observable<any> {
    const param = JSON.stringify({origen});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/catalogos/origen/contacto/estatus', param, {headers});
  }

  registraOrigenContacto(origen: Origen): Observable<any> {
    const param = JSON.stringify({origen});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/catalogos/origen/contacto', param, {headers});
  }

  obtenerpaises(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/catalogos/pais', {headers});
  }

  actualizaEstatusPais(pais: Catalogo): Observable<any> {
    const param = JSON.stringify({pais});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/catalogos/pais/estatus', param, {headers});
  }

  registraPais(pais: Catalogo): Observable<any> {
    const param = JSON.stringify({pais});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/catalogos/pais', param, {headers});
  }

  obtenerDepartamentos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/catalogos/departamentos', {headers});
  }

  actualizaEstatusDepartamento(departamento: Catalogo): Observable<any> {
    const param = JSON.stringify({departamento});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/catalogos/departamentos/estatus', param, {headers});
  }

  registraDepartamento(departamento: Catalogo): Observable<any> {
    const param = JSON.stringify({departamento});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/catalogos/departamentos', param, {headers});
  }

}
