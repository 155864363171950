export class Fechas{
	public fechaInicio: any;
	public fechaFinal: any;

	constructor(
		obj: DataObjFechas
	){
		this.fechaInicio = obj && obj.fechaInicio? obj.fechaInicio :null;
		this.fechaFinal = obj && obj.fechaFinal ? obj.fechaFinal : null;

	}
}
interface DataObjFechas{
	fechaInicio: any;
	fechaFinal: any;
}