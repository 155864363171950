<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" >
  <div class="col-md-12">
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="btn-actions-pane-left">
          <div class="row mt-3">
            <div class="form-group pr-4">
              <input type="text" placeholder="Fecha" class="form-control d-none d-lg-block" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)">
              <input type="text" placeholder="Fecha" class="form-control d-lg-none" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)">
            </div>
            <div class="search-wrapper pr-3" [class.active]="paginacion.isActive" *ngIf="resultados.length > 0">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchAsignacion" id="searchAsignacion" #searchAsignacion="ngModel" [(ngModel)]="paginacion.textSearch" (blur)="textSearchChips()">
                <button class="search-icon" (click)="paginacion.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = ''; chips = [];"></button>
            </div>
            <div class="from-group" *ngIf="resultados.length > 0">
              <div class="btn-group-lg btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="filter_tipo">
                <label ngbButtonLabel class="btn-outline-primary">
                  <input ngbButton type="radio" [value]="1"> TNC
                </label>
                <label ngbButtonLabel class="btn-outline-primary">
                  <input ngbButton type="radio" [value]="4"> %COP
                </label>
                <label ngbButtonLabel class="btn-outline-primary">
                  <input ngbButton type="radio" [value]="2"> Bajas
                </label>
                <label ngbButtonLabel class="btn-outline-primary">
                  <input ngbButton type="radio" [value]="3"> Vacantes
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-actions-pane-right" *ngIf="resultados.length > 0">
          <span>
            ({{(resultados | anyScope: paginacion.textSearch).length}} de {{(resultados).length}})
          </span>
          <!-- <div class="btn-group">
            <button class="btn-wide btn-icon btn-icon-right btn-pill btn btn-lg" *ngIf="!filterActive" (click)="filterActive = true">
              Filtrar
              <i class="pe-7s-filter btn-icon-wrapper"> </i>
            </button>
          </div> -->
        </div>
      </div>
      <div class="card-subtitle mt-2 ml-3" *ngIf="chips.length > 0">
        <mat-chip-list>
          <mat-chip  *ngFor="let chip of chips" [selectable]="selectable" [removable]="removable"> {{chip}}
            <mat-icon matChipRemove *ngIf="removable" (click)="remove(chip) ">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="divider mt-0 m-0" *ngIf="chips.length > 0"></div>
      <div class="card-body p-0">
        <div class="card-body text-center" *ngIf="resultados.length > 0">
          <h5 *ngIf="filter_tipo === 1" class="card-title">TNC {{anio}}</h5>
          <h5 *ngIf="filter_tipo === 2" class="card-title">Bajas {{anio}}</h5>
          <h5 *ngIf="filter_tipo === 3" class="card-title">Vacantes {{anio}}</h5>
          <h5 *ngIf="filter_tipo === 4" class="card-title">%COP {{anio}}</h5>
          <div class="table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th class="text-center"  style="min-width: 100px !important;"> Division</th>
                  <th class="text-center"  style="min-width: 100px !important;"> Número de elementos</th>
                  <th *ngFor="let dia of fechas">{{dia| amDateFormat: 'DD/MM' }}</th>
                  <th class="text-center"  style="min-width: 100px !important;"> Total</th>
                </tr>
              </thead>
              <tbody *ngFor="let resultado of resultados | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize">
                <tr>
                  <td >{{resultado.nombre}}</td>
                  <td >{{resultado.elementos}}</td>
                  <td class="text-center"  *ngFor="let resul of resultado.resultado" [ngClass]="{'bg-success': resul.porcentaje >= 98.99 && filter_tipo === 4, 'bg-warning': resul.porcentaje <= 98.98 && resul.porcentaje >= 90.1 && filter_tipo === 4, 'bg-danger': resul.porcentaje <= 90 && resul.porcentaje >= 76 && filter_tipo === 4, 'bg-dark': resul.porcentaje <= 75.99 && filter_tipo === 4, 'text-dark': resul.porcentaje <= 98.98 && resul.porcentaje >= 90.1 && filter_tipo === 4, 'text-white': (resul.porcentaje > 98.98 || resul.porcentaje < 90.1) && filter_tipo === 4 }" >
                    <span *ngIf="filter_tipo === 1" [title]="resul.turnos + ' turnos'">
                      {{resul.faltas}}
                    </span>
                    <span *ngIf="filter_tipo === 2" [title]="resul.turnos + ' turnos'">
                      {{resul.bajas}}
                    </span>
                    <span  *ngIf="filter_tipo === 3" [title]="resul.turnos + ' turnos'">
                      {{resul.vacantes}}
                    </span>
                    <span *ngIf="filter_tipo === 4" [title]="resul.turnos + ' turnos'">
                      {{resul.porcentaje}}
                    </span>
                  </td>
                  <td [ngClass]="{'bg-success': resultado.cop >= 98.99 && filter_tipo === 4, 'bg-warning': resultado.cop <= 98.98 && resultado.cop >= 90.1  && filter_tipo === 4, 'bg-danger': resultado.cop <= 90 && resultado.cop >= 76  && filter_tipo === 4, 'bg-dark': resultado.cop <= 75.99  && filter_tipo === 4, 'text-dark': resultado.cop <= 98.98 && resultado.cop >= 90.1  && filter_tipo === 4, 'text-white': (resultado.cop > 98.98 || resultado.cop < 90.1)  && filter_tipo === 4 }">
                    <span *ngIf="filter_tipo === 1"  [title]="resultado.turnos + ' turnos'">
                        {{resultado.faltas}}
                      </span>
                      <span *ngIf="filter_tipo === 2"  [title]="resultado.turnos + ' turnos'">
                        {{resultado.bajas}}
                      </span>
                      <span *ngIf="filter_tipo === 3"  [title]="resultado.turnos + ' turnos'">
                        {{resultado.vacantes}}
                      </span>
                      <span *ngIf="filter_tipo === 4" [title]="resultado.turnos + ' turnos'">
                        {{resultado.cop}}
                      </span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td ><b>{{resultadoTotal.nombre}}</b></td>
                  <td ><b>{{resultadoTotal.elementos}}</b></td>
                  <td class="text-center"  *ngFor="let resul of resultadoTotal.resultado" [ngClass]="{'bg-success': resul.porcentaje >= 98.99  && filter_tipo === 4, 'bg-warning': resul.porcentaje <= 98.98 && resul.porcentaje >= 90.1 && filter_tipo === 4, 'bg-danger': resul.porcentaje <= 90 && resul.porcentaje >= 76 && filter_tipo === 4, 'bg-dark': resul.porcentaje <= 75.99 && filter_tipo === 4, 'text-dark': resul.porcentaje <= 98.98 && resul.porcentaje >= 90.1 && filter_tipo === 4, 'text-white': (resul.porcentaje > 98.98 || resul.porcentaje < 90.1) && filter_tipo === 4  }">
                    <span *ngIf="filter_tipo === 1" [title]="resul.turnos + ' turnos'">
                      <b>{{resul.faltas}}</b>
                    </span>
                    <span *ngIf="filter_tipo === 2" [title]="resul.turnos + ' turnos'">
                      <b>{{resul.bajas}}</b>
                    </span>
                    <span  *ngIf="filter_tipo === 3" [title]="resul.turnos + ' turnos'">
                      <b>{{resul.vacantes}}</b>
                    </span>
                    <span  *ngIf="filter_tipo === 4" [title]="resul.turnos + ' turnos'">
                      <b>{{resul.porcentaje | number : '1.0-2': 'en-MX'}}</b>
                    </span>
                  </td>
                  <td [ngClass]="{'bg-success': resultadoTotal.cop >= 98.99 && filter_tipo === 4, 'bg-warning': resultadoTotal.cop <= 98.98 && resultadoTotal.cop >= 90.1  && filter_tipo === 4, 'bg-danger': resultadoTotal.cop <= 90 && resultadoTotal.cop >= 76  && filter_tipo === 4, 'bg-dark': resultadoTotal.cop <= 75.99  && filter_tipo === 4, 'text-dark': resultadoTotal.cop <= 98.98 && resultadoTotal.cop >= 90.1  && filter_tipo === 4, 'text-white': (resultadoTotal.cop > 98.98 || resultadoTotal.cop < 90.1)  && filter_tipo === 4 }">
                    <span *ngIf="filter_tipo === 1"  [title]="resultadoTotal.turnos + ' turnos'">
                        <b>{{resultadoTotal.faltas}}</b>
                      </span>
                      <span *ngIf="filter_tipo === 2"  [title]="resultadoTotal.turnos + ' turnos'">
                        <b>{{resultadoTotal.bajas}}</b>
                      </span>
                      <span *ngIf="filter_tipo === 3"  [title]="resultadoTotal.turnos + ' turnos'">
                        <b>{{resultadoTotal.vacantes}}</b>
                      </span>
                      <span *ngIf="filter_tipo === 4" [title]="resultadoTotal.turnos + ' turnos'">
                        <b>{{resultadoTotal.cop | number : '1.0-2': 'en-MX'}}</b>
                      </span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="mt-2" *ngIf="resultados.length > 0">
        <div class="row p-2">
          <div class="col-sm-12">
            <div class="pull-right">
              <button type="button" (click) = "crearExcel()" class="btn btn-primary" [disabled]="loading" *ngIf="identity.permisos.rptCoberturaDia">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="card main-card mb-3" *ngIf="resultados.length > 0">
  <div class="card-header">
    <h5 *ngIf="filter_tipo === 1" class="card-title">TNC {{anio}}</h5>
    <h5 *ngIf="filter_tipo === 2" class="card-title">Bajas {{anio}}</h5>
    <h5 *ngIf="filter_tipo === 3" class="card-title">Vacantes {{anio}}</h5>
    <h5 *ngIf="filter_tipo === 4" class="card-title">%COP {{anio}}</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-4">
        <div id="wrapper" *ngIf="filter_tipo === 1">
          <apx-chart
            [series]="seriesPieTnc"
            [chart]="{
              width: 520,
              type: 'pie'
            }"
            [labels]="categories"
            [responsive]="[
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }
          ]"
          ></apx-chart>
        </div>
    
        <div id="wrapper" *ngIf="filter_tipo === 2">
          <apx-chart
            [series]="seriesPieBaja"
            [chart]="{
              width: 520,
              type: 'pie'
            }"
            [labels]="categories"
            [responsive]="[
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }
          ]"
          ></apx-chart>
        </div>
    
        <div id="wrapper" *ngIf="filter_tipo === 3">
          <apx-chart
            [series]="seriesPieVacantes"
            [chart]="{
              width: 520,
              type: 'pie'
            }"
            [labels]="categories"
            [responsive]="[
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }
          ]"
          ></apx-chart>
        </div>
    
        <div id="wrapper" *ngIf="filter_tipo === 4">
          <apx-chart
          [series]="seriesPiePorcentaje"
          [chart]="{
            width: 520,
            type: 'pie'
          }"
          [labels]="categories"
          [responsive]="[
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]"
        ></apx-chart>
        </div>
      </div>
      <div class="col-sm-8">
        <div id="wrapper" *ngIf="filter_tipo === 1">
          <div id="chart-months">
            <apx-chart
              [series]="seriesTnc"
              [chart]="{
                animations: {
                  enabled: false,
                  easing: 'easeinout',
                  speed: 200,
                  animateGradually: {
                      enabled: false,
                      delay: 150
                  },
                  dynamicAnimation: {
                      enabled: false,
                      speed: 350
                  }
                },
                id: 'tnc',
                type: 'bar',
                height: 300,
                toolbar: {
                  show: false,
                  autoSelected: 'pan'
                }
              }"
              [yaxis]="{
                show: true,
                tickAmount: 3
              }"
              [dataLabels]="{
                enabled: false
              }"
              [fill]="{
                opacity: 1,
                type: 'solid'
              }"
              [stroke]="{
                width: 2,
                curve: 'smooth'
              }"
              [xaxis]="{
                type: 'datetime',
                categories: fechas
              }"
              [plotOptions]="{
                bar: {
                  columnWidth: '40%',
                  endingShape: 'rounded'
                }
              }"
            ></apx-chart>
          </div>
          <div id="chart-years">
            <apx-chart
              [series]="seriesTnc"
              [chart]="{
                animations: {
                  enabled: false,
                  easing: 'easeinout',
                  speed: 200,
                  animateGradually: {
                      enabled: false,
                      delay: 150
                  },
                  dynamicAnimation: {
                      enabled: false,
                      speed: 350
                  }
                },
                height: 150,
                type: 'bar',
                toolbar: {
                  autoSelected: 'selection'
                },
                brush: {
                  enabled: true,
                  target: 'tnc'
                },
                selection: {
                  enabled: true,
                  xaxis: {
                    min: selectionInicio,
                    max: selectionFin
                  }
                }
              }"
              [legend]="{
                position: 'top',
                horizontalAlign: 'left'
              }"
              [dataLabels]="{
                enabled: false
              }"
              [fill]="{
                opacity: 1,
                type: 'solid'
              }"
              [stroke]="{
                width: 2,
                curve: 'smooth'
              }"
              [xaxis]="{
                type: 'datetime',
                categories: fechas
              }"
              [plotOptions]="{
                bar: {
                  columnWidth: '40%',
                  endingShape: 'rounded'
                }
              }"
            ></apx-chart>
          </div>
        </div>
    
        <div id="wrapper" *ngIf="filter_tipo === 2">
          <div id="chart-months">
            <apx-chart
              [series]="seriesBaja"
              [chart]="{
                animations: {
                  enabled: false,
                  easing: 'easeinout',
                  speed: 200,
                  animateGradually: {
                      enabled: false,
                      delay: 150
                  },
                  dynamicAnimation: {
                      enabled: false,
                      speed: 350
                  }
                },
                id: 'baja',
                type: 'bar',
                height: 300,
                toolbar: {
                  show: false,
                  autoSelected: 'pan'
                }
              }"
              [yaxis]="{
                show: true,
                tickAmount: 3
              }"
              [dataLabels]="{
                enabled: false
              }"
              [fill]="{
                opacity: 1,
                type: 'solid'
              }"
              [stroke]="{
                width: 2,
                curve: 'smooth'
              }"
              [xaxis]="{
                type: 'datetime',
                categories: fechas
              }"
              [plotOptions]="{
                bar: {
                  columnWidth: '40%',
                  endingShape: 'rounded'
                }
              }"
            ></apx-chart>
          </div>
          <div id="chart-years">
            <apx-chart
              [series]="seriesBaja"
              [chart]="{
                animations: {
                  enabled: false,
                  easing: 'easeinout',
                  speed: 200,
                  animateGradually: {
                      enabled: false,
                      delay: 150
                  },
                  dynamicAnimation: {
                      enabled: false,
                      speed: 350
                  }
                },
                height: 150,
                type: 'bar',
                toolbar: {
                  autoSelected: 'selection'
                },
                brush: {
                  enabled: true,
                  target: 'baja'
                },
                selection: {
                  enabled: true,
                  xaxis: {
                    min: selectionInicio,
                    max: selectionFin
                  }
                }
              }"
              [legend]="{
                show: false,
                position: 'top',
                horizontalAlign: 'left'
              }"
              [dataLabels]="{
                enabled: false
              }"
              [fill]="{
                opacity: 1,
                type: 'solid'
              }"
              [stroke]="{
                width: 2,
                curve: 'smooth'
              }"
              [xaxis]="{
                type: 'datetime',
                categories: fechas
              }"
              [plotOptions]="{
                bar: {
                  columnWidth: '40%',
                  endingShape: 'rounded'
                }
              }"
            ></apx-chart>
          </div>
        </div>
    
        <div id="wrapper" *ngIf="filter_tipo === 3">
          <div id="chart-months">
            <apx-chart
              [series]="seriesVacante"
              [chart]="{
                animations: {
                  enabled: false,
                  easing: 'easeinout',
                  speed: 200,
                  animateGradually: {
                      enabled: false,
                      delay: 150
                  },
                  dynamicAnimation: {
                      enabled: false,
                      speed: 350
                  }
                },
                id: 'vacante',
                type: 'bar',
                height: 300,
                toolbar: {
                  show: false,
                  autoSelected: 'pan'
                }
              }"
              [yaxis]="{
                show: true,
                tickAmount: 3
              }"
              [dataLabels]="{
                enabled: false
              }"
              [fill]="{
                opacity: 1,
                type: 'solid'
              }"
              [stroke]="{
                width: 2,
                curve: 'smooth'
              }"
              [xaxis]="{
                type: 'datetime',
                categories: fechas
              }"
              [plotOptions]="{
                bar: {
                  columnWidth: '40%',
                  endingShape: 'rounded'
                }
              }"
            ></apx-chart>
          </div>
          <div id="chart-years">
            <apx-chart
              [series]="seriesVacante"
              [chart]="{
                animations: {
                  enabled: false,
                  easing: 'easeinout',
                  speed: 200,
                  animateGradually: {
                      enabled: false,
                      delay: 150
                  },
                  dynamicAnimation: {
                      enabled: false,
                      speed: 350
                  }
                },
                height: 150,
                type: 'bar',
                toolbar: {
                  autoSelected: 'selection'
                },
                brush: {
                  enabled: true,
                  target: 'vacante'
                },
                selection: {
                  enabled: true,
                  xaxis: {
                    min: selectionInicio,
                    max: selectionFin
                  }
                }
              }"
              [legend]="{
                show: false,
                position: 'top',
                horizontalAlign: 'left'
              }"
              [dataLabels]="{
                enabled: false
              }"
              [fill]="{
                opacity: 1,
                type: 'solid'
              }"
              [stroke]="{
                width: 2,
                curve: 'smooth'
              }"
              [xaxis]="{
                type: 'datetime',
                categories: fechas
              }"
              [plotOptions]="{
                bar: {
                  columnWidth: '40%',
                  endingShape: 'rounded'
                }
              }"
            ></apx-chart>
          </div>
        </div>
    
        <div id="wrapper" *ngIf="filter_tipo === 4">
          <div id="chart-months">
            <apx-chart
              [series]="seriesPorcentaje"
              [chart]="{
                animations: {
                  enabled: false,
                  easing: 'easeinout',
                  speed: 200,
                  animateGradually: {
                      enabled: false,
                      delay: 150
                  },
                  dynamicAnimation: {
                      enabled: false,
                      speed: 350
                  }
                },
                id: 'porcentaje',
                type: 'bar',
                height: 300,
                toolbar: {
                  show: false,
                  autoSelected: 'pan'
                }
              }"
              [yaxis]="{
                show: true,
                tickAmount: 3
              }"
              [dataLabels]="{
                enabled: false
              }"
              [fill]="{
                opacity: 1,
                type: 'solid'
              }"
              [stroke]="{
                width: 2,
                curve: 'smooth'
              }"
              [xaxis]="{
                type: 'datetime',
                categories: fechas
              }"
              [plotOptions]="{
                bar: {
                  columnWidth: '40%',
                  endingShape: 'rounded'
                }
              }"
            ></apx-chart>
          </div>
          <div id="chart-years">
            <apx-chart
              [series]="seriesPorcentaje"
              [chart]="{
                animations: {
                  enabled: false,
                  easing: 'easeinout',
                  speed: 200,
                  animateGradually: {
                      enabled: false,
                      delay: 150
                  },
                  dynamicAnimation: {
                      enabled: false,
                      speed: 350
                  }
                },
                height: 150,
                type: 'bar',
                toolbar: {
                  autoSelected: 'selection'
                },
                brush: {
                  enabled: true,
                  target: 'porcentaje'
                },
                selection: {
                  enabled: true,
                  xaxis: {
                    min: selectionInicio,
                    max: selectionFin
                  }
                }
              }"
              [legend]="{
                show: false,
                position: 'top',
                horizontalAlign: 'left'
              }"
              [dataLabels]="{
                enabled: false
              }"
              [fill]="{
                opacity: 1,
                type: 'solid'
              }"
              [stroke]="{
                width: 2,
                curve: 'smooth'
              }"
              [xaxis]="{
                type: 'datetime',
                categories: fechas
              }"
              [plotOptions]="{
                bar: {
                  columnWidth: '40%',
                  endingShape: 'rounded'
                }
              }"
            ></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



