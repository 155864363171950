import { ActionReducerMap } from '@ngrx/store';
import * as reducers from './reducers';

export interface AppState {
    usuarios: reducers.UsuariosState;
    usuario: reducers.UsuarioState;
    auth: reducers.AuthState;
    ui: reducers.UiState;
    catalogos: reducers.TiposState;
    toastr: reducers.ToastrState;
    asignacion: reducers.AsignacionesState;
    direccion: reducers.DireccionState;
    division: reducers.DivisionState;
    sucursales: reducers.SucursalState;
    clientes: reducers.ClienteState;
    servicios: reducers.ServicioState;
    tipoServicio: reducers.TipoServicioState;
    tipoSolicitud: reducers.TipoSolicitudState;
    indicador: reducers.IndicadorState;
    tableroKpi: reducers.TableroKpiState;
    captura: reducers.CapturaState;
    areas: reducers.AreaState;
    colores: reducers.ColoresState;
    cumplimiento: reducers.CumplimientoState;
    dianolaboral: reducers.DiaNoLaboralState;
    capturaAsignacion: reducers.CapturaAsignacionesState;
    reporteAsignacion: reducers.ReporteAsignacionState;
    empresa: reducers.EmpresaState;
    industria: reducers.IndustriaState;
    origenContacto: reducers.OrigenContactoState;
    pais: reducers.PaisesState;
    consulta: reducers.ConsutaState;
    contacto: reducers.ContactoState;
    oportunidad: reducers.OportunidadState;
    actividadMultiple: reducers.ActividadesMultipleState;
    actividadArchivos: reducers.ActividadesArchivosState;
    kpiVendedor: reducers.KPIsVendedoresState;
    reporteKpiVendedor: reducers.ReporteKPIsVendedoresState;
    reportes: reducers.ReporteState;
    ticket: reducers.TicketState;
    consultaCatalogos: reducers.ConsutaCatalogoState;
    dashboard: reducers.DashboardState;
}

export const appReducers: ActionReducerMap<AppState> = {
    usuarios: reducers.usuariosReducer,
    usuario: reducers.usuarioReducer,
    auth: reducers.authReducer,
    ui: reducers.uiReducer,
    catalogos: reducers.catalogosReducer,
    toastr: reducers.toastrReducer,
    asignacion: reducers.asignacionesReducer,
    direccion: reducers.direccionReducer,
    division: reducers.divisionReducer,
    sucursales: reducers.sucursalesReducer,
    clientes: reducers.clienteReducer,
    servicios: reducers.servicioReducer,
    tipoServicio: reducers.tipoServicioReducer,
    tipoSolicitud: reducers.tipoSolicitudReducer,
    indicador: reducers.indicadorReducer,
    tableroKpi: reducers.tableroKpiReducer,
    captura: reducers.capturaReducer,
    areas: reducers.areasReducer,
    colores: reducers.coloresReducer,
    cumplimiento: reducers.cumplimientoReducer,
    dianolaboral: reducers.diaNoLaboralReducer,
    capturaAsignacion: reducers.capturasAsignacionReducer,
    reporteAsignacion: reducers.reporteAsignacionReducer,
    empresa: reducers.empresaReducer,
    industria: reducers.industriaReducer,
    origenContacto: reducers.origenContactoReducer,
    pais: reducers.paisEmpresaReducer,
    consulta: reducers.consultasReducer,
    contacto: reducers.contactoReducer,
    oportunidad: reducers.oportunidadReducer,
    actividadMultiple: reducers.actividadesMultipleReducer,
    actividadArchivos: reducers.actividadesArchivosReducer,
    kpiVendedor: reducers.kpisVendedoresReducer,
    reporteKpiVendedor: reducers.reporteKpisVendedoresReducer,
    reportes: reducers.reportesReducer,
    ticket: reducers.ticketReducer,
    consultaCatalogos: reducers.consultaCatalogosReducer,
    dashboard: reducers.dashboardReducer,
};
