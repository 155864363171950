<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="card main-card mb-3">
	<div class="card-header  ml-3 mt-2">
		<div class="btn-actions-pane-left mb-4">
			<div class="row mt-3">
				<div class="from-group">
					<div class="btn-group-lg btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="filter_tipo">
						<label ngbButtonLabel class="btn-outline-primary">
							<input ngbButton type="radio" [value]="1"> TNC
						</label>
						<label ngbButtonLabel class="btn-outline-primary">
							<input ngbButton type="radio" [value]="4"> %COP
						</label>
						<label ngbButtonLabel class="btn-outline-primary">
							<input ngbButton type="radio" [value]="2"> Bajas
						</label>
						<label ngbButtonLabel class="btn-outline-primary">
							<input ngbButton type="radio" [value]="3"> Vacantes
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-sm-5">
				<div id="wrapper" *ngIf="filter_tipo === 1">
					<apx-chart
						[series]="seriesPieTnc"
						[chart]="{
							width: 520,
							type: 'pie'
						}"
						[labels]="categories"
						[responsive]="[
						{
							breakpoint: 480,
							options: {
								chart: {
									width: 200
								},
								legend: {
									position: 'bottom'
								}
							}
						}
					]"
					></apx-chart>
				</div>
		
				<div id="wrapper" *ngIf="filter_tipo === 2">
					<apx-chart
						[series]="seriesPieBaja"
						[chart]="{
							width: 520,
							type: 'pie'
						}"
						[labels]="categories"
						[responsive]="[
						{
							breakpoint: 480,
							options: {
								chart: {
									width: 200
								},
								legend: {
									position: 'bottom'
								}
							}
						}
					]"
					></apx-chart>
				</div>
		
				<div id="wrapper" *ngIf="filter_tipo === 3">
					<apx-chart
						[series]="seriesPieVacantes"
						[chart]="{
							width: 520,
							type: 'pie'
						}"
						[labels]="categories"
						[responsive]="[
						{
							breakpoint: 480,
							options: {
								chart: {
									width: 200
								},
								legend: {
									position: 'bottom'
								}
							}
						}
					]"
					></apx-chart>
				</div>
		
				<div id="wrapper" *ngIf="filter_tipo === 4">
					<apx-chart
					[series]="seriesPiePorcentaje"
					[chart]="{
						width: 520,
						type: 'pie'
					}"
					[labels]="categories"
					[responsive]="[
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200
							},
							legend: {
								position: 'bottom'
							}
						}
					}
				]"
				></apx-chart>
				</div>
			</div>
			<div class="col-sm-7">
				<div id="wrapper" *ngIf="filter_tipo === 1">
					<div id="chart-months">
						<apx-chart
							[series]="seriesTnc"
							[chart]="{
								animations: {
									enabled: false,
									easing: 'easeinout',
									speed: 200,
									animateGradually: {
											enabled: false,
											delay: 150
									},
									dynamicAnimation: {
											enabled: false,
											speed: 350
									}
								},
								id: 'tnc',
								type: 'bar',
								height: 300,
								toolbar: {
									show: false,
									autoSelected: 'pan'
								}
							}"
							[yaxis]="{
								show: true,
								tickAmount: 3
							}"
							[dataLabels]="{
								enabled: false
							}"
							[fill]="{
								opacity: 1,
								type: 'solid'
							}"
							[stroke]="{
								width: 2,
								curve: 'smooth'
							}"
							[xaxis]="{
								type: 'datetime',
								categories: fechas
							}"
							[plotOptions]="{
								bar: {
									columnWidth: '40%',
									endingShape: 'rounded'
								}
							}"
						></apx-chart>
					</div>
					<div id="chart-years">
						<apx-chart
							[series]="seriesTnc"
							[chart]="{
								animations: {
									enabled: false,
									easing: 'easeinout',
									speed: 200,
									animateGradually: {
											enabled: false,
											delay: 150
									},
									dynamicAnimation: {
											enabled: false,
											speed: 350
									}
								},
								height: 150,
								type: 'bar',
								toolbar: {
									autoSelected: 'selection'
								},
								brush: {
									enabled: true,
									target: 'tnc'
								},
								selection: {
									enabled: true,
									xaxis: {
										min: selectionInicio,
										max: selectionFin
									}
								}
							}"
							[legend]="{
								position: 'top',
								horizontalAlign: 'left'
							}"
							[dataLabels]="{
								enabled: false
							}"
							[fill]="{
								opacity: 1,
								type: 'solid'
							}"
							[stroke]="{
								width: 2,
								curve: 'smooth'
							}"
							[xaxis]="{
								type: 'datetime',
								categories: fechas
							}"
							[plotOptions]="{
								bar: {
									columnWidth: '40%',
									endingShape: 'rounded'
								}
							}"
						></apx-chart>
					</div>
				</div>
		
				<div id="wrapper" *ngIf="filter_tipo === 2">
					<div id="chart-months">
						<apx-chart
							[series]="seriesBaja"
							[chart]="{
								animations: {
									enabled: false,
									easing: 'easeinout',
									speed: 200,
									animateGradually: {
											enabled: false,
											delay: 150
									},
									dynamicAnimation: {
											enabled: false,
											speed: 350
									}
								},
								id: 'baja',
								type: 'bar',
								height: 300,
								toolbar: {
									show: false,
									autoSelected: 'pan'
								}
							}"
							[yaxis]="{
								show: true,
								tickAmount: 3
							}"
							[dataLabels]="{
								enabled: false
							}"
							[fill]="{
								opacity: 1,
								type: 'solid'
							}"
							[stroke]="{
								width: 2,
								curve: 'smooth'
							}"
							[xaxis]="{
								type: 'datetime',
								categories: fechas
							}"
							[plotOptions]="{
								bar: {
									columnWidth: '40%',
									endingShape: 'rounded'
								}
							}"
						></apx-chart>
					</div>
					<div id="chart-years">
						<apx-chart
							[series]="seriesBaja"
							[chart]="{
								animations: {
									enabled: false,
									easing: 'easeinout',
									speed: 200,
									animateGradually: {
											enabled: false,
											delay: 150
									},
									dynamicAnimation: {
											enabled: false,
											speed: 350
									}
								},
								height: 150,
								type: 'bar',
								toolbar: {
									autoSelected: 'selection'
								},
								brush: {
									enabled: true,
									target: 'baja'
								},
								selection: {
									enabled: true,
									xaxis: {
										min: selectionInicio,
										max: selectionFin
									}
								}
							}"
							[legend]="{
								show: false,
								position: 'top',
								horizontalAlign: 'left'
							}"
							[dataLabels]="{
								enabled: false
							}"
							[fill]="{
								opacity: 1,
								type: 'solid'
							}"
							[stroke]="{
								width: 2,
								curve: 'smooth'
							}"
							[xaxis]="{
								type: 'datetime',
								categories: fechas
							}"
							[plotOptions]="{
								bar: {
									columnWidth: '40%',
									endingShape: 'rounded'
								}
							}"
						></apx-chart>
					</div>
				</div>
		
				<div id="wrapper" *ngIf="filter_tipo === 3">
					<div id="chart-months">
						<apx-chart
							[series]="seriesVacante"
							[chart]="{
								animations: {
									enabled: false,
									easing: 'easeinout',
									speed: 200,
									animateGradually: {
											enabled: false,
											delay: 150
									},
									dynamicAnimation: {
											enabled: false,
											speed: 350
									}
								},
								id: 'vacante',
								type: 'bar',
								height: 300,
								toolbar: {
									show: false,
									autoSelected: 'pan'
								}
							}"
							[yaxis]="{
								show: true,
								tickAmount: 3
							}"
							[dataLabels]="{
								enabled: false
							}"
							[fill]="{
								opacity: 1,
								type: 'solid'
							}"
							[stroke]="{
								width: 2,
								curve: 'smooth'
							}"
							[xaxis]="{
								type: 'datetime',
								categories: fechas
							}"
							[plotOptions]="{
								bar: {
									columnWidth: '40%',
									endingShape: 'rounded'
								}
							}"
						></apx-chart>
					</div>
					<div id="chart-years">
						<apx-chart
							[series]="seriesVacante"
							[chart]="{
								animations: {
									enabled: false,
									easing: 'easeinout',
									speed: 200,
									animateGradually: {
											enabled: false,
											delay: 150
									},
									dynamicAnimation: {
											enabled: false,
											speed: 350
									}
								},
								height: 150,
								type: 'bar',
								toolbar: {
									autoSelected: 'selection'
								},
								brush: {
									enabled: true,
									target: 'vacante'
								},
								selection: {
									enabled: true,
									xaxis: {
										min: selectionInicio,
										max: selectionFin
									}
								}
							}"
							[legend]="{
								show: false,
								position: 'top',
								horizontalAlign: 'left'
							}"
							[dataLabels]="{
								enabled: false
							}"
							[fill]="{
								opacity: 1,
								type: 'solid'
							}"
							[stroke]="{
								width: 2,
								curve: 'smooth'
							}"
							[xaxis]="{
								type: 'datetime',
								categories: fechas
							}"
							[plotOptions]="{
								bar: {
									columnWidth: '40%',
									endingShape: 'rounded'
								}
							}"
						></apx-chart>
					</div>
				</div>
		
				<div id="wrapper" *ngIf="filter_tipo === 4">
					<div id="chart-months">
						<apx-chart
							[series]="seriesPorcentaje"
							[chart]="{
								animations: {
									enabled: false,
									easing: 'easeinout',
									speed: 200,
									animateGradually: {
											enabled: false,
											delay: 150
									},
									dynamicAnimation: {
											enabled: false,
											speed: 350
									}
								},
								id: 'porcentaje',
								type: 'bar',
								height: 300,
								toolbar: {
									show: false,
									autoSelected: 'pan'
								}
							}"
							[yaxis]="{
								show: true,
								tickAmount: 3
							}"
							[dataLabels]="{
								enabled: false
							}"
							[fill]="{
								opacity: 1,
								type: 'solid'
							}"
							[stroke]="{
								width: 2,
								curve: 'smooth'
							}"
							[xaxis]="{
								type: 'datetime',
								categories: fechas
							}"
							[plotOptions]="{
								bar: {
									columnWidth: '40%',
									endingShape: 'rounded'
								}
							}"
						></apx-chart>
					</div>
					<div id="chart-years">
						<apx-chart
							[series]="seriesPorcentaje"
							[chart]="{
								animations: {
									enabled: false,
									easing: 'easeinout',
									speed: 200,
									animateGradually: {
											enabled: false,
											delay: 150
									},
									dynamicAnimation: {
											enabled: false,
											speed: 350
									}
								},
								height: 150,
								type: 'bar',
								toolbar: {
									autoSelected: 'selection'
								},
								brush: {
									enabled: true,
									target: 'porcentaje'
								},
								selection: {
									enabled: true,
									xaxis: {
										min: selectionInicio,
										max: selectionFin
									}
								}
							}"
							[legend]="{
								show: false,
								position: 'top',
								horizontalAlign: 'left'
							}"
							[dataLabels]="{
								enabled: false
							}"
							[fill]="{
								opacity: 1,
								type: 'solid'
							}"
							[stroke]="{
								width: 2,
								curve: 'smooth'
							}"
							[xaxis]="{
								type: 'datetime',
								categories: fechas
							}"
							[plotOptions]="{
								bar: {
									columnWidth: '40%',
									endingShape: 'rounded'
								}
							}"
						></apx-chart>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>