<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
	<div class="col-md-4 col-sm-6" *ngIf="identity.permisos && identity.permisos.rptKpiVendedores">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/estadistricas.png"
											ancho="180px"
											largo="170px"
											title="KPIs vendedores" 
											subTitle="En este reporte se encuentran las estadísticas de los contactos, empresas, oportunidades y actividades registradas en el sistema." 
											url="reportes/crm/kpis-vendedores"></app-reporte-menu>
	</div>
	<div class="col-md-4 col-sm-6" *ngIf="identity.permisos && identity.permisos.rptCumpleannos">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/cumpleanos.png"
											ancho="180px"
											largo="170px"  
											title="Cumpleaños" 
											subTitle="En este reporte se muestra los contactos que cumplen años." 
											url="reportes/crm/cumpleannos"></app-reporte-menu>
	</div>
	<div class="col-md-4 col-sm-6" *ngIf="identity.permisos && identity.permisos.rptOportunidadEtapas">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/oportunidad.png"
											ancho="180px"
											largo="170px"  
											title="Oportunidad por etapa" 
											subTitle="En este reporte se muestra las oportunidades registradas divididas por etapas." 
											url="reportes/crm/oportunidad/etapas"></app-reporte-menu>
	</div>
	<div class="col-md-4 col-sm-6" *ngIf="identity.permisos && identity.permisos.rptOportunidadPropietarios">
		<app-reporte-menu imagen="true" 
											src="assets/images/reportes/oportunidad.png"
											ancho="180px"
											largo="170px"  
											title="Oportunidad por propietario" 
											subTitle="En este reporte se gestiona las oportunidades registradas que tiene como responsable por cada usuario." 
											url="reportes/crm/oportunidad/propietarios"></app-reporte-menu>
	</div>
</div>
