import { Pipe, PipeTransform } from '@angular/core';
import { Reporte } from '../models/reporte-actividades';

interface ObjFiltroReporte {
  area: number;
  usuario: string;
}

@Pipe({
  name: 'filtroReporteActividad'
})
export class FiltroReporteActividadPipe implements PipeTransform {


  transform(value: Reporte[], args: ObjFiltroReporte): any {
    if (!value.length) {
      return [];
    }
    if (!args) {
      return value;
    }
    const listaFiltrada = value.filter( (item: Reporte) => {
      let existe: boolean = false;
      let variable = item.departamento.find(item => item.id === args.area );
      if (variable && variable.id ) { existe = true; }

      // let variable = item.departamento.findIndex(item => item.id === args.area );
      // if (variable === 0 ) { existe = true; }

      if ((!args.area || (args.area && existe === true )) && 
        (!args.usuario || (args.usuario && item.nombre === args.usuario))){
        return true;
      } else {
        return false;
      }
    });
    return listaFiltrada;
  }

}

