import { Component, OnInit, OnDestroy } from '@angular/core';
import { Division, DivisionSortType } from 'src/app/models/division';
import { DivisionesService } from 'src/app/services/divisiones.service';
import { ActivatedRoute,Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Pagination } from 'src/app/models/pagination';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.sass']
})
export class DivisionComponent implements OnInit, OnDestroy  {
  heading = 'Detalles de las divisiones';
  subheading = 'Divisiones registrandas en el sistema';
  icon = ' lnr-list icon-gradient bg-happy-fisher';
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeDivision: Subscription = new Subscription();
  public division: Division;
  public divisionInicial: Division = new Division(null);
  public divisiones: Division[];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public filterActive: boolean = false;
  public orderType = [...DivisionSortType];
  constructor(
    private divisionesService: DivisionesService,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) {
    this.division = new Division(null);
    this.paginacion.sortType = '+nombre';
    this.identity = new Identity(null);
    this.divisiones = [];
   }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeDivision = this.store.select('division').subscribe(
      (state) => {
        this.divisiones = [...state.divisiones];
      }
    );
    console.log('Inicializa component Division...');
    this.store.dispatch(new usuariosActions.CargarDivisiones());
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeDivision.unsubscribe();
  }


  editarDivision(zon: Division) {
    this.division = {...zon};
    this.divisionInicial = {...zon};
  }

  estatusDivision(division: Division) {
    if (division.id > 0) {
      this.loading = true;
      this.divisionesService.updateEstado(division.id).subscribe(
        (response) => {
          this.store.dispatch(new usuariosActions.ActualizaDivisionEstatusSuccess(division.id));
          this.toastr.success('Actualización correctamente.', 'Exito');
          this.loading = false;
        }, (error) => {
          this.toastr.error('Ocurrió un error.', 'Error');
          this.loading = false;
        }
      );
    }
  }

  onSubmit(form) {
    Swal.fire({
      title: '¿Deseas registrar la division?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.divisionesService.registerDivision(this.division).subscribe(
            (respuesta) => {
              if (respuesta.division) {
                this.store.dispatch(new usuariosActions.ActualizaDivisionSuccess(respuesta.division));
                resolve(respuesta.division);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaDivisionFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          form.reset();
          this.division = new Division(null);
          this.divisionInicial = new Division(null);
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Division registrada.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    ); 
  }

  limpiar(form) {
    this.division = new Division(null);
    this.divisionInicial = new Division(null);
    form.reset();
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

}
