import { SortOrder } from './sort';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Contacto{
	public id: number;
	public imagen: string;
	public email: CorreoContacto[];
	public emails: string;
	public nombre: string;
	public apellido: string;
	public cargo: string;
	public telefono: string;
	public extension: string;
	public celular: string;
	public tipoOrigen: number;
	public desTipoOrigen: string;
	public especificacion: boolean;
	public desEspecificacion: string;
	public etapa: number;
	public desEtapa: string;
	public estado: number;
	public desEstado: string;
	public propietario: number;
	public desPropietario: string;
	public empresa: number;
	public desEmpresa: string;
	public status: boolean;
	public pais: number;
	public desPais: string;
	public codigoPostal: string;
	public municipio: number;
	public desMunicipio: string;
	public estadoDireccion: number;
	public desEstadoDireccion: string;
	public colonia: string;
	public calle: string;
  public fecRegistro: Date;
	public fecModificacion: Date;
	public cumpleanios: Date;
	public fecCumpleanios: Date;
	public paginaWeb: string;
	public empresaPublicidad: string;
	public check: boolean;
	public fecAutoActualizacion: Date;
	constructor(
		obj: DataObjContacto
	){
		this.id = obj && obj.id ? obj.id : 0;
		this.imagen = obj && obj.imagen ? obj.imagen : '';
		this.email = obj && obj.email ? obj.email : [];
		this.emails = obj && obj.emails ? obj.emails : '';
		this.nombre = obj && obj.nombre ? obj.nombre: '';
		this.apellido = obj && obj.apellido ? obj.apellido : '';
		this.cargo = obj && obj.cargo ? obj.cargo: '';
		this.telefono = obj && obj.telefono ? obj.telefono: '';
		this.extension = obj && obj.extension ? obj.extension: null;
		this.celular = obj && obj.celular ? obj.celular : '';
		this.tipoOrigen = obj && obj.tipoOrigen ? obj.tipoOrigen : 0;
		this.desTipoOrigen = obj && obj.desTipoOrigen ? obj.desTipoOrigen : '';
		this.especificacion = obj && obj.especificacion ? obj.especificacion : false;
		this.desEspecificacion = obj && obj.desEspecificacion ? obj.desEspecificacion : '';
		this.etapa = obj && obj.etapa ? obj.etapa : null;
		this.desEtapa = obj && obj.desEtapa ? obj.desEtapa: '';
		this.estado = obj && obj.estado ? obj.estado : null;
		this.desEstado = obj && obj.desEstado ? obj.desEstado: '';
		this.propietario = obj && obj.propietario ? obj.propietario: null;
		this.desPropietario = obj && obj.desPropietario ? obj.desPropietario: '';
		this.empresa = obj && obj.empresa ? obj.empresa: null;
		this.desEmpresa = obj && obj.desEmpresa ? obj.desEmpresa : '';
		this.status = obj && obj.status ? obj.status : false;
		this.pais = obj && obj.pais ? obj.pais : null;
		this.desPais = obj && obj.desPais ? obj.desPais: '';
		this.codigoPostal = obj && obj.codigoPostal ? obj.codigoPostal: null;
		this.municipio = obj && obj.municipio ? obj.municipio: null;
		this.desMunicipio = obj && obj.desMunicipio ? obj.desMunicipio: '';
		this.estadoDireccion = obj && obj.estadoDireccion ? obj.estadoDireccion: null;
		this.desEstadoDireccion = obj && obj.desEstadoDireccion ? obj.desEstadoDireccion : '';
		this.colonia = obj && obj.colonia ? obj.colonia : '';
		this.calle = obj && obj.calle ? obj.calle : '';
		this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
		this.fecModificacion = obj && obj.fecModificacion ? obj.fecModificacion : null;
		this.cumpleanios = obj && obj.cumpleanios ? obj.cumpleanios : null;
		this.fecCumpleanios = obj && obj.fecCumpleanios ? obj.fecCumpleanios : null;
		this.paginaWeb = obj && obj.paginaWeb ? obj.paginaWeb : null;
		this.empresaPublicidad = obj && obj.empresaPublicidad ? obj.empresaPublicidad : null;
		this.check = obj && obj.check ? obj.check : false;
		this.fecAutoActualizacion = obj && obj.fecAutoActualizacion ? obj.fecAutoActualizacion : null;
  }
}

interface DataObjContacto{
	id?: number;
	imagen?: string;
	email?: CorreoContacto[];
	emails?: string;
	nombre?: string;
	apellido?: string;
	cargo?: string;
	telefono?: string;
	extension?: string;
	celular?: string;
	tipoOrigen?: number;
	desTipoOrigen?: string;
	especificacion?: boolean;
	desEspecificacion?: string;
	etapa?: number;
	desEtapa?: string;
	estado?: number;
	desEstado?: string;
	propietario?: number;
	desPropietario?: string;
	empresa?: number;
	desEmpresa?: string;
	status?: boolean;
	pais?: number;
	desPais?: string;
	codigoPostal?: string;
	municipio?: number;
	desMunicipio?: string;
	estadoDireccion?: number;
	desEstadoDireccion?: string;
	colonia?: string;
	calle?: string;
	fecRegistro?: Date;
	fecModificacion?: Date;
	cumpleanios?: Date;
	fecCumpleanios: Date;
	paginaWeb?: string;
	empresaPublicidad?: string;
	check?: boolean;
	fecAutoActualizacion?: Date;
}

export class CorreoContacto {
	public email: string;
	public suscrito: boolean;
	public spam: boolean;
	public rebotado: boolean;
	constructor(obj: ObjCorreoContacto) {
		this.email = obj && obj.email ? obj.email : '';
		this.suscrito = obj && obj.suscrito ? obj.suscrito : true;
		this.spam = obj && obj.spam ? obj.spam : false;
		this.rebotado = obj && obj.rebotado ? obj.rebotado : false;
	}
}

interface ObjCorreoContacto {
	email?: string;
	suscrito?: boolean;
	spam?: boolean;
	rebotado?: boolean;
}

export const ContactoSortType: SortOrder[] = [
	{ id: '+id', text: 'ID'},
	{ id: '-id', text: 'ID desc'},
	{ id: '+emails', text: 'Email'},
	{ id: '-emails', text: 'Email desc'},
	{ id: '+nombre', text: 'Nombres'},
	{ id: '-nombre', text: 'Nombres desc'},
	{ id: '+apellido', text: 'Apellidos'},
	{ id: '-apellido', text: 'Apellidos desc'},
	{ id: '+desCargo', text: 'Cargo'},
	{ id: '-desCargo', text: 'Cargo desc'},
	{ id: '+desEtapa', text: 'Etapa'},
	{ id: '-desEtapa', text: 'Etapa desc'},
	{ id: '+desEstado', text: 'Estados del contacto'},
	{ id: '-desEstado', text: 'Estados del contacto desc'},
	{ id: '+desEmpresa', text: 'Neogocio o Empresa'},
	{ id: '-desEmpresa', text: 'Neogocio o Empresa desc'},
	{ id: '+fecRegistro', text: 'Fecha de alta'},
	{ id: '-fecRegistro', text: 'Fecha de alta desc'},
	{ id: '+fecModificacion', text: 'Fecha de modificación'},
	{ id: '-fecModificacion', text: 'Fecha de modificación desc'}
];
