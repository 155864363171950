import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';

@Component({
  selector: 'app-forgot-password-boxed',
  templateUrl: './forgot-password-boxed.component.html',
  styles: [],
  providers: []
})
export class ForgotPasswordBoxedComponent implements OnInit, OnDestroy {

  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };

  constructor(
    private auth: AuthService,
    public store: Store<AppState>
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  onSubmit( data: any) {
   //  this.auth.resetPassword(data.email);
  }

}
