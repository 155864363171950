import { Component, OnInit, OnDestroy } from '@angular/core';
import { Catalogo } from 'src/app/models/catalogo';
import { Empresa } from 'src/app/models/empresa';
import { Oportunidad, NegocioCatalogo } from 'src/app/models/oportunidades';
import { OportunidadesService } from 'src/app/services/oportunidades.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { DateWithoutTimeZonePipe } from 'src/app/pipes/date-without-time-zone.pipe';


@Component({
  selector: 'app-oportunidades-edit',
  templateUrl: './oportunidades-edit.component.html',
  styleUrls: ['./oportunidades-edit.component.sass']
})
export class OportunidadesEditComponent implements OnInit, OnDestroy {
  heading = 'Detalles de la oportunidad';
  subheading = 'Oportunidad registranda en el sistema';
  icon = 'pe-7s-notebook icon-gradient bg-dark';
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private subcriptionOportunidad: Subscription = new Subscription();
  public oportunidad: Oportunidad  = new Oportunidad(null);
  public oportunidadInicial: Oportunidad  = new Oportunidad(null);
  public propietarios: Catalogo[] = [];
  public etapas: Catalogo[] = [];
  public empresas: Empresa[] = [];
  public empresasFiltradas: Empresa[] = [];
  public contactos: Catalogo[] = [];
  public negocios = [...NegocioCatalogo];
  public loading: boolean;
  public empresaAnterior: number;
  public editar: boolean;
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'nombre',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Sin datos'
  };
  public currencySettings = {
    digitGroupSeparator: ',',
    decimalCharacter: '.',
    decimalCharacterAlternative: '.',
    currencySymbol: '$ ',
    currencySymbolPlacement: 'p',
    roundingMethod: 'U',
    minimumValue: '0'
  };
  private pipeDateWithoutTimeZone = new DateWithoutTimeZonePipe();
  constructor(
    private oportunidadesService: OportunidadesService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { 
    this.identity = new Identity(null);
    this.empresaAnterior = -1;
    this.editar = true;
    this.store.dispatch(new usuariosActions.LimpiarOportunidad());
    this.store.dispatch(new usuariosActions.ConsultaEtapas());
    // this.store.dispatch(new usuariosActions.ConsultaEmpresas());
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.empresas = [...state.empresas];
        this.propietarios = [...state.usuarios.map( item => {
          return {id: item.id, nombre: item.descripcion, status: true};
        })];
        this.contactos = [...state.contactosEmpresa];
        this.etapas = [...state.etapas.filter(item => item.status)];
      }
    );
    this.subcriptionOportunidad = this.store.select('oportunidad').subscribe(
      (state) => {
        this.oportunidad = {...state.oportunidad};
        this.oportunidadInicial = {...state.oportunidad};
        this.oportunidad.fecha = this.oportunidad.fecha ? this.pipeDateWithoutTimeZone.transform(this.oportunidad.fecha) : null;
        this.oportunidadInicial.fecha = this.oportunidadInicial.fecha ? this.pipeDateWithoutTimeZone.transform(this.oportunidadInicial.fecha) : null;
        if (this.oportunidad.id > 0 && this.oportunidad.empresa > 0 && state.loaded && !state.loading) {
          this.contactosEmpresa(true);
        } else {
          this.editar = false;
        }
      }
    );
    console.log('Inicializa component empresas-edit...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeConsulta.unsubscribe();
    this.subcriptionOportunidad.unsubscribe();
  }

  inicializa() {
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        if (id > 0) {
          this.store.dispatch(new usuariosActions.CargarOportunidad(id));
        } else {
          this.oportunidad.propietario = this.identity.id;
          this.oportunidadInicial.propietario = this.identity.id;
        }
    });
  }

  async onSubmit() {
    await this.validaOportunidad().then(
      (resultado) => {
        Swal.fire({
          title: '¿Deseas registrar la oportunidad?',
          showCancelButton: true,
          confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
          cancelButtonColor: MESSAGES.C_BTN_CANCEL,
          confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
          cancelButtonText: MESSAGES.M_BTN_CANCEL,
          focusConfirm: false,
          focusCancel: true,
          animation: true,
          showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
          showLoaderOnConfirm: true,
          preConfirm:  ()  => {
            return new Promise( (resolve)  => {
              this.oportunidadesService.registraOportunidad(this.oportunidad).subscribe(
                (respuesta) => {
                  if (respuesta.oportunidad) {
                    this.store.dispatch(new usuariosActions.ActualizaOportunidadSuccess(respuesta.oportunidad));
                    resolve(respuesta.oportunidad);
                  } else {
                    Swal.showValidationMessage('Ocurrió un error en la operación');
                    resolve(false);
                  }
                }, (error) => {
                  this.store.dispatch(new usuariosActions.ActualizaOportunidadFail(error));
                  Swal.showValidationMessage('Ocurrió un error en la operación');
                  resolve(false);
                }
              );
            });
          },
          allowOutsideClick: false
        }).then(
          (result: any) => {
            if (result.value) {
              Swal.fire({
                title: MESSAGES.M_TIT_CONFIRM,
                text: 'Oportunidad registrada.',
                icon: 'success'
              });
              this.router.navigate(['/auth/oportunidades/profile', result.value.id]);
            } else {
              Swal.fire({
                title: MESSAGES.M_TIT_CANCELADO,
                timer: 1200,
                showConfirmButton: false,
                icon: 'error',
                onOpen: () =>  {}
              }).then(() => {}, () => {});
            }
          }, () => {
            Swal.fire({
              title: MESSAGES.M_TIT_CANCELADO,
              timer: 1200,
              showConfirmButton: false,
              icon: 'error',
              onOpen: () => {}
            }).then( () => {},  () => {});
          }
        );
    }, (error) => { }
    );
  }

  contactosEmpresa(existe?: boolean){
    if (this.oportunidad.desEmpresa) {
      if (!existe) {
        this.oportunidad.empresa = 0;
      }
      this.empresas.findIndex(item => {
        if(item.nombre.toLowerCase().trim() === this.oportunidad.desEmpresa.toLowerCase().trim()){
          this.oportunidad.empresa = item.id;
          return true;
        }
      });
      if (this.oportunidad.empresa === 0) {
        this.toastr.warning('La empresa no existe');
      } else {
        if (this.oportunidad.empresa > 0 && this.oportunidad.empresa !== this.empresaAnterior) {
          this.editar = true;
          this.store.dispatch(new usuariosActions.ConsultaContactosEmpresa(this.oportunidad.empresa));
          this.empresaAnterior = this.oportunidad.empresa;
        } else if (this.oportunidad.empresa === 0 && this.oportunidad.empresa !== this.empresaAnterior) {
          this.editar = false
          this.contactos = [];
          this.empresaAnterior = 0;
        }
      }
    }
  }

  onItemSelect(item: any) {
    // console.log(item);
  }

  onSelectAll(items: any) {
    // console.log(items);
  }

  validaOportunidad() {
    const deferred = new Promise((resolve, reject) => {
      if (!this.oportunidad.contactos || !this.oportunidad.contactos.length) {
        resolve(false);
        this.toastr.warning('Requiere al menos un contacto');
      }
      resolve(true);
    });
    return deferred;
  }

  cargarEmpresas() {
    if (this.oportunidad.desEmpresa.trim().length >= 3 ) {
      this.store.dispatch(new usuariosActions.ConsultaEmpresas(this.oportunidad.desEmpresa, 100));
    } else {
      this.empresas = [];
    }
  }


}



