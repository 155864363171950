import { SortOrder } from './sort';

export class Asignaciones {
    public id: number;
    public numeroOrden: number;
    public cliente: number;
    public desCliente: string;
    public servicio: number;
    public desServicio: string;
    // public localidad: number;
    public localidad: string;
    public sucursal: number;
    public desSucursal: string;
    public fecInicio: Date;
    public fecTermino: Date;
    public opcFin: boolean;
    public tipoServicio: number;
    public desTipoServicio: string;
    public tipoSolicitud: number;
    public desTipoSolicitud: string;
    public elementos: number;
    public turnos: number;
    public division: number;
    public desDivision: string;
    public estatus: number;
    
  constructor(
    obj: ObjAsignaciones
  ) {
        this.id = obj && obj.id ? obj.id : 0;
        this.numeroOrden = obj && obj.numeroOrden ? obj.numeroOrden : null;
        this.cliente = obj && obj.cliente ? obj.cliente : null;
        this.desCliente = obj && obj.desCliente ? obj.desCliente : '';
        this.servicio = obj && obj.servicio ? obj.servicio : null;
        this.desServicio = obj && obj.desServicio ? obj.desServicio : '';
        // this.localidad = obj && obj.localidad ? obj.localidad : null;
        this.localidad = obj && obj.localidad ? obj.localidad : '';
        this.sucursal = obj && obj.sucursal ? obj.sucursal : null;
        this.desSucursal = obj && obj.desSucursal ? obj.desSucursal : '';
        this.fecInicio = obj && obj.fecInicio ? obj.fecInicio : null;
        this.fecTermino = obj && obj.fecTermino ? obj.fecTermino : null;
        this.opcFin = obj && obj.opcFin ? obj.opcFin : false;
        this.tipoServicio = obj && obj.tipoServicio ? obj.tipoServicio : null;
        this.desTipoServicio = obj && obj.desTipoServicio ? obj.desTipoServicio : '';
        this.tipoSolicitud = obj && obj.tipoSolicitud ? obj.tipoSolicitud : null;
        this.desTipoSolicitud = obj && obj.desTipoSolicitud ? obj.desTipoSolicitud : '';
        this.elementos = obj && obj.elementos ? obj.elementos : 0;
        this.turnos = obj && obj.turnos ? obj.turnos : 0;
        this.division = obj && obj.division ? obj.division : null;
        this.desDivision = obj && obj.desDivision ? obj.desDivision : '';
        this.estatus = obj && obj.estatus ? obj.estatus : 0;
        
  }
}

interface ObjAsignaciones {
    id: number;
    numeroOrden: number;
    cliente: number;
    desCliente: string;
    servicio: number;
    desServicio: string;
    // localidad: number;
    localidad: string;
    sucursal: number;
    desSucursal: string;
    fecInicio: Date;
    fecTermino: Date;
    opcFin: boolean;
    tipoServicio: number;
    desTipoServicio: string;
    tipoSolicitud: number;
    desTipoSolicitud: string;
    elementos: number;
    turnos: number;
    division: number;
    desDivision: string;
    estatus: number;
}


export const AsignacionSortType: SortOrder[] = [
	{ id: '+id', text: 'ID'},
	{ id: '-id', text: 'ID desc'},
	{ id: '+numeroOrden', text: 'Número de orden'},
	{ id: '-numeroOrden', text: 'Número de orden desc'},
	{ id: '+desServicio', text: 'Servicio'},
  { id: '-desServicio', text: 'Servicio desc'},
  { id: '+localidad', text: 'Localidad'},
  { id: '-localidad', text: 'Localidad desc'},
  { id: '+desSucursal', text: 'Sucursal'},
  { id: '-desSucursal', text: 'Sucursal desc'},
  { id: '+desTipoServicio', text: 'Tipo de servicio'},
  { id: '-desTipoServicio', text: 'Tipo de servicio desc'},
  { id: '+desTipoSolicitud', text: 'Tipo de solicitud'},
  { id: '-desTipoSolicitud', text: 'Tipo de solicitud desc'},
  { id: '+elementos', text: 'Elementos'},
  { id: '-elementos', text: 'Elementos desc'},
  { id: '+turnos', text: 'Turnos'},
	{ id: '-turnos', text: 'Turnos desc'}
  ];


