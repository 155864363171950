import { Action } from '@ngrx/store';
import { Asignaciones, Capturas } from 'src/app/models/captura-asignacion';
import { Fechas } from 'src/app/models/fechas';

export const CARGAR_CAPTURA_ASIGNACION = '[Asignaciones] Cargar captura asignacion';
export const CARGAR_CAPTURA_ASIGNACION_FAIL = '[Asignaciones] Cargar captura asignacion FAIL';
export const CARGAR_CAPTURA_ASIGNACION_SUCCESS = '[Asignaciones] Cargar captura asignacion SUCCESS';
export const ACTUALIZA_CAPTURA_ASIGNACION = '[Asignaciones] Actualiza captura asignacion';
export const ACTUALIZA_CAPTURA_ASIGNACION_FAIL = '[Asignaciones] Actualiza captura asignacion FAIL';
export const ACTUALIZA_CAPTURA_ASIGNACION_SUCCESS = '[Asignaciones] Actualiza captura asignacion SUCCESS';
export const LIMPIAR_CAPTURAS_ASIGNACION = '[Asignaciones] Limpiar indicador';



export class CargarCapturasAsignacion implements Action {
    readonly type = CARGAR_CAPTURA_ASIGNACION;

    constructor( public fecha: Fechas, public opciones: any ) {}
  }
  
export class CargarCapturasAsignacionFail implements Action {
  readonly type = CARGAR_CAPTURA_ASIGNACION_FAIL;
  
  constructor( public payload: any ) {}
  }
  
export class CargarCapturasAsignacionSuccess implements Action {
  readonly type = CARGAR_CAPTURA_ASIGNACION_SUCCESS;
  
  constructor( public capturas: Asignaciones[], public totalFiltro: number, public total: number ) {}
  }

export class ActualizaCapturaAsignacion implements Action {
  readonly type = ACTUALIZA_CAPTURA_ASIGNACION;
  
  constructor( public id: number, public captura: Capturas) { }
  }
  
export class ActualizaCapturaAsignacionFail implements Action {
  readonly type = ACTUALIZA_CAPTURA_ASIGNACION_FAIL;
  
  constructor( public payload: any ) {}
  }
  
export class ActualizaCapturaAsignacionSuccess implements Action {
  readonly type = ACTUALIZA_CAPTURA_ASIGNACION_SUCCESS;
  
  constructor( public id: number, public captura : Capturas ) {}
  }


export class LimpiarCapturasAsignacion implements Action {
    readonly type = LIMPIAR_CAPTURAS_ASIGNACION;

    constructor() {}  
}

export type capturasAsignacionAcciones = CargarCapturasAsignacion
                                        | CargarCapturasAsignacionFail
                                        | CargarCapturasAsignacionSuccess
                                        | ActualizaCapturaAsignacion
                                        | ActualizaCapturaAsignacionFail
                                        | ActualizaCapturaAsignacionSuccess
                                        | LimpiarCapturasAsignacion;