import { Action } from '@ngrx/store';
import { Area, Departamento } from 'src/app/models/departamento';

export const CARGAR_AREAS_USUARIO = '[Area] Cargar areas usuario';
export const CARGAR_AREAS_USUARIO_FAIL = '[Area] Cargar areas usuario FAIL';
export const CARGAR_AREAS_USUARIO_SUCCESS = '[Area] Cargar areas usuario SUCCESS';
export const CARGAR_AREAS = '[Area] Cargar areas';
export const CARGAR_AREAS_FAIL = '[Area] Cargar areas FAIL';
export const CARGAR_AREAS_SUCCESS = '[Area] Cargar areas SUCCESS';
export const ACTUALIZA_AREA = '[Area] Actualiza area';
export const ACTUALIZA_AREA_FAIL = '[Area] Actualiza area FAIL';
export const ACTUALIZA_AREA_SUCCESS = '[Area] Actualiza area SUCCESS';
export const ELIMINAR_AREA = '[Area] Elimina area';
export const ELIMINAR_AREA_FAIL = '[Area] Elimina area FAIL';
export const ELIMINAR_AREA_SUCCESS = '[Area] Elimina area SUCCESS';



export class CargarAreasUsuario implements Action {
    readonly type = CARGAR_AREAS_USUARIO;
  }
  
export class CargarAreasUsuarioFail implements Action {
  readonly type = CARGAR_AREAS_USUARIO_FAIL;
  
  constructor( public payload: any ) {}
  }
  
export class CargarAreasUsuarioSuccess implements Action {
  readonly type = CARGAR_AREAS_USUARIO_SUCCESS;
  
  constructor( public areas: Area[] ) {}
  }

export class CargarAreas implements Action {
    readonly type = CARGAR_AREAS;
  }
  
export class CargarAreasFail implements Action {
  readonly type = CARGAR_AREAS_FAIL;
  
  constructor( public payload: any ) {}
  }
  
export class CargarAreasSuccess implements Action {
  readonly type = CARGAR_AREAS_SUCCESS;
  
  constructor( public areas: Departamento[] ) {}
  }

export class ActualizaArea implements Action {
  readonly type = ACTUALIZA_AREA;

  constructor( public area: Departamento ) { }
}

export class ActualizaAreaFail implements Action {
  readonly type = ACTUALIZA_AREA_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaAreaSuccess implements Action {
  readonly type = ACTUALIZA_AREA_SUCCESS;

  constructor( public area: Departamento ) {}
}

export class EliminarArea implements Action {
  readonly type = ELIMINAR_AREA;

  constructor( public id: number ) { }
}

export class EliminarAreaFail implements Action {
  readonly type = ELIMINAR_AREA_FAIL;

  constructor( public payload: any ) {}
}

export class EliminarAreaSuccess implements Action {
  readonly type = ELIMINAR_AREA_SUCCESS;

  constructor( public id: number ) {}
}

export type areasAcciones = CargarAreasUsuario
                            | CargarAreasUsuarioFail
                            | CargarAreasUsuarioSuccess
                            | CargarAreas
                            | CargarAreasFail
                            | CargarAreasSuccess
                            | ActualizaArea 
                            | ActualizaAreaFail 
                            | ActualizaAreaSuccess 
                            | EliminarArea 
                            | EliminarAreaFail 
                            | EliminarAreaSuccess;