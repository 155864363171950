import * as fromUsuario from '../actions';
import { ReporteDia, CoberturaOperacional, CoberturaOperacionalServicio, DivisionIniFinaEn, ServicioIniFinaEn, DivisionBajaVacan } from 'src/app/models/reporte-asignacion';

export interface ReporteAsignacionState {
  coberturaOperacional: CoberturaOperacional[];
  reporte: ReporteDia[];
  coberturaOperacionalServicio: CoberturaOperacionalServicio[];
  divisionIniFinaEn: DivisionIniFinaEn[];
  servicioIniFinaEn: ServicioIniFinaEn[];
  bajaVacante: DivisionBajaVacan[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: ReporteAsignacionState = {
  coberturaOperacional: [],
  reporte: [],
  coberturaOperacionalServicio: [],
  divisionIniFinaEn: [],
  servicioIniFinaEn: [],
  bajaVacante: [],
  loaded: false,
  loading: false,
  error: null
};

export function reporteAsignacionReducer( state = estadoInicial, action: fromUsuario.reporteAsignacionDiaAcciones): ReporteAsignacionState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_REPORTE_ASIGNACION_DIA:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_REPORTE_ASIGNACION_DIA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        reporte: [...action.reporte]
        };
    case fromUsuario.CARGAR_REPORTE_ASIGNACION_DIA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.CARGAR_REPORTE_COBERTURA_OPERACIONAL:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_REPORTE_COBERTURA_OPERACIONAL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        coberturaOperacional: [...action.reporte]
        };
    case fromUsuario.CARGAR_REPORTE_COBERTURA_OPERACIONAL_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.CARGAR_REPORTE_COBERTURA_OPERACIONAL_SERVICIO:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_REPORTE_COBERTURA_OPERACIONAL_SERVICIO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        coberturaOperacionalServicio: [...action.reporte]
        };
    case fromUsuario.CARGAR_REPORTE_COBERTURA_OPERACIONAL_SERVICIO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
     case fromUsuario.CARGAR_REPORTE_SERVICIO_INICIO:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_REPORTE_SERVICIO_INICIO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        servicioIniFinaEn: [...action.reporte],
        divisionIniFinaEn: [...action.division]
        };
    case fromUsuario.CARGAR_REPORTE_SERVICIO_INICIO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.CARGAR_REPORTE_SERVICIO_FINALIZO:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_REPORTE_SERVICIO_FINALIZO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        servicioIniFinaEn: [...action.reporte],
        divisionIniFinaEn: [...action.division]
        };
    case fromUsuario.CARGAR_REPORTE_SERVICIO_FINALIZO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.CARGAR_REPORTE_BAJA_VACANTES:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_REPORTE_BAJA_VACANTES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        bajaVacante: [...action.reporte]
        };
    case fromUsuario.CARGAR_REPORTE_BAJA_VACANTES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.LIMPIAR_REPORTE_ASIGNACION_DIA:
      return {
        ...state,
        reporte: []
      };
    default:
      return state;
  }
}

