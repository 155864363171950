import { BrowserModule} from '@angular/platform-browser';
import { NgModule, LOCALE_ID} from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
// import { NgReduxModule} from '@angular-redux/store';
// import { NgRedux, DevToolsExtension} from '@angular-redux/store';
import { rootReducer, ArchitectUIState} from './themeoptions/store';
import { AppRoutingModule} from './app-routing.module';
// import { LoadingBarRouterModule} from '@ngx-loading-bar/router';
import { TokenInterceptorService } from './services/token-interceptor.service';

import {CommonModule, LocationStrategy, HashLocationStrategy} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppComponent} from './app.component';
// import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MetaModule } from '@ngx-meta/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-MX';
registerLocaleData(localeEs, 'es');
// import { CookieService } from 'ngx-cookie-service';

import { MomentModule } from 'ngx-moment';

// BOOTSTRAP COMPONENTS

// import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {NgbModule, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
// import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
// import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
// import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
// import {NgxLoadingModule, ngxLoadingAnimationTypes} from 'ngx-loading';
// import {RoundProgressModule} from 'angular-svg-round-progressbar';
import {ToastrModule} from 'ngx-toastr';
// import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
// import {AngularStickyThingsModule} from '@w11k/angular-sticky-things';
// import {NouisliderModule} from 'ng2-nouislider';
// import {JwBootstrapSwitchNg2Module} from 'jw-bootstrap-switch-ng2';
// import {AngularEditorModule} from '@kolkov/angular-editor';
// LAYOUT

import {ThemeOptions} from './theme-options';

// import { MomentModule } from 'angular2-moment';
// Gauges Charts

// import { environment } from 'src/environments/environment.prod';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './store/app.reducer';
import { AuthModule } from './components/auth/auth.module';
import { PublicModule } from './components/public/public.module';
import { EffectsModule } from '@ngrx/effects';
import { effectsArr } from './store/effects';
import { StorageModule } from '@ngx-pwa/local-storage';
import { LayoutModule } from './layout/layout.module';
import { DecorationsModule } from './decorations/decorations.module';
import { PipesModule } from './pipes/pipes.module';
// import { NgbDateCustomParserFormatterService } from './services/ngb-date-custom-parser-formatter.service';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '../environments/environment';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {defineLocale} from "ngx-bootstrap/chronos";
import {esLocale} from 'ngx-bootstrap/locale';
defineLocale('es', esLocale);





@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // StoreModule.forRoot({}, {}),
    // StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgReduxModule,
    CommonModule,
    // LoadingBarRouterModule,
    LayoutModule,
    AuthModule,
    PublicModule,
    DecorationsModule,
    PipesModule,

    // Angular Bootstrap Components

    // PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    // NgBootstrapFormValidationModule.forRoot(),
    // NgxLoadingModule.forRoot({
    //   animationType: ngxLoadingAnimationTypes.threeBounce,
    //   backdropBackgroundColour: 'rgba(0,0,0,0.1)',
    //   backdropBorderRadius: '4px',
    //   primaryColour: '#ffffff',
    //   secondaryColour: '#ffffff',
    //   tertiaryColour: '#ffffff',
    //   fullScreenBackdrop: true
    // }),
    // RoundProgressModule,
    MomentModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    // AngularStickyThingsModule,
    // NouisliderModule,
    // JwBootstrapSwitchNg2Module,
    // AngularEditorModule,
    HttpClientModule,
    SweetAlert2Module.forRoot({
      // buttonsStyling: false,
      // customClass: 'modal-content',
      // confirmButtonClass: 'btn btn-primary',
      // cancelButtonClass: 'btn btn-secondary'
    }),

    StoreModule.forRoot(appReducers, {
      runtimeChecks: {
        // strictStateImmutability: false,
        // strictActionImmutability: false
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot( effectsArr ),
    // NgIdleKeepaliveModule.forRoot(),
    MetaModule.forRoot(),
    StorageModule.forRoot({ IDBNoWrap: true }),
    FontAwesomeModule,
  ],
  providers: [
    // {
    //   provide:
    //   PERFECT_SCROLLBAR_CONFIG,
    //   // DROPZONE_CONFIG,
    //   useValue:
    //   DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    //   // DEFAULT_DROPZONE_CONFIG,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    // ConfigActions,
    ThemeOptions,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    // EncrDecrService,
    // CookieService,
    // { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatterService },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(
    private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension,
    private bsLocaleService: BsLocaleService
  ) {
    this.bsLocaleService.use('es');
    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );
  }
}
