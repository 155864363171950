import { SortOrder } from './sort';

export class Servicio{
	public id: number;
	public nombre: string;
	public sucursal: number;
	public desSucursal: string;
	public cliente: number;
    public desCliente: string;
	public status: boolean;

	constructor(
		obj: DataObjServicio
	){
		this.id = obj && obj.id? obj.id :null;
		this.nombre = obj && obj.nombre? obj.nombre :'';
		this.sucursal = obj && obj.sucursal? obj.sucursal :null;
		this.desSucursal = obj && obj.desSucursal? obj.desSucursal :'';
		this.cliente = obj && obj.cliente? obj.cliente :null;
		this.desCliente = obj && obj.desCliente? obj.desCliente :'';
		this.status = obj && obj.status ? obj.status : false;
	}
}
interface DataObjServicio{
    id: number;
	nombre: string;
	sucursal: number;
	desSucursal: string;
	cliente: number;
	desCliente: string;
	status: boolean;
}



export const ServicioSortType: SortOrder[] = [
	{ id: '+id', text: 'ID'},
	{ id: '-id', text: 'ID desc'},
	{ id: '+nombre', text: 'Nombre'},
	{ id: '-nombre', text: 'Nombre desc'},
	{ id: '+desCliente', text: 'Clientes'},
	{ id: '-desCliente', text: 'Clientes desc'},
	{ id: '+desSucursal', text: 'Sucursal'},
	{ id: '-desSucursal', text: 'Sucursal desc'}
  ];