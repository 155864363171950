import * as fromAuth from '../actions/auth.actions';
import { Identity } from '../../models/user';
import { identity } from 'rxjs';

export interface AuthState {
    identity: Identity;
    loaded: boolean;
    loading: boolean;
    error: any;
}

const estadoInicial: AuthState = {
    identity: null,
    loaded: false,
    loading: false,
    error: null
};

export function authReducer( state = estadoInicial, action: fromAuth.authAcciones ): AuthState {

    switch ( action.type ) {

        case fromAuth.SET_AUTH:
            return {
                ...state,
                // user: { ... action.user },
                loading: true,
                loaded: false,
                error: null
            };

        case fromAuth.UNSET_AUTH:
            return {
                ...state,
                loading: true,
                loaded: false,
                identity: null
            };

        case fromAuth.GET_AUTH:
            return {
                ...state,
                loading: true,
                error: null
            };

        case fromAuth.LOAD_AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                identity: {...action.identity}
            };

        case fromAuth.LOAD_AUTH_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };

        case fromAuth.CHANGE_PASSWORD_AUTH:
            return {
                ...state,
                // user: { ... action.user },
                loading: true,
                loaded: false,
                error: null
            };

        case fromAuth.CHANGE_PASSWORD_AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true
            };

        case fromAuth.CHANGE_PASSWORD_AUTH_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: { ...action.payload }
            };

        case fromAuth.SET_PROFILE_AUTH:
            return {
                ...state,
                // user: { ... action.user },
                loading: true,
                loaded: false,
                error: null
            };

        case fromAuth.SET_PROFILE_AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                identity: {
                    ...state.identity,
                    ...action
                }
            };

        case fromAuth.SET_PROFILE_AUTH_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };

        default:
            return state;

    }


}
