import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as usuarioActions from '../actions';
import { of, Observable } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import {Location} from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        public authService: AuthService,
        public usuarioService: UsuariosService,
        private router: Router,
        protected local: StorageMap,
        private location: Location,
        private toastr: ToastrService
    ) {}

    @Effect()
    loginUsuario$ = this.actions$.pipe(
      ofType(usuarioActions.SET_AUTH ),
      switchMap( (action: usuarioActions.SetAuthAction) => {
        return this.authService.login(action.usuario, action.password, action.captcha, action.rememberme)
          .pipe(
            map( user => {
              if (user.login && user.login.identity && user.login.identity.id) {
                const identity = user.login.identity;
                const token = user.login.token;
                this.authService.setIdentity(identity);
                this.authService.setToken(token);
                if (action.rememberme) {
                  this.authService.setUsuarioSession(action.usuario, action.password);
                  localStorage.setItem('rememberme', 'true');
                } else {
                  this.authService.setUsuarioSession('', '');
                  localStorage.setItem('rememberme', 'false');
                }
                this.router.navigate(['/auth/dashboard']);
                return new usuarioActions.LoadAuthSuccess(user.login.identity);
              } else {
                return new usuarioActions.LoadAuthFail(user.login);
              }
            }),
            catchError( error => of(new usuarioActions.LoadAuthFail(error))  )
          );
      })
    );

    @Effect()
    cargarUsuario$ = this.actions$.pipe(
      ofType(usuarioActions.GET_AUTH ),
      switchMap( (action: any) => {
        return this.authService.getIdentity()
          .pipe(
            map( (identity: any) => {
              return new usuarioActions.LoadAuthSuccess({...identity});
            }),
            catchError( error => of(new usuarioActions.LoadAuthFail(error))  )
          );
      })
    );

    @Effect()
    logoutUsuario$ = this.actions$.pipe(
      ofType(usuarioActions.UNSET_AUTH ),
      switchMap( (action: any) => {
        return this.authService.logOut()
          .pipe(
            map( (user) => {
              const item = localStorage.getItem('sesionUser');
              const rememberme = localStorage.getItem('rememberme');
              localStorage.clear();
              this.local.clear().subscribe();
              localStorage.setItem('sesionUser', item);
              localStorage.setItem('rememberme', rememberme);
              this.router.navigate(['/public/login']);
              return new usuarioActions.LoadAuthSuccess(null);
            }),
            catchError( (err: any) => {
              const item = localStorage.getItem('sesionUser');
              const rememberme = localStorage.getItem('rememberme');
              localStorage.clear();
              this.local.clear().subscribe();
              localStorage.setItem('sesionUser', item);
              localStorage.setItem('rememberme', rememberme);
              this.router.navigate(['/public/login']);
              return of(new usuarioActions.LoadAuthSuccess(null));
            })
          );
      })
    );

    @Effect()
    changePasswordUsuario$ = this.actions$.pipe(
      ofType(usuarioActions.CHANGE_PASSWORD_AUTH ),
      switchMap( (action: any) => {
        return this.usuarioService.changePassword(action.actual, action.password)
          .pipe(
            map( (user) => {
              this.location.back();
              this.toastr.success('Actualización correctamente');
              return new usuarioActions.GetAuthAction();
            }),
            catchError( (err: any) => {
              return of(new usuarioActions.ChangePasswordAuthFail(err));
            })
          );
      })
    );

    @Effect()
    SetProfileUsuario$ = this.actions$.pipe(
      ofType(usuarioActions.SET_PROFILE_AUTH ),
      switchMap( (action: any) => {
        return this.usuarioService.updateUserPerfil(action.usuario)
          .pipe(
            map( (user: any) => {
              return new usuarioActions.SetProfileAuthSuccess(user.nombre,
                user.apellidoPaterno + ' ' + user.apellidoMaterno, user.email );
            }),
            catchError( (err: any) => {
              return of(new usuarioActions.SetProfileAuthFail(err));
            })
          );
      })
    );

}
