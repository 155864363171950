import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Identity } from 'src/app/models/user';
import { Sucursal, SucursalSortType } from 'src/app/models/sucursales';
import { Pagination } from 'src/app/models/pagination';
import { SucursalesService } from 'src/app/services/sucursales.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { ToastrService } from 'ngx-toastr';
import { GENERALES } from 'src/app/models/generales';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import { Division } from 'src/app/models/division';
import * as usuariosActions from 'src/app/store/actions';
import { Estado } from 'src/app/models/direccion';

@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.sass']
})
export class SucursalesComponent implements OnInit, OnDestroy {
  public title: string;
  public identity: Identity;
  private subscribeAuth: Subscription = new Subscription();
  private subscribeSucursal: Subscription = new Subscription();
  private subscribeDireccion: Subscription = new Subscription();
  public sucursal: Sucursal;
  public sucursales: Sucursal[];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public divisiones: Division[] = [];
  public estados: Estado[] = [];
  public sucursalInicial = new Sucursal(null);
  public filterActive: boolean = false;
  public orderType = [...SucursalSortType];
  constructor(
    private catalogosService: SucursalesService,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) { 
    this.paginacion.sortType = '+nombre';
    this.identity = new Identity(null);
    this.sucursal = new Sucursal(null);
    this.sucursales = [];
  }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeSucursal = this.store.select('sucursales').subscribe(
      (state) => {
        this.sucursales = [...state.sucursales];
        this.divisiones = [...state.divisiones.filter( item => item.estatus)];
      }
    );
    this.subscribeDireccion = this.store.select('direccion').subscribe(
      (state) => {
        this.estados = [...state.estados];
      }
    );
    this.title = 'Catálogo Sucursales';
    console.log('Inicializa component Sucursales...');
    this.store.dispatch(new usuariosActions.CargarEstados());
    this.store.dispatch(new usuariosActions.CargarSucursales());
  }
  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeSucursal.unsubscribe();
    this.subscribeDireccion.unsubscribe();
  }

  getSucursales() {
    this.loading = true;
    this.catalogosService.obtenerSucursales().subscribe(
      (response) => {
        this.sucursales = response.sucursales;
        this.loading = false;
      }, (err) => {
        this.toastr.error('Ocurrió un error.', 'Error');
        this.loading = false;
      }
    );
  }

  editarSucursal(format) {
    this.sucursal = {...format};
    this.sucursalInicial = {...format};
  }

  estatusSucursal(sucur) {
    if (sucur.id > 0) {
      this.loading = true;
      this.catalogosService.actualizaEstatusSucursal(sucur).subscribe(
        (response) => {
          this.store.dispatch(new usuariosActions.ActualizaSucursalEstatusSuccess(sucur.id));
          this.toastr.success('Actualización correctamente.', 'Exito');
          this.loading = false;
        }, (error) => {
          this.toastr.error('Ocurrió un error.', 'Error');
          this.loading = false;
        }
      );
    }
  }

  onSubmit(form) {
    const itm = this.divisiones.find( item => item.id === this.sucursal.division);
    this.sucursal.desDivision = itm.nombre;
    Swal.fire({
      title: '¿Deseas registrar la sucursal?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve, reject)  => {
          this.catalogosService.registraSucursal(this.sucursal).subscribe(
            (respuesta) => {
              if (respuesta.sucursal) {
                this.store.dispatch(new usuariosActions.ActualizaSucursalSuccess(respuesta.sucursal));
                resolve(respuesta.sucursal);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaSucursalFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          form.reset();
          this.sucursal = new Sucursal(null);
          this.sucursalInicial = new Sucursal(null);
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Sucursal registrado.',
            icon: 'success'
          });
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  limpiar(form) {
    this.sucursal = new Sucursal(null);
    this.sucursalInicial = new Sucursal(null);
    form.reset();
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

}
