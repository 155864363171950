import { Action } from '@ngrx/store';
import { Contacto } from 'src/app/models/contacto';
import { Oportunidad } from 'src/app/models/oportunidades';
import { VentaPropietario } from 'src/app/models/reporte-ventas-propietarios';
import { Fechas } from 'src/app/models/fechas';

export const CARGAR_REPORTE_CUMPLEANNOS = '[Reportes] Cargar reporte cumpleannos';
export const CARGAR_REPORTE_CUMPLEANNOS_SUCCESS = '[Reportes] Cargar reporte cumpleannos success';
export const CARGAR_REPORTE_CUMPLEANNOS_FAIL = '[Reportes] Cargar reporte cumpleannos fail';
export const LIMPIAR_REPORTE_CUMPLEANNOS = '[Reportes] Limpiar reporte cumpleannos';
export const CARGAR_REPORTE_VENTAS_ETAPAS = '[Reportes] Cargar reporte ventas etapas';
export const CARGAR_REPORTE_VENTAS_ETAPAS_SUCCESS = '[Reportes] Cargar reporte ventas etapas success';
export const CARGAR_REPORTE_VENTAS_ETAPAS_FAIL = '[Reportes] Cargar reporte ventas etapas fail';
export const LIMPIAR_REPORTE_VENTAS_ETAPAS = '[Reportes] Limpiar reporte ventas etapas';
export const CARGAR_REPORTE_VENTAS_PROPIETARIOS = '[Reportes] Cargar reporte ventas propietarios';
export const CARGAR_REPORTE_VENTAS_PROPIETARIOS_SUCCESS = '[Reportes] Cargar reporte ventas propietarios success';
export const CARGAR_REPORTE_VENTAS_PROPIETARIOS_FAIL = '[Reportes] Cargar reporte ventas propietarios fail';
export const LIMPIAR_REPORTE_VENTAS_PROPIETARIOS = '[Reportes] Limpiar reporte ventas propietarios';



export class CargarReporteCumpleannos implements Action {
  readonly type = CARGAR_REPORTE_CUMPLEANNOS;

  constructor( public fecha: Fechas, public opciones: any ) { }
}

export class CargarReporteCumpleannosFail implements Action {
  readonly type = CARGAR_REPORTE_CUMPLEANNOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteCumpleannosSuccess implements Action {
  readonly type = CARGAR_REPORTE_CUMPLEANNOS_SUCCESS;

  constructor( public contactos: Contacto[], public totalFiltro: number, public total: number ) {}
}

export class LimpiarReporteCumpleannos implements Action {
  readonly type = LIMPIAR_REPORTE_CUMPLEANNOS;

  constructor() {}
}

export class CargarReporteVentasEtapas implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_ETAPAS;

  constructor( public fecha: Fechas ) { }
}

export class CargarReporteVentasEtapasFail implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_ETAPAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteVentasEtapasSuccess implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_ETAPAS_SUCCESS;

  constructor( public oportunidades: Oportunidad[] ) {}
}

export class LimpiarReporteVentasEtapas implements Action {
  readonly type = LIMPIAR_REPORTE_VENTAS_ETAPAS;

  constructor() {}
}

export class CargarReporteVentasPropietarios implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_PROPIETARIOS;

  constructor( public fecha: Fechas ) { }
}

export class CargarReporteVentasPropietariosFail implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_PROPIETARIOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteVentasPropietariosSuccess implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_PROPIETARIOS_SUCCESS;

  constructor( public reporte: VentaPropietario[] ) {}
}

export class LimpiarReporteVentasPropietarios implements Action {
  readonly type = LIMPIAR_REPORTE_VENTAS_PROPIETARIOS;

  constructor() {}
}


export type reportes =  CargarReporteCumpleannos 
                        | CargarReporteCumpleannosFail 
                        | CargarReporteCumpleannosSuccess
                        | LimpiarReporteCumpleannos
                        | CargarReporteVentasEtapas 
                        | CargarReporteVentasEtapasFail 
                        | CargarReporteVentasEtapasSuccess
                        | LimpiarReporteVentasEtapas
                        | CargarReporteVentasPropietarios 
                        | CargarReporteVentasPropietariosFail 
                        | CargarReporteVentasPropietariosSuccess
                        | LimpiarReporteVentasPropietarios
												;
