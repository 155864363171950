<div class="h-100">
	<div class="row h-100 no-gutters">
		<div class="d-none d-lg-block col-lg-4">
			<div class="slider-light">
        <ngx-slick-carousel class="carousel" 
          #slickModal="slick-carousel" 
          [config]="slideConfig" 
          (init)="slickInit($event)"
          (breakpoint)="breakpoint($event)"
          (afterChange)="afterChange($event)"
          (beforeChange)="beforeChange($event)">
          <div ngxSlickItem *ngFor="let slide of slides" class="slide">
            <img src="{{ slide.img }}" alt="" width="100%">
          </div>
        </ngx-slick-carousel>
			</div>
		</div>
		<div class="h-100 d-flex bg-inicio justify-content-center align-items-center col-md-12 col-lg-8 loading-container">
			<div class="mx-auto app-login-box col-sm-12 col-md-6 col-lg-6 col-xl-5">
        <div>
          <div class="app-logo-login  mx-auto" >
            <!--   app-logo -->
            <img src="../../../../assets/images/login/logo.png" class="img-fluid" alt="Logotipo" >
          </div>
        </div>
        <div class="card pb-0 mb-2">
          <div class="bar-header">
            <img src="assets/images/banda.png" class="img-header">
          </div>
          <div class="card-body ">
            <h4 class="mb-0 text-center">
              <div>Bienvenido</div>
              <span>Por favor inicie sesión en su cuenta.</span>
            </h4>
            <div class="divider"></div>
            <div class="text-center">
              <form (ngSubmit)="onSubmit()" #f="ngForm">
                <div class="row">
                  <div class="col-12">
                    <fieldset class="form-group">
                      <div tabindex="-1" role="group">
                        <!-- <label for="exampleEmail">Usuario</label> -->
                        <div class="input-group">
                          <input type="text" id="exampleEmail" name="usuarioLogin" #usuarioLogin="ngModel" placeholder="Usuario" class="form-control" [(ngModel)]="usuario.usuario" required>
                            <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="fa fa-user"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </fieldset> 
                    <fieldset class="form-group">
                      <div tabindex="-1" role="group">
                        <!-- <label for="examplePassword">Contraseña</label> -->
                        <div class="input-group">
                          <input type="password" id="examplePassword" name="passwordLogin" #passwordLogin="ngModel" placeholder="Contraseña" class="form-control" [(ngModel)]="usuario.password" required>
                          <div class="input-group-append">
                            <span class="input-group-text">
                            <i class="fa fa-lock"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="form-group">
                      <div class="text-center">
                        <div style="transform:scale(1);transform-origin:0;">
                          <div #recaptcha style="display: inline-block;"></div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="form-group">
                      <div tabindex="-1" role="group">
                        <div class="input-group">
                          <button type="submit" class="btn-wide btn-shadow btn-hover-shine btn-pill btn btn-primary btn-block btn-lg" [disabled]="!f.valid || cargando">
                            Ingresar
                          </button>
                        </div>
                      </div>
                    </fieldset>
                   
                  </div>
                </div>
                <div class="custom-control custom-checkbox text-left">
                  <input id="exampleCheck" type="checkbox" name="check" #check="ngModel" autocomplete="off" class="custom-control-input" value="true" [(ngModel)]="rememberme">
                  <label for="exampleCheck" class="custom-control-label">Recordarme</label>
                </div>
                <div class="divider"></div>
                <div class="d-flex justify-content-end align-items-center">
                  <div class="ml-auto">
                    <a [routerLink]="'/public/restore'" class="btn-lg btn btn-link">Recuperar Contraseña</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ngx-loading [show]="cargando" [template]="loadingTemplate"></ngx-loading>
		</div>
	</div>
</div>