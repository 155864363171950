<div class="app-inner-layout">
  <div class="app-inner-layout__wrapper" [ngClass]="{'open-mobile-menu' : toggleMobileSidebar}">
    <div class="app-inner-layout__content card">
      <div class="card-header">
        <div class="mobile-app-menu-btn">
          <div class="card-header">
            <div class="card-header-title">Información de ticket</div>
          </div>
          <ul class="nav flex-column">
            <li class="nav-item-title nav-item text-left" style="text-transform: none; padding-top: .5em;">#{{ticket.id}} - {{ticket.asunto}}</li>
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item text-left" style="text-transform: none; padding: 0 1rem;">Usuario emisor</li>
            <li class="nav-item-title nav-item ml-3 text-left"  style="text-transform: none;">{{ticket.desUsuarioRegistro}}</li>
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item text-left" style="text-transform: none; padding: 0 1rem;">Departamento emisor</li>
            <li class="nav-item-title nav-item ml-3 text-left"  style="text-transform: none;">{{ticket.desDepartamentoEmisor}}</li>
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item text-left" style="text-transform: none; padding: 0 1rem;">Departamento receptor</li>
            <li class="nav-item-title nav-item ml-3 text-left"  style="text-transform: none;">{{ticket.desDepartamento}}</li>
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item text-left" style="text-transform: none; padding: 0 1rem;">Usuarios receptores</li>
            <li class="nav-item-title nav-item ml-3 text-left" *ngFor="let receptor of ticket.usuariosReceptores" style="text-transform: none;">{{receptor.nombre}}</li>
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item text-left" style="text-transform: none; padding: 0 1rem;">Fecha de creación</li>
            <li class="nav-item-title nav-item ml-3 text-left"  style="text-transform: none;">{{ticket.fecRegistro | amLocale: 'es'  | amDateFormat: 'LL'}}</li>
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item text-left" style="text-transform: none; padding: 0 1rem;">Última modificación</li>
            <li class="nav-item-title nav-item ml-3 text-left"  style="text-transform: none;">{{ticket.fecModificacion | amLocale: 'es'  | amTimeAgo}}</li>
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item text-left" style="text-transform: none; padding: 0 1rem;">Archivos adjuntos</li>
            <li class="nav-item-title nav-item ml-3 text-left">
              <ul *ngFor="let adjunto of ticket.adjuntos" class="p-0">
                <li class="d-inline mr-3" >
                  <a [href]="url + '/archivos/descargar/?path=' +  adjunto.urlPath" target="_blank">
                    <i class="fa fa-paperclip"></i> {{adjunto.name}}
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item text-left" style="text-transform: none; padding: 0 1rem;">Estatus

              <div class="badge badge-pill pull-right" [ngClass]="{'badge-success': ticket.usuarioRegistro === identity.id && ticket.existe, 
                                                                    'badge-primary': ticket.usuarioRegistro !== identity.id && ticket.existe,
                                                                    'badge-dark': !ticket.existe}" *ngIf="ticket.status === 1">Nuevo</div>
              <div class="badge badge-pill pull-right" [ngClass]="{'badge-success': ticket.usuarioRespondio === identity.id && ticket.existe, 
                                                                  'badge-warning': ticket.usuarioRegistro === identity.id && ticket.usuarioRespondio !== identity.id && ticket.existe, 
                                                                  'badge-primary': ticket.usuarioRegistro !== identity.id && ticket.usuarioRespondio !== identity.id && ticket.existe,
                                                                  'badge-dark': !ticket.existe}" *ngIf="ticket.status === 2">{{ (identity.id === ticket.usuarioRespondio ? 'Actualizado' : 'Respondido') }}</div>
              <div class="badge badge-pill pull-right badge-secondary" *ngIf="ticket.status === 3">Cerrado</div>
            </li>
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item">
              <div class="row">
                <button type="button"  type="button" (click)="editarEstado(ticket)" class="btn-icon btn-wide btn-outline-2x btn btn-outline-alternate btn-sm d-flex ml-3 mb-2" *ngIf="ticket.usuarioRegistro === identity.id && ticket.status !== 3">
                  <span class="pr-2 align-middle opacity-7">
                    <i class="fas fa-ban" [ngClass]="{'fa-trash-alt': ticket.status === 1 }"></i>
                  </span>
                  {{ (ticket.status === 1 ? 'Eliminar' : 'Cerrar') }}
                </button>
                <button type="button" *ngIf="ticket.usuarioRegistro === identity.id && ticket.status === 1" [routerLink]="['/auth/tickets/register', ticket.id]" [routerLinkActive]=""  class="btn-icon btn-wide btn-outline-2x btn btn-outline-primary btn-sm d-flex ml-3 mb-2">
                  <span class="pr-2 align-middle opacity-7">
                    <i class="fas fa-edit"></i>
                  </span>
                  Editar
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div class="card-header-title">Seguimiento</div>
      </div>
      <div class="card-body">
        <div class="pane-left" *ngIf="ticket.usuarioRegistro === identity.id || (ticket.existe && ticket.status !== 3)">
          <textarea class="form-control" placeholder="Escribe el mensaje aquí..." name="textSeguimientoTicket" [(ngModel)]="mensaje.mensaje"></textarea>
          <button aria-haspopup="true" class="btn btn-link p-0 mr-2 ml-3" type="button" (click)="agregarMensaje()">
            <span>
              <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                <div class="icon-wrapper-bg bg-success"></div>
                <i class="text-success fa fa-send-o"></i>
              </div>
            </span>
          </button>
        </div>
        <a (click)="fileAdjuntos.click()" class="pointer" appDragDropFile (fileDropped)="uploadAttachment($event)" *ngIf="ticket.usuarioRegistro === identity.id ||(ticket.existe && ticket.status !== 3)">Adjuntar archivos <i class="fa fa-paperclip"></i></a>
        <input type="file" hidden #fileAdjuntos multiple="true" class="form-control" (change)="uploadAttachment($event.target.files)">
        <ul class="list-group mt-2" *ngIf="ticket.usuarioRegistro === identity.id ||(ticket.existe && ticket.status !== 3)">
          <li class="list-group-item" *ngFor="let adj of mensaje.adjuntos; let i = index;">
            <i class="fa fa-paperclip mr-2"></i>
            {{adj.name}}
            <span class="pull-right text-danger" (click)="deleteAttachment(i)"><i class="fa fa-close"></i></span>
          </li>
        </ul>
        <hr *ngIf="ticket.usuarioRegistro === identity.id ||(ticket.existe && ticket.status !== 3)">
        <div class="row">
					<div class="ml-auto mr-2 mb-2">
            <button type="button"  type="button" (click)="actualizaMensaje()" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3">
              <span class="pr-2 align-middle opacity-7">
                <i class="fas fa-sync-alt"></i>
              </span>
              Actualizar
            </button>
					</div>
				</div>
        <div class="card card-top-border card-shadow-secondary border-secondary mb-2" *ngFor="let msj of ticket.mensajes" [ngClass]="{'card-shadow-success card border-success': msj.usuario === identity.id }">
          <div class="widget-content border-bottom pt-1 pb-1">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="font-icon-wrapper font-icon-lg border-0 mb-0">
                  <img width="50"  [src]="url + '/usuarios/perfiles/' + msj.usuario + '/imagenes'" alt="Imagen de perfil de usuario">
                </div>
              </div>
              <div class="widget-content-left">
                <div class="widget-heading text-secondary">{{msj.desUsuario}}</div>
                <div class="widget-subheading">{{msj.desDepartamento}}</div>
              </div>
              <div class="widget-content-right">
                <div class="widget-subheading">{{msj.fecRegistro | amLocale: 'es'  | amTimeAgo}}</div>
              </div>
            </div>
          </div>
          <div class="card-body">
            {{msj.mensaje}}
          </div>
          <div class="card-footer" *ngIf="msj.adjuntos.length">
            <ul class="m-0 pl-0">
              <li class="d-inline mr-3" *ngFor="let attch of msj.adjuntos">
                <a [href]="url + '/archivos/descargar/?path=' +  attch.urlPath" target="_blank">
                  <i class="fa fa-paperclip"></i> {{attch.name}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="app-inner-layout__sidebar card">
      <div class="card-header">
        <div class="card-header-title">Información de ticket</div>
      </div>
      <ul class="nav flex-column">
        <li class="nav-item-title nav-item ml-3" style="padding-top: .5em;">#{{ticket.id}} - {{ticket.asunto}}</li>
        <li class="nav-item-divider nav-item"></li>
        <li class="nav-item" style="padding: 0 1rem;">Usuario emisor</li>
        <li class="nav-item-title nav-item">{{ticket.desUsuarioRegistro}}</li>
        <li class="nav-item-divider nav-item"></li>
        <li class="nav-item" style="padding: 0 1rem;">Departamento emisor</li>
        <li class="nav-item-title nav-item">{{ticket.desDepartamentoEmisor}}</li>
        <li class="nav-item-divider nav-item"></li>
        <li class="nav-item" style="padding: 0 1rem;">Departamento receptor</li>
        <li class="nav-item-title nav-item">{{ticket.desDepartamento}}</li>
        <li class="nav-item-divider nav-item"></li>
        <li class="nav-item" style="padding: 0 1rem;">Usuarios receptores</li>
        <li class="nav-item-title nav-item" *ngFor="let receptor of ticket.usuariosReceptores" style="text-transform: none;">{{receptor.nombre}}</li>
        <li class="nav-item-divider nav-item"></li>
        <li class="nav-item" style="padding: 0 1rem;">Fecha de creación</li>
        <li class="nav-item-title nav-item">{{ticket.fecRegistro| amLocale: 'es'  | amDateFormat: 'LL'}}</li>
        <li class="nav-item-divider nav-item"></li>
        <li class="nav-item" style="padding: 0 1rem;">Última modificación</li>
        <li class="nav-item-title nav-item">{{ticket.fecModificacion| amLocale: 'es'  | amTimeAgo}}</li>
        <li class="nav-item-divider nav-item"></li>
        <li class="nav-item" style="padding: 0 1rem;">Archivos adjuntos</li>
        <li class="nav-item-title nav-item">
          <ul *ngFor="let adjunto of ticket.adjuntos">
            <li class="d-inline mr-3" >
              <a [href]="url + '/archivos/descargar/?path=' +  adjunto.urlPath" target="_blank">
                <i class="fa fa-paperclip"></i> {{adjunto.name}}
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item-divider nav-item"></li>
        <li class="nav-item" style="padding: 0 1rem;">Estatus
          <div class="badge badge-pill pull-right" [ngClass]="{'badge-success': ticket.usuarioRegistro === identity.id && ticket.existe, 
                                                        'badge-primary': ticket.usuarioRegistro !== identity.id && ticket.existe,
                                                        'badge-dark': !ticket.existe}" *ngIf="ticket.status === 1">Nuevo</div>
          <div class="badge badge-pill pull-right" [ngClass]="{'badge-success': ticket.usuarioRespondio === identity.id && ticket.existe, 
                                                        'badge-warning': ticket.usuarioRegistro === identity.id && ticket.usuarioRespondio !== identity.id && ticket.existe, 
                                                        'badge-primary': ticket.usuarioRegistro !== identity.id && ticket.usuarioRespondio !== identity.id && ticket.existe,
                                                        'badge-dark': !ticket.existe}" *ngIf="ticket.status === 2">{{ (identity.id === ticket.usuarioRespondio ? 'Actualizado' : 'Respondido') }}</div>
          <div class="badge badge-pill pull-right badge-secondary" *ngIf="ticket.status === 3">Cerrado</div>
        </li>
        <li class="nav-item-divider nav-item"></li>
        <li class="nav-item">
          <div class="row">
            <button type="button"  type="button" (click)="editarEstado(ticket)" class="btn-icon btn-wide btn-outline-2x btn btn-outline-alternate btn-sm d-flex ml-3 mb-2" *ngIf="ticket.usuarioRegistro === identity.id && ticket.status !== 3">
              <span class="pr-2 align-middle opacity-7">
                <i class="fas fa-ban" [ngClass]="{'fa-trash-alt': ticket.status === 1 }"></i>
              </span>
              {{ (ticket.status === 1 ? 'Eliminar' : 'Cerrar') }}
            </button>
            <button type="button" *ngIf="ticket.usuarioRegistro === identity.id && ticket.status === 1" [routerLink]="['/auth/tickets/register', ticket.id]" [routerLinkActive]=""  class="btn-icon btn-wide btn-outline-2x btn btn-outline-primary btn-sm d-flex ml-3 mb-2">
              <span class="pr-2 align-middle opacity-7">
                <i class="fas fa-edit"></i>
              </span>
              Editar
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>