export class Pagination {
	public page: number;
	public pageSize: number;
	public optionSize: OptionSizePage[];
	public sortType: string;
	public sortReverse: boolean;
	public textSearch: string;
	public isActive: boolean;
  public textSearchAnterior: string;
  public totalSize: number;
  public totalFiltro: number;


	constructor(
	  obj: ObjPagination
	) {
	  this.page = obj && obj.page ? obj.page : 1;
	  this.pageSize = obj && obj.pageSize ? obj.pageSize : 10;
	  this.optionSize = obj && obj.optionSize ? obj.optionSize : [
		 { value: 10, description: '10 elementos por hoja' },
		 { value: 20, description: '20 elementos por hoja' },
		 { value: 50, description: '50 elementos por hoja' },
		 { value: 100000, description: 'Todos' }
	  ];
	  this.sortType = obj && obj.sortType ? obj.sortType : '';
	  this.sortReverse = obj && obj.sortReverse ? obj.sortReverse : false;
	  this.textSearch = obj && obj.textSearch ? obj.textSearch : '';
	  this.isActive = obj && obj.isActive ? obj.isActive : false;
		this.textSearchAnterior = obj && obj.textSearchAnterior ? obj.textSearchAnterior : '';
		this.totalSize = obj && obj.totalSize ? obj.totalSize : 0;
    this.totalFiltro = obj && obj.totalFiltro ? obj.totalFiltro : 0;
	}
 }
 
 interface ObjPagination {
	page: number;
	pageSize: number;
	optionSize: OptionSizePage[];
	sortType: string;
	sortReverse: boolean;
	textSearch: string;
	isActive: boolean;
	textSearchAnterior?: string;
	totalSize?: number;
  totalFiltro?: number;
 }


 export class OptionSizePage {
	public value: number;
	public description: string;
	constructor(
	  obj: ObjOptionSizePage
	) {
	  this.value = obj && obj.value ? obj.value : 10;
	  this.description = obj && obj.description ? obj.description : '10 elementos por hoja';
	}
 }
 
 interface ObjOptionSizePage {
	value: number;
	description: string;
 }