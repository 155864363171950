import { Component, OnInit, OnDestroy } from '@angular/core';
import { IndicadoresService } from 'src/app/services/indicadores.service';
import { Pagination } from 'src/app/models/pagination';
import { LisIndicador } from 'src/app/models/indicador';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'; 
import { MESSAGES } from 'src/app/models/messages';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-list-indicadores',
  templateUrl: './list-indicadores.component.html',
  styleUrls: ['./list-indicadores.component.sass']
})
export class ListIndicadoresComponent implements OnInit, OnDestroy {
  heading = 'Detalles de los indicadores';
  subheading = 'Indicadores registrandas en el sistema';
  icon = ' pe-7s-graph icon-gradient bg-happy-fisher';
  public indicadores: LisIndicador[] = [];
  public paginacion = new Pagination(null);
  public identity: Identity;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionIndicadores: Subscription = new Subscription();
  public filterActive: boolean = false;
  public orderType = [{ id: '+id', text: 'ID'},
                      { id: '-id', text: 'ID desc'},
                      { id: '+nombre', text: 'Nombre'},
                      { id: '-nombre', text: 'Nombre desc'},
                      { id: '+dia', text: 'Fecha'},
                      { id: '-dia', text: 'Fecha desc'}];
  constructor(
    private indicadoresService: IndicadoresService,
    private toastrService: ToastrService,
    private store: Store<AppState>,
  ) {
    this.paginacion.sortType = '-id';
   }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
   this.subcriptionIndicadores = this.store.select('indicador').subscribe(
    (state) => {
        this.indicadores = [...state.indicadores];
      }
    );
    this.store.dispatch(new usuariosActions.CargarIndicadores());

  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe(); 
    this.subcriptionIndicadores.unsubscribe();
  }

  editarEstado(indicador: LisIndicador){
    if(indicador.estado){



      Swal.fire({
        title: '¿Deseas desactivar el indicador?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
        cancelButtonColor: MESSAGES.C_BTN_CANCEL,
        confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
        cancelButtonText: MESSAGES.M_BTN_CANCEL,
        focusConfirm: false,
        focusCancel: true,
        animation: true,
         showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
        showLoaderOnConfirm: true,
        preConfirm:  ()  => {
          return new Promise( (resolve)  => {
            this.indicadoresService.actualizaEstado(indicador.id).subscribe(
              (respuesta) => {
                if (respuesta.estado) {
                  this.store.dispatch(new usuariosActions.ActualizaIndicadorEstatusSuccess(indicador.id));
                  resolve(respuesta);
                } else {
                  Swal.showValidationMessage('Ocurrió un error en la operación');
                  resolve(false);
                }
              }, (error) => {
                this.store.dispatch(new usuariosActions.ActualizaIndicadorEstatusFail(error));
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            );
          });
        },
        allowOutsideClick: false
      }).then(
        (result: any) => {
          if (result.value) {
            Swal.fire({
              title: MESSAGES.M_TIT_CONFIRM,
              text: 'El indicador se encuntra desactivado.',
              icon: 'success'
            });
          } else {
            Swal.fire({
              title: MESSAGES.M_TIT_CANCELADO,
              timer: 1200,
              showConfirmButton: false,
              icon: 'error',
              onOpen: () =>  {}
            }).then(() => {}, () => {});
          }
        }, () => {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () => {}
          }).then( () => {},  () => {});
        }
      );



      // Swal.fire({
      //   title: '¿Deseas desactivar el indicador?',
      //   showCancelButton: true,
      //   confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      //   cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      //   confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      //   cancelButtonText: MESSAGES.M_BTN_CANCEL,
      //   focusConfirm: false,
      //   focusCancel: true,
      //   animation: true,
      //   showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      //   showLoaderOnConfirm: true,
      //   preConfirm:  ()  => {
      //     return new Promise( (resolve, reject)  => {
      //       this.indicadoresService.actualizaEstado(indicador.id).subscribe(
      //         (respuesta) => {
      //           if (respuesta) {
      //             this.store.dispatch(new usuariosActions.ActualizaIndicadorEstatusSuccess(indicador.id));
      //             resolve(respuesta);
      //           } else {
      //             reject(null);
      //           }
      //         }, (error) => {
      //           this.store.dispatch(new usuariosActions.ActualizaIndicadorEstatusFail(error));
      //           reject(error);
      //         }
      //       );
      //     });
      //   },
      //   allowOutsideClick: false
      // }).then(
      //   (result) => {
      //     if (result.value) {
      //       Swal.fire({
      //         title: MESSAGES.M_TIT_CONFIRM,
      //         text: 'El indicador se encuntra desactivado.',
      //         icon: 'success'
      //       });
      //     }
      //   }, () => {
      //     Swal.fire({
      //       title: MESSAGES.M_TIT_CANCELADO,
      //       timer: 1200,
      //       showConfirmButton: false,
      //       icon: 'error',
      //       onOpen: () => {}
      //     }).then( () => {},  () => {});
      //   }
      // );
    }else{
      this.indicadoresService.actualizaEstado(indicador.id).subscribe(
        (response) => {
          this.store.dispatch(new usuariosActions.ActualizaIndicadorEstatusSuccess(indicador.id));
          this.toastrService.success('El indicador se encuntra activo', 'Indicador Actualizado');
        }, (error) => {
          this.toastrService.error('Ocurrió un error.', 'Error');
        }
      );
    }
  }



  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchIndicador").focus();
  }

  closeSearch() {
    this.paginacion.isActive = false;
    this.paginacion.textSearch = '';
  }


}
