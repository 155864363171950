import { SortOrder } from './sort';

export class TipoServicio {
    public id: number;
    public nombre: string;
    public status: boolean;
    constructor(
      obj: ObjTipoServicio
    ) {
      this.id = obj && obj.id ? obj.id : 0;
      this.nombre = obj && obj.nombre ? obj.nombre : '';
      this.status = obj && obj.status ? obj.status : false;
    }
  }
  
  interface ObjTipoServicio {
    id: number;
    nombre: string;
    status: boolean;
  }


  export const TipoServicioSortType: SortOrder[] = [
    { id: '+id', text: 'ID'},
    { id: '-id', text: 'ID desc'},
    { id: '+nombre', text: 'Nombre'},
    { id: '-nombre', text: 'Nombre desc'}
    ];
  