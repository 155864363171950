import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { ActivatedRoute,Router } from '@angular/router';
import { Identity, UserType, User } from 'src/app/models/user';
import { GLOBAL } from 'src/app/models/global';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import * as usuariosActions from 'src/app/store/actions';
import { Subscription } from 'rxjs';
import { UsuarioState, AuthState, TiposState } from 'src/app/store/reducers';
import { Departamento } from 'src/app/models/departamento';
import { DepartamentosService } from 'src/app/services/departamentos.service';
import { ToastrService } from 'ngx-toastr';
import { Cliente } from 'src/app/models/clientes';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.sass']
})
export class EditComponent implements OnInit, OnDestroy {
  public title: string;
  public url: string;
  public identity: Identity;
  public user: User;
  public status: any;
  // public tipos: Catalogo[];
  public departamentos: Departamento[];
  // public divisiones:Division[];
  public area = [];
  public page: number;
  public pages: number;
  public nextPage: number;
  public prevPage: number;
  public areaSelected = null;
  public clienteSelected = null;
  public userId: number = 0;
  public userInicial: User;
  public tipos: UserType[];
  public cargando: boolean;
  public clientes: Cliente[] = [];
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionUsuario: Subscription = new Subscription();
  public subcriptionCatalogo: Subscription = new Subscription();
  public subcriptionConsulta: Subscription = new Subscription();
  heading = 'Detalle de usuario';
  subheading = 'Información de usuario registrado en el sistema';
  icon = 'pe-7s-user icon-gradient bg-happy-fisher';
  @ViewChild('usuarioUser', {static: false}) usuarioText: ElementRef;
  @ViewChild('nombreUser', {static: false}) nombreText: ElementRef;
  @ViewChild('apellidoPaternoUser', {static: false}) apellidoPaternoText: ElementRef;
  @ViewChild('apellidoMaternoUser', {static: false}) apellidoMaternoText: ElementRef;
  @ViewChild('emailUser', {static: false}) emailText: ElementRef;
  @ViewChild('passwordUser', {static: false}) contrasenaText: ElementRef;
  @ViewChild('passwordConfirmUser', {static: false}) confirmContrasenaText: ElementRef;

  constructor(
    private userService: UsersService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
	 private toastr: ToastrService,
   private _departamentosService : DepartamentosService,
   private router:Router,
  ) {
    this.url = GLOBAL.url;
    this.user = new User(null);
    this.userInicial = new User(null);
    this.tipos = [];
    this.title = 'Registrar usuario';
    this.cargando = false;
  }

  ngOnInit() {
    this.subcriptionUsuario = this.store.select('usuario').subscribe(
      (state: UsuarioState) => {
        this.user = {...this.user, ...state.usuario};
        this.userInicial = {...this.userInicial, ...state.usuario};
        this.cargando = state.loading;
      }
    );
    this.subcriptionConsulta = this.store.select('consultaCatalogos').subscribe(
      (state) => {
        this.departamentos = [...state.departamentos.filter(item => item.status)];
        this.clientes = [...state.clientes.filter(item => item.status)];

      }
    );
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
	 );
    this.subcriptionCatalogo = this.store.select('catalogos').subscribe(
      (state: TiposState) => {
        this.tipos = [...state.tipos];
      }
	 );
   this.inicializa();
	}

  ngOnDestroy() {
    this.subcriptionUsuario.unsubscribe();
    this.subcriptionCatalogo.unsubscribe();
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionConsulta.unsubscribe();
  }

  inicializa(){
    this.route.params.subscribe(params => {
      this.userId = params.id;
      if (this.userId > 0) {
		  this.store.dispatch(new usuariosActions.CargarUsuario(this.userId));
      } else {
        this.user = new User(null);
      }
    });
	 this.store.dispatch(new usuariosActions.LoadTypeUsers());
   this.store.dispatch(new usuariosActions.ConsultaCatalogoDepartamentos());
   this.store.dispatch(new usuariosActions.ConsultaCatalogoClientes());
  }

  onSubmit(form) {
    this.cargando = true;
    this.store.dispatch(new usuariosActions.EditarUsuario(this.user));
    this.cargando = false;
  }

  validaCampos() {
    return new Promise((resolve, reject) => {
      this.user.usuario = this.user.usuario.toLocaleLowerCase();
      if (!this.user.usuario) {
        this.usuarioText.nativeElement.focus().selected();
        reject('Capture nombre de usuario');
      } else {
        this.userService.existsUser(this.user.usuario).subscribe(
          (respuesta) => {
            if (respuesta.usuario && !this.user.id) {
              this.usuarioText.nativeElement.focus().selected();
              reject('El nombre usuario ya existe');
            } else if (!this.user.nombre) {
              this.nombreText.nativeElement.focus().selected();
              reject('Capture nombre');
            } else if (!this.user.apellidoPaterno) {
              this.apellidoPaternoText.nativeElement.focus().selected();
              reject('Capture apellido paterno');
            } else {
              this.userService.verificateEmail(this.user.id, this.user.email).subscribe(
                (respuestaEmail) => {
                  if (respuestaEmail.email) {
                    this.emailText.nativeElement.focus().selected();
                    reject('El correo electrónico ya existe');
                  } else if (!this.user.password && !this.user.id) {
                    this.contrasenaText.nativeElement.focus().selected();
                    reject('La contraseña no es valida');
                  } else if (this.user.password !== this.user.passwordConfirmar && !this.user.id) {
                    this.confirmContrasenaText.nativeElement.focus().selected();
                    reject('Contraseña no coincide');
                  } else if (!this.user.tipo) {
                    reject('Capture tipo de usuario');
                  } else {
                    resolve(true);
                  }
                }, (error) => {
                  reject('');
                }
              );
            }
          }, (error) => {
            reject('');
          }
        );
      }
    });
  }

  usuarioLower() {
    this.user.usuario = this.user.usuario.toLocaleLowerCase();
  }


	// getDivisiones(){
	// 	this.userService.getTypeDivision().subscribe(
	// 		Response =>{
	// 			if(!Response.divisiones){
	// 				this.status = 'error';
	// 			}else{
	// 				this.divisiones = Response.divisiones;
  //         console.log(this.divisiones);
	// 			}

	// 		},error =>{
	// 			var errorMensaje = <any>error;
	// 			console.log(errorMensaje);
	// 			if(errorMensaje != null){
	// 				 this.status = 'error';
	// 			}
	// 		});
	// }


	agregarDepartamento(){
      const itm = this.user.departamento.find( ite => ite.id === this.areaSelected);
      if (!itm) {
        const list = [...this.user.departamento];
        const departamento = this.departamentos.find( ite => ite.id === this.areaSelected);
        this.user.departamento = [...list, departamento];
      }
	}

	deleteArea(id){
    this.user.departamento = this.user.departamento.filter(item => item.id !== id);
	}

  agregarClientes(){
    const itm = this.user.clientes.find( ite => ite.id === this.clienteSelected);
    if (!itm) {
      const list = [...this.user.clientes];
      const clientes = this.clientes.find( ite => ite.id === this.clienteSelected);
      
      this.user.clientes = [...list,  { id: clientes.id, nombre: clientes.nombre, status: true}];
      console.log(this.user.clientes);
    }
}

deleteCliente(id){
  this.user.clientes = this.user.clientes.filter(item => item.id !== id);
}

}
