import { Action } from '@ngrx/store';
import { Catalogo } from 'src/app/models/catalogo';
import { Empresa } from 'src/app/models/empresa';
import { User } from 'src/app/models/user';
import { Contacto } from 'src/app/models/contacto';
import { Oportunidad } from 'src/app/models/oportunidades';
import { Origen } from 'src/app/models/origen-contacto';
import { Industria } from 'src/app/models/industrias';
import { UsoCfdi } from 'src/app/models/uso-cfdi';

export const CONSULTA_INDUSTRIAS = '[Consultas] Consulta industrias';
export const CONSULTA_INDUSTRIAS_SUCCESS = '[Consultas] Consulta industrias success';
export const CONSULTA_INDUSTRIAS_FAIL = '[Consultas] Consulta industrias fail';
export const CONSULTA_EMPRESAS = '[Consultas] Consulta empresas';
export const CONSULTA_EMPRESAS_SUCCESS = '[Consultas] Consulta empresas success';
export const CONSULTA_EMPRESAS_FAIL = '[Consultas] Consulta empresas fail';
export const CONSULTA_USUARIOS = '[Consultas] Consulta usuarios';
export const CONSULTA_USUARIOS_SUCCESS = '[Consultas] Consulta usuarios success';
export const CONSULTA_USUARIOS_FAIL = '[Consultas] Consulta usuarios fail';
export const CONSULTA_CONTACTOS_EMPRESA = '[Consultas] Consulta contactos empresa';
export const CONSULTA_CONTACTOS_EMPRESA_SUCCESS = '[Consultas] Consulta contactos empresa success';
export const CONSULTA_CONTACTOS_EMPRESA_FAIL = '[Consultas] Consulta contactos empresa fail';
export const CONSULTA_OPORTUNIDADES_EMPRESA = '[Consultas] Consulta oportunidades empresa';
export const CONSULTA_OPORTUNIDADES_EMPRESA_SUCCESS = '[Consultas] Consulta oportunidades empresa success';
export const CONSULTA_OPORTUNIDADES_EMPRESA_FAIL = '[Consultas] Consulta oportunidades empresa fail';
export const CONSULTA_CONTACTOS = '[Consultas] Consulta contactos';
export const CONSULTA_CONTACTOS_SUCCESS = '[Consultas] Consulta contactos success';
export const CONSULTA_CONTACTOS_FAIL = '[Consultas] Consulta contactos fail';
export const LIMPIAR_CONTACTOS_EMPRESA = '[Consultas] Limpiar contactos empresa';
export const CONSULTA_ETAPAS = '[Consultas] Consulta etapas';
export const CONSULTA_ETAPAS_SUCCESS = '[Consultas] Consulta etapas success';
export const CONSULTA_ETAPAS_FAIL = '[Consultas] Consulta etapas fail';
export const CONSULTA_PAISES = '[Consultas] Consulta paises';
export const CONSULTA_PAISES_SUCCESS = '[Consultas] Consulta paises success';
export const CONSULTA_PAISES_FAIL = '[Consultas] Consulta paises fail';
export const CONSULTA_CLASIFICACIONES = '[Consultas] Consulta clasificaciones';
export const CONSULTA_CLASIFICACIONES_SUCCESS = '[Consultas] Consulta clasificaciones success';
export const CONSULTA_CLASIFICACIONES_FAIL = '[Consultas] Consulta clasificaciones fail';
export const CONSULTA_ESTADOS_CONTACTOS = '[Consultas] Consulta estados contactos';
export const CONSULTA_ESTADOS_CONTACTOS_SUCCESS = '[Consultas] Consulta estados contactos success';
export const CONSULTA_ESTADOS_CONTACTOS_FAIL = '[Consultas] Consulta estados contactos fail';
export const CONSULTA_ETAPAS_CONTACTOS = '[Consultas] Consulta etapas contactos';
export const CONSULTA_ETAPAS_CONTACTOS_SUCCESS = '[Consultas] Consulta etapas contactos success';
export const CONSULTA_ETAPAS_CONTACTOS_FAIL = '[Consultas] Consulta etapas contactos fail';
export const CONSULTA_ORIGEN_CONTACTOS = '[Consultas] Consulta origen contactos';
export const CONSULTA_ORIGEN_CONTACTOS_SUCCESS = '[Consultas] Consulta origen contactos success';
export const CONSULTA_ORIGEN_CONTACTOS_FAIL = '[Consultas] Consulta origen contactos fail';
export const CONSULTA_TIPOS_ORIGEN = '[Consultas] Consulta tipos origen';
export const CONSULTA_TIPOS_ORIGEN_SUCCESS = '[Consultas] Consulta tipos origen success';
export const CONSULTA_TIPOS_ORIGEN_FAIL = '[Consultas] Consulta tipos origen fail';
export const CONSULTA_OPORTUNIDADES_CONTACTO = '[Consultas] Consulta oportunidades contacto';
export const CONSULTA_OPORTUNIDADES_CONTACTO_SUCCESS = '[Consultas] Consulta oportunidades contacto success';
export const CONSULTA_OPORTUNIDADES_CONTACTO_FAIL = '[Consultas] Consulta oportunidades contacto fail';
export const CONSULTA_CONTACTOS_OPORTUNIDAD = '[Consultas] Consulta contactos oportunidad';
export const CONSULTA_CONTACTOS_OPORTUNIDAD_SUCCESS = '[Consultas] Consulta contactos oportunidad success';
export const CONSULTA_CONTACTOS_OPORTUNIDAD_FAIL = '[Consultas] Consulta contactos oportunidad fail';
export const CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_EMPRESA = '[Consultas] Consulta oportunidades ganadas perdidas empresa';
export const CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_EMPRESA_SUCCESS = '[Consultas] Consulta oportunidades ganadas perdidas empresa success';
export const CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_EMPRESA_FAIL = '[Consultas] Consulta oportunidades ganadas perdidas empresa fail';
export const CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_CONTACTO = '[Consultas] Consulta oportunidades ganadas perdidas contacto';
export const CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_CONTACTO_SUCCESS = '[Consultas] Consulta oportunidades ganadas perdidas contacto success';
export const CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_CONTACTO_FAIL = '[Consultas] Consulta oportunidades ganadas perdidas contacto fail';
export const CONSULTA_USOS_CFDIS = '[Consultas] Consulta usos cfdis';
export const CONSULTA_USOS_CFDIS_SUCCESS = '[Consultas] Consulta usos cfdis SUCCESS';
export const CONSULTA_USOS_CFDIS_FAIL = '[Consultas] Consulta usos cfdis FAIL';


export class ConsultaIndustrias implements Action {
  readonly type = CONSULTA_INDUSTRIAS;
}

export class ConsultaIndustriasFail implements Action {
  readonly type = CONSULTA_INDUSTRIAS_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaIndustriasSuccess implements Action {
  readonly type = CONSULTA_INDUSTRIAS_SUCCESS;

  constructor(public industrias: Industria[]) {}
}

export class ConsultaEmpresas implements Action {
  readonly type = CONSULTA_EMPRESAS;

  constructor (public searchText: string = '', public limite: number = null) {}
}

export class ConsultaEmpresasFail implements Action {
  readonly type = CONSULTA_EMPRESAS_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaEmpresasSuccess implements Action {
  readonly type = CONSULTA_EMPRESAS_SUCCESS;

  constructor( public empresas: Empresa[] ) {}
}

export class ConsultaUsuarios implements Action {
  readonly type = CONSULTA_USUARIOS;
}

export class ConsultaUsuariosFail implements Action {
  readonly type = CONSULTA_USUARIOS_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaUsuariosSuccess implements Action {
  readonly type = CONSULTA_USUARIOS_SUCCESS;

  constructor( public usuarios: User[] ) {}
}

export class ConsultaContactosEmpresa implements Action {
  readonly type = CONSULTA_CONTACTOS_EMPRESA;

  constructor(public id: number) {}
}

export class ConsultaContactosEmpresaFail implements Action {
  readonly type = CONSULTA_CONTACTOS_EMPRESA_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaContactosEmpresaSuccess implements Action {
  readonly type = CONSULTA_CONTACTOS_EMPRESA_SUCCESS;

  constructor( public contactos: Catalogo[] ) {}
}

export class ConsultaOportunidadesEmpresa implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_EMPRESA;

  constructor(public id: number) {}
}

export class ConsultaOportunidadesEmpresaFail implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_EMPRESA_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaOportunidadesEmpresaSuccess implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_EMPRESA_SUCCESS;

  constructor( public oportunidades: Oportunidad[] ) {}
}

export class ConsultaContactos implements Action {
  readonly type = CONSULTA_CONTACTOS;
}

export class ConsultaContactosFail implements Action {
  readonly type = CONSULTA_CONTACTOS_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaContactosSuccess implements Action {
  readonly type = CONSULTA_CONTACTOS_SUCCESS;

  constructor( public contactos: Contacto[] ) {}
}

export class LimpiarContactosEmpresa implements Action {
  readonly type = LIMPIAR_CONTACTOS_EMPRESA;

  constructor() {}
}

export class ConsultaEtapas implements Action {
  readonly type = CONSULTA_ETAPAS;
}

export class ConsultaEtapasFail implements Action {
  readonly type = CONSULTA_ETAPAS_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaEtapasSuccess implements Action {
  readonly type = CONSULTA_ETAPAS_SUCCESS;

  constructor(public etapas: Catalogo[]) {}
}

export class ConsultaPaises implements Action {
  readonly type = CONSULTA_PAISES;
}

export class ConsultaPaisesFail implements Action {
  readonly type = CONSULTA_PAISES_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaPaisesSuccess implements Action {
  readonly type = CONSULTA_PAISES_SUCCESS;

  constructor(public paises: Catalogo[]) {}
}

export class ConsultaClasificaciones implements Action {
  readonly type = CONSULTA_CLASIFICACIONES;
}

export class ConsultaClasificacionesFail implements Action {
  readonly type = CONSULTA_CLASIFICACIONES_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaClasificacionesSuccess implements Action {
  readonly type = CONSULTA_CLASIFICACIONES_SUCCESS;

  constructor(public clasificaciones: Catalogo[]) {}
}

export class ConsultaEstadosContactos implements Action {
  readonly type = CONSULTA_ESTADOS_CONTACTOS;
}

export class ConsultaEstadosContactosFail implements Action {
  readonly type = CONSULTA_ESTADOS_CONTACTOS_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaEstadosContactosSuccess implements Action {
  readonly type = CONSULTA_ESTADOS_CONTACTOS_SUCCESS;

  constructor(public estados: Catalogo[]) {}
}

export class ConsultaEtapasContactos implements Action {
  readonly type = CONSULTA_ETAPAS_CONTACTOS;
}

export class ConsultaEtapasContactosFail implements Action {
  readonly type = CONSULTA_ETAPAS_CONTACTOS_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaEtapasContactosSuccess implements Action {
  readonly type = CONSULTA_ETAPAS_CONTACTOS_SUCCESS;

  constructor(public etapas: Catalogo[]) {}
}
export class ConsultaOrigenContactos implements Action {
  readonly type = CONSULTA_ORIGEN_CONTACTOS;
}
export class ConsultaOrigenContactosFail implements Action {
  readonly type = CONSULTA_ORIGEN_CONTACTOS_FAIL;

  constructor( public payload: any ) {}
}
export class ConsultaOrigenContactosSuccess implements Action {
  readonly type = CONSULTA_ORIGEN_CONTACTOS_SUCCESS;

  constructor(public origen: Origen[]) {}
}

export class ConsultaTiposOrigen implements Action {
  readonly type = CONSULTA_TIPOS_ORIGEN;
}
export class ConsultaTiposOrigenFail implements Action {
  readonly type = CONSULTA_TIPOS_ORIGEN_FAIL;

  constructor( public payload: any ) {}
}
export class ConsultaTiposOrigenSuccess implements Action {
  readonly type = CONSULTA_TIPOS_ORIGEN_SUCCESS;

  constructor(public tipo: Catalogo[]) {}
}

export class ConsultaOportunidadesContacto implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_CONTACTO;

  constructor(public id: number) {}
}

export class ConsultaOportunidadesContactoFail implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_CONTACTO_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaOportunidadesContactoSuccess implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_CONTACTO_SUCCESS;

  constructor( public oportunidades: Oportunidad[] ) {}
}

export class ConsultaContactosOportunidad implements Action {
  readonly type = CONSULTA_CONTACTOS_OPORTUNIDAD;

  constructor(public id: number) {}
}

export class ConsultaContactosOportunidadFail implements Action {
  readonly type = CONSULTA_CONTACTOS_OPORTUNIDAD_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaContactosOportunidadSuccess implements Action {
  readonly type = CONSULTA_CONTACTOS_OPORTUNIDAD_SUCCESS;

  constructor( public contactos: Contacto[] ) {}
}

export class ConsultaOportunidadesGanadasPerdidasEmpresa implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_EMPRESA;

  constructor(public id: number) {}
}

export class ConsultaOportunidadesGanadasPerdidasEmpresaFail implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_EMPRESA_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaOportunidadesGanadasPerdidasEmpresaSuccess implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_EMPRESA_SUCCESS;

  constructor( public ganados: number, public perdidos: number ) {}
}

export class ConsultaOportunidadesGanadasPerdidasContacto implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_CONTACTO;

  constructor(public id: number) {}
}

export class ConsultaOportunidadesGanadasPerdidasContactoFail implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_CONTACTO_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaOportunidadesGanadasPerdidasContactoSuccess implements Action {
  readonly type = CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_CONTACTO_SUCCESS;

  constructor( public ganados: number, public perdidos: number ) {}
}


export class ConsultaUsosCfdis implements Action {
  readonly type = CONSULTA_USOS_CFDIS;
}

export class ConsultaUsosCfdisFail implements Action {
  readonly type = CONSULTA_USOS_CFDIS_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaUsosCfdisSuccess implements Action {
  readonly type = CONSULTA_USOS_CFDIS_SUCCESS;

  constructor(public usosCfdis: UsoCfdi[]) {}
}

export type consultasAcciones = ConsultaIndustrias
                                | ConsultaIndustriasFail
                                | ConsultaIndustriasSuccess
                                | ConsultaEmpresas
                                | ConsultaEmpresasFail
                                | ConsultaEmpresasSuccess
                                | ConsultaUsuarios
                                | ConsultaUsuariosFail
                                | ConsultaUsuariosSuccess
                                | ConsultaContactosEmpresa
                                | ConsultaContactosEmpresaFail
                                | ConsultaContactosEmpresaSuccess
                                | ConsultaOportunidadesEmpresa
                                | ConsultaOportunidadesEmpresaFail
                                | ConsultaOportunidadesEmpresaSuccess
                                | ConsultaContactos
                                | ConsultaContactosFail
                                | ConsultaContactosSuccess
                                | LimpiarContactosEmpresa
                                | ConsultaEtapas
                                | ConsultaEtapasFail
                                | ConsultaEtapasSuccess
                                | ConsultaPaises
                                | ConsultaPaisesFail
                                | ConsultaPaisesSuccess
                                | ConsultaClasificaciones
                                | ConsultaClasificacionesFail
                                | ConsultaClasificacionesSuccess
                                | ConsultaEstadosContactos
                                | ConsultaEstadosContactosFail
                                | ConsultaEstadosContactosSuccess
                                | ConsultaEtapasContactos
                                | ConsultaEtapasContactosFail
                                | ConsultaEtapasContactosSuccess
                                | ConsultaOrigenContactos
                                | ConsultaOrigenContactosFail
                                | ConsultaOrigenContactosSuccess
                                | ConsultaTiposOrigen
                                | ConsultaTiposOrigenFail
                                | ConsultaTiposOrigenSuccess
                                | ConsultaOportunidadesContacto
                                | ConsultaOportunidadesContactoFail
                                | ConsultaOportunidadesContactoSuccess
                                | ConsultaContactosOportunidad
                                | ConsultaContactosOportunidadFail
                                | ConsultaContactosOportunidadSuccess
                                | ConsultaOportunidadesGanadasPerdidasEmpresa
                                | ConsultaOportunidadesGanadasPerdidasEmpresaFail
                                | ConsultaOportunidadesGanadasPerdidasEmpresaSuccess
                                | ConsultaOportunidadesGanadasPerdidasContacto
                                | ConsultaOportunidadesGanadasPerdidasContactoFail
                                | ConsultaOportunidadesGanadasPerdidasContactoSuccess
                                | ConsultaUsosCfdis
                                | ConsultaUsosCfdisFail
                                | ConsultaUsosCfdisSuccess
																;
