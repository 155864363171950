import { Action } from '@ngrx/store';
import { Dianolaboral } from 'src/app/models/dianolaboral';
import { Fechas } from 'src/app/models/fechas';


export const CARGAR_DIAS_NO_LABORABLES = '[Laboral] Cargar dias no laborables';
export const CARGAR_DIAS_NO_LABORABLES_FAIL = '[Laboral] Cargar dias no laborables FAIL';
export const CARGAR_DIAS_NO_LABORABLES_SUCCESS = '[Laboral] Cargar dias no laborables SUCCESS';
export const ELIMINA_DIAS_NO_LABORABLES = '[Laboral] Elimina dias no laborables';
export const ELIMINA_DIAS_NO_LABORABLES_FAIL = '[Laboral] Elimina dias no laborables FAIL';
export const ELIMINA_DIAS_NO_LABORABLES_SUCCESS = '[Laboral] Elimina dias no laborables SUCCESS';
export const ACTUALIZA_DIAS_NO_LABORABLES = '[Laboral] Actualiza dias no laborables';
export const ACTUALIZA_DIAS_NO_LABORABLES_FAIL = '[Laboral] Actualiza dias no laborables FAIL';
export const ACTUALIZA_DIAS_NO_LABORABLES_SUCCESS = '[Laboral] Actualiza dias no laborables SUCCESS';



export class CargarDiasNoLaborables implements Action {
    readonly type = CARGAR_DIAS_NO_LABORABLES;

    constructor( public fecha: Fechas ) {}
}
  
export class CargarDiasNoLaborablesFail implements Action {
  readonly type = CARGAR_DIAS_NO_LABORABLES_FAIL;
  
  constructor( public payload: any ) {}
}
  
export class CargarDiasNoLaborablesSuccess implements Action {
  readonly type = CARGAR_DIAS_NO_LABORABLES_SUCCESS;
  
  constructor( public dianolaboral: Dianolaboral[] ) {}
}

export class ActualizaDiaNoLaboral implements Action {
    readonly type = ACTUALIZA_DIAS_NO_LABORABLES;
  
    constructor( public dianolaboral: Dianolaboral) { }
}
  
export class ActualizaDiaNoLaboralFail implements Action {
    readonly type = ACTUALIZA_DIAS_NO_LABORABLES_FAIL;
  
    constructor( public payload: any ) {}
}
  
export class ActualizaDiaNoLaboralSuccess implements Action {
    readonly type = ACTUALIZA_DIAS_NO_LABORABLES_SUCCESS;
  
    constructor( public dianolaboral: Dianolaboral ) {}
}

export class EliminaDiaNoLaboral implements Action {
    readonly type = ELIMINA_DIAS_NO_LABORABLES;
  
    constructor( public id: number) { }
}
  
export class EliminaDiaNoLaboralFail implements Action {
    readonly type = ELIMINA_DIAS_NO_LABORABLES_FAIL;
  
    constructor( public payload: any ) {}
}
  
export class EliminaDiaNoLaboralSuccess implements Action {
    readonly type = ELIMINA_DIAS_NO_LABORABLES_SUCCESS;
  
    constructor( public id: number ) {}
}



export type diaNoLaboralAcciones = CargarDiasNoLaborables
                                | CargarDiasNoLaborablesFail
                                | CargarDiasNoLaborablesSuccess
                                | ActualizaDiaNoLaboral
                                | ActualizaDiaNoLaboralFail
                                | ActualizaDiaNoLaboralSuccess
                                | EliminaDiaNoLaboral
                                | EliminaDiaNoLaboralFail
                                | EliminaDiaNoLaboralSuccess;