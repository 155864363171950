import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { TipoSolicitudService } from 'src/app/services/tipo-solicitud.service';

@Injectable()
export class TipoSolicitudEffects {

  constructor(
    private actions$: Actions,
    public tipoSolicitudService: TipoSolicitudService
  ) {}

  @Effect()
  cargarTipoSolicitud$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_TIPO_SOLICITUDES ),
    switchMap( () => {
      return this.tipoSolicitudService.obtenerTipoSolicitudes()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarTipoSolicitudesSuccess(respuesta.solicitudes);
          }),
          catchError( error => of(new usuariosActions.CargarTipoSolicitudesFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusTipoSolicitud$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_TIPO_SOLICITUD_ESTATUS ),
    switchMap( (action: any) => {
      return this.tipoSolicitudService.actualizaEstatusTipoSolicitud(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaTipoSolicitudEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaTipoSolicitudEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaTipoSolicitud$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_TIPO_SOLICITUD ),
    switchMap( (action: any) => {
      return this.tipoSolicitudService.registraTipoSolicitud(action.solicitud)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaTipoSolicitudSuccess(action.solicitud);
            }),
            catchError( error => of(new usuariosActions.ActualizaTipoSolicitudFail(error))  )
        );
    })
  );

}
