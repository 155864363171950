import { Component, OnInit, OnDestroy } from '@angular/core';
import { Colores, ColorSortType } from 'src/app/models/colores';
import { ColoresService } from 'src/app/services/colores.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Pagination } from 'src/app/models/pagination';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import * as usuariosActions from 'src/app/store/actions';
import { MESSAGES } from 'src/app/models/messages';

@Component({
  selector: 'app-colores',
  templateUrl: './colores.component.html',
  styleUrls: ['./colores.component.sass']
})
export class ColoresComponent implements OnInit, OnDestroy {
  heading = 'Detalle de los colores';
  subheading = 'Colores registrandos en el sistema';
  icon = ' lnr-list icon-gradient bg-happy-fisher';
  public color: Colores;
  public colorInicial: Colores = new Colores(null);
  public colores: Colores[];
  public loading: boolean;
  public identity: Identity;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionColores: Subscription = new Subscription();
  public paginacion: Pagination = new Pagination(null);
  public filterActive: boolean = false;
  public orderType = [...ColorSortType];
  constructor(
    private colorService: ColoresService,
    private toastr: ToastrService,
    private store: Store<AppState>,
  ) { 
    this.paginacion.sortType = '+nombre';
    this.color = new Colores(null);
    this.colores = [];
    this.color.color = '#185888';
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionColores = this.store.select('colores').subscribe(
      (state) => {
        this.colores = [...state.colores];
      }
    );
    this.store.dispatch(new usuariosActions.CargarColores());
    console.log('Inicializa component colores...');
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionColores.unsubscribe();
  }


  onSubmit(form){
    Swal.fire({
      title: '¿Deseas registrar el color?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
       showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.colorService.registerColores(this.color).subscribe(
            (respuesta) => {
              if (respuesta.color) {
                this.store.dispatch(new usuariosActions.ActualizaColorSuccess(respuesta.color));
                resolve(respuesta.color);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaAreaFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result: any) => {
        if (result.value) {
          form.reset();
          this.color = new Colores(null);
          this.colorInicial = new Colores(null);
          this.color.color = '#185888';
          this.colorInicial.color = '#185888';
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Color registrado.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  editarColor(color : Colores){
    this.color  = {...color};
    this.colorInicial = {...color};
  }

  

  deleteColor(id){
    this.loading = true;
    Swal.fire({
      title: '¿Deseas eliminar el color?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
       showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.colorService.deleteColor(id).subscribe(
            (respuesta) => {
              if (respuesta.respond) {
                this.store.dispatch(new usuariosActions.EliminarColorSuccess(id));
                resolve(respuesta.respond);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaColorFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result: any) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Color eliminado.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
    this.loading = false;
  }

  limpiar(form){
    this.color = new Colores(null);
    this.color.color = '#185888';
    this.colorInicial = new Colores(null);
    this.colorInicial.color = '#185888';
    form.reset();
  }

 
  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

}
