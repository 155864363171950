<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" >
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card mb-3">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">  
        <div class="p-3">
          <div class="form-group">
            <label for="pageSizeVehiculo">Mostrar por hoja</label>
            <select class="custom-select" id="pageSizeVehiculo" name="pageSizeVehiculo" #pageSizeVehiculo="ngModel" [(ngModel)]="paginacion.pageSize">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="sortTypeVehiculos">Ordenar por</label>
            <select class="custom-select mb-2 mr-1" id="sortTypeVehiculos" name="sortTypeVehiculos" #sortTypeVehiculos="ngModel" [(ngModel)]="paginacion.sortType">
              <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
            </select>
          </div>
          <div class="form-group">
            <div class="menu-header-title font-size-md mt-2">Filtrar division</div>
            <ul class="todo-list-wrapper list-group list-group-flush">
              <li class="list-group-item" *ngFor="let item of division">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-2">
                      <div class="custom-checkbox custom-control">
                        <input type="checkbox" id="selectCheckDivisionFilter{{item.id}}" class="custom-control-input" name="selectCheckDivisionFilter{{item.id}}" #selectCheckDivisionFilter{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeDivision()">
                        <label class="custom-control-label" for="selectCheckDivisionFilter{{item.id}}">&nbsp;</label>
                      </div>
                    </div>
                    <div class="widget-content-left flex2">
                      <div class="widget-heading">{{item.nombre}}</div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9" [ngClass]="{'col-lg-12': !filterActive }">
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="btn-actions-pane-left">
          <div class="row mt-3">
            <div class="form-group pr-4">
              <input type="text" placeholder="Fecha" class="form-control d-none d-lg-block" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)">
              <input type="text" placeholder="Fecha" class="form-control d-lg-none" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)">
            </div>
            <div class="search-wrapper pr-3" [class.active]="paginacion.isActive" *ngIf="resultados.length > 0">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchAsignacion" id="searchAsignacion" #searchAsignacion="ngModel" [(ngModel)]="paginacion.textSearch" (blur)="textSearchChips()">
                <button class="search-icon" (click)="paginacion.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = ''; chips = [];"></button>
            </div>
          </div>
        </div>
        <div class="btn-actions-pane-right" *ngIf="resultados.length > 0">
          <span>
            ({{(resultados | anyScope: paginacion.textSearch | multiOpcionesSelected : { opciones: division, parametro: 'division'}).length}} de {{(resultados).length}})
          </span>
          <div class="btn-group">
            <button class="btn-wide btn-icon btn-icon-right btn-pill btn btn-lg" *ngIf="!filterActive" (click)="filterActive = true">
              Filtrar
              <i class="pe-7s-filter btn-icon-wrapper"> </i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-subtitle mt-2 ml-3" *ngIf="chips.length > 0">
        <mat-chip-list>
          <mat-chip  *ngFor="let chip of chips" [selectable]="selectable" [removable]="removable"> {{chip}}
            <mat-icon matChipRemove *ngIf="removable" (click)="remove(chip) ">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="divider mt-0 m-0" *ngIf="chips.length > 0"></div>
      <div class="card-body p-0">
        <div class="card-body text-center" *ngIf="resultados.length > 0">
          <h5  class="card-title">Resumen servicios iniciados en por servicio {{anio}}</h5>
          <div class="table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th class="text-center" (click)="sort('numeroOrden')"> # Orden
                    <span *ngIf="paginacion.sortType === '-numeroOrden'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+numeroOrden'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sort('desCliente')"> Cliente
                    <span *ngIf="paginacion.sortType === '-desCliente'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+desCliente'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sort('desServicio')"> Servicio
                    <span *ngIf="paginacion.sortType === '-desServicio'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+desServicio'" class="fa fa-caret-up"></span>  
                  </th>
                  <!-- <th class="text-center"> Localidad</th> -->
                  <th class="text-center" (click)="sort('desSucursal')"> Sucursal
                    <span *ngIf="paginacion.sortType === '-desSucursal'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+desSucursal'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sort('localidad')"> Estado
                    <span *ngIf="paginacion.sortType === '-localidad'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+localidad'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sort('desDivision')"> División
                    <span *ngIf="paginacion.sortType === '-desDivision'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+desDivision'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sort('elementos')"> No. Elementos
                    <span *ngIf="paginacion.sortType === '-elementos'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+elementos'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sort('turnos')"> TRpordía
                    <span *ngIf="paginacion.sortType === '-turnos'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+turnos'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sort('vacantes')"> Vacantes
                    <span *ngIf="paginacion.sortType === '-vacantes'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+vacantes'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sort('bajas')"> Bajas
                    <span *ngIf="paginacion.sortType === '-bajas'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+bajas'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sort('faltas')"> Total TNC's
                    <span *ngIf="paginacion.sortType === '-faltas'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+faltas'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sort('cop')"> %COP
                    <span *ngIf="paginacion.sortType === '-cop'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+cop'" class="fa fa-caret-up"></span>  
                  </th>
                </tr>
              </thead>
              <tbody *ngFor="let resultado of resultados | anyScope: paginacion.textSearch | multiOpcionesSelected : { opciones: division, parametro: 'division'} | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize">
                <tr>
                  <td >{{resultado.numeroOrden}}</td>
                  <td >{{resultado.desCliente}}</td>
                  <td >{{resultado.desServicio}}</td>
                  <!-- <td >{{resultado.elementos}}</td> -->
                  <td >{{resultado.desSucursal}}</td>
                  <td >{{resultado.localidad}}</td>
                  <td >{{resultado.desDivision}}</td>
                  <td >{{resultado.elementos}}</td>
                  <td >{{resultado.turnos}}</td>
                  <td >{{resultado.vacantes}}</td>
                  <td >{{resultado.bajas}}</td>
                  <td >{{resultado.faltas}}</td>
                  <td [ngClass]="{'bg-success': resultado.cop >= 98.99, 'bg-warning': resultado.cop <= 98.98 && resultado.cop >= 90.1, 'bg-danger': resultado.cop <= 90 && resultado.cop >= 76, 'bg-dark': resultado.cop <= 75.99, 'text-dark': resultado.cop <= 98.98 && resultado.cop >= 90.1, 'text-white': resultado.cop > 98.98 || resultado.cop < 90.1  }">{{resultado.cop}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="mt-2" *ngIf="resultados.length > 0">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="text-left">
              <ngb-pagination
                [collectionSize]="(resultados | anyScope: paginacion.textSearch | multiOpcionesSelected : { opciones: division, parametro: 'division'} ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="3">
                <ng-template ngbPaginationFirst>First</ng-template>
                <ng-template ngbPaginationLast>Last</ng-template>
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="pull-right">
              <button type="button" (click) = "crearExcel()" class="btn btn-primary" [disabled]="loading" *ngIf="identity.permisos.rptCobertura">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


