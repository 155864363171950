import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { CapturasService } from 'src/app/services/captura-asignacion.service';

@Injectable()
export class CapturasAsignacionEffects {

  constructor(
    private actions$: Actions,
    public capturasService: CapturasService
  ) {}

  @Effect()
  cargarReporteActividades$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_CAPTURA_ASIGNACION ),
    switchMap( (action: any) => {
      return this.capturasService.obtenerAsignacionCapturar(action.fecha, action.opciones)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarCapturasAsignacionSuccess(respuesta.capturas, respuesta.totalFiltro, respuesta.total);
            }),
            catchError( error => of(new usuariosActions.CargarCapturasAsignacionFail(error))  )
        );
    })
  );


  @Effect()
  actualizaResultado$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_CAPTURA_ASIGNACION ),
    switchMap( (action: any) => {
      return this.capturasService.actualizaAsignacionCapturar(action.id, action.captura)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaCapturaAsignacionSuccess(action.id, respuesta.resultado);
            }),
            catchError( error => of(new usuariosActions.ActualizaCapturaAsignacionFail(error))  )
        );
    })
  );


}
