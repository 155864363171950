import * as fromUsuario from '../actions';
import { KpisVendedores } from 'src/app/models/kpis-vendedores';
import { SeriesBar, FunnelGraphColors } from 'src/app/models/graph';
import { ActividadMultiple } from 'src/app/models/actividades';
import { Contacto } from 'src/app/models/contacto';
import { Empresa } from 'src/app/models/empresa';

export interface KPIsVendedoresState {
  series: SeriesBar[];
	categories: string[];
	actividades: ActividadMultiple[];
	empresas: Empresa[];
	contactos: Contacto[];
	oportunidadesAbiertas: KpisVendedores[];
  dataFunnel: FunnelGraphColors[];
  totalFiltroEmpresa: number;
  totalEmpresa: number;
  totalFiltroContacto: number;
  totalContacto: number;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: KPIsVendedoresState = {
  series: [],
	categories: [],
	actividades: [],
	empresas: [],
	contactos: [],
	oportunidadesAbiertas: [],
  dataFunnel: [],
  totalFiltroEmpresa: 0,
  totalEmpresa: 0,
  totalFiltroContacto: 0,
  totalContacto: 0,
  loaded: false,
  loading: false,
  error: null
};

export function kpisVendedoresReducer( state = initState, action: fromUsuario.kpisVendedoresAcciones ): KPIsVendedoresState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_ACTIVIDADES_MULTIPLES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_ACTIVIDADES_MULTIPLES_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				series: [...action.series],
				categories: [...action.categories]
      };
    case fromUsuario.CARGAR_ACTIVIDADES_MULTIPLES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
		case fromUsuario.CARGAR_ACTIVIDADES_MULTIPLES_PENDIENTES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_ACTIVIDADES_MULTIPLES_PENDIENTES_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				actividades: [...action.actividades]
      };
    case fromUsuario.CARGAR_ACTIVIDADES_MULTIPLES_PENDIENTES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
		case fromUsuario.CARGAR_EMPRESAS_REGISTRADAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_EMPRESAS_REGISTRADAS_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				empresas: [...action.empresas],
        totalFiltroEmpresa: action.totalFiltro,
        totalEmpresa: action.total,
      };
    case fromUsuario.CARGAR_EMPRESAS_REGISTRADAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
		case fromUsuario.CARGAR_CONTACTOS_REGISTRADAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_CONTACTOS_REGISTRADAS_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				contactos: [...action.contactos],
        totalFiltroContacto: action.totalFiltro,
        totalContacto: action.total
      };
    case fromUsuario.CARGAR_CONTACTOS_REGISTRADAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
		case fromUsuario.CARGAR_OPORTUNIDADES_ABIERTAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_OPORTUNIDADES_ABIERTAS_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				oportunidadesAbiertas: [...action.oportunidades]
      };
    case fromUsuario.CARGAR_OPORTUNIDADES_ABIERTAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.CARGAR_FUNNEL_OPORTUNIDADES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_FUNNEL_OPORTUNIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				dataFunnel: [...action.datas]
      };
    case fromUsuario.CARGAR_FUNNEL_OPORTUNIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    default:
      return state;
  }
}
