import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FilterType } from 'src/app/models/user';

@Component({
  selector: 'app-option-filter-collapse',
  templateUrl: './option-filter-collapse.component.html',
  styleUrls: ['./option-filter-collapse.component.scss']
})
export class OptionFilterCollapseComponent implements OnInit {
  @Input() lista: FilterType[] = [];
  @Input() titulo: string = 'Filtro';
  @Output() regresa: EventEmitter<FilterType[]> = new EventEmitter();
  textRandom = Math.random() * 1;

  constructor() { }

  ngOnInit(): void {
  }

  actualiza() {
    this.regresa.emit([...this.lista]);
  }

  checkedInput(item: FilterType){
    item.checked = !item.checked;
    this.actualiza();
  }

}
