import { SortOrder } from './sort';

export class Division {
	public id: number;
	public nombre: string;	
	public estatus: boolean;
	constructor(
		 obj: DataObjDivision
	) {
		this.id	=obj && obj.id ? obj.id: 0;
		this.nombre =obj && obj.nombre ? obj.nombre : '';
		this.estatus =obj && obj.estatus ? obj.estatus: true;
	}
}
interface DataObjDivision{
	id: number;
	nombre: string;
	estatus: boolean;
}


export const DivisionSortType: SortOrder[] = [
    { id: '+id', text: 'ID'},
    { id: '-id', text: 'ID desc'},
    { id: '+nombre', text: 'Nombre'},
    { id: '-nombre', text: 'Nombre desc'}
    ];
  