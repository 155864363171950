<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" >
  <div class="col-xl-12">
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="btn-actions-pane-left">
          <div class="row mt-3">
            <div class="form-group pr-4 ">
              <input type="text" placeholder="Fecha" class="form-control d-none d-lg-block" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)" [minDate]="minDate"
              [maxDate]="maxDate">
              <input type="text" placeholder="Fecha" class="form-control d-lg-none" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)" [minDate]="minDate"
              [maxDate]="maxDate">
            </div>
            <div class="row" *ngIf="departamentos.length > 0"  >
              <ng-select style="width: 20rem;" [items]="departamentos"
                bindLabel="nombre"
                bindValue="id"
                placeholder="Áreas"
                [(ngModel)]="filtro_area">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="btn-actions-pane-right row" *ngIf="reportes.length > 0">
          <button  printSectionId="print-section" ngxPrint #print [useExistingCss]="true" type="button" class="mr-2 btn-icon btn-wide btn-outline-2x btn btn-outline-focus ">
            <i class="fa fa-ellipsis-h btn-icon-wrapper " > </i>
            <i class="fa fa-file-pdf-o btn-icon-wrapper" > </i>
            PDF
          </button>
          <button  printSectionId="print2-section" ngxPrint #print [useExistingCss]="true" type="button" class="mr-2 btn-icon btn-wide btn-outline-2x btn btn-outline-focus ">
            <i class="fa fa-ellipsis-v btn-icon-wrapper " > </i>
            <i class="fa fa-file-pdf-o btn-icon-wrapper" > </i>
            PDF
          </button>
          <button (click)="exportAsXLSX()" type="button" class="mr-2 btn-icon btn-wide btn-outline-2x btn btn-outline-focus " *ngIf="excel.length > 0">
            <i class="fa fa-file-excel-o btn-icon-wrapper"></i>
            <span>Excel</span>
          </button>
        </div>
		  </div>
      <div class="table-responsive d-none d-md-block" *ngIf="reportes">
        <table class="align-middle mb-0 table table- table-striped table-hover table-bordered" style="page-break-inside:avoid; page-break-after:auto">
          <thead>
            <tr>
              <th class="text-center" (click)="sort('departamento')">Área
                <span *ngIf="paginacion.sortType === '-departamento'" class="fa fa-caret-down"></span>
                <span *ngIf="paginacion.sortType === '+departamento'" class="fa fa-caret-up"></span>  
              </th>
              <!-- <th class="text-center" (click)="sort('tipo')">Puesto
                <span *ngIf="paginacion.sortType === '-tipo'" class="fa fa-caret-down"></span>
                <span *ngIf="paginacion.sortType === '+tipo'" class="fa fa-caret-up"></span>
              </th> -->
              <th class="text-center" (click)="sort('nombre')">Nombre
                <span *ngIf="paginacion.sortType === '-nombre'" class="fa fa-caret-down"></span>
                <span *ngIf="paginacion.sortType === '+nombre'" class="fa fa-caret-up"></span>
              </th>
              <th class="text-center">Actividad</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor = "let reporte of reportes | filtroReporteActividad: {area: filtro_area, usuario: ''} | orderBy: [paginacion.sortType] ">
              <td class="text-left">
                <ul>
                  <li *ngFor="let area of reporte.departamento"> {{area.nombre}} </li>
                </ul>
				      </td>
					    <!-- <td class="text-left">{{reporte.tipo}}</td> -->
              <td class="text-left">{{reporte.nombre}}</td>
					    <td class="text-left">
                <ul>
                  <li *ngFor="let actividad of reporte.actividades ">[{{actividad.fec_registro | amDateFormat: 'DD/MM/YYYY HH:mm'}}] <span [innerHTML]="actividad.descripcion"></span>
                    <button   *ngFor="let anexo of actividad.anexo "  class="p-0 mt-2 pl-3 pr-3  btn-icon btn-icon-only btn btn-link" (click)="openLarge(content6)">
                      <i *ngIf="anexo.tipo != 'pdf'" class="fa fa-file-image-o btn-icon-wrapper"> </i>
                      <span *ngIf="anexo.tipo != 'pdf'" class="sr-only">Foto</span>
                      <i  *ngIf="anexo.tipo == 'pdf'" class="fa fa-file-pdf-o icon-gradient bg-love-kiss btn-icon-wrapper"> </i>
                      <span  *ngIf="anexo.tipo == 'pdf'" class="sr-only">PDF</span>
                      <ng-template #content6 let-c="close" let-d="dismiss">
                        <div class="modal-header">
                          <h4 class="modal-title">{{anexo.nombre + '.' + anexo.tipo}}</h4>
                          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body text-center">
                          <img *ngIf="anexo.tipo != 'pdf' " [src]="url + '/actividades/' + actividad.id + '/anexos/' + anexo.file" [alt]="anexo.nombre"  class="img-rounded" alt="Cinque Terre" width="700" height="600">
                          <ngx-extended-pdf-viewer *ngIf="anexo.tipo == 'pdf'"[src]="url + '/actividades/' + actividad.id + '/anexos/' + anexo.file" [useBrowserLocale]="false" height="80vh" ></ngx-extended-pdf-viewer>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cerrar</button>
                        </div>
                      </ng-template>
                    </button>
                  </li>
                </ul>
					    </td>
            </tr>
      	  </tbody>
		    </table>
      </div>
      <div class="d-md-none">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let reporte of reportes | filtroReporteActividad: {area: filtro_area, usuario: ''} | orderBy: [paginacion.sortType] ">
            <div class="no-gutters row">
              <div class="col-xl-12">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="widget-heading">{{reporte.nombre}}</div>
                        <div class="widget-subheading" *ngIf="reporte.departamento.length > 0">
                          Área:
                          <ul>
                            <li *ngFor="let area of reporte.departamento"> {{area.nombre}} </li>
                          </ul>
                        </div> 
                        <div class="widget-description text-muted"> 
                          Actividad:
                          <ul>
                            <li *ngFor="let actividad of reporte.actividades ">[{{actividad.fec_registro | amDateFormat: 'DD/MM/YYYY HH:mm'}}] <span [innerHTML]="actividad.descripcion"></span>
                              <button   *ngFor="let anexo of actividad.anexo "  class="p-0 mt-2 pl-3 pr-3  btn-icon btn-icon-only btn btn-link" (click)="openLarge(content6)">
                                <i *ngIf="anexo.tipo != 'pdf'" class="fa fa-file-image-o btn-icon-wrapper"> </i>
                                <span *ngIf="anexo.tipo != 'pdf'" class="sr-only">Foto</span>
                                <i  *ngIf="anexo.tipo == 'pdf'" class="fa fa-file-pdf-o icon-gradient bg-love-kiss btn-icon-wrapper"> </i>
                                <span  *ngIf="anexo.tipo == 'pdf'" class="sr-only">PDF</span>
                                <ng-template #content6 let-c="close" let-d="dismiss">
                                  <div class="modal-header">
                                    <h4 class="modal-title">{{anexo.nombre + '.' + anexo.tipo}}</h4>
                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body text-center">
                                    <img *ngIf="anexo.tipo != 'pdf' " [src]="url + '/actividades/' + actividad.id + '/anexos/' + anexo.file" [alt]="anexo.nombre"  class="img-rounded" alt="Cinque Terre" width="700" height="600">
                                    <ngx-extended-pdf-viewer *ngIf="anexo.tipo == 'pdf'"[src]="url + '/actividades/' + actividad.id + '/anexos/' + anexo.file" [useBrowserLocale]="false" height="80vh" ></ngx-extended-pdf-viewer>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cerrar</button>
                                  </div>
                                </ng-template>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-sm-6 col-md-2 col-xl-2">
                <td class="text-center">
                  <button type="button" class="btn btn-flat" [ngClass]="{'btn-secondary': !asignacion.estatus, 'btn-success': asignacion.estatus}"
                    title="Presione para activar/desactivar la sucursal." (click)="editarEstado(asignacion)">
                    {{ (asignacion.estatus ? 'Activo' : 'Desactivado') }}
                  </button> 
                </td>
              </div> -->
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>



<div class="table-responsive" style="padding-right: 1.5em; padding-left: 1.5em; position: absolute; right: -1;" id="print-section" [style.visibility]="'hidden'">
	<div style="padding-right: 1.5em; padding-left: 1.5em;">
    <table style="width: 100%;">
      <tr>
        <td style="width: 20%; text-align: center; padding: 20px; height: 100px;">
          <img src="assets/images/logo-pdf.png" style="height: 100px; width: auto;">
        </td>
        <td style="width: 80%; text-align: center;">
          <h5><b> Cumplimiento de captura de actividades</b></h5>
          <p >{{fechaIniPdf}} - {{fechaFinPdf}}</p>
        </td>
      </tr>
    </table>
    <table class="align-middle mb-0 table table- table-striped table-hover table-bordered">
      <thead>
        <tr>
          <th class="text-center">Área</th>
          <th class="text-center">Puesto</th>
          <th class="text-center">Nombre</th>
          <th class="text-center">Actividad</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor = "let reporte of reportes | filtroReporteActividad: {area: filtro_area, usuario: ''} | orderBy: [paginacion.sortType] ">
          <td class="text-left">
            <ul>
              <li *ngFor="let area of reporte.departamento"> {{area.nombre}} </li>
            </ul>
          </td>
          <td class="text-left">{{reporte.tipo}}</td>
          <td class="text-left">{{reporte.nombre}}</td>
          <td class="text-left">
            <ul>
              <li *ngFor="let actividad of reporte.actividades">[{{actividad.fec_registro | amDateFormat: 'DD/MM/YYYY HH:mm'}}] <span [innerHTML]="actividad.descripcion"></span> </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
	</div>
</div>


<div class="table-responsive" style="padding-right: 1.5em; padding-left: 1.5em; position: absolute; right: -1;" id="print2-section" [style.visibility]=" 'hidden'">
  <div style="padding-right: 1.5em; padding-left: 1.5em;">
    <table style="width: 100%;">
      <tr>
        <td style="width: 20%; text-align: center; padding: 20px; height: 100px;">
          <img src="assets/images/logo-pdf.png" style="height: 100px; width: auto;">
        </td>
        <td style="width: 80%; text-align: center;">
          <h5><b> Cumplimiento de captura de actividades</b></h5>
          <p >{{fechaIniPdf}} - {{fechaFinPdf}}</p>
        </td>
      </tr>
    </table>
		<table class="align-middle mb-0 table table- table-striped table-hover table-bordered">
		  <thead>
			  <tr>
          <th class="text-center">Área</th>
          <th class="text-center">Puesto</th>
          <th class="text-center">Nombre</th>
			  </tr>
		  </thead>
		  <tbody *ngFor = "let reporte of reportes | filtroReporteActividad: {area: filtro_area, usuario: ''} | orderBy: [paginacion.sortType]  ">
				<tr>
					<td class="text-left">
						<ul>
							<li *ngFor="let area of reporte.departamento"> {{area.nombre}} </li>
						</ul>
					</td>
					<td class="text-left">{{reporte.tipo}}</td>
					<td class="text-left">{{reporte.nombre}}</td>
				</tr>
				<tr>
					<td class="text-left" colspan="3">
						<ul>
							<li *ngFor="let actividad of reporte.actividades">[{{actividad.fec_registro | amDateFormat: 'DD/MM/YYYY HH:mm'}}] <span [innerHTML]="actividad.descripcion"></span> </li>
						</ul>
					</td>
				</tr>
		  </tbody>
		</table>
	</div>
</div>
