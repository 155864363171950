import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { CumplimientoService } from 'src/app/services/cumplimineto.service';

@Injectable()
export class CumplimientoEffects {

  constructor(
    private actions$: Actions,
    public cumplimientoService: CumplimientoService
  ) {}

  @Effect()
  cargarReporteActividades$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_ACTIVIDADES ),
    switchMap( (action: any) => {
      return this.cumplimientoService.reportes(action.fecha, )
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarReporteActividadesSuccess(respuesta.reporte, respuesta.excel, respuesta.areas);
            }),
            catchError( error => of(new usuariosActions.CargarReporteActividadesFail(error))  )
        );
    })
  );

  @Effect()
  cargarCumplimineto$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_CUMPLIMIENTO ),
    switchMap( (action: any) => {
      return this.cumplimientoService.cumplimiento(action.fecha, action.opciones )
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarCumplimientoSuccess(respuesta.cumplimiento, respuesta.labels, respuesta.totalFiltro, respuesta.total);
            }),
            catchError( error => of(new usuariosActions.CargarCumplimientoFail(error))  )
        );
    })
  );

  @Effect()
  actualizaCumplimineto$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZAR_CUMPLIMIENTO ),
    switchMap( (action: any) => {
      return this.cumplimientoService.updateUserCumplimiento(action.justificacion)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaCumplimientoSuccess(action.justificacion);
            }),
            catchError( error => of(new usuariosActions.ActualizaCumplimientoFail(error))  )
        );
    })
  );


}
