import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Identity, Permisos, UserType, Suscripciones } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';

@Component({
  selector: 'app-usuario-config-todos',
  templateUrl: './usuario-config-todos.component.html',
  styleUrls: ['./usuario-config-todos.component.scss']
})
export class UsuarioConfigTodosComponent implements OnInit, OnDestroy {
  public identity: Identity;
  private subscribeAuth: Subscription = new Subscription();
  public loading = false;
  public url: string;
  public suscripciones: Suscripciones[] = [];
  public todo = false;
  public todoSuscripciones = false;
  public filterPermisos: number = 1;

  @Input() permisos: Permisos;
  @Input() tipoSelected: UserType;
  @Output() messageEvent = new EventEmitter<Permisos>();
  constructor(
    private store: Store<AppState>,
  ) {
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;

    // filterPermisos = 1 TODOS
    // filterPermisos = 2 Consulta
    // filterPermisos = 3 Registros
    // filterPermisos = 4 Empresas
    // filterPermisos = 5 Oportunidades
    // filterPermisos = 6 Reportes 
    // filterPermisos = 7 Contactos
    // filterPermisos = 8 Tickets
    // filterPermisos = 9 Catálogos 
    // filterPermisos = 10 Indicadores
    // filterPermisos = 11 Asignaciones
    // filterPermisos = 12 Usuarios
    // filterPermisos = 13 Actividades
    // filterPermisos = 14 Control
   }

  ngOnInit(): void {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.filterSelecionar();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
  }

  sendMessage() {
    this.messageEvent.emit(this.permisos);
  }

  validarTodo() {
    if(this.filterPermisos !== 3 ){
      setTimeout( () => {
        // tslint:disable-next-line:forin
        for (const field in this.permisos) {
          if (field.search('Edit')) {
            if (this.permisos[field]) {
              const atributo = field.substr(0, field.length - 4) + 'Ver';
              if (atributo in this.permisos) {
                this.permisos[atributo] = true;
              }
            }
          }
        }
        const elements = Object.keys(this.permisos);
        this.todo = elements.every( (key) => this.permisos[key]);
      }, 1);
    }
    this.sendMessage();
  }

  validarTodoSuscripciones() {
    this.todoSuscripciones = this.suscripciones.every( (item) => item.checked);
  }


  seleccionar() {
    switch ( this.filterPermisos ) {
      case 1:
        this.seleccionarTodo();
      break;
      case 2:
        this.seleccionarConsulta();
      break;
      case 3:
        this.seleccionarRegistro();
      break;
      case 4:
        this.seleccionarEmpresa();
      break;
      case 5:
        this.seleccionarOportunidad();
      break;
      case 6:
        this.seleccionarReporte();
      break;
      case 7:
        this.seleccionarContacto();
      break;
      case 9:
        this.seleccionarCatalogo();
      break;
      case 10:
        this.seleccionarIndicador();
      break;
      case 11:
        this.seleccionarAsignacion();
      break;
      case 12:
        this.seleccionarUsuario();
      break;
      case 13:
        this.seleccionarActividad();
      break;
      case 14:
        this.seleccionarControl();
      break;
      case 15:
        this.seleccionarTicket();
      break;
    }
  }

  seleccionarTodo() {
    if (this.todo) {
      // tslint:disable-next-line:forin
      for (const field in this.permisos) {
        this.permisos[field] = true;
      }
    } else {
      // tslint:disable-next-line:forin
      for (const field in this.permisos) {
        this.permisos[field] = false;
      }
    }
  }

  seleccionarConsulta() {
    if (this.todo) {
      this.permisos.usuariosListVer = true;
      this.permisos.usuariosRegVer = true;
      this.permisos.empresasListVer = true;
      this.permisos.empresasRegVer = true;
      this.permisos.empresasDownload = true;
      this.permisos.contactosListVer = true;
      this.permisos.contactosRegVer = true;
      this.permisos.contactosDownload = true;
      this.permisos.oportunidadesListVer = true;
      this.permisos.oportunidadesRegVer = true;
      this.permisos.oportunidadesDownload = true;
      this.permisos.actividadesListEdit = true;
      this.permisos.actividadesListVer = true;
      this.permisos.actividadesRegEdit = true;
      this.permisos.actividadesRegVer = true;
      this.permisos.indicadorListVer = true;
      this.permisos.indicadorRegVer = true;
      this.permisos.asignacionListVer = true;
      this.permisos.asignacionRegVer = true;
      this.permisos.actividadesListVer = true;
      this.permisos.actividadesRegVer = true;
      this.permisos.catDepartamentosVer = true;
      this.permisos.catOrigenesVer = true;
      this.permisos.catIndustriasVer = true;
      this.permisos.catPaisesVer = true;
      this.permisos.catDivisionesVer = true;
      this.permisos.catSucursalesVer = true;
      this.permisos.catColoresVer = true;
      this.permisos.catServiciosVer = true;
      this.permisos.catClienteVer = true;
      this.permisos.catTipoServicioVer = true;
      this.permisos.rptKpiVendedores = true;
      this.permisos.rptCumpleannos = true;
      this.permisos.rptOportunidadEtapas = true;
      this.permisos.rptOportunidadPropietarios = true;
      this.permisos.rptCobertura = true;
      this.permisos.rptCoberturaDia = true;
      this.permisos.rptActividades = true;
      this.permisos.rptServicioCop = true;
      this.permisos.rptServicioInicia = true;
      this.permisos.rptServicioTermina = true;
      this.permisos.rptBajaVacantes = true;
      this.permisos.rptIndicador = true;
      this.permisos.rptIndicadorArea = true;
      this.permisos.rptIndicadorUsuario = true;
      this.permisos.seguimientoCumplimientoVer = true;
      this.permisos.vacacionesVer = true;
      this.permisos.actividadesMultipleVer = true;
      this.permisos.ticketsVer = true;
      
    } else {
      this.permisos.usuariosListVer = false;
      this.permisos.usuariosRegVer = false;
      this.permisos.empresasListVer = false;
      this.permisos.empresasRegVer = false;
      this.permisos.empresasDownload = false;
      this.permisos.contactosListVer = false;
      this.permisos.contactosRegVer = false;
      this.permisos.contactosDownload = false;
      this.permisos.oportunidadesListVer = false;
      this.permisos.oportunidadesRegVer = false;
      this.permisos.oportunidadesDownload = false;
      this.permisos.actividadesListEdit = false;
      this.permisos.actividadesListVer = false;
      this.permisos.actividadesRegEdit = false;
      this.permisos.actividadesRegVer = false;
      this.permisos.indicadorListVer = false;
      this.permisos.indicadorRegVer = false;
      this.permisos.asignacionListVer = false;
      this.permisos.asignacionRegVer = false;
      this.permisos.actividadesListVer = false;
      this.permisos.actividadesRegVer = false;
      this.permisos.catDepartamentosVer = false;
      this.permisos.catOrigenesVer = false;
      this.permisos.catIndustriasVer = false;
      this.permisos.catPaisesVer = false;
      this.permisos.catDivisionesVer = false;
      this.permisos.catSucursalesVer = false;
      this.permisos.catColoresVer = false;
      this.permisos.catServiciosVer = false;
      this.permisos.catClienteVer = false;
      this.permisos.catTipoServicioVer = false;
      this.permisos.rptKpiVendedores = false;
      this.permisos.rptCumpleannos = false;
      this.permisos.rptOportunidadEtapas = false;
      this.permisos.rptOportunidadPropietarios = false;
      this.permisos.rptCobertura = false;
      this.permisos.rptCoberturaDia = false;
      this.permisos.rptActividades = false;
      this.permisos.rptServicioCop = false;
      this.permisos.rptServicioInicia = false;
      this.permisos.rptServicioTermina = false;
      this.permisos.rptBajaVacantes = false;
      this.permisos.rptIndicador = false;
      this.permisos.rptIndicadorArea = false;
      this.permisos.rptIndicadorUsuario = false;
      this.permisos.seguimientoCumplimientoVer = false;
      this.permisos.vacacionesVer = false;
      this.permisos.actividadesMultipleVer = false;
      this.permisos.ticketsVer = false;
    }
  }


  seleccionarRegistro() {
    if (this.todo) {
      this.permisos.usuariosListEdit = true;
      this.permisos.usuariosRegEdit = true;
      this.permisos.empresasListEdit = true;
      this.permisos.empresasRegEdit = true;
      this.permisos.contactosListEdit = true;
      this.permisos.contactosRegEdit = true;
      this.permisos.oportunidadesListEdit = true;
      this.permisos.oportunidadesRegEdit = true;
      this.permisos.indicadorListEdit = true;
      this.permisos.indicadorRegEdit = true;
      this.permisos.indicadorCapturar = true;
      this.permisos.asignacionListEdit = true;
      this.permisos.asignacionRegEdit = true;
      this.permisos.asignacionCapturar = true;
      this.permisos.actividadesListEdit = true;
      this.permisos.actividadesRegEdit = true;
      this.permisos.catDepartamentosEdit = true;
      this.permisos.catOrigenesEdit = true;
      this.permisos.catIndustriasEdit = true;
      this.permisos.catPaisesEdit = true;
      this.permisos.catDivisionesEdit = true;
      this.permisos.catSucursalesEdit = true;
      this.permisos.catColoresEdit = true;
      this.permisos.catServiciosEdit = true;
      this.permisos.catClienteEdit = true;
      this.permisos.catTipoServicioEdit = true;
      this.permisos.seguimientoCumplimientoEdit = true;
      this.permisos.vacacionesEdit = true;
      this.permisos.actividadesMultipleEdit = true;
      this.permisos.ticketsEdit = true;
    } else {
      this.permisos.usuariosListEdit = false;
      this.permisos.usuariosRegEdit = false;
      this.permisos.empresasListEdit = false;
      this.permisos.empresasRegEdit = false;
      this.permisos.contactosListEdit = false;
      this.permisos.contactosRegEdit = false;
      this.permisos.oportunidadesListEdit = false;
      this.permisos.oportunidadesRegEdit = false;
      this.permisos.indicadorListEdit = false;
      this.permisos.indicadorRegEdit = false;
      this.permisos.indicadorCapturar = false;
      this.permisos.asignacionListEdit = false;
      this.permisos.asignacionRegEdit = false;
      this.permisos.asignacionCapturar = false;
      this.permisos.actividadesListEdit = false;
      this.permisos.actividadesRegEdit = false;
      this.permisos.catDepartamentosEdit = false;
      this.permisos.catOrigenesEdit = false;
      this.permisos.catIndustriasEdit = false;
      this.permisos.catPaisesEdit = false;
      this.permisos.catDivisionesEdit = false;
      this.permisos.catSucursalesEdit = false;
      this.permisos.catColoresEdit = false;
      this.permisos.catServiciosEdit = false;
      this.permisos.catClienteEdit = false;
      this.permisos.catTipoServicioEdit = false;
      this.permisos.seguimientoCumplimientoEdit = false;
      this.permisos.vacacionesEdit = false;
      this.permisos.actividadesMultipleEdit = false;
      this.permisos.ticketsEdit = false;
    }
  }

  seleccionarEmpresa() {
    if (this.todo) {
      this.permisos.empresasListEdit = true;
      this.permisos.empresasListVer = true;
      this.permisos.empresasRegEdit = true;
      this.permisos.empresasRegVer = true;
      this.permisos.empresasDownload = true;
      this.permisos.actividadesMultipleEdit = true;
      this.permisos.actividadesMultipleVer = true;
    } else {
      this.permisos.empresasListEdit = false;
      this.permisos.empresasListVer = false;
      this.permisos.empresasRegEdit = false;
      this.permisos.empresasRegVer = false;
      this.permisos.empresasDownload = false;
      this.permisos.actividadesMultipleEdit = false;
      this.permisos.actividadesMultipleVer = false;
    }
  }

  seleccionarOportunidad() {
    if (this.todo) {
      this.permisos.oportunidadesListEdit = true;
      this.permisos.oportunidadesListVer = true;
      this.permisos.oportunidadesRegEdit = true;
      this.permisos.oportunidadesRegVer = true;
      this.permisos.oportunidadesDownload = true;
      this.permisos.actividadesMultipleEdit = true;
      this.permisos.actividadesMultipleVer = true;
    } else {
      this.permisos.oportunidadesListEdit = false;
      this.permisos.oportunidadesListVer = false;
      this.permisos.oportunidadesRegEdit = false;
      this.permisos.oportunidadesRegVer = false;
      this.permisos.oportunidadesDownload = false;
      this.permisos.actividadesMultipleEdit = false;
      this.permisos.actividadesMultipleVer = false;
    }
  }

  seleccionarReporte() {
    if (this.todo) {
      this.permisos.rptKpiVendedores = true;
      this.permisos.rptCumpleannos = true;
      this.permisos.rptOportunidadEtapas = true;
      this.permisos.rptOportunidadPropietarios = true;
      this.permisos.rptCobertura = true;
      this.permisos.rptCoberturaDia = true;
      this.permisos.rptActividades = true;
      this.permisos.rptServicioCop = true;
      this.permisos.rptServicioInicia = true;
      this.permisos.rptServicioTermina = true;
      this.permisos.rptBajaVacantes = true;
      this.permisos.rptIndicador = true;
      this.permisos.rptIndicadorArea = true;
      this.permisos.rptIndicadorUsuario = true;
    } else {
      this.permisos.rptKpiVendedores = false;
      this.permisos.rptCumpleannos = false;
      this.permisos.rptOportunidadEtapas = false;
      this.permisos.rptOportunidadPropietarios = false;
      this.permisos.rptCobertura = false;
      this.permisos.rptCoberturaDia = false;
      this.permisos.rptActividades = false;
      this.permisos.rptServicioCop = false;
      this.permisos.rptServicioInicia = false;
      this.permisos.rptServicioTermina = false;
      this.permisos.rptBajaVacantes = false;
      this.permisos.rptIndicador = false;
      this.permisos.rptIndicadorArea = false;
      this.permisos.rptIndicadorUsuario = false;
    }
  }

  seleccionarContacto() {
    if (this.todo) {
      this.permisos.contactosListEdit = true;
      this.permisos.contactosListVer = true;
      this.permisos.contactosRegEdit = true;
      this.permisos.contactosRegVer = true;
      this.permisos.contactosDownload = true;
      this.permisos.actividadesMultipleEdit = true;
      this.permisos.actividadesMultipleVer = true;
    } else {
      this.permisos.contactosListEdit = false;
      this.permisos.contactosListVer = false;
      this.permisos.contactosRegEdit = false;
      this.permisos.contactosRegVer = false;
      this.permisos.contactosDownload = false;
      this.permisos.actividadesMultipleEdit = false;
      this.permisos.actividadesMultipleVer = false;
    }
  }

  seleccionarCatalogo() {
    if (this.todo) {
      this.permisos.catDepartamentosEdit = true;
      this.permisos.catDepartamentosVer = true;
      this.permisos.catOrigenesEdit = true;
      this.permisos.catOrigenesVer = true;
      this.permisos.catIndustriasEdit = true;
      this.permisos.catIndustriasVer = true;
      this.permisos.catPaisesEdit = true;
      this.permisos.catPaisesVer = true;
      this.permisos.catDivisionesEdit = true;
      this.permisos.catDivisionesVer = true;
      this.permisos.catSucursalesEdit = true;
      this.permisos.catSucursalesVer = true;
      this.permisos.catColoresEdit = true;
      this.permisos.catColoresVer = true;
      this.permisos.catServiciosEdit = true;
      this.permisos.catServiciosVer = true;
      this.permisos.catClienteEdit = true;
      this.permisos.catClienteVer = true;
      this.permisos.catTipoServicioEdit = true;
      this.permisos.catTipoServicioVer = true;
    } else {
      this.permisos.catDepartamentosEdit = false;
      this.permisos.catDepartamentosVer = false;
      this.permisos.catOrigenesEdit = false;
      this.permisos.catOrigenesVer = false;
      this.permisos.catIndustriasEdit = false;
      this.permisos.catIndustriasVer = false;
      this.permisos.catPaisesEdit = false;
      this.permisos.catPaisesVer = false;
      this.permisos.catDivisionesEdit = false;
      this.permisos.catDivisionesVer = false;
      this.permisos.catSucursalesEdit = false;
      this.permisos.catSucursalesVer = false;
      this.permisos.catColoresEdit = false;
      this.permisos.catColoresVer = false;
      this.permisos.catServiciosEdit = false;
      this.permisos.catServiciosVer = false;
      this.permisos.catClienteEdit = false;
      this.permisos.catClienteVer = false;
      this.permisos.catTipoServicioEdit = false;
      this.permisos.catTipoServicioVer = false;
    }
  }

  seleccionarIndicador() {
    if (this.todo) {
      this.permisos.indicadorListEdit = true;
      this.permisos.indicadorListVer = true;
      this.permisos.indicadorRegEdit = true;
      this.permisos.indicadorRegVer = true;
      this.permisos.indicadorCapturar = true;
    } else {
      this.permisos.indicadorListEdit = false;
      this.permisos.indicadorListVer = false;
      this.permisos.indicadorRegEdit = false;
      this.permisos.indicadorRegVer = false;
      this.permisos.indicadorCapturar = false;
    }
  }

  seleccionarAsignacion() {
    if (this.todo) {
      this.permisos.asignacionListEdit = true;
      this.permisos.asignacionListVer = true;
      this.permisos.asignacionRegEdit = true;
      this.permisos.asignacionRegVer = true;
      this.permisos.asignacionCapturar = true;
    } else {
      this.permisos.asignacionListEdit = false;
      this.permisos.asignacionListVer = false;
      this.permisos.asignacionRegEdit = false;
      this.permisos.asignacionRegVer = false;
      this.permisos.asignacionCapturar = false;
    }
  }

  seleccionarUsuario() {
    if (this.todo) {
      this.permisos.usuariosListEdit = true;
      this.permisos.usuariosListVer = true;
      this.permisos.usuariosRegEdit = true;
      this.permisos.usuariosRegVer = true;
    } else {
      this.permisos.usuariosListEdit = false;
      this.permisos.usuariosListVer = false;
      this.permisos.usuariosRegEdit = false;
      this.permisos.usuariosRegVer = false;
    }
  }

  seleccionarActividad() {
    if (this.todo) {
      this.permisos.actividadesListEdit = true;
      this.permisos.actividadesListVer = true;
      this.permisos.actividadesRegEdit = true;
      this.permisos.actividadesRegVer = true;
    } else {
      this.permisos.actividadesListEdit = false;
      this.permisos.actividadesListVer = false;
      this.permisos.actividadesRegEdit = false;
      this.permisos.actividadesRegVer = false;
    }
  }

  seleccionarControl() {
    if (this.todo) {
      this.permisos.seguimientoCumplimientoEdit = true;
      this.permisos.seguimientoCumplimientoVer = true;
      this.permisos.vacacionesEdit = true;
      this.permisos.vacacionesVer = true;
    } else {
      this.permisos.seguimientoCumplimientoEdit = false;
      this.permisos.seguimientoCumplimientoVer = false;
      this.permisos.vacacionesEdit = false;
      this.permisos.vacacionesVer = false;
    }
  }

  seleccionarTicket() {
    if (this.todo) {
      this.permisos.ticketsEdit = true;
      this.permisos.ticketsVer = true;
    } else {
      this.permisos.ticketsEdit = false;
      this.permisos.ticketsVer = false;
    }
  }



  filterSelecionar(){
    switch ( this.filterPermisos ) {
      case 1:
        this.filterSeleccionarTodo();
      break;
      case 2:
        this.filterSeleccionarConsulta();
      break;
      case 3:
        this.filterSeleccionarRegistro();
      break;
      case 4:
        this.filterSeleccionarEmpresa();
      break;
      case 5:
        this.filterSeleccionarOportunidad();
      break;
      case 6:
        this.filterSeleccionarReporte();
      break;
      case 7:
        this.filterSeleccionarContacto();
      break;
      case 9:
        this.filterSeleccionarCatalogo();
      break;
      case 10:
        this.filterSeleccionarIndicador();
      break;
      case 11:
        this.filterSeleccionarAsignacion();
      break;
      case 12:
        this.filterSeleccionarUsuario();
      break;
      case 13:
        this.filterSeleccionarActividad();
      break;
      case 14:
        this.filterSeleccionarControl();
      break;
      case 15:
        this.filterSeleccionarTicket();
      break;
    }
  }


  filterSeleccionarTodo() {
    const elements = Object.keys(this.permisos);
    this.todo = elements.every( (key) => this.permisos[key]);
    // let valor = true;
    // for (const field in this.permisos) {
    //   if(!this.permisos[field]){
    //     valor = false;
    //   }
    // }

    // if ( valor ) {
    //   this.todo = true;
    // } else {
    //   this.todo = false;
    // }
  }

  filterSeleccionarConsulta() {
    if (  this.permisos.usuariosListVer &&
          this.permisos.usuariosRegVer &&
          this.permisos.empresasListVer &&
          this.permisos.empresasRegVer &&
          this.permisos.empresasDownload &&
          this.permisos.contactosListVer &&
          this.permisos.contactosRegVer &&
          this.permisos.contactosDownload &&
          this.permisos.oportunidadesListVer &&
          this.permisos.oportunidadesRegVer &&
          this.permisos.oportunidadesDownload &&
          this.permisos.actividadesListEdit &&
          this.permisos.actividadesListVer &&
          this.permisos.actividadesRegEdit &&
          this.permisos.actividadesRegVer &&
          this.permisos.indicadorListVer &&
          this.permisos.indicadorRegVer &&
          this.permisos.asignacionListVer &&
          this.permisos.asignacionRegVer &&
          this.permisos.actividadesListVer &&
          this.permisos.actividadesRegVer &&
          this.permisos.catDepartamentosVer &&
          this.permisos.catOrigenesVer &&
          this.permisos.catIndustriasVer &&
          this.permisos.catPaisesVer &&
          this.permisos.catDivisionesVer &&
          this.permisos.catSucursalesVer &&
          this.permisos.catColoresVer &&
          this.permisos.catServiciosVer &&
          this.permisos.catClienteVer &&
          this.permisos.catTipoServicioVer &&
          this.permisos.rptKpiVendedores &&
          this.permisos.rptCumpleannos &&
          this.permisos.rptOportunidadEtapas &&
          this.permisos.rptOportunidadPropietarios &&
          this.permisos.rptCobertura &&
          this.permisos.rptCoberturaDia &&
          this.permisos.rptActividades &&
          this.permisos.rptServicioCop &&
          this.permisos.rptServicioInicia &&
          this.permisos.rptServicioTermina &&
          this.permisos.rptBajaVacantes &&
          this.permisos.rptIndicador &&
          this.permisos.rptIndicadorArea &&
          this.permisos.rptIndicadorUsuario &&
          this.permisos.seguimientoCumplimientoVer &&
          this.permisos.vacacionesVer &&
          this.permisos.actividadesMultipleVer &&
          this.permisos.ticketsVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }


  filterSeleccionarRegistro() {
    if (  this.permisos.usuariosListEdit &&
          this.permisos.usuariosRegEdit &&
          this.permisos.empresasListEdit &&
          this.permisos.empresasRegEdit &&
          this.permisos.contactosListEdit &&
          this.permisos.contactosRegEdit &&
          this.permisos.oportunidadesListEdit &&
          this.permisos.oportunidadesRegEdit &&
          this.permisos.indicadorListEdit &&
          this.permisos.indicadorRegEdit &&
          this.permisos.indicadorCapturar &&
          this.permisos.asignacionListEdit &&
          this.permisos.asignacionRegEdit &&
          this.permisos.asignacionCapturar &&
          this.permisos.actividadesListEdit &&
          this.permisos.actividadesRegEdit &&
          this.permisos.catDepartamentosEdit &&
          this.permisos.catOrigenesEdit &&
          this.permisos.catIndustriasEdit &&
          this.permisos.catPaisesEdit &&
          this.permisos.catDivisionesEdit &&
          this.permisos.catSucursalesEdit &&
          this.permisos.catColoresEdit &&
          this.permisos.catServiciosEdit &&
          this.permisos.catClienteEdit &&
          this.permisos.catTipoServicioEdit &&
          this.permisos.seguimientoCumplimientoEdit &&
          this.permisos.vacacionesEdit &&
          this.permisos.actividadesMultipleEdit &&
          this.permisos.ticketsEdit ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarEmpresa() {
    if (  this.permisos.empresasListEdit &&
          this.permisos.empresasListVer &&
          this.permisos.empresasRegEdit &&
          this.permisos.empresasRegVer &&
          this.permisos.empresasDownload &&
          this.permisos.actividadesMultipleEdit &&
          this.permisos.actividadesMultipleVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarOportunidad() {
    if (  this.permisos.oportunidadesListEdit &&
          this.permisos.oportunidadesListVer &&
          this.permisos.oportunidadesRegEdit &&
          this.permisos.oportunidadesRegVer &&
          this.permisos.oportunidadesDownload &&
          this.permisos.actividadesMultipleEdit &&
          this.permisos.actividadesMultipleVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarReporte() {
    if (  this.permisos.rptKpiVendedores &&
          this.permisos.rptCumpleannos &&
          this.permisos.rptOportunidadEtapas &&
          this.permisos.rptOportunidadPropietarios &&
          this.permisos.rptCobertura &&
          this.permisos.rptCoberturaDia &&
          this.permisos.rptActividades &&
          this.permisos.rptServicioCop &&
          this.permisos.rptServicioInicia &&
          this.permisos.rptServicioTermina &&
          this.permisos.rptBajaVacantes &&
          this.permisos.rptIndicador &&
          this.permisos.rptIndicadorArea &&
          this.permisos.rptIndicadorUsuario ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarContacto() {
    if (  this.permisos.contactosListEdit &&
          this.permisos.contactosListVer &&
          this.permisos.contactosRegEdit &&
          this.permisos.contactosRegVer &&
          this.permisos.contactosDownload &&
          this.permisos.actividadesMultipleEdit &&
          this.permisos.actividadesMultipleVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarCatalogo() {
    if (  this.permisos.catDepartamentosEdit &&
          this.permisos.catDepartamentosVer &&
          this.permisos.catOrigenesEdit &&
          this.permisos.catOrigenesVer &&
          this.permisos.catIndustriasEdit &&
          this.permisos.catIndustriasVer &&
          this.permisos.catPaisesEdit &&
          this.permisos.catPaisesVer &&
          this.permisos.catDivisionesEdit &&
          this.permisos.catDivisionesVer &&
          this.permisos.catSucursalesEdit &&
          this.permisos.catSucursalesVer &&
          this.permisos.catColoresEdit &&
          this.permisos.catColoresVer &&
          this.permisos.catServiciosEdit &&
          this.permisos.catServiciosVer &&
          this.permisos.catClienteEdit &&
          this.permisos.catClienteVer &&
          this.permisos.catTipoServicioEdit &&
          this.permisos.catTipoServicioVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarIndicador() {
    if (  this.permisos.indicadorListEdit &&
          this.permisos.indicadorListVer &&
          this.permisos.indicadorRegEdit &&
          this.permisos.indicadorRegVer &&
          this.permisos.indicadorCapturar ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarAsignacion() {
    if (  this.permisos.asignacionListEdit &&
          this.permisos.asignacionListVer &&
          this.permisos.asignacionRegEdit &&
          this.permisos.asignacionRegVer &&
          this.permisos.asignacionCapturar ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarUsuario() {
    if (  this.permisos.usuariosListEdit &&
          this.permisos.usuariosListVer &&
          this.permisos.usuariosRegEdit &&
          this.permisos.usuariosRegVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarActividad() {
    if (  this.permisos.actividadesListEdit &&
          this.permisos.actividadesListVer &&
          this.permisos.actividadesRegEdit &&
          this.permisos.actividadesRegVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarControl() {
    if (  this.permisos.seguimientoCumplimientoEdit &&
          this.permisos.seguimientoCumplimientoVer &&
          this.permisos.vacacionesEdit &&
          this.permisos.vacacionesVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarTicket() {
    if (  this.permisos.ticketsEdit &&
          this.permisos.ticketsVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

}
