import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Identity } from 'src/app/models/user';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reporte-menu',
  templateUrl: './reporte-menu.component.html',
  styleUrls: ['./reporte-menu.component.sass']
})
export class ReporteMenuComponent implements OnInit, OnDestroy {
  @Input() public imagen: boolean = false;
  @Input() public src: string = '';
  @Input() public icon: string = '';
  @Input() public title: string = '';
  @Input() public subTitle: string = '';
  @Input() public footer: string = '';
  @Input() public colorLeft: string = '#fff';
  @Input() public colorRight: string = '#fff';
  @Input() public colorText: string = '#222';
  @Input() public url: string = '';
  @Input() public ancho: string = '';
  @Input() public largo: string = '';

  public identity: Identity = new Identity(null);
  private subscribeAuth: Subscription = new Subscription();
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) { }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
  }

  routerUrl() {
    if (this.url) {
      let url = '/auth/' + this.url;
      this.router.navigate([url]);
    }
  }

}
