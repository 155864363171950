import { Action } from '@ngrx/store';

export const SUCCESS_TOASTR = '[Toast] Cargando Success Toastr...';
export const ERROR_TOASTR = '[Toast] Cargando Error Toastr...';
export const WARN_TOASTR = '[Toast] Cargando Warn Toastr...';
export const INFO_TOASTR = '[Toast] Cargando Info Toastr...';
export const CLEAR_TOASTR = '[Toast] Clear Toastrs...';

export class SuccessToastrAction implements Action {
    readonly type = SUCCESS_TOASTR;

    constructor( public message: string, public title: string, public status: any, public code: any) {}
}

export class ErrorToastrAction implements Action {
    readonly type = ERROR_TOASTR;

    constructor( public message: string, public title: string, public status: any, public code: any) {}
}

export class WarnToastrAction implements Action {
    readonly type = WARN_TOASTR;

    constructor( public message: string, public title: string, public status: any, public code: any) {}
}

export class InfoToastrAction implements Action {
    readonly type = INFO_TOASTR;

    constructor( public message: string, public title: string, public status: any, public code: any) {}
}

export class ClearToastrAction implements Action {
    readonly type = CLEAR_TOASTR;
}


export type toastrAcciones = SuccessToastrAction | ErrorToastrAction | WarnToastrAction | InfoToastrAction | ClearToastrAction;
