import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { AsignacionesService } from 'src/app/services/asignaciones.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';

@Injectable()
export class AsignacionEffects {

  constructor(
    private actions$: Actions,
    public asignacionesService: AsignacionesService,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) {}

  @Effect()
  cargarAsignaciones$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ASIGNACIONES ),
    switchMap( () => {
      return this.asignacionesService.obtenerAsignaciones()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarAsignacionesSuccess(respuesta.asignaciones);
          }),
          catchError( error => of(new usuariosActions.CargarAsignacionesFail(error))  )
        );
    })
  );

  @Effect()
  cargarAsignacion$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ASIGNACION ),
    switchMap( (action: any) => {
      return this.asignacionesService.obtenerAsignacion(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarAsignacionSuccess(respuesta.asignacion);
          }),
          catchError( error => of(new usuariosActions.CargarAsignacionFail(error))  )
        );
    })
  );

  @Effect()
  guardarAsignacion$ = this.actions$.pipe(
    ofType( usuariosActions.REGISTRAR_ASIGNACION ),
    switchMap( (action: any) => {
      return this.asignacionesService.guardarAsignacion(action.asignacion)
        .pipe(
          map( respuesta => {
            return new usuariosActions.RegistrarAsignacionSuccess(respuesta.asignacion);
          }),
          catchError( error => of(new usuariosActions.RegistrarAsignacionFail(error))  )
        );
    })
  );

  @Effect()
  eliminarAsignacion$ = this.actions$.pipe(
    ofType( usuariosActions.ELIMINAR_ASIGNACION ),
    switchMap( (action: any) => {
      return this.asignacionesService.eliminarAsignacion(action.id)
        .pipe(
          map( respuesta => {
            return new usuariosActions.EliminarAsignacionSuccess(action.id);
          }),
          catchError( error => of(new usuariosActions.EliminarAsignacionFail(error))  )
        );
    })
  );

  @Effect()
  cabiarEstatusAsignacion$ = this.actions$.pipe(
    ofType( usuariosActions.CAMBIAR_ESTATUS_ASIGNACION ),
    switchMap( (action: any) => {
      return this.asignacionesService.actualizarEstatusAsignacion(action.asignacion)
        .pipe(
          map( respuesta => {
            return new usuariosActions.CambiarEstatusAginacionSuccess(respuesta.asignacion);
          }),
          catchError( error => of(new usuariosActions.CambiarEstatusAginacionFail(error))  )
        );
    })
  );


  @Effect()
  existeAsignacionFijo$ = this.actions$.pipe(
    ofType( usuariosActions.EXISTE_ASIGNACION_FIJO ),
    switchMap( (action: any) => {
      return this.asignacionesService.existeAsignacion(action.asignacion) 
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ExisteAsignacionFijoSuccess(respuesta.asignacion);
          }),
          catchError( error => of(new usuariosActions.ExisteAsignacionFijoFail(error))  )
        );
    })
  );

}
