import { Action } from '@ngrx/store';
import { TipoSolicitud } from 'src/app/models/tipo-solicitud';

export const CARGAR_TIPO_SOLICITUDES = '[TipoSolicitud] Cargar tipo solicitud';
export const CARGAR_TIPO_SOLICITUDES_FAIL = '[TipoSolicitud] Cargar tipo solicitud FAIL';
export const CARGAR_TIPO_SOLICITUDES_SUCCESS = '[TipoSolicitud] Cargar tipo solicitud SUCCESS';
export const ACTUALIZA_TIPO_SOLICITUD = '[TipoSolicitud] Actualiza tipo solicitud';
export const ACTUALIZA_TIPO_SOLICITUD_FAIL = '[TipoSolicitud] Actualiza tipo solicitud FAIL';
export const ACTUALIZA_TIPO_SOLICITUD_SUCCESS = '[TipoSolicitud] Actualiza tipo solicitud SUCCESS';
export const ACTUALIZA_TIPO_SOLICITUD_ESTATUS = '[TipoSolicitud] Actualiza tipo solicitud estatus';
export const ACTUALIZA_TIPO_SOLICITUD_ESTATUS_FAIL = '[TipoSolicitud] Actualiza tipo solicitud estatus FAIL';
export const ACTUALIZA_TIPO_SOLICITUD_ESTATUS_SUCCESS = '[TipoSolicitud] Actualiza tipo solicitud estatus SUCCESS';

export class CargarTipoSolicitudes implements Action {
  readonly type = CARGAR_TIPO_SOLICITUDES;
}

export class CargarTipoSolicitudesFail implements Action {
  readonly type = CARGAR_TIPO_SOLICITUDES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarTipoSolicitudesSuccess implements Action {
  readonly type = CARGAR_TIPO_SOLICITUDES_SUCCESS;

  constructor( public solicitudes: TipoSolicitud[] ) {}
}

export class ActualizaTipoSolicitud implements Action {
  readonly type = ACTUALIZA_TIPO_SOLICITUD;

  constructor( public solicitud: TipoSolicitud ) { }
}

export class ActualizaTipoSolicitudFail implements Action {
  readonly type = ACTUALIZA_TIPO_SOLICITUD_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaTipoSolicitudSuccess implements Action {
  readonly type = ACTUALIZA_TIPO_SOLICITUD_SUCCESS;

  constructor( public solicitud: TipoSolicitud ) {}
}

export class ActualizaTipoSolicitudEstatus implements Action {
  readonly type = ACTUALIZA_TIPO_SOLICITUD_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaTipoSolicitudEstatusFail implements Action {
  readonly type = ACTUALIZA_TIPO_SOLICITUD_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaTipoSolicitudEstatusSuccess implements Action {
  readonly type = ACTUALIZA_TIPO_SOLICITUD_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export type tipoSolicitudAcciones = CargarTipoSolicitudes 
                            | CargarTipoSolicitudesFail 
                            | CargarTipoSolicitudesSuccess 
                            | ActualizaTipoSolicitud 
                            | ActualizaTipoSolicitudFail 
                            | ActualizaTipoSolicitudSuccess 
                            | ActualizaTipoSolicitudEstatus 
                            | ActualizaTipoSolicitudEstatusFail 
                            | ActualizaTipoSolicitudEstatusSuccess;

