import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { KPIsVendedoresService } from 'src/app/services/kpis-vendedores.service';


@Injectable()
export class ReporteKPIsVendedoresEffects {

  constructor(
    private actions$: Actions,
    public kpisVendedoresService: KPIsVendedoresService
  ) {}

  @Effect()
  cargarActividades$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_ACTIVIDADES_MULTIPLES ),
    switchMap( (action: any) => {
      return this.kpisVendedoresService.obtenerActividades(action.fecha, action.opciones)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteActividadesMultiplesSuccess(respuesta.series, respuesta.categories, respuesta.total);
          }),
          catchError( error => of(new usuariosActions.CargarReporteActividadesMultiplesFail(error))  )
        );
    })
	);
	
	@Effect()
  cargarActividadesPendientes$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_ACTIVIDADES_MULTIPLES_PENDIENTES ),
    switchMap( (action: any) => {
      return this.kpisVendedoresService.obtenerActividadesPendientes(action.fecha)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteActividadesMultiplesPendientesSuccess(respuesta.series, respuesta.categories);
          }),
          catchError( error => of(new usuariosActions.CargarReporteActividadesMultiplesPendientesFail(error))  )
        );
    })
	);
	
	@Effect()
  cargarEmpresasRegistradas$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_EMPRESAS_REGISTRADAS ),
    switchMap( (action: any) => {
      return this.kpisVendedoresService.obtenerEmpresasRegistradas(action.fecha)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteEmpresasRegistradasSuccess(respuesta.empresas);
          }),
          catchError( error => of(new usuariosActions.CargarReporteEmpresasRegistradasFail(error))  )
        );
    })
	);
	
	@Effect()
  cargarContactosRegistradas$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_CONTACTOS_REGISTRADAS ),
    switchMap( (action: any) => {
      return this.kpisVendedoresService.obtenerContactosRegistradas(action.fecha)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteContactosRegistradasSuccess(respuesta.contactos);
          }),
          catchError( error => of(new usuariosActions.CargarReporteContactosRegistradasFail(error))  )
        );
    })
	);
	
	@Effect()
  cargarOportunidadesAbiertas$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS ),
    switchMap( (action: any) => {
      return this.kpisVendedoresService.obtenerOportunidadesAbiertas(action.fecha)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteOportunidadesAbiertasSuccess(respuesta.oportunidades);
          }),
          catchError( error => of(new usuariosActions.CargarReporteOportunidadesAbiertasFail(error))  )
        );
    })
  );



  @Effect()
  cargarClasificacionesOportunidades$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES ),
    switchMap( (action: any) => {
      return this.kpisVendedoresService.obtenerClasificacionOportunidades(action.fecha)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteClasificacionOportunidadesSuccess(respuesta.series, respuesta.labels);
          }),
          catchError( error => of(new usuariosActions.CargarReporteClasificacionOportunidadesFail(error))  )
        );
    })
	);

  @Effect()
  cargarClasesOportunidades$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_CLASES_OPORTUNIDADES ),
    switchMap( (action: any) => {
      return this.kpisVendedoresService.obtenerClasesOportunidades(action.fecha)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteClasesOportunidadesSuccess(respuesta.series, respuesta.labels);
          }),
          catchError( error => of(new usuariosActions.CargarReporteClasesOportunidadesFail(error))  )
        );
    })
	);

  @Effect()
  cargarIndustriasOportunidades$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES ),
    switchMap( (action: any) => {
      return this.kpisVendedoresService.obtenerIndustriasOportunidades(action.fecha)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteIndustriasOportunidadesSuccess(respuesta.series, respuesta.categories);
          }),
          catchError( error => of(new usuariosActions.CargarReporteIndustriasOportunidadesFail(error))  )
        );
    })
	);

  @Effect()
  cargarFunnelOportunidades$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_FUNNEL_OPORTUNIDADES ),
    switchMap( (action: any) => {
      return this.kpisVendedoresService.obtenerFunnelOportunidades(action.fecha)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteFunnelOportunidadesSuccess(respuesta.dataSource);
          }),
          catchError( error => of(new usuariosActions.CargarReporteFunnelOportunidadesFail(error))  )
        );
    })
	);


}
