import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity, Permisos, UserType, Suscripciones } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import { Location } from '@angular/common';
import { MESSAGES } from 'src/app/models/messages';
import Swal from 'sweetalert2';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-usuario-config-edit',
  templateUrl: './usuario-config-edit.component.html',
  styleUrls: ['./usuario-config-edit.component.scss']
})
export class UsuarioConfigEditComponent implements OnInit, OnDestroy {
  public identity: Identity;
  public title: string;
  private subscribeAuth: Subscription = new Subscription();
  private subscribeTipos: Subscription = new Subscription();
  public tiposVer: UserType[] = [];
  public tipos: UserType[] = [];
  public tiposVerInicial: UserType[] = [];
  public tiposInicial: UserType[] = [];
  public loading = false;
  public url: string;
  public permisos: Permisos = new Permisos(null);
  public permisosInicial: Permisos = new Permisos(null);
  public tipoSelected: UserType = new UserType(null);
  public suscripciones: Suscripciones[] = [];

  public todo = false;
  public todoVer = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private locate: Location,
    public usuarioService: UsuariosService,
  ) { 
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;
    this.tipos = [];
    this.tiposVer = [];
    this.tiposInicial = [];
    this.tiposVerInicial = [];
  }

  ngOnInit(): void {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.subscribeTipos = this.store.select('catalogos').subscribe(
      (state) => {
        this.tiposVer = [...state.tiposVer.filter(item => item.id !== state.tipo.id)];
        this.tipos = [...state.tipos.filter(item => item.id !== state.tipo.id)];
        this.tiposVerInicial = JSON.parse(JSON.stringify(this.tiposVer)) as UserType[];
        this.tiposInicial = JSON.parse(JSON.stringify(this.tipos)) as UserType[];
        this.permisos = {...state.permisos};
        this.permisosInicial = {...state.permisos};
        this.tipoSelected = {...state.tipo};
        this.suscripciones = [...state.suscripciones];
      }
    );
    this.title = 'Configuración de usuario';
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeTipos.unsubscribe();
  }

  inicializa() {
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        if (id > 0) {
          this.store.dispatch(new usuariosActions.LoadPermisos(id));
          this.store.dispatch(new usuariosActions.LoadTypeUser(id));
        } else {
          this.tipoSelected = new UserType(null);
          this.permisos = new Permisos(null);
          this.permisosInicial = new Permisos(null);
          this.suscripciones = this.suscripciones.map( item => {
            item.checked = false;
            return item;
          });
        }
        this.store.dispatch(new usuariosActions.LoadTypeUsersConfig(id));
    });
    setTimeout( () => {
      if (this.identity.tipo !== 1) {
        this.locate.back();
      }
    }, 5);
  }

  
  registrarTipo() {
    Swal.fire({
      title: '¿Deseas registrar el tipo de usuario?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
       showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          let tipos = this.tipos.filter(item => item.checked);
          let tiposVer = this.tiposVer.filter(item => item.checked);
          this.usuarioService.saveTypePermsiosUser({...this.tipoSelected}, {...this.permisos}, [...this.suscripciones], {...tiposVer}, {...tipos}).subscribe(
            (respuesta) => {
              if (respuesta.tipo) {
                this.store.dispatch(new usuariosActions.SavePermisosSuccess({...respuesta.tipo}, {...this.permisos}, [...this.suscripciones]));
                resolve(respuesta.tipo);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.SavePermisosFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result: any) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Tipo de usuario registrado.',
            icon: 'success'
          });
          this.router.navigate(['/auth/config/usuarios']);
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  
  registrarPermisos() {
    this.permisos.usuarios = true;
    this.permisos.home = true;
    this.permisos.catDepartamentos = true;
    this.permisos.actividades = true; 
    this.permisos.catDivisiones = true;
    this.permisos.catSucursales = true;
    this.permisos.catColores = true;
    this.permisos.catServicios = true;
    this.permisos.indicador = true;
    this.permisos.catCliente = true;
    this.permisos.asignacion = true;
    this.permisos.catTipoServicio = true;
    this.permisos.catIndustrias = true;
    this.permisos.oportunidades = true;
    this.permisos.contactos = true;
    this.permisos.empresas = true;
    this.permisos.catOrigenes = true;
    this.permisos.catPaises = true;
    this.permisos.actividadesMultiple = true;

    if (this.tipoSelected.nombre && this.tipoSelected.nombre !== '') {
      if (!this.tipoSelected.id || this.tipoSelected.id === 0) {
        this.tipoSelected.id = 0;
        this.permisos.web = true;
      }
      this.registrarTipo();
    } else {
      this.toastr.warning('Captura el nombre del perfil');
    }
  }

  receiveMessage(event: Permisos) {
    this.permisos = {...event};
  }

  changeTiposVer() {
    this.tiposVer = [...this.tiposVer];
    let valor = true;
    this.tiposVer.find(item => { if(!item.checked){  valor = item.checked;  }});
    this.todoVer = valor;

  }

  changeTipos() {
    this.tipos = [...this.tipos];
    let valor = true;
    this.tipos.find(item => { if(!item.checked){  valor = item.checked;  }});
    this.todo = valor;
  }

  seleccionarVer() {
    if (this.todoVer) {
      this.tiposVer.find(item => { item.checked = true});
    } else {
      this.tiposVer.find(item => { item.checked = false});
    }
  }

  seleccionar() {
    if (this.todo) {
      this.tipos.find(item => { item.checked = true});
    } else {
      this.tipos.find(item => { item.checked = false});
    }
  }


}
