<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" >
  <div class="col-md-12">
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="btn-actions-pane-left">
          <div class="row mt-3">
            <div class="form-group pr-4">
              <input type="text" placeholder="Fecha" class="form-control d-none d-lg-block" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)">
              <input type="text" placeholder="Fecha" class="form-control d-lg-none" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)">
            </div>
            <div class="search-wrapper pr-3" [class.active]="paginacionD.isActive" *ngIf="resultadosDivision.length > 0">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchAsignacion" id="searchAsignacion" #searchAsignacion="ngModel" [(ngModel)]="paginacionD.textSearch" (blur)="textSearchChipsD()">
                <button class="search-icon" (click)="paginacionD.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacionD.isActive = false; paginacionD.textSearch = ''; chipsD = []"></button>
            </div>
          </div>
        </div>
        <div class="btn-actions-pane-right" *ngIf="resultadosDivision.length > 0">
          <span>
            ({{(resultadosDivision | anyScope: paginacionD.textSearch).length}} de {{(resultadosDivision).length}})
          </span>
          <div class="btn-group">
            <button class="btn-wide btn-icon btn-icon-right btn-pill btn btn-lg" (click)="crearExcel()">
              Excel
              <i class="fa fa-file-excel-o btn-icon-wrapper"> </i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-subtitle mt-2 ml-3" *ngIf="chipsD.length > 0">
        <mat-chip-list>
          <mat-chip  *ngFor="let chip of chipsD" [selectable]="selectable" [removable]="removable"> {{chip}}
            <mat-icon matChipRemove *ngIf="removable" (click)="removeD(chip) ">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="divider mt-0 m-0" *ngIf="chipsD.length > 0"></div>
      <div class="card-body p-0">
        <div class="card-body text-center" *ngIf="resultadosDivision.length > 0">
          <h5  class="card-title">Resumen servicios iniciados en (por división)</h5>
          <div class="table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th class="text-center" (click)="sortD('nombre')"> División
                    <span *ngIf="paginacionD.sortType === '-nombre'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionD.sortType === '+nombre'" class="fa fa-caret-up"></span> 
                  </th>
                  <th class="text-center" (click)="sortD('servicios')"> Servicios
                    <span *ngIf="paginacionD.sortType === '-servicios'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionD.sortType === '+servicios'" class="fa fa-caret-up"></span>
                  </th>
                  <th class="text-center" (click)="sortD('elementos')"> Número de elementos
                    <span *ngIf="paginacionD.sortType === '-elementos'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionD.sortType === '+elementos'" class="fa fa-caret-up"></span>
                  </th>
                  <th class="text-center" (click)="sortD('turnos')"> Turnos
                    <span *ngIf="paginacionD.sortType === '-turnos'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionD.sortType === '+turnos'" class="fa fa-caret-up"></span>
                  </th>
                  <th class="text-center" (click)="sortD('vacantes')"> Vacantes
                    <span *ngIf="paginacionD.sortType === '-vacantes'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionD.sortType === '+vacantes'" class="fa fa-caret-up"></span>
                  </th>
                  <th class="text-center" (click)="sortD('bajas')"> Bajas
                    <span *ngIf="paginacionD.sortType === '-bajas'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionD.sortType === '+bajas'" class="fa fa-caret-up"></span>
                  </th>
                  <th class="text-center" (click)="sortD('adicion')"> Adición
                    <span *ngIf="paginacionD.sortType === '-adicion'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionD.sortType === '+adicion'" class="fa fa-caret-up"></span>
                  </th>
                  <th class="text-center" (click)="sortD('reduccion')"> Reducción
                    <span *ngIf="paginacionD.sortType === '-reduccion'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionD.sortType === '+reduccion'" class="fa fa-caret-up"></span>
                  </th>
                </tr>
              </thead>
              <tbody *ngFor="let resultado of resultadosDivision | anyScope: paginacionD.textSearch | orderBy: [paginacionD.sortType] ">
                <tr>
                  <td>{{resultado.nombre}}</td>
                  <td>{{resultado.servicios}}</td>
                  <td>{{resultado.elementos}}</td>
                  <td>{{resultado.turnos}}</td>
                  <td>{{resultado.vacantes}}</td>
                  <td>{{resultado.bajas}}</td>
                  <td>{{resultado.adicion}}</td>
                  <td>{{resultado.reduccion}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td><b>{{resultadoTotal.nombre}}</b></td>
                  <td><b>{{resultadoTotal.servicios}}</b></td>
                  <td><b>{{resultadoTotal.elementos}}</b></td>
                  <td><b>{{resultadoTotal.turnos}}</b></td>
                  <td><b>{{resultadoTotal.vacantes}}</b></td>
                  <td><b>{{resultadoTotal.bajas}}</b></td>
                  <td><b>{{resultadoTotal.adicion}}</b></td>
                  <td><b>{{resultadoTotal.reduccion}}</b></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="resultadosDivision.length > 0">
        <div class="row p-2">
          <div class="col-sm-12">
            <div class="pull-right">
              <button type="button" (click) = "crearExcel()" class="btn btn-primary" [disabled]="loading" *ngIf="identity.permisos.rptCobertura">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
<div class="row" *ngIf="resultadosDivision.length > 0">
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card mb-3">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">  
        <div class="p-3">
          <div class="form-group">
            <label for="pageSizeVehiculo">Mostrar por hoja</label>
            <select class="custom-select" id="pageSizeVehiculo" name="pageSizeVehiculo" #pageSizeVehiculo="ngModel" [(ngModel)]="paginacionS.pageSize">
              <option *ngFor="let pagt of paginacionS.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="sortTypeVehiculos">Ordenar por</label>
            <select class="custom-select mb-2 mr-1" id="sortTypeVehiculos" name="sortTypeVehiculos" #sortTypeVehiculos="ngModel" [(ngModel)]="paginacionS.sortType">
              <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
            </select>
          </div>
          <div class="form-group">
            <div class="menu-header-title font-size-md mt-2">Filtrar division</div>
            <ul class="todo-list-wrapper list-group list-group-flush">
              <li class="list-group-item" *ngFor="let item of division">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-2">
                      <div class="custom-checkbox custom-control">
                        <input type="checkbox" id="selectCheckDivisionFilter{{item.id}}" class="custom-control-input" name="selectCheckDivisionFilter{{item.id}}" #selectCheckDivisionFilter{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeDivision()">
                        <label class="custom-control-label" for="selectCheckDivisionFilter{{item.id}}">&nbsp;</label>
                      </div>
                    </div>
                    <div class="widget-content-left flex2">
                      <div class="widget-heading">{{item.nombre}}</div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9" [ngClass]="{'col-lg-12': !filterActive }">
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="btn-actions-pane-left">
          <div class="row">
            <div class="search-wrapper pr-3" [class.active]="paginacionS.isActive" *ngIf="resultadosReporte.length > 0">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchAsignacion" id="searchAsignacion" #searchAsignacion="ngModel" [(ngModel)]="paginacionS.textSearch" (blur)="textSearchChipsS()">
                <button class="search-icon" (click)="paginacionS.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacionS.isActive = false; paginacionS.textSearch = ''; chipsS = [];"></button>
            </div>
          </div>
        </div>
        <div class="btn-actions-pane-right" *ngIf="resultadosReporte.length > 0">
          <span>
            ({{(resultadosReporte | anyScope: paginacionS.textSearch | multiOpcionesSelected : { opciones: division, parametro: 'division'}).length}} de {{(resultadosReporte).length}})
          </span>
          <div class="btn-group">
            <button class="btn-wide btn-icon btn-icon-right btn-pill btn btn-lg" *ngIf="!filterActive" (click)="filterActive = true">
              Filtrar
              <i class="pe-7s-filter btn-icon-wrapper"> </i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-subtitle mt-2 ml-3" *ngIf="chipsS.length > 0">
        <mat-chip-list>
          <mat-chip  *ngFor="let chip of chipsS" [selectable]="selectable" [removable]="removable"> {{chip}}
            <mat-icon matChipRemove *ngIf="removable" (click)="removeS(chip) ">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="divider mt-0 m-0" *ngIf="chipsS.length > 0"></div>
       <div class="card-body p-0">
        <div class="card-body text-center" *ngIf="resultadosReporte.length > 0">
          <h5  class="card-title">Resumen servicios iniciados en</h5>
          <div class="table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th class="text-center" (click)="sortS('numeroOrden')"> # Orden
                    <span *ngIf="paginacionS.sortType === '-numeroOrden'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionS.sortType === '+numeroOrden'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sortS('desCliente')"> Cliente
                    <span *ngIf="paginacionS.sortType === '-desCliente'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionS.sortType === '+desCliente'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sortS('desServicio')"> Servicio
                    <span *ngIf="paginacionS.sortType === '-desServicio'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionS.sortType === '+desServicio'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sortS('desSucursal')"> Sucursal
                    <span *ngIf="paginacionS.sortType === '-desSucursal'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionS.sortType === '+desSucursal'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sortS('localidad')"> Estado
                    <span *ngIf="paginacionS.sortType === '-localidad'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionS.sortType === '+localidad'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sortS('desDivision')"> División
                    <span *ngIf="paginacionS.sortType === '-desDivision'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionS.sortType === '+desDivision'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sortS('elementos')"> No. Elementos
                    <span *ngIf="paginacionS.sortType === '-elementos'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionS.sortType === '+elementos'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sortS('turnos')"> TRpordía
                    <span *ngIf="paginacionS.sortType === '-turnos'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionS.sortType === '+turnos'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sortS('vacantes')"> Vacantes
                    <span *ngIf="paginacionS.sortType === '-vacantes'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionS.sortType === '+vacantes'" class="fa fa-caret-up"></span>  
                  </th>
                  <th class="text-center" (click)="sortS('bajas')"> Bajas
                    <span *ngIf="paginacionS.sortType === '-bajas'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacionS.sortType === '+bajas'" class="fa fa-caret-up"></span>  
                  </th>
                </tr>
              </thead>
              <tbody *ngFor="let resultado of resultadosReporte | anyScope: paginacionS.textSearch | multiOpcionesSelected : { opciones: division, parametro: 'division'} | orderBy: [paginacionS.sortType] | slice: (paginacionS.page-1) * paginacionS.pageSize : (paginacionS.page-1) * paginacionS.pageSize + paginacionS.pageSize">
                <tr>
                  <td >{{resultado.numeroOrden}}</td>
                  <td >{{resultado.desCliente}}</td>
                  <td >{{resultado.desServicio}}</td>
                  <td >{{resultado.desSucursal}}</td>
                  <td >{{resultado.localidad}}</td>
                  <td >{{resultado.desDivision}}</td>
                  <td >{{resultado.elementos}}</td>
                  <td >{{resultado.turnos}}</td>
                  <td >{{resultado.vacantes}}</td>
                  <td >{{resultado.bajas}}</td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div *ngIf="resultadosReporte.length > 0">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="text-left">
              <ngb-pagination
                [collectionSize]="(resultadosReporte | anyScope: paginacionS.textSearch | multiOpcionesSelected : { opciones: division, parametro: 'division'} ).length" [(page)]="paginacionS.page" [pageSize]="paginacionS.pageSize" [maxSize]="3">
                <ng-template ngbPaginationFirst>First</ng-template>
                <ng-template ngbPaginationLast>Last</ng-template>
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
            </div>
          </div>
          <!-- <div class="col-sm-6">
            <div class="pull-right">
              <button type="button" (click) = "crearExcel()" class="btn btn-primary" [disabled]="loading" *ngIf="identity.permisos.rptCobertura">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>


