import * as fromUsuario from '../actions';
import { Asignaciones } from 'src/app/models/captura-asignacion';

export interface CapturaAsignacionesState {
  captura: Asignaciones[];
  total: number;
  totalFiltro: number;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: CapturaAsignacionesState = {
  captura: [],
  total: 0,
  totalFiltro: 0,
  loaded: false,
  loading: false,
  error: null
};

export function capturasAsignacionReducer( state = estadoInicial, action: fromUsuario.capturasAsignacionAcciones): CapturaAsignacionesState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_CAPTURA_ASIGNACION:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_CAPTURA_ASIGNACION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        captura: [...action.capturas],
        total: action.total,
        totalFiltro: action.totalFiltro
        };
    case fromUsuario.CARGAR_CAPTURA_ASIGNACION_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.ACTUALIZA_CAPTURA_ASIGNACION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_CAPTURA_ASIGNACION_SUCCESS:
      if(action.captura.resultado === 0){
        state.captura = state.captura.map( item => {
          if (item.id === action.id) {
            item.resultados.map(itm =>{
              if(itm.captura === action.captura.fecha){
                if(action.captura.tipo === 1){
                  itm.falta = action.captura.resultado;
                }
                if(action.captura.tipo === 2){
                  itm.disponibilidad = (itm.disponibilidad - itm.bajas);
                  itm.bajas = action.captura.resultado;
                }
                if(action.captura.tipo === 3){
                  itm.disponibilidad = (itm.disponibilidad + itm.vacantes);
                  itm.vacantes = action.captura.resultado;
                }
              } 
            });
          }
          return item;
        });
      }else{
        state.captura = state.captura.map( item => {
          if (item.id === action.id) {
            item.resultados.map(itm =>{
              if(itm.captura === action.captura.fecha){
                if(action.captura.tipo === 1){
                  itm.falta = action.captura.resultado;
                }
                if(action.captura.tipo === 2){
                  itm.bajas = action.captura.resultado;
                  itm.disponibilidad = action.captura.disponibilidad;
                }
                if(action.captura.tipo === 3){
                  itm.vacantes = action.captura.resultado;
                  itm.disponibilidad = action.captura.disponibilidad;
                }
              } 
            });
          }
          return item;
        });
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_CAPTURA_ASIGNACION_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_CAPTURAS_ASIGNACION:
      return {
        ...state,
        captura: []
      };

    default:
      return state;
  }
}

