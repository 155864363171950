<div class="d-flex" *ngIf="identity && identity.id">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" [src]="url + '/usuarios/perfiles/' + identity.id + '/imagenes?v=' + version" alt="Imagen de perfil" class="rounded-circle">
                </div>
              </span>
            </button>
            <div class="dropdown-menu-lg" ngbDropdownMenu>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <img width="42" [src]="url + '/usuarios/perfiles/' + identity.id + '/imagenes?v=' + version" alt="Imagen de perfil" class="rounded-circle">
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">{{identity.nombre}}</div>
                          <!-- <div class="widget-subheading opacity-8">{{identity.desTipo}}</div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scroll-area-xs">
                <perfect-scrollbar [autoPropagation]="true">
                  <ul class="nav flex-column">
                    <li class="nav-item-header nav-item">ACCIONES</li>
                    <li class="nav-item"><a [routerLink]="'/auth/change-password'" class="nav-link">Cambiar contraseña</a></li>
                    <li class="nav-item" *ngIf="identity.permisos && (identity.permisos.contactosListVer || identity.permisos.oportunidadesListVer || identity.permisos.empresasListEdit)"><a [routerLink]="'/auth/kpis-vendedores'" class="nav-link">Mis indicadores</a></li>
                  </ul>
                </perfect-scrollbar>
              </div>
              <ul class="nav flex-column">
                <li class="nav-item-divider mb-0 nav-item"></li>
              </ul>
              <div class="grid-menu overflow-hidden" [ngClass]="{'grid-menu-2col': identity.tipo === 1}">
                <div class="no-gutters row">
                  <div [ngClass]="{'col-12': identity.tipo !== 1, 'col-sm-6': identity.tipo === 1}">
                    <a [routerLink]="['/auth/setting-profile']" class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-warning">
                      <i class="pe-7s-user icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"></i>Perfil
                    </a>
                  </div>
                  <div class="col-sm-6" *ngIf="identity.tipo === 1">
                    <a [routerLink]="['/auth/config/usuarios']" class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-danger">
                      <i class="pe-7s-config icon-gradient bg-love-kiss btn-icon-wrapper mb-2"></i><b>Configuración</b>
                    </a>
                  </div>
                </div>
              </div>
              <ul class="nav flex-column">
                <li class="nav-item-divider nav-item"></li>
                <li class="nav-item-btn text-center nav-item">
                  <button class="btn-pill btn-shadow btn-shine btn btn-focus" (click)="logout()">
                    <i class="menu-icon fa fa-sign-out"></i> Cerrar sesión
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="widget-content-left ml-3 header-user-info">
          <div class="widget-heading">{{identity.nombre + ' ' + identity.apellidos}}</div>
          <!-- <div class="widget-subheading">{{identity.desTipo}}</div> -->
        </div>
      </div>
    </div>
  </div>
</div>

