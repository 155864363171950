<div class="col-12 mt-3">
	<div class="form-group">
		<div class="row">
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 1}" (click)="filterPermisos = 1; filterSelecionar()" title="Ver y editar todos los permisos">TODOS</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 2}" (click)="filterPermisos = 2; filterSelecionar()" title="Ver y editar todos los permisos de consulta">Consulta</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 3}" (click)="filterPermisos = 3; filterSelecionar()" title="Ver y editar todos los permisosd de registros">Registros</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 9}" (click)="filterPermisos = 9; filterSelecionar()" title="Configuración de los catálogos de áreas, divisiones, sucursales, colores, servicios, clientes, países, origenes de contactos, industrias, tipos de empresas y tipos de servicios">Catálogos</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 12}" (click)="filterPermisos = 12; filterSelecionar()" title="Ver y editar usuarios">Usuarios</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 14}" (click)="filterPermisos = 14; filterSelecionar()" title="Configuración del control de las actividades">Control</button>
			</div>
		</div>
	</div>
</div>
<ngb-tabset class="tabs-animated tabs-animated-line" justify="justified">
	<ngb-tab>
		<ng-template ngbTabTitle><span>Permisos</span></ng-template>
		<ng-template ngbTabContent>
			<ul class="todo-list-wrapper list-group list-group-flush">
				<li class="list-group-item">
					<div class="widget-content p-0">
						<div class="widget-content-wrapper">
							<div class="widget-content-left mr-2">
								<div class="custom-checkbox custom-control">
									<input type="checkbox" id="allPermisosForm" name="allPermisosForm" #allPermisosForm="ngModel" [(ngModel)]="todo" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="seleccionar()">
									<label class="custom-control-label" for="allPermisosForm">&nbsp; Seleccionar todo</label>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<hr class="p-0 m-0">
			<div class="scroll-area-lg">
				<div class="scrollbar-container">
					<perfect-scrollbar [autoPropagation]="true">
						<ul class="todo-list-wrapper list-group list-group-flush">
							<!-- 12 - Usuarios -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 12 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormusuariosListEdit" name="permisoFormusuariosListEdit" #permisoFormusuariosListEdit="ngModel" [(ngModel)]="permisos.usuariosListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormusuariosListEdit">&nbsp; Editar lista de usuarios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item"*ngIf="filterPermisos === 1 || filterPermisos === 12 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormusuariosListVer" name="permisoFormusuariosListVer" #permisoFormusuariosListVer="ngModel" [(ngModel)]="permisos.usuariosListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormusuariosListVer">&nbsp; Ver lista de usuarios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 12 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormusuariosRegEdit" name="permisoFormusuariosRegEdit" #permisoFormusuariosRegEdit="ngModel" [(ngModel)]="permisos.usuariosRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormusuariosRegEdit">&nbsp; Editar detalle de usuarios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 12 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormusuariosRegVer" name="permisoFormusuariosRegVer" #permisoFormusuariosRegVer="ngModel" [(ngModel)]="permisos.usuariosRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormusuariosRegVer">&nbsp; Ver detalle de usuarios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 9 - Catálogos -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDepartamentosRegEdit" name="permisoFormDepartamentosRegEdit" #permisoFormDepartamentosRegEdit="ngModel" [(ngModel)]="permisos.catDepartamentosEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDepartamentosRegEdit">&nbsp; Editar detalle del catálogo áreas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDepartamentosVer" name="permisoFormDepartamentosVer" #permisoFormDepartamentosVer="ngModel" [(ngModel)]="permisos.catDepartamentosVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDepartamentosVer">&nbsp; Ver detalle del catálogo áreas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormOrigenesContactoRegEdit" name="permisoFormOrigenesContactoRegEdit" #permisoFormOrigenesContactoRegEdit="ngModel" [(ngModel)]="permisos.catOrigenesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormOrigenesContactoRegEdit">&nbsp; Editar detalle del catálogo orígenes de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormOrigenesContactoVer" name="permisoFormOrigenesContactoVer" #permisoFormOrigenesContactoVer="ngModel" [(ngModel)]="permisos.catOrigenesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormOrigenesContactoVer">&nbsp; Ver detalle del catálogo orígenes de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormIndustriasRegEdit" name="permisoFormIndustriasRegEdit" #permisoFormIndustriasRegEdit="ngModel" [(ngModel)]="permisos.catIndustriasEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormIndustriasRegEdit">&nbsp; Editar detalle del catálogo industrias</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormIndustriasVer" name="permisoFormIndustriasVer" #permisoFormIndustriasVer="ngModel" [(ngModel)]="permisos.catIndustriasVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormIndustriasVer">&nbsp; Ver detalle del catálogo industrias</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormPaisesRegEdit" name="permisoFormPaisesRegEdit" #permisoFormPaisesRegEdit="ngModel" [(ngModel)]="permisos.catPaisesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormPaisesRegEdit">&nbsp; Editar detalle del catálogo países</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormPaisesVer" name="permisoFormPaisesVer" #permisoFormPaisesVer="ngModel" [(ngModel)]="permisos.catPaisesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormPaisesVer">&nbsp; Ver detalle del catálogo países</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDivisionesEdit" name="permisoFormDivisionesEdit" #permisoFormDivisionesEdit="ngModel" [(ngModel)]="permisos.catDivisionesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDivisionesEdit">&nbsp; Editar detalle del catálogo divisiones</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDivisionesRegVer" name="permisoFormDivisionesRegVer" #permisoFormDivisionesRegVer="ngModel" [(ngModel)]="permisos.catDivisionesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDivisionesRegVer">&nbsp; Ver detalle del catálogo divisiones</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormSucursalesRegEdit" name="permisoFormSucursalesRegEdit" #permisoFormSucursalesRegEdit="ngModel" [(ngModel)]="permisos.catSucursalesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormSucursalesRegEdit">&nbsp; Editar detalle del catálogo sucursales</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormSucursalesVer" name="permisoFormSucursalesVer" #permisoFormSucursalesVer="ngModel" [(ngModel)]="permisos.catSucursalesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormSucursalesVer">&nbsp; Ver detalle del catálogo sucursales</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormColoresRegEdit" name="permisoFormColoresRegEdit" #permisoFormColoresRegEdit="ngModel" [(ngModel)]="permisos.catColoresEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormColoresRegEdit">&nbsp; Editar detalle del catálogo colores</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormColoresVer" name="permisoFormColoresVer" #permisoFormColoresVer="ngModel" [(ngModel)]="permisos.catColoresVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormColoresVer">&nbsp; Ver detalle del catálogo colores</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormServiciosRegEdit" name="permisoFormServiciosRegEdit" #permisoFormServiciosRegEdit="ngModel" [(ngModel)]="permisos.catServiciosEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormServiciosRegEdit">&nbsp; Editar detalle del catálogo servicios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormServiciosVer" name="permisoFormServiciosVer" #permisoFormServiciosVer="ngModel" [(ngModel)]="permisos.catServiciosVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormServiciosVer">&nbsp; Ver detalle del catálogo servicios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormClientesRegEdit" name="permisoFormClientesRegEdit" #permisoFormClientesRegEdit="ngModel" [(ngModel)]="permisos.catClienteEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormClientesRegEdit">&nbsp; Editar detalle del catálogo clientes</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormClientesVer" name="permisoFormClientesVer" #permisoFormClientesVer="ngModel" [(ngModel)]="permisos.catClienteVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormClientesVer">&nbsp; Ver detalle del catálogo clientes</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormTiposServicioRegEdit" name="permisoFormTiposServicioRegEdit" #permisoFormTiposServicioRegEdit="ngModel" [(ngModel)]="permisos.catTipoServicioEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormTiposServicioRegEdit">&nbsp; Editar detalle del catálogo tipos de servicio</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormTiposServicioVer" name="permisoFormTiposServicioVer" #permisoFormTiposServicioVer="ngModel" [(ngModel)]="permisos.catTipoServicioVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormTiposServicioVer">&nbsp; Ver detalle del catálogo tipos de servicio</label>
											</div>
										</div>
									</div>
								</div>
							</li>
								<!-- 14 - Control -->
								<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 14 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormSeguiminetoCumplimientoEdit" name="permisoFormSeguiminetoCumplimientoEdit" #permisoFormSeguiminetoCumplimientoEdit="ngModel" [(ngModel)]="permisos.seguimientoCumplimientoEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormSeguiminetoCumplimientoEdit">&nbsp; Editar detalle de seguimineto cumplimiento</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 14 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormSeguiminetoCumplimientoVer" name="permisoFormSeguiminetoCumplimientoVer" #permisoFormSeguiminetoCumplimientoVer="ngModel" [(ngModel)]="permisos.seguimientoCumplimientoVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormSeguiminetoCumplimientoVer">&nbsp; Ver detalle de seguimineto cumplimiento</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 14 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormVacacionesEdit" name="permisoFormVacacionesEdit" #permisoFormVacacionesEdit="ngModel" [(ngModel)]="permisos.vacacionesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormVacacionesEdit">&nbsp; Editar detalle de vanaciones</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 14 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormVacacionesVer" name="permisoFormVacacionesVer" #permisoFormVacacionesVer="ngModel" [(ngModel)]="permisos.vacacionesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormVacacionesVer">&nbsp; Ver detalle de vanaciones</label>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</perfect-scrollbar>
				</div>
			</div>
		</ng-template>
	</ngb-tab>
</ngb-tabset>