import { environment } from 'src/environments/environment';

export let GLOBAL = {
  url: environment.url,
  captcha: environment.captcha,
  keyEncr: environment.encryptKey,
  keyIV: environment.encryptIV,
  path: window.location.origin,
  emailsDns: environment.emailsDns,
  title: environment.title
};
