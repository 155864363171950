import { Action } from '@ngrx/store';
import { Catalogo } from 'src/app/models/catalogo';
import { Sucursal } from 'src/app/models/sucursales';
import { Colores } from 'src/app/models/colores';
import { Servicio } from 'src/app/models/servicios';
import { Cliente } from 'src/app/models/clientes';


export const CONSULTA_CATALOGO_DIVISIONES = '[ConsultasCatalogo] Consulta divisiones';
export const CONSULTA_CATALOGO_DIVISIONES_SUCCESS = '[ConsultasCatalogo] Consulta divisiones success';
export const CONSULTA_CATALOGO_DIVISIONES_FAIL = '[ConsultasCatalogo] Consulta divisiones fail';
export const CONSULTA_CATALOGO_DEPARTAMENTOS = '[ConsultasCatalogo] Consulta departamentos';
export const CONSULTA_CATALOGO_DEPARTAMENTOS_SUCCESS = '[ConsultasCatalogo] Consulta departamentos success';
export const CONSULTA_CATALOGO_DEPARTAMENTOS_FAIL = '[ConsultasCatalogo] Consulta departamentos fail';
export const CONSULTA_CATALOGO_SUCURSALES = '[ConsultasCatalogo] Consulta sucursales';
export const CONSULTA_CATALOGO_SUCURSALES_SUCCESS = '[ConsultasCatalogo] Consulta sucursales success';
export const CONSULTA_CATALOGO_SUCURSALES_FAIL = '[ConsultasCatalogo] Consulta sucursales fail';
export const CONSULTA_CATALOGO_COLORES = '[ConsultasCatalogo] Consulta colores';
export const CONSULTA_CATALOGO_COLORES_SUCCESS = '[ConsultasCatalogo] Consulta colores success';
export const CONSULTA_CATALOGO_COLORES_FAIL = '[ConsultasCatalogo] Consulta colores fail';
export const CONSULTA_CATALOGO_SERVICIOS = '[ConsultasCatalogo] Consulta servicios';
export const CONSULTA_CATALOGO_SERVICIOS_SUCCESS = '[ConsultasCatalogo] Consulta servicios success';
export const CONSULTA_CATALOGO_SERVICIOS_FAIL = '[ConsultasCatalogo] Consulta servicios fail';
export const CONSULTA_CATALOGO_CLIENTES = '[ConsultasCatalogo] Consulta clientes';
export const CONSULTA_CATALOGO_CLIENTES_SUCCESS = '[ConsultasCatalogo] Consulta clientes success';
export const CONSULTA_CATALOGO_CLIENTES_FAIL = '[ConsultasCatalogo] Consulta clientes fail';
export const CONSULTA_CATALOGO_TIPO_SERVICIOS = '[ConsultasCatalogo] Consulta tipo servicios';
export const CONSULTA_CATALOGO_TIPO_SERVICIOS_SUCCESS = '[ConsultasCatalogo] Consulta tipo servicios success';
export const CONSULTA_CATALOGO_TIPO_SERVICIOS_FAIL = '[ConsultasCatalogo] Consulta tipo servicios fail';

export class ConsultaCatalogoDivisiones implements Action {
  readonly type = CONSULTA_CATALOGO_DIVISIONES;
}

export class ConsultaCatalogoDivisionesFail implements Action {
  readonly type = CONSULTA_CATALOGO_DIVISIONES_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaCatalogoDivisionesSuccess implements Action {
  readonly type = CONSULTA_CATALOGO_DIVISIONES_SUCCESS;

  constructor(public division: Catalogo[]) {}
}

export class ConsultaCatalogoDepartamentos implements Action {
  readonly type = CONSULTA_CATALOGO_DEPARTAMENTOS;

  constructor() {}
}

export class ConsultaCatalogoDepartamentosFail implements Action {
  readonly type = CONSULTA_CATALOGO_DEPARTAMENTOS_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaCatalogoDepartamentosSuccess implements Action {
  readonly type = CONSULTA_CATALOGO_DEPARTAMENTOS_SUCCESS;

  constructor(public departamentos: Catalogo[]) {}
}

export class ConsultaCatalogoSucursales implements Action {
  readonly type = CONSULTA_CATALOGO_SUCURSALES;

  constructor() {}
}

export class ConsultaCatalogoSucursalesFail implements Action {
  readonly type = CONSULTA_CATALOGO_SUCURSALES_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaCatalogoSucursalesSuccess implements Action {
  readonly type = CONSULTA_CATALOGO_SUCURSALES_SUCCESS;

  constructor(public sucursales: Sucursal[]) {}
}

export class ConsultaCatalogoColores implements Action {
  readonly type = CONSULTA_CATALOGO_COLORES;

  constructor() {}
}

export class ConsultaCatalogoColoresFail implements Action {
  readonly type = CONSULTA_CATALOGO_COLORES_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaCatalogoColoresSuccess implements Action {
  readonly type = CONSULTA_CATALOGO_COLORES_SUCCESS;

  constructor(public colores: Colores[]) {}
}

export class ConsultaCatalogoServicios implements Action {
  readonly type = CONSULTA_CATALOGO_SERVICIOS;

  constructor() {}
}

export class ConsultaCatalogoServiciosFail implements Action {
  readonly type = CONSULTA_CATALOGO_SERVICIOS_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaCatalogoServiciosSuccess implements Action {
  readonly type = CONSULTA_CATALOGO_SERVICIOS_SUCCESS;

  constructor(public servicios: Servicio[]) {}
}

export class ConsultaCatalogoClientes implements Action {
  readonly type = CONSULTA_CATALOGO_CLIENTES;

  constructor() {}
}

export class ConsultaCatalogoClientesFail implements Action {
  readonly type = CONSULTA_CATALOGO_CLIENTES_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaCatalogoClientesSuccess implements Action {
  readonly type = CONSULTA_CATALOGO_CLIENTES_SUCCESS;

  constructor(public clientes: Cliente[]) {}
}

export class ConsultaCatalogoTipoServicios implements Action {
  readonly type = CONSULTA_CATALOGO_TIPO_SERVICIOS;

  constructor() {}
}

export class ConsultaCatalogoTipoServiciosFail implements Action {
  readonly type = CONSULTA_CATALOGO_TIPO_SERVICIOS_FAIL;

  constructor( public payload: any ) {}
}

export class ConsultaCatalogoTipoServiciosSuccess implements Action {
  readonly type = CONSULTA_CATALOGO_TIPO_SERVICIOS_SUCCESS;

  constructor(public tipos: Catalogo[]) {}
}

export type consultaCatalogosAcciones = ConsultaCatalogoDivisiones
																			| ConsultaCatalogoDivisionesFail
																			| ConsultaCatalogoDivisionesSuccess
                                      | ConsultaCatalogoDepartamentos
                                      | ConsultaCatalogoDepartamentosFail
                                      | ConsultaCatalogoDepartamentosSuccess
                                      | ConsultaCatalogoSucursales
                                      | ConsultaCatalogoSucursalesFail
                                      | ConsultaCatalogoSucursalesSuccess
                                      | ConsultaCatalogoColores
                                      | ConsultaCatalogoColoresFail
                                      | ConsultaCatalogoColoresSuccess
                                      | ConsultaCatalogoServicios
                                      | ConsultaCatalogoServiciosFail
                                      | ConsultaCatalogoServiciosSuccess
                                      | ConsultaCatalogoClientes
                                      | ConsultaCatalogoClientesFail
                                      | ConsultaCatalogoClientesSuccess
                                      | ConsultaCatalogoTipoServicios
                                      | ConsultaCatalogoTipoServiciosFail
                                      | ConsultaCatalogoTipoServiciosSuccess
																			;
