import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { EmpresasService } from 'src/app/services/empresa.service';

@Injectable()
export class EmpresaEffects {

  constructor(
    private actions$: Actions,
    public empresasService: EmpresasService
  ) {}


  @Effect()
  cargarEmpresas2$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_EMPRESAS ),
    switchMap( (action: any) => {
      return this.empresasService.obtenerEmpresas(action.opciones)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarEmpresasSuccess(respuesta.empresas, respuesta.totalFiltro, respuesta.total);
          }),
          catchError( error => of(new usuariosActions.CargarEmpresasFail(error))  )
        );
    })
  );

  @Effect()
  cargarEmpresa$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_EMPRESA ),
    switchMap( (action: any) => {
      return this.empresasService.obtenerDatoEmpresa(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarEmpresaSuccess(respuesta.empresa);
          }),
          catchError( error => of(new usuariosActions.CargarEmpresaFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusEmpresa$ = this.actions$.pipe(
    ofType( usuariosActions.ELIMINAR_EMPRESA ),
    switchMap( (action: any) => {
      return this.empresasService.eliminarEmpresa(action.id)
        .pipe(
          map( respuesta => {
            return new usuariosActions.EliminarEmpresaSuccess(action.id);
          }),
          catchError( error => of(new usuariosActions.EliminarEmpresaFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEmpresa$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_EMPRESA ),
    switchMap( (action: any) => {
      return this.empresasService.registraEmpresa(action.empresa)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaEmpresaSuccess(action.empresa);
            }),
            catchError( error => of(new usuariosActions.ActualizaEmpresaFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEmpresasDuplicados$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_EMPRESAS_DUPLICADOS ),
    switchMap( (action: any) => {
      return this.empresasService.actualizaEmpresasDuplicados(action.empresa, action.duplicados)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaEmpresasDuplicadosSuccess(action.empresa, action.duplicados);
            }),
            catchError( error => of(new usuariosActions.ActualizaEmpresasDuplicadosFail(error))  )
        );
    })
  );

}
