import { Catalogo } from './catalogo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SortOrder } from './sort';

export class Oportunidad{
	public id: number;
	public nombre: string;
	public importe: number;
	public fecha: Date;
	public etapa: number;
	public desEtapa: string;
	public propietario: number;
	public desPropietario: string;
	public contactos: Catalogo[];
	public empresa: number;
	public desEmpresa: string;
	public tipoNegocio: string;
	public mostrar: boolean;
	public status: boolean;
	public fecRegistro: Date;
	public fecModificacion: Date;
	constructor(
		obj: DataObjOportunidad
	){
		this.id = obj && obj.id ? obj.id : 0;
		this.nombre = obj && obj.nombre ? obj.nombre : '';
		this.importe = obj && obj.importe ? obj.importe : 0;
		this.fecha = obj && obj.fecha ? obj.fecha : null;
		this.etapa = obj && obj.etapa ? obj.etapa : null;
		this.desEtapa = obj && obj.desEtapa ? obj.desEtapa : null;
		this.propietario = obj && obj.propietario ? obj.propietario : null;
		this.desPropietario = obj && obj.desPropietario ? obj.desPropietario : '';
		this.contactos = obj && obj.contactos ? obj.contactos : [];
		this.empresa = obj && obj.empresa ? obj.empresa : null;
		this.desEmpresa = obj && obj.desEmpresa ? obj.desEmpresa : '';
		this.tipoNegocio = obj && obj.tipoNegocio ? obj.tipoNegocio : null;
		this.mostrar = obj && obj.mostrar ? obj.mostrar: false;
		this.status = obj && obj.status ? obj.status: false;
    this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
		this.fecModificacion = obj && obj.fecModificacion ? obj.fecModificacion : null;
	}
}

interface DataObjOportunidad{
	id: number;
	nombre: string;
	importe: number;
	fecha: Date;
	etapa: number;
	desEtapa: string;
	propietario: number;
	desPropietario: string;
	empresa: number;
	desEmpresa: string;
	tipoNegocio: string;
	contactos: Catalogo[];
	mostrar: boolean;
	status: boolean;
  fecRegistro?: Date;
	fecModificacion?: Date;
}

export const NegocioCatalogo: Catalogo[] = [
	{ id: 1, nombre: 'Cliente nuevo', status: true},
	{ id: 2, nombre: 'Cliente existente', status: true}
];

export const ReporteVentaSortType: SortOrder[] = [
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+nombre', text: 'Nombres'},
  { id: '-nombre', text: 'Nombres desc'},
  { id: '+desEmpresa', text: 'Empresa'},
  { id: '-desEmpresa', text: 'Empresa desc'},
  { id: '+importe', text: 'Importe'},
  { id: '-importe', text: 'Importe desc'},
  { id: '+desPropietario', text: 'Propietario'},
  { id: '-desPropietario', text: 'Propietario desc'},
  { id: '+fecha', text: 'Fecha de cierre'},
  { id: '-fecha', text: 'Fecha de cierre desc'},
  { id: '+fecRegistro', text: 'Fecha de alta'},
  { id: '-fecRegistro', text: 'Fecha de alta desc'},
  { id: '+fecModificacion', text: 'Fecha de modificación'},
  { id: '-fecModificacion', text: 'Fecha de modificación desc'}
];

export const ColoresCatalogo: Catalogo[] = [
	{id: 1, nombre: '#a1ce69' , status: true},
	{id: 2, nombre: '#44aca3' , status: true},
	{id: 3, nombre: '#a79d7a' , status: true},
	{id: 4, nombre: '#ffcf1a' , status: true},
	{id: 5, nombre: '#576d96' , status: true},
	{id: 6, nombre: '#dc7633' , status: true},
	{id: 7, nombre: '#a569bd' , status: true},
	{id: 8, nombre: '#831f38' , status: true}
];


