import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Identity, Permisos, UserType, Suscripciones } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';

@Component({
  selector: 'app-usuario-config-admin',
  templateUrl: './usuario-config-admin.component.html',
  styleUrls: ['./usuario-config-admin.component.scss']
})
export class UsuarioConfigAdminComponent implements OnInit, OnDestroy {
  public identity: Identity;
  private subscribeAuth: Subscription = new Subscription();
  public loading = false;
  public url: string;
  public suscripciones: Suscripciones[] = [];
  public todo = false;
  public todoSuscripciones = false;
  public filterPermisos: number = 1;

  @Input() permisos: Permisos;
  @Input() tipoSelected: UserType;
  @Output() messageEvent = new EventEmitter<Permisos>();
  constructor(
    private store: Store<AppState>,
  ) { 
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;

    // filterPermisos = 1 TODOS
    // filterPermisos = 2 Consulta
    // filterPermisos = 3 Registros
    // filterPermisos = 4 Empresas
    // filterPermisos = 5 Oportunidades
    // filterPermisos = 6 Reportes 
    // filterPermisos = 7 Contactos
    // filterPermisos = 8 Tickets
    // filterPermisos = 9 Catálogos 
    // filterPermisos = 10 Indicadores
    // filterPermisos = 11 Asignaciones
    // filterPermisos = 12 Usuarios
    // filterPermisos = 13 Actividades
    // filterPermisos = 14 Control
  }

  ngOnInit(): void {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.filterSelecionar();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
  }

  sendMessage() {
    this.messageEvent.emit(this.permisos);
  }

  validarTodo() {
    if(this.filterPermisos !== 3 ){
      setTimeout( () => {
        // tslint:disable-next-line:forin
        for (const field in this.permisos) {
          if (field.search('Edit')) {
            if (this.permisos[field]) {
              const atributo = field.substr(0, field.length - 4) + 'Ver';
              if (atributo in this.permisos) {
                this.permisos[atributo] = true;
              }
            }
          }
        }
        const elements = Object.keys(this.permisos);
        this.todo = elements.every( (key) => this.permisos[key]);
      }, 1);
    }
    this.sendMessage();
  }

  validarTodoSuscripciones() {
    this.todoSuscripciones = this.suscripciones.every( (item) => item.checked);
  }


  seleccionar() {
    switch ( this.filterPermisos ) {
      case 1:
        this.seleccionarTodo();
      break;
      case 2:
        this.seleccionarConsulta();
      break;
      case 3:
        this.seleccionarRegistro();
      break;
      case 9:
        this.seleccionarCatalogo();
      break;
      case 12:
        this.seleccionarUsuario();
      break;
      case 14:
        this.seleccionarControl();
      break;
    }
  }

  seleccionarTodo() {
    if (this.todo) {
      this.permisos.usuariosListEdit = true;
      this.permisos.usuariosListVer = true;
      this.permisos.usuariosRegEdit = true;
      this.permisos.usuariosRegVer = true;
      this.permisos.catDepartamentosEdit = true;
      this.permisos.catDepartamentosVer = true;
      this.permisos.catOrigenesEdit = true;
      this.permisos.catOrigenesVer = true;
      this.permisos.catIndustriasEdit = true;
      this.permisos.catIndustriasVer = true;
      this.permisos.catPaisesEdit = true;
      this.permisos.catPaisesVer = true;
      this.permisos.catDivisionesEdit = true;
      this.permisos.catDivisionesVer = true;
      this.permisos.catSucursalesEdit = true;
      this.permisos.catSucursalesVer = true;
      this.permisos.catColoresEdit = true;
      this.permisos.catColoresVer = true;
      this.permisos.catServiciosEdit = true;
      this.permisos.catServiciosVer = true;
      this.permisos.catClienteEdit = true;
      this.permisos.catClienteVer = true;
      this.permisos.catTipoServicioEdit = true;
      this.permisos.catTipoServicioVer = true;
      this.permisos.seguimientoCumplimientoEdit = true;
      this.permisos.seguimientoCumplimientoVer = true;
      this.permisos.vacacionesEdit = true;
      this.permisos.vacacionesVer = true;
    } else {
      this.permisos.usuariosListEdit = false;
      this.permisos.usuariosListVer = false;
      this.permisos.usuariosRegEdit = false;
      this.permisos.usuariosRegVer = false;
      this.permisos.catDepartamentosEdit = false;
      this.permisos.catDepartamentosVer = false;
      this.permisos.catOrigenesEdit = false;
      this.permisos.catOrigenesVer = false;
      this.permisos.catIndustriasEdit = false;
      this.permisos.catIndustriasVer = false;
      this.permisos.catPaisesEdit = false;
      this.permisos.catPaisesVer = false;
      this.permisos.catDivisionesEdit = false;
      this.permisos.catDivisionesVer = false;
      this.permisos.catSucursalesEdit = false;
      this.permisos.catSucursalesVer = false;
      this.permisos.catColoresEdit = false;
      this.permisos.catColoresVer = false;
      this.permisos.catServiciosEdit = false;
      this.permisos.catServiciosVer = false;
      this.permisos.catClienteEdit = false;
      this.permisos.catClienteVer = false;
      this.permisos.catTipoServicioEdit = false;
      this.permisos.catTipoServicioVer = false;
      this.permisos.seguimientoCumplimientoEdit = false;
      this.permisos.seguimientoCumplimientoVer = false;
      this.permisos.vacacionesEdit = false;
      this.permisos.vacacionesVer = false;
    }
  }

  seleccionarConsulta() {
    if (this.todo) {
      this.permisos.usuariosListVer = false;
      this.permisos.usuariosRegVer = false;
      this.permisos.catDepartamentosVer = false;
      this.permisos.catOrigenesVer = false;
      this.permisos.catIndustriasVer = false;
      this.permisos.catPaisesVer = false;
      this.permisos.catDivisionesVer = false;
      this.permisos.catSucursalesVer = false;
      this.permisos.catColoresVer = false;
      this.permisos.catServiciosVer = false;
      this.permisos.catClienteVer = false;
      this.permisos.catTipoServicioVer = false;
      this.permisos.seguimientoCumplimientoVer = false;
      this.permisos.vacacionesVer = false;
    } else {
      this.permisos.usuariosListVer = false;
      this.permisos.usuariosRegVer = false;
      this.permisos.catDepartamentosVer = false;
      this.permisos.catOrigenesVer = false;
      this.permisos.catIndustriasVer = false;
      this.permisos.catPaisesVer = false;
      this.permisos.catDivisionesVer = false;
      this.permisos.catSucursalesVer = false;
      this.permisos.catColoresVer = false;
      this.permisos.catServiciosVer = false;
      this.permisos.catClienteVer = false;
      this.permisos.catTipoServicioVer = false;
      this.permisos.seguimientoCumplimientoVer = false;
      this.permisos.vacacionesVer = false;
    }
  }


  seleccionarRegistro() {
    if (this.todo) {
      this.permisos.usuariosListEdit = true;
      this.permisos.usuariosRegEdit = true;
      this.permisos.catDepartamentosEdit = true;
      this.permisos.catOrigenesEdit = true;
      this.permisos.catIndustriasEdit = true;
      this.permisos.catPaisesEdit = true;
      this.permisos.catDivisionesEdit = true;
      this.permisos.catSucursalesEdit = true;
      this.permisos.catColoresEdit = true;
      this.permisos.catServiciosEdit = true;
      this.permisos.catClienteEdit = true;
      this.permisos.catTipoServicioEdit = true;
      this.permisos.seguimientoCumplimientoEdit = true;
      this.permisos.vacacionesEdit = true;
    } else {
      this.permisos.usuariosListEdit = false;
      this.permisos.usuariosRegEdit = false;
      this.permisos.catDepartamentosEdit = false;
      this.permisos.catOrigenesEdit = false;
      this.permisos.catIndustriasEdit = false;
      this.permisos.catPaisesEdit = false;
      this.permisos.catDivisionesEdit = false;
      this.permisos.catSucursalesEdit = false;
      this.permisos.catColoresEdit = false;
      this.permisos.catServiciosEdit = false;
      this.permisos.catClienteEdit = false;
      this.permisos.catTipoServicioEdit = false;
      this.permisos.seguimientoCumplimientoEdit = false;
      this.permisos.vacacionesEdit = false;
    }
  }


  seleccionarCatalogo() {
    if (this.todo) {
      this.permisos.catDepartamentosEdit = true;
      this.permisos.catDepartamentosVer = true;
      this.permisos.catOrigenesEdit = true;
      this.permisos.catOrigenesVer = true;
      this.permisos.catIndustriasEdit = true;
      this.permisos.catIndustriasVer = true;
      this.permisos.catPaisesEdit = true;
      this.permisos.catPaisesVer = true;
      this.permisos.catDivisionesEdit = true;
      this.permisos.catDivisionesVer = true;
      this.permisos.catSucursalesEdit = true;
      this.permisos.catSucursalesVer = true;
      this.permisos.catColoresEdit = true;
      this.permisos.catColoresVer = true;
      this.permisos.catServiciosEdit = true;
      this.permisos.catServiciosVer = true;
      this.permisos.catClienteEdit = true;
      this.permisos.catClienteVer = true;
      this.permisos.catTipoServicioEdit = true;
      this.permisos.catTipoServicioVer = true;
    } else {
      this.permisos.catDepartamentosEdit = false;
      this.permisos.catDepartamentosVer = false;
      this.permisos.catOrigenesEdit = false;
      this.permisos.catOrigenesVer = false;
      this.permisos.catIndustriasEdit = false;
      this.permisos.catIndustriasVer = false;
      this.permisos.catPaisesEdit = false;
      this.permisos.catPaisesVer = false;
      this.permisos.catDivisionesEdit = false;
      this.permisos.catDivisionesVer = false;
      this.permisos.catSucursalesEdit = false;
      this.permisos.catSucursalesVer = false;
      this.permisos.catColoresEdit = false;
      this.permisos.catColoresVer = false;
      this.permisos.catServiciosEdit = false;
      this.permisos.catServiciosVer = false;
      this.permisos.catClienteEdit = false;
      this.permisos.catClienteVer = false;
      this.permisos.catTipoServicioEdit = false;
      this.permisos.catTipoServicioVer = false;
    }
  }

  seleccionarUsuario() {
    if (this.todo) {
      this.permisos.usuariosListEdit = true;
      this.permisos.usuariosListVer = true;
      this.permisos.usuariosRegEdit = true;
      this.permisos.usuariosRegVer = true;
    } else {
      this.permisos.usuariosListEdit = false;
      this.permisos.usuariosListVer = false;
      this.permisos.usuariosRegEdit = false;
      this.permisos.usuariosRegVer = false;
    }
  }

  seleccionarControl() {
    if (this.todo) {
      this.permisos.seguimientoCumplimientoEdit = true;
      this.permisos.seguimientoCumplimientoVer = true;
      this.permisos.vacacionesEdit = true;
      this.permisos.vacacionesVer = true;
    } else {
      this.permisos.seguimientoCumplimientoEdit = false;
      this.permisos.seguimientoCumplimientoVer = false;
      this.permisos.vacacionesEdit = false;
      this.permisos.vacacionesVer = false;
    }
  }



  filterSelecionar(){
    switch ( this.filterPermisos ) {
      case 1:
        this.filterSeleccionarTodo();
      break;
      case 2:
        this.filterSeleccionarConsulta();
      break;
      case 3:
        this.filterSeleccionarRegistro();
      break;
      case 9:
        this.filterSeleccionarCatalogo();
      break;
      case 12:
        this.filterSeleccionarUsuario();
      break;
      case 14:
        this.filterSeleccionarControl();
      break;
    }
  }


  filterSeleccionarTodo() {
    if (  this.permisos.usuariosListEdit &&
          this.permisos.usuariosListVer &&
          this.permisos.usuariosRegEdit &&
          this.permisos.usuariosRegVer &&
          this.permisos.catDepartamentosEdit &&
          this.permisos.catDepartamentosVer &&
          this.permisos.catOrigenesEdit &&
          this.permisos.catOrigenesVer &&
          this.permisos.catIndustriasEdit &&
          this.permisos.catIndustriasVer &&
          this.permisos.catPaisesEdit &&
          this.permisos.catPaisesVer &&
          this.permisos.catDivisionesEdit &&
          this.permisos.catDivisionesVer &&
          this.permisos.catSucursalesEdit &&
          this.permisos.catSucursalesVer &&
          this.permisos.catColoresEdit &&
          this.permisos.catColoresVer &&
          this.permisos.catServiciosEdit &&
          this.permisos.catServiciosVer &&
          this.permisos.catClienteEdit &&
          this.permisos.catClienteVer &&
          this.permisos.catTipoServicioEdit &&
          this.permisos.catTipoServicioVer &&
          this.permisos.seguimientoCumplimientoEdit &&
          this.permisos.seguimientoCumplimientoVer &&
          this.permisos.vacacionesEdit &&
          this.permisos.vacacionesVer) {
  this.todo = true;
} else {
  this.todo = false;
}
  }

  filterSeleccionarConsulta() {
    if (  this.permisos.usuariosListVer &&
          this.permisos.usuariosRegVer &&
          this.permisos.catDepartamentosVer &&
          this.permisos.catOrigenesVer &&
          this.permisos.catIndustriasVer &&
          this.permisos.catPaisesVer &&
          this.permisos.catDivisionesVer &&
          this.permisos.catSucursalesVer &&
          this.permisos.catColoresVer &&
          this.permisos.catServiciosVer &&
          this.permisos.catClienteVer &&
          this.permisos.catTipoServicioVer &&
          this.permisos.seguimientoCumplimientoVer &&
          this.permisos.vacacionesVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }


  filterSeleccionarRegistro() {
    if (  this.permisos.usuariosListEdit &&
          this.permisos.usuariosRegEdit &&
          this.permisos.catDepartamentosEdit &&
          this.permisos.catOrigenesEdit &&
          this.permisos.catIndustriasEdit &&
          this.permisos.catPaisesEdit &&
          this.permisos.catDivisionesEdit &&
          this.permisos.catSucursalesEdit &&
          this.permisos.catColoresEdit &&
          this.permisos.catServiciosEdit &&
          this.permisos.catClienteEdit &&
          this.permisos.catTipoServicioEdit &&
          this.permisos.seguimientoCumplimientoEdit &&
          this.permisos.vacacionesEdit ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }


  filterSeleccionarCatalogo() {
    if (  this.permisos.catDepartamentosEdit &&
          this.permisos.catDepartamentosVer &&
          this.permisos.catOrigenesEdit &&
          this.permisos.catOrigenesVer &&
          this.permisos.catIndustriasEdit &&
          this.permisos.catIndustriasVer &&
          this.permisos.catPaisesEdit &&
          this.permisos.catPaisesVer &&
          this.permisos.catDivisionesEdit &&
          this.permisos.catDivisionesVer &&
          this.permisos.catSucursalesEdit &&
          this.permisos.catSucursalesVer &&
          this.permisos.catColoresEdit &&
          this.permisos.catColoresVer &&
          this.permisos.catServiciosEdit &&
          this.permisos.catServiciosVer &&
          this.permisos.catClienteEdit &&
          this.permisos.catClienteVer &&
          this.permisos.catTipoServicioEdit &&
          this.permisos.catTipoServicioVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }



  filterSeleccionarUsuario() {
    if (  this.permisos.usuariosListEdit &&
          this.permisos.usuariosListVer &&
          this.permisos.usuariosRegEdit &&
          this.permisos.usuariosRegVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }


  filterSeleccionarControl() {
    if (  this.permisos.seguimientoCumplimientoEdit &&
          this.permisos.seguimientoCumplimientoVer &&
          this.permisos.vacacionesEdit &&
          this.permisos.vacacionesVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

}
