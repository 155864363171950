import * as fromUsuario from '../actions';
import { Cliente } from 'src/app/models/clientes';

export interface ClienteState {
  clientes: Cliente[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: ClienteState = {
  clientes: [],
  loaded: false,
  loading: false,
  error: null
};

export function clienteReducer( state = estadoInicial, action: fromUsuario.clienteAcciones ): ClienteState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_CLIENTES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_CLIENTES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        clientes: [...action.clientes]
      };
    case fromUsuario.CARGAR_CLIENTES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_CLIENTE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_CLIENTE_SUCCESS:
      const itm = state.clientes.find( item => item.id === action.cliente.id);
      if (itm && itm.id) {
        state.clientes = state.clientes.map( item => {
          if (item.id === action.cliente.id) {
            item = {...action.cliente};
          }
          return item;
        });
      } else {
        state.clientes.push(action.cliente);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_CLIENTE_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_CLIENTE_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_CLIENTE_ESTATUS_SUCCESS:
      state.clientes = state.clientes.map( item => {
        if (item.id === action.id) {
          item.status = !item.status;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_CLIENTE_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

