import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Division } from '../models/division';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DivisionesService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }
  registerDivision(divisionToRegister: Division): Observable<any> {
		const params = JSON.stringify({division: divisionToRegister});
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/division', params, {headers});
  }
  
  getDivisiones(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
  
    return this.http.get(this.url + '/division', {headers});
   }

   getDivisionesActivas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
  
    return this.http.get(this.url + '/division/activas', {headers});
   }

   updateEstado(id): Observable<any> {
    const params = JSON.stringify(id);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.put(this.url + '/division', params, {headers});
   }

}