<app-page-title [heading]="title" [icon]="'pe-7s-id icon-gradient bg-dark'"
  [subheading]="'Editado de los permisos de usuarios'"></app-page-title>
<form #formPermisos="ngForm" name="formPermisos" (ngSubmit)="registrarPermisos()" autocomplete="off">
	<div class="row">
		<div class="col-lg-6">
			<div class="main-card mb-3 card card-shadow-primary">
				<div class="card-header">
					<div class="card-header-title">Captura la información del tipo de usuario</div>
				</div>
				<div class="card-body">
					<fieldset class="form-group required">
						<label for="contactosOportunidad" class="control-label">Nombre de perfil</label>
						<input type="text" class="form-control" title="Nombre de tipo de perfil de usuario" placeholder="Nombre de perfil..." id="nombrePermiso" name="nombrePermiso" #nombrePermiso="ngModel" [(ngModel)]="tipoSelected.nombre" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" required>
					</fieldset>
					<div class="row">
						<div class="col-6">
							<fieldset class="form-group required">
								<label for="contactosOportunidad" class="control-label">¿Quién puedes ver?</label>
								<div class="vsm-list">
									<div class="vsm-item">
										<a class="vsm-link">
											<span class="vsm-title">
												<div class="widget-content p-0">
													<div class="widget-content-wrapper">
														<div class="widget-content-left">
															<div class="custom-checkbox custom-control">
																<input type="checkbox" id="selectCheckOpcionesTodoVer" class="custom-control-input" name="selectCheckOpcionesTodoVer" #selectCheckOpcionesTodoVer="ngModel" [(ngModel)]="todoVer" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="seleccionarVer()">
																<label class="custom-control-label" for="selectCheckOpcionesTodoVer">&nbsp; Seleccionar todo</label>
															</div>
														</div>
													</div>
												</div>
											</span>
										</a>
									</div>
									<div class="vsm-item" *ngFor="let item of tiposVer">
										<a class="vsm-link">
											<span class="vsm-title">
												<div class="todo-indicator bg-warning"></div>
												<div class="widget-content p-0">
													<div class="widget-content-wrapper">
														<div class="widget-content-left">
															<div class="custom-checkbox custom-control">
																<input type="checkbox" id="selectCheckOpcionesTiposVer{{item.id}}" class="custom-control-input" name="selectCheckOpcionesTiposVer{{item.id}}" #selectCheckOpcionesTiposVer{{item.id}}="ngModel" [(ngModel)]="item.checked" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="changeTiposVer()">
																<label class="custom-control-label" for="selectCheckOpcionesTiposVer{{item.id}}">&nbsp; {{item.nombre}}</label>
															</div>
														</div>
													</div>
												</div>
											</span>
										</a>
									</div>
								</div>
							</fieldset>
						</div>
						<div class="col-6">
							<fieldset class="form-group required">
								<label for="contactosOportunidad" class="control-label">¿Quién te puede ver?</label>
								<div class="vsm-list">
									<div class="vsm-item">
										<a class="vsm-link">
											<span class="vsm-title">
												<div class="widget-content p-0">
													<div class="widget-content-wrapper">
														<div class="widget-content-left">
															<div class="custom-checkbox custom-control">
																<input type="checkbox" id="selectCheckOpcionesTodo" class="custom-control-input" name="selectCheckOpcionesTodo" #selectCheckOpcionesTodo="ngModel" [(ngModel)]="todo" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="seleccionar()">
																<label class="custom-control-label" for="selectCheckOpcionesTodo">&nbsp; Seleccionar todo</label>
															</div>
														</div>
													</div>
												</div>
											</span>
										</a>
									</div>
									<div class="vsm-item" *ngFor="let item of tipos">
										<div class="todo-indicator bg-warning"></div>
										<a class="vsm-link">
											<div class="todo-indicator bg-warning"></div>
											<span class="vsm-title">
												<div class="todo-indicator bg-warning"></div>
												<div class="widget-content p-0">
													<div class="todo-indicator bg-warning"></div>
													<div class="widget-content-wrapper">
														<div class="todo-indicator bg-warning"></div>
														<div class="widget-content-left">
															<div class="custom-checkbox custom-control">
																<input type="checkbox" id="selectCheckOpcionesTipos{{item.id}}" class="custom-control-input" name="selectCheckOpcionesTipos{{item.id}}" #selectCheckOpcionesTipos{{item.id}}="ngModel" [(ngModel)]="item.checked" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="changeTipos()">
																<label class="custom-control-label" for="selectCheckOpcionesTipos{{item.id}}">&nbsp; {{item.nombre}}</label>
															</div>
														</div>
													</div>
												</div>
											</span>
										</a>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
				</div>
				<div class="grid-menu grid-menu-2col border-top">
					<div class="no-gutters row">
						<div class="col-sm-6" *ngIf="identity.permisos.empresasListVer || identity.permisos.empresasListEdit">
							<a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger" [routerLink]="['/auth/config/usuarios']">
								<i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
							</a>
						</div>
						<div class="col-sm-6" *ngIf="identity.permisos && identity.permisos.empresasRegEdit">
							<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" *ngIf="(!loading) || (tipoSelected.id!==1 && tipoSelected.id!==5)" [disabled]="!formPermisos.valid || ((permisos | json) === (permisosInicial | json) &&
																																																																																									  tipoSelected.id > 0 && (tiposVer | json) === (tiposVerInicial | json) && 
																																																																																									(tipos | json) === (tiposInicial | json))">
								<i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
							</button>
							<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="loading">
								<span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
								<br>
								Loading...
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="main-card mb-3 card">
				<div class="card-header">Permisos de usuario</div>
				<div class="card-body p-0">
					<ngb-tabset class="tabs-animated tabs-animated-line" justify="justified">
						<ngb-tab>
							<ng-template ngbTabTitle><span>TODOS</span></ng-template>
							<ng-template ngbTabContent>
								<app-usuario-config-todos (messageEvent)="receiveMessage($event)" [tipoSelected]="tipoSelected" [permisos]="permisos"></app-usuario-config-todos>
							</ng-template>
						</ngb-tab>
						<ngb-tab>
							<ng-template ngbTabTitle><span>Administración</span></ng-template>
							<ng-template ngbTabContent>
								<app-usuario-config-admin (messageEvent)="receiveMessage($event)" [tipoSelected]="tipoSelected" [permisos]="permisos"></app-usuario-config-admin>
							</ng-template>
						</ngb-tab>
						<ngb-tab>
							<ng-template ngbTabTitle><span>SMIE</span></ng-template>
							<ng-template ngbTabContent>
								<app-usuario-config-smie (messageEvent)="receiveMessage($event)" [tipoSelected]="tipoSelected" [permisos]="permisos"></app-usuario-config-smie>
							</ng-template>
						</ngb-tab>
						<ngb-tab>
							<ng-template ngbTabTitle><span>CRM</span></ng-template>
							<ng-template ngbTabContent>
								<app-usuario-config-crm (messageEvent)="receiveMessage($event)" [tipoSelected]="tipoSelected" [permisos]="permisos"></app-usuario-config-crm>
							</ng-template>
						</ngb-tab>
						<ngb-tab>
							<ng-template ngbTabTitle><span>Ticket</span></ng-template>
							<ng-template ngbTabContent>
								<app-usuario-config-ticket (messageEvent)="receiveMessage($event)" [tipoSelected]="tipoSelected" [permisos]="permisos"></app-usuario-config-ticket>
							</ng-template>
						</ngb-tab>
					</ngb-tabset>
				</div>
			</div>
		</div>
	</div>
</form>