import * as fromUsuario from '../actions';
import { Reporte, Excel } from 'src/app/models/reporte-actividades';
import { Departamento } from 'src/app/models/departamento';
import { Cumplimiento, Labels, Justificacion } from 'src/app/models/cumplimiento';

export interface CumplimientoState {
  reporte: Reporte[];
  excel: Excel[];
  areas: Departamento[];
  totalCumplimiento: number;
  totalFiltroCumplimiento: number;
  cumplimiento: Cumplimiento[];
  labels: Labels[];
  justificacion: Justificacion;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: CumplimientoState = {
  reporte: [],
  excel: [],
  areas: [],
  totalCumplimiento: 0,
  totalFiltroCumplimiento: 0,
  cumplimiento: [],
  labels: [],
  justificacion: new Justificacion(null),
  loaded: false,
  loading: false,
  error: null
};

export function cumplimientoReducer( state = estadoInicial, action: fromUsuario.cumpliminetoAcciones ): CumplimientoState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_REPORTE_ACTIVIDADES:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_REPORTE_ACTIVIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        reporte: [...action.reporte],
        excel: [...action.excel],
        areas: [...action.areas]
        };
    case fromUsuario.CARGAR_REPORTE_ACTIVIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.CARGAR_CUMPLIMIENTO:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_CUMPLIMIENTO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        cumplimiento:[...action.cumplimiento],
        labels: [...action.labels],
        totalCumplimiento: action.total,
        totalFiltroCumplimiento: action.totalFiltro
        };
    case fromUsuario.CARGAR_CUMPLIMIENTO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.ACTUALIZAR_CUMPLIMIENTO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZAR_CUMPLIMIENTO_SUCCESS:
      const ind = state.labels.findIndex(itm => itm === action.justificacion.fecha);
      if(ind > -1){
        state.cumplimiento = state.cumplimiento.map(item => {
          if(item.dataSet && item.dataSet.id === action.justificacion.id){
            if(action.justificacion.valor == 55){
              item.dataSet.data[ind] = 20;
            }
            if(action.justificacion.valor == 20){
              item.dataSet.data[ind] = 55;
            }
          }
          return item;
        });   
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZAR_CUMPLIMIENTO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}



