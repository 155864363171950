<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="col-sm-12 col-md-12 col-lg-12">
  <form #registerForm="ngForm" (ngSubmit)="onSubmit(registerForm ,content6)" class="col-lg-12">
    <div class="row">
      <div class="col-md-4">
        <div class="main-card mb-3 card">
          <div class="card-header">
            Captura la información del indicador
          </div>
          <div class="card-body">
            <fieldset class="form-group required">
              <label  class="control-label">Nombre</label>
              <input type="text" name="nombre" #nombre="ngModel" [(ngModel)]="indicador.nombre"  class="form-control" maxlength="50" minlength="2" placeholder="Nombre del indicador" title="Capture el nombre personal." required  autocomplete="off"/>
              <span *ngIf="!nombre.valid && nombre.touched"> El nombre es obligatorio</span>
            </fieldset> 
            <fieldset class="form-group required">
              <label for="tipoUser" class="control-label">Tipo de indicador: </label>
              <select class="form-control" id="tipo"  name="tipo" #tipo= "ngModel" [(ngModel)]="indicador.tipo">
                <option *ngFor="let tipo of tipo_indicadores" [value]="tipo.id">{{tipo.nombre}}</option>
              </select>
            </fieldset>
            <fieldset class="form-group" [ngClass]="{'required': indicador.id === 0}">
              <label  class="control-label">Fecha de inicio</label>
              <input type="text" placeholder="Fecha" class="form-control" name="dia" #dia="ngModel" bsDatepicker [(ngModel)]="indicador.dia" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" [minDate]="minDate"  autocomplete="off" [disabled]="indicador.id > 0" >
            </fieldset>
            <fieldset class="form-group required">
              <label  class="control-label">Frecuencia</label>
              <div class="row">
                <div class="col-sm-6">
                  <input type="number" name="frecuencia" #frecuencia="ngModel" [(ngModel)]="indicador.valor" class="form-control" maxlength="50" minlength="2" placeholder="frecuencia del indicador" title="Capture el frecuencia." required />
                  <span *ngIf="!frecuencia.valid && frecuencia.touched"> La frecuencia es obligatorio</span>
                </div>
                <div class="col-sm-6">
                  <select class="form-control"  name="opcion" #opcion= "ngModel" [(ngModel)]="indicador.opcion">
                    <option value="1">Días</option>
                    <!-- <option value="2">Semana</option> -->
                    <option value="3">Meses</option>
                    <option value="4">Años</option>
                  </select>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-group required">
              <label  class="control-label">Objetivo</label>
              <input type="number" name="objetivo" #objetivo="ngModel" [(ngModel)]="indicador.objetivo"  class="form-control" placeholder="objetivo del indicador" title="Capture el objetivo personal." required />
              <span *ngIf="!objetivo.valid && objetivo.touched"> El Objetivo es obligatorio</span>
            </fieldset>
            <fieldset class="form-group required">
              <label  class="control-label">Unidad</label>
              <!-- <input type="text" name="unidad" #unidad="ngModel" [(ngModel)]="indicador.unidad" class="form-control" placeholder="unidad del indicador" title="Capture la unidad." required /> -->
              <select class="form-control"   name="unidad" #unidad="ngModel"  [(ngModel)]="indicador.unidad">
                <option value=" "></option>
                <option value="%">%</option>
                <option value="$">$</option>
              </select>
              <span *ngIf="!unidad.valid && unidad.touched"> La Unidad es obligatorio</span>
            </fieldset>
            <fieldset class="form-group required">
              <label  class="control-label">Campo del resultado</label>
              <input type="text" name="resultado" #resultado="ngModel" [(ngModel)]="indicador.resultado" class="form-control"  placeholder="resultado del indicador" title="Capture el campo del resultado." required />
              <span *ngIf="!resultado.valid && resultado.touched"> El Campo del resultado es obligatorio</span>
            </fieldset>
          </div>
          <div class="grid-menu grid-menu-2col border-top">
            <div class="no-gutters row">
              <div class="col-sm-6" *ngIf="identity.permisos.indicadorListVer">
                <a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger" [routerLink]="['/auth/indicador']">
                  <i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
                </a>
              </div>
              <div class="col-sm-6" *ngIf="identity.permisos && identity.permisos.indicadorRegEdit">
                <button type="submit" class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" *ngIf="!loading" [disabled]="(!registerForm.form.valid || !indicador.colores.length || !indicador.asignados.length || !indicador.variable.length || !identity.permisos.indicadorRegEdit) || ((indicador | json) === (indicadorInicial | json))">
                  <i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
                </button>
                <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="loading">
                  <span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
                  <br>
                  Loading...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group ">
          <div class="main-card mb-6 card col-md-12">
            <div class="card-header">
              Fórmula del indicador
            </div>
            <section class="card-body">
              <fieldset class="form-group required"> 
                <div class="wrapper form-group">
                  <label  class="control-label">Fórmula: </label>
                  <div class="row">
                    <div class="col-sm-10">
                      <div id="math-field" ></div>
                      <textarea  tabindex=0 id="substitue-id" name="formula" #formula="ngModel" [(ngModel)]="indicador.latex" ></textarea>	
                    </div>
                    <div class="col-sm-2">
                      <button type="button" class="mr-2 btn-icon btn-icon-only btn btn-outline-primary" [disabled]="!identity.permisos.indicadorRegEdit" (click)="detectarVariable()">
                        <i class="fa fa-plus btn-icon-wrapper"> </i>
                        <span class="sr-only">Agregar</span>
                      </button>
                    </div>
                  </div>
                </div>
                <span *ngIf="!formula.valid && formula.touched"> La Fórmula es obligatoria</span>
                <fieldset class="form-group required">
                  <div  *ngIf='indicador.variable'>
                    <div class="wrapper" *ngFor="let varible of indicador.variable ; let i = index">
                      <div class="row ">
                        <div class="col-sm-6">
                          <div class=" input-group input-group-sm"> 
                            <div class=" input-group-prepend">
                              <label class="control-label input-group-text"><b>{{varible.variable}}:</b></label>
                            </div>
                            <input type="text"  class="form-control" name="nombre-{{i}}" #nombre="ngModel" [(ngModel)]="varible.nombre" placeholder="Nombre de la variable" title="Capture el nombre de la variable." required  autocomplete="off"/>
                          </div>
                        </div>
                        <div class="col-sm-6 row">
                          <div class="form-group col-sm-6" >
                            <!-- <mat-checkbox name="constante-{{i}}" #constante="ngModel" [(ngModel)]="varible.constante" >Constante</mat-checkbox> -->
                            <bSwitch
                            name="constante-{{i}}" 
                            #constante="ngModel" 
                            [(ngModel)]="varible.constante"
                            [switch-size]="'small'"
                            [switch-on-text]="'Constante'"
                            [switch-off-text]="'Variable'"
                            [switch-on-color]="'danger'"
                            [switch-off-color]="'success'"
                          ></bSwitch>
                          </div>
                          <div class="col-sm-4 form-group" *ngIf="varible.constante == true"> 
                            <input type="number"  class="form-control form-control-sm" name="valor-{{i}}" #valor="ngModel" [(ngModel)]="indicador.variable[i].valor" placeholder="Valor de la variable" title="Capture el valor de la variable." required  autocomplete="off"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </fieldset>
            </section>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="main-card mb-3 card col-md-12">
              <div class="card-header">
                Responsables
              </div>
              <section class="card-body">
                <fieldset class="form-group required">
                  <label for="areaSelect" class="control-label">Área: </label>
                  <div class="form-group">
                    <ng-select [items]="areas"
                      bindLabel="nombre"
                      bindValue="id"
                      placeholder="departamento"
                      [(ngModel)]="area" id="areaSelect"  name="areaSelect" #areaSelect= "ngModel" (blur)="agregarResponsable(area)">
                    </ng-select>
                  </div>
                  <div class="form-group" *ngIf="areas" >
                    <label for="usuarioArea" class="control-label">Usuarios: </label>
                    <div class="row">
                      <div class="col-sm-10">
                        <ng-select [items]="usuariosArea"
                          bindLabel="nombre"
                          bindValue="id"
                          placeholder="departamento"
                          [(ngModel)]="usuarioSelect" id="usuarioArea"  name="usuarioArea" #usuarioArea= "ngModel"  >
                        </ng-select>
                      </div>
                      <div class="col-sm-1">
                        <button type="button" class="mr-2 btn-icon btn-icon-only btn btn-outline-primary" [disabled]="!identity.permisos.indicadorRegEdit" (click)="agregarUsuarios(usuarioSelect)">
                          <i class="fa fa-plus btn-icon-wrapper"> </i>
                          <span class="sr-only">Agregar</span>
                        </button> 	
                      </div>
                    </div>	
                  </div>
                  <div *ngFor = "let asignadas of AreasAsignadas">
                    <div *ngFor = "let area of areas ">
                      <div class="main-card mb-3 card no-shadow card-border" *ngIf="area.id == asignadas.id">
                        <div class="card-header">
                          <div class="card-header-title font-size-lg text-capitalize font-weight-normal">{{area.nombre}}</div>
                        </div>
                        <div class="card-body p-0">
                          <ul class="todo-list-wrapper list-group list-group-flush">
                            <li class="list-group-item" *ngFor="let asignados of indicador.asignados | multiOpcionesSelected : { opciones: [{ id: area.id, nombre: area.nombre, checked: true} ], parametro: 'idArea'}  ; let i = index">
                              <div class="widget-content p-0" >
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="widget-description text-focus"> {{asignados.usuario}} </div>
                                  </div>
                                  <div class="widget-content-right widget-content-actions">
                                    <button type="button" (click)="deleteUsuario(asignados.idUsuario)" class="border-0 btn-transition btn btn-outline-danger">
                                      <i class="fas fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </section>
            </div>
          </div>
          <div class="col-md-6">
            <div class="main-card mb-3 card col-md-12">
              <div class="card-header">
                Condicionales
              </div>
              <section class="card-body">
                <form #registerColor="ngForm" (ngSubmit)="agregarColor()" class="col-lg-12 form-group">
                  <div class="row ">
                    <div class="col-sm-6">
                      <fieldset class="form-group required">
                        <label for="minimo" class="control-label">Mínimo: </label>
                        <input type="number" name="minimo" #minimo="ngModel" [(ngModel)]="aggColor.minimo" class="form-control" placeholder="mínimo" title="Capture el mínimo del indicador." required />
                        <span *ngIf="!minimo.valid && minimo.touched"> El mínimo es obligatorio</span>
                      </fieldset>
                    </div>
                    <div class="col-sm-6">
                      <fieldset class="form-group required">
                        <label for="maximo" class="control-label">Máximo: </label>
                        <input type="number" name="maximo" #maximo="ngModel" [(ngModel)]="aggColor.maximo" class="form-control" placeholder="máximo" title="Capture el máximo del indicador." required />
                        <span *ngIf="!maximo.valid && maximo.touched"> El máximo es obligatorio</span>
                      </fieldset>
                    </div>
                  </div>
                    <div class="row">
                      <div class="col-sm-10">
                        <fieldset class="form-group required">
                          <label for="color" class="control-label">Color: </label>
                          <ng-select [items]="colores"
                              bindLabel="nombre"
                              bindValue="color"
                              placeholder="color"
                              [(ngModel)]="aggColor.color" id="color"  name="color" #color= "ngModel" required>
                          </ng-select>
                        </fieldset>
                      </div>
                      <div class="col-sm-2">
                        <label for="color" class="control-label" style="color: #f1f4f6;">. </label>
                        <button type="submit" class="mr-2 btn-icon btn-icon-only btn btn-outline-primary" [disabled]="!registerColor.form.valid || !identity.permisos.indicadorRegEdit">
                          <i class="fa fa-plus btn-icon-wrapper"> </i>
                          <span class="sr-only">Agregar</span>
                        </button>
                      </div>
                  </div>  
                </form>
                <table class="align-middle mb-0 table table-bordered" *ngIf="(indicador.colores).length > 0">
                  <thead>
                    <th >Mínimo</th>
                    <th >Máximo</th>
                    <th >Color</th>
                    <th class="text-center" width="100">Acciones</th>
                  </thead>
                  <tbody>
                    <tr *ngFor = "let color of indicador.colores">
                      <td >{{color.minimo}}</td>
                      <td >{{color.maximo}}</td>
                      <td [style.background]="color.color"></td>
                      <td class="text-center">
                      <button type="button" class="mr-2 btn-icon btn-icon-only btn btn-outline-danger" [disabled]="!identity.permisos.indicadorRegEdit" (click)="deleteColor(color.color)">
                        <i class="pe-7s-trash btn-icon-wrapper"> </i>
                        <span class="sr-only">Eliminar</span>
                      </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>  
</div>
<div class="wrapper" style="color: #f1f4f6;">
  Rendered Latex:
  <div id="latex" style="color: #f1f4f6;"></div>
</div>


<ng-template #content6 let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Responsable de captura</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      El indicador <b>{{indicador.nombre}}</b> tiene como fecha limite de captura el dia <b>{{indicador.fechaLimite | amDateFormat: 'DD/MM/YYYY'}}</b>. ¿Desea que los usuarios actuales capturen este periodo o el próximo?.
    </p>
    <div class="row">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" [value]="2" id="periodoActual" name="periodoActual" #periodoActual="ngModel" [(ngModel)]="responsable">
        <label class="form-check-label" for="periodoActual"> Periodo actual</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" [value]="3" id="periodoProximo" name="periodoProximo" #periodoProximo="ngModel" [(ngModel)]="responsable">
        <label class="form-check-label" for="periodoProximo"> Próximo periodo</label>
      </div>
      <!-- <div class="col-md-4 form-group">
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"  [(ngModel)]="responsable">
          <mat-radio-button class="example-radio-button" value="2">Periodo actual</mat-radio-button>
          <mat-radio-button class="example-radio-button" value="3">Próximo periodo</mat-radio-button>
        </mat-radio-group>
      </div> -->
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cerrar</button>
    <button type="button" class="btn btn-primary" (click)="usuariosNuevos() ">Guardar cambios</button>
  </div>
</ng-template>