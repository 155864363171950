 
 import { Injectable } from '@angular/core';
 import { Observable } from 'rxjs';
 import { GENERALES } from 'src/app/models/generales';
 
 @Injectable({
   providedIn: 'root'
 })
 export class EcuacionesService {
   
    
   constructor() {
  }
   
   sacarformula(latexSpan,latex){
    
    var dividir = 1;
    var enter;
    var formula = '';
    var guarEnter = '';
    var guarBlanco = '';
    var estatusEnter = true;
    var estatusBlanco = true;
    var simbolo = 0;
    var editarDividir = [];
    var variables = [];
    var parentesisFormula = '';
    var parentesisLatex = '1';
    var id = 0;
    var contador = 0;
    var formulanue = '';
    var formulaInicio = '';
    var formulaFinal = '';
    var faltaAbrrir = false;
    var func = false;
    var id2 = 0;
    var cambioFormula;
    var seguimiento = 0; 
    var parentesisIgual = false;
    var idcontador = 0;
    var elevar = false;
    var form;
    var errorFormula = false;

    if(latex[1]=='f'){
      if(latex[2]=='r'){
        if(latex[3]=='a'){
          if(latex[4]=='c'){
            dividir = 2;
            formula = '(';
            if(latex[7]=='f'){
              if(latex[8]=='r'){
                if(latex[9]=='a'){
                  if(latex[10]=='c'){
                    formula = '((';
                  }
                }
              }
            }
          }
        }
      }
    }


  
    while(latexSpan.length > id){
       enter = true;
      if(latexSpan[id] == '+'){
        enter = false;
        formula = formula + latexSpan[id];
      }
      if(latexSpan[id] == '−'){
        enter = false;
        formula = formula + '-';
      }
      if(latexSpan[id] == '·'){
        enter = false;
        formula = formula + '*';
      }
      if(  latexSpan[id] == '0'  || latexSpan[id] == '1' 
        || latexSpan[id] == '2' || latexSpan[id] == '3' 
        || latexSpan[id] == '4' || latexSpan[id] == '5' 
        || latexSpan[id] == '6' || latexSpan[id] == '7' 
        || latexSpan[id] == '8' || latexSpan[id] == '9'){
        enter = false;
        formula = formula + latexSpan[id];
        var idNumero = id + 1;
          if(latexSpan[idNumero]=='.' || latexSpan[idNumero]==','){
            formula = formula + latexSpan[idNumero];
            idNumero = idNumero + 1;
            while(latexSpan[idNumero] == '0'  || latexSpan[idNumero] == '1' 
            || latexSpan[idNumero] == '2' || latexSpan[idNumero] == '3' 
            || latexSpan[idNumero] == '4' || latexSpan[idNumero] == '5' 
            || latexSpan[idNumero] == '6' || latexSpan[idNumero] == '7' 
            || latexSpan[idNumero] == '8' || latexSpan[idNumero] == '9'){
              formula = formula + latexSpan[idNumero];
              idNumero = idNumero + 1;
            }
            id = idNumero - 1;
          }
      }
      if( latexSpan[id] == 'a' || latexSpan[id] == 'A'
       || latexSpan[id] == 'b' || latexSpan[id] == 'B' 
       || latexSpan[id] == 'c' || latexSpan[id] == 'C'
       || latexSpan[id] == 'd' || latexSpan[id] == 'D'
       || latexSpan[id] == 'e' || latexSpan[id] == 'E'
       || latexSpan[id] == 'f' || latexSpan[id] == 'F'
       || latexSpan[id] == 'g' || latexSpan[id] == 'G'
       || latexSpan[id] == 'h' || latexSpan[id] == 'H'
       || latexSpan[id] == 'i' || latexSpan[id] == 'I'
       || latexSpan[id] == 'j' || latexSpan[id] == 'J'
       || latexSpan[id] == 'k' || latexSpan[id] == 'K'
       || latexSpan[id] == 'l' || latexSpan[id] == 'L'
       || latexSpan[id] == 'm' || latexSpan[id] == 'M'
       || latexSpan[id] == 'n' || latexSpan[id] == 'N'
       || latexSpan[id] == 'ñ' || latexSpan[id] == 'Ñ'
       || latexSpan[id] == 'o' || latexSpan[id] == 'O'
       || latexSpan[id] == 'p' || latexSpan[id] == 'P'
       || latexSpan[id] == 'q' || latexSpan[id] == 'Q'
       || latexSpan[id] == 'r' || latexSpan[id] == 'R'
       || latexSpan[id] == 's' || latexSpan[id] == 'S'
       || latexSpan[id] == 't' || latexSpan[id] == 'T'
       || latexSpan[id] == 'u' || latexSpan[id] == 'U'
       || latexSpan[id] == 'v' || latexSpan[id] == 'V'
       || latexSpan[id] == 'w' || latexSpan[id] == 'W'
       || latexSpan[id] == 'x' || latexSpan[id] == 'X'
       || latexSpan[id] == 'y' || latexSpan[id] == 'Y'
       || latexSpan[id] == 'z' || latexSpan[id] == 'Z'){
        enter = false;
        formula = formula + latexSpan[id];
        const ind = variables.findIndex( (item) => item.variable === latexSpan[id]);
        if (ind > -1) {
          }
        else{
          variables.push({id: 0, indicador: 0,variable: latexSpan[id],nombre: '',constante:false ,valor:0});
        }
      }
      if(latexSpan[id] == '√'){
        enter = false;
        formula = formula + latexSpan[id];
      }
      if(latexSpan[id] == '('){
        enter = false;
        formula = formula + latexSpan[id];
      }
      if(latexSpan[id] == ')'){
        enter = false;
        formula = formula + latexSpan[id];
      }
      if(latexSpan[id] == '{'){
        enter = false;
        formula = formula + latexSpan[id];
      }
      if(latexSpan[id] == '}'){
        enter = false;
        formula = formula + latexSpan[id];
      }
      if(latexSpan[id] == '^'){
        enter = false;
        formula = formula + latexSpan[id] ;
      }
      if(latexSpan[id] == 'π'){
        enter = false;
        formula = formula + latexSpan[id];
      }
      if(latexSpan[id] == guarBlanco){
        formula = formula + ')';
        enter = false;
      }
      if(latexSpan[id] == guarEnter){
        simbolo = id + 1;
        enter = false;
        while(latexSpan[simbolo] != guarEnter && simbolo < latexSpan.length && latexSpan[simbolo] != guarBlanco){
          simbolo = simbolo + 1;
        }
        simbolo = simbolo + 1;
        if(estatusBlanco == true){
          if(latexSpan[simbolo] != '0' && latexSpan[simbolo] != '1' 
          && latexSpan[simbolo] != '2' && latexSpan[simbolo] != '3' 
          && latexSpan[simbolo] != '4' && latexSpan[simbolo] != '5' 
          && latexSpan[simbolo] != '6' && latexSpan[simbolo] != '7' 
          && latexSpan[simbolo] != '8' && latexSpan[simbolo] != '9'
          && latexSpan[simbolo] != 'a' && latexSpan[simbolo] != 'A'
          && latexSpan[simbolo] != 'b' && latexSpan[simbolo] != 'B' 
          && latexSpan[simbolo] != 'c' && latexSpan[simbolo] != 'C'
          && latexSpan[simbolo] != 'd' && latexSpan[simbolo] != 'D'
          && latexSpan[simbolo] != 'e' && latexSpan[simbolo] != 'E'
          && latexSpan[simbolo] != 'f' && latexSpan[simbolo] != 'F'
          && latexSpan[simbolo] != 'g' && latexSpan[simbolo] != 'G'
          && latexSpan[simbolo] != 'h' && latexSpan[simbolo] != 'H'
          && latexSpan[simbolo] != 'i' && latexSpan[simbolo] != 'I'
          && latexSpan[simbolo] != 'j' && latexSpan[simbolo] != 'J'
          && latexSpan[simbolo] != 'k' && latexSpan[simbolo] != 'K'
          && latexSpan[simbolo] != 'l' && latexSpan[simbolo] != 'L'
          && latexSpan[simbolo] != 'm' && latexSpan[simbolo] != 'M'
          && latexSpan[simbolo] != 'n' && latexSpan[simbolo] != 'N'
          && latexSpan[simbolo] != 'ñ' && latexSpan[simbolo] != 'Ñ'
          && latexSpan[simbolo] != 'o' && latexSpan[simbolo] != 'O'
          && latexSpan[simbolo] != 'p' && latexSpan[simbolo] != 'P'
          && latexSpan[simbolo] != 'q' && latexSpan[simbolo] != 'Q'
          && latexSpan[simbolo] != 'r' && latexSpan[simbolo] != 'R'
          && latexSpan[simbolo] != 's' && latexSpan[simbolo] != 'S'
          && latexSpan[simbolo] != 't' && latexSpan[simbolo] != 'T'
          && latexSpan[simbolo] != 'u' && latexSpan[simbolo] != 'U'
          && latexSpan[simbolo] != 'v' && latexSpan[simbolo] != 'V'
          && latexSpan[simbolo] != 'w' && latexSpan[simbolo] != 'W'
          && latexSpan[simbolo] != 'x' && latexSpan[simbolo] != 'X'
          && latexSpan[simbolo] != 'y' && latexSpan[simbolo] != 'Y'
          && latexSpan[simbolo] != 'z' && latexSpan[simbolo] != 'Z'
          && latexSpan[simbolo] != '√' && latexSpan[simbolo] != '('
          && latexSpan[simbolo] != ')' && latexSpan[simbolo] != '{'
          && latexSpan[simbolo] != '}' && latexSpan[simbolo] != 'π'
          && latexSpan[simbolo] != guarEnter){
            guarBlanco = latexSpan[simbolo];
            estatusBlanco = false;
          }
        }
        var simb = id + 1;
        if(simbolo < latexSpan.length){
          if(latexSpan[simbolo] == guarBlanco  ){
            formula = formula + ')/(';
          }
          if(latexSpan[simbolo] != guarBlanco && latexSpan[simb] != guarBlanco){
            formula = formula + '(';
          }
        }
      }
      if(enter == true){
        if(dividir == 2){
          guarEnter = latexSpan[id];
          formula = formula + ')/(';
          estatusEnter = false;
          simbolo = id + 1;
          while(latexSpan[simbolo] != guarEnter && simbolo < latexSpan.length && latexSpan[simbolo] != guarBlanco){
            simbolo = simbolo + 1;
          }
          simbolo = simbolo + 1;
          if(estatusBlanco == true){
            if(latexSpan[simbolo] != '0'  && latexSpan[simbolo] != '1' 
            && latexSpan[simbolo] != '2' && latexSpan[simbolo] != '3' 
            && latexSpan[simbolo] != '4' && latexSpan[simbolo] != '5' 
            && latexSpan[simbolo] != '6' && latexSpan[simbolo] != '7' 
            && latexSpan[simbolo] != '8' && latexSpan[simbolo] != '9'
            && latexSpan[simbolo] != 'a' && latexSpan[simbolo] != 'A'
            && latexSpan[simbolo] != 'b' && latexSpan[simbolo] != 'B' 
            && latexSpan[simbolo] != 'c' && latexSpan[simbolo] != 'C'
            && latexSpan[simbolo] != 'd' && latexSpan[simbolo] != 'D'
            && latexSpan[simbolo] != 'e' && latexSpan[simbolo] != 'E'
            && latexSpan[simbolo] != 'f' && latexSpan[simbolo] != 'F'
            && latexSpan[simbolo] != 'g' && latexSpan[simbolo] != 'G'
            && latexSpan[simbolo] != 'h' && latexSpan[simbolo] != 'H'
            && latexSpan[simbolo] != 'i' && latexSpan[simbolo] != 'I'
            && latexSpan[simbolo] != 'j' && latexSpan[simbolo] != 'J'
            && latexSpan[simbolo] != 'k' && latexSpan[simbolo] != 'K'
            && latexSpan[simbolo] != 'l' && latexSpan[simbolo] != 'L'
            && latexSpan[simbolo] != 'm' && latexSpan[simbolo] != 'M'
            && latexSpan[simbolo] != 'n' && latexSpan[simbolo] != 'N'
            && latexSpan[simbolo] != 'ñ' && latexSpan[simbolo] != 'Ñ'
            && latexSpan[simbolo] != 'o' && latexSpan[simbolo] != 'O'
            && latexSpan[simbolo] != 'p' && latexSpan[simbolo] != 'P'
            && latexSpan[simbolo] != 'q' && latexSpan[simbolo] != 'Q'
            && latexSpan[simbolo] != 'r' && latexSpan[simbolo] != 'R'
            && latexSpan[simbolo] != 's' && latexSpan[simbolo] != 'S'
            && latexSpan[simbolo] != 't' && latexSpan[simbolo] != 'T'
            && latexSpan[simbolo] != 'u' && latexSpan[simbolo] != 'U'
            && latexSpan[simbolo] != 'v' && latexSpan[simbolo] != 'V'
            && latexSpan[simbolo] != 'w' && latexSpan[simbolo] != 'W'
            && latexSpan[simbolo] != 'x' && latexSpan[simbolo] != 'X'
            && latexSpan[simbolo] != 'y' && latexSpan[simbolo] != 'Y'
            && latexSpan[simbolo] != 'z' && latexSpan[simbolo] != 'Z'
            && latexSpan[simbolo] != '√' && latexSpan[simbolo] != '('
            && latexSpan[simbolo] != ')' && latexSpan[simbolo] != '{'
            && latexSpan[simbolo] != '}' && latexSpan[simbolo] != 'π'
            && latexSpan[simbolo] != guarEnter){
              guarBlanco = latexSpan[simbolo];
              estatusBlanco = false;
            }
          }
        }
        if(estatusEnter == true){
          guarEnter = latexSpan[id];
          formula = formula + '(';
          estatusEnter = false;
        }
      }
        id = id+1;
    }
      
    // segunda parte
    id = 0;
    while(formula.length > id){
      if(formula[id]=='('){
        contador = contador + 1;
      }
      if(formula[id]==')'){
        contador = contador - 1;
        simb = id + 1
        if(formula[simb]=='('){
          faltaAbrrir = true;
        }
      }
      if (contador == -1){
        faltaAbrrir = true;
      }
      id = id + 1; 
    }
    id = 1;

    if(contador == 0){
      idNumero = 0;
      while (formula.length > id) {
        if(formula[id] == '/'){
          func = true;
          editarDividir.push({id: idNumero})
        }
        if(formula[id] == ')' || formula[id] == '}' || formula[id] == ']'){
          idNumero = idNumero - 1;
        }
        id = id +1;
      }
      id = 1;
      if(func == true){
        if(faltaAbrrir == true){
          while(formula.length > id){
            if(formula[id] == ')' )  {
              id2 = id +1;
              if(formula[id2] == '(' ) {
                simb = id2 ;
                simbolo = id ;
                id2 = 0;
                while(formula.length > simb){
                  formulaFinal = formulaFinal + formula[simb];
                  simb = simb + 1;
                }
                while(simbolo >= id2){
                  formulaInicio = formulaInicio + formula[id2];
                  id2 = id2 + 1;
                }
                formula = formulaInicio + ')/' + formulaFinal;
              }
            } 
            id = id +1;
          }
        }
        id = 1;
        if(faltaAbrrir == false){
          while(formula.length > id){
            if(formula[id] == ')' )  {
              id2 = id +1;
              if(formula[id2] == '(' ) {
                simb = id2 ;
                simbolo = id ;
                id2 = 0;
                while(formula.length > simb){
                  formulaFinal = formulaFinal + formula[simb];
                  simb = simb + 1;
                }
                while(simbolo >= id2){
                  formulaInicio = formulaInicio + formula[id2];
                  id2 = id2 + 1;
                }
                formula = formulaInicio + ')/(' + formulaFinal;
              }
            } 
            id = id +1;
          }
        }
        // esto es para los numero sin operador
        id = 1 ;
        while(formula.length > id){
          if(formula[id] == ')'  ){
            id2 = id +1;
            simb = id - 1;
            if(formula[id2] != '*' && formula[id2] != '-' &&  formula[id2] != '+' &&  formula[id2] != '/' && formula[id2] != ')' && formula.length > id2){
              simb = id2 ;
              simbolo = id ;
              id2 = 0;
              while(formula.length > simb){
                formulaFinal = formulaFinal + formula[simb];
                simb = simb + 1;
              }
              while(simbolo >= id2){
                formulaInicio = formulaInicio + formula[id2];
                id2 = id2 + 1;
              }
              formulanue = formulaInicio + ')/(' + formulaFinal;
              cambioFormula = true;
            }
            id2 = id +1;
            simb = id - 1;
            if(formula[simb] == '*' ||  formula[simb] == '-' ||   formula[simb] == '+' ||  formula[simb] == '/'){
              simbolo = simb ;
              simb = id ;
              id2 = 0;
              while(formula.length > simb){
                formulaFinal = formulaFinal + formula[simb];
                simb = simb + 1;
              }
              while(simbolo >= id2){
                formulaInicio = formulaInicio + formula[id2];
                id2 = id2 + 1;
              }
              formulanue = formulaInicio + formulaFinal;
              cambioFormula = true;
            }
            if(cambioFormula == true){
              id = 0;
              formula = formulanue;
              cambioFormula = false;
              formulanue = '';
              formulaInicio = '';
              formulaFinal = '';
            }
          }
          if( formula[id] == '(' ){
            id2 = id +1;
            simb = id - 1;
            if(formula[simb] != '*' &&  formula[simb] != '-' &&   formula[simb] != '+' &&  formula[simb] != '/' && formula[simb] != '('){
              simbolo = simb ;
              simb = id ;
              id2 = 0;
              while(formula.length > simb){
                formulaFinal = formulaFinal + formula[simb];
                simb = simb + 1;
              }
              while(simbolo >= id2){
                formulaInicio = formulaInicio + formula[id2];
                id2 = id2 + 1;
              }
              formulanue = formulaInicio + ')/(' + formulaFinal;
              cambioFormula = true;
            }
            id2 = id +1;
            simb = id - 1;
            if(formula[id2] == '*'  ||  formula[id2] == '+' ||  formula[id2] == '/' || formula[id2] == ')'){
              simb = id2 ;
              simbolo = id ;
              id2 = 0;
              while(formula.length > simb){
                formulaFinal = formulaFinal + formula[simb];
                simb = simb + 1;
              }
              while(simbolo >= id2){
                formulaInicio = formulaInicio + formula[id2];
                id2 = id2 + 1;
              }
              formulanue = formulaInicio + formulaFinal;
              cambioFormula = true;
            }
            if(cambioFormula == true){
              id = 0;
              formula = formulanue;
              cambioFormula = false;
              formulanue = '';
              formulaInicio = '';
              formulaFinal = '';
            }
            
          } 
          id = id +1;
        }
      }
    }
    
    if(contador < 0){
      while (formula.length > id) {
        if(formula[id] == '/'){
          func = true;
        }
        id = id +1;
      }
      id = 1;
      if(func == true){
        while(formula.length > id){
          if(formula[id] == ')'){
            id2 = id +1;
            if(formula[id2] == '(' ) {
              simb = id2 ;
              simbolo = id ;
              id2 = 0;
              while(formula.length > simb){
                formulaFinal = formulaFinal + formula[simb];
                simb = simb + 1;
              }
              while(simbolo >= id2){
                formulaInicio = formulaInicio + formula[id2];
                id2 = id2 + 1;
              }
              formula = formulaInicio + ')/(' + formulaFinal;
              formulanue = '';
              formulaInicio = '';
              formulaFinal = '';
            }
          } 
          if( formula[id] == '(' ){
            id2 = id +1;
            simb = id - 1;
            if(formula[simb] != '*' &&  formula[simb] != '-' &&   formula[simb] != '+' &&  formula[simb] != '/' && formula[simb] != '('){
              simbolo = simb ;
              simb = id ;
              id2 = 0;
              while(formula.length > simb){
                formulaFinal = formulaFinal + formula[simb];
                simb = simb + 1;
              }
              while(simbolo >= id2){
                formulaInicio = formulaInicio + formula[id2];
                id2 = id2 + 1;
              }
              formulanue = formulaInicio + ')/(' + formulaFinal;
              cambioFormula = true;
            }
            id2 = id +1;
            simb = id - 1;
            if(formula[id2] == '*'  ||  formula[id2] == '+' ||  formula[id2] == '/' || formula[id2] == ')'){
              simb = id2 ;
              simbolo = id ;
              id2 = 0;
              while(formula.length > simb){
                formulaFinal = formulaFinal + formula[simb];
                simb = simb + 1;
              }
              while(simbolo >= id2){
                formulaInicio = formulaInicio + formula[id2];
                id2 = id2 + 1;
              }
              formulanue = formulaInicio + formulaFinal;
              cambioFormula = true;
            }
            if(cambioFormula == true){
              id = 0;
              formula = formulanue;
              cambioFormula = false;
              formulanue = '';
              formulaInicio = '';
              formulaFinal = '';
            }
          } 
          id = id +1;
        }
      }
    }


    if(contador > 0){
      while (formula.length > id) {
        if(formula[id] == '/'){
          func = true;
        }
        id = id +1;
      }
      id = 1;
      if(func == true){
        while(formula.length > id){
          if(formula[id] == ')' )  {
            id2 = id +1;
            if(formula[id2] == '(' ) {
              simb = id2 ;
              simbolo = id ;
              id2 = 0;
              while(formula.length > simb){
                formulaFinal = formulaFinal + formula[simb];
                simb = simb + 1;
              }
              while(simbolo >= id2){
                formulaInicio = formulaInicio + formula[id2];
                id2 = id2 + 1;
              }
              formula = formulaInicio + ')/' + formulaFinal;
            }
          } 
          id = id +1;
        }
      }
      id = 1 ;
      while(formula.length > id){
        if(formula[id] == ')' ){
          id2 = id +1;
          simb = id - 1;
          if(formula[id2] != '*' && formula[id2] != '-' &&  formula[id2] != '+' &&  formula[id2] != '/' && formula[id2] != ')' && formula.length > id2){
            simb = id2 ;
            simbolo = id ;
            id2 = 0;
            while(formula.length > simb){
              formulaFinal = formulaFinal + formula[simb];
              simb = simb + 1;
            }
            while(simbolo >= id2){
              formulaInicio = formulaInicio + formula[id2];
              id2 = id2 + 1;
            }
            formulanue = formulaInicio + ')/' + formulaFinal;
            cambioFormula = true;
          }
          id2 = id +1;
          simb = id - 1;
          if(formula[simb] == '*' ||  formula[simb] == '-' ||   formula[simb] == '+' ||  formula[simb] == '/'){
            simbolo = simb ;
            simb = id ;
            id2 = 0;
            while(formula.length > simb){
              formulaFinal = formulaFinal + formula[simb];
              simb = simb + 1;
            }
            while(simbolo >= id2){
              formulaInicio = formulaInicio + formula[id2];
              id2 = id2 + 1;
            }
            formulanue = formulaInicio + formulaFinal;
            cambioFormula = true;
          }
          if(cambioFormula == true){
            id = 0;
            formula = formulanue;
            cambioFormula = false;
            formulanue = '';
            formulaInicio = '';
            formulaFinal = '';
          }
        }
        if( formula[id] == '(' ){
          id2 = id +1;
          simb = id - 1;
          if(formula[simb] != '*' &&  formula[simb] != '-' &&   formula[simb] != '+' &&  formula[simb] != '/' && formula[simb] != '('){
            simbolo = simb ;
            simb = id ;
            id2 = 0;
            while(formula.length > simb){
              formulaFinal = formulaFinal + formula[simb];
              simb = simb + 1;
            }
            while(simbolo >= id2){
              formulaInicio = formulaInicio + formula[id2];
              id2 = id2 + 1;
            }
            formulanue = formulaInicio + ')/' + formulaFinal;
            cambioFormula = true;
          }
          id2 = id +1;
          simb = id - 1;
          if(formula[id2] == '*'  ||  formula[id2] == '+' ||  formula[id2] == '/' || formula[simb] == ')'){
            simb = id2 ;
            simbolo = id ;
            id2 = 0;
            while(formula.length > simb){
              formulaFinal = formulaFinal + formula[simb];
              simb = simb + 1;
            }
            while(simbolo >= id2){
              formulaInicio = formulaInicio + formula[id2];
              id2 = id2 + 1;
            }
            formulanue = formulaInicio + formulaFinal;
            cambioFormula = true;
          }
          if(cambioFormula == true){
            id = 0;
            formula = formulanue;
            cambioFormula = false;
            formulanue = '';
            formulaInicio = '';
            formulaFinal = '';
          }
        } 
        id = id +1;
      }
    }
    contador = 0;
    id = 0;
    while(formula.length > id){
      if(formula[id]=='('){
        contador = contador + 1;
      }
      if(formula[id]==')'){
        contador = contador - 1;
      }
      id = id + 1; 
    }
    errorFormula = false;
    if(contador < 0){
      seguimiento = formula.length - 1;
      while (parentesisFormula.length != parentesisLatex.length && errorFormula == false) {
        parentesisFormula = '';
        parentesisLatex = '';
        id = 0;
        contador = 0;
        while(formula.length > id){
          if(formula[id] == '(' || formula[id] == '{' || formula[id] == '['){
            parentesisFormula = parentesisFormula + '(';
          }
          if(formula[id] == ')' || formula[id] == '}' || formula[id] == ']'){
            parentesisFormula = parentesisFormula + ')';
          }
          id = id + 1; 
        }
        id = 0;
        while(latex.length > id){
          if(latex[id] == '(' || latex[id] == '{' || latex[id] == '['){
            parentesisLatex = parentesisLatex + '(';
          }
          if(latex[id] == ')' || latex[id] == '}' || latex[id] == ']'){
            parentesisLatex = parentesisLatex + ')';
          }
          id = id + 1; 
        }
        id = parentesisLatex.length -1;
        id2 = parentesisFormula.length -1 ;
        contador = 0;
        simb = 0;
        simbolo = 0;
        idNumero = 0;
        idcontador = id;
        if ( id != id2){
          parentesisIgual = false;
          while (id >= 0) {
            if(parentesisLatex[id] == parentesisFormula[id2] ){
              if(parentesisLatex[id] == '('){
                contador = contador + 1;
                simb = simb - 1;
              }
              if(parentesisLatex[id] == ')'){
                simb = simb + 1;
                idNumero = idNumero + 1;
              }
              if(simb == 0){
                simbolo = idNumero +1;
              }
              if( id2 == 0){
                parentesisIgual = true;
                id = 0;
                // (simb == 1 && id != idcontador) ||
              }
            }
            if(parentesisLatex[id] != parentesisFormula[id2]){
              parentesisIgual = false;
              id = 0;
            }
            id = id -1 ;
            id2 = id2 -1 ;
          }
          
          idNumero = 0;
          if(parentesisIgual == false){
            id = formula.length - 1;
            id2 = 0;
            simb = 0;
            while (contador != id2) {
              if(formula[id] == '(' || formula[id] == '{' || formula[id] == '['){
                id2 = id2 + 1;
              }
              id = id - 1;
            }   
          }
          if(parentesisIgual == true){
            id2 = 0;
            contador = 0;
            while (simb < parentesisLatex.length) {
              if(parentesisLatex[simb] == ')'){
                id2 = simb;
                simb = parentesisLatex.length;
              }
              simb = simb + 1;
            }
            id2 = id2 - 1;
            simb = parentesisLatex.length - 1;
            while (simb >= id2) {
              if(parentesisLatex[simb] == '('){
                contador = contador + 1;
              }
              simb = simb - 1;
            }
            id = formula.length - 1;
            id2 = 0;
            simb = 0;
            while (contador != id2) {
              if(formula[id] == '(' || formula[id] == '{' || formula[id] == '['){
                id2 = id2 + 1;
              }
              id = id - 1;
            }  
          }
          id = id + 1;
          idNumero = idNumero - 1;
          parentesisLatex = '';
          formulaFinal = '';
          formulaInicio = '';
          simb = 0;
          while (simb < id) {
            formulaInicio = formulaInicio + formula[simb];
            simb = simb + 1;
          }
          while (id < formula.length) {
            formulaFinal = formulaFinal + formula[id];
            id = id + 1;
          }
          formula = formulaInicio + '('  + formulaFinal;
          parentesisFormula = '';
          parentesisLatex = '';
          id = 0;
          contador = 0;
          while(formula.length > id){
            if(formula[id] == '(' || formula[id] == '{' || formula[id] == '['){
              parentesisFormula = parentesisFormula + '(';
            }
            if(formula[id] == ')' || formula[id] == '}' || formula[id] == ']'){
              parentesisFormula = parentesisFormula + ')';
            }
            id = id + 1; 
          }
          id = 0;
          while(latex.length > id){
            if(latex[id] == '(' || latex[id] == '{' || latex[id] == '['){
              parentesisLatex = parentesisLatex + '(';
            }
            if(latex[id] == ')' || latex[id] == '}' || latex[id] == ']'){
              parentesisLatex = parentesisLatex + ')';
            }
            id = id + 1; 
          }
          if( parentesisLatex.length < parentesisFormula.length){
            errorFormula = true;            
          }
        }
      }
    }

    if(errorFormula == true){
      alert('Formula no especificada');
    }

    id = 0;
    elevar = false;
    while (id < latex.length) {
      if(latex[id] == '^'){
        elevar = true;
        id2 = id;
        id =  latex.length;
      }
      id = id + 1;
    }

    if(elevar == true){
      idcontador = 0;
      while (idcontador < latex.length) {
        if(latex[idcontador] == '^'){
          formulaInicio = '';
          formulaFinal = '';
          formulanue = '';
          form = '';
          id = idcontador;
          while (id >= 0) {
            idNumero = id - 1;
            if(latex[id] == 't' && latex[idNumero] == 'o'){
              idNumero = id;
              id = 0;
            }
            if( latex[id] == '+' || latex[id] == '-' || latex[id] == '}'){
              idNumero = id;
              id = 0;
            }
            id = id - 1;
          }
          id = idNumero + 1;
          while (latex[id] != '^' ) {
            formulaInicio = formulaInicio + latex[id];
            id = id + 1;
          }

          id = idcontador + 1;
          if(latex[id] == '{'){
            idNumero = id + 1;
            while (latex[idNumero] != '}' ) {
              formulaFinal = formulaFinal + latex[idNumero];
              idNumero = idNumero + 1;
            }
            
            formulanue = formulaInicio + '^'+ '(' + formulaFinal + ')';
            form = formulaInicio + formulaFinal;           
          }
          if(latex[id] != '{'){
            formulanue = formulaInicio + '^' + latex[id] ;
            form = formulaInicio + latex[id];
          }
          id = form.length - 1;
          id2 = formula.length - 1;
          while (id2 >= 0) {
            if(formula[id2] == form[id]){
              simb = id - 1;
              simbolo = id2 - 1;
              while (formula[simbolo] == form[simb] && simb >= 0) {
                simb = simb - 1;
                simbolo = simbolo - 1;
              }
              if(simb == -1){
                idNumero = simbolo;
                id = id2 + 1;
                id2 = 0
              
              }
            }
            id2 = id2 - 1;
          }
          id2 = 0;
          formulaInicio = '';
          formulaFinal = '';
          while (id2 <= idNumero) {
            formulaInicio = formulaInicio + formula[id2];
            id2 = id2 + 1;
          }
          while (id < formula.length) {
            formulaFinal = formulaFinal + formula[id];
            id = id + 1;
          }
          formula = formulaInicio + formulanue + formulaFinal;
        }
        idcontador = idcontador + 1;
      }
    }
    return {variables: variables, formuala : formula}
   }
   
   resolverFormula(formula,variables):Observable<any> {
     
    var id = 0;
    var  simb = 0;
    var  simbolo = 0;
    var  idNumero = 0;
    var inicio = 0
    var final = 0;
    var id2;
    var resultado = 0;
    var operacion = false;
    var simboloInicio = false;
    var sumaInicio = false;
    var idoperacion;
    var menosInicio = false;
    var menosFinal = false;
    var formulanue = '';
    var formulaInicio = '';
     var formulaFinal = '';


     while (id< formula.length ) {
        if(formula[id] == 'a' || formula[id] == 'A'
        || formula[id] == 'b' || formula[id] == 'B' 
        || formula[id] == 'c' || formula[id] == 'C'
        || formula[id] == 'd' || formula[id] == 'D'
        || formula[id] == 'e' || formula[id] == 'E'
        || formula[id] == 'f' || formula[id] == 'F'
        || formula[id] == 'g' || formula[id] == 'G'
        || formula[id] == 'h' || formula[id] == 'H'
        || formula[id] == 'i' || formula[id] == 'I'
        || formula[id] == 'j' || formula[id] == 'J'
        || formula[id] == 'k' || formula[id] == 'K'
        || formula[id] == 'l' || formula[id] == 'L'
        || formula[id] == 'm' || formula[id] == 'M'
        || formula[id] == 'n' || formula[id] == 'N'
        || formula[id] == 'ñ' || formula[id] == 'Ñ'
        || formula[id] == 'o' || formula[id] == 'O'
        || formula[id] == 'p' || formula[id] == 'P'
        || formula[id] == 'q' || formula[id] == 'Q'
        || formula[id] == 'r' || formula[id] == 'R'
        || formula[id] == 's' || formula[id] == 'S'
        || formula[id] == 't' || formula[id] == 'T'
        || formula[id] == 'u' || formula[id] == 'U'
        || formula[id] == 'v' || formula[id] == 'V'
        || formula[id] == 'w' || formula[id] == 'W'
        || formula[id] == 'x' || formula[id] == 'X'
        || formula[id] == 'y' || formula[id] == 'Y'
        || formula[id] == 'z' || formula[id] == 'Z'){
  
          if (variables.length) {
            const index = GENERALES.indexOfObject(variables , 'variable', formula[id]);
            if (index > -1) {
              const ind = variables.findIndex( (item) => item.variable === formula[id] );
              if (ind > -1) {
                simb = 0;
                formulaInicio = '';
                formulaFinal = '';
                while (simb < id) {
                  formulaInicio = formulaInicio + formula[simb];
                  simb = simb + 1;
                }
                simb = id + 1;
                while (simb < formula.length) {
                  formulaFinal = formulaFinal + formula[simb];
                  simb = simb + 1;
                }
                formula = formulaInicio + variables[ind].valor + formulaFinal;
                id = -1
              }
            }
          }
        }
        id = id + 1;
      }

    id = 0;
    formulaInicio = '';
    formulaFinal = '';
    simb = 0;

    while (id < formula.length) {
      if(formula[id] == '(' || formula[id] == '{' || formula[id] == '['){
        idNumero = 2;
        simbolo = id;
        id = formula.length;
      }
      id = id + 1;
    }
    if(idNumero == 2){
      id = simbolo - 1;
      simbolo = 1;
      while (id < formula.length) {
        if(formula[id] == '(' || formula[id] == '{' || formula[id] == '['){
          simbolo = id;
          idoperacion = id;
        }
        if(formula[id] == ')' || formula[id] == '}' || formula[id] == ']'){
          simb = idoperacion - 1;
          idoperacion = idoperacion + 1;
          operacion = false;
          formulanue = '';
          while (idoperacion < id) {
            formulanue = formulanue + formula[idoperacion];
            idoperacion = idoperacion + 1;
          }
          idoperacion = 0;
          while (idoperacion < formulanue.length) {
            if(formulanue[idoperacion] == '/' || formulanue[idoperacion] == '+' || formulanue[idoperacion] == '-' || formulanue[idoperacion] == '*'){
              operacion = true;
              idoperacion = formulanue.length;
            }
            idoperacion = idoperacion + 1;
          }
          
          if(operacion == true){
            idoperacion = 0;
            formulaFinal = '';
            formulaInicio = '';
            while (idoperacion < formulanue.length) {
              if(formulanue[idoperacion] == '^'){
                id2 = idoperacion +1;
                formulaFinal  ='';
                formulaInicio = '';
                menosFinal = false;
                if(formulanue[id2] == '-'){
                  idNumero = id2 + 1;
                  menosFinal = true;
                  while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' && formulanue[idNumero] != '*' && idNumero < formulanue.length) {
                    formulaFinal = formulaFinal + formulanue[idNumero];
                    idNumero = idNumero + 1;
                  }
                }
                if(formulanue[id2] != '-'){
                  idNumero = id2;
                  while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' && formulanue[idNumero] != '*' && idNumero < formulanue.length) {
                    formulaFinal = formulaFinal + formulanue[idNumero];
                    idNumero = idNumero + 1;
                  }
                }
                id2 = idNumero;
                final = parseFloat(formulaFinal);
                idNumero = idoperacion -1;
                while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' &&  formulanue[idNumero] != '*' && idNumero >= 0) {
                  idNumero = idNumero - 1;
                }
                simb = idNumero + 1;
                while (simb < idoperacion) {
                  formulaInicio = formulaInicio + formulanue[simb];
                  simb = simb + 1;
                }
                inicio = parseFloat(formulaInicio);
                resultado = inicio * inicio;
                simb = 2;
  
                while (simb < final) {
                  resultado = resultado * inicio;
                  simb = simb + 1;
                }
                formulaFinal  ='';
                formulaInicio = '';
                while (formulanue.length > id2) {
                  formulaFinal = formulaFinal + formulanue[id2];
                  id2 = id2 + 1;
                }
                id2 = 0 ;
                while (idNumero >= id2 ) {
                  formulaInicio = formulaInicio + formulanue[id2];
                  id2 = id2 + 1;
                }
  
                formulanue = formulaInicio + resultado + formulaFinal;
                
                
                idoperacion = -1;
              }
              idoperacion = idoperacion + 1;
            }
  
            idoperacion = 0;
            while (idoperacion < formulanue.length) {
              if(formulanue[idoperacion] == '/'){
                id2 = idoperacion +1;
                formulaFinal  ='';
                formulaInicio = '';
                menosFinal = false;
                if(formulanue[id2] == '-'){
                  idNumero = id2 + 1;
                  menosFinal = true;
                  while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' && formulanue[idNumero] != '*' && idNumero < formulanue.length) {
                    formulaFinal = formulaFinal + formulanue[idNumero];
                    idNumero = idNumero + 1;
                  }
                }
                if(formulanue[id2] != '-'){
                  idNumero = id2;
                  while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' && formulanue[idNumero] != '*' && idNumero < formulanue.length) {
                    formulaFinal = formulaFinal + formulanue[idNumero];
                    idNumero = idNumero + 1;
                  }
                }
                id2 = idNumero;
                final = parseFloat(formulaFinal);
                idNumero = idoperacion -1;
                while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' &&  formulanue[idNumero] != '*' &&idNumero >= 0) {
                  idNumero = idNumero - 1;
                }
                simb = idNumero + 1;
                while (simb < idoperacion) {
                  formulaInicio = formulaInicio + formulanue[simb];
                  simb = simb + 1;
                }
                inicio = parseFloat(formulaInicio);
                resultado = inicio / final;
                formulaFinal  ='';
                formulaInicio = '';
                while (formulanue.length > id2) {
                  formulaFinal = formulaFinal + formulanue[id2];
                  id2 = id2 + 1;
                }
                id2 = 0 ;
                while (idNumero >= id2 ) {
                  formulaInicio = formulaInicio + formulanue[id2];
                  id2 = id2 + 1;
                }
  
                if(menosFinal == true){
                  formulanue = formulaInicio + '-' +resultado + formulaFinal;
                }
  
                if(menosFinal == false){
                  formulanue = formulaInicio + resultado + formulaFinal;
                }
               
                idoperacion = -1;
              }
              if(formulanue[idoperacion] == '*'){
                id2 = idoperacion +1;
                formulaFinal  ='';
                formulaInicio = '';
                menosFinal = false;
                if(formulanue[id2] == '-'){
                  idNumero = id2 + 1;
                  menosFinal = true;
                  while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' && formulanue[idNumero] != '*' && idNumero < formulanue.length) {
                    formulaFinal = formulaFinal + formulanue[idNumero];
                    idNumero = idNumero + 1;
                  }
                }
                if(formulanue[id2] != '-'){
                  idNumero = id2;
                  while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' && formulanue[idNumero] != '*' && idNumero < formulanue.length) {
                    formulaFinal = formulaFinal + formulanue[idNumero];
                    idNumero = idNumero + 1;
                  }
                }
                id2 = idNumero;
                final = parseFloat(formulaFinal);
                idNumero = idoperacion -1;
                while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' &&  formulanue[idNumero] != '*' && idNumero >= 0) {
                  idNumero = idNumero - 1;
                }
                simb = idNumero + 1;
                while (simb < idoperacion) {
                  formulaInicio = formulaInicio + formulanue[simb];
                  simb = simb + 1;
                }
                inicio = parseFloat(formulaInicio);
                resultado = inicio * final;
                formulaFinal  ='';
                formulaInicio = '';
                while (formulanue.length > id2) {
                  formulaFinal = formulaFinal + formulanue[id2];
                  id2 = id2 + 1;
                }
                id2 = 0 ;
                while (idNumero >= id2 ) {
                  formulaInicio = formulaInicio + formulanue[id2];
                  id2 = id2 + 1;
                }
  
                if(menosFinal == false){
                  formulanue = formulaInicio + resultado + formulaFinal;
                }
                if(menosFinal == true){
                  formulanue = formulaInicio +'-'+ resultado + formulaFinal;
                }
                
                idoperacion = -1;
              }
              idoperacion = idoperacion + 1;
            }
  
            idoperacion = 0;
            while (idoperacion < formulanue.length) {
              if(formulanue[idoperacion] == '+'  && idoperacion > 0){
                id2 = idoperacion +1;
                formulaFinal = '';
                formulaInicio = '';
                menosFinal = false;
                menosInicio = false;
                if(formulanue[id2] == '-'){
                  idNumero = id2 + 1;
                  menosFinal = true;
                  while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' && formulanue[idNumero] != '*' && idNumero < formulanue.length) {
                    formulaFinal = formulaFinal + formulanue[idNumero];
                    idNumero = idNumero + 1;
                  }
                }
                if(formulanue[id2] != '-'){
                  idNumero = id2;
                  while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' && formulanue[idNumero] != '*' && idNumero < formulanue.length) {
                    formulaFinal = formulaFinal + formulanue[idNumero];
                    idNumero = idNumero + 1;
                  }
                }
                id2 = idNumero;
                final = parseFloat(formulaFinal);
                idNumero = idoperacion -1;
                while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' &&  formulanue[idNumero] != '*' && idNumero >= 0) {
                  idNumero = idNumero - 1;
                }
                simb = idNumero + 1;
                while (simb < idoperacion) {
                  formulaInicio = formulaInicio + formulanue[simb];
                  simb = simb + 1;
                }
                inicio = parseFloat(formulaInicio);
  
                simb = idNumero - 1;
                if(formulanue[idNumero] == '-' && idNumero == 0){
                  menosInicio = true;
                }
                  
                if(formulanue[idNumero] == '-' && (formulanue[simb] == '/' || formulanue[simb] == '+' || formulanue[simb] == '-' ||  formulanue[simb] == '*')){
                  menosInicio = true;
                }
  
                sumaInicio = false;
                if(menosInicio == true){
                  if(menosFinal == true){
                    resultado = inicio + final;
                  }
                  if(menosFinal == false){
                    resultado = - inicio + final;
                  }
                  sumaInicio = true;
                }
                if(menosInicio == false){
                  if(menosFinal == true){
                    resultado = inicio - final;
                  }
                  if(menosFinal == false){
                    resultado = inicio + final;
                  }
                }
              
                formulaFinal  ='';
                formulaInicio = '';
                while (formulanue.length > id2) {
                  formulaFinal = formulaFinal + formulanue[id2];
                  id2 = id2 + 1;
                }
                id2 = 0 ;
                if(sumaInicio == false){
                  while (idNumero >= id2 ) {
                    formulaInicio = formulaInicio + formulanue[id2];
                    id2 = id2 + 1;
                  }
                }
                
                if(sumaInicio == true){
                  idNumero = idNumero - 1;
                  while (idNumero >= id2 ) {
                    formulaInicio = formulaInicio + formulanue[id2];
                    id2 = id2 + 1;
                  }
                }
                
                formulanue = formulaInicio + resultado + formulaFinal;
                idoperacion = -1;
              }
              if(formulanue[idoperacion] == '-' && idoperacion > 0){
                id2 = idoperacion +1;
                formulaFinal = '';
                formulaInicio = '';
                menosFinal = false;
                menosInicio = false;
                if(formulanue[id2] == '-'){
                  idNumero = id2 + 1;
                  menosFinal = true;
                  while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' && formulanue[idNumero] != '*' && idNumero < formulanue.length) {
                    formulaFinal = formulaFinal + formulanue[idNumero];
                    idNumero = idNumero + 1;
                  }
                }
                if(formulanue[id2] != '-'){
                  idNumero = id2;
                  while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' && formulanue[idNumero] != '*' && idNumero < formulanue.length) {
                    formulaFinal = formulaFinal + formulanue[idNumero];
                    idNumero = idNumero + 1;
                  }
                }
                id2 = idNumero;
                final = parseFloat(formulaFinal);
                idNumero = idoperacion -1;
                while (formulanue[idNumero] != '/' && formulanue[idNumero] != '+' && formulanue[idNumero] != '-' &&  formulanue[idNumero] != '*' &&idNumero >= 0) {
                  idNumero = idNumero - 1;
                }
                simb = idNumero + 1;
                while (simb < idoperacion) {
                  formulaInicio = formulaInicio + formulanue[simb];
                  simb = simb + 1;
                }
                inicio = parseFloat(formulaInicio);
  
                simb = idNumero - 1;
                if(formulanue[idNumero] == '-' && idNumero == 0){
                  menosInicio = true;
                }
                  
                if(formulanue[idNumero] == '-' && (formulanue[simb] == '/' || formulanue[simb] == '+' || formulanue[simb] == '-' ||  formulanue[simb] == '*')){
                  menosInicio = true;
                }
      
                if(menosInicio ==true ){
                  resultado = inicio + final;
                }
                if(menosInicio == false){
                  resultado = inicio - final;
                }
              
                formulaFinal  ='';
                formulaInicio = '';
                while (formulanue.length > id2) {
                  formulaFinal = formulaFinal + formulanue[id2];
                  id2 = id2 + 1;
                }
                id2 = 0 ;
                if(menosInicio == true){
                  idNumero = idNumero - 1;
                  while (idNumero >= id2 ) {
                    formulaInicio = formulaInicio + formulanue[id2];
                    id2 = id2 + 1;
                  }
                }
                if(menosInicio == false){
                  while (idNumero >= id2 ) {
                    formulaInicio = formulaInicio + formulanue[id2];
                    id2 = id2 + 1;
                  }
                }
                
                if(menosInicio == false){
                  formulanue = formulaInicio + resultado + formulaFinal;
                }
                if(menosInicio == true){
                  formulanue = formulaInicio + '-'+ resultado + formulaFinal;
                }
                idoperacion = - 1;
              }
              idoperacion = idoperacion + 1;
            }
            /// aqui termina
  
  
            formulaInicio = '';
            formulaFinal = '';
            idoperacion = 0;
            simbolo = simbolo - 1;
            while (idoperacion <= simbolo) {
              if(idoperacion != simbolo){
                formulaInicio = formulaInicio + formula[idoperacion];
              }
              if(idoperacion == simbolo){
                if(formulanue[0] != '-'){
                  formulaInicio = formulaInicio + formula[idoperacion];
                }
                if(formulanue[0] == '-'){
                  if(formula[idoperacion] == '+' || formula[idoperacion] == '-'){
                    if(formula[idoperacion] == '+'){
                      formulaInicio = formulaInicio + '-';
                      simb = 1;
                      while (simb < formulanue.length) {
                        formulaFinal = formulaFinal + formulanue[simb];
                        simb = simb + 1;
                      }
                      formulanue = formulaFinal;
                      formulaFinal = '';
                    } 
                    if(formula[idoperacion] == '-'){
                      formulaInicio = formulaInicio + '+';
                      simb = 1;
                      while (simb < formulanue.length) {
                        formulaFinal = formulaFinal + formulanue[simb];
                        simb = simb + 1;
                      }
                      formulanue = formulaFinal;
                      formulaFinal = '';
                    } 
                  }
                  if(formula[idoperacion] != '+' && formula[idoperacion] != '-'){
                    formulaInicio = formulaInicio + formula[idoperacion];
                  }
                }
              }
              idoperacion = idoperacion + 1;
            }
            idoperacion = id + 1;
            while (idoperacion < formula.length) {
              formulaFinal = formulaFinal + formula[idoperacion];
              idoperacion = idoperacion + 1;
            }
            formula = formulaInicio + formulanue + formulaFinal
            id = -1;
          }
  
          if(operacion == false){
            formulaInicio = '';
            formulaFinal = '';
            idoperacion = 0;
            simbolo = simbolo - 1;
            while (idoperacion <= simbolo) {
              formulaInicio = formulaInicio + formula[idoperacion];
              idoperacion = idoperacion + 1;
            }
            idoperacion = id + 1;
            while (idoperacion < formula.length) {
              formulaFinal = formulaFinal + formula[idoperacion];
              idoperacion = idoperacion + 1;
            }
            formula = formulaInicio + formulanue + formulaFinal
            id = -1;
          }
        }
      id = id + 1;
    }
    idNumero = 0;
  }
   
    if(idNumero == 0){
      id = 0;
      while (id < formula.length) {
        if(formula[id] == '^'){
          formulaFinal  ='';
          formulaInicio = '';
          menosFinal = false;
          id2 = id +1;
          if(formula[id2] == '-'){
            idNumero = id2 +1;
            menosFinal = true;
            while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' && formula[idNumero] != '*' && idNumero < formula.length) {
              formulaFinal = formulaFinal + formula[idNumero];
              idNumero = idNumero + 1;
            }
          }
          if(formula[id2] != '-'){
            idNumero = id2;
            while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' && formula[idNumero] != '*' && idNumero < formula.length) {
              formulaFinal = formulaFinal + formula[idNumero];
              idNumero = idNumero + 1;
            }
          }
          id2  = idNumero;
          final = parseFloat(formulaFinal);
          idNumero = id -1;
          while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' &&  formula[idNumero] != '*' &&idNumero >= 0) {
            idNumero = idNumero - 1;
          }
          simb = idNumero +1;
          while (simb < id) {
            formulaInicio = formulaInicio + formula[simb];
            simb = simb + 1;
          }
  
          inicio = parseFloat(formulaInicio);
          
          resultado = inicio * inicio;
          simb = 2;
  
          while (simb < final) {
            resultado = resultado * inicio;
            simb = simb + 1;
          }
  
          formulaFinal  ='';
          formulaInicio = '';
          while (formula.length > id2) {
            formulaFinal = formulaFinal + formula[id2];
            id2 = id2 + 1;
          }
          id2 = 0 ;
          while (idNumero >= id2 ) {
            formulaInicio = formulaInicio + formula[id2];
            id2 = id2 + 1;
          }
          
          formula = formulaInicio + resultado + formulaFinal;
         
          id = - 1;
  
        }
        id = id + 1;
      }
  
      id = 0;
      while (id < formula.length) {
        if(formula[id] == '/'){
          formulaFinal  ='';
          formulaInicio = '';
          menosFinal = false;
          id2 = id +1;
          if(formula[id2] == '-'){
            idNumero = id2 +1;
            menosFinal = true;
            while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' && formula[idNumero] != '*' && idNumero < formula.length) {
              formulaFinal = formulaFinal + formula[idNumero];
              idNumero = idNumero + 1;
            }
          }
          if(formula[id2] != '-'){
            idNumero = id2;
            while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' && formula[idNumero] != '*' && idNumero < formula.length) {
              formulaFinal = formulaFinal + formula[idNumero];
              idNumero = idNumero + 1;
            }
          }
          id2 = idNumero;
          final = parseFloat(formulaFinal);
          idNumero = id -1;
          while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' &&  formula[idNumero] != '*' &&idNumero >= 0) {
            idNumero = idNumero - 1;
          }
          idNumero = idNumero + 1 ;
          simb = idNumero;
          while (simb < id) {
            formulaInicio = formulaInicio + formula[simb];
            simb = simb + 1;
          }
  
          inicio = parseFloat(formulaInicio);
          resultado = inicio / final;
  
          idNumero = idNumero - 1;
          formulaFinal  ='';
          formulaInicio = '';
          while (formula.length > id2) {
            formulaFinal = formulaFinal + formula[id2];
            id2 = id2 + 1;
          }
          id2 = 0 ;
          while (idNumero >= id2 ) {
            formulaInicio = formulaInicio + formula[id2];
            id2 = id2 + 1;
          }
          
          if(menosFinal == true){
            formula = formulaInicio +'-'+ resultado + formulaFinal;
          }
          if(menosFinal == false){
            formula = formulaInicio + resultado + formulaFinal;
          }
          
          id = - 1;
  
        }
        if(formula[id] == '*'){
            formulaFinal  ='';
            formulaInicio = '';
            menosFinal = false;
            id2 = id +1;
            if(formula[id2] == '-'){
              idNumero = id2 +1;
              menosFinal = true;
              while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' && formula[idNumero] != '*' && idNumero < formula.length) {
                formulaFinal = formulaFinal + formula[idNumero];
                idNumero = idNumero + 1;
              }
            }
            if(formula[id2] != '-'){
              idNumero = id2;
              while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' && formula[idNumero] != '*' && idNumero < formula.length) {
                formulaFinal = formulaFinal + formula[idNumero];
                idNumero = idNumero + 1;
              }
            }
            id2  = idNumero;
            final = parseFloat(formulaFinal);
            idNumero = id -1;
            while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' &&  formula[idNumero] != '*' &&idNumero >= 0) {
              idNumero = idNumero - 1;
            }
            simb = idNumero +1;
            while (simb < id) {
              formulaInicio = formulaInicio + formula[simb];
              simb = simb + 1;
            }
    
            inicio = parseFloat(formulaInicio);
            resultado = inicio * final;
    
            formulaFinal  ='';
            formulaInicio = '';
            while (formula.length > id2) {
              formulaFinal = formulaFinal + formula[id2];
              id2 = id2 + 1;
            }
            id2 = 0 ;
            while (idNumero >= id2 ) {
              formulaInicio = formulaInicio + formula[id2];
              id2 = id2 + 1;
            }
            
            if(menosFinal == false){
              formula = formulaInicio + resultado + formulaFinal;
            }
            if(menosFinal == true){
              formula = formulaInicio + '-' + resultado + formulaFinal;
            }
            id = - 1;
    
          }
        id = id + 1;
      }
  
      id = 0;
      while (id < formula.length) {
        
        if(formula[id] == '+' && id > 0){
          formulaFinal  ='';
          formulaInicio = '';
          id2 = id +1;
          menosFinal = false;
          menosInicio = false;
          if(formula[id2] == '-'){
            idNumero = id2 + 1;
            menosFinal = true;
            while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' && formula[idNumero] != '*' && idNumero < formula.length) {
              formulaFinal = formulaFinal + formula[idNumero];
              idNumero = idNumero + 1;
            }
          }
          if(formula[id2] != '-'){
            idNumero = id2;
            while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' && formula[idNumero] != '*' && idNumero < formula.length) {
              formulaFinal = formulaFinal + formula[idNumero];
              idNumero = idNumero + 1;
            }
          }
          id2 = idNumero;
          final = parseFloat(formulaFinal);
          idNumero = id -1;
          while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' &&  formula[idNumero] != '*' &&idNumero >= 0) {
            idNumero = idNumero - 1;
          }
          simb = idNumero + 1;
          while (simb < id) {
            formulaInicio = formulaInicio + formula[simb];
            simb = simb + 1;
          }
          inicio = parseFloat(formulaInicio);
  
          simb = idNumero - 1;
          if(formula[idNumero] == '-' && idNumero == 0){
            menosInicio = true;
          }
            
          if(formula[idNumero] == '-' && (formula[simb] == '/' || formula[simb] == '+' || formula[simb] == '-' ||  formula[simb] == '*')){
            menosInicio = true;
          }
  
          sumaInicio = false;
          if(menosInicio == true){
            if(menosFinal == true){
              resultado = inicio + final;
            }
            if(menosFinal == false){
              resultado = - inicio + final;
            }
            sumaInicio = true;
          }
          if(menosInicio == false){
            if(menosFinal == true){
              resultado = inicio - final;
            }
            if(menosFinal == false){
              resultado = inicio + final;
            }
          }
        
          formulaFinal  ='';
          formulaInicio = '';
          while (formula.length > id2) {
            formulaFinal = formulaFinal + formula[id2];
            id2 = id2 + 1;
          }
          id2 = 0 ;
          if(sumaInicio == false){
            while (idNumero >= id2 ) {
              formulaInicio = formulaInicio + formula[id2];
              id2 = id2 + 1;
            }
          }
          
          if(sumaInicio == true){
            idNumero = idNumero - 1;
            while (idNumero >= id2 ) {
              formulaInicio = formulaInicio + formula[id2];
              id2 = id2 + 1;
            }
          }
          
          formula = formulaInicio + resultado + formulaFinal;
          
          id = -1;
          
        }
        if(formula[id] == '-' && id > 0){
          formulaFinal  ='';
          formulaInicio = '';
          id2 = id +1;
          menosFinal = true;
          menosInicio = false;
          if(formula[id2] == '-'){
            idNumero = id2 + 1;
            menosFinal = true;
            while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' && formula[idNumero] != '*' && idNumero < formula.length) {
              formulaFinal = formulaFinal + formula[idNumero];
              idNumero = idNumero + 1;
            }
          }
          if(formula[id2] != '-'){
            idNumero = id2;
            while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' && formula[idNumero] != '*' && idNumero < formula.length) {
              formulaFinal = formulaFinal + formula[idNumero];
              idNumero = idNumero + 1;
            }
          }
          id2 = idNumero;
          final = parseFloat(formulaFinal);
          idNumero = id -1;
          while (formula[idNumero] != '/' && formula[idNumero] != '+' && formula[idNumero] != '-' &&  formula[idNumero] != '*' &&idNumero >= 0) {
            idNumero = idNumero - 1;
          }
          simb = idNumero + 1;
          while (simb < id) {
            formulaInicio = formulaInicio + formula[simb];
            simb = simb + 1;
          }
  
          inicio = parseFloat(formulaInicio);
          simb = idNumero - 1;
          if(formula[idNumero] == '-' && idNumero == 0){
            menosInicio = true;
          }
            
          if(formula[idNumero] == '-' && (formula[simb] == '/' || formula[simb] == '+' || formula[simb] == '-' ||  formula[simb] == '*')){
            menosInicio = true;
          }
  
          if(menosInicio ==true ){
            resultado = inicio + final;
          }
          if(menosInicio == false){
            resultado = inicio - final;
          }
        
          formulaFinal  ='';
          formulaInicio = '';
          while (formula.length > id2) {
            formulaFinal = formulaFinal + formula[id2];
            id2 = id2 + 1;
          }
          id2 = 0 ;
          if(menosInicio == true){
            idNumero = idNumero - 1;
            while (idNumero >= id2 ) {
              formulaInicio = formulaInicio + formula[id2];
              id2 = id2 + 1;
            }
          }
          if(menosInicio == false){
            while (idNumero >= id2 ) {
              formulaInicio = formulaInicio + formula[id2];
              id2 = id2 + 1;
            }
          }
          
          if(menosInicio == false){
            formula = formulaInicio + resultado + formulaFinal;
          }
          if(menosInicio == true){
            formula = formulaInicio + '-'+ resultado + formulaFinal;
          }
          id = -1;
        }
        id = id + 1;
      }
      
    }
    
    formula = Math.round(formula);
     return formula;

    }
 
 


    usuariosIndicador(nuevos,viejos){
      var id = 0;
      var id2 = 0;
      var nombre_usuario;
      var usuario;
      var area;
      var usariofalta = [];
      var usuariosEstam = [];
      var usariosEliminados = [];
      var arr = [];
      
      if(viejos != null){
        arr = Object.values(viejos);
      }
      
      const usuariosNuevos = nuevos;
      const usuariosViejos = arr;


      if(usuariosViejos.length == 0 && usuariosNuevos.length > 0){
        while (usuariosNuevos.length > id){
          usuario = usuariosNuevos[id].idUsuario;
          nombre_usuario = usuariosNuevos[id].usuario;
          area = usuariosNuevos[id].idArea;
          usariofalta.push({id:usuario, nombre: nombre_usuario, area: area });
          id = id + 1;
        }
      }

      if(usuariosNuevos.length == 0 && usuariosViejos.length > 0){
        while (usuariosViejos.length > id){
          usuario = usuariosViejos[id].idUsuario;
          nombre_usuario = usuariosViejos[id].usuario;
          area = usuariosViejos[id].idArea;
          usariosEliminados.push({id:usuario, nombre: nombre_usuario, area: area });
          id = id + 1;
        }
      }
  
      if(usuariosViejos.length > 0 && usuariosNuevos.length > 0){
        while (usuariosNuevos.length > id){
          if(usuariosNuevos[id].idUsuario == usuariosViejos[id2].idUsuario){
            usuario = usuariosNuevos[id].idUsuario;
            nombre_usuario = usuariosNuevos[id].usuario;
            area = usuariosNuevos[id].idArea;
            usuariosEstam.push({id:usuario, nombre: nombre_usuario, area: area });
            id = id + 1;
            id2 = -1;
          }
          if((usuariosViejos.length - 1)== id2){
            usuario = usuariosNuevos[id].idUsuario;
            nombre_usuario = usuariosNuevos[id].usuario;
            area = usuariosNuevos[id].idArea;
            usariofalta.push({id:usuario, nombre: nombre_usuario, area: area });
            id = id + 1;
            id2 = -1;
          }
          id2 = id2 + 1;
        }
    
        id= 0;
        id2 = 0;
        while (usuariosViejos.length > id){
          if(usuariosViejos[id].idUsuario == usuariosNuevos[id2].idUsuario){
            id = id + 1;
            id2 = -1;
          }
          if((usuariosNuevos.length - 1)== id2){
            usuario = usuariosViejos[id].idUsuario;
            nombre_usuario = usuariosViejos[id].usuario;
            area = usuariosViejos[id].idArea;
            usariofalta.push({id: usuario, nombre: nombre_usuario, area: area});
            id = id + 1;
            id2 = -1;
          }
          id2 = id2 + 1;
        }

        id = 0;
        id2 = 0;
        
        if(usariofalta.length > 0){
          while(usuariosViejos.length > id && usariofalta.length > 0){
            if(usuariosViejos[id].idUsuario == usariofalta[id2].id){
              usuario = usuariosViejos[id].idUsuario;
              nombre_usuario = usuariosViejos[id].usuario;
              area = usuariosViejos[id].idArea;
              usariosEliminados.push({id:usuario, nombre: nombre_usuario, area: area });
              const ind = usariofalta.findIndex( (item) => item.id === usuario);
              if (ind > -1) {
                usariofalta.splice(ind, 1);
              }
    
              id = id + 1;
              id2 = -1;
            }
            if((usariofalta.length - 1)== id2){
              id = id + 1;
              id2 = -1;
            }
            id2 = id2 + 1;
          }
        }
  


      }
  
    return {quedaron: usuariosEstam, nuevos: usariofalta, eliminados: usariosEliminados};
    }
 }
 


  


