import { Action } from '@ngrx/store';
import { Contacto } from 'src/app/models/contacto';
import { Empresa } from 'src/app/models/empresa';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CargaContacto } from 'src/app/models/carga-contactos';

export const CARGAR_CONTACTOS = '[Contacto] Cargar contactos';
export const CARGAR_CONTACTOS_FAIL = '[Contacto] Cargar contactos FAIL';
export const CARGAR_CONTACTOS_SUCCESS = '[Contacto] Cargar contactos SUCCESS';
export const CARGAR_CONTACTOS2 = '[Contacto] Cargar contactos 2';
export const CARGAR_CONTACTOS2_SUCCESS = '[Contacto] Cargar contactos 2 SUCCESS';
export const CARGAR_CONTACTO = '[Contacto] Cargar contacto';
export const CARGAR_CONTACTO_FAIL = '[Contacto] Cargar contacto FAIL';
export const CARGAR_CONTACTO_SUCCESS = '[Contacto] Cargar contacto SUCCESS';
export const ACTUALIZA_CONTACTO = '[Contacto] Actualiza contacto';
export const ACTUALIZA_CONTACTO_FAIL = '[Contacto] Actualiza contacto FAIL';
export const ACTUALIZA_CONTACTO_SUCCESS = '[Contacto] Actualiza contacto SUCCESS';
export const UPDATE_CARGA_CONTACTOS = '[Contacto] Update carga contactos';
export const UPDATE_CARGA_CONTACTOS_FAIL = '[Contacto] Update carga contactos FAIL';
export const UPDATE_CARGA_CONTACTOS_SUCCESS = '[Contacto] Update carga contactos SUCCESS';
export const ELIMINAR_CONTACTO = '[Contacto] Eliminar contacto';
export const ELIMINAR_CONTACTO_FAIL = '[Contacto] Eliminar contacto FAIL';
export const ELIMINAR_CONTACTO_SUCCESS = '[Contacto] Eliminar contacto SUCCESS';
export const LIMPIAR_CONTACTO = '[Contacto] Limpiar contacto';
export const CARGAR_ULTIMA_ACTIVIDAD_CONTACTOS = '[Contacto] Cargar ultima actividad contacto';
export const CARGAR_ULTIMA_ACTIVDAD_CONTACTOS_FAIL = '[Contacto] Cargar ultima actividad contacto FAIL';
export const CARGAR_ULTIMA_ACTIVDAD_CONTACTOS_SUCCESS = '[Contacto] Cargar ultima actividad contacto SUCCESS';
export const ACTUALIZA_CONTACTOS_DUPLICADOS = '[Contacto] Actualiza contactos duplicados';
export const ACTUALIZA_CONTACTOS_DUPLICADOS_FAIL = '[Contacto] Actualiza contactos duplicados FAIL';
export const ACTUALIZA_CONTACTOS_DUPLICADOS_SUCCESS = '[Contacto] Actualiza contactos duplicados SUCCESS';

export class CargarContactos implements Action {
  readonly type = CARGAR_CONTACTOS;
}

export class CargarContactosFail implements Action {
  readonly type = CARGAR_CONTACTOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarContactosSuccess implements Action {
  readonly type = CARGAR_CONTACTOS_SUCCESS;

  constructor( public contactos: Contacto[] ) {}
}

export class CargarContactos2 implements Action {
  readonly type = CARGAR_CONTACTOS2;

  constructor(public opciones: any) {}
}

export class CargarContactos2Success implements Action {
  readonly type = CARGAR_CONTACTOS2_SUCCESS;

  constructor(public contactos: Contacto[], public totalFiltro: number, public total: number) {}
}

export class CargarContacto implements Action {
  readonly type = CARGAR_CONTACTO;

  constructor(public id: number) {}
}

export class CargarContactoFail implements Action {
  readonly type = CARGAR_CONTACTO_FAIL;

  constructor( public payload: any ) {}
}

export class CargarContactoSuccess implements Action {
  readonly type = CARGAR_CONTACTO_SUCCESS;

  constructor( public contacto: Contacto ) {}
}

export class ActualizaContacto implements Action {
  readonly type = ACTUALIZA_CONTACTO;

  constructor( public contacto: Contacto, public empresa: Empresa ) { }
}

export class ActualizaContactoFail implements Action {
  readonly type = ACTUALIZA_CONTACTO_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaContactoSuccess implements Action {
  readonly type = ACTUALIZA_CONTACTO_SUCCESS;

  constructor( public contacto: Contacto ) {}
}

export class UpdateCargaContactos implements Action {
  readonly type = UPDATE_CARGA_CONTACTOS;

  constructor(public contactos: CargaContacto[]) { }
}

export class UpdateCargaContactosFail implements Action {
  readonly type = UPDATE_CARGA_CONTACTOS_FAIL;

  constructor( public payload: any ) {}
}

export class UpdateCargaContactosSuccess implements Action {
  readonly type = UPDATE_CARGA_CONTACTOS_SUCCESS;

  constructor() {}
}

export class EliminarContacto implements Action {
  readonly type = ELIMINAR_CONTACTO;

  constructor( public id: number ) { }
}

export class EliminarContactoFail implements Action {
  readonly type = ELIMINAR_CONTACTO_FAIL;

  constructor( public payload: any ) {}
}

export class EliminarContactoSuccess implements Action {
  readonly type = ELIMINAR_CONTACTO_SUCCESS;

  constructor( public id: number ) {}
}

export class LimpiarContacto implements Action {
  readonly type = LIMPIAR_CONTACTO;

  constructor() {}
}

export class CargarUltimaActividadContacto implements Action {
  readonly type = CARGAR_ULTIMA_ACTIVIDAD_CONTACTOS;

  constructor(public id: number) {}
}

export class CargarUltimaActividadContactoFail implements Action {
  readonly type = CARGAR_ULTIMA_ACTIVDAD_CONTACTOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarUltimaActividadContactoSuccess implements Action {
  readonly type = CARGAR_ULTIMA_ACTIVDAD_CONTACTOS_SUCCESS;

  constructor( public actividad: string ) {} //NgbDateStruct
}

export class ActualizaContactosDuplicados implements Action {
  readonly type = ACTUALIZA_CONTACTOS_DUPLICADOS;

  constructor( public contacto: Contacto, public duplicados: Contacto[] ) { }
}

export class ActualizaContactosDuplicadosFail implements Action {
  readonly type = ACTUALIZA_CONTACTOS_DUPLICADOS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaContactosDuplicadosSuccess implements Action {
  readonly type = ACTUALIZA_CONTACTOS_DUPLICADOS_SUCCESS;

  constructor( public contacto: Contacto, public duplicados: Contacto[] ) {}
}

export type contactoAcciones =  CargarContactos 
                              | CargarContactosFail 
                              | CargarContactosSuccess 
                              | CargarContactos2
                              | CargarContactos2Success 
                              | CargarContacto 
                              | CargarContactoFail 
                              | CargarContactoSuccess 
                              | ActualizaContacto 
                              | ActualizaContactoFail 
                              | ActualizaContactoSuccess 
                              | UpdateCargaContactos 
                              | UpdateCargaContactosFail 
                              | UpdateCargaContactosSuccess 
                              | EliminarContacto 
                              | EliminarContactoFail 
                              | EliminarContactoSuccess 
                              | LimpiarContacto
                              | CargarUltimaActividadContacto 
                              | CargarUltimaActividadContactoFail 
                              | CargarUltimaActividadContactoSuccess 
                              | ActualizaContactosDuplicados 
                              | ActualizaContactosDuplicadosFail 
                              | ActualizaContactosDuplicadosSuccess ;
