<div class="he-auto">
  <div class="row he-auto no-gutters">
    <div class="he-auto p-3 d-md-flex d-sm-block bg-white justify-content-center align-items-center col-md-12 col-lg-7">
      <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
        <h4>
          <div>Cambiar la contraseña del usuario actual</div>
          <span>Facil de hacer, solo sigue los siguientes pasos.</span>
        </h4>
        <div>
          <form (ngSubmit)="onSubmit( f.value )" #f="ngForm">
            <div  class="row">
              <div class="col-md-6">
                <fieldset class="form-group" id="__BVID__132">
                  <div tabindex="-1" role="group">
                    <label for="oldPasswordChangePassword">
                      <span class="text-danger">*</span>
                      Contraseña actual
                    </label>
                    <input ngModel type="password" id="oldPasswordChangePassword" name="oldPassword" #oldPassword="ngModel" placeholder="Contraseña actual..." class="form-control" required>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
              <fieldset class="form-group" id="__BVID__134">
                <div tabindex="-1" role="group">
                  <label for="newPasswordChangePassword">
                    <span class="text-danger">*</span>
                    Nueva contraseña
                  </label>
                  <input ngModel type="password" id="newPasswordChangePassword" name="newPassword" #newPassword="ngModel" placeholder="Nueva contraseña" class="form-control" required>
                </div>
              </fieldset>
              </div>
              <div class="col-md-6">
                <fieldset class="form-group" id="__BVID__138">
                  <div tabindex="-1" role="group">
                    <label for="passwordRepChangePassword">
                      <span class="text-danger">*</span>
                      Confirma la contraseña
                    </label>
                    <input ngModel id="passwordRepChangePassword" name="passwordRep" #passwordRep="ngModel" type="password" placeholder="Confirma aquí tu contraseña..." class="form-control" required>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="mt-4 d-flex align-items-center">
              <div class="ml-auto">
                <button type="submit" class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-success btn-lg" *ngIf="!cargando">  Registrar cambios</button>
                <button class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-secondary btn-lg" type="button" disabled *ngIf="cargando">
                  <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
