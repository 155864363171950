import { Component, OnInit, OnDestroy } from '@angular/core';
import { Catalogo } from 'src/app/models/catalogo';
import { User } from 'src/app/models/user';
import { Ticket, AdjuntosMensaje } from 'src/app/models/ticket';
import { TicketsService } from 'src/app/services/tickets.services';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';

@Component({
  selector: 'app-tickets-edit',
  templateUrl: './tickets-edit.component.html',
  styleUrls: ['./tickets-edit.component.sass']
})
export class TicketsEditComponent implements OnInit, OnDestroy {
  heading = 'Detalles del ticket';
  subheading = 'Ticket registrando en el sistema';
  icon = 'pe-7s-ticket icon-gradient bg-dark';
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private subscribeConsultaCatalogo: Subscription = new Subscription();
  private subcriptionTicket: Subscription = new Subscription();
  public ticket: Ticket  = new Ticket(null);
  public ticketInicial: Ticket  = new Ticket(null);
  public departamentos: Catalogo[];
  public departamentosEmisor: Catalogo[];
  public loading: boolean;
  public usuarios: User[];
  public usuariosFilter: Catalogo[];
  public departamentoAnterior: number;
  public adjunto: AdjuntosMensaje = new AdjuntosMensaje(null);
  public textFile: string = '';
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'nombre',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Sin datos'
  };
  constructor(
    private ticketesService: TicketsService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
  ) {
    this.identity = new Identity(null);
    this.usuarios = [];
    this.usuariosFilter = [];
    this.departamentos = [];
    this.departamentoAnterior = 0;
    this.store.dispatch(new usuariosActions.LimpiarTicket());
   }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.usuarios = [...state.usuarios.filter(item => item.tipo !== 5)];
      }
    );
    this.subscribeConsultaCatalogo = this.store.select('consultaCatalogos').subscribe(
      (state) => {
        this.departamentosEmisor = this.identity.tipo === 1 ?
        [...state.departamentos.filter(item => item.status)] :
        [...this.identity.departamentos.map(item => { return new Catalogo({id: item.id, nombre: item.nombre, status: true}); } )];
        this.departamentos = [...state.departamentos.filter(item => item.status)];
      }
    );
    this.subcriptionTicket = this.store.select('ticket').subscribe(
      (state) => {
        this.ticket = {...state.ticket};
        this.ticketInicial = {...state.ticket};
      }
    );
    console.log('Inicializa component tickets-edit...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeConsulta.unsubscribe();
    this.subcriptionTicket.unsubscribe();
    this.subscribeConsultaCatalogo.unsubscribe();
  }

  inicializa() {
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        if (id > 0) {
          this.store.dispatch(new usuariosActions.CargarTicket(id));
        } else {
          this.ticket = new Ticket(null);
          if(this.identity.departamentos.length === 1){
            this.identity.departamentos.find(item => { 
              this.ticket.departamentoEmisor = item.id;
              this.ticket.desDepartamentoEmisor = item.nombre;
            });
          }
        }
    });
    this.store.dispatch(new usuariosActions.ConsultaCatalogoDepartamentos());
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());   
  }

  async onSubmit() {
    await this.validaTicket().then(
      (resultado) => {
        Swal.fire({
          title: '¿Deseas registrar el ticket?',
          showCancelButton: true,
          confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
          cancelButtonColor: MESSAGES.C_BTN_CANCEL,
          confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
          cancelButtonText: MESSAGES.M_BTN_CANCEL,
          focusConfirm: false,
          focusCancel: true,
          animation: true,
          showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
          showLoaderOnConfirm: true,
          preConfirm:  ()  => {
            return new Promise( (resolve, reject)  => {
              this.ticketesService.registraTicket(this.ticket).subscribe(
                (respuesta) => {
                  if (respuesta.ticket) {
                    const adjuntos:AdjuntosMensaje[]  = [...this.ticket.adjuntos.filter(item => item.file)];
                    if(adjuntos.length){
                      this.ticketesService.addFileTransfersMensaje(adjuntos, respuesta.ticket.id, respuesta.mensaje).subscribe(
                        (res: any) => { }, (error) => { console.log(error); }
                      );
                    }
                    this.store.dispatch(new usuariosActions.ActualizaTicketSuccess(respuesta.ticket));
                    resolve(respuesta.ticket);
                  } else {
                    Swal.showValidationMessage('Ocurrió un error en la operación');
                    resolve(false);
                  }
                }, (error) => {
                  Swal.showValidationMessage('Ocurrió un error en la operación');
                  resolve(false);
                }
              );
            });
          },
          allowOutsideClick: false
        }).then(
          (result) => {
            if (result.value) {
              Swal.fire({
                title: MESSAGES.M_TIT_CONFIRM,
                text: 'Ticket registrado.',
                icon: 'success'
              });
              this.router.navigate(['/auth/tickets']);
            } else {
              Swal.fire({
                title: MESSAGES.M_TIT_CANCELADO,
                timer: 1200,
                showConfirmButton: false,
                icon: 'error',
                onOpen: () =>  {}
              }).then(() => {}, () => {});
            }
          }, () => {
            Swal.fire({
              title: MESSAGES.M_TIT_CANCELADO,
              timer: 1200,
              showConfirmButton: false,
              icon: 'error',
              onOpen: () => {}
            }).then( () => {},  () => {});
          }
        );
    }, (error) => { }
    );
  }

  usuariosDepartamento(){
    if(this.ticket.departamento !== this.departamentoAnterior){
      this.ticket.usuariosReceptores = [];
      this.usuariosFilter = [];
       this.usuarios.find(item => {
        const itm = item.departamento.find( ite => ite.id === this.ticket.departamento);
        if (itm && itm.id) {
          this.usuariosFilter.push({id: item.id, nombre: item.nombre  + ' ' + item.apellidoPaterno + ' ' + item.apellidoMaterno, status: true});
        }
      });
      this.departamentoAnterior = this.ticket.departamento;
    }
  }

  onItemSelect(item: any) {
    // console.log(item);
  }

  onSelectAll(items: any) {
    // console.log(items);
  }


  validaTicket() {
    const deferred = new Promise((resolve, reject) => {
        resolve(true);
    });
    return deferred;
  }

  fileChangeEvent(event: any) {
    const archivo: File = event.target.files[0];
    const mimeType = archivo.type.split('/').pop();
    if ( mimeType === 'jpeg' || mimeType === 'png' || mimeType === 'gif' || mimeType === 'pdf' ) {
        this.adjunto.file = archivo;
        this.adjunto.type = mimeType;
        this.adjunto.size = archivo.size;
    }else{
      this.textFile = '';
      this.toastr.warning('El formato del archivo "' + archivo.name +  '" no es compatible.');
    }
  }

  addFile() {
    if (this.adjunto && this.adjunto.name !== '' && this.adjunto.type !== '') {
      const itm = this.ticket.adjuntos.find( item => item.name === this.adjunto.name && item.type === this.adjunto.type);
      if (!(itm && itm.id)) {
        // this.ticket.adjuntos.push({...this.adjunto});
        const arr = [...this.ticket.adjuntos, {...this.adjunto}];
        this.ticket.adjuntos = arr;
        this.adjunto = new AdjuntosMensaje(null);
        this.textFile = '';
      } 
    }
  }
  
  deleteFile(file: AdjuntosMensaje) {
    this.ticket.adjuntos = [...this.ticket.adjuntos.filter(item => item !== file )];
    if(!file.file){
      this.ticket.adjuntosEliminados.push({...file});
    }
  }

}
