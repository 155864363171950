import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as usuariosActions from 'src/app/store/actions';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.sass'],
  providers: []
})
export class RestoreComponent implements OnInit {
  public title: string;
  public email: string;
  public loading: boolean;
  @ViewChild('emailRestore', {static: false}) emailRestore: ElementRef;
  public year = 2020;

  constructor(
    private userService: UsersService,
    private router: Router,
    private location: Location,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) {
    this.email = '';
    this.loading = false;
  }

  ngOnInit() {
    console.log('Inicializa componente Restauración de usuario...');
    this.title = 'Restauración de usuario';
  }

  cancel() {
    this.location.back();
  }

  onSubmit() {
    this.loading = true;
    this.userService.existsEmail(this.email).subscribe(
      (respuestaEmail) => {
        if (!respuestaEmail.email) {
          this.emailRestore.nativeElement.focus().select();
          this.store.dispatch( new usuariosActions.WarnToastrAction(
            'Correo invalido, no se encuentra registrado.', 'Advertencia', {}, {}) );
          this.loading = false;
        } else {
          this.userService.recoveryUser(this.email).subscribe(
            (respuesta) => {
              this.loading = false;
              if (respuesta.mensaje && respuesta.mensaje.code && (respuesta.mensaje.code === 200 || respuesta.mensaje.code === '200') ) {
                this.router.navigate(['/public/login']);
              }
            }, (error) => {
              this.loading = false;
            }
          );
        }
      }, (error) => {
        this.loading = false;
        this.emailRestore.nativeElement.focus().select();
        this.toastr.error('Su colicitud no fue recibida, intente de nuevo.', 'Error');
      }
    );
  }

}
