import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServicioIniFinaEn, DivisionIniFinaEn, OrderIniFinaEnSortType  } from 'src/app/models/reporte-asignacion';
import { Pagination } from 'src/app/models/pagination';
import { Fechas } from 'src/app/models/fechas';
import { DatePipe } from '@angular/common';
import { Identity } from 'src/app/models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Division } from 'src/app/models/division';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import {ExcelService} from 'src/app/services/excel.service';
import * as usuariosActions from 'src/app/store/actions';
import * as moment from 'moment';

@Component({
  selector: 'app-reporte-asignacion-inicia',
  templateUrl: './reporte-asignacion-inicia.component.html',
  styleUrls: ['./reporte-asignacion-inicia.component.sass']
})
export class ReporteAsignacionIniciaComponent implements OnInit, OnDestroy {
  heading = 'Resumen de servicios iniciados en';
  subheading = 'servicios iniciados en';
  icon = ' pe-7s-note2 icon-gradient bg-happy-fisher';
  public datePipe = new DatePipe("en-US");
  public dates: Date[] = [];
  public anio: string;
  public resultadosReporte: ServicioIniFinaEn[] = [];
  public resultadosDivision: DivisionIniFinaEn[] = [];
  public orderType = [...OrderIniFinaEnSortType];
  public resultadoTotal: DivisionIniFinaEn = new DivisionIniFinaEn (null);
  public paginacionD = new Pagination(null);
  public paginacionS = new Pagination(null);
  public chipsS = [];
  public chipsD = [];
  public loading: boolean;
  public filterActive: boolean;
  public selectable: boolean;
  public dias: Fechas;
  public removable: boolean;
  public division: Division[];
  public identity: Identity;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionReporteAsignacion: Subscription = new Subscription();
  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private excelService: ExcelService,
  ) { 
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - 15);
    this.removable = true;
    this.paginacionS.sortType = '-id'
    this.paginacionD.sortType = '-id'
    this.dates = [ fecha,new Date() ];
    this.dias = new Fechas(null);
    this.loading = false;
    this.filterActive = false;
    this.selectable = true;
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionReporteAsignacion = this.store.select('reporteAsignacion').subscribe(
      (state) => {
        this.division = [];
        this.resultadoTotal = new DivisionIniFinaEn (null);
        this.resultadoTotal.nombre = 'Total';
        this.resultadosDivision = [...state.divisionIniFinaEn];
        this.resultadosReporte = [...state.servicioIniFinaEn];

        this.resultadosDivision.find(item => {
          this.resultadoTotal.bajas = this.resultadoTotal.bajas + item.bajas ;
          this.resultadoTotal.servicios = this.resultadoTotal.servicios + item.servicios ;
          this.resultadoTotal.elementos = this.resultadoTotal.elementos + item.elementos;
          this.resultadoTotal.adicion = this.resultadoTotal.adicion + item.adicion ;
          this.resultadoTotal.turnos = this.resultadoTotal.turnos + item.turnos;
          this.resultadoTotal.vacantes = this.resultadoTotal.vacantes + item.vacantes;
          this.resultadoTotal.reduccion = this.resultadoTotal.reduccion + item.reduccion;
         });

        this.resultadosReporte.find(item => {
          let existe = false;
          this.division.find(itm => {
            if(itm.nombre == item.desDivision){
              existe = true;
            }
          })
          if(!existe){
            this.division.push({id:item.division, nombre:item.desDivision , estatus: true});
          }
        });
      }
    );
  }

  
  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionReporteAsignacion.unsubscribe();
  }

  fecha(fecha:Date[]){
    if(fecha && fecha.length === 2){
      const fecha1= this.datePipe.transform(fecha["0"], 'yyyy-MM-dd');
      const fecha2= this.datePipe.transform(fecha["1"], 'yyyy-MM-dd');
      const anio1 = this.datePipe.transform(fecha["0"], 'yyyy');
      const anio2 = this.datePipe.transform(fecha["1"], 'yyyy');      

      if(anio1 === anio2){
        this.anio = anio1;
      }else{
        this.anio = (anio1 + '-' + anio2);
      }
      this.dias = {fechaInicio: fecha1, fechaFinal: fecha2};
      this.store.dispatch(new usuariosActions.CargarReporteServicioInicio(this.dias));
    }
  }

  sortD(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacionD.sortType || ('+' + descriptionSort) === this.paginacionD.sortType ) {
      this.paginacionD.sortReverse = !this.paginacionD.sortReverse;
      if (this.paginacionD.sortReverse) {
        this.paginacionD.sortType = '-' + descriptionSort;
      } else {
        this.paginacionD.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacionD.sortType = '+' + descriptionSort;
      this.paginacionD.sortReverse = false;
    }
  }

  sortS(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacionS.sortType || ('+' + descriptionSort) === this.paginacionS.sortType ) {
      this.paginacionS.sortReverse = !this.paginacionS.sortReverse;
      if (this.paginacionS.sortReverse) {
        this.paginacionS.sortType = '-' + descriptionSort;
      } else {
        this.paginacionS.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacionS.sortType = '+' + descriptionSort;
      this.paginacionS.sortReverse = false;
    }
  }

  changeDivision() {
    this.division = [...this.division];
  }
  
  async crearExcel() {
    this.loading = true;
    let servicio;
    let division; 

    servicio = this.resultadosReporte.map( item => {
      const obj: any = {};
      obj.NO_ORDEN = item.numeroOrden;
      obj.CLIENTE = item.desCliente;
      obj.SERVICIO = item.desServicio;
      obj.SUCURSAL = item.desSucursal;
      obj.ESTADO = item.localidad;
      obj.DIVISIÓN = item.desDivision;
      obj.NO_ELEMENTOS = item.elementos;
      obj.TURNOS_DIA = item.turnos;
      obj.VACANTES = item.vacantes;
      obj.BAJAS = item.bajas;
      return obj;
    });

    
    division = this.resultadosDivision.map( item => {
      const obj: any = {};
      obj.DIVISIÓN = item.nombre;
      obj.SERVICIO = item.servicios;
      obj.NO_ELEMENTOS = item.elementos;
      obj.TURNOS_DIA = item.turnos;
      obj.VACANTES = item.vacantes;
      obj.BAJAS = item.bajas;
      obj.ADICIÓN = item.adicion;
      obj.REDUCCIÓN = item.reduccion;
      return obj;
    });
    this.excelService.exportAsExcelFileReporteIniciadoFinalizado(division, servicio, 'Resumen de servicios iniciados en');
    this.loading = true;
    
  }


  textSearchChipsS(){
    if(this.paginacionS.textSearch.length > 0){
      this.chipsS = [...this.paginacionS.textSearch.split(' ')];
    }
    
  }

  removeS(chips){
    this.chipsS = [...this.chipsS.filter(item => item !== chips)];
    this.paginacionS.textSearch = this.chipsS.join(' ');
  }


  textSearchChipsD(){
    if(this.paginacionD.textSearch.length > 0){
      this.chipsD = [...this.paginacionD.textSearch.split(' ')];
    }
    
  }

  removeD(chips){
    this.chipsD = [...this.chipsD.filter(item => item !== chips)];
    this.paginacionD.textSearch = this.chipsD.join(' ');
  }



}
