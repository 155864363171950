import {Routes} from '@angular/router';

import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PoliticaPrivacidadComponent } from './politica-privacidad/politica-privacidad.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

export const layoutRoutes: Routes = [
    {path: 'terms-and-conditions', component: TermsAndConditionsComponent, data: {extraParameter: 'pagesMenu'}},
    {path: 'privacy-policy', component: PrivacyPolicyComponent, data: {extraParameter: 'pagesMenu'}},
    {path: 'politica-de-privacidad', component: PoliticaPrivacidadComponent, data: {extraParameter: 'pagesMenu'}},
];
