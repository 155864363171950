import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { ColoresService } from 'src/app/services/colores.service';

@Injectable()
export class ColoresEffects {

  constructor(
    private actions$: Actions,
    public coloresService: ColoresService
  ) {}

  @Effect()
  cargarColores$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_COLORES),
    switchMap( () => {
      return this.coloresService.getColores()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarColoresSuccess(respuesta.color);
          }),
          catchError( error => of(new usuariosActions.CargarColoresFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusColor$ = this.actions$.pipe(
    ofType( usuariosActions.ELIMINAR_COLOR ),
    switchMap( (action: any) => {
      return this.coloresService.deleteColor(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.EliminarColorSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.EliminarColorFail(error))  )
        );
    })
  );

  @Effect()
  actualizaColor$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_COLOR ),
    switchMap( (action: any) => {
      return this.coloresService.registerColores(action.color)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaColorSuccess(action.color);
            }),
            catchError( error => of(new usuariosActions.ActualizaColorFail(error))  )
        );
    })
  );


}
