import { Component, OnInit, OnDestroy } from '@angular/core';
import { Cumplimiento, Justificacion, DataSet, Labels } from 'src/app/models/cumplimiento';
import { Departamento } from 'src/app/models/reporte-actividades';
import { Dianolaboral } from 'src/app/models/dianolaboral';
import { Fechas } from 'src/app/models/fechas';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState, TiposState } from 'src/app/store/reducers';
import * as usuariosActions from 'src/app/store/actions';
import { ApexTooltip } from "ng-apexcharts";
import { Pagination } from 'src/app/models/pagination';
import { OrderByPipe } from 'src/app/pipes/order-by.pipe';
import { FilterType } from 'src/app/models/user';

@Component({
  selector: 'app-cumplimiento',
  templateUrl: './cumplimiento.component.html',
  styleUrls: ['./cumplimiento.component.sass']
})
export class CumplimientoComponent implements OnInit, OnDestroy {
	heading = 'Seguimiento de cumplimiento';
	subheading = '';
	icon = ' pe-7s-graph2 icon-gradient bg-happy-fisher';
	public maxDate: Date;
	public diasnolobaral: Dianolaboral[] = [];
	public datePipe = new DatePipe("en-US");
	public dates: Date[] = [];
	public labels: Labels[] = [];
	public dataSets: DataSet[] = [];
	public justificacion: Justificacion;
	public dias: Fechas;
	public nuevoDiaNoLaboral : Dianolaboral;
	public identity: Identity;
	public subcriptionIdentity: Subscription = new Subscription();
	public subcriptionCumplimiento: Subscription = new Subscription();
	public subcriptionDiaNoLaboral: Subscription = new Subscription();
	public subscribeConsulta: Subscription = new Subscription();
	public subcriptionCatalogo: Subscription = new Subscription();
	public tooltip: ApexTooltip;
	public paginacion: Pagination = new Pagination(null);
	public height: number = 0;
	public loading: boolean = false;
	public filterDepartamentos: FilterType[];
	public filterTipos: FilterType[];
	public listSearch = [];
	public filterActive = false;
	constructor(
		private store: Store<AppState>,
	) { 
	const fecha = new Date();
	fecha.setDate(fecha.getDate() - 7)
	this.dates = [ fecha,new Date() ];
	this.dias = new Fechas(null);
	this.nuevoDiaNoLaboral = new Dianolaboral(null);
	this.maxDate = new Date();
	this.maxDate.setDate(this.maxDate.getDate());
	this.justificacion = new Justificacion(null);
	this.tooltip = {
		x: {
			show: true,
			format: 'dd MMM',
			formatter: undefined,
		},
        y: {
          formatter: function(val) {
            return ""
          },
          title: {
            formatter: function (seriesName) {
              return seriesName
            }
          }
      }
    };
  }

  ngOnInit() {
		this.subcriptionIdentity = this.store.select('auth').subscribe(
			(state: AuthState) => {
				this.identity = {...state.identity};
			}
		);
		this.subcriptionDiaNoLaboral = this.store.select('dianolaboral').subscribe(
			(state) => {
				this.diasnolobaral = [...state.dias];
			}
		);
		this.subcriptionCumplimiento = this.store.select('cumplimiento').subscribe(
			(state) => {
				this.loading = false;
				this.paginacion.totalSize = state.totalCumplimiento;
				this.paginacion.totalFiltro = state.totalFiltroCumplimiento;	
				// this.heading = (this.labels.filter( item => item.labels.length) * 2);
				this.labels = [...state.labels];			
				const pipeOrderByPipe = new OrderByPipe();
				this.dataSets  = pipeOrderByPipe.transform([...state.cumplimiento.map( item => { return item.dataSet; })], '-name');

				if(this.dataSets.length > 3){
					this.height = this.dataSets.length * 60;
				}else if(this.dataSets.length === 3){
					this.height = this.dataSets.length * 80;
				}else if(this.dataSets.length === 2){
					this.height = this.dataSets.length * 100;
				}else if(this.dataSets.length === 1){
					this.height = this.dataSets.length * 120;
				}
			}
		);
		this.subcriptionCatalogo = this.store.select('catalogos').subscribe(
      (state: TiposState) => {
        this.filterTipos = [...state.tipos];
      }
	 );
		this.subscribeConsulta = this.store.select('consultaCatalogos').subscribe(
      (state) => {
        this.filterDepartamentos = [...state.departamentos.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
      }
    );
		this.store.dispatch(new usuariosActions.ConsultaCatalogoDepartamentos());
		this.store.dispatch(new usuariosActions.LoadTypeUsers());
  }


  ngOnDestroy() {
		this.subcriptionIdentity.unsubscribe();
		this.subcriptionDiaNoLaboral.unsubscribe();
		this.subcriptionCumplimiento.unsubscribe();
  }

	
  formatoFecha(value, timestamp, index) {
 		return moment(new Date(value + ' 12:00')).format("DD MMM")
  }

  fecha(fecha:Date[]){
		if(fecha && fecha.length === 2){
			const fecha1= this.datePipe.transform(fecha["0"], 'yyyy-MM-dd')
			const fecha2= this.datePipe.transform(fecha["1"], 'yyyy-MM-dd');
			this.dias = {fechaInicio: fecha1, fechaFinal: fecha2}
			this.store.dispatch(new usuariosActions.CargarDiasNoLaborables(this.dias));
			this.cargarCumplimiento();
		}
  }

  onSubmit(form){
		this.loading = true;
		this.nuevoDiaNoLaboral.dia = this.datePipe.transform(this.nuevoDiaNoLaboral.dia, 'yyyy-MM-dd');
		this.store.dispatch(new usuariosActions.ActualizaDiaNoLaboral(this.nuevoDiaNoLaboral));
		this.nuevoDiaNoLaboral = new Dianolaboral(null);
		this.cargarCumplimiento();
		form.reset();
  }

  deleteDianolaboral(id){
		this.store.dispatch(new usuariosActions.EliminaDiaNoLaboral(id));
		this.cargarCumplimiento();
  }
  
  actulizar(event){
		if(this.identity.permisos.seguimientoCumplimientoEdit){
			if(event.target.getAttribute ( "i" ) != null){
				const usuario = event.target.getAttribute ( "i" );
				const dia = event.target.getAttribute ( "j" );
				const valor: number = event.target.getAttribute ( "val" );
				const id: number = this.dataSets[usuario].id;
				const fecha :any = this.labels[dia];
				this.justificacion = {id, valor, fecha};
				this.store.dispatch(new usuariosActions.ActualizaCumplimiento(this.justificacion));
			}
		}
	}

	cargarCumplimiento(reset = true) {
    if (reset) {
      this.paginacion.page = 1;
    }
    const opciones: any = {};
    opciones.numPage = this.paginacion.page;
    opciones.numLimit = this.paginacion.pageSize;
    // opciones.desOrderBy = this.paginacion.sortType;
		opciones.textSearch = this.listSearch.join('%');
    opciones.desDepartamento = this.filterDepartamentos.filter( itm => itm.checked).map( itm => itm.id).join(',');
		opciones.desTipo = this.filterTipos.filter( itm => itm.checked).map( itm => itm.id).join(',');
    this.store.dispatch(new usuariosActions.CargarCumplimiento(this.dias, opciones));
  }

	limpiar(form){
		this.nuevoDiaNoLaboral = new Dianolaboral(null);
		form.reset();
	}

	changeDepartamentos(){
		this.filterDepartamentos = [...this.filterDepartamentos];
    this.cargarCumplimiento();
	}

	changeTipos(){
		this.filterTipos = [...this.filterTipos];
    this.cargarCumplimiento();
	}

	searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchCumplimiento").focus();
  }


  closeSearch() {
    this.paginacion.isActive = false;
    this.paginacion.textSearch = '';
    this.listSearch = [];
    this.cargarCumplimiento();
  }

	changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.trim().split(' ').filter( itm => itm);
    if (this.paginacion.textSearch.trim() !== this.paginacion.textSearchAnterior) {
      this.cargarCumplimiento();
    }
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
    this.cargarCumplimiento();
  }

}