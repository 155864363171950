import * as fromUsuario from '../actions';
import { Indicador,LisIndicador } from 'src/app/models/indicador';

export interface IndicadorState {
  indicadores: LisIndicador[];
  indicador: Indicador;
  tipo: any[];
  captura: any[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: IndicadorState = {
  indicadores: [],
  indicador: new Indicador(null),
  tipo: [],
  captura: [],
  loaded: false,
  loading: false,
  error: null
};

export function indicadorReducer( state = estadoInicial, action: fromUsuario.indicadoresAcciones ): IndicadorState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_INDICADORES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_INDICADORES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        indicadores: [...action.indicadores]
      };
    case fromUsuario.CARGAR_INDICADORES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CARGAR_INDICADOR:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_INDICADOR_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        indicador: {...action.indicador}
        };
    case fromUsuario.CARGAR_INDICADOR_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
        };
    case fromUsuario.CARGAR_TIPO_INDICADOR:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_TIPO_INDICADOR_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tipo: [...action.tipo]
        };
    case fromUsuario.CARGAR_TIPO_INDICADOR_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.ACTUALIZA_INDICADOR:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_INDICADOR_SUCCESS:
      const itm = state.indicadores.find( item => item.id === action.indicador.id);
      if (itm && itm.id) {
        state.indicadores = state.indicadores.map( item => {
          if (item.id === action.indicador.id) {
            item = {...action.indicador};
          }
          return item;
        });
      } else {
        state.indicadores.push(action.indicador);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_INDICADOR_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_INDICADOR_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_INDICADOR_ESTATUS_SUCCESS:
      state.indicadores = state.indicadores.map( item => {
        if (item.id === action.id) {
          item.estado = !item.estado;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_INDICADOR_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_INDICADOR:
      return {
        ...state,
        indicador: new Indicador(null)
      };
    default:
      return state;
  }
}

