<div class="col-12 mt-3">
	<div class="form-group">
		<div class="row">
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 1}" (click)="filterPermisos = 1; filterSelecionar()" title="Ver y editar todos los permisos">TODOS</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 2}" (click)="filterPermisos = 2; filterSelecionar()" title="Ver y editar todos los permisos de consulta">Consulta</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 3}" (click)="filterPermisos = 3; filterSelecionar()" title="Ver y editar todos los permisosd de registros">Registros</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 4}" (click)="filterPermisos = 4; filterSelecionar()" title="Ver y editar empresas">Empresas</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 5}" (click)="filterPermisos = 5; filterSelecionar()" title="Ver y editar oportunidades">Oportunidades</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 6}" (click)="filterPermisos = 6; filterSelecionar()" title="Ver los reportes">Reportes</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 7}" (click)="filterPermisos = 7; filterSelecionar()" title="Ver y editar contactos">Contactos</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 9}" (click)="filterPermisos = 9; filterSelecionar()" title="Configuración de los catálogos de departamentos, países, origenes de contactos, industrias y tipos de empresas">Catálogos</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 10}" (click)="filterPermisos = 10; filterSelecionar()" title="Ver y editar indicadores">Indicadores</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 11}" (click)="filterPermisos = 11; filterSelecionar()" title="Ver y editar asignaciones">Asignaciones</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 12}" (click)="filterPermisos = 12; filterSelecionar()" title="Ver y editar usuarios">Usuarios</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 13}" (click)="filterPermisos = 13; filterSelecionar()" title="Ver y editar actividades">Actividades</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 14}" (click)="filterPermisos = 14; filterSelecionar()" title="Configuración del control de las actividades">Control</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 15}" (click)="filterPermisos = 15; filterSelecionar()" title="Contestar tickets">Tickets</button>
			</div>
		</div>
	</div>
</div>
<ngb-tabset class="tabs-animated tabs-animated-line" justify="justified">
	<ngb-tab>
		<ng-template ngbTabTitle><span>Permisos</span></ng-template>
		<ng-template ngbTabContent>
			<ul class="todo-list-wrapper list-group list-group-flush">
				<li class="list-group-item">
					<div class="widget-content p-0">
						<div class="widget-content-wrapper">
							<div class="widget-content-left mr-2">
								<div class="custom-checkbox custom-control">
									<input type="checkbox" id="allPermisosForm" name="allPermisosForm" #allPermisosForm="ngModel" [(ngModel)]="todo" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="seleccionar()">
									<label class="custom-control-label" for="allPermisosForm">&nbsp; Seleccionar todo</label>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<hr class="p-0 m-0">
			<div class="scroll-area-lg">
				<div class="scrollbar-container">
					<perfect-scrollbar [autoPropagation]="true">
						<ul class="todo-list-wrapper list-group list-group-flush">
							<!-- 12 - Usuarios -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 12 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormusuariosListEdit" name="permisoFormusuariosListEdit" #permisoFormusuariosListEdit="ngModel" [(ngModel)]="permisos.usuariosListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormusuariosListEdit">&nbsp; Editar lista de usuarios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item"*ngIf="filterPermisos === 1 || filterPermisos === 12 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormusuariosListVer" name="permisoFormusuariosListVer" #permisoFormusuariosListVer="ngModel" [(ngModel)]="permisos.usuariosListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormusuariosListVer">&nbsp; Ver lista de usuarios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 12 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormusuariosRegEdit" name="permisoFormusuariosRegEdit" #permisoFormusuariosRegEdit="ngModel" [(ngModel)]="permisos.usuariosRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormusuariosRegEdit">&nbsp; Editar detalle de usuarios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 12 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormusuariosRegVer" name="permisoFormusuariosRegVer" #permisoFormusuariosRegVer="ngModel" [(ngModel)]="permisos.usuariosRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormusuariosRegVer">&nbsp; Ver detalle de usuarios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 4 - Empresas -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormEmpresasListEdit" name="permisoFormEmpresasListEdit" #permisoFormEmpresasListEdit="ngModel" [(ngModel)]="permisos.empresasListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormEmpresasListEdit">&nbsp; Editar lista de empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormempresasListVer" name="permisoFormempresasListVer" #permisoFormempresasListVer="ngModel" [(ngModel)]="permisos.empresasListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormempresasListVer">&nbsp; Ver lista de empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormempresasRegEdit" name="permisoFormempresasRegEdit" #permisoFormempresasRegEdit="ngModel" [(ngModel)]="permisos.empresasRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormempresasRegEdit">&nbsp; Editar detalle de empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormempresasRegVer" name="permisoFormempresasRegVer" #permisoFormempresasRegVer="ngModel" [(ngModel)]="permisos.empresasRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormempresasRegVer">&nbsp; Ver detalle de empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormEmpresasDownload" name="permisoFormEmpresasDownload" #permisoFormEmpresasDownload="ngModel" [(ngModel)]="permisos.empresasDownload" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormEmpresasDownload">&nbsp; Descargar empresas en XLSX</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 7 - Contactos -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 7 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormcontactosListEdit" name="permisoFormcontactosListEdit" #permisoFormcontactosListEdit="ngModel" [(ngModel)]="permisos.contactosListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormcontactosListEdit">&nbsp; Editar lista de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 7 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormcontactosListVer" name="permisoFormcontactosListVer" #permisoFormcontactosListVer="ngModel" [(ngModel)]="permisos.contactosListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormcontactosListVer">&nbsp; Ver lista de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 7 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormcontactosRegEdit" name="permisoFormcontactosRegEdit" #permisoFormcontactosRegEdit="ngModel" [(ngModel)]="permisos.contactosRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormcontactosRegEdit">&nbsp; Editar detalle de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 7 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormcontactosRegVer" name="permisoFormcontactosRegVer" #permisoFormcontactosRegVer="ngModel" [(ngModel)]="permisos.contactosRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormcontactosRegVer">&nbsp; Ver detalle de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 7 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormContactosDownload" name="permisoFormContactosDownload" #permisoFormContactosDownload="ngModel" [(ngModel)]="permisos.contactosDownload" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormContactosDownload">&nbsp; Descargar contactos en XLSX</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 5 - Oportunidades -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 5 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormoportunidadesListEdit" name="permisoFormoportunidadesListEdit" #permisoFormoportunidadesListEdit="ngModel" [(ngModel)]="permisos.oportunidadesListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormoportunidadesListEdit">&nbsp; Editar lista de oportunidades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 5 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormoportunidadesListVer" name="permisoFormoportunidadesListVer" #permisoFormoportunidadesListVer="ngModel" [(ngModel)]="permisos.oportunidadesListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormoportunidadesListVer">&nbsp; Ver lista de oportunidades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 5 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormoportunidadesRegEdit" name="permisoFormoportunidadesRegEdit" #permisoFormoportunidadesRegEdit="ngModel" [(ngModel)]="permisos.oportunidadesRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormoportunidadesRegEdit">&nbsp; Editar detalle de oportunidades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 5 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormoportunidadesRegVer" name="permisoFormoportunidadesRegVer" #permisoFormoportunidadesRegVer="ngModel" [(ngModel)]="permisos.oportunidadesRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormoportunidadesRegVer">&nbsp; Ver detalle de oportunidades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 5 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormOportunidadesDownload" name="permisoFormOportunidadesDownload" #permisoFormOportunidadesDownload="ngModel" [(ngModel)]="permisos.oportunidadesDownload" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormOportunidadesDownload">&nbsp; Descargar oportunidades en XLSX</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 5 || filterPermisos === 7 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesMultipleVer" name="permisoFormActividadesMultipleVer" #permisoFormActividadesMultipleVer="ngModel" [(ngModel)]="permisos.actividadesMultipleVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesMultipleVer">&nbsp; Ver lista de actividades de contactos, oportunidades y empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 5 || filterPermisos === 7 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesMultipleEdit" name="permisoFormActividadesMultipleEdit" #permisoFormActividadesMultipleEdit="ngModel" [(ngModel)]="permisos.actividadesMultipleEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesMultipleEdit">&nbsp; Editar detalle de actividades de contactos, oportunidades y empresas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
								<!-- 10 - Indicadores -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 10 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormindicadorListEdit" name="permisoFormindicadorListEdit" #permisoFormindicadorListEdit="ngModel" [(ngModel)]="permisos.indicadorListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormindicadorListEdit">&nbsp; Editar lista de indicador</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 10 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormindicadorListVer" name="permisoFormindicadorListVer" #permisoFormindicadorListVer="ngModel" [(ngModel)]="permisos.indicadorListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormindicadorListVer">&nbsp; Ver lista de indicador</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 10 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormindicadorRegEdit" name="permisoFormindicadorRegEdit" #permisoFormindicadorRegEdit="ngModel" [(ngModel)]="permisos.indicadorRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormindicadorRegEdit">&nbsp; Editar detalle de indicador</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 10 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormindicadorRegVer" name="permisoFormindicadorRegVer" #permisoFormindicadorRegVer="ngModel" [(ngModel)]="permisos.indicadorRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormindicadorRegVer">&nbsp; Ver detalle de indicador</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 10 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormindicadorCapturar" name="permisoFormindicadorCapturar" #permisoFormindicadorCapturar="ngModel" [(ngModel)]="permisos.indicadorCapturar" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormindicadorCapturar">&nbsp; Captura indicadores</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 11 - Asignaciones -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 11 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormAsignacionListEdit" name="permisoFormAsignacionListEdit" #permisoFormAsignacionListEdit="ngModel" [(ngModel)]="permisos.asignacionListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormAsignacionListEdit">&nbsp; Editar lista de asignacion</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 11 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormAsignacionListVer" name="permisoFormAsignacionListVer" #permisoFormAsignacionListVer="ngModel" [(ngModel)]="permisos.asignacionListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormAsignacionListVer">&nbsp; Ver lista de asignacion</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 11 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormAsignacionRegEdit" name="permisoFormAsignacionRegEdit" #permisoFormAsignacionRegEdit="ngModel" [(ngModel)]="permisos.asignacionRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormAsignacionRegEdit">&nbsp; Editar detalle de asignacion</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 11 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormAsignacionRegVer" name="permisoFormAsignacionRegVer" #permisoFormAsignacionRegVer="ngModel" [(ngModel)]="permisos.asignacionRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormAsignacionRegVer">&nbsp; Ver detalle de asignacion</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 11 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormAsignacionCapturar" name="permisoFormAsignacionCapturar" #permisoFormaasignacionCapturar="ngModel" [(ngModel)]="permisos.asignacionCapturar" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormAsignacionCapturar">&nbsp; Captura indicadores operativos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 13 - Actividades -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 13 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesListEdit" name="permisoFormActividadesListEdit" #permisoFormActividadesListEdit="ngModel" [(ngModel)]="permisos.actividadesListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesListEdit">&nbsp; Editar lista de actividades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 13 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesListVer" name="permisoFormActividadesListVer" #permisoFormActividadesListVer="ngModel" [(ngModel)]="permisos.actividadesListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesListVer">&nbsp; Ver lista de actividades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 13 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesRegEdit" name="permisoFormActividadesRegEdit" #permisoFormActividadesRegEdit="ngModel" [(ngModel)]="permisos.actividadesRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesRegEdit">&nbsp; Editar detalle de actividades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 13 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormActividadesRegVer" name="permisoFormActividadesRegVer" #permisoFormActividadesRegVer="ngModel" [(ngModel)]="permisos.actividadesRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormActividadesRegVer">&nbsp; Ver detalle de actividades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 9 - Catálogos -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDepartamentosRegEdit" name="permisoFormDepartamentosRegEdit" #permisoFormDepartamentosRegEdit="ngModel" [(ngModel)]="permisos.catDepartamentosEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDepartamentosRegEdit">&nbsp; Editar detalle del catálogo áreas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDepartamentosVer" name="permisoFormDepartamentosVer" #permisoFormDepartamentosVer="ngModel" [(ngModel)]="permisos.catDepartamentosVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDepartamentosVer">&nbsp; Ver detalle del catálogo áreas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormOrigenesContactoRegEdit" name="permisoFormOrigenesContactoRegEdit" #permisoFormOrigenesContactoRegEdit="ngModel" [(ngModel)]="permisos.catOrigenesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormOrigenesContactoRegEdit">&nbsp; Editar detalle del catálogo orígenes de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormOrigenesContactoVer" name="permisoFormOrigenesContactoVer" #permisoFormOrigenesContactoVer="ngModel" [(ngModel)]="permisos.catOrigenesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormOrigenesContactoVer">&nbsp; Ver detalle del catálogo orígenes de contactos</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormIndustriasRegEdit" name="permisoFormIndustriasRegEdit" #permisoFormIndustriasRegEdit="ngModel" [(ngModel)]="permisos.catIndustriasEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormIndustriasRegEdit">&nbsp; Editar detalle del catálogo industrias</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormIndustriasVer" name="permisoFormIndustriasVer" #permisoFormIndustriasVer="ngModel" [(ngModel)]="permisos.catIndustriasVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormIndustriasVer">&nbsp; Ver detalle del catálogo industrias</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormPaisesRegEdit" name="permisoFormPaisesRegEdit" #permisoFormPaisesRegEdit="ngModel" [(ngModel)]="permisos.catPaisesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormPaisesRegEdit">&nbsp; Editar detalle del catálogo países</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormPaisesVer" name="permisoFormPaisesVer" #permisoFormPaisesVer="ngModel" [(ngModel)]="permisos.catPaisesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormPaisesVer">&nbsp; Ver detalle del catálogo países</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDivisionesEdit" name="permisoFormDivisionesEdit" #permisoFormDivisionesEdit="ngModel" [(ngModel)]="permisos.catDivisionesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDivisionesEdit">&nbsp; Editar detalle del catálogo divisiones</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormDivisionesRegVer" name="permisoFormDivisionesRegVer" #permisoFormDivisionesRegVer="ngModel" [(ngModel)]="permisos.catDivisionesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormDivisionesRegVer">&nbsp; Ver detalle del catálogo divisiones</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormSucursalesRegEdit" name="permisoFormSucursalesRegEdit" #permisoFormSucursalesRegEdit="ngModel" [(ngModel)]="permisos.catSucursalesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormSucursalesRegEdit">&nbsp; Editar detalle del catálogo sucursales</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormSucursalesVer" name="permisoFormSucursalesVer" #permisoFormSucursalesVer="ngModel" [(ngModel)]="permisos.catSucursalesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormSucursalesVer">&nbsp; Ver detalle del catálogo sucursales</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormColoresRegEdit" name="permisoFormColoresRegEdit" #permisoFormColoresRegEdit="ngModel" [(ngModel)]="permisos.catColoresEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormColoresRegEdit">&nbsp; Editar detalle del catálogo colores</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormColoresVer" name="permisoFormColoresVer" #permisoFormColoresVer="ngModel" [(ngModel)]="permisos.catColoresVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormColoresVer">&nbsp; Ver detalle del catálogo colores</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormServiciosRegEdit" name="permisoFormServiciosRegEdit" #permisoFormServiciosRegEdit="ngModel" [(ngModel)]="permisos.catServiciosEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormServiciosRegEdit">&nbsp; Editar detalle del catálogo servicios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormServiciosVer" name="permisoFormServiciosVer" #permisoFormServiciosVer="ngModel" [(ngModel)]="permisos.catServiciosVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormServiciosVer">&nbsp; Ver detalle del catálogo servicios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormClientesRegEdit" name="permisoFormClientesRegEdit" #permisoFormClientesRegEdit="ngModel" [(ngModel)]="permisos.catClienteEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormClientesRegEdit">&nbsp; Editar detalle del catálogo clientes</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormClientesVer" name="permisoFormClientesVer" #permisoFormClientesVer="ngModel" [(ngModel)]="permisos.catClienteVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormClientesVer">&nbsp; Ver detalle del catálogo clientes</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormTiposServicioRegEdit" name="permisoFormTiposServicioRegEdit" #permisoFormTiposServicioRegEdit="ngModel" [(ngModel)]="permisos.catTipoServicioEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormTiposServicioRegEdit">&nbsp; Editar detalle del catálogo tipos de servicio</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 9 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormTiposServicioVer" name="permisoFormTiposServicioVer" #permisoFormTiposServicioVer="ngModel" [(ngModel)]="permisos.catTipoServicioVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormTiposServicioVer">&nbsp; Ver detalle del catálogo tipos de servicio</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 6 - Reportes -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptKpisVendedoresVer" name="permisoFormRptKpisVendedoresVer" #permisoFormRptKpisVendedoresVer="ngModel" [(ngModel)]="permisos.rptKpiVendedores" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptKpisVendedoresVer">&nbsp; Ver reporte de KPIs de vendedores</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptCumpleanios" name="permisoFormRptCumpleanios" #permisoFormRptCumpleanios="ngModel" [(ngModel)]="permisos.rptCumpleannos" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptCumpleanios">&nbsp; Ver reporte de cumpleaños</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptOportunidadesXEtapas" name="permisoFormRptOportunidadesXEtapas" #permisoFormRptOportunidadesXEtapas="ngModel" [(ngModel)]="permisos.rptOportunidadEtapas" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptOportunidadesXEtapas">&nbsp; Ver reporte de oportunidades por etapas</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptOportunidadesXPropietario" name="permisoFormRptOportunidadesXPropietario" #permisoFormRptOportunidadesXPropietario="ngModel" [(ngModel)]="permisos.rptOportunidadPropietarios" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptOportunidadesXPropietario">&nbsp; Ver reporte de oportunidades por propietarios</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptCobertura" name="permisoFormRptCobertura" #permisoFormRptCobertura="ngModel" [(ngModel)]="permisos.rptCobertura" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptCobertura">&nbsp; Ver reporte de cobertura operacional</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptCoberturaXDia" name="permisoFormRptCoberturaXDia" #permisoFormRptCoberturaXDia="ngModel" [(ngModel)]="permisos.rptCoberturaDia" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptCoberturaXDia">&nbsp; Ver reporte de cobertura operacional por día</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptActividades" name="permisoFormRptActividades" #permisoFormRptActividades="ngModel" [(ngModel)]="permisos.rptActividades" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptActividades">&nbsp; Ver reporte de actividades</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptServicioCop" name="permisoFormRptServicioCop" #permisoFormRptServicioCop="ngModel" [(ngModel)]="permisos.rptServicioCop" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptServicioCop">&nbsp; Ver reporte de cobertura operacional por servicio</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptServicioInicia" name="permisoFormRptServicioInicia" #permisoFormRptServicioInicia="ngModel" [(ngModel)]="permisos.rptServicioInicia" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptServicioInicia">&nbsp; Ver reporte de servicios que inician en</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptServicioTermina" name="permisoFormRptServicioTermina" #permisoFormRptServicioTermina="ngModel" [(ngModel)]="permisos.rptServicioTermina" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptServicioTermina">&nbsp; Ver reporte de servicios que finaliza en</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptBajaVacantes" name="permisoFormRptBajaVacantes" #permisoFormRptBajaVacantes="ngModel" [(ngModel)]="permisos.rptBajaVacantes" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptBajaVacantes">&nbsp; Ver reporte de bajas y vacantes</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptIndicador" name="permisoFormRptIndicador" #permisoFormRptIndicador="ngModel" [(ngModel)]="permisos.rptIndicador" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptIndicador">&nbsp; Ver reporte de KPIs</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptIndicadorXArea" name="permisoFormRptIndicadorXArea" #permisoFormRptIndicadorXArea="ngModel" [(ngModel)]="permisos.rptIndicadorArea" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptIndicadorXArea">&nbsp; Ver reporte de KPIs por área</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 6 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormRptIndicadorXUsuario" name="permisoFormRptIndicadorXUsuario" #permisoFormRptIndicadorXUsuario="ngModel" [(ngModel)]="permisos.rptIndicadorUsuario" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormRptIndicadorXUsuario">&nbsp; Ver reporte de KPIs por usuario</label>
											</div>
										</div>
									</div>
								</div>
							</li>
								<!-- 14 - Control -->
								<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 14 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormSeguiminetoCumplimientoEdit" name="permisoFormSeguiminetoCumplimientoEdit" #permisoFormSeguiminetoCumplimientoEdit="ngModel" [(ngModel)]="permisos.seguimientoCumplimientoEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormSeguiminetoCumplimientoEdit">&nbsp; Editar detalle de seguimineto cumplimiento</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 14 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormSeguiminetoCumplimientoVer" name="permisoFormSeguiminetoCumplimientoVer" #permisoFormSeguiminetoCumplimientoVer="ngModel" [(ngModel)]="permisos.seguimientoCumplimientoVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormSeguiminetoCumplimientoVer">&nbsp; Ver detalle de seguimineto cumplimiento</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 14 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormVacacionesEdit" name="permisoFormVacacionesEdit" #permisoFormVacacionesEdit="ngModel" [(ngModel)]="permisos.vacacionesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormVacacionesEdit">&nbsp; Editar detalle de vanaciones</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 14 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormVacacionesVer" name="permisoFormVacacionesVer" #permisoFormVacacionesVer="ngModel" [(ngModel)]="permisos.vacacionesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormVacacionesVer">&nbsp; Ver detalle de vanaciones</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- 15 - Tickets -->
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 15 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormTicketsRegEdit" name="permisoFormTicketsRegEdit" #permisoFormTicketsRegEdit="ngModel" [(ngModel)]="permisos.ticketsEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormTicketsRegEdit">&nbsp; Editar detalle de tickets</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 15 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormTicketsVer" name="permisoFormTicketsVer" #permisoFormTicketsVer="ngModel" [(ngModel)]="permisos.ticketsVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormTicketsVer">&nbsp; Ver detalle de tickets</label>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</perfect-scrollbar>
				</div>
			</div>
		</ng-template>
	</ngb-tab>
</ngb-tabset>