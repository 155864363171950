import { Action } from '@ngrx/store';
import { Sucursal } from 'src/app/models/sucursales';
import { Division } from 'src/app/models/division';

export const CARGAR_SUCURSALES = '[Sucursal] Cargar sucursales';
export const CARGAR_SUCURSALES_FAIL = '[Sucursal] Cargar sucursales FAIL';
export const CARGAR_SUCURSALES_SUCCESS = '[Sucursal] Cargar sucursales SUCCESS';
export const ACTUALIZA_SUCURSAL = '[Sucursal] Actualiza sucursales';
export const ACTUALIZA_SUCURSAL_FAIL = '[Sucursal] Actualiza sucursales FAIL';
export const ACTUALIZA_SUCURSAL_SUCCESS = '[Sucursal] Actualiza sucursales SUCCESS';
export const ACTUALIZA_SUCURSAL_ESTATUS = '[Sucursal] Actualiza sucursales estatus';
export const ACTUALIZA_SUCURSAL_ESTATUS_FAIL = '[Sucursal] Actualiza sucursales estatus FAIL';
export const ACTUALIZA_SUCURSAL_ESTATUS_SUCCESS = '[Sucursal] Actualiza sucursales estatus SUCCESS';

export class CargarSucursales implements Action {
  readonly type = CARGAR_SUCURSALES;
}

export class CargarSucursalesFail implements Action {
  readonly type = CARGAR_SUCURSALES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarSucursalesSuccess implements Action {
  readonly type = CARGAR_SUCURSALES_SUCCESS;

  constructor( public sucursales: Sucursal[], public divisiones: Division[] ) {}
}

export class ActualizaSucursal implements Action {
  readonly type = ACTUALIZA_SUCURSAL;

  constructor( public sucursal: Sucursal ) { }
}

export class ActualizaSucursalFail implements Action {
  readonly type = ACTUALIZA_SUCURSAL_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaSucursalSuccess implements Action {
  readonly type = ACTUALIZA_SUCURSAL_SUCCESS;

  constructor( public sucursal: Sucursal ) {}
}

export class ActualizaSucursalEstatus implements Action {
  readonly type = ACTUALIZA_SUCURSAL_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaSucursalEstatusFail implements Action {
  readonly type = ACTUALIZA_SUCURSAL_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaSucursalEstatusSuccess implements Action {
  readonly type = ACTUALIZA_SUCURSAL_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export type sucursalesAcciones =  CargarSucursales 
                                | CargarSucursalesFail 
                                | CargarSucursalesSuccess 
                                | ActualizaSucursal 
                                | ActualizaSucursalFail 
                                | ActualizaSucursalSuccess 
                                | ActualizaSucursalEstatus 
                                | ActualizaSucursalEstatusFail 
                                | ActualizaSucursalEstatusSuccess;

