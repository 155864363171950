export class Anexo {
	public reporte:		boolean;
	public testimonio:	boolean;
	public fotos:			boolean;
	public documento:		boolean;
	public otro:			boolean;
	public anotaciones:	string;	
	constructor(
		 obj: DataObjAnexo
	) {
		this.reporte		 =obj && obj.reporte? obj.reporte: false;
		this.testimonio	 =obj && obj.testimonio? obj.testimonio : false;
		this.fotos			 =obj && obj.fotos? obj.fotos : false;
		this.documento	 	 =obj && obj.documento? obj.documento : false;	
		this.otro			 =obj && obj.otro? obj.otro : false;
		this.anotaciones	 =obj && obj.anotaciones? obj.anotaciones : '';
	}
}
interface DataObjAnexo{
	reporte:			boolean;
	testimonio:		boolean;
	fotos:			boolean;
	documento:		boolean;
	otro:				boolean;
	anotaciones:	string;
}