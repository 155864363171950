import * as fromUsuarios from '../actions';
import { User } from 'src/app/models/user';
import { SeriesBar, FunnelGraphColors } from 'src/app/models/graph';

export interface DashboardState {
  usuariosConectados: User[];
	labelsContactos: string[];
	seriesContactos: SeriesBar[];
	labelsEmpresas: string[];
  seriesEmpresas: SeriesBar[];
  labelsOportunidades: string[];
  seriesOportunidades: SeriesBar[];
	hoyContactos: number;
  hoyEmpresas: number;
  hoyOportunidades: number;
  cantidadActividades: number;
  hoyActividades: number;
  dataFunnel: FunnelGraphColors[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: DashboardState = {
  usuariosConectados: [],
	labelsContactos:[],
	seriesContactos:[],
	labelsEmpresas:[],
  seriesEmpresas:[],
  labelsOportunidades:[],
  seriesOportunidades:[],
  hoyContactos: 0,
  hoyEmpresas: 0,
  hoyOportunidades: 0,
  cantidadActividades: 0,
  hoyActividades: 0,
  dataFunnel: [],
  loaded: false,
  loading: false,
  error: null
};

export function dashboardReducer( state = estadoInicial, action: fromUsuarios.dashboardAcciones ): DashboardState {

  switch ( action.type ) {
    case fromUsuarios.CARGAR_DASHBOARD_USUARIOS_CONECTADOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_USUARIOS_CONECTADOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        usuariosConectados: [...action.usuarios]
      };
    case fromUsuarios.CARGAR_DASHBOARD_USUARIOS_CONECTADOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_CONTACTOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_CONTACTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        labelsContactos: [...action.labels],
        seriesContactos: [...action.series],
        hoyContactos: action.hoy
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_CONTACTOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_EMPRESAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_EMPRESAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        labelsEmpresas: [...action.labels],
        seriesEmpresas: [...action.series],
        hoyEmpresas: action.hoy
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_EMPRESAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_OPORTUNIDADES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_OPORTUNIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        labelsOportunidades: [...action.labels],
        seriesOportunidades: [...action.series],
        hoyOportunidades: action.hoy
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_OPORTUNIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_CANTIDAD_ACTIVIDADES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_CANTIDAD_ACTIVIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        cantidadActividades: action.cantidad,
        hoyActividades: action.hoy
      };
    case fromUsuarios.CARGAR_DASHBOARD_CANTIDAD_ACTIVIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_FUNNEL_OPORTUNIDADES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_FUNNEL_OPORTUNIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				dataFunnel: [...action.datas]
      };
    case fromUsuarios.CARGAR_DASHBOARD_FUNNEL_OPORTUNIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    default:
      return state;
  }
}

