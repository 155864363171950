import { Action } from '@ngrx/store';
import { Indicador,LisIndicador } from 'src/app/models/indicador';

export const CARGAR_INDICADORES = '[Indicador] Cargar indicadores';
export const CARGAR_INDICADORES_FAIL = '[Indicador] Cargar indicadores FAIL';
export const CARGAR_INDICADORES_SUCCESS = '[Indicador] Cargar indicadores SUCCESS';
export const CARGAR_INDICADOR = '[Indicador] Cargar indicador';
export const CARGAR_INDICADOR_FAIL = '[Indicador] Cargar indicador FAIL';
export const CARGAR_INDICADOR_SUCCESS = '[Indicador] Cargar indicador SUCCESS';
export const CARGAR_TIPO_INDICADOR = '[Indicador] Cargar tipo de indicador';
export const CARGAR_TIPO_INDICADOR_FAIL = '[Indicador] Cargar tipo de indicador FAIL';
export const CARGAR_TIPO_INDICADOR_SUCCESS = '[Indicador] Cargar tipo de indicador SUCCESS';
export const ACTUALIZA_INDICADOR = '[Indicador] Actualiza indicadores';
export const ACTUALIZA_INDICADOR_FAIL = '[Indicador] Actualiza indicadores FAIL';
export const ACTUALIZA_INDICADOR_SUCCESS = '[Indicador] Actualiza indicadores SUCCESS';
export const ACTUALIZA_INDICADOR_ESTATUS = '[Indicador] Actualiza indicadores estatus';
export const ACTUALIZA_INDICADOR_ESTATUS_FAIL = '[Indicador] Actualiza indicadores estatus FAIL';
export const ACTUALIZA_INDICADOR_ESTATUS_SUCCESS = '[Indicador] Actualiza indicadores estatus SUCCESS';
export const LIMPIAR_INDICADOR = '[Indicador] Limpiar indicador';



export class CargarIndicadores implements Action {
  readonly type = CARGAR_INDICADORES;
}

export class CargarIndicadoresFail implements Action {
  readonly type = CARGAR_INDICADORES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarIndicadoresSuccess implements Action {
  readonly type = CARGAR_INDICADORES_SUCCESS;

  constructor( public indicadores: LisIndicador[] ) {}
}

export class CargarIndicador implements Action {
  readonly type = CARGAR_INDICADOR;

  constructor( public id: number ) {}
}

export class CargarIndicadorFail implements Action {
  readonly type = CARGAR_INDICADOR_FAIL;

  constructor( public payload: any ) {}
}

export class CargarIndicadorSuccess implements Action {
  readonly type = CARGAR_INDICADOR_SUCCESS;

  constructor( public indicador: Indicador ) {}
}

export class CargarTipoIndicador implements Action {
  readonly type = CARGAR_TIPO_INDICADOR;
}

export class CargarTipoIndicadorFail implements Action {
  readonly type = CARGAR_TIPO_INDICADOR_FAIL;

  constructor( public payload: any ) {}
}

export class CargarTipoIndicadorSuccess implements Action {
  readonly type = CARGAR_TIPO_INDICADOR_SUCCESS;

  constructor( public tipo: any[] ) {}
}

export class ActualizaIndicador implements Action {
  readonly type = ACTUALIZA_INDICADOR;

  constructor( public indicador: Indicador, public accion: number, public  usuario: any) { }
}

export class ActualizaIndicadorFail implements Action {
  readonly type = ACTUALIZA_INDICADOR_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaIndicadorSuccess implements Action {
  readonly type = ACTUALIZA_INDICADOR_SUCCESS;

  constructor( public indicador: Indicador ) {}
}

export class ActualizaIndicadorEstatus implements Action {
  readonly type = ACTUALIZA_INDICADOR_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaIndicadorEstatusFail implements Action {
  readonly type = ACTUALIZA_INDICADOR_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaIndicadorEstatusSuccess implements Action {
  readonly type = ACTUALIZA_INDICADOR_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export class LimpiarIndicador implements Action {
    readonly type = LIMPIAR_INDICADOR;

    constructor() {}  
}

export type indicadoresAcciones =  CargarIndicadores 
                                | CargarIndicadoresFail 
                                | CargarIndicadoresSuccess 
                                | CargarIndicador 
                                | CargarIndicadorFail 
                                | CargarIndicadorSuccess 
                                | CargarTipoIndicador 
                                | CargarTipoIndicadorFail 
                                | CargarTipoIndicadorSuccess 
                                | ActualizaIndicador 
                                | ActualizaIndicadorFail 
                                | ActualizaIndicadorSuccess 
                                | ActualizaIndicadorEstatus 
                                | ActualizaIndicadorEstatusFail 
                                | ActualizaIndicadorEstatusSuccess
                                | LimpiarIndicador