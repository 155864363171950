import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.sass']
})
export class ReportesComponent implements OnInit, OnDestroy {
  heading = 'Detalles de los reportes';
  subheading = 'Listado de los reportes del sistema';
  icon = 'pe-7s-display2 icon-gradient bg-dark';
  private subscribeAuth: Subscription = new Subscription();
  public identity: Identity;
  public loading = false;
  public url: string;
  public version = 1;
  constructor(
    private store: Store<AppState>,
    // private titleService: Title
  ) { 
    // this.titleService.setTitle(GLOBAL.title + this.heading);
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;
  }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
        const date = new Date();
        this.version = date.getTime();
      }
    );
    console.log('Inicializa el componente Reporte-list ...');
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
  }
   

}
