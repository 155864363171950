import { Component, OnInit, OnDestroy } from '@angular/core';
import { CoberturaOperacionalServicio, CoberturaOperacionalServicioSortType } from 'src/app/models/reporte-asignacion';
import { Pagination } from 'src/app/models/pagination';
import { Fechas } from 'src/app/models/fechas';
import { DatePipe } from '@angular/common';
import { Identity } from 'src/app/models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Division } from 'src/app/models/division';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import {ExcelService} from 'src/app/services/excel.service';
import * as usuariosActions from 'src/app/store/actions';
import * as moment from 'moment';

@Component({
  selector: 'app-reporte-cobertura-servicio',
  templateUrl: './reporte-cobertura-servicio.component.html',
  styleUrls: ['./reporte-cobertura-servicio.component.sass']
})
export class ReporteCoberturaServicioComponent implements OnInit, OnDestroy {
  heading = 'Resumen de la cobertura operacional por servicio';
  subheading = 'Cobertura operacional';
  icon = ' pe-7s-note2 icon-gradient bg-happy-fisher';
  public datePipe = new DatePipe("en-US");
  public dates: Date[] = [];
  public anio: string;
  public resultados: CoberturaOperacionalServicio[] = [];
  public paginacion = new Pagination(null);
  public chips = [];
  public loading: boolean;
  public filterActive: boolean;
  public selectable: boolean;
  public dias: Fechas;
  public removable: boolean;
  public orderType = [...CoberturaOperacionalServicioSortType];
  public division: Division[];
  public identity: Identity;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionReporteAsignacion: Subscription = new Subscription();
  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private excelService: ExcelService,
  ) {
  const fecha = new Date();
  fecha.setDate(fecha.getDate() - 15);
  this.removable = true;
  this.paginacion.sortType = '-id'
  this.dates = [ fecha,new Date() ];
  this.dias = new Fechas(null);
  this.loading = false;
  this.filterActive = false;
  this.selectable = true;
   }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionReporteAsignacion = this.store.select('reporteAsignacion').subscribe(
      (state) => {
        this.division = [];
        this.resultados = [...state.coberturaOperacionalServicio];
        this.resultados.find(item => {
          let existe = false;
          this.division.find(itm => {
            if(itm.nombre == item.desDivision){
              existe = true;
            }
          })
          if(!existe){
            this.division.push({id:item.division, nombre:item.desDivision , estatus: true});
          }
        });
      }
    );
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionReporteAsignacion.unsubscribe();
  }

  fecha(fecha:Date[]){
    if(fecha && fecha.length === 2){
      const fecha1= this.datePipe.transform(fecha["0"], 'yyyy-MM-dd');
      const fecha2= this.datePipe.transform(fecha["1"], 'yyyy-MM-dd');
      const anio1 = this.datePipe.transform(fecha["0"], 'yyyy');
      const anio2 = this.datePipe.transform(fecha["1"], 'yyyy');      

      if(anio1 === anio2){
        this.anio = anio1;
      }else{
        this.anio = (anio1 + '-' + anio2);
      }
      this.dias = {fechaInicio: fecha1, fechaFinal: fecha2};
      this.store.dispatch(new usuariosActions.CargarReporteCoberturaOperacionalServicio(this.dias));
    }
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

  changeDivision() {
    this.division = [...this.division];
  }
  
  async crearExcel() {
    this.loading = true;
    let excel;

    excel = this.resultados.map( item => {
      const obj: any = {};
      obj.NO_ORDEN = item.numeroOrden;
      obj.CLIENTE = item.desCliente;
      obj.SERVICIO = item.desServicio;
      obj.SUCURSAL = item.desSucursal;
      obj.ESTADO = item.localidad;
      obj.DIVISIÓN = item.desDivision;
      obj.NO_ELEMENTOS = item.elementos;
      obj.TURNOS_DIA = item.turnos;
      obj.VACANTES = item.vacantes;
      obj.BAJAS = item.bajas;
      obj.TNCS = item.faltas;
      obj.COP = item.cop;
      return obj;
    });
    this.excelService.exportAsExcelFile(excel, 'Resumen de la cobertura operacional por servicio');
    this.loading = true;
    
  }



  textSearchChips(){
    if(this.paginacion.textSearch.length > 0){
      this.chips = [...this.paginacion.textSearch.split(' ')];
    }
    
  }

  remove(chips){
    this.chips = [...this.chips.filter(item => item !== chips)];
    this.paginacion.textSearch = this.chips.join(' ');
  }



}
