import { Component, OnInit, OnDestroy } from '@angular/core';
import { IndicadoresService } from 'src/app/services/indicadores.service';
import { Indicador, Color} from 'src/app/models/indicador';
import { Area, Usuarios} from 'src/app/models/departamento';
import { Colores } from 'src/app/models/colores';
import { ActivatedRoute,Router  } from '@angular/router';
import { EcuacionesService } from 'src/app/services/ecuacion.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';


@Component({
  selector: 'app-indicador',
  templateUrl: './indicador.component.html',
  styleUrls: ['./indicador.component.sass']
})
export class IndicadorComponent implements OnInit, OnDestroy {
  heading = 'Detalle de indicador';
  subheading = 'Nombre del indicador registrando en el sistema';
  icon = ' pe-7s-graph icon-gradient bg-happy-fisher';
  public MQ = null;
  public mathField;
  public indicador: Indicador = new Indicador(null);
  public indicadorInicial: Indicador = new Indicador(null);
  public colores: Colores[] = [];
  public tipo_indicadores: any[] = [];
  public areas: Area[] = [];
  public color: Colores;
  public usuariosArea: Usuarios[] = [];
  public idArea:number = 0;
  public AreasAsignadas: Usuarios[] = [];
  public aggColor: Color = new Color(null);
  public usuarioSelect:number = null;
  public myValue = true;
  public minDate: Date;
  public area;
  public responsable;
  public estado_usuario; 
  public usuariosEliminados = [];
  public usuariosAgregados = [];
  public identity: Identity;
  public loading: boolean = false;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionIndicador: Subscription = new Subscription();
  public subcriptionConsulta: Subscription = new Subscription();
  public subcriptionAreas: Subscription = new Subscription();
  constructor(
    private indicadoresService: IndicadoresService,
    private route: ActivatedRoute,
    private ecuacionesService: EcuacionesService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private router:Router,
    private store: Store<AppState>,
  ) { 
    this.identity = new Identity(null);
    this.responsable = 1;
    this.indicador = new Indicador(null);
    this.indicadorInicial = new Indicador(null);
    this.store.dispatch(new usuariosActions.LimpiarIndicador());
  }

  ngOnInit() {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionConsulta = this.store.select('consultaCatalogos').subscribe(
      (state) => {
        this.colores = [...state.colores];
      }
    );
    this.subcriptionIndicador = this.store.select('indicador').subscribe(
      (state) => {
        this.tipo_indicadores = [...state.tipo];
        this.indicador = {...state.indicador};
        this.indicadorInicial = {...state.indicador};
        

        this.mathField = document.getElementById('math-field');
        var latexSpan = document.getElementById('latex');
        this.MQ = (window as any).MathQuill.getInterface(2);
        var mathField = this.MQ.MathField(this.mathField, {
          substituteTextarea: () => {
            return document.getElementById('substitue-id');
          },
          spaceBehavesLikeTab: true, // configurable
          handlers: {
            edit: function () { // useful event handlers
              latexSpan.textContent = mathField.latex(); // simple API
            } 
          }
        });
        mathField.latex(this.indicador.latex);

        this.indicador.dia = (new Date(this.indicador.dia) );
        this.indicadorInicial.dia = (new Date(this.indicador.dia) );
        this.areasAsignadas();
      }
    );
    this.subcriptionAreas = this.store.select('areas').subscribe(
      (state) => {
        this.areas = [...state.areas];
      }
    );





    this.inicializa();
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionIndicador.unsubscribe();
    this.subcriptionConsulta.unsubscribe();
    this.subcriptionAreas.unsubscribe();
  }

  inicializa() {
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        if (id > 0) {
          this.store.dispatch(new usuariosActions.CargarIndicador(id));
        } else {
          this.indicador = new Indicador(null);
        }
    });
    this.store.dispatch(new usuariosActions.CargarAreasUsuario());
    this.store.dispatch(new usuariosActions.CargarTipoIndicador());
    this.store.dispatch(new usuariosActions.ConsultaCatalogoColores());

    // this.store.dispatch(new usuariosActions.ConsultaCatalogoSucursales());
   
    // this.store.dispatch(new usuariosActions.ConsultaCatalogoServicios());
    // this.store.dispatch(new usuariosActions.ConsultaCatalogoClientes());
                                      
  }



  onSubmit(form,content) {
    this.loading = true;
    this.indicador.latex = document.getElementById('latex').innerHTML;
    var usuarios = this.ecuacionesService.usuariosIndicador(this.indicador.asignados,this.indicadorInicial.asignados);
    this.estado_usuario = {nuevos:usuarios.nuevos, eliminados: usuarios.eliminados};
    this.usuariosEliminados = usuarios.eliminados;
    this.usuariosAgregados = usuarios.nuevos;


    var latexSpan = document.getElementById('math-field').innerText;
    var latex = document.getElementById('latex').innerText;
    var resultado = this.ecuacionesService.sacarformula(latexSpan,latex);
    var variable = resultado.variables;
    var formula = resultado.formuala;

    if((this.indicador.variable.length ===  variable.length && this.indicador.formula === formula)){
      if(this.indicador.id > 0){
        if(this.indicadorInicial.asignados != this.indicador.asignados){
          this.modalService.open(content, {
            size: 'lg'
          });
        }else{
          Swal.fire({
            title: '¿Desea registrar los cambios?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: MESSAGES.C_BTN_WARNING,
            cancelButtonColor: MESSAGES.C_BTN_CANCEL,
            confirmButtonText: MESSAGES.M_BTN_ACTUALIZA,
            cancelButtonText: MESSAGES.M_BTN_CANCEL_A,
            focusConfirm: false,
            focusCancel: true,
            animation: true,
            showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
            showLoaderOnConfirm: true,
            preConfirm: (result) => {
              return new Promise((resolve, reject) => {
                this.indicadoresService.registraIndicador(this.indicador, 1,this.estado_usuario).subscribe(
                  (respuesta) => {
                    this.loading = false;
                    this.router.navigate(['/auth/indicador']);
                    form.reset();
                    if(this.indicador.asignados.length === 0){
                      this.toastrService.success('El indicador no tiene usuarios asignados', 'Indicador Actualizado');
                    }else{
                      this.toastrService.success('Se registró los cambios del indicador','Indicador Actualizado' );
                    }
                    resolve(respuesta);
                  }, (error) => {
                    Swal.showValidationMessage('Ocurrió un error en la operación');
                    resolve(false);
                  }
                );
              });
            }
          }).then( (resul) => {
            if (resul.value) {
              Swal.fire({
                title: MESSAGES.M_TIT_SUCCESS,
                timer: 1200,
                showConfirmButton: false,
                icon: 'success',
                onOpen: () => {}
              }).then(() => {}, () => {});
              this.router.navigate(['/auth/indicador']);
            } else {
              Swal.fire({
                title: MESSAGES.M_TIT_CANCELADO,
                timer: 1200,
                showConfirmButton: false,
                icon: 'error',
                onOpen: () =>  {}
              }).then(() => {}, () => {});
            }
          });
        }
      }else{
        Swal.fire({
          title: '¿Desea registrar el indicador?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: MESSAGES.C_BTN_WARNING,
          cancelButtonColor: MESSAGES.C_BTN_CANCEL,
          confirmButtonText: MESSAGES.M_BTN_ACTUALIZA,
          cancelButtonText: MESSAGES.M_BTN_CANCEL_A,
          focusConfirm: false,
          focusCancel: true,
          animation: true,
           showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
          showLoaderOnConfirm: true,
          preConfirm: (result) => {
            return new Promise((resolve, reject) => {
              this.indicadoresService.registraIndicador(this.indicador, 1,this.estado_usuario).subscribe(
                (respuesta) => {
                  this.router.navigate(['/auth/indicador']);
                  form.reset();
                  this.toastrService.success('Se registró el indicador','Indicador registrado');
                  resolve(respuesta);
                }, (error) => {
                  Swal.showValidationMessage('Ocurrió un error en la operación');
                  resolve(false);
                }
              );
            });
          }
        }).then( (resul) => {
          if (resul.value) {
            Swal.fire({
              title: MESSAGES.M_TIT_SUCCESS,
              timer: 1200,
              showConfirmButton: false,
              icon: 'success',
              onOpen: () => {}
            }).then(() => {}, () => {});
            this.router.navigate(['/auth/indicador']);
          } else {
            Swal.fire({
              title: MESSAGES.M_TIT_CANCELADO,
              timer: 1200,
              showConfirmButton: false,
              icon: 'error',
              onOpen: () =>  {}
            }).then(() => {}, () => {});
          }
        });
      }
    }else{
      this.toastrService.warning('Se modificó la fórmula, pero no se volvió a generar','Fórmula' );
      this.detectarVariable();
    }
      
  }

  agregarResponsable(id){
    this.usuariosArea = [];
    this.areas.map( item => {
      if (item.id === id) {
        this.idArea = item.id;
        this.usuariosArea = item.usuarios;  
        this.usuarioSelect = null;    
      }
    });
  }


  agregarUsuarios(id){
    var existe = false;
    this.usuariosArea.map( item => {
      if (item.id === id) {
        this.indicador.asignados.map( item2 => {
          if(item2.idUsuario === item.id){
            existe = true;
          }
        });
        if(!existe){
          const aisgnado = {id: 0,idUsuario: item.id, idArea: this.idArea ,usuario: item.nombre};
          const arr = [...this.indicador.asignados, {...aisgnado}];
          this.indicador.asignados = arr;
        }else{
          this.toastrService.warning('El usuario ya está asignado','Responsables' );
        }
      }
    });
    this.areasAsignadas();
  }

  areasAsignadas(){
    var existe = false;
    this.indicador.asignados.map( item => {
      existe = false;
      this.AreasAsignadas.map( item2 => {
        if(item2.id === item.idArea){
          existe = true;
        }
      });
      if(!existe){
        this.AreasAsignadas.push({id: item.idArea,nombre: ''});
      }
    });
  }



  deleteUsuario(id){
    this.indicador.asignados = this.indicador.asignados.filter( item => item.idUsuario !== id);
    this.AreasAsignadas = []
    this.areasAsignadas();
  }

  agregarColor(){
    var existe = false;
    if(this.aggColor.maximo > this.aggColor.minimo){
      this.indicador.colores.map( item => {
        if(item.color === this.aggColor.color){
          existe = true;
        }
      });
      if(!existe){
        const arr = [...this.indicador.colores, {...this.aggColor}];
        this.indicador.colores = arr;
        this.aggColor = new Color (null);
      }else{
        this.toastrService.warning('El color ya está asignado','Condicionales' ); 
      }
    }else{
      this.toastrService.warning('El máximo no puedo ser menos que el mínimo ','Condicionales' );
    }
  }

  deleteColor(color){
    this.indicador.colores = this.indicador.colores.filter( item => item.color !== color);
  }


  detectarVariable(){
    var latexSpan = document.getElementById('math-field').innerText;
    var latex = document.getElementById('latex').innerText;
    var resultado = this.ecuacionesService.sacarformula(latexSpan,latex);
    this.indicador.variable = resultado.variables;
    this.indicador.formula = resultado.formuala;
  }


  usuariosNuevos(){
    this.indicadoresService.registraIndicador(this.indicador, this.responsable , this.estado_usuario).subscribe(Response =>{
      this.router.navigate(['/auth/indicador']);
      this.toastrService.success('Se registró los cambios del indicador','Indicador Actualizado' );
      this.modalService.dismissAll('Close');
    }, error =>{
      console.log(<any>error);
    });
  }

}