import { Directive, HostBinding, Output, EventEmitter, HostListener, Input } from '@angular/core';
// import { Directorio } from '../models/directorio';
import { ArchivoGeneral } from '../models/archivo-general';

export interface ObjDropTranfers {
  files: any[],
  // folderDestino: Directorio,
  // folderMovido: Directorio,
  folderDestino: any,
  folderMovido: any,
  archivoMovido: ArchivoGeneral
}
@Directive({
  selector: '[appDragDropFolderFile]'
})
export class DragDropFolderFileDirective {
  @Output() fileDropped = new EventEmitter<any>();
  @Output() startDrag = new EventEmitter<any>();
  @Output() endDrag = new EventEmitter<any>();
  @HostBinding('style.background-color') private background = 'inherit';
  @HostBinding('style.opacity') private opacity = '1';
  @Input() preventBodyDrop = true;
  @Input() folder: any = null;
  // @Input() folder: Directorio = null;
  @Input() archivo: ArchivoGeneral = null;
  // private archivoSeleccionado: ArchivoGeneral = null;
  // private folderDestino: Directorio = null;
  // private folderMoviendo: Directorio = null;

  // Dragover listener
  @HostListener('dragstart', ['$event']) onDragStart(evt) {
    // evt.preventDefault();
    // evt.stopPropagation();
    // this.folderMoviendo = {...this.folder};
    // this.archivoSeleccionado = {...this.archivo};
    this.startDrag.emit({
      files: [],
      folderDestino: null,
      folderMovido: {...this.folder},
      archivoMovido: {...this.archivo}
    } as ObjDropTranfers);
  }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#9ecbec';
    this.opacity = '0.8';
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'inherit';
    this.opacity = '1';
    // this.folderDestino = null;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'inherit'
    this.opacity = '1';
    const files = evt.dataTransfer.files;
    this.fileDropped.emit(files);
  }

  @HostListener('dragend', ['$event']) onDragEnd(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.endDrag.emit({
      files: [],
      folderDestino: null,
      folderMovido: null,
      archivoMovido: null
    } as ObjDropTranfers);
  }

  // @HostListener('body:dragover', ['$event'])
  // onBodyDragOver(evt: DragEvent) {
  //   console.log('body:dragover', evt);
  //   if (this.preventBodyDrop) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  // }
  // @HostListener('body:drop', ['$event'])
  // onBodyDrop(evt: DragEvent) {
  //   console.log('body:drop', evt);
  //   if (this.preventBodyDrop) {
  //     event.preventDefault();
  //   }
  // }

}
