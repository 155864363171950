import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { Departamento } from '../models/departamento';

@Injectable({
  providedIn: 'root'
})
export class DepartamentosService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  registerDepartamento(departamentoToRegister: Departamento): Observable<any> {
    const params = JSON.stringify({departamento: departamentoToRegister});
	 const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/departamentos/', params, {headers});
  }

  getDepartamentos(): Observable<any> {
	const headers = new HttpHeaders().set('Content-type', 'application/json');

	return this.http.get(this.url + '/departamentos', {headers});
 }

 updateDepartamento(departamentoToUpdate: Departamento): Observable<any> {
	const params = JSON.stringify({departamento:departamentoToUpdate});
	const headers = new HttpHeaders().set('Content-Type', 'application/json');
	return this.http.put(this.url + '/departamentos/' + departamentoToUpdate.id, params, {headers});
 }

 getDepartamento(id: number): Observable<any> {
	const headers = new HttpHeaders().set('Content-type', 'application/json');
	
	return this.http.get(this.url + '/departamentos/' + id, {headers});
 }

 deleteDepartamento(id: number): Observable<any> {
	const headers = new HttpHeaders().set('Content-Type', 'application/json');

	return this.http.delete(this.url + '/departamentos/' + id, {headers});

}

getDepartamentoConsul(): Observable<any> {
	const headers = new HttpHeaders().set('Content-type', 'application/json');


	return this.http.get(this.url + '/departamentos/consul/', {headers});
  }


  getUsuariosArea():Observable<any>{
    const headers = new HttpHeaders().set('Content-type', 'application/json');

    return this.http.get(this.url + '/departamentos/usuarios/', {headers});
  }

}
