import * as fromUsuario from '../actions';
import { TipoSolicitud } from 'src/app/models/tipo-solicitud';

export interface TipoSolicitudState {
  solicitudes: TipoSolicitud[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: TipoSolicitudState = {
  solicitudes: [],
  loaded: false,
  loading: false,
  error: null
};

export function tipoSolicitudReducer( state = estadoInicial, action: fromUsuario.tipoSolicitudAcciones ): TipoSolicitudState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_TIPO_SOLICITUDES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_TIPO_SOLICITUDES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        solicitudes: [...action.solicitudes]
      };
    case fromUsuario.CARGAR_TIPO_SOLICITUDES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_TIPO_SOLICITUD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_TIPO_SOLICITUD_SUCCESS:
      const itm = state.solicitudes.find( item => item.id === action.solicitud.id);
      if (itm && itm.id) {
        state.solicitudes = state.solicitudes.map( item => {
          if (item.id === action.solicitud.id) {
            item = {...action.solicitud};
          }
          return item;
        });
      } else {
        state.solicitudes.push(action.solicitud);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_TIPO_SOLICITUD_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_TIPO_SOLICITUD_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_TIPO_SOLICITUD_ESTATUS_SUCCESS:
      state.solicitudes = state.solicitudes.map( item => {
        if (item.id === action.id) {
          item.status = !item.status;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_TIPO_SOLICITUD_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

