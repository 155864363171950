import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { UsuariosService } from '../../services/usuarios.service';
import { User } from 'src/app/models/user';

@Injectable()
export class UsuariosEffects {

    constructor(
        private actions$: Actions,
        public usuariosService: UsuariosService
    ) {}

    @Effect()
    cargarUsuarios$ = this.actions$.pipe(
			ofType( usuariosActions.CARGAR_USUARIOS ),
			switchMap( () => {
				return this.usuariosService.getUsers(0)
					.pipe(
						map( (users) => {
							return new usuariosActions.CargarUsuariosSuccess(users.usuarios);
						}),
						catchError( error => of(new usuariosActions.CargarUsuariosFail(error))  )
					);
			})
    );

    @Effect()
    eliminarUsuarios$ = this.actions$.pipe(
			ofType( usuariosActions.ELIMINAR_USUARIOS ),
			switchMap( (action: any) => {
				return this.usuariosService.deleteUser(action.id)
					.pipe(
						map( users => {
							return new usuariosActions.EliminarUsuariosSuccess(action.id, users);
						}),
						catchError( error => of(new usuariosActions.CargarUsuariosFail(error))  )
					);
			})
    );

    @Effect()
    actualizaEstatusUsuario$ = this.actions$.pipe(
      ofType( usuariosActions.ACTUALIZA_USUARIO_ESTATUS ),
      switchMap( (action: any) => {
        return this.usuariosService.actualizaEstatusUsuario(action.usuario)
          .pipe(
              map( respuesta => {
                return new usuariosActions.ActualizaUsuariosEstatusSuccess(action.usuario);
              }),
              catchError( error => of(new usuariosActions.ActualizaUsuariosEstatusFail(error))  )
          );
      })
    );


}
