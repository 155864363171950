import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { TipoServicio, TipoServicioSortType } from 'src/app/models/tipo-servicio';
import { Pagination } from 'src/app/models/pagination';
import { TipoServicioService } from 'src/app/services/tipo-servicio.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-tipo-servicio',
  templateUrl: './tipo-servicio.component.html',
  styleUrls: ['./tipo-servicio.component.sass']
})
export class TipoServicioComponent implements OnInit, OnDestroy {
  public title: string;
  public identity: Identity;
  private subscribeAuth: Subscription = new Subscription();
  private subscribeServicio: Subscription = new Subscription();
  public servicio: TipoServicio;
  public servicioInicial: TipoServicio = new TipoServicio(null);
  public servicios: TipoServicio[];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public filterActive: boolean = false;
  public orderType = [...TipoServicioSortType];
  constructor(
    private servicioService: TipoServicioService,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) {
    this.paginacion.sortType = '+nombre';
    this.identity = new Identity(null);
    this.servicio = new TipoServicio(null);
    this.servicios = [];
   }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeServicio = this.store.select('tipoServicio').subscribe(
      (state) => {
        this.servicios = [...state.servicios];
      }
    );
    this.title = 'Catálogo Tipo de servicios';
    console.log('Inicializa component TipoServicios...');
    this.store.dispatch(new usuariosActions.CargarTipoServicios());
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeServicio.unsubscribe();
  }

  editarTipoServicio(zon: TipoServicio) {
    this.servicio = {...zon};
    this.servicioInicial = {...zon};
  }

  estatusTipoServicio(servicio: TipoServicio) {
    if (servicio.id > 0) {
      this.loading = true;
      this.servicioService.actualizaEstatusTipoServicio(servicio).subscribe(
        (response) => {
          this.store.dispatch(new usuariosActions.ActualizaTipoServicioEstatusSuccess(servicio.id));
          this.toastr.success('Actualización correctamente.', 'Exito');
          this.loading = false;
        }, (error) => {
          this.toastr.error('Ocurrió un error.', 'Error');
          this.loading = false;
        }
      );
    }
  }

  onSubmit(form) {
    Swal.fire({
      title: '¿Deseas registrar el tipo de servicio?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve, reject)  => {
          this.servicioService.registraTipoServicio(this.servicio).subscribe(
            (respuesta) => {
              if (respuesta.servicio) {
                this.store.dispatch(new usuariosActions.ActualizaTipoServicioSuccess(respuesta.servicio));
                resolve(respuesta.servicio);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaTipoServicioFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          form.reset();
          this.servicio = new TipoServicio(null);
          this.servicioInicial = new TipoServicio(null);
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Tipo servicio registrado.',
            icon: 'success'
          });
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  limpiar(form) {
    this.servicio = new TipoServicio(null);
    this.servicioInicial = new TipoServicio(null);
    form.reset();
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }
  
}
