import { SortOrder } from './sort';

export class ReporteDia{
    public id: number;
    public nombre: string;
    public resultado: Resultado[];
    public faltas: number;
    public bajas: number;
    public vacantes: number;
    public cop: number;
    public turnos: number;
    public elementos: number;

	constructor(
		obj: DataObjReporteDia
	){
        this.id = obj && obj.id ? obj.id :0
        this.nombre = obj && obj.nombre ? obj.nombre :'';
        this.resultado = obj && obj.resultado ? obj.resultado: [];
        this.faltas = obj && obj.faltas ? obj.faltas: 0;
        this.bajas = obj && obj.bajas ? obj.bajas : 0;
        this.vacantes = obj && obj.vacantes ? obj.vacantes: 0;
        this.cop = obj && obj.cop ? obj.cop: 0;
        this.turnos = obj && obj.turnos ? obj.turnos: 0;
        this.elementos = obj && obj.elementos ? obj.elementos: 0;
	}
}
interface DataObjReporteDia{
    id: number;
    nombre: string;
    resultado: Resultado[];
    faltas: number;
    bajas: number;
    vacantes: number;
    cop: number;
    turnos: number;
    elementos: number;
}

export class Resultado{
    public dia: Date;
    public faltas: number;
    public bajas: number;
    public vacantes: number;
    public porcentaje: number;
    public turnos: number;
	constructor(
		obj: DataObjResultado
	){
        this.dia = obj && obj.dia ? obj.dia : null;
        this.faltas = obj && obj.faltas ? obj.faltas: 0;
        this.bajas = obj && obj.bajas ? obj.bajas : 0;
        this.vacantes = obj && obj.vacantes ? obj.vacantes: 0;
        this.porcentaje = obj && obj.porcentaje ? obj.porcentaje: 0;
        this.turnos = obj && obj.turnos ? obj.turnos: 0;
	}
}
interface DataObjResultado{
    dia: Date;
    faltas: number;
    bajas: number;
    vacantes: number;
    porcentaje: number;
    turnos: number;
}



export class CoberturaOperacional{
    public id: number;
    public nombre: string;
    public faltas: number;
    public bajas: number;
    public vacantes: number;
    public cop: number;
    public turnos: number;
    public elementos: number;
    public fijo: number;
    public extraordinario: number;
    public cortesia: number;


	constructor(
		obj: DataObjCoberturaOperacional
	){
        this.id = obj && obj.id ? obj.id :0
        this.nombre = obj && obj.nombre ? obj.nombre :'';
        this.faltas = obj && obj.faltas ? obj.faltas: 0;
        this.bajas = obj && obj.bajas ? obj.bajas : 0;
        this.vacantes = obj && obj.vacantes ? obj.vacantes: 0;
        this.cop = obj && obj.cop ? obj.cop: 0;
        this.turnos = obj && obj.turnos ? obj.turnos: 0;
        this.elementos = obj && obj.elementos ? obj.elementos: 0;
        this.fijo = obj && obj.fijo ? obj.fijo: 0;
        this.extraordinario = obj && obj.extraordinario ? obj.extraordinario: 0;
        this.cortesia = obj && obj.cortesia ? obj.cortesia: 0;
	}
}
interface DataObjCoberturaOperacional{
    id: number;
    nombre: string;
    faltas: number;
    bajas: number;
    vacantes: number;
    cop: number;
    turnos: number;
    elementos: number;
    fijo: number;
    extraordinario: number;
    cortesia: number;
}


export class CoberturaOperacionalServicio{
  public id: number;
  public numeroOrden: number;
  public cliente: number;
  public desCliente: string;
  public servicio: number;
  public desServicio: string;
  public localidad: string;
  public sucursal: number;
  public desSucursal: string;
  public division: number;
  public desDivision: string;
  public elementos: number;
  public turnos: number;
  public faltas: number;
  public bajas: number;
  public vacantes: number;
  public cop: number;
	constructor(
		obj: DataObjCoberturaOperacionalServicio
	){
        this.id = obj && obj.id ? obj.id :0
        this.numeroOrden = obj && obj.numeroOrden ? obj.numeroOrden : 0;
        this.cliente = obj && obj.cliente ? obj.cliente: 0;
        this.desCliente = obj && obj.desCliente ? obj.desCliente : '';
        this.servicio = obj && obj.servicio ? obj.servicio: 0;
        this.desServicio = obj && obj.desServicio ? obj.desServicio: '';
        this.localidad = obj && obj.localidad ? obj.localidad: '';
        this.sucursal = obj && obj.sucursal ? obj.sucursal: 0;
        this.desSucursal = obj && obj.desSucursal ? obj.desSucursal : '';
        this.division = obj && obj.division ? obj.division :0;
        this.desDivision = obj && obj.desDivision ? obj.desDivision: '';
        this.elementos = obj && obj.elementos ? obj.elementos : 0;
        this.turnos = obj && obj.turnos ? obj.turnos: 0;
        this.faltas = obj && obj.faltas ? obj.faltas: 0;
        this.bajas = obj && obj.bajas ? obj.bajas: 0;
        this.vacantes = obj && obj.vacantes ? obj.vacantes: 0;
        this.cop = obj && obj.cop ? obj.cop: 0;
	}
}
interface DataObjCoberturaOperacionalServicio{
    id: number;
    numeroOrden: number;
    cliente: number;
    desCliente: string;
    servicio: number;
    desServicio: string;
    localidad: string;
    sucursal: number;
    desSucursal: string;
    division: number;
    desDivision: string;
    elementos: number;
    turnos: number;
    faltas: number;
    bajas: number;
    vacantes: number;
    cop: number;
}

export const CoberturaOperacionalServicioSortType: SortOrder[] = [
	{ id: '+id', text: 'ID'},
	{ id: '-id', text: 'ID desc'},
	{ id: '+numeroOrden', text: 'No. Orden'},
	{ id: '-numeroOrden', text: 'No. Orden desc'},
	{ id: '+desCliente', text: 'Clientes'},
    { id: '-desCliente', text: 'Clientes desc'},
    { id: '+desServicio', text: 'Servicios'},
	{ id: '-desServicio', text: 'Servicios desc'},
	{ id: '+desSucursal', text: 'Sucursal'},
    { id: '-desSucursal', text: 'Sucursal desc'},
    { id: '+localidad', text: 'Estados '},
    { id: '-localidad', text: 'Estados  desc'},
    { id: '+desDivision', text: 'Divisiones  '},
    { id: '-desDivision', text: 'Divisiones   desc'},
    { id: '+elementos', text: 'No. Elementos '},
    { id: '-elementos', text: 'No. Elementos  desc'},
    { id: '+turnos', text: 'Turnos por día '},
    { id: '-turnos', text: 'Turnos por día  desc'},
    { id: '+vacantes', text: 'Vacantes  '},
    { id: '-vacantes', text: 'Vacantes   desc'},
    { id: '+bajas', text: 'Bajas  '},
    { id: '-bajas', text: 'Bajas   desc'},
    { id: '+faltas', text: 'TNCs '},
    { id: '-faltas', text: 'TNCs  desc'},
    { id: '+cop', text: '%COP  '},
	{ id: '-cop', text: '%COP   desc'}
  ];



  export class ServicioIniFinaEn{
    public id: number;
    public numeroOrden: number;
    public cliente: number;
    public desCliente: string;
    public servicio: number;
    public desServicio: string;
    public localidad: string;
    public sucursal: number;
    public desSucursal: string;
    public division: number;
    public desDivision: string;
    public elementos: number;
    public turnos: number;
    public bajas: number;
    public vacantes: number;
      constructor(
          obj: DataObjServicioIniFinaEn
      ){
          this.id = obj && obj.id ? obj.id :0
          this.numeroOrden = obj && obj.numeroOrden ? obj.numeroOrden : 0;
          this.cliente = obj && obj.cliente ? obj.cliente: 0;
          this.desCliente = obj && obj.desCliente ? obj.desCliente : '';
          this.servicio = obj && obj.servicio ? obj.servicio: 0;
          this.desServicio = obj && obj.desServicio ? obj.desServicio: '';
          this.localidad = obj && obj.localidad ? obj.localidad: '';
          this.sucursal = obj && obj.sucursal ? obj.sucursal: 0;
          this.desSucursal = obj && obj.desSucursal ? obj.desSucursal : '';
          this.division = obj && obj.division ? obj.division :0;
          this.desDivision = obj && obj.desDivision ? obj.desDivision: '';
          this.elementos = obj && obj.elementos ? obj.elementos : 0;
          this.turnos = obj && obj.turnos ? obj.turnos: 0;
          this.bajas = obj && obj.bajas ? obj.bajas: 0;
          this.vacantes = obj && obj.vacantes ? obj.vacantes: 0;
      }
  }
  interface DataObjServicioIniFinaEn{
      id: number;
      numeroOrden: number;
      cliente: number;
      desCliente: string;
      servicio: number;
      desServicio: string;
      localidad: string;
      sucursal: number;
      desSucursal: string;
      division: number;
      desDivision: string;
      elementos: number;
      turnos: number;
      bajas: number;
      vacantes: number;
  }

  export class DivisionIniFinaEn{
    public id: number;
    public nombre: string;
    public servicios: number;
    public bajas: number;
    public vacantes: number;
    public turnos: number;
    public elementos: number;
    public adicion: number;
    public reduccion: number;

	constructor(
		obj: DataObjDivisionIniFinaEn
	){
        this.id = obj && obj.id ? obj.id :0
        this.nombre = obj && obj.nombre ? obj.nombre :'';
        this.servicios = obj && obj.servicios ? obj.servicios: 0;
        this.bajas = obj && obj.bajas ? obj.bajas : 0;
        this.vacantes = obj && obj.vacantes ? obj.vacantes: 0;
        this.turnos = obj && obj.turnos ? obj.turnos: 0;
        this.elementos = obj && obj.elementos ? obj.elementos: 0;
        this.adicion = obj && obj.adicion ? obj.adicion: 0;
        this.reduccion = obj && obj.reduccion ? obj.reduccion: 0;
	}
}
interface DataObjDivisionIniFinaEn{
    id: number;
    nombre: string;
    servicios: number;
    bajas: number;
    vacantes: number;
    turnos: number;
    elementos: number;
    adicion: number;
    reduccion: number;
    
}

export const OrderIniFinaEnSortType: SortOrder[] = [
	{ id: '+id', text: 'ID'},
	{ id: '-id', text: 'ID desc'},
	{ id: '+numeroOrden', text: 'No. Orden'},
	{ id: '-numeroOrden', text: 'No. Orden desc'},
	{ id: '+desCliente', text: 'Clientes'},
    { id: '-desCliente', text: 'Clientes desc'},
    { id: '+desServicio', text: 'Servicios'},
	{ id: '-desServicio', text: 'Servicios desc'},
	{ id: '+desSucursal', text: 'Sucursal'},
    { id: '-desSucursal', text: 'Sucursal desc'},
    { id: '+localidad', text: 'Estados '},
    { id: '-localidad', text: 'Estados  desc'},
    { id: '+desDivision', text: 'Divisiones  '},
    { id: '-desDivision', text: 'Divisiones   desc'},
    { id: '+elementos', text: 'No. Elementos '},
    { id: '-elementos', text: 'No. Elementos  desc'},
    { id: '+turnos', text: 'Turnos por día '},
    { id: '-turnos', text: 'Turnos por día  desc'},
    { id: '+vacantes', text: 'Vacantes  '},
    { id: '-vacantes', text: 'Vacantes   desc'},
    { id: '+bajas', text: 'Bajas  '},
    { id: '-bajas', text: 'Bajas   desc'}
  ];


  export class DivisionBajaVacan{
    public id: number;
    public nombre: string;
    public servicios: number;
    public bajas: number;
    public vacantes: number;
    public elementos: number;
    public porcentajeBaja: number;
    public porcentajeVacante: number;


	constructor(
		obj: DataObjDivisionBajaVacan
	){
        this.id = obj && obj.id ? obj.id :0
        this.nombre = obj && obj.nombre ? obj.nombre :'';
        this.servicios = obj && obj.servicios ? obj.servicios: 0;
        this.bajas = obj && obj.bajas ? obj.bajas : 0;
        this.vacantes = obj && obj.vacantes ? obj.vacantes: 0;
        this.elementos = obj && obj.elementos ? obj.elementos: 0;
        this.porcentajeBaja = obj && obj.porcentajeBaja ? obj.porcentajeBaja: 0;
        this.porcentajeVacante = obj && obj.porcentajeVacante ? obj.porcentajeVacante: 0;
	}
}
interface DataObjDivisionBajaVacan{
    id: number;
    nombre: string;
    servicios: number;
    bajas: number;
    vacantes: number;
    elementos: number;
    porcentajeBaja: number;
    porcentajeVacante: number;
}
