import { Action } from '@ngrx/store';
import { TipoServicio } from 'src/app/models/tipo-servicio';

export const CARGAR_TIPO_SERVICIOS = '[TipoServicio] Cargar tipo servicio';
export const CARGAR_TIPO_SERVICIOS_FAIL = '[TipoServicio] Cargar tipo servicio FAIL';
export const CARGAR_TIPO_SERVICIOS_SUCCESS = '[TipoServicio] Cargar tipo servicio SUCCESS';
export const ACTUALIZA_TIPO_SERVICIO = '[TipoServicio] Actualiza tipo servicio';
export const ACTUALIZA_TIPO_SERVICIO_FAIL = '[TipoServicio] Actualiza tipo servicio FAIL';
export const ACTUALIZA_TIPO_SERVICIO_SUCCESS = '[TipoServicio] Actualiza tipo servicio SUCCESS';
export const ACTUALIZA_TIPO_SERVICIO_ESTATUS = '[TipoServicio] Actualiza tipo servicio estatus';
export const ACTUALIZA_TIPO_SERVICIO_ESTATUS_FAIL = '[TipoServicio] Actualiza tipo servicio estatus FAIL';
export const ACTUALIZA_TIPO_SERVICIO_ESTATUS_SUCCESS = '[TipoServicio] Actualiza tipo servicio estatus SUCCESS';

export class CargarTipoServicios implements Action {
  readonly type = CARGAR_TIPO_SERVICIOS;
}

export class CargarTipoServiciosFail implements Action {
  readonly type = CARGAR_TIPO_SERVICIOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarTipoServiciosSuccess implements Action {
  readonly type = CARGAR_TIPO_SERVICIOS_SUCCESS;

  constructor( public servicios: TipoServicio[] ) {}
}

export class ActualizaTipoServicio implements Action {
  readonly type = ACTUALIZA_TIPO_SERVICIO;

  constructor( public servicio: TipoServicio ) { }
}

export class ActualizaTipoServicioFail implements Action {
  readonly type = ACTUALIZA_TIPO_SERVICIO_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaTipoServicioSuccess implements Action {
  readonly type = ACTUALIZA_TIPO_SERVICIO_SUCCESS;

  constructor( public servicio: TipoServicio ) {}
}

export class ActualizaTipoServicioEstatus implements Action {
  readonly type = ACTUALIZA_TIPO_SERVICIO_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaTipoServicioEstatusFail implements Action {
  readonly type = ACTUALIZA_TIPO_SERVICIO_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaTipoServicioEstatusSuccess implements Action {
  readonly type = ACTUALIZA_TIPO_SERVICIO_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export type tipoServicioAcciones = CargarTipoServicios 
                                | CargarTipoServiciosFail 
                                | CargarTipoServiciosSuccess 
                                | ActualizaTipoServicio 
                                | ActualizaTipoServicioFail 
                                | ActualizaTipoServicioSuccess 
                                | ActualizaTipoServicioEstatus 
                                | ActualizaTipoServicioEstatusFail 
                                | ActualizaTipoServicioEstatusSuccess;

