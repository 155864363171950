import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { DepartamentosService } from 'src/app/services/departamentos.service';

@Injectable()
export class AreasEffects {

  constructor(
    private actions$: Actions,
    public departamentosService: DepartamentosService
  ) {}

  @Effect()
  cargarAreasUsuario$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_AREAS_USUARIO ),
    switchMap( () => {
      return this.departamentosService.getUsuariosArea()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarAreasUsuarioSuccess(respuesta.areas);
          }),
          catchError( error => of(new usuariosActions.CargarAreasUsuarioFail(error))  )
        );
    })
  );


  @Effect()
  cargarAreas$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_AREAS),
    switchMap( () => {
      return this.departamentosService.getDepartamentos()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarAreasSuccess(respuesta.departamentos);
          }),
          catchError( error => of(new usuariosActions.CargarAreasFail(error))  )
        );
    })
  );

  @Effect()
  EliminarArea$ = this.actions$.pipe(
    ofType( usuariosActions.ELIMINAR_AREA ),
    switchMap( (action: any) => {
      return this.departamentosService.deleteDepartamento(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.EliminarAreaSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.EliminarAreaFail(error))  )
        );
    })
  );

  @Effect()
  actualizaArea$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_AREA ),
    switchMap( (action: any) => {
      return this.departamentosService.registerDepartamento(action.area)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaAreaSuccess(respuesta.departamento);
            }),
            catchError( error => of(new usuariosActions.ActualizaAreaFail(error))  )
        );
    })
  );


}
