import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { ServiciosService } from 'src/app/services/servicios.service';

@Injectable()
export class ServicioEffects {

  constructor(
    private actions$: Actions,
    public serviciosService: ServiciosService
  ) {}

  @Effect()
  cargarServicio$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_SERVICIOS ),
    switchMap( () => {
      return this.serviciosService.obtenerServicios()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarServiciosSuccess(respuesta.servicios);
          }),
          catchError( error => of(new usuariosActions.CargarServiciosFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusServicio$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_SERVICIO_ESTATUS ),
    switchMap( (action: any) => {
      return this.serviciosService.actualizaEstatusServicio(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaServicioEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaServicioEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaServicio$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_SERVICIO ),
    switchMap( (action: any) => {
      return this.serviciosService.registraServicio(action.servicio)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaServicioSuccess(action.servicio);
            }),
            catchError( error => of(new usuariosActions.ActualizaServicioFail(error))  )
        );
    })
  );

}
