<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card mb-3">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">  
        <div class="p-3">
          <div class="form-group">
            <label for="pageSizeVehiculo">Mostrar por hoja</label>
            <select class="custom-select" id="pageSizeVehiculo" name="pageSizeVehiculo" #pageSizeVehiculo="ngModel" [(ngModel)]="paginacion.pageSize">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="sortTypeVehiculos">Ordenar por</label>
            <select class="custom-select mb-2 mr-1" id="sortTypeVehiculos" name="sortTypeVehiculos" #sortTypeVehiculos="ngModel" [(ngModel)]="paginacion.sortType">
              <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9" [ngClass]="{'col-lg-12': !filterActive }">
    <div class="main-card mb-3 card card-shadow-primary">
      <div class="card-header">
        <div class="card-header-title">Indicadores</div>
        <div class="btn-actions-pane-right mr-4">
          ({{(indicadores   | anyScope: paginacion.textSearch).length}} de {{(indicadores).length}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchIndicador" id="searchIndicador" #searchIndicador="ngModel" [(ngModel)]="paginacion.textSearch">
                <button class="search-icon" (click)="searchClick()" for="searchIndicador"><span></span></button>
              </div>
              <button class="close" (click)="closeSearch()"></button>
            </div>
            <a *ngIf="identity.permisos.contactosRegEdit" [routerLink]="['/auth/indicador/register', 0]" [routerLinkActive]="" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3">
              Registrar nuevo
              <span class="pl-2 align-middle opacity-7">
                <i class="fa fa-angle-right"></i>
              </span>
            </a>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let indicador of indicadores  | anyScope: paginacion.textSearch 
                                                                            | orderBy: [paginacion.sortType]  
                                                                            | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize">
            <div class="no-gutters row">
              <div class="col-sm-12 col-md-8 col-xl-8">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-3">#{{indicador.id}}</div>
                      <!-- <div class="widget-content-left mr-3">
                        <i class="rounded fas fa-handshake fa-2x text-success""></i>
                      </div> -->
                      <div class="widget-content-left">
                        <div class="widget-heading">{{indicador.nombre}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-2 col-xl-2">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                      <div class="widget-heading">Fecha: {{indicador.dia | amDateFormat: 'DD/MM/YYYY'}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-2 col-xl-2">
                <div class="d-inline-block" ngbDropdown placement="left">
                  <button type="button" class="btn-pill btn btn-outline-primary" ngbDropdownToggle>
                    Acciones
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu-xl">
                    <div class="dropdown-menu-header">
                      <div class="dropdown-menu-header-inner bg-sunny-morning">
                        <div class="menu-header-content text-dark">
                          <h5 class="menu-header-title">ACCIONES</h5>
                        </div>
                      </div>
                    </div>
                    <div class="grid-menu grid-menu-xl grid-menu-3col">
                      <div class="no-gutters row">
                        <div class="col-sm-6 col-xl-6" *ngIf="identity.permisos.indicadorRegVer || identity.permisos.indicadorRegEdit">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary" [routerLink]="['/auth/indicador/register/', indicador.id]" title="Editar el indicador">
                            <i class="fa fa-edit btn-icon-wrapper btn-icon-lg mb-3"> </i> Editar
                          </button>
                        </div>
                        <div class="col-sm-6 col-xl-6" *ngIf="identity.permisos.indicadorListEdit">
                          <button class="btn-icon-vertical btn-square btn-transition btn"  [ngClass]="{'btn-outline-secondary': !indicador.estado, 'btn-outline-success': indicador.estado}" (click)="editarEstado(indicador)"  title="Presione para activar/desactivar el indicador.">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 "[ngClass]="{'fa-ban': !indicador.estado, 'fa-check': indicador.estado}">  </i> {{ (indicador.estado ? 'Activo' : 'Desactivado') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="text-left">
              <ngb-pagination
                [collectionSize]="(indicadores | anyScope: paginacion.textSearch).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="3">
                <ng-template ngbPaginationFirst>First</ng-template>
                <ng-template ngbPaginationLast>Last</ng-template>
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
            </div>
          </div>
          <!-- <div class="col-sm-6">
            <div class="pull-right">
              <button type="button" (click) = "crearExcel()" class="btn btn-primary" *ngIf="identity.permisos.asignacionListEdit">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>