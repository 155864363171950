import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Servicio } from 'src/app/models/servicios';
import { Cliente } from 'src/app/models/clientes';
import { TipoSolicitud } from 'src/app/models/tipo-solicitud';
import { Sucursal } from 'src/app/models/sucursales';
import { TipoServicio } from 'src/app/models/tipo-servicio';
import { Pagination } from 'src/app/models/pagination';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import * as usuariosActions from 'src/app/store/actions';
import { AsignacionesService } from 'src/app/services/asignaciones.service';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import { Asignaciones } from 'src/app/models/asignaciones';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.sass']
})
export class EditAsignacionComponent implements OnInit, OnDestroy {
  heading = 'Detalle de la asignación';
  subheading = 'Asignación registranda en el sistema';
  icon = ' pe-7s-check icon-gradient bg-happy-fisher';
  private subscribeAuth: Subscription = new Subscription();
  private subscribeAsignacion: Subscription = new Subscription();
  private subscribeSolicitud: Subscription = new Subscription();
  private subcriptionConsulta: Subscription = new Subscription();
  public servicios: Servicio[] = [];
  public tipoServicios: TipoServicio[] = [];
  public solicitudes: TipoSolicitud[] = [];
  public sucursales: Sucursal[] = [];
  public clientes: Cliente[] = [];
  public serviciosSelecionados: Servicio[] = [];
  public actualizar: boolean;
  public identity: Identity;
  public id: number = 0;
  public asignacion: Asignaciones;
  public asignacionInicial: Asignaciones;
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private asignacionService: AsignacionesService,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) { 
    this.identity = new Identity(null);
    this.asignacion = new Asignaciones (null);
    this.asignacionInicial = new Asignaciones (null);
    this.store.dispatch(new usuariosActions.LimpiarAsignacion());
    this.actualizar = true;
  }

  
  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeAsignacion = this.store.select('asignacion').subscribe(
      (state) => {
        if(this.actualizar){
          this.asignacion = {...state.asignacion};
          this.asignacion.fecInicio = (new Date(this.asignacion.fecInicio) );
          // this.asignacion.fecTermino = (new Date(this.asignacion.fecTermino) );
          this.asignacion.fecTermino = this.asignacion.opcFin ? null : (new Date(this.asignacion.fecTermino) );
          // if(this.asignacion.opcFin){
          //   this.asignacion.fecTermino = null;
          // }else{
          //   this.asignacion.fecTermino = (new Date(this.asignacion.fecTermino) );
          // }
        }
        if(state.existe && state.existe.id > 0 && !this.actualizar){
          this.asignacion.tipoSolicitud = null;
          this.mensaje(state.existe);
        }
      }
    );
    this.subscribeSolicitud = this.store.select('tipoSolicitud').subscribe(
      (state) => {
        this.solicitudes = [...state.solicitudes.filter(item => item.status)];
      }
    );
    this.subcriptionConsulta = this.store.select('consultaCatalogos').subscribe(
      (state) => {
        this.sucursales = [...state.sucursales.filter(item => item.status)];
        this.servicios = [...state.servicios.filter(item => item.status)];
        // this.filtrarServicios(this.asignacion.cliente);
        this.serviciosSelecionados = this.servicios.filter( item =>  item.cliente === this.asignacion.cliente);
        this.clientes = [...state.clientes.filter(item => item.status )];
        this.tipoServicios =[...state.tipoServicios.filter(item => item.status)];
      }
    );
    console.log('Inicializa component asignacion...');
    this.inicializa();
  }

  inicializa() {
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        this.id = params.id;
        if (id > 0) {
          this.store.dispatch(new usuariosActions.CargarAsignacion(id));
        } else {
          this.asignacion = new Asignaciones (null);
          this.asignacion.fecInicio = null;
          this.asignacion.fecTermino = null;
        }
    });
    this.store.dispatch(new usuariosActions.CargarTipoSolicitudes());
    this.store.dispatch(new usuariosActions.ConsultaCatalogoSucursales());
    this.store.dispatch(new usuariosActions.ConsultaCatalogoServicios());
    this.store.dispatch(new usuariosActions.ConsultaCatalogoClientes());
    this.store.dispatch(new usuariosActions.ConsultaCatalogoTipoServicios());
    
  }


  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeAsignacion.unsubscribe();
    this.subscribeSolicitud.unsubscribe();
    this.subcriptionConsulta.unsubscribe();
  }


  filtrarServicios(id){
    this.asignacion.servicio = null;
    this.serviciosSelecionados = this.servicios.filter( item =>  item.cliente === id);
  }

  filtrarSucursal(id){
    this.servicios.find( item => {
      if(item.id === id){
        this.asignacion.desSucursal = item.desSucursal;
        this.asignacion.sucursal = item.sucursal;
      }
    });
    if(this.asignacion.sucursal){
      this.sucursales.find( item => {
        if(item.id === this.asignacion.sucursal){
          this.asignacion.localidad = item.desEstado;
        }
      });
    }
  }

  editarMarcaDispositivo(asignacion: Asignaciones) {
    this.asignacion = {...asignacion};
    this.asignacionInicial = {...asignacion};
  }

  onSubmit() {
    Swal.fire({
      title: '¿Desea registrar los cambios?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_WARNING,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACTUALIZA,
      cancelButtonText: MESSAGES.M_BTN_CANCEL_A,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm: (result) => {
        return new Promise((resolve) => {
          this.asignacionService.guardarAsignacion(this.asignacion).subscribe(
            (respuesta) => {
              resolve(respuesta);
            }, (error) => {
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      }
    }).then( (resul) => {
      if (resul.value) {
        Swal.fire({
          title: MESSAGES.M_TIT_SUCCESS,
          timer: 1200,
          showConfirmButton: false,
          icon: 'success',
          onOpen: () => {}
        }).then(() => {}, () => {});
        this.router.navigate(['/auth/asignacion']);
      } else {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () =>  {}
        }).then(() => {}, () => {});
      }
    });
  }

  limpiar(form) {
    this.asignacion = new Asignaciones (null);;
    this.asignacionInicial = new Asignaciones (null);;
    form.reset();
  }



  verficarExistencia(){
    if(this.asignacion.tipoSolicitud > 0 && this.asignacion.tipoServicio > 0  && this.asignacion.servicio > 0 && this.asignacion.numeroOrden > 0 ){
      this.actualizar = false;
      this.store.dispatch(new usuariosActions.ExisteAsignacionFijo(this.asignacion));
    }
  }

  mensaje(consulta: Asignaciones){
    Swal.fire({
      title: 'Asignación duplicada',
      icon: 'info',
      text: 'Ya se encuentra una asignación registrada con esta información, ¿deseas cargar la asignación?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_INFO,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACTUALIZA,
      cancelButtonText: MESSAGES.M_BTN_CANCEL_A,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm: (result) => {
        return new Promise((resolve, reject) => {
          this.asignacion = {...consulta};
          this.asignacion.fecInicio = (new Date(this.asignacion.fecInicio) );
          this.asignacion.fecTermino = (new Date(this.asignacion.fecTermino) );
          this.asignacionInicial = {...this.asignacion};
          resolve(this.asignacion);
        });
      }
    }).then( (resul) => {
      if (resul.value) {
        Swal.fire({
          title: MESSAGES.M_TIT_SUCCESS,
          timer: 1200,
          showConfirmButton: false,
          icon: 'success',
          onOpen: () => {}
        }).then(() => {}, () => {});
      } else {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () =>  {}
        }).then(() => {}, () => {});
      }
    });
    this.store.dispatch(new usuariosActions.LimpiarAsignacionFijo());
  }

  fechaTermino(){
    if(this.asignacion.opcFin){
      this.asignacion.fecTermino = null;
    }else{
      this.asignacion.fecTermino = new Date();
    }
  }

}
