import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Colores } from '../models/colores';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColoresService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }
  registerColores(colorToRegister: Colores): Observable<any> {
		const params = JSON.stringify({color: colorToRegister});
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/color', params, {headers});
  }
  
  getColores(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
  
    return this.http.get(this.url + '/color', {headers});
   }

   updateEstado(id): Observable<any> {
    const params = JSON.stringify(id);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.put(this.url + '/color', params, {headers});
   }

   deleteColor(id: number): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
	
		return this.http.delete(this.url + '/color/' + id, {headers});
  }
  

}