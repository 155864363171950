import {Component, HostListener, OnInit, OnDestroy} from '@angular/core';
import {ThemeOptions} from '../../../theme-options';
import {select} from '@angular-redux/store';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { Identity } from 'src/app/models/user';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnDestroy {
  public extraParameter: any;
  public identity: Identity;
  private subscripcion = new Subscription();
  public ocultar = true;
  public tipoSidebar: number = 0;

  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>
  ) {
    this.identity = new Identity(null);
  }

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1600) {
        this.globals.toggleSidebar = true;
      }
    });

    if ( this.activatedRoute.snapshot.firstChild
       && this.activatedRoute.snapshot.firstChild.data
       && this.activatedRoute.snapshot.firstChild.data.extraParameter) {
      this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;
    } else {
      this.extraParameter = '';
    }

    this.subscripcion = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
      }
    );
    this.tipoOpcionSidebar();
  }

  tipoOpcionSidebar(){
    if(this.identity.tipo === 1 || this.identity.tipo === 5){
      this.tipoSidebar = 1;
    }else if(this.identity.permisos && (this.identity.permisos.actividadesRegEdit || 
      this.identity.permisos.indicadorCapturar || this.identity.permisos.asignacionCapturar ||
      this.identity.permisos.asignacionListVer || this.identity.permisos.asignacionRegVer ||
      this.identity.permisos.indicadorRegVer || this.identity.permisos.indicadorRegEdit ||
      this.identity.permisos.catDepartamentosEdit || this.identity.permisos.catDepartamentosVer || 
      this.identity.permisos.catDivisionesEdit || this.identity.permisos.catDivisionesVer || 
      this.identity.permisos.catSucursalesEdit || this.identity.permisos.catSucursalesVer || 
      this.identity.permisos.catColoresEdit || this.identity.permisos.catColoresVer || 
      this.identity.permisos.catServiciosEdit || this.identity.permisos.catServiciosVer || 
      this.identity.permisos.catClienteEdit || this.identity.permisos.catClienteVer ||
      this.identity.permisos.catTipoServicioEdit || this.identity.permisos.catTipoServicioVer ||
      this.identity.permisos.rptCoberturaDia || this.identity.permisos.rptCobertura || 
      this.identity.permisos.rptServicioCop || this.identity.permisos.rptBajaVacantes || 
      this.identity.permisos.rptServicioInicia || this.identity.permisos.rptServicioTermina || 
      this.identity.permisos.rptIndicador || this.identity.permisos.rptIndicadorArea || 
      this.identity.permisos.rptIndicadorUsuario || this.identity.permisos.rptActividades)){
      this.tipoSidebar = 2;
    }else if(this.identity.permisos && (this.identity.permisos.empresasListEdit || 
      this.identity.permisos.empresasListVer || this.identity.permisos.contactosListVer || 
      this.identity.permisos.contactosListEdit || this.identity.permisos.oportunidadesListVer || 
      this.identity.permisos.oportunidadesListEdit || this.identity.permisos.catIndustriasVer || 
      this.identity.permisos.catIndustriasEdit || this.identity.permisos.catOrigenesVer || 
      this.identity.permisos.catOrigenesEdit || this.identity.permisos.catPaisesVer || 
      this.identity.permisos.catPaisesEdit || this.identity.permisos.rptKpiVendedores || 
      this.identity.permisos.rptCumpleannos || this.identity.permisos.rptOportunidadEtapas || 
      this.identity.permisos.rptOportunidadPropietarios )){
      this.tipoSidebar = 3;
    }else if(this.identity.permisos && (this.identity.permisos.ticketsVer || 
      this.identity.permisos.ticketsEdit )){
      this.tipoSidebar = 4;
    }else{
      this.tipoSidebar = 0;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1600) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }

  ngOnDestroy() {
    this.subscripcion.unsubscribe();
  }

  logout() {
    this.store.dispatch( new usuariosActions.UnsetAuthAction() );
  }

}
