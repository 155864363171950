import { SortOrder } from './sort';

export class Colores{
	public id: number;
    public nombre: string;
    public color: string;


	constructor(
		obj: DataObjColores
	){
		this.id = obj && obj.id? obj.id :null;
		this.nombre = obj && obj.nombre? obj.nombre :'';
        this.color = obj && obj.color? obj.color :'';
	}
}
interface DataObjColores{
    id: number;
    nombre: string;
    color: string;
}


export const ColorSortType: SortOrder[] = [
	{ id: '+id', text: 'ID'},
	{ id: '-id', text: 'ID desc'},
	{ id: '+nombre', text: 'Nombre'},
	{ id: '-nombre', text: 'Nombre desc'}
  ];