import { Action } from '@ngrx/store';
import { Cliente } from 'src/app/models/clientes';

export const CARGAR_CLIENTES = '[Cliente] Cargar cliente';
export const CARGAR_CLIENTES_FAIL = '[Cliente] Cargar cliente FAIL';
export const CARGAR_CLIENTES_SUCCESS = '[Cliente] Cargar cliente SUCCESS';
export const ACTUALIZA_CLIENTE = '[Cliente] Actualiza cliente';
export const ACTUALIZA_CLIENTE_FAIL = '[Cliente] Actualiza cliente FAIL';
export const ACTUALIZA_CLIENTE_SUCCESS = '[Cliente] Actualiza cliente SUCCESS';
export const ACTUALIZA_CLIENTE_ESTATUS = '[Cliente] Actualiza cliente estatus';
export const ACTUALIZA_CLIENTE_ESTATUS_FAIL = '[Cliente] Actualiza cliente estatus FAIL';
export const ACTUALIZA_CLIENTE_ESTATUS_SUCCESS = '[Cliente] Actualiza cliente estatus SUCCESS';

export class CargarClientes implements Action {
  readonly type = CARGAR_CLIENTES;
}

export class CargarClientesFail implements Action {
  readonly type = CARGAR_CLIENTES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarClientesSuccess implements Action {
  readonly type = CARGAR_CLIENTES_SUCCESS;

  constructor( public clientes: Cliente[] ) {}
}

export class ActualizaCliente implements Action {
  readonly type = ACTUALIZA_CLIENTE;

  constructor( public cliente: Cliente ) { }
}

export class ActualizaClienteFail implements Action {
  readonly type = ACTUALIZA_CLIENTE_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaClienteSuccess implements Action {
  readonly type = ACTUALIZA_CLIENTE_SUCCESS;

  constructor( public cliente: Cliente ) {}
}

export class ActualizaClienteEstatus implements Action {
  readonly type = ACTUALIZA_CLIENTE_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaClienteEstatusFail implements Action {
  readonly type = ACTUALIZA_CLIENTE_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaClienteEstatusSuccess implements Action {
  readonly type = ACTUALIZA_CLIENTE_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export type clienteAcciones = CargarClientes 
                                | CargarClientesFail 
                                | CargarClientesSuccess 
                                | ActualizaCliente 
                                | ActualizaClienteFail 
                                | ActualizaClienteSuccess 
                                | ActualizaClienteEstatus 
                                | ActualizaClienteEstatusFail 
                                | ActualizaClienteEstatusSuccess;

