<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row pl-3 pr-3">
  <div class="col-sm-12 col-lg-6" *ngIf="identity.permisos && (identity.permisos.ticketsEdit || identity.permisos.ticketsVer)">
    <div class="card-hover-shadow-2x mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal"><i
          class="header-icon pe-7s-ticket bg-black"></i> Mis tickets
        </div>
      </div>
      <div class="scroll-area-lg">
        <perfect-scrollbar [autoPropagation]="true">
          <ul class="todo-list-wrapper list-group list-group-flush">
            <app-item-ticket [tickets]="tickets | optionCheckMultiple : statusFilter
              | optionCheckMultiple : existeFilter
              | orderBy: '-fecModificacion' 
              | slice: 0 : 10"></app-item-ticket>
            <li class="text-center list-group-item" *ngIf="!(tickets | optionCheckMultiple : statusFilter | optionCheckMultiple : existeFilter).length"><h3 class="text-muted">Sin tickets pendientes</h3></li>
          </ul>
        </perfect-scrollbar>
      </div>
      <div class="d-flex card-footer">
        <a class="btn btn-link btn-sm mr-auto" *ngIf="identity.permisos && identity.permisos.ticketsVer" [routerLink]="['/auth/tickets']">Ver más</a>
        <a class="btn btn-primary" *ngIf="identity.permisos && identity.permisos.ticketsEdit" [routerLink]="['/auth/tickets/register', 0]">Nuevo ticket</a>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-lg-6" *ngIf="identity && (identity.permisos.oportunidadesListVer || identity.permisos.empresasListVer || identity.permisos.contactosListVer)">
    <div class="mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i class="header-icon lnr-apartment bg-black"> </i>
          Información
        </div>
      </div>
      <div class="p-0 card-body">
        <div class="p-1 slick-slider-sm mx-auto">
          <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig6">
            <div ngxSlickItem *ngIf="identity && identity.permisos.contactosListVer">
              <div class="widget-chart widget-chart2 text-left p-0">
                <div class="widget-chat-wrapper-outer">
                  <div class="widget-chart-content widget-chart-content-lg pb-0 pt-2">
                    <div class="widget-chart-flex">
                      <div class="widget-title opacity-5 text-muted text-uppercase">Nuevos contactos</div>
                    </div>
                    <div class="widget-numbers">
                      <div class="widget-chart-flex">
                        <div>
                          <small class="opacity-5 pr-1">Hoy </small>
                          <span>{{ hoyContactos }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="widget-chart-wrapper he-auto opacity-10 m-0">
                    <div class="d-block">
                      <apx-chart
                      [series]="seriesContactos"
                      [chart]="{
                        height: 350,
                        type: 'line',
                        stacked: false,
                        locales: [lenguaje],
                        defaultLocale: 'es'
                      }"
                      [xaxis]="{
                        type: 'datetime'
                      }"
                      [dataLabels]="{
                        enabled: false
                      }"
                      [grid]="{
                        padding: {
                          right: 30,
                          left: 20
                        }
                      }"
                      [colors]="['#3f6ad8', '#3ac47d', '#d92550']"
                      [stroke]="{
                        curve: 'straight'
                      }"
                      [labels]="labelsContactos"
                    ></apx-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ngxSlickItem *ngIf="identity && identity.permisos.empresasListVer">
              <div class="widget-chart widget-chart2 text-left p-0">
                <div class="widget-chat-wrapper-outer">
                  <div class="widget-chart-content widget-chart-content-lg pb-0 pt-2">
                    <div class="widget-chart-flex">
                      <div class="widget-title opacity-5 text-muted text-uppercase">Nuevas empresas</div>
                    </div>
                    <div class="widget-numbers">
                      <div class="widget-chart-flex">
                        <div>
                          <small class="opacity-5 pr-1">Hoy </small>
                          <span>{{ hoyEmpresas }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="widget-chart-wrapper he-auto opacity-10 m-0">
                    <div class="d-block">
                      <apx-chart
                      [series]="seriesEmpresas"
                      [chart]="{
                        height: 350,
                        type: 'line',
                        stacked: false,
                        locales: [lenguaje],
                        defaultLocale: 'es'
                      }"
                      [xaxis]="{
                        type: 'datetime'
                      }"
                      [dataLabels]="{
                        enabled: false
                      }"
                      [grid]="{
                        padding: {
                          right: 30,
                          left: 20
                        }
                      }"
                      [colors]="['#3ac47d']"
                      [stroke]="{
                        curve: 'straight'
                      }"
                      [labels]="labelsEmpresas"
                    ></apx-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ngxSlickItem *ngIf="identity && identity.permisos.oportunidadesListVer">
              <div class="widget-chart widget-chart2 text-left p-0">
                <div class="widget-chat-wrapper-outer">
                  <div class="widget-chart-content widget-chart-content-lg pb-0 pt-2">
                    <div class="widget-chart-flex">
                      <div class="widget-title opacity-5 text-muted text-uppercase">Nuevas oportunidades</div>
                    </div>
                    <div class="widget-numbers">
                      <div class="widget-chart-flex">
                        <div>
                          <small class="opacity-5 pr-1">Hoy </small>
                          <span>{{ hoyOportunidades }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="widget-chart-wrapper he-auto opacity-10 m-0">
                    <div class="d-block">
                      <apx-chart
                      [series]="seriesOportunidades"
                      [chart]="{
                        height: 350,
                        type: 'line',
                        stacked: false,
                        locales: [lenguaje],
                        defaultLocale: 'es'
                      }"
                      [xaxis]="{
                        type: 'datetime'
                      }"
                      [dataLabels]="{
                        enabled: false
                      }"
                      [grid]="{
                        padding: {
                          right: 30,
                          left: 20
                        }
                      }"
                      [colors]="['#d92550']"
                      [stroke]="{
                        curve: 'straight'
                      }"
                      [labels]="labelsOportunidades"
                    ></apx-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-lg-6" *ngIf="identity.permisos && (identity.permisos.oportunidadesListEdit || identity.permisos.oportunidadesListVer || identity.permisos.oportunidadesRegEdit
  || identity.permisos.oportunidadesRegVer)">
    <div class="card-hover-shadow-2x mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal"><i
          class="header-icon fas fa-hand-holding-usd bg-black"></i> Oportunidades por etapas
        </div>
      </div>
      <div class="card-body">
        <ejs-accumulationchart [legendSettings]="legend" [tooltip]="tooltipSettings" *ngIf="dataFunnel.length">
          <e-accumulation-series-collection>
            <e-accumulation-series type="Funnel" [dataSource]="dataFunnel" xName="name" yName="value"  innerTadius="10%" [dataLabel]="chartLabel" [palettes]="colorPalettes"></e-accumulation-series>
          </e-accumulation-series-collection>
        </ejs-accumulationchart>
        <div *ngIf="!dataFunnel.length">
          <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-lg-6" *ngIf="(identity && (identity.permisos.actividadesRegVer || identity.permisos.actividadesRegEdit))">
    <div *ngIf="!hoyActividad" (dblclick)="openActividad()">
      <div class="card mb-3 card-tabbed-header">
        <div class="no-results">
          <div class="swal2-icon swal2-error swal2-animate-error-icon swal2-icon-show" style="display: flex;">
            <span class="swal2-x-mark">
              <span class="swal2-x-mark-line-left"></span>
              <span class="swal2-x-mark-line-right"></span>
            </span>
          </div>
          <div class="results-title">Actividad no registrada</div>
          <div class="results-subtitle">No se ha registrado ninguna actividad el día de hoy</div>
        </div>
      </div>
    </div>
    <div *ngIf="hoyActividad" (dblclick)="openActividad()">
      <div class="card mb-3 card-tabbed-header" >
        <div class="no-results">
          <div class="swal2-icon swal2-success swal2-animate-success-icon">
            <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
            <span class="swal2-success-line-tip"></span>
            <span class="swal2-success-line-long"></span>
            <div class="swal2-success-ring"></div>
            <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
            <div class="swal2-success-circular-line-right"
              style="background-color: rgb(255, 255, 255);"></div>
          </div>
          <div class="results-title">Actividad registrada</div>
          <div class="results-subtitle">Se registró la actividad del día de hoy</div>
        </div>
      </div>
    </div>
    <div>
      <div class="card mb-3 widget-chart">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg"></div> <!-- bg-primary-->
          <i class="lnr-pencil text-primary"></i>
        </div>
        <div class="widget-heading">Actividades registradas</div>
        <div class="widget-numbers">
          <span class="count-up-wrapper-2">
            <span [countUp]="cantidadActividades"></span> <!--  [options]="myOpts"-->
          </span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="indicadores">
    <div class="row" *ngIf="indicadores.length > 0" (dblclick)="openCaptura()">
      <div class="col-md-4 form-group" *ngFor="let item of indicadores">
        <div class="card mb-3 card-tabbed-header" >
          <div class="no-results">
            <div class="icon-wrapper rounded-circle font-icon-lg">
              <div class="icon-wrapper-bg"></div> <!-- bg-primary *ngIf="indicadores"-->
              <i class=" lnr-clock text-warning"></i>
            </div>
            <div class="results-title">Indicador no capturado</div>
            <div class="results-subtitle">No se ha capturado el indicador {{item.nombre}}, fecha limite {{item.dia | amDateFormat: 'DD/MM/YYYY'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row p-3" *ngIf="identity.permisos && identity.permisos.usuariosListVer">
  <div class="col-md-12">
    <div class="main-card mb-3 card">
      <div class="card-header">Últimas conexiones de usuarios</div>
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
          <thead>
            <tr>
              <th class="text-center">#</th>
              <th>Nombre</th>
              <th>Última conexión</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let usuario of usuariosConectados">
              <td class="text-center text-muted">#{{usuario.id}}</td>
              <td>
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-3">
                      <div class="widget-content-left">
                        <img width="40" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + usuario.id+ '/imagenes'" alt="Imagen de usuario">
                      </div>
                    </div>
                    <div class="widget-content-left flex2">
                      <div class="widget-heading">{{usuario.descripcion}}</div>
                      <div class="widget-subheading opacity-7">{{usuario.desTipo}}</div>
                    </div>
                  </div>
                </div>
              </td>
              <td>{{usuario.registro | amLocale: 'es' | amCalendar }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>