import { Action } from '@ngrx/store';
import { Colores } from 'src/app/models/colores';

export const CARGAR_COLORES = '[Colores] Cargar colores';
export const CARGAR_COLORES_FAIL = '[Colores] Cargar colores FAIL';
export const CARGAR_COLORES_SUCCESS = '[Colores] Cargar colores SUCCESS';
export const ACTUALIZA_COLOR = '[Colores] Actualiza color';
export const ACTUALIZA_COLOR_FAIL = '[Colores] Actualiza color FAIL';
export const ACTUALIZA_COLOR_SUCCESS = '[Colores] Actualiza color SUCCESS';
export const ELIMINAR_COLOR = '[Colores] Elimina color';
export const ELIMINAR_COLOR_FAIL = '[Colores] Elimina color FAIL';
export const ELIMINAR_COLOR_SUCCESS = '[Colores] Elimina color SUCCESS';



export class CargarColores implements Action {
    readonly type = CARGAR_COLORES;
  }
  
export class CargarColoresFail implements Action {
  readonly type = CARGAR_COLORES_FAIL;
  
  constructor( public payload: any ) {}
  }
  
export class CargarColoresSuccess implements Action {
  readonly type = CARGAR_COLORES_SUCCESS;
  
  constructor( public colores: Colores[] ) {}
  }

export class ActualizaColor implements Action {
  readonly type = ACTUALIZA_COLOR;

  constructor( public color: Colores ) { }
}

export class ActualizaColorFail implements Action {
  readonly type = ACTUALIZA_COLOR_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaColorSuccess implements Action {
  readonly type = ACTUALIZA_COLOR_SUCCESS;

  constructor( public color: Colores ) {}
}

export class EliminarColor implements Action {
  readonly type = ELIMINAR_COLOR;

  constructor( public id: number ) { }
}

export class EliminarColorFail implements Action {
  readonly type = ELIMINAR_COLOR_FAIL;

  constructor( public payload: any ) {}
}

export class EliminarColorSuccess implements Action {
  readonly type = ELIMINAR_COLOR_SUCCESS;

  constructor( public id: number ) {}
}

export type coloresAcciones = CargarColores
                            | CargarColoresFail
                            | CargarColoresSuccess
                            | ActualizaColor 
                            | ActualizaColorFail 
                            | ActualizaColorSuccess 
                            | EliminarColor 
                            | EliminarColorFail 
                            | EliminarColorSuccess;