import * as fromUsuario from '../actions';
import { Catalogo } from 'src/app/models/catalogo';
import { Sucursal } from 'src/app/models/sucursales';
import { Colores } from 'src/app/models/colores';
import { Servicio } from 'src/app/models/servicios';
import { Cliente } from 'src/app/models/clientes';

export interface ConsutaCatalogoState {
  divisiones: Catalogo[];
  departamentos: Catalogo[];
  sucursales: Sucursal[];
  colores: Colores[];
  servicios: Servicio[];
  clientes: Cliente[];
  tipoServicios: Catalogo[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: ConsutaCatalogoState = {
  divisiones: [],
  departamentos: [],
  sucursales: [],
  colores: [],
  servicios: [],
  clientes: [],
  tipoServicios: [],
  loaded: false,
  loading: false,
  error: null
};

export function consultaCatalogosReducer( state = initState, action: fromUsuario.consultaCatalogosAcciones ): ConsutaCatalogoState {

  switch ( action.type ) {
    case fromUsuario.CONSULTA_CATALOGO_DIVISIONES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_CATALOGO_DIVISIONES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        divisiones: [...action.division]
      };
    case fromUsuario.CONSULTA_CATALOGO_DIVISIONES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
     case fromUsuario.CONSULTA_CATALOGO_DEPARTAMENTOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_CATALOGO_DEPARTAMENTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        departamentos: [...action.departamentos]
      };
    case fromUsuario.CONSULTA_CATALOGO_DEPARTAMENTOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
    case fromUsuario.CONSULTA_CATALOGO_SUCURSALES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_CATALOGO_SUCURSALES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        sucursales: [...action.sucursales]
      };
    case fromUsuario.CONSULTA_CATALOGO_SUCURSALES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
    case fromUsuario.CONSULTA_CATALOGO_COLORES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_CATALOGO_COLORES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        colores: [...action.colores]
      };
    case fromUsuario.CONSULTA_CATALOGO_COLORES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
    case fromUsuario.CONSULTA_CATALOGO_SERVICIOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_CATALOGO_SERVICIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        servicios: [...action.servicios]
      };
    case fromUsuario.CONSULTA_CATALOGO_SERVICIOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
    case fromUsuario.CONSULTA_CATALOGO_CLIENTES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_CATALOGO_CLIENTES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        clientes: [...action.clientes]
      };
    case fromUsuario.CONSULTA_CATALOGO_CLIENTES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
    case fromUsuario.CONSULTA_CATALOGO_TIPO_SERVICIOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_CATALOGO_TIPO_SERVICIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tipoServicios: [...action.tipos]
      };
    case fromUsuario.CONSULTA_CATALOGO_TIPO_SERVICIOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
    default:
      return state;
  }
}
