import { Action } from '@ngrx/store';
import { Captura, Resultado } from 'src/app/models/captura-resultado-indicador';

export const CARGAR_RESULTADOS_INDICADORES = '[Captura] Cargar resultados indicadores';
export const CARGAR_RESULTADOS_INDICADORES_FAIL = '[Captura] Cargar resultados indicadores FAIL';
export const CARGAR_RESULTADOS_INDICADORES_SUCCESS = '[Captura] Cargar resultados indicadores SUCCESS';
export const ACTUALIZA_RESULTADO_INDICADOR = '[Captura] Actualiza resultado indicador';
export const ACTUALIZA_RESULTADO_INDICADOR_FAIL = '[Captura] Actualiza resultado indicador FAIL';
export const ACTUALIZA_RESULTADO_INDICADOR_SUCCESS = '[Captura] Actualiza resultado indicador SUCCESS';
export const LIMPIAR_RESULTADOS = '[Captura] Limpiar indicador';



export class CargarResultadosIndicadores implements Action {
    readonly type = CARGAR_RESULTADOS_INDICADORES;
  }
  
export class CargarResultadosIndicadoresFail implements Action {
  readonly type = CARGAR_RESULTADOS_INDICADORES_FAIL;
  
  constructor( public payload: any ) {}
  }
  
export class CargarResultadosIndicadoresSuccess implements Action {
  readonly type = CARGAR_RESULTADOS_INDICADORES_SUCCESS;
  
  constructor( public capturas: Captura[] ) {}
  }

export class ActualizaResultadoIndicador implements Action {
  readonly type = ACTUALIZA_RESULTADO_INDICADOR;
  
  constructor( public id: number, public resultado: any, public indicador: number ) { }
  }
  
export class ActualizaResultadoIndicadorFail implements Action {
  readonly type = ACTUALIZA_RESULTADO_INDICADOR_FAIL;
  
  constructor( public payload: any ) {}
  }
  
export class ActualizaResultadoIndicadorSuccess implements Action {
  readonly type = ACTUALIZA_RESULTADO_INDICADOR_SUCCESS;
  
  constructor( public resultado : Resultado ) {}
  }


export class LimpiarResultados implements Action {
    readonly type = LIMPIAR_RESULTADOS;

    constructor() {}  
}

export type capturasAcciones = CargarResultadosIndicadores
                                | CargarResultadosIndicadoresFail
                                | CargarResultadosIndicadoresSuccess
                                | ActualizaResultadoIndicador
                                | ActualizaResultadoIndicadorFail
                                | ActualizaResultadoIndicadorSuccess
                                | LimpiarResultados;