import { Usuario } from '../../models/usuario.model';
import * as fromUsuarios from '../actions';
import { User } from 'src/app/models/user';

export interface UsuariosState {
    usuarios: User[];
    loaded: boolean;
    loading: boolean;
    error: any;
}

const estadoInicial: UsuariosState = {
    usuarios: [],
    loaded: false,
    loading: false,
    error: null
};

export function usuariosReducer( state = estadoInicial, action: fromUsuarios.usuariosAcciones ): UsuariosState {
	switch ( action.type ) {
		case fromUsuarios.CARGAR_USUARIOS:
			return {
				...state,
				loading: true,
				error: null
			};
		case fromUsuarios.CARGAR_USUARIOS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				usuarios: [...action.usuarios]
			};
		case fromUsuarios.CARGAR_USUARIOS_FAIL:
			return {
				...state,
				loaded: false,
				loading: false,
				error: {
					status: action.payload.status,
					message: action.payload.message,
					url: action.payload.url
				}
			};
		case fromUsuarios.ELIMINAR_USUARIOS:
			return {
				...state,
				loading: false,
				loaded: true
			};
		case fromUsuarios.ELIMINAR_USUARIOS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				usuarios: state.usuarios.filter( (user) => user.id !== action.id)
			};
		case fromUsuarios.ACTUALIZA_USUARIO_ESTATUS:
			return {
				...state,
				loading: true,
				error: null
			};
		case fromUsuarios.ACTUALIZA_USUARIO_ESTATUS_SUCCESS:
			const itm = state.usuarios.find( item => item.id === action.usuario.id);
      if (itm && itm.id) {
        state.usuarios = state.usuarios.map( item => {
          if (item.id === action.usuario.id) {
            item.status = action.usuario.status === 1 ? 2 : 1;
          }
          return item;
        });
      } 
			return {
				...state,
				loading: false,
				loaded: true
			};
		case fromUsuarios.ACTUALIZA_USUARIO_ESTATUS_FAIL:
			return {
				...state,
				loaded: false,
				loading: false,
				error: {
					status: action.payload.status,
					message: action.payload.message,
					url: action.payload.url
				}
			};
		default:
			return state;
	}
}

