import { Pipe, PipeTransform } from '@angular/core';
import { ReporteArea } from '../models/tablero-kpi';

interface ObjFiltroReporteArea {
  area: string;
  indicador: string;
  tipo: string;
}

@Pipe({
  name: 'filtroIndicadorArea'
})
export class FiltroIndicadorAreaPipe implements PipeTransform {

  transform(value: ReporteArea[], args: ObjFiltroReporteArea): any {
    if (!value.length) {
      return [];
    }
    if (!args) {
      return value;
    }
    const listaFiltrada = value.filter( (item: ReporteArea) => {
      if (
        (!args.area || (args.area && item.area === args.area)) &&   
        (!args.tipo || (args.tipo && item.tipo === args.tipo)) && 
        (!args.indicador || (args.indicador && item.indicador === args.indicador))){
        return true;
      } else {
        return false;
      }
    });
    return listaFiltrada;
  }

}
