import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { ArchivoConcepto } from 'src/app/models/archivo';

@Injectable({
  providedIn: 'root'
})
export class ActividadesArchivosService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  eliminarArchivo(id: number, archivo: ArchivoConcepto): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.delete(this.url + '/archivos/' + archivo.nombre + '/formato/' + archivo.tipo + '/empresa/' + id + '/tipo/' + archivo.concepto, {headers});
  }

  obtenerArchivos(id: number, tipo: number): Observable<any> {
		const headers = new HttpHeaders().set('Content-type', 'application/json');
		
		return this.http.get(this.url + '/archivos/' + id + '/formato/' + tipo, {headers});
  }

  descargarArchivos(id: number, archivo: ArchivoConcepto): Observable<any> {
		const headers = new HttpHeaders().set('Content-type', 'application/json');
		
		return this.http.get(this.url + '/archivos/descargar/' + archivo.nombre + '/formato/' + archivo.tipo + '/empresa/' + id + '/tipo/' + archivo.concepto, {headers});
  }






}
