import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { Asignaciones } from 'src/app/models/asignaciones';
import { Fechas } from 'src/app/models/fechas';

@Injectable({
  providedIn: 'root'
})
export class AsignacionesService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  guardarAsignacion(asignacion: Asignaciones): Observable<any> {
    const obj = {asignacion};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/asignaciones', params, {headers});
  }

  obtenerAsignaciones(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/asignaciones', {headers});
  }

  eliminarAsignacion(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.delete(this.url + '/asignaciones/' + id, {headers});
  }

  obtenerAsignacion(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/asignaciones/' + id, {headers});
  }

  actualizarEstatusAsignacion(asignacion: Asignaciones): Observable<any>{
    const param = JSON.stringify(asignacion);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/asignaciones/estatus', param, {headers});
  }

  existeAsignacion(asignacion: Asignaciones): Observable<any> {
    const param = JSON.stringify(asignacion);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.put(this.url + '/asignaciones/existe ',param, {headers});
  }
  
}
