import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { CapturasService } from 'src/app/services/captura-resultado-indicador.service';

@Injectable()
export class CapturasEffects {

  constructor(
    private actions$: Actions,
    public capturasService: CapturasService
  ) {}

  @Effect()
  cargarIndicadoresCapturar$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_RESULTADOS_INDICADORES ),
    switchMap( () => {
      return this.capturasService.obtenerIndicadoresCapturar()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarResultadosIndicadoresSuccess(respuesta.captura);
          }),
          catchError( error => of(new usuariosActions.CargarResultadosIndicadoresFail(error))  )
        );
    })
  );


  @Effect()
  actualizaResultado$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_RESULTADO_INDICADOR ),
    switchMap( (action: any) => {
      return this.capturasService.actualizaResultado(action.id, action.resultado, action.indicador)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaResultadoIndicadorSuccess(respuesta.resultado);
            }),
            catchError( error => of(new usuariosActions.ActualizaResultadoIndicadorFail(error))  )
        );
    })
  );


}
