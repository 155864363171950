<div class="d-flex h-100 justify-content-center align-items-center">
  <div class="mx-auto  col-md-6">
    <div class="app-logo-long mx-auto mb-3"></div>
    <form #f="ngForm" #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div class="modal-dialog w-100">
        <div class="modal-content">
          <div class="modal-header">
            <div class="h5 modal-title">
              Cambiar la contraseña del usuario
              <h6 class="mt-1 mb-0 opacity-8">
                <span>Facil de hacer, solo sigue los siguientes pasos.</span>
              </h6>
            </div>
            <img src="../../../../assets/images/login/logo.png" alt="Logotipo" style="width: 70px;">
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xs-12">
                <div class="form-group required">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="contrasenaUsuarioRecovery" class="control-label">Nueva Contraseña: </label>
                    </div>
                    <div class="col-sm-8">
                      <input name="contrasenaUsuarioRecovery" id="contrasenaUsuarioRecovery" #contrasenaUsuarioRecovery="ngModel" type="password" class="form-control" [(ngModel)]="usuario.contrasena" 
                          title="Ingrese contraseña de usuario. Requiere ser mayor de 6 caracteres sin espacios." 
                          placeholder="Ingrese minimo 6 caracteres" minlength="6" maxlength="50" pattern="^\S{6,50}$" required />
                    </div>
                  </div>
                </div>
                <div class="form-group required">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="confirmaContrasenaUsuarioRecovery" class="control-label">Confirma contraseña: </label>
                    </div>
                    <div class="col-sm-8">
                      <input name="confirmaContrasenaUsuarioRecovery" id="confirmaContrasenaUsuarioRecovery" #confirmaContrasenaUsuarioRecovery="ngModel" type="password" class="form-control" 
                        [(ngModel)]="usuario.confirmaContrasena" title="Contraseña de usuario" placeholder="Ingrese minímo 6 caracteres" 
                        minlength="6" maxlength="50" pattern="^\S{6,50}$" required />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="divider"></div>
            <h6 class="mb-0">
              <a [routerLink]="['/public/login']" class="text-primary">Inciar sesión</a>
            </h6>
          </div>
          <div class="modal-footer clearfix">
            <div class="float-right">
              <button type="submit" data-style="zoom-in" class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-success btn-lg" [disabled]="!f.valid"> Registrar cambios</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
