import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity, User } from 'src/app/models/user';
import { Subscription, Subject } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import { KpisVendedores, KpisVendedorOportunidadSortType } from 'src/app/models/kpis-vendedores';
import { SeriesBar, FunnelGraph } from 'src/app/models/graph';
import { ActividadMultiple } from 'src/app/models/actividades';
import { Empresa, EmpresaSortType } from 'src/app/models/empresa';
import { Contacto, ContactoSortType } from 'src/app/models/contacto';
import { FilterType } from 'src/app/models/user';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-kpis-vendedores',
  templateUrl: './kpis-vendedores.component.html',
  styleUrls: ['./kpis-vendedores.component.sass']
})
export class KpisVendedoresComponent implements OnInit, OnDestroy {
  heading = 'Mis indicadores';
  subheading = '';
  icon = 'pe-7s-graph2 icon-gradient bg-dark';
  public title = 'Mis indicadores';
  private subscribeAuth: Subscription = new Subscription();
  private subscribeKPIsVendedor: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  public identity: Identity;
  public loading = false;
  public url: string;
  public version = 1;
  public series: SeriesBar[] = [];
	public categories: string[] = [];
	public actividades: ActividadMultiple[] = [];
	public empresas: Empresa[] = [];
	public contactos: Contacto[] = [];
  public oportunidadesAbiertas: KpisVendedores[] = [];
  public orderTypeContacto = [...ContactoSortType];
  public orderTypeEmpresas = [...EmpresaSortType];
  public orderTypeOportunidades = [...KpisVendedorOportunidadSortType];
  public oportunidadesAbiertasTotal: KpisVendedores = new KpisVendedores(null);
  public paginacionActividad = new Pagination(null);
  public paginacionEmpresa = new Pagination(null);
  public paginacionContacto = new Pagination(null);
  public paginacionOportunidad = new Pagination(null);
  public filterActiveActividad: boolean = false;
  public filterActiveEmpresa: boolean = false;
  public filterActiveContacto: boolean = false;
  public filterActiveOportunidad: boolean = false;
  public listSearchActividad: string[] = [];
  public listSearchEmpresa: string[] = [];
  public listSearchContacto: string[] = [];
  public listSearchOportunidad: string[] = [];
  public isCollapsed = false;
  public dataFunnel: FunnelGraph[] = [];
  public chartLabel: Object = {
    visible: true,
    position: 'Inside',
    name: 'text'
  };
  public legend: Object = {
    visible: true,
    position: 'Right'
  };
  public tooltipSettings: object = {
    enable : true,
    format: '${point.x} : <b>$${point.y}</b>'
  };

  public filterIndustrias: FilterType[] = [];
  public filterClasificaciones: FilterType[] = [];
  public filterPaises: FilterType[] = [];


  public filterOrigenContacto: FilterType[] = [];
  public filterEtapas: FilterType[] = [];
  public filterPaisesContacto: FilterType[] = [];
  public filterEstatus: FilterType[] = [];

  constructor(
    private store: Store<AppState>,
    private titleService: Title
  ) {
    // this.titleService.setTitle(GLOBAL.title + this.title);
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;
    this.paginacionActividad.sortType = '-id';
    this.paginacionEmpresa.sortType = '-id';
    this.paginacionContacto.sortType = '-id';
    this.paginacionOportunidad.sortType = '-id';
   }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.filterIndustrias = [...state.industrias.filter(item => item.status).map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        })];
        this.filterClasificaciones = [...state.clasificaciones.filter(item => item.status).map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        })];
        this.filterPaises = [...state.paises.filter(item => item.status).map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        })];
        this.filterEtapas = [...state.etapasContacto.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.filterEstatus = [...state.estadosContacto.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.filterOrigenContacto = [...state.origenContacto.filter( item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.filterPaisesContacto = [...state.paises.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
      }
    );
    this.subscribeKPIsVendedor = this.store.select('kpiVendedor').subscribe(
      (state) => {
        this.oportunidadesAbiertasTotal = new KpisVendedores(null);
        this.series = [...state.series];
        this.categories = [...state.categories];
        this.actividades = [...state.actividades];
        this.empresas = [...state.empresas];
        this.contactos = [...state.contactos];
        this.oportunidadesAbiertas = [...state.oportunidadesAbiertas];
        this.dataFunnel = [...state.dataFunnel];
        this.oportunidadesAbiertasTotal.nombre = 'Total';
        this.oportunidadesAbiertas.find(item => { this.oportunidadesAbiertasTotal.cantidad += item.cantidad });
        this.paginacionEmpresa.totalSize = state.totalEmpresa;
        this.paginacionEmpresa.totalFiltro = state.totalFiltroEmpresa;
        this.paginacionContacto.totalSize = state.totalContacto;
        this.paginacionContacto.totalFiltro = state.totalFiltroContacto;
      }
    );
    console.log('Inicializa el componente Contacto ...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeKPIsVendedor.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  inicializa() {
    this.store.dispatch(new usuariosActions.CargarActividadesMultiples());
    this.store.dispatch(new usuariosActions.CargarActividadesMultiplesPendientes());
    this.store.dispatch(new usuariosActions.CargarOportunidadesAbiertas());
    this.store.dispatch(new usuariosActions.CargarFunnelOportunidades());



    this.store.dispatch(new usuariosActions.ConsultaIndustrias());
    this.store.dispatch(new usuariosActions.ConsultaClasificaciones());
    this.store.dispatch(new usuariosActions.ConsultaPaises());



    this.store.dispatch(new usuariosActions.ConsultaOrigenContactos());
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());
    this.store.dispatch(new usuariosActions.ConsultaEstadosContactos());
    this.store.dispatch(new usuariosActions.ConsultaEtapasContactos());

    this.cargarEmpresas();
    this.cargarContactos();
  }

  sortActividad(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacionActividad.sortType || ('+' + descriptionSort) === this.paginacionActividad.sortType ) {
      this.paginacionActividad.sortReverse = !this.paginacionActividad.sortReverse;
      if (this.paginacionActividad.sortReverse) {
        this.paginacionActividad.sortType = '-' + descriptionSort;
      } else {
        this.paginacionActividad.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacionActividad.sortType = '+' + descriptionSort;
      this.paginacionActividad.sortReverse = false;
    }
  }

  changeTextSearchActividad() {
    this.listSearchActividad = this.paginacionActividad.textSearch.split(' ');
  }

  removerTextSearchActividad(text: string) {
    this.listSearchActividad = this.listSearchActividad.filter( item => item !== text );
    this.paginacionActividad.textSearch = this.listSearchActividad.join(' ');
  }

  sortEmpresa(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacionEmpresa.sortType || ('+' + descriptionSort) === this.paginacionEmpresa.sortType ) {
      this.paginacionEmpresa.sortReverse = !this.paginacionEmpresa.sortReverse;
      if (this.paginacionEmpresa.sortReverse) {
        this.paginacionEmpresa.sortType = '-' + descriptionSort;
      } else {
        this.paginacionEmpresa.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacionEmpresa.sortType = '+' + descriptionSort;
      this.paginacionEmpresa.sortReverse = false;
    }
  }

  changeTextSearchEmpresa() {
    this.listSearchEmpresa = this.paginacionEmpresa.textSearch.split(' ');
    if (this.paginacionEmpresa.textSearch.trim() !== this.paginacionEmpresa.textSearchAnterior) {
      this.cargarEmpresas();
    }
  }



  changeClasificaciones() {
    this.filterClasificaciones = [...this.filterClasificaciones];
    this.cargarEmpresas();
  }

  changeIndustrias() {
    this.filterIndustrias = [...this.filterIndustrias];
    this.cargarEmpresas();
  }

  changePaises() {
    this.filterPaises = [...this.filterPaises];
    this.cargarEmpresas();
  }


  cargarEmpresas (reset = true) {
    if (reset) {
      this.paginacionEmpresa.page = 1;
    }
    const opciones: any = {};
    this.paginacionEmpresa.textSearchAnterior = '' + this.paginacionEmpresa.textSearch.trim();
    opciones.numPage = this.paginacionEmpresa.page;
    opciones.numLimit = this.paginacionEmpresa.pageSize;
    opciones.desOrderBy = this.paginacionEmpresa.sortType;
    opciones.textSearch = this.listSearchEmpresa.join('|');
    opciones.desClasificacion = this.filterClasificaciones.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desIndustria = this.filterIndustrias.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desPais = this.filterPaises.filter( itm => itm.checked).map( itm => itm.id).join(',');
    this.store.dispatch(new usuariosActions.CargarEmpresasRegistradas(opciones));
  }

  printDireccion(empresa: Empresa) {
    let coma = '';
    let direccion = '';
    if (empresa.desPais) {
      direccion += coma + empresa.desPais;
      coma = ', ';
    }
    if (empresa.desEstado) {
      direccion += coma + empresa.desEstado;
      coma = ', ';
    }
    if (empresa.desMunicipio) {
      direccion += coma + empresa.desMunicipio;
      coma = ', ';
    }
    if (empresa.colonia) {
      direccion += coma + empresa.colonia;
      coma = ', ';
    }
    if (empresa.calle) {
      direccion += coma + empresa.calle;
      coma = ', ';
    }
    if (empresa.codigoPostal) {
      direccion += coma + 'C.P. ' + empresa.codigoPostal;
      coma = ', ';
    } 
    if (direccion === '') {
      direccion = 'Sin domicilio';
    }
    return direccion;
  }

  removerTextSearchEmpresa(text: string) {
    this.listSearchEmpresa = this.listSearchEmpresa.filter( item => item !== text );
    this.paginacionEmpresa.textSearch = this.listSearchEmpresa.join(' ');
    this.cargarEmpresas();
  }

  sortContacto(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacionContacto.sortType || ('+' + descriptionSort) === this.paginacionContacto.sortType ) {
      this.paginacionContacto.sortReverse = !this.paginacionContacto.sortReverse;
      if (this.paginacionContacto.sortReverse) {
        this.paginacionContacto.sortType = '-' + descriptionSort;
      } else {
        this.paginacionContacto.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacionContacto.sortType = '+' + descriptionSort;
      this.paginacionContacto.sortReverse = false;
    }
  }

  changeTextSearchContacto() {
    this.listSearchContacto = this.paginacionContacto.textSearch.split(' ');
    if (this.paginacionContacto.textSearch.trim() !== this.paginacionContacto.textSearchAnterior) {
      this.cargarContactos();
    }
  }

  removerTextSearchContacto(text: string) {
    this.listSearchContacto = this.listSearchContacto.filter( item => item !== text );
    this.paginacionContacto.textSearch = this.listSearchContacto.join(' ');
    this.cargarContactos();
  }

  changeTiposContacto() {
    this.filterOrigenContacto = [...this.filterOrigenContacto];
    this.cargarContactos();
  }

  changeEstatus() {
    this.filterEstatus = [...this.filterEstatus];
    this.cargarContactos();
  }

  changeEtapas() {
    this.filterEtapas = [...this.filterEtapas];
    this.cargarContactos();
  }

  changePaisesContacto() {
    this.filterPaisesContacto = [...this.filterPaisesContacto];
    this.cargarContactos();
  }

  searchClick() {
    this.paginacionContacto.isActive = true;
    document.getElementById("searchContactos").focus();
  }

  printCSV() {

  }

  cargarContactos (reset = true) {
    if (reset) {
      this.paginacionContacto.page = 1;
    }
    const opciones: any = {};
    this.paginacionContacto.textSearchAnterior = '' + this.paginacionContacto.textSearch.trim();
    opciones.numPage = this.paginacionContacto.page;
    opciones.numLimit = this.paginacionContacto.pageSize;
    opciones.desOrderBy = this.paginacionContacto.sortType;
    opciones.textSearch = this.listSearchContacto.join('|');
    opciones.desOrigen = this.filterOrigenContacto.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desEstado = this.filterEstatus.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desEtapa = this.filterEtapas.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desPais = this.filterPaises.filter( itm => itm.checked).map( itm => itm.id).join(',');
    this.store.dispatch(new usuariosActions.CargarContactosRegistradas(opciones));
  }

  

  sortOportunidad(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacionOportunidad.sortType || ('+' + descriptionSort) === this.paginacionOportunidad.sortType ) {
      this.paginacionOportunidad.sortReverse = !this.paginacionOportunidad.sortReverse;
      if (this.paginacionOportunidad.sortReverse) {
        this.paginacionOportunidad.sortType = '-' + descriptionSort;
      } else {
        this.paginacionOportunidad.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacionOportunidad.sortType = '+' + descriptionSort;
      this.paginacionOportunidad.sortReverse = false;
    }
  }

  changeTextSearchOportunidad() {
    this.listSearchOportunidad = this.paginacionOportunidad.textSearch.split(' ');
  }

  removerTextSearchOportunidad(text: string) {
    this.listSearchOportunidad = this.listSearchOportunidad.filter( item => item !== text );
    this.paginacionOportunidad.textSearch = this.listSearchOportunidad.join(' ');
  }

}
