import * as fromUsuario from '../actions';
import { Asignaciones } from 'src/app/models/asignaciones';

export interface AsignacionesState {
  asignaciones: Asignaciones[];
  asignacion: Asignaciones;
  existe: Asignaciones;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: AsignacionesState = {
  asignaciones: [],
  asignacion: new Asignaciones(null),
  existe: new Asignaciones(null),
  loaded: false,
  loading: false,
  error: null
};

export function asignacionesReducer( state = estadoInicial, action: fromUsuario.asignacionAcciones ): AsignacionesState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_ASIGNACIONES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_ASIGNACIONES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        asignaciones: [...action.asignaciones]
      };
    case fromUsuario.CARGAR_ASIGNACIONES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CARGAR_ASIGNACION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_ASIGNACION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        asignacion: {...action.asignacion}
      };
    case fromUsuario.CARGAR_ASIGNACION_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.REGISTRAR_ASIGNACION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.REGISTRAR_ASIGNACION_SUCCESS:
      const itm = state.asignaciones.find( item => item.id === action.asignacion.id);
      if (itm && itm.id) {
        state.asignaciones = state.asignaciones.map( item => {
          if (item.id === action.asignacion.id) {
            item = {...action.asignacion};
          }
          return item;
        });
      } else {
        state.asignaciones.push(action.asignacion);
      }
      if (state.asignacion.id === action.asignacion.id) {
        state.asignacion = {...action.asignacion};
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.REGISTRAR_ASIGNACION_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ELIMINAR_ASIGNACION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ELIMINAR_ASIGNACION_SUCCESS:
      state.asignaciones = state.asignaciones.filter( item => item.id !== action.id);
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ELIMINAR_ASIGNACION_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CAMBIAR_ESTATUS_ASIGNACION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CAMBIAR_ESTATUS_ASIGNACION_SUCCESS:
      const itm2 = state.asignaciones.find( item2 => item2.id === action.asignacion.id);
      const estatus = (action.asignacion.estatus ? 0 : 1);
      if (itm2 && itm2.id) {
        state.asignaciones = state.asignaciones.map( item2 => {
          if (item2.id === action.asignacion.id) {
            item2.estatus = estatus;
          }
          return item2;
        });
      } 
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.CAMBIAR_ESTATUS_ASIGNACION_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.EXISTE_ASIGNACION_FIJO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.EXISTE_ASIGNACION_FIJO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        existe: {...action.asignacion}
      };
    case fromUsuario.EXISTE_ASIGNACION_FIJO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_ASIGNACION:
      return {
        ...state,
        asignacion: new Asignaciones(null)
      };
    case fromUsuario.LIMPIAR_ASIGNACION_FIJO:
      return {
        ...state,
        existe: new Asignaciones(null)
      };
    default:
      return state;
  }
}
