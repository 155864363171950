import * as fromUsuario from '../actions';
import { Origen } from 'src/app/models/origen-contacto';

export interface OrigenContactoState {
  origenes: Origen[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: OrigenContactoState = {
  origenes: [],
  loaded: false,
  loading: false,
  error: null
};

export function origenContactoReducer( state = initState, action: fromUsuario.origenContactoAcciones ): OrigenContactoState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_ORIGEN_CONTACTOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_ORIGEN_CONTACTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        origenes: [...action.origenes]
      };
    case fromUsuario.CARGAR_ORIGEN_CONTACTOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.ACTUALIZA_ORIGEN_CONTACTO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_ORIGEN_CONTACTO_SUCCESS:
      const itm = state.origenes.find( item => item.id === action.origen.id);
      if (itm && itm.id) {
        state.origenes = state.origenes.filter(item => item.nombre !== action.origen.nombre).map( item => {
          if (item.id === action.origen.id) {
            item = {...action.origen};
          }
          return item;
        });
      } else {
        state.origenes.push(action.origen);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_ORIGEN_CONTACTO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_ORIGEN_CONTACTO_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_ORIGEN_CONTACTO_ESTATUS_SUCCESS:
      state.origenes = state.origenes.map( item => {
        if (item.id === action.id) {
          item.status = !item.status;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_ORIGEN_CONTACTO_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}
