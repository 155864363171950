import * as fromCatalogos from '../actions/catalogos.actions';
import { Permisos, Suscripciones, UserType } from 'src/app/models/user';

export interface TiposState {
  tipos: UserType[];
  tiposVer: UserType[];
  tipo: UserType;
  permisos: Permisos;
  suscripciones: Suscripciones[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: TiposState = {
  tipos: [],
  tiposVer: [],
  tipo: new UserType(null),
  permisos: new Permisos(null),
  suscripciones: [],
  loaded: false,
  loading: false,
  error: null
};


export function catalogosReducer( state = initState, action: fromCatalogos.catalogosAcciones ): TiposState {
	switch ( action.type ) {
    case fromCatalogos.LOAD_TYPE_USERS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromCatalogos.LOAD_TYPE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tipos: [...action.tipos]
      };
    case fromCatalogos.LOAD_TYPE_USERS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromCatalogos.LOAD_PERMISOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromCatalogos.LOAD_PERMISOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        permisos: {...action.permisos}
      };
    case fromCatalogos.LOAD_PERMISOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromCatalogos.SAVE_PERMISOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromCatalogos.SAVE_PERMISOS_SUCCESS:
      const item = state.tipos.find( element => element.id === action.tipo.id);
      if (item && item.id && item.id > 0) {
        state.tipos = state.tipos.map(
          element => {
            if (element.id === action.tipo.id) {
              return action.tipo;
            }
            return element;
          }
        );
      } else {
        state.tipos.push({...action.tipo});
      }
      state.suscripciones = state.suscripciones.map( element => {
        element.checked = false;
        return element;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        permisos: new Permisos(null),
        tipo: new UserType(null)
      };
    case fromCatalogos.SAVE_PERMISOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromCatalogos.LOAD_SUSCRIPCIONES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromCatalogos.LOAD_SUSCRIPCIONES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        suscripciones: [...action.suscripciones],
        tipo: {...action.tipo}
      };
    case fromCatalogos.LOAD_SUSCRIPCIONES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromCatalogos.UPDATE_TYPE_USERS_STATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromCatalogos.UPDATE_TYPE_USERS_STATUS_SUCCESS:
      state.tipos = state.tipos.map( item => {
        if (item.id === action.tipo.id) {
          item.checked = !item.checked;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromCatalogos.UPDATE_TYPE_USERS_STATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };


    case fromCatalogos.LOAD_TYPE_USERS_CONFIG:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromCatalogos.LOAD_TYPE_USERS_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tipos: [...action.tipos],
        tiposVer: [...action.tiposVer],
      };
    case fromCatalogos.LOAD_TYPE_USERS_CONFIG_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };

    case fromCatalogos.LOAD_TYPE_USER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromCatalogos.LOAD_TYPE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tipo: {...action.tipo},
      };
    case fromCatalogos.LOAD_TYPE_USER_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    default:
      return state;
  }
}
