<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" >
  <div class="col-md-12">
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="btn-actions-pane-left">
          <div class="row mt-3">
            <div class="form-group pr-4">
              <input type="text" placeholder="Fecha" class="form-control d-none d-lg-block" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)">
              <input type="text" placeholder="Fecha" class="form-control d-lg-none" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)">
            </div>
            <div class="search-wrapper pr-3" [class.active]="paginacion.isActive" *ngIf="resultados.length > 0">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchAsignacion" id="searchAsignacion" #searchAsignacion="ngModel" [(ngModel)]="paginacion.textSearch" (blur)="textSearchChips()">
                <button class="search-icon" (click)="paginacion.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = ''; chips = [];"></button>
            </div>
            <div class="from-group" *ngIf="resultados.length > 0">
              <div class="btn-group-lg btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="filter_tipo">
                <label ngbButtonLabel class="btn-outline-primary">
                  <input ngbButton type="radio" [value]="1"> Bajas
                </label>
                <label ngbButtonLabel class="btn-outline-primary">
                  <input ngbButton type="radio" [value]="2"> Vacantes
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-actions-pane-right" *ngIf="resultados.length > 0">
          <span>
            ({{(resultados | anyScope: paginacion.textSearch).length}} de {{(resultados).length}})
          </span>
        </div>
      </div>
      <div class="card-subtitle mt-2 ml-3" *ngIf="chips.length > 0">
        <mat-chip-list>
          <mat-chip  *ngFor="let chip of chips" [selectable]="selectable" [removable]="removable"> {{chip}}
            <mat-icon matChipRemove *ngIf="removable" (click)="remove(chip) ">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="divider mt-0 m-0" *ngIf="chips.length > 0"></div>
      <div class="card-body p-0">
        <div class="card-body text-center" *ngIf="resultados.length > 0">
          <h5 *ngIf="filter_tipo === 1" class="card-title">Bajas {{anio}}</h5>
          <h5 *ngIf="filter_tipo === 2" class="card-title">Vacantes {{anio}}</h5>
          <div class="table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th class="text-center"> Division</th>
                  <th class="text-center"> Servicios</th>
									<th class="text-center"> Guardias</th>
									<th class="text-center" *ngIf="filter_tipo === 1"> Bajas</th>
									<th class="text-center" *ngIf="filter_tipo === 2"> Vacantes</th>
									<th class="text-center" *ngIf="filter_tipo === 1"> %Bajas</th>
									<th class="text-center" *ngIf="filter_tipo === 2"> %Vacantes</th>
                </tr>
              </thead>
              <tbody *ngFor="let resultado of resultados | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize">
                <tr>
                  <td >{{resultado.nombre}}</td>
									<td >{{resultado.servicios}}</td>
									<td >{{resultado.elementos}}</td>
									<td *ngIf="filter_tipo === 1">{{resultado.bajas}}</td>
									<td *ngIf="filter_tipo === 2">{{resultado.vacantes}}</td>
									<td *ngIf="filter_tipo === 1">{{resultado.porcentajeBaja}}%</td>
									<td *ngIf="filter_tipo === 2">{{resultado.porcentajeVacante}}%</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
									<td ><b>{{resultadoTotal.nombre}}</b></td>
									<td ><b>{{resultadoTotal.servicios}}</b></td>
									<td ><b>{{resultadoTotal.elementos}}</b></td>
									<td *ngIf="filter_tipo === 1"><b>{{resultadoTotal.bajas}}</b></td>
									<td *ngIf="filter_tipo === 2"><b>{{resultadoTotal.vacantes}}</b></td>
									<td *ngIf="filter_tipo === 1"><b>{{resultadoTotal.porcentajeBaja | number : '1.0-2': 'en-MX'}}%</b></td>
									<td *ngIf="filter_tipo === 2"><b>{{resultadoTotal.porcentajeVacante | number : '1.0-2': 'en-MX'}}%</b></td>
                </tr>
              </tfoot>
            </table>
					</div>
					<!-- <div class="pull-right mr-3" *ngIf="filter_tipo === 1">
						Promedio: {{promedioBaja | number : '1.0-2': 'en-MX' }}%
					</div>
					<div class="pull-right mr-3" *ngIf="filter_tipo === 2">
						Promedio: {{promedioVacante | number : '1.0-2': 'en-MX' }}%
					</div> -->
        </div>
      </div>
      <div class="mt-2" *ngIf="resultados.length > 0">
        <div class="row p-2">
          <div class="col-sm-12">
            <div class="pull-right">
              <button type="button" (click) = "crearExcel()" class="btn btn-primary" [disabled]="loading" *ngIf="identity.permisos.rptCoberturaDia">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
