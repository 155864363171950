import * as fromUsuario from '../actions';
import { Captura } from 'src/app/models/captura-resultado-indicador';

export interface CapturaState {
  captura: Captura[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: CapturaState = {
  captura: [],
  loaded: false,
  loading: false,
  error: null
};

export function capturaReducer( state = estadoInicial, action: fromUsuario.capturasAcciones ): CapturaState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_RESULTADOS_INDICADORES:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_RESULTADOS_INDICADORES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        captura: [...action.capturas]
        };
    case fromUsuario.CARGAR_RESULTADOS_INDICADORES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.ACTUALIZA_RESULTADO_INDICADOR:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_RESULTADO_INDICADOR_SUCCESS:
      state.captura = state.captura.map( item => {
        if (item.id === action.resultado.id && item.indicador === action.resultado.indicador) {
          item.color = action.resultado.color;
          item.actualizado = action.resultado.fecha;
          item.resultado = action.resultado.resultado;
          item.edito = true;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_RESULTADO_INDICADOR_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_RESULTADOS:
      return {
        ...state,
        captura: []
      };

    default:
      return state;
  }
}

