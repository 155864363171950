import * as fromUsuario from '../actions';
import { ReporteUsuario, ReporteArea, ReporteIndicador, Nombre } from 'src/app/models/tablero-kpi';

export interface TableroKpiState {
  Reporteusuarios: ReporteUsuario[];
  Reporteareas: ReporteArea[];
  Reporteindicadores: ReporteIndicador[];
  area: Nombre[];
  indicador: Nombre[];
  tipo: Nombre[];
  usuario: Nombre[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: TableroKpiState = {
  Reporteusuarios: [],
  Reporteareas: [],
  Reporteindicadores: [],
  area: [],
  indicador: [],
  tipo: [],
  usuario: [],
  loaded: false,
  loading: false,
  error: null
};

export function  tableroKpiReducer( state = estadoInicial, action: fromUsuario.tableroKpiAcciones ): TableroKpiState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_REPORTE_KPI_INDICADORES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_KPI_INDICADORES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        Reporteindicadores: [...action.ReporteIndicador],
        indicador: [...action.Indicador],
        tipo: [...action.tipo],
      };
    case fromUsuario.CARGAR_REPORTE_KPI_INDICADORES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CARGAR_REPORTE_KPI_AREAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_KPI_AREAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        Reporteareas: [...action.ReporteArea],
        area: [...action.area],
        tipo: [...action.tipo],
        indicador: [...action.Indicador],
      };
    case fromUsuario.CARGAR_REPORTE_KPI_AREAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CARGAR_REPORTE_KPI_USUARIOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_KPI_USUARIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        Reporteusuarios: [...action.ReporteUsuario],
        area: [...action.area],
        indicador: [...action.Indicador],
        tipo: [...action.tipo],
        usuario: [...action.usuario]
      };
    case fromUsuario.CARGAR_REPORTE_KPI_USUARIOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_REPORTE_KPI_USUARIOS:
      return {
        ...state,
        Reporteusuarios: [],
        area: [],
        indicador: [],
        tipo: [],
        usuario: []
      };
    case fromUsuario.LIMPIAR_REPORTE_KPI_AREAS:
      return {
        ...state,
        Reporteareas: [],
        area: [],
        tipo: [],
        indicador: []
      };
    case fromUsuario.LIMPIAR_REPORTE_KPI_INDICADORES:
      return {
        ...state,
        Reporteindicadores: [],
        indicador: [],
        tipo: []
      };
    default:
      return state;
  }
}

