import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MESSAGES } from 'src/app/models/messages';
import { Ticket } from 'src/app/models/ticket';
import { TicketsService } from 'src/app/services/tickets.services';
import { AppState } from 'src/app/store/app.reducer';
import Swal from 'sweetalert2';
import * as usuariosActions from 'src/app/store/actions';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-item-ticket',
  templateUrl: './item-ticket.component.html',
  styleUrls: ['./item-ticket.component.sass']
})
export class ItemTicketComponent implements OnInit, OnDestroy {
  @Input() tickets: Ticket[] = [];
  public identity: Identity = new Identity(null);
  private subscriptionAuth: Subscription = new Subscription();
  constructor(
    private ticketesService: TicketsService,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.subscriptionAuth = this.store.select('auth').subscribe(
      state => {
        this.identity = {...state.identity};
      }
    );
  }

  ngOnDestroy() {
    this.subscriptionAuth.unsubscribe();
  }

  editarEstado(ticket: Ticket){
    const text = ticket.status === 1 ? '¿Deseas eliminar el ticket?' : '¿Deseas cerrar el ticket?';
    Swal.fire({
      title: text,
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.ticketesService.actualizaEstatusTicket(ticket).subscribe(
            (response) => {
              if (response.respond) {
                this.store.dispatch(new usuariosActions.ActualizaTicketEstatusSuccess(ticket.id, ticket.status));
                resolve(true);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaTicketEstatusFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Actualización correctamente.',
            icon: 'success'
          });
        } else {
          Swal.close();
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

}
