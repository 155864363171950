import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { DianolaboralService } from 'src/app/services/dianolaboral.service';

@Injectable()
export class DiaNoLaboralEffects {

  constructor(
    private actions$: Actions,
    public dianolaboralService: DianolaboralService
  ) {}

  @Effect()
  cargarDiaNoLaboral$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_DIAS_NO_LABORABLES ),
    switchMap( (action: any) => {
      return this.dianolaboralService.getDiasnolaboral(action.fecha)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarDiasNoLaborablesSuccess(respuesta.dianolaboral);
            }),
            catchError( error => of(new usuariosActions.CargarDiasNoLaborablesFail(error))  )
        );
    })
  );

  @Effect()
  actualizaDiaNoLaboral$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_DIAS_NO_LABORABLES ),
    switchMap( (action: any) => {
      return this.dianolaboralService.registerDianolaboral(action.dianolaboral)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaDiaNoLaboralSuccess(respuesta.dianolaboral);
            }),
            catchError( error => of(new usuariosActions.ActualizaDiaNoLaboralFail(error))  )
        );
    })
  );


  @Effect()
  eliminaDiaNoLaboral$ = this.actions$.pipe(
    ofType( usuariosActions.ELIMINA_DIAS_NO_LABORABLES ),
    switchMap( (action: any) => {
      return this.dianolaboralService.deleteDianolaboral(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.EliminaDiaNoLaboralSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.EliminaDiaNoLaboralFail(error))  )
        );
    })
  );



}
