export const environment = {
  production: true,
  url: 'https://api.smie.systemsjivabe.com',
  path: 'https://smie.systemsjivabe.com',
  captcha: '6LevNnkiAAAAAMb-MxsPM9mpzzlxJQ1cYsRzm4uG',
  encryptKey: 'egIgFxsbHIPg5cjZrEkRJtwxqiCjUgkT',
  encryptIV: 'YG8EsFzpWJdk7OjIUKGycABogkdPy2On',
  apiKeyMaps: 'AIzaSyDDVYUfjeFZRI2VauO3YiATG-UzeF49_Fc',
  emailsDns: [
    '@systemsjivabe.com'
  ],
  title: 'SMIE - '
};
