export let MESSAGES = {
    C_BTN_SUCCESS: '#4CAF50',
    C_BTN_DANGER: '#DD6B55',
    C_BTN_INFO: '#BBDEFB',
    C_BTN_WARNING: '#FFD180',
    C_BTN_CANCEL: '#aaaaaa',
    // M = Mensajes
    // M -> TIT = Titulo
    M_TIT_ERROR: '¡Error!',
    M_TIT_ADVERTENCIA: '¡Advertencia!',
    M_TIT_EXITO: 'Éxito',
    M_TIT_SUCCESS: 'Hecho',
    M_TIT_INFO: 'Aviso',
    M_TIT_CONFIRM: 'Confirmado',
    M_TIT_CANCELADO: '¡Cancelado!',
    M_TIT_TIMEOUT_SESSION: 'Su tiempo de espera a expirado, desea continuar?',
    // M -> BTN = Boton
    M_BTN_CANCEL_A: 'No, cancela',
    M_BTN_CANCEL: 'Cancelar',
    M_BTN_DELETE_A: 'Sí, eliminala',
    M_BTN_DELETE: 'Sí, eliminalo',
    M_BTN_CONFIRM: 'Ok',
    M_BTN_CONFIRMA: 'Sí, acepto',
    M_BTN_CONTINUAR: 'Sí, continuar',
    M_BTN_NO_SALIR: 'No, salir',
    M_BTN_ACTUALIZA: 'Sí, actualiza',
    M_BTN_ACEPTAR: 'Aceptar',
    M_BTN_CANCELAR: 'Cancelar',
    // M -> INF = Texto informativo
    M_INF_DELETE_CLIENTE: 'Se eliminará todo información asignada al cliente, excepto su historial de movimientos.',
    M_INF_DELETE_AFILIAL: 'Los cambios realizados se verán reflejados hasta el momento de guardar el cliente.',
    M_INF_DELETE_EVALUACION: 'Se eliminará todo información asignada de la evaluación, excepto su historial de movimientos.',
    M_INF_DELETE_PREGUNTA: 'Los cambios realizados se verán reflejados hasta el momento de guardar la evaluación.',
    M_INF_DELETE_APARTADO: 'Los cambios realizados se verán reflejados hasta el momento de guardar la evaluación.',
    M_INF_DELETE_USUARIO: 'Se eliminará todo información asignada al usuario, excepto su historial de movimientos.',
    M_INF_UPDATE_CLIENTE: 'Se actualizará solamente la información del cliente, no tendrá cambios en su historial de movimientos.',
    M_INF_UPDATE_USUARIO: 'Se actualizará solamente la información del usuario, no tendrá cambios en su historial de movimientos.',
    M_INF_UPDATE_EVALUACION_HABILITAR: 'Se pondrá disponible la captura de la evaluación para los usuarios.',
    M_INF_UPDATE_EVALUACION_DESHABILITAR: 'Se deshabilitará de forma temporal la captura de la evaluación para los usuarios.',
    // tslint:disable-next-line:max-line-length
    M_INF_UPDATE_APARTADO: 'Se actualizará solamente la información del apartado de forma temporal, hasta terminar el registro de la evaluación.',
    // tslint:disable-next-line:max-line-length
    M_INF_UPDATE_PREGUNTA: 'Se actualizará solamente la información de la pregunta en el apartado actual de forma temporal, hasta terminar el registro de la evaluación.',
    // tslint:disable-next-line:max-line-length
    M_INF_UPDATE_ESTATUS: 'El realizar el cambio de estatus no afectará el historial de movimientos, solamente habilita/deshabilita la opción para futuros movimientos.',
    // tslint:disable-next-line:max-line-length
    M_INF_UPDATE_ALERTA_ESTATUS: 'El deshabilitar la alerta establecerá por default "Sin asunto" todas las opciones de respuestas en las evaluaciones que tenga relación y sin opción de revertir la información.',
    // tslint:disable-next-line:max-line-length
    M_INF_UPDATE_CATALOGO_CORPORACION: 'Se actualizará el nombre de la corporación y el cambio será reflejado en todos los registros que tenga relación.',
    // tslint:disable-next-line:max-line-length
    M_INF_UPDATE_CATALOGO_APARTADO: 'Se actualizará el nombre del apartado y el cambio será reflejado en todos los registros que tenga relación.',
    M_INF_CANCEL_FOLIO: 'Al cancelar el folio no se podrá revertir el estatus, y tendrá que iniciar una nueva evaluación.',
    M_INF_REPLACE_CATALOGO: 'Ya existe esta descripción, al aceptar la acción combinará todo registro con el que tenga relación.',
    M_INF_ERROR_CLIENTE: 'Oops, su cliente no fue eliminado!',
    M_INF_ERROR_EVALUACION: 'Oops, su evaluación no fue eliminada!',
    M_INF_ERROR_UPDATE_EVALUACION: 'Oops, su evaluación no fue actualizada!',
    M_INF_ERROR_PREGUNTA: 'Oops, su pregunta no fue eliminada!',
    M_INF_ERROR_APARTADO_DELETE: 'Oops, su apartado no fue eliminado!',
    M_INF_ERROR_USUARIO_SAVE: 'Oops, su usuario no fue registrado!',
    M_INF_ERROR_USUARIO_UPDATE: 'Oops, su usuario no fue actualizado!',
    M_INF_ERROR_APARTADO: 'Oops, su apartado no fue registrado!',
    M_INF_ERROR_USUARIO_DELETE: 'Oops, su usuario no fue eliminado!',
    M_INF_INFO_REPORTE: 'Generar el archivo pdf demorará unos segundos y durante ese tiempo no podrá realizar otras actividades.',
    M_INF_WARNING_AUTH: 'Autentificación incorrecta',
    M_INF_WARNING_ACCESS: 'Ya cuenta con una sesión iniciada',
    M_INF_WARNING_FECHA: 'Capture una fecha valida',
    M_INF_SUCCESS_EVALUACION: 'La información capturada sera registrada.',
    M_INF_ERROR_LOGIN: 'Error de acceso',
    M_INF_TIMEOUT_SESSION: 'Por inactividad y seguridad, el sistema se cerrará.',
    M_INF_ERROR_TIMEOUT: 'Cierre de sesión',
    M_INF_REPLACE_CLIENTE: 'Se actualizará la información del cliente con la captura previa de forma temporal hasta guardar los cambios.',
    M_INF_DELETE_EMPRESA: 'Se eliminará todo información asignada a la empresa, excepto su historial de movimientos.',
    // tslint:disable-next-line:max-line-length
    M_INF_DELETE_ARCHIVO_CAPACITACION: 'Se eliminará de manera permanente de la evaluación al confimar esta acción sin opción de recuperación.',
    M_INF_SALIR: 'Se cancelarán todos los cambios.',
    M_INF_EDITAR_TIENDA: 'Se cargará información de la tienda con los datos previos de forma temporal hasta guardar los cambios.',
    // tslint:disable-next-line:max-line-length
    M_INF_EDITAR_DATO_FISCAL: 'Se cargará información del determinante de cargo con los datos previos de forma temporal hasta guardar los cambios.'
};
