<div [class]="'app-header header-shadow text-darker'">
  <div class="logo-src mr-5 p-5"></div>
  <div class="app-header__content">
    <div class="app-header-left">
      <app-search-box></app-search-box>
    </div>
    <div class="app-header-right">
      <app-dots></app-dots>
      <app-user-box></app-user-box>
    </div>
  </div>
  <div class="app-header__mobile-menu">
    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
    <div class="mh-100 d-flex justify-content">
      <img src="assets/images/logo-long.png" style="width: 185px;" class="mr-auto ml-4">
    </div>
  </div>
</div>
