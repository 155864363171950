import { Component, OnInit, OnDestroy } from '@angular/core';
import { Capturas, Asignaciones, AsignacionesSortType, Resultados } from 'src/app/models/captura-asignacion';
import { Pagination } from 'src/app/models/pagination';
import { Fechas } from 'src/app/models/fechas';
import { DatePipe } from '@angular/common';
import { Identity } from 'src/app/models/user';
import { CapturasService } from 'src/app/services/captura-asignacion.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import {ExcelService} from 'src/app/services/excel.service';
import * as usuariosActions from 'src/app/store/actions';
import * as moment from 'moment';
import { FilterType } from 'src/app/models/user';

@Component({
  selector: 'app-captura-asignacion',
  templateUrl: './captura-asignacion.component.html',
  styleUrls: ['./captura-asignacion.component.sass']
})
export class CapturaAsignacionComponent implements OnInit, OnDestroy {
  heading = 'Captura de indicadores operativos';
  subheading = 'Cambia de opción a TNC, Vacantes y Bajas y selecciona la fecha que deséas capturar';
  icon = ' pe-7s-calculator icon-gradient bg-happy-fisher';
  public datePipe = new DatePipe("en-US");
  public dates: Date[] = [];
  public anio: string;
  public resultados: Asignaciones[] = [];
  public paginacion = new Pagination(null);
  public captura: Capturas; 
  public fechas = [];
  public filterActive = false;
  public orderType = [...AsignacionesSortType];
  public filterDivisiones: FilterType[];
  public listSearch = [];
  public diaActual;
  public loading: boolean;
  public dias: Fechas;
  public minDate: Date;
  public maxDate: Date;
  public filter_tipo: number;
  public nombre: string;
  public elementos: number;
  public id: number;
  public identity: Identity;
  public downloading = false;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionCumplimiento: Subscription = new Subscription();
  public subscribeConsulta: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private excelService: ExcelService,
    private capturasService: CapturasService
  ) { 
  this.captura = new Capturas (null); 
  const fecha = new Date();
  fecha.setDate(fecha.getDate() - 15);
  this.paginacion.sortType = '-id'
	this.dates = [ fecha,new Date() ];
	this.dias = new Fechas(null);
  this.minDate = new Date();
  this.maxDate = new Date();
  this.filter_tipo = 1;
	this.minDate.setDate(this.minDate.getDate() - 90);
  this.maxDate.setDate(this.maxDate.getDate());
  this.diaActual = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  this.loading = true;
  this.elementos = 0;
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionCumplimiento = this.store.select('capturaAsignacion').subscribe(
      (state) => {
        this.paginacion.totalSize = state.total;
        this.paginacion.totalFiltro = state.totalFiltro;
        this.fechas = [];
        this.resultados = [...state.captura];
        this.resultados.find(item => {
          if(item.resultados && item.resultados.length > this.fechas.length)
          {
            this.fechas = item.resultados.map( itm => {
              const obj: any = {};
              obj.dia= itm.captura;
              return obj;
            });
          }
        });
      }
    );
    this.subscribeConsulta = this.store.select('consultaCatalogos').subscribe(
      (state) => {
        this.filterDivisiones = [...state.divisiones.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
      }
    );
    this.store.dispatch(new usuariosActions.ConsultaCatalogoDivisiones());
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionCumplimiento.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  fecha(fecha:Date[]){
    if(fecha && fecha.length === 2){
      const fecha1= this.datePipe.transform(fecha["0"], 'yyyy-MM-dd');
      const fecha2= this.datePipe.transform(fecha["1"], 'yyyy-MM-dd');
      const anio1 = this.datePipe.transform(fecha["0"], 'yyyy');
      const anio2 = this.datePipe.transform(fecha["1"], 'yyyy');
      if(anio1 === anio2){
        this.anio = anio1;
      }else{
        this.anio = (anio1 + '-' + anio2);
      }
      this.dias = {fechaInicio: fecha1, fechaFinal: fecha2};
      this.cargarCapturas();
    }
  }


  cargarCapturas(reset = true) {
    if (reset) {
      this.paginacion.page = 1;
    }
    const opciones: any = {};
    this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
    opciones.numPage = this.paginacion.page;
    opciones.numLimit = this.paginacion.pageSize;
    opciones.desOrderBy = this.paginacion.sortType;
    opciones.textSearch = this.listSearch.join('%');
    opciones.desDivision = this.filterDivisiones.filter( itm => itm.checked).map( itm => itm.id).join(',');
    this.store.dispatch(new usuariosActions.CargarCapturasAsignacion(this.dias, opciones));
  }
    
      
  actulizar(orden: number,servicio: string ,id: number , resultado: Resultados, content, fehca){
    this.loading = false;
    var arr = fehca.split('-');
    if(arr.length === 3){
      const mes = Number(arr[1]) - 1; 
      this.id = id;
      this.nombre = (orden + '/' + servicio);
      this.captura.fecha = (new Date(arr[0],mes,arr[2]));
      this.captura.tipo = this.filter_tipo;
      this.captura.disponibilidad = resultado.disponibilidad;

      let fechaActual = new Date();
      fechaActual.setHours(0,0,0,0);
      const fechaActualiS = fechaActual.toUTCString();
      const fechaCaptura = new Date(this.captura.fecha).toUTCString();
      
      if(this.captura.tipo == 1){
        this.captura.elementos = resultado.turnos;
        this.elementos = resultado.turnos;
        this.captura.resultado = resultado.falta;
        this.modalService.open(content, {
          size: 'lg'
        });
      }
      if(this.captura.tipo == 2 && fechaCaptura === fechaActualiS){
        this.captura.elementos = resultado.elementos;
        this.elementos = resultado.elementos - resultado.disponibilidad;
        this.captura.resultado = resultado.bajas;
        this.modalService.open(content, {
          size: 'lg'
        });
      }
      if(this.captura.tipo == 3 && fechaCaptura === fechaActualiS){
        this.captura.elementos = resultado.elementos;
        this.elementos = resultado.disponibilidad;
        this.captura.resultado = resultado.vacantes;
        this.modalService.open(content, {
          size: 'lg'
        });
      }
    }
      
  }

  guardarResultado(){
    this.store.dispatch(new usuariosActions.ActualizaCapturaAsignacion(this.id,this.captura));
    this.modalService.dismissAll('Close');
  }

  
  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
    this.cargarCapturas();
  }

    
  crearExcel() {
    this.downloading = true;
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');

    const opciones: any = {};
    opciones.numPage = 0;
    opciones.numLimit = null;
    opciones.desOrderBy = '';
    opciones.textSearch = '';
    opciones.desDivision = '';
    this.capturasService.downloadExcelCapturar(this.dias, opciones).subscribe( respuesta => {

      this.excelService.exportAsExcelFileAsignacion(respuesta.tnc, respuesta.vacantes, respuesta.baja , 'Indicadores operativos' + i);
      this.downloading = false;
    }, error => {
      console.log(error);
      this.downloading = false;
    });
    
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.trim().split(' ').filter( itm => itm);
    if (this.paginacion.textSearch.trim() !== this.paginacion.textSearchAnterior) {
      this.cargarCapturas();
    }
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
    this.cargarCapturas();
  }

  changeDivision() {
    this.filterDivisiones = [...this.filterDivisiones];
    this.cargarCapturas();
  }

  validar(){
    if((this.captura.resultado <= this.captura.elementos && this.captura.tipo !== 3 ) || (this.captura.resultado <= this.captura.disponibilidad && this.captura.tipo === 3)){
      this.loading = false;
    }else{
      this.loading = true;
    }
  }

  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchCaptura").focus();
  }


  closeSearch() {
    this.paginacion.isActive = false;
    this.paginacion.textSearch = '';
    this.listSearch = [];
    this.cargarCapturas();
  }



}
