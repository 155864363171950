import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { Dianolaboral } from '../models/dianolaboral';
import { Fechas } from 'src/app/models/fechas';

@Injectable({
  providedIn: 'root'
})
export class DianolaboralService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  registerDianolaboral(dianolaboralToRegister: Dianolaboral): Observable<any> {
	const params = JSON.stringify({dianolaboral: dianolaboralToRegister});
	 const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/dianolaboral/', params, {headers});
  }

  getDiasnolaboral(dias: Fechas): Observable<any> {
	const params = JSON.stringify({dias});
	const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this.http.post(this.url + '/dianolaboral/dias_no_laboral', params, {headers});
	 }

	 getDianolaboral(id: number): Observable<any> {
		const headers = new HttpHeaders().set('Content-type', 'application/json');
		
		return this.http.get(this.url + '/dianolaboral/' + id, {headers});
	 }

	 deleteDianolaboral(id: number): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
	
		return this.http.delete(this.url + '/dianolaboral/' + id, {headers});
	
	}

//  updateDepartamento(departamentoToUpdate: Departamento): Observable<any> {
// 	const params = JSON.stringify({departamento:departamentoToUpdate});
// 	const headers = new HttpHeaders().set('Content-Type', 'application/json');
	
// 	return this.http.put(this.url + '/departamentos/' + departamentoToUpdate.id, params, {headers});
//  }



}
