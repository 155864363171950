<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" >
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card mb-3">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="p-3">
          <div class="form-group">
            <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize" (change)="cargarCumplimiento()">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <div class="v-options-menu vsm-default">
            <div class="vsm-list">
              <div class="vsm-header">Opciones</div>
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                <ngb-panel class="p-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <span class="vsm-title">Áreas</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let item of filterDepartamentos">
                          <a class="vsm-link">
                            <span class="vsm-title">
                              <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="custom-checkbox custom-control">
                                      <input type="checkbox" id="selectCheckOpcionesDepartamentos{{item.id}}" class="custom-control-input" name="selectCheckOpcionesDepartamentos{{item.id}}" #selectCheckOpcionesDepartamentos{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeDepartamentos()">
                                      <label class="custom-control-label" for="selectCheckOpcionesDepartamentos{{item.id}}">&nbsp;</label>
                                    </div>
                                  </div>
                                  <div class="widget-content-left flex2">
                                    <div class="widget-heading">{{item.nombre}}</div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
								<ngb-panel class="p-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <span class="vsm-title">Tipo</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let item of filterTipos">
                          <a class="vsm-link">
                            <span class="vsm-title">
                              <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="custom-checkbox custom-control">
                                      <input type="checkbox" id="selectCheckOpcionesTipos{{item.id}}" class="custom-control-input" name="selectCheckOpcionesTipos{{item.id}}" #selectCheckOpcionesTipos{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeTipos()">
                                      <label class="custom-control-label" for="selectCheckOpcionesTipos{{item.id}}">&nbsp;</label>
                                    </div>
                                  </div>
                                  <div class="widget-content-left flex2">
                                    <div class="widget-heading">{{item.nombre}}</div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9" [ngClass]="{'col-lg-12': !filterActive }" >
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="btn-actions-pane-left">
          <div class="row mt-3">
            <div class="form-group pr-4">
              <input type="text" placeholder="Fecha" class="form-control" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)"  [maxDate]="maxDate">
            </div>
          </div>
        </div>
        <div class="btn-actions-pane-right mr-4">
          ({{paginacion.totalFiltro}} de {{paginacion.totalSize}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchCumplimiento" id="searchCumplimiento" #searchCumplimiento="ngModel" [(ngModel)]="paginacion.textSearch" (ngModelChange)="changeTextSearch()">
                <button class="search-icon" (click)="searchClick()" for="searchCumplimiento"><span></span></button>
              </div>
              <button class="close" (click)="closeSearch()"></button>
            </div>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <button type="button" class="m-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearch" (click)="removerTextSearch(texto)">
          {{texto}}
          <i class="ml-1 fas fa-times"></i>
        </button>
				<div class="divider" *ngIf="listSearch.length"></div>
				<apx-chart
					[labels]="labels"
					[dataLabels]="{
								enabled: false
								}"
					[plotOptions]= "{
						heatmap: {
							shadeIntensity: 0.5,
							colorScale: {
								ranges: [{
									from: -30,
									to: 5,
									name: 'Cumplió',
									color: '#00A100'
								},
								{
									from: 6,
									to: 20,
									name: 'Vacaciones/Permiso',
									color: '#128FD9'
								},
								{
									from: 21,
									to: 45,
									name: 'Día no laboral',
									color: '#808080'
								},
								{
									from: 46,
									to: 55,
									name: 'No cumplió',
									color: '#FF0000'
								}
								]
							}
						}
						}"
					[xaxis]=" {
								type: 'category',
								title: {
									text: 'Fechas' },
								labels: {
									formatter: formatoFecha
								}
							}"
					[yaxis]="{ title: {
						text: 'Usuarios' } }"
					[chart]="{
								height: height,
								type: 'heatmap'
							}"
					[series]="dataSets"
					[tooltip]="tooltip"
					(click)="actulizar($event)">
				</apx-chart>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="card no-shadow bg-transparent text-left">
              <div class="widget-chart-content">
                <ngb-pagination class="mb-2" [boundaryLinks]="true"
                  [collectionSize]="paginacion.totalFiltro" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5" (pageChange)="cargarCumplimiento(false)">
                  <ng-template ngbPaginationFirst>First</ng-template>
                  <ng-template ngbPaginationLast>Last</ng-template>
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row col-12">
    <div class="col-md-3" *ngIf="identity.permisos.vacacionesEdit">
      <form id="registerForm" name="registerForm" #registerForm="ngForm" (ngSubmit)="onSubmit(registerForm)" >
        <div class="main-card mb-3 card">
          <!-- <div class="card-header  text-capitalize font-weight-normal">
            <h5>REGISTRO DE DÍAS NO LABORABLES</h5>
          </div> -->
          <div class="card-header with-border">
            <div class="card-header-title">Registrar día no laboral</div>
          </div>
          <div class="card-body">
            <fieldset class="form-group required">
              <label  class="control-label">Dia</label>
              <input type="text" placeholder="Fecha"  class="form-control" name="dia" #dia="ngModel" bsDatepicker [(ngModel)]="nuevoDiaNoLaboral.dia" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" placement="top" autocomplete="off">
              <span *ngIf="!descripcion.valid && descripcion.touched"> El dia es obligatorio</span>
            </fieldset>
            <fieldset class="form-group required">
              <label  class="control-label">Descripción</label>
              <textarea type="text" name="descripcion" #descripcion="ngModel" [(ngModel)]="nuevoDiaNoLaboral.descripcion" class="form-control" rows="2" placeholder="Descripción" minlength="5" title="Capture la descripcion." required ></textarea>
              <span *ngIf="!descripcion.valid && descripcion.touched"> La descripción es obligatorio</span>
            </fieldset>
          </div>
          <div class="grid-menu grid-menu-2col border-top">
            <div class="no-gutters row">
              <div class="col-sm-6" *ngIf="identity.permisos && (identity.permisos)">
                <a class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" (click)="limpiar(registerForm)">
                  <i class="lnr-undo btn-icon-wrapper"></i> Cancelar cambios
                </a>
              </div>
              <div class="col-sm-6" *ngIf="identity.permisos && identity.permisos.vacacionesEdit">
                <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" *ngIf="!loading" [disabled]="!registerForm.form.valid || !identity.permisos.vacacionesEdit">
                  <i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
                </button>
                <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" disabled *ngIf="loading">
                  <span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
                  <br>
                  Loading...
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-9" [ngClass]="{'col-md-12': !identity.permisos.vacacionesVer }">
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4" *ngFor="let dianolaboral of diasnolobaral " >
          <div class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
            <div class="widget-chat-wrapper-outer">
              <div class="widget-chart-content">
                <h5 class="widget-subheading">{{dianolaboral.dia | amDateFormat: 'DD/MM/YYYY'}}
                  <button type="button" class="mr-2 btn-icon btn-icon-only btn btn-outline-danger  widget-subheading pull-right" (click)="deleteDianolaboral(dianolaboral.id)" [disabled]=" !identity.permisos.vacacionesEdit">
                    <i class="pe-7s-trash btn-icon-wrapper"> </i>
                    <span class="sr-only">Eliminar</span>
                  </button>
                </h5>
                <div class="widget-chart-flex">
                  <div class="widget-numbers mb-0 w-100">
                    <div class="widget-chart-flex">
                      <div class="fsize-1">{{dianolaboral.descripcion}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



