import {Component, OnInit, OnDestroy} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Identity, User } from 'src/app/models/user';
import * as usuariosActions from 'src/app/store/actions';
import { Ticket } from 'src/app/models/ticket';
import { FiltroCheck } from 'src/app/pipes/option-check-multiple.pipe';
import { GLOBAL } from 'src/app/models/global';
import { SeriesBar, FunnelGraph } from 'src/app/models/graph';
import { Captura } from 'src/app/models/captura-resultado-indicador';
declare var require: any;


@Component({
  selector: 'app-dashboard-usuarios',
  templateUrl: './dashboard-usuarios.component.html',
  styleUrls: ['./dashboard-usuarios.component.scss']
})
export class DashboardUsuariosComponent implements OnInit, OnDestroy {
  heading = 'Dashboard';
  subheading = '';
  icon = 'pe-7s-display1 icon-gradient bg-happy-fisher';
  public loading: boolean;
  public identity: Identity;
  public cantidadActividades: number = 0;
  public hoyActividad: number = 0;
  public datePipe = new DatePipe("en-US");
  public indicadores: Captura[] = [];
  public tickets: Ticket[] = [];
  public existeFilter: FiltroCheck[] = [
    { value: 1, text: 'Creados', parametro: 'existe', checked: true},
    { value: 2, text: 'Recibidos', parametro: 'existe', checked: true}
  ];
  public statusFilter: FiltroCheck[] = [
    { value: 1, text: 'Nuevo', parametro: 'status', checked: true},
    { value: 2, text: 'Actualizado/Respondido', parametro: 'status', checked: true},
    { value: 3, text: 'Cerrado', parametro: 'status', checked: false}
  ];
  public usuariosConectados: User[] = [];
  public url: string;
  public hoyContactos = 0;
  public hoyEmpresas = 0;
  public hoyOportunidades = 0;
  public labelsContactos: string[] = [];
  public seriesContactos: SeriesBar[] = [];
  public labelsEmpresas: string[] = [];
  public seriesEmpresas: SeriesBar[] = [];
  public labelsOportunidades: string[] = [];
  public seriesOportunidades: SeriesBar[] = [];
  public lenguaje = require('apexcharts/dist/locales/es.json');
  public slideConfig6 = {
    className: 'center',
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    dots: true,
  };
  public dataFunnel: FunnelGraph[] = [];
  public chartLabel: Object = {
    visible: true,
    position: 'Inside',
    name: 'text'
  };
  public colorPalettes = [
    '#831f38',
    '#a569bd',
    '#dc7633',
    '#576d96',
    '#ffcf1a',
    '#a79d7a',
    '#44aca3',
    '#a1ce69'
  ];
  public legend: Object = {
    visible: true,
    position: 'Right'
  };
  public tooltipSettings: object = {
    enable : true,
    format: '${point.x} : <b>$${point.y}</b>'
  };

  private subcriptionCaptura: Subscription = new Subscription();
  private subscriptionTickets: Subscription = new Subscription();
  private subscriptionAuth: Subscription = new Subscription();
  private subscriptionDashboard: Subscription = new Subscription();
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) { 
    this.store.dispatch(new usuariosActions.LimpiarResultados());
    this.identity = new Identity(null);
    this.url = GLOBAL.url;
  }

  ngOnInit(): void {
    this.subscriptionAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionCaptura = this.store.select('captura').subscribe(
      (state) => {
        this.indicadores = [...state.captura.filter( item => item.edito == false)];
      }
    );
    this.subscriptionTickets = this.store.select('ticket').subscribe(
      (state) => {
        this.tickets = [...state.tickets];
      }
    );
    this.subscriptionDashboard = this.store.select('dashboard').subscribe(
      (state) => {
        this.usuariosConectados = [...state.usuariosConectados];
        this.hoyContactos = state.hoyContactos;
        this.hoyEmpresas = state.hoyEmpresas;
        this.hoyOportunidades = state.hoyOportunidades;
        this.labelsContactos = [...state.labelsContactos];
        this.seriesContactos = [...state.seriesContactos];
        this.labelsEmpresas = [...state.labelsEmpresas];
        this.seriesEmpresas = [...state.seriesEmpresas];
        this.labelsOportunidades = [...state.labelsOportunidades];
        this.seriesOportunidades = [...state.seriesOportunidades];
        this.cantidadActividades = state.cantidadActividades;
        this.hoyActividad = state.hoyActividades;

        this.dataFunnel = [...state.dataFunnel.map(item => {
          return ({name: item.name, value: item.value, text: item.text});
        })];
        this.colorPalettes = [...state.dataFunnel.map(item => {
          return item.color;
        })];
      }
    );
    this.inicializa();
  }


  inicializa() {
    setTimeout( () => {
      let fecha = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.store.dispatch(new usuariosActions.CargarDashboardCantidadActividades(fecha));
      this.store.dispatch(new usuariosActions.CargarResultadosIndicadores());
      if (this.identity.permisos && this.identity.permisos.oportunidadesListVer) {
        this.store.dispatch(new usuariosActions.CargarDashboardLineOportunidades());
        this.store.dispatch(new usuariosActions.CargarDashboardFunnelOportunidades());
      }
      if (this.identity.permisos && this.identity.permisos.empresasListVer) {
        this.store.dispatch(new usuariosActions.CargarDashboardLineEmpresas());
      }
      if (this.identity.permisos && this.identity.permisos.contactosListVer) {
        this.store.dispatch(new usuariosActions.CargarDashboardLineContactos());
      }
      if (this.identity.permisos && this.identity.permisos.usuariosListVer) {
        this.store.dispatch(new usuariosActions.CargarDashboardUsuariosConectados());
      }
      if (this.identity.permisos && this.identity.permisos.ticketsVer) {
        this.store.dispatch(new usuariosActions.CargarTickets());
      }
    }, 10);
  }



  ngOnDestroy() {
    this.subcriptionCaptura.unsubscribe();
    this.subscriptionTickets.unsubscribe();
    this.subscriptionAuth.unsubscribe();
    this.subscriptionDashboard.unsubscribe();
  }

  openActividad(){
    this.router.navigate(['/auth/actividades/register/0']);
  }

  openCaptura(){
    this.router.navigate(['/auth/captura/indicador/administrativo']);
  }

}
