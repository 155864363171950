<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="form-group col-sm-12 col-md-3 col-lg-3">
  <label for="tipoUser" class="control-label">Tipo de indicador: </label>
  <select class="form-control" id="select"  name="select" #select= "ngModel" [(ngModel)]="tipo" (click)="indetificarIndicadores()" >
    <option [ngValue]="1" selected >Todos</option>
    <option [ngValue]="2">Capturado</option>
    <option [ngValue]="3">No capturado</option>
  </select>
</div>
<div class="row">
  <div class="col-sm-12 col-md-7 col-lg-7">
    <div class="main-card mb-3 card">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngFor="let indicador of indicadores" (dblclick)="indicadorCapturar(indicador)">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left flex2">
                <div class="widget-heading"><b><h5>{{indicador.nombre}}</h5></b></div>
                <div class="widget-subheading opacity-10">
                  <span class="pr-2"> <b>Fecha límite: </b> 
                    {{indicador.dia | amDateFormat: 'DD/MM/YYYY'}}
                  </span>
                </div>
                <div class="widget-subheading opacity-10">
                  <span class="pr-2" *ngIf="indicador.edito"><b>Actualizado:</b> 
                    {{indicador.actualizado | amDateFormat: 'DD/MM/YYYY HH:mm'}}
                  </span>
                </div>
              </div>
              <div class="widget-content-right text-right mr-3" *ngIf="indicador.capturado == 0">
                <div><b>No capturado</b></div>
              </div>
              <div class="widget-content-right">
                <div class="round-progress-text" *ngIf="indicador.unidad === '%'">
                  <span>{{indicador.resultado + indicador.unidad }}</span>
                  <round-progress
                    [current]="indicador.resultado"
                    [max]="indicador.maximo"
                    [color]="indicador.color"
                    [background]="'#e3e3e3'"
                    [radius]="35"
                    [stroke]="7"
                    [semicircle]="false"
                    [rounded]="true"
                    [clockwise]="false"
                    [responsive]="false"
                    [duration]="2000"
                    [animation]="'easeInOutQuart'"
                    [animationDelay]="0"></round-progress>
                </div>
                <div class="widget-numbers">
                  <span class="count-up-wrapper-2" *ngIf="indicador.unidad === '$'">
                    <span [style.color]="indicador.color" [countUp]="indicador.resultado" [options]="peso"></span>
                  </span>
                  <span class="count-up-wrapper-2" *ngIf="indicador.unidad === ' '">
                    <span [style.color]="indicador.color" [countUp]="indicador.resultado" [options]="unidad"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-sm-12 col-md-5 col-lg-5" *ngIf = "mostrarFormulario == true"> 
    <div class="main-card mb-3 card">
      <div class="card-header">
        Indicador - {{nombre}}
      </div>
      <section class="card-body">
        <form #registerForm="ngForm" (ngSubmit)="onSubmit()" class="col-lg-10">
          <div *ngFor = "let variable of variables ; let i = index">
            <div *ngIf= "!variable.constante" >
              <fieldset class="form-group required" [disabled] = "variable.constante" >
                  <label  class="control-label">{{variable.nombre}}</label>
                  <input  type="number" name="valor-{{i}}" #valor="ngModel" [(ngModel)]="variable.valor" 
                    class="form-control"  placeholder="valor de la variable" title="Capture el valor de la variable." required />
                  <span *ngIf="!valor.valid && valor.touched"> El valor es obligatorio</span>
              </fieldset>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="ml-auto">
                <button type="submit" class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-primary btn-lg" [disabled]="!registerForm.form.valid">Registrar cambios</button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</div>



