import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from 'src/app/models/user';
import { Pagination } from 'src/app/models/pagination';
import { MESSAGES } from 'src/app/models/messages';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { GENERALES } from 'src/app/models/generales';
import * as usuariosActions from 'src/app/store/actions';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GLOBAL } from 'src/app/services/global';
import { ToastrService } from 'ngx-toastr';
import { Identity } from 'src/app/models/user';
import { AuthState } from 'src/app/store/reducers';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.sass'],
  providers: []
})
export class ListUsersComponent implements OnInit, OnDestroy {
  public title: string;
  public url: string;
  public status: string;
  public page: number;
  public nextPage: number;
  public prevPage: number;
  public total: number;
  public pages: number;
  public users: User[];
  public table: any;
  public loading: boolean;
  public sortType: string;
  public search: User;
  public id:  		number;
  public sortReverse: any;
  public identity: Identity;
  public filterActive = false;
  public paginacion = new Pagination(null);
  heading = 'Información de usuarios';
  subheading = 'Contenido de usuarios activos en el sistema';
  icon = 'pe-7s-users icon-gradient bg-happy-fisher';
  cargando: boolean;
  subscriptionUsuarios: Subscription = new Subscription();
  public subcriptionIdentity: Subscription = new Subscription();
  public orderType = [  { id: '+id', text: 'ID'},
                        { id: '-id', text: 'ID desc'},
                        { id: '+usuario', text: 'Usuario'},
                        { id: '-usuario', text: 'Usuario desc'},
                        { id: '+nombre', text: 'Nombre'},
                        { id: '-nombre', text: 'Nombre desc'},
                        { id: '+apellidoPaterno', text: 'Apellido Paterno'},
                        { id: '-apellidoPaterno', text: 'Apellido Paterno desc'},
                        { id: '+apellidoMaterno', text: 'Apellido Materno'},
                        { id: '-apellidoMaterno', text: 'Apellido Materno desc'},
                        { id: '+desTipo', text: 'Tipo de usuario'},
                        { id: '-desTipo', text: 'Tipo de usuario desc'},
                        { id: '+desDivision', text: 'Division del usuario'},
                        { id: '-desDivision', text: 'Division del usuario desc'},
                        { id: '+departamento', text: 'Área(s) del usuario'},
                        { id: '-departamento', text: 'Área(s) del usuario desc'},
                        { id: '+fecRegistro', text: 'Fecha de alta'},
                        { id: '-fecRegistro', text: 'Fecha de alta desc'}];
  constructor(
    private userService: UsersService,
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
  ) {
    this.loading = false;
    this.users = [];
    this.sortReverse = false;
    this.search = new User(null);
    this.url = GLOBAL.url;
    this.paginacion.sortType = '+nombre';
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
	 );
    this.subscriptionUsuarios = this.store.select('usuarios').subscribe(
      (state: any) => {
		  this.users = [...state.usuarios];
      }
    );
    this.store.dispatch(new usuariosActions.CargarUsuarios());
  }

  ngOnDestroy() {
    this.subscriptionUsuarios.unsubscribe();
    this.subcriptionIdentity.unsubscribe();
  }

  deleteUser(userDelete: number, index: number) {
    this.loading = true;
    Swal.fire({
      title: 'Deseas eliminar el usuario?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_WARNING,
      confirmButtonText: MESSAGES.M_BTN_ACTUALIZA,
      cancelButtonText: MESSAGES.M_BTN_CANCEL_A,
      focusConfirm: false,
      focusCancel: true,
      animation: false,
       showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm: (result) => {
        return new Promise((resolve, reject) => {
          this.userService.deleteUser(userDelete).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      }
    }).then( (resul) => {
      if (resul.value) {
        Swal.fire({
          title: MESSAGES.M_TIT_SUCCESS,
          timer: 1200,
          showConfirmButton: false,
          icon: 'success',
          onOpen: () => {}
        }).then(() => {}, () => {});
        const indx = GENERALES.indexOfObject(this.users, 'id', userDelete);
        this.users.splice(indx, 1);
        // this._alert.success('Usuario eliminado');
      } else {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () =>  {}
        }).then(() => {}, () => {});
      }
      this.loading = false;
    }, (error) => {
      Swal.fire({
        title: MESSAGES.M_TIT_CANCELADO,
        timer: 1200,
        showConfirmButton: false,
        icon: 'error',
        onOpen: () =>  {}
      }).then(() => {}, () => {});
      this.loading = false;
    });

  }

  editarUsuario(index: number, user: User) {
    this.router.navigate(['/auth/users/register', user.id]);
  }

  // sort(descriptionSort: string) {
  //   if (descriptionSort === this.sortType) {
  //     this.sortReverse = !this.sortReverse;
  //   } else {
  //     this.sortReverse = false;
  //   }
  //   this.sortType = descriptionSort;
  // }

  eliminarUsuario(user: User) {
    Swal.fire({
      title: '¿Deseas eliminar el usuario?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
       showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.userService.deleteUser(user.id).subscribe(
            (respuesta) => {
              if (respuesta.respond) {
                this.store.dispatch(new usuariosActions.EliminarUsuariosSuccess(user.id, this.users));
                resolve(respuesta.respond);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.CargarUsuariosFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result: any) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Usuario eliminado.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }



  statusUsuario(user: User) {
    Swal.fire({
      title: '¿Deseas actualizar el usuario?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
       showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.userService.actualizaEstatusUsuario(user).subscribe(
            (respuesta) => {
              if (respuesta.respond) {
                this.store.dispatch(new usuariosActions.ActualizaUsuariosEstatusSuccess(user));
                resolve(respuesta.respond);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaUsuariosEstatusFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result: any) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Usuario actualido.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchUser").focus();
  }

  closeSearch() {
    this.paginacion.isActive = false;
    this.paginacion.textSearch = '';
  }


  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }


	
}


