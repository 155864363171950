import { Departamento } from './reporte-actividades';

export class Cumplimiento{
	public departamentos: Departamento [];
	public dataSet: DataSet;


	constructor(
		obj: DataObjCumplimiento
	){
		this.departamentos = obj && obj.departamentos? obj.departamentos :[];
		this.dataSet = obj && obj.dataSet? obj.dataSet :new DataSet(null);


	}
}
interface DataObjCumplimiento{
	departamentos: Departamento [];
	dataSet: DataSet;
}

export class Justificacion{
	public id: number;
	public valor: number;
	public fecha: any;


	constructor(
		obj: DataObjJustificacion
	){
		this.id = obj && obj.id? obj.id :null;
		this.valor = obj && obj.valor? obj.valor :null;
		this.fecha = obj && obj.fecha? obj.fecha :null;

	}
}
interface DataObjJustificacion{
	id: number;
	valor: number;
	fecha: any;
}


export class DataSet{
	public id: number;
	public name: number;
	public data: Array<number>;


	constructor(
		obj: DataObjDataSet
	){
		this.id = obj && obj.id? obj.id :null;
		this.name = obj && obj.name? obj.name :null;
		this.data = obj && obj.data? obj.data :null;

	}
}
interface DataObjDataSet{
	id: number;
	name: number;
	data: Array<number>;
}

export class Labels{
	public labels: Array<string>;


	constructor(
		obj: DataObjLabels
	){
		this.labels = obj && obj.labels? obj.labels :null;

	}
}
interface DataObjLabels{
	labels: Array<string>;
}