import {Component, OnInit, OnDestroy} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Identity, User } from 'src/app/models/user';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public identity: Identity;
  private subscriptionAuth: Subscription = new Subscription();
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {
    this.store.dispatch(new usuariosActions.LimpiarResultados());
    this.identity = new Identity(null);
  }
  
  ngOnInit() {
    this.subscriptionAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
        console.log(state.identity);
      }
    );
  }

  ngOnDestroy() {
    this.subscriptionAuth.unsubscribe();
  }
 

}
