import { Component, OnInit, OnDestroy } from '@angular/core';
// import { Identity, User, FilterType } from 'src/app/models/user';
import { Identity, User } from 'src/app/models/user';
import { Subscription, Subject } from 'rxjs';
import { Asignaciones, AsignacionSortType } from 'src/app/models/asignaciones';
import { Pagination } from 'src/app/models/pagination';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import { AsignacionesService } from 'src/app/services/asignaciones.service';
import {ExcelService} from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-lis-asignaciones',
  templateUrl: './lis-asignaciones.component.html',
  styleUrls: ['./lis-asignaciones.component.sass']
})
export class LisAsignacionesComponent implements OnInit, OnDestroy {
  private subscribeAuth: Subscription = new Subscription();
  private subscribeAsignacion: Subscription = new Subscription();
  public identity: Identity;
  public title: string;
  public asignaciones: Asignaciones[] = [];
  public loading = false;
  public search: Asignaciones;
  public mesSelected = 0;
  public statusFilter = 0;
  public refresh: Subject<any>;
  public url: string;
  public orderType = [...AsignacionSortType];
  public paginacion = new Pagination(null);
  public usuarioSearch = 0;
  public prospectoSearch = 0;
  public usuariosFilter: User[] = [];
  public isCollapsed = false;
  // public filterTipos: FilterType[] = [];
  public estatusFilter: any[] = [];
  public tipoFilter: any[] = [];
  public centroCostoFilter: any[] = [];
  public filterActive = false;
  public fecha: Date = new Date();
  public datePipe = new DatePipe('en-US');
  public limiteFecha: string;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private excelService: ExcelService,
    private asignacionesService: AsignacionesService
  ) {
    this.identity = new Identity(null);
    this.loading = false;
    this.search = new Asignaciones(null);
    this.url = GLOBAL.url;
    this.refresh = new Subject();
    this.asignaciones = [];
    this.paginacion.sortType = '-id';
    // this.estatusFilter = [
    //   new EstatusVehiculo({id: 1, nombre: 'En proceso', checked: false}),
    //   new EstatusVehiculo({id: 2, nombre: 'Vendido', checked: false}),
    //   new EstatusVehiculo({id: 0, nombre: 'Cancelado', checked: false})
    // ];
    this.limiteFecha = this.datePipe.transform(new Date().setMonth(new Date().getMonth() + 1), 'yyyy-MM-dd');
   }

   ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeAsignacion = this.store.select('asignacion').subscribe(
      (state) => {
        this.asignaciones = [...state.asignaciones];
      }
    );
    this.title = 'Asignaciones';
    console.log('Inicializa el componente Asignaciones ...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeAsignacion.unsubscribe();
  }

  inicializa() {
    this.store.dispatch(new usuariosActions.CargarAsignaciones());
  }

  consultaDetalle(asignacion: Asignaciones) {
    if (asignacion.id && asignacion.id > 0) {
      this.router.navigate(['/auth/asignacion/register/', asignacion.id]);
    }
  }

  sort(descriptionSort: string) {
    if (descriptionSort === this.paginacion.sortType) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
    } else {
      this.paginacion.sortReverse = false;
    }
    this.paginacion.sortType = descriptionSort;
  }


  editarEstado(asignacion: Asignaciones){
    if(asignacion.estatus){



      Swal.fire({
        title: '¿Deseas desactivar asignacion?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
        cancelButtonColor: MESSAGES.C_BTN_CANCEL,
        confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
        cancelButtonText: MESSAGES.M_BTN_CANCEL,
        focusConfirm: false,
        focusCancel: true,
        animation: true,
         showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
        showLoaderOnConfirm: true,
        preConfirm:  ()  => {
          return new Promise( (resolve)  => {
            this.asignacionesService.actualizarEstatusAsignacion(asignacion).subscribe(
              (respuesta) => {
                if (respuesta) {
                  this.store.dispatch(new usuariosActions.CambiarEstatusAginacionSuccess(asignacion));
                  resolve(respuesta);
                } else {
                  Swal.showValidationMessage('Ocurrió un error en la operación');
                  resolve(false);
                }
              }, (error) => {
                this.store.dispatch(new usuariosActions.ActualizaIndicadorEstatusFail(error));
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            );
          });
        },
        allowOutsideClick: false
      }).then(
        (result: any) => {
          if (result.value) {
            Swal.fire({
              title: MESSAGES.M_TIT_CONFIRM,
              text: 'La asignacion se encuntra desactivada.',
              icon: 'success'
            });
          } else {
            Swal.fire({
              title: MESSAGES.M_TIT_CANCELADO,
              timer: 1200,
              showConfirmButton: false,
              icon: 'error',
              onOpen: () =>  {}
            }).then(() => {}, () => {});
          }
        }, () => {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () => {}
          }).then( () => {},  () => {});
        }
      );
    }else{
      this.asignacionesService.actualizarEstatusAsignacion(asignacion).subscribe(
        (response) => {
          this.store.dispatch(new usuariosActions.CambiarEstatusAginacionSuccess(asignacion));
          this.toastr.success('La asignacion se encuntra activo', 'Asignacion actualizada');
        }, (error) => {
          this.toastr.error('Ocurrió un error.', 'Error');
        }
      );
    }
  }


  changeEstatus() {
    this.estatusFilter = [...this.estatusFilter];
  }

  changeTipo() {
    this.tipoFilter = [...this.tipoFilter];
  }

  changeCentroCosto() {
    this.centroCostoFilter = [...this.centroCostoFilter];
  }

  crearExcel() {
    let excel;
    excel = this.asignaciones.map( item => {
      const obj: any = {};
      obj.NO_ORDEN= item.numeroOrden;
      obj.NOMBRE_DEL_CLIENTE = item.desCliente;
      obj.SERVICIO = item.desServicio;
      obj.LOCALIDAD = item.localidad;
      obj.SUCURSAL = item.desSucursal;
      obj.FECHA_INICIO = (item.fecInicio && item.fecInicio != null ? moment(item.fecInicio).format('DD/MM/YYYY') : '');
      obj.FECHA_TERMINO = (item.fecTermino && item.fecTermino != null ? moment(item.fecTermino).format('DD/MM/YYYY') : '');
      // obj.SIN_FIN  = (item.opcFin ? 'Si' : 'No');
      obj.TIPO_SERVICIO = item.desTipoServicio;
      obj.TIPO_SOLICITUD = item.desTipoSolicitud;
      obj.ELEMENTOS = item.elementos;
      obj.TURNOS = item.turnos;
      obj.ESTATUS = (item.estatus ? 'Activo' : 'Desactivado');
      return obj;
    });
    this.excelService.exportAsExcelFile(excel, 'asignaciones');
  }

  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchAsignacion").focus();
  }

  closeSearch() {
    this.paginacion.isActive = false;
    this.paginacion.textSearch = '';
  }


}
