import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Capturas } from '../models/captura-asignacion';
import { Observable } from 'rxjs';
import { Fechas } from 'src/app/models/fechas';

@Injectable({
  providedIn: 'root'
})
export class CapturasService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  obtenerAsignacionCapturar(dias: Fechas, opciones: any): Observable<any> {
    const params = JSON.stringify({dias, opciones});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/captura/asignacion', params, {headers});
  }

  actualizaAsignacionCapturar(id: number, captura: Capturas ): Observable<any> {
	const params = JSON.stringify({captura:captura});
	const headers = new HttpHeaders().set('Content-Type', 'application/json');
		
	return this.http.put(this.url + '/captura/asignacion/' + id, params, {headers});
  }

  downloadExcelCapturar(dias: Fechas, opciones: any): Observable<any> {
    const params = JSON.stringify({dias, opciones});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/captura/asignacion/excel', params, {headers});
  }

  // existeAsignacionFijo(cliente: number, servicio: number, tipo: number): Observable<any> {
  //   const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');

  //   return this.http.get(this.url + '/vehiculos/'+ cliente +'/existe/poliza/' + servicio, {headers});
  // }

}