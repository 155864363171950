import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
	providedIn: 'root'
 })
export class ExcelService {
  constructor() { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileAsignacion(tnc: any[],vacantes: any[],bajas: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tnc);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vacantes);
    const worksheet3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(bajas);
    const workbook: XLSX.WorkBook = { Sheets: { 'TNC': worksheet , 'Vacantes': worksheet2 , 'Bajas': worksheet3}, SheetNames:  ['TNC', 'Vacantes','Bajas'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  public exportAsExcelFileResumenAsignacion(tnc: any[],vacantes: any[],bajas: any[], porcentaje: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tnc);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vacantes);
    const worksheet3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(bajas);
    const worksheet4: XLSX.WorkSheet = XLSX.utils.json_to_sheet(porcentaje);
    const workbook: XLSX.WorkBook = { Sheets: { 'TNC': worksheet , 'Vacantes': worksheet2 , 'Bajas': worksheet3, 'TNC %': worksheet4}, SheetNames:  ['TNC', 'Vacantes','Bajas', 'TNC %'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileReporteBajaVacante(sheetOne: any[], sheetTwo: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetOne);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetTwo);
    const workbook: XLSX.WorkBook = { Sheets: { 'Baja': worksheet , 'Vacante': worksheet2 },  SheetNames:  ['Baja', 'Vacante'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  public exportAsExcelFileReporteIniciadoFinalizado(sheetOne: any[], sheetTwo: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetOne);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetTwo);
    const workbook: XLSX.WorkBook = { Sheets: { 'Dicisión': worksheet , 'Servicios': worksheet2 },  SheetNames:  ['Dicisión', 'Servicios'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    // FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}


// XXX| A | B | C | D | E | F | G |
// ---+---+---+---+---+---+---+---+
//  1 | S | h | e | e | t | J | S |
//  2 | 1 | 2 |   |   | 5 | 6 | 7 |
//  3 | 2 | 3 |   |   | 6 | 7 | 8 |
//  4 | 3 | 4 |   |   | 7 | 8 | 9 |
//  5 | 4 | 5 | 6 | 7 | 8 | 9 | 0 |
// This worksheet can be built up in the order A1:G1, A2:B4, E2:G4, A5:G5:

// /* Initial row */

// var ws = XLSX.utils.json_to_sheet([
//   { A: "S", B: "h", C: "e", D: "e", E: "t", F: "J", G: "S" }
// ], {header: ["A", "B", "C", "D", "E", "F", "G"], skipHeader: true});


// /* Write data starting at A2 */

// XLSX.utils.sheet_add_json(ws, [
//   { A: 1, B: 2 }, { A: 2, B: 3 }, { A: 3, B: 4 }
// ], {skipHeader: true, origin: "A2"});



// /* Write data starting at E2 */

// XLSX.utils.sheet_add_json(ws, [
//   { A: 5, B: 6, C: 7 }, { A: 6, B: 7, C: 8 }, { A: 7, B: 8, C: 9 }
// ], {skipHeader: true, origin: { r: 1, c: 4 }, header: [ "A", "B", "C" ]});


// /* Append row */

// XLSX.utils.sheet_add_json(ws, [
//   { A: 4, B: 5, C: 6, D: 7, E: 8, F: 9, G: 0 }
// ], {header: ["A", "B", "C", "D", "E", "F", "G"], skipHeader: true, origin: -1});