import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { Actividad } from '../models/actividades';
import { Cumplimiento } from 'src/app/models/cumplimiento';
import { Fechas } from 'src/app/models/fechas';

@Injectable({
  providedIn: 'root'
})
export class ActividadesService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

	registerActividad(actividadToRegister: Actividad): Observable<any> {
		const params = JSON.stringify({actividad: actividadToRegister});
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this.http.post(this.url + '/actividades', params, {headers});
	}

	getActividades(page = null): Observable<any> {
		const headers = new HttpHeaders().set('Content-type', 'application/json');

		return this.http.get(this.url + '/actividades', {headers});
	}

	getActividad(id: number): Observable<any> {
		const headers = new HttpHeaders().set('Content-type', 'application/json');
		
		return this.http.get(this.url + '/actividades/' + id, {headers});
	 }

	 updateActividad(actividadToUpdate: Actividad): Observable<any> {
		const params = JSON.stringify({actividad:actividadToUpdate});
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		
		return this.http.put(this.url + '/actividades/' + actividadToUpdate.id, params, {headers});
	 }
	 deleteActividad(id: number): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
	
		return this.http.delete(this.url + '/actividades/' + id, {headers});
	
	}

	 getAnexo(id: number): Observable<any> {
		const headers = new HttpHeaders().set('Content-type', 'application/json');
		
		return this.http.get(this.url + '/actividades/anexos/' + id, {headers});
	 }

	 deleteFile(id: number, idAnexo: number, name: string): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.delete(this.url +'/actividades/'+id + '/anexos/' + idAnexo + '/' + name, {headers});
	 }

	 reportes(dias: Fechas): Observable<any> {
		const params = JSON.stringify({dias});
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this.http.post(this.url + '/actividades/reporte', params, {headers});
	 }

	 cumplimiento(dias: Fechas): Observable<any> {
		const params = JSON.stringify({dias});
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this.http.post(this.url + '/actividades/cumplimiento', params, {headers});
	 }

	 
	 updateUserCumplimiento(param: Cumplimiento): Observable<any> {
		const params = JSON.stringify(param);
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
	
		return this.http.put(this.url + '/actividades/cumplimiento_tabla', params , {headers});
	 }

	 getFiltro(): Observable<any> {
		const headers = new HttpHeaders().set('Content-type', 'application/json');
		
		return this.http.get(this.url + '/actividades/filtro', {headers});
	 }

	 getActividadMes(mes,anio):Observable<any>{
		const headers = new HttpHeaders().set('Content-type', 'application/json');
		
		return this.http.get(this.url + '/actividades/'+ mes +'/'+ anio, {headers});
	 }

}
