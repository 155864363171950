import { Action } from '@ngrx/store';
import { Estado, Municipio, Colonia } from 'src/app/models/direccion';

export const CARGAR_ESTADOS = '[Direccion] Cargar estados';
export const CARGAR_ESTADOS_SUCCESS = '[Direccion] Cargar estados success';
export const CARGAR_ESTADOS_FAIL = '[Direccion] Cargar estados fail';
export const CARGAR_MUNICIPIOS = '[Direccion] Cargar municipio';
export const CARGAR_MUNICIPIOS_SUCCESS = '[Direccion] Cargar municipio success';
export const CARGAR_MUNICIPIOS_FAIL = '[Direccion] Cargar municipio fail';
export const CARGAR_COLONIAS = '[Direccion] Cargar colonias';
export const CARGAR_COLONIAS_SUCCESS = '[Direccion] Cargar colonias success';
export const CARGAR_COLONIAS_FAIL = '[Direccion] Cargar colonias fail';
export const CARGAR_CODE_POSTAL = '[Direccion] Cargar codigo postal';
export const CARGAR_CODE_POSTAL_SUCCESS = '[Direccion] Cargar codigo postal success';
export const CARGAR_CODE_POSTAL_FAIL = '[Direccion] Cargar codigo postal fail';

export class CargarEstados implements Action {
  readonly type = CARGAR_ESTADOS;
}

export class CargarEstadosFail implements Action {
  readonly type = CARGAR_ESTADOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarEstadosSuccess implements Action {
  readonly type = CARGAR_ESTADOS_SUCCESS;

  constructor(public estados: Estado[]) {}
}

export class CargarMunicipios implements Action {
  readonly type = CARGAR_MUNICIPIOS;

  constructor(public estado: number) {}
}

export class CargarMunicipiosFail implements Action {
  readonly type = CARGAR_MUNICIPIOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarMunicipiosSuccess implements Action {
  readonly type = CARGAR_MUNICIPIOS_SUCCESS;

  constructor(public municipios: Municipio[], public estado: number) {}
}

export class CargarColonias implements Action {
  readonly type = CARGAR_COLONIAS;

  constructor(public estado: number, public municipio: number) {}
}

export class CargarColoniasFail implements Action {
  readonly type = CARGAR_COLONIAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarColoniasSuccess implements Action {
  readonly type = CARGAR_COLONIAS_SUCCESS;

  constructor(public colonias: Colonia[], public estado: number, public municipio: number) {}
}

export class CargarCodePostal implements Action {
  readonly type = CARGAR_CODE_POSTAL;

  constructor(public codePostal: string) {}
}

export class CargarCodePostalFail implements Action {
  readonly type = CARGAR_CODE_POSTAL_FAIL;

  constructor( public payload: any ) {}
}

export class CargarCodePostalSuccess implements Action {
  readonly type = CARGAR_CODE_POSTAL_SUCCESS;

  constructor(public estado: number, public municipio: number) {}
}

export type direccionAcciones = CargarEstados 
                              | CargarEstadosFail 
                              | CargarEstadosSuccess 
                              | CargarMunicipios 
                              | CargarMunicipiosFail 
                              | CargarMunicipiosSuccess 
                              | CargarColonias 
                              | CargarColoniasFail 
                              | CargarColoniasSuccess 
                              | CargarCodePostal 
                              | CargarCodePostalFail 
                              | CargarCodePostalSuccess;
