import { Action } from '@ngrx/store';
import { ActividadMultiple } from 'src/app/models/actividades';

export const CARGAR_ACTIVIDADES_EMPRESA = '[ActividadMultiple] Cargar actividades empresa';
export const CARGAR_ACTIVIDADES_EMPRESA_FAIL = '[ActividadMultiple] Cargar actividades empresa FAIL';
export const CARGAR_ACTIVIDADES_EMPRESA_SUCCESS = '[ActividadMultiple] Cargar actividades empresa SUCCESS';
export const ACTUALIZA_ACTIVIDADES = '[ActividadMultiple] Actualiza actividades';
export const ACTUALIZA_ACTIVIDADES_FAIL = '[ActividadMultiple] Actualiza actividades FAIL';
export const ACTUALIZA_ACTIVIDADES_SUCCESS = '[ActividadMultiple] Actualiza actividades SUCCESS';
export const ACTUALIZA_ACTIVIDADES_ESTATUS = '[ActividadMultiple] Actualiza actividades estatus';
export const ACTUALIZA_ACTIVIDADES_ESTATUS_FAIL = '[ActividadMultiple] Actualiza actividades estatus FAIL';
export const ACTUALIZA_ACTIVIDADES_ESTATUS_SUCCESS = '[ActividadMultiple] Actualiza actividades estatus SUCCESS';
export const LIMPIAR_ACTIVIDADES = '[Contacto] Limpiar actividades';
export const CARGAR_ACTIVIDADES_CONTACTO = '[ActividadMultiple] Cargar actividades contacto';
export const CARGAR_ACTIVIDADES_CONTACTO_FAIL = '[ActividadMultiple] Cargar actividades contacto FAIL';
export const CARGAR_ACTIVIDADES_CONTACTO_SUCCESS = '[ActividadMultiple] Cargar actividades contacto SUCCESS';
export const CARGAR_ACTIVIDADES_OPORTUNIDAD = '[ActividadMultiple] Cargar actividades contactos oportunidad';
export const CARGAR_ACTIVIDADES_OPORTUNIDAD_FAIL = '[ActividadMultiple] Cargar actividades contactos oportunidad FAIL';
export const CARGAR_ACTIVIDADES_OPORTUNIDAD_SUCCESS = '[ActividadMultiple] Cargar actividades contactos oportunidad SUCCESS';


export class CargarActividadesEmpresa implements Action {
  readonly type = CARGAR_ACTIVIDADES_EMPRESA;

  constructor(public id: number) {}
}

export class CargarActividadesEmpresaFail implements Action {
  readonly type = CARGAR_ACTIVIDADES_EMPRESA_FAIL;

  constructor( public payload: any ) {}
}

export class CargarActividadesEmpresaSuccess implements Action {
  readonly type = CARGAR_ACTIVIDADES_EMPRESA_SUCCESS;

  constructor( public actividades: ActividadMultiple[] ) {}
}

export class ActualizaActividades implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES;

  constructor( public actividad: ActividadMultiple ) { }
}

export class ActualizaActividadesFail implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaActividadesSuccess implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES_SUCCESS;

  constructor( public actividad: ActividadMultiple ) {}
}

export class ActualizaActividadesEstatus implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaActividadesEstatusFail implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaActividadesEstatusSuccess implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES_ESTATUS_SUCCESS;

  constructor( public actividad: ActividadMultiple ) {}
}

export class LimpiarActividades implements Action {
  readonly type = LIMPIAR_ACTIVIDADES;

  constructor() {}
}

export class CargarActividadesContacto implements Action {
  readonly type = CARGAR_ACTIVIDADES_CONTACTO;

  constructor(public id: number) {}
}

export class CargarActividadesContactoFail implements Action {
  readonly type = CARGAR_ACTIVIDADES_CONTACTO_FAIL;

  constructor( public payload: any ) {}
}

export class CargarActividadesContactoSuccess implements Action {
  readonly type = CARGAR_ACTIVIDADES_CONTACTO_SUCCESS;

  constructor( public actividades: ActividadMultiple[] ) {}
}

export class CargarActividadesOportunidad implements Action {
  readonly type = CARGAR_ACTIVIDADES_OPORTUNIDAD;

  constructor(public id: number) {}
}

export class CargarActividadesOportunidadFail implements Action {
  readonly type = CARGAR_ACTIVIDADES_OPORTUNIDAD_FAIL;

  constructor( public payload: any ) {}
}

export class CargarActividadesOportunidadSuccess implements Action {
  readonly type = CARGAR_ACTIVIDADES_OPORTUNIDAD_SUCCESS;

  constructor( public actividades: ActividadMultiple[] ) {}
}

export type actividadesMultipleAcciones = CargarActividadesEmpresa
                                        | CargarActividadesEmpresaFail
                                        | CargarActividadesEmpresaSuccess
                                        | ActualizaActividades
                                        | ActualizaActividadesFail
                                        | ActualizaActividadesSuccess
                                        | ActualizaActividadesEstatus
                                        | ActualizaActividadesEstatusFail
                                        | ActualizaActividadesEstatusSuccess
                                        | LimpiarActividades
                                        | CargarActividadesContacto
                                        | CargarActividadesContactoFail
                                        | CargarActividadesContactoSuccess
                                        | CargarActividadesOportunidad
                                        | CargarActividadesOportunidadFail
                                        | CargarActividadesOportunidadSuccess ;

