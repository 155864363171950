import * as fromUsuario from '../actions';
import { Colores } from 'src/app/models/colores';

export interface ColoresState {
  colores: Colores[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: ColoresState = {
  colores: [],
  loaded: false,
  loading: false,
  error: null
};

export function coloresReducer( state = estadoInicial, action: fromUsuario.coloresAcciones ): ColoresState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_COLORES:
      return {
        ...state,
        loading: true,
        error: null
        };
    case fromUsuario.CARGAR_COLORES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        colores: [...action.colores]
        };
    case fromUsuario.CARGAR_COLORES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
        ...action.payload
          }
      };
    case fromUsuario.ACTUALIZA_COLOR:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_COLOR_SUCCESS:
      const itm = state.colores.find( item => item.id === action.color.id);
      if (itm && itm.id) {
        state.colores = state.colores.map( item => {
          if (item.id === action.color.id) {
            item = {...action.color};
          }
          return item;
        });
      } else {
        state.colores.push(action.color);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_COLOR_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ELIMINAR_COLOR:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ELIMINAR_COLOR_SUCCESS:
      state.colores = state.colores.filter( item => item.id !== action.id);
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ELIMINAR_COLOR_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

