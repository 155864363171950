import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { ClientesService } from 'src/app/services/cliente.service';

@Injectable()
export class ClienteEffects {

  constructor(
    private actions$: Actions,
    public clientesServices: ClientesService
  ) {}

  @Effect()
  cargarCliente$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_CLIENTES ),
    switchMap( () => {
      return this.clientesServices.obtenerClientes()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarClientesSuccess(respuesta.clientes);
          }),
          catchError( error => of(new usuariosActions.CargarClientesFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusCliente$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_CLIENTE_ESTATUS ),
    switchMap( (action: any) => {
      return this.clientesServices.actualizaEstatusCliente(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaClienteEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaClienteEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaCliente$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_CLIENTE ),
    switchMap( (action: any) => {
      return this.clientesServices.registraCliente(action.cliente)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaClienteSuccess(action.cliente);
            }),
            catchError( error => of(new usuariosActions.ActualizaClienteFail(error))  )
        );
    })
  );

}
