import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Cliente } from 'src/app/models/clientes';
import { Servicio, ServicioSortType } from 'src/app/models/servicios';
import { Sucursal } from 'src/app/models/sucursales';
import { Pagination } from 'src/app/models/pagination';
import { ServiciosService } from 'src/app/services/servicios.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { ToastrService } from 'ngx-toastr';
import { GENERALES } from 'src/app/models/generales';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';
@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.sass']
})
export class ServiciosComponent implements OnInit, OnDestroy {
  private subscribeAuth: Subscription = new Subscription();
  private subscribeServicio: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  public title: string;
  public identity: Identity;
  public servicio: Servicio;
  public sucursales: Sucursal[] = [];
  public clientes: Cliente[] = [];
  public servicioInicial: Servicio = new Servicio(null);
  public servicios: Servicio[] = [];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public filterActive: boolean = false;
  public orderType = [...ServicioSortType];
  constructor(
    private serviciosService: ServiciosService,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) {
    this.paginacion.sortType = '+nombre';
    this.identity = new Identity(null);
    this.servicio = new Servicio(null);
   }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeServicio = this.store.select('servicios').subscribe(
      (state) => {
        this.servicios = [...state.servicios];
      }
    );
    this.subscribeConsulta = this.store.select('consultaCatalogos').subscribe(
      (state) => {
        this.sucursales = [...state.sucursales.filter(item => item.status)];
        this.clientes = [...state.clientes.filter(item => item.status)];
      }
    );
    this.title = 'Catálogo de servicios';
    console.log('Inicializa component Servicios...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeServicio.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  inicializa() {
    this.store.dispatch(new usuariosActions.CargarServicios());
    this.store.dispatch(new usuariosActions.ConsultaCatalogoClientes());
    this.store.dispatch(new usuariosActions.ConsultaCatalogoSucursales());
  }

  editarServicio(zon: Servicio) {
    this.servicio = {...zon};
    this.servicioInicial = {...zon};
  }

  estatusServicio(servicio: Servicio) {
    if (servicio.id > 0) {
      this.loading = true;
      this.serviciosService.actualizaEstatusServicio(servicio).subscribe(
        (response) => {
          this.store.dispatch(new usuariosActions.ActualizaServicioEstatusSuccess(servicio.id));
          this.toastr.success('Actualización correctamente.', 'Exito');
          this.loading = false;
        }, (error) => {
          this.toastr.error('Ocurrió un error.', 'Error');
          this.loading = false;
        }
      );
    }
  }

  onSubmit(form) {
    this.clientes.find(item => {
      if(item.id === this.servicio.cliente){
        this.servicio.desCliente = item.nombre
      }
    });
    this.sucursales.find(item => {
      if(item.id === this.servicio.sucursal){
        this.servicio.desSucursal = item.nombre
      }
    });
    Swal.fire({
      title: '¿Deseas registrar el servicio?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.serviciosService.registraServicio(this.servicio).subscribe(
            (respuesta) => {
              if (respuesta.servicio) {
                this.store.dispatch(new usuariosActions.ActualizaServicioSuccess(respuesta.servicio));
                resolve(respuesta.servicio);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaServicioFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          form.reset();
          this.servicio = new Servicio(null);
          this.servicioInicial = new Servicio(null);
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Servicio registrado.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    ); 
  }

  limpiar(form) {
    this.servicio = new Servicio(null);
    this.servicioInicial = new Servicio(null);
    form.reset();
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

}
