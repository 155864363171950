import { Component, OnInit, OnDestroy } from '@angular/core';
import { Departamento, DepartamentoSortType } from 'src/app/models/departamento';
import { DepartamentosService } from 'src/app/services/departamentos.service';
import { Identity } from 'src/app/models/user';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Pagination } from 'src/app/models/pagination';
import * as usuariosActions from 'src/app/store/actions';
import { MESSAGES } from 'src/app/models/messages';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.sass']
})
export class AreasComponent implements OnInit, OnDestroy {
  heading = 'Área';
  subheading = 'Distintas áreas de la empresa';
  icon = ' lnr-list icon-gradient bg-happy-fisher';
  public area: Departamento;
  public areaInicial: Departamento = new Departamento(null);
  public areas: Departamento[];
  public loading: boolean;
  public identity: Identity;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionArea: Subscription = new Subscription();
  public paginacion: Pagination = new Pagination(null);
  public filterActive: boolean = false;
  public orderType = [...DepartamentoSortType];
  constructor(
    private departamentosService : DepartamentosService,
    private toastr: ToastrService,
    private store: Store<AppState>,
  ) { 
    this.area = new Departamento(null);
    this.paginacion.sortType = '+nombre';
    this.areas = [];
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionArea = this.store.select('areas').subscribe(
      (state) => {
        this.areas = [...state.departamentos];
      }
    );
    this.store.dispatch(new usuariosActions.CargarAreas());
    console.log('Inicializa component areas...');
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionArea.unsubscribe();
  }

  
  onSubmit(form){
    Swal.fire({
      title: '¿Deseas registrar el área?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
       showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.departamentosService.registerDepartamento(this.area).subscribe(
            (respuesta) => {
              if (respuesta.departamento) {
                this.store.dispatch(new usuariosActions.ActualizaAreaSuccess(respuesta.departamento));
                resolve(respuesta.departamento);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaAreaFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result: any) => {
        if (result.value) {
          form.reset();
          this.area = new Departamento(null);
          this.areaInicial = new Departamento(null);
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Área registrada.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  editarArea(area : Departamento){
    this.area  = {...area};
    this.areaInicial = {...area};
  }

  

  deleteArea(id: number){
    this.loading = true;
    Swal.fire({
      title: '¿Deseas eliminar el área?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
       showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.departamentosService.deleteDepartamento(id).subscribe(
            (respuesta) => {
              if (respuesta.respond) {
                this.store.dispatch(new usuariosActions.EliminarAreaSuccess(id));
                resolve(respuesta.respond);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaAreaFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result: any) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Área eliminada.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
    this.loading = false;
  }

  limpiar(form){
    this.area = new Departamento(null);
    this.areaInicial = new Departamento(null);
    form.reset();
  }

 
  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

}
