import { Pipe, PipeTransform } from '@angular/core';
import { ActividadMultiple } from '../models/actividades';

@Pipe({
  name: 'filtroActividadesConcepto'
})
export class FiltroActividadesConceptoPipe implements PipeTransform {

  transform(value: ActividadMultiple[], concepto: number): any {
    if(!value){
      return [];
    }
    if(!concepto){
      return value;
    }
    const listaFiltrada = value.filter( (item: ActividadMultiple) => {
      if ( !concepto || (concepto && item.concepto === concepto)){
        return true;
      } else {
        return false;
      }
    });
    return listaFiltrada;
  }

}



