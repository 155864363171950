import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Catalogo } from 'src/app/models/catalogo';
import { Industria } from 'src/app/models/industrias';
import { Empresa } from 'src/app/models/empresa';
import { Estado, Municipio, Colonia } from 'src/app/models/direccion';
import { EmpresasService } from 'src/app/services/empresa.service';
import Swal from 'sweetalert2';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { UsoCfdi } from 'src/app/models/uso-cfdi';
import { DateWithoutTimeZonePipe } from 'src/app/pipes/date-without-time-zone.pipe';


@Component({
  selector: 'app-empresas-edit',
  templateUrl: './empresas-edit.component.html',
  styleUrls: ['./empresas-edit.component.sass']
})
export class EmpresasEditComponent implements OnInit, OnDestroy {
  heading = 'Detalles de la empresa';
  subheading = 'Empresa registranda en el sistema';
  icon = 'pe-7s-notebook icon-gradient bg-dark';
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private subcriptionEmpresa: Subscription = new Subscription();
  private subcriptionDireccion: Subscription = new Subscription();
  public empresa: Empresa  = new Empresa(null);
  public empresaInicial: Empresa  = new Empresa(null);
  public industrias: Industria[];
  public industriasFiltradas: Industria[];
  public estados: Estado[];
  public municipios: Municipio[];
  public colonias: Colonia[];
  public estadosDatoFiscal: Estado[];
  public municipiosDatoFiscal: Municipio[];
  public coloniasDatoFiscal: Colonia[];
  public loading: boolean;
  public editar: Boolean = false;
  public editarEstaMuni: Boolean = false;
  public editarMunicipio: Boolean = false;
  public editarDatoFiscal: Boolean = false;
  public editarEstaMuniDatoFiscal: Boolean = false;
  public editarMunicipioDatoFiscal: Boolean = false;
  public imageChangedEvent: any;
  public showCropper: Boolean = false;
  public tipoDireccion: number = 0 ;
  public telefonos: number = 1;
  public propietarios: User[];
  public clasificaciones: Catalogo[];
  public clases: Catalogo[];
  public paises: Catalogo[];
  public cpAnterior: string;
  public cpAnteriorDatoFiscal: string;
  public clasificacionAnterior: number;
  public tiposContribuyentes = [ {nombre: 'Físicas'}, {nombre: 'Morales'} ];
  public usosCfdis: UsoCfdi[] = [];
  private pipeDateWithoutTimeZone = new DateWithoutTimeZonePipe();

  @ViewChild(ImageCropperComponent, {static: false}) imageCropper: ImageCropperComponent;
  constructor(
    private empresasService: EmpresasService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.loading = false;
    this.identity = new Identity(null);
    this.industrias = [];
    this.industriasFiltradas = [];
    this.colonias = [];
    this.municipios = [];
    this.estados = [];
    this.propietarios = [];
    this.estadosDatoFiscal = [];
    this.municipiosDatoFiscal = [];
    this.clases = [];
    this.coloniasDatoFiscal = [];
    this.cpAnterior = '';
    this.cpAnteriorDatoFiscal = '';
    this.clasificacionAnterior = 99999;
    this.store.dispatch(new usuariosActions.LimpiarEmpresa());
   }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.industrias = [...state.industrias.filter(item => item.status)];
        this.propietarios = [...state.usuarios];
        this.clasificaciones = [...state.clasificaciones.filter(item => item.status)];
        this.paises = [...state.paises.filter(item => item.status)];
        this.usosCfdis = [...state.usosCfdis];
        this.industriasFiltradas = [...state.industrias.filter(item => item.status)];
      }
    );
    this.subcriptionEmpresa = this.store.select('empresa').subscribe(
      (state) => {
        this.empresa = {...state.empresa};
        this.empresaInicial = {...state.empresa};

        this.empresa.fundacion = this.empresa.fundacion ? this.pipeDateWithoutTimeZone.transform(this.empresa.fundacion) : null;
        this.empresaInicial.fundacion = this.empresaInicial.fundacion ? this.pipeDateWithoutTimeZone.transform(this.empresaInicial.fundacion) : null;

        if(this.empresa.pais === 1 && !this.loading && this.estados.length === 0 && this.municipios.length === 0){
          this.consultaCodigoPostal();
        }
        if(this.empresa.paisDatoFiscal === 1 && !this.loading && this.estadosDatoFiscal.length === 0 && this.municipiosDatoFiscal.length === 0 ){
          this.consultaCodigoPostalDatoFiscal();
          console.log('datos fiscales');
        }
      }
    );
    this.subcriptionDireccion = this.store.select('direccion').subscribe(
      (state) => {
        if(state.estados.length > 0 || state.municipios.length > 0 || state.colonias.length > 0){
          this.asignarDireccion(state.municipio, state.estado, [...state.estados], [...state.municipios], [...state.colonias]);
        }
      }
    );
    console.log('Inicializa component empresas-edit...');
    this.inicializa();
  }

  asignarDireccion(municipio: number, estado: number, estados: Estado[], municipios: Municipio[], colonias: Colonia[]){
    this.loading = false;
    switch (this.tipoDireccion) {
      case 1:
        this.estados = [...estados];
        this.municipios = [...municipios];
        this.colonias = [...colonias];
        if(this.editarEstaMuni){
          this.empresa.municipio = municipio;
          this.empresa.estado = estado;
          if(estado && municipio){
            this.editar = false;
          }
          if(estado === 0 && municipio === 0){
            this.editar = true;
          }
        }
        if(this.empresa.paisDatoFiscal === 1 && (this.estadosDatoFiscal.length === 0 || this.municipiosDatoFiscal.length === 0 )){
          this.consultaCodigoPostalDatoFiscal();
        }
        break;
      case 2:
        this.estadosDatoFiscal = [...estados];
        this.municipiosDatoFiscal = [...municipios];
        this.coloniasDatoFiscal = [...colonias];
        if(this.editarEstaMuniDatoFiscal){
          this.empresa.municipioDatoFiscal = municipio;
          this.empresa.estadoDatoFiscal = estado;
          if(estado && municipio){
            this.editarDatoFiscal = false;
          }
          if(estado === 0 && municipio === 0){
            this.editarDatoFiscal = true;
          }
        }
        if( this.empresa.pais === 1 && (this.estados.length === 0 || this.municipios.length === 0 )){
          this.consultaCodigoPostal();
        }
        break;
    }
  }


  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeConsulta.unsubscribe();
    this.subcriptionEmpresa.unsubscribe();
    this.subcriptionDireccion.unsubscribe();
  }

  inicializa() {
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        if (id > 0) {
          this.store.dispatch(new usuariosActions.CargarEmpresa(id));
        } else {
          this.empresa = new Empresa(null);
          this.empresa.propietario = this.identity.id;
          this.empresa.pais = 1;
          this.empresa.paisDatoFiscal = 1;
          this.empresaInicial = {...this.empresa};
        }
    });
    this.store.dispatch(new usuariosActions.ConsultaIndustrias());
    this.store.dispatch(new usuariosActions.CargarEstados());
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());
    this.store.dispatch(new usuariosActions.ConsultaPaises());
    this.store.dispatch(new usuariosActions.ConsultaClasificaciones());
    this.store.dispatch(new usuariosActions.ConsultaUsosCfdis());
    this.store.dispatch(new usuariosActions.ConsultaIndustrias());
  }

  async onSubmit() {
    await this.validaEmpresa().then(
      (resultado) => {
        console.log('hola?');
        Swal.fire({
          title: '¿Deseas registrar la empresa?',
          showCancelButton: true,
          confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
          cancelButtonColor: MESSAGES.C_BTN_CANCEL,
          confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
          cancelButtonText: MESSAGES.M_BTN_CANCEL,
          focusConfirm: false,
          focusCancel: true,
          animation: true,
           showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
          showLoaderOnConfirm: true,
          preConfirm:  ()  => {
            return new Promise( (resolve)  => {
              this.empresasService.registraEmpresa(this.empresa).subscribe(
                (respuesta) => {
                  if (respuesta.empresa) {
                    this.store.dispatch(new usuariosActions.ActualizaEmpresaSuccess(respuesta.empresa));
                    resolve(respuesta.empresa);
                  } else {
                    Swal.showValidationMessage('Ocurrió un error en la operación');
                    resolve(false);
                  }
                }, (error) => {
                  this.store.dispatch(new usuariosActions.ActualizaEmpresaFail(error));
                  Swal.showValidationMessage('Ocurrió un error en la operación');
                  resolve(false);
                }
              );
            });
          },
          allowOutsideClick: false
        }).then(
          (result: any) => {
            if (result.value) {
              Swal.fire({
                title: MESSAGES.M_TIT_CONFIRM,
                text: 'Empresa registrada.',
                icon: 'success'
              });
              this.router.navigate(['/auth/empresas/profile', result.value.id]);
            } else {
              Swal.fire({
                title: MESSAGES.M_TIT_CANCELADO,
                timer: 1200,
                showConfirmButton: false,
                icon: 'error',
                onOpen: () =>  {}
              }).then(() => {}, () => {});
            }
          }, () => {
            Swal.fire({
              title: MESSAGES.M_TIT_CANCELADO,
              timer: 1200,
              showConfirmButton: false,
              icon: 'error',
              onOpen: () => {}
            }).then( () => {},  () => {});
          }
        );
    }, (error) => { }
    );
  }

  consultaCodigoPostal() {
    this.tipoDireccion = 1;
    // this.empresa.municipio = null;
    // this.empresa.estado = null;
    if(this.empresa.codigoPostal && this.empresa.pais === 1 && this.empresa.codigoPostal !== this.cpAnterior){
      this.cpAnterior = this.empresa.codigoPostal;
      this.loading = true;
      this.editarEstaMuni = true;
      this.editar = true;
      this.store.dispatch(new usuariosActions.CargarCodePostal(this.empresa.codigoPostal));
    }else{
      this.editar = false;
    }
  }

  consultaMunicipio() {
    this.tipoDireccion = 1;
    if(this.empresa.estado && this.empresa.pais === 1){
      this.editarEstaMuni = false;
      this.editarMunicipio = true;
      this.empresa.municipio = null;
      this.store.dispatch(new usuariosActions.CargarMunicipios(this.empresa.estado));
    }
  }



  validaEmpresa() {
    const deferred = new Promise((resolve, reject) => {
      if(this.empresa.pais === 1 && this.empresa.codigoPostal){
         this.estados.find(item => { if  (item.id === this.empresa.estado) { this.empresa.desEstado = item.nombre } });
         this.municipios.find(item => { if  (item.id === this.empresa.municipio) { this.empresa.desMunicipio = item.nombre } });
        
      }
      if(this.empresa.paisDatoFiscal === 1 && this.empresa.opcDatoFiscal && !this.empresa.direccionDatoFiscal && this.empresa.codigoPostalDatoFiscal){
        this.estadosDatoFiscal.find(item => { if (item.id === this.empresa.estadoDatoFiscal) { this.empresa.desEstadoDatoFiscal = item.nombre} });
        this.municipiosDatoFiscal.find(item => { if (item.id === this.empresa.municipioDatoFiscal) { this.empresa.desMunicipioDatoFiscal = item.nombre} });
      }
      this.industrias.find(item =>{ if (item.nombre === this.empresa.desIndustria){ this.empresa.industria = item.id} });    
      this.paises.find(item =>{ if (item.id === this.empresa.pais) {this.empresa.desPais = item.nombre} });
      this.paises.find(item =>{ if (item.id === this.empresa.paisDatoFiscal) {this.empresa.desPaisDatoFiscal = item.nombre} });
      this.existsEmpresa().then( (respuesta) => {
        if (!respuesta) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, (err) => {
        resolve(false);
      });
    });
    return deferred;
  }

  fileChangeEvent(event: any): void {
    // this.files = event.target.files as Array<File>;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.empresa.imagen = event.base64;
    // console.log(event);
  }

  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  cropperReady() {
    // console.log('Cropper ready');
  }

  loadImageFailed() {
    // console.log('Load failed');
  }

  rotateLeft() {
    this.imageCropper.canvasRotation = 1;

    // this.imageCropper.rotateLeft;
  }

  rotateRight() {
    this.imageCropper.canvasRotation = 3;

    // this.imageCropper.rotateRight
  }

  flipHorizontal() {
    this.imageCropper.transform.flipH = !this.imageCropper.transform.flipH;

    // this.imageCropper.flipHorizontal;
  }

  flipVertical() {
    this.imageCropper.transform.flipV = !this.imageCropper.transform.flipV;

    // this.imageCropper.flipVertical;
  }


  cantidadTelefono(evento: number){
    this.telefonos = evento === 1 ? (this.telefonos + 1) : (this.telefonos - 1);
  }

  consultaCodigoPostalDatoFiscal() {
    this.tipoDireccion = 2;
    // this.empresa.municipioDatoFiscal = null;
    // this.empresa.estadoDatoFiscal = null;
    if(this.empresa.codigoPostalDatoFiscal && this.empresa.paisDatoFiscal === 1 && this.empresa.codigoPostalDatoFiscal !== this.cpAnteriorDatoFiscal){
      this.loading = true;
      this.cpAnteriorDatoFiscal = this.empresa.codigoPostalDatoFiscal;
      this.editarEstaMuniDatoFiscal = true;
      this.editarDatoFiscal = true;
      this.store.dispatch(new usuariosActions.CargarCodePostal(this.empresa.codigoPostalDatoFiscal));
    }else{
      this.editarDatoFiscal = false;
    }
  }

  consultaMunicipioDatoFiscal() {
    this.tipoDireccion = 2;
    if(this.empresa.estadoDatoFiscal && this.empresa.paisDatoFiscal === 1){
      this.editarEstaMuniDatoFiscal = false;
      this.editarMunicipioDatoFiscal = true;
      this.empresa.municipioDatoFiscal = null;
      this.store.dispatch(new usuariosActions.CargarMunicipios(this.empresa.estadoDatoFiscal));
    }
  }

  limpiarFecha() {
    this.empresa.fundacion = null;
  }

  existsEmpresa() {
    return new Promise( (resolve, reject) => {
      this.empresasService.existeEmpresa(this.empresa).subscribe(
        (resultado: any) => {
          if (resultado.empresa && resultado.empresa.id) {
            Swal.fire({
              title: '¿El nombre de la empresa ya existe, deseas cargar su información?',
              text: 'Si aceptas cargara la información de esa empresa, si cancelas, tienes que cambiar el nombre de la empresa para continuar',
              showCancelButton: true,
              confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
              cancelButtonColor: MESSAGES.C_BTN_CANCEL,
              confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
              cancelButtonText: MESSAGES.M_BTN_CANCEL,
              focusConfirm: false,
              focusCancel: true,
              animation: true,
              showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
              showLoaderOnConfirm: false
            }).then(
              (result: any) => {
                if (result.value) {
                  Swal.fire({
                    title: MESSAGES.M_TIT_CONFIRM,
                    timer: 1200,
                    showConfirmButton: false,
                  });
                  this.router.navigate(['/auth/empresas/register', resultado.empresa.id]);
                  resolve(false);
                } else {
                  Swal.fire({
                    title: MESSAGES.M_TIT_CANCELADO,
                    timer: 1200,
                    showConfirmButton: false,
                    icon: 'error',
                    onOpen: () =>  {}
                  }).then(() => {}, () => {});
                  resolve(false);
                }
              }, () => {
                Swal.fire({
                  title: MESSAGES.M_TIT_CANCELADO,
                  timer: 1200,
                  showConfirmButton: false,
                  icon: 'error',
                  onOpen: () => {}
                }).then( () => {},  () => {});
              }
            );
        } else {
          resolve(false);
        }
      }, (error) => {
        resolve(false);
      });
    });
  }
}
