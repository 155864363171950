import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as usuarioActions from '../actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { Identity } from 'src/app/models/user';


@Injectable()
export class CatalogoEffects {

    constructor(
        private actions$: Actions,
        public usuarioService: UsuariosService,
        protected local: StorageMap
    ) {}


		@Effect()
		tiposUsuariosCatalogo$ = this.actions$.pipe(
			ofType(usuarioActions.LOAD_TYPE_USERS ),
			switchMap( (action: any) => {
				return this.usuarioService.getTypeUser()
					.pipe(
						map( (user: any) => {
							return new usuarioActions.LoadTypeUsersSuccess(user.tipos);
						}),
						catchError( error => of(new usuarioActions.LoadTypeUsersFail(error))  )
					);
			})
		);
	
		@Effect()
		tipoPermisosCatalogo$ = this.actions$.pipe(
			ofType(usuarioActions.LOAD_PERMISOS ),
			switchMap( (action: any) => {
				return this.usuarioService.getTypePermsiosUser(action.id)
					.pipe(
						map( (user: Identity) => {
							return new usuarioActions.LoadPermisosSuccess( user.permisos);
						}),
						catchError( error => of(new usuarioActions.LoadPermisosFail(error))  )
					);
			})
		);
	
		@Effect()
		saveTipoPermisosCatalogo$ = this.actions$.pipe(
			ofType(usuarioActions.SAVE_PERMISOS ),
			switchMap( (action: any) => {
				return this.usuarioService.saveTypePermsiosUser(action.tipo, action.permisos, action.suscripciones, action.tiposVer, action.tipos)
					.pipe(
						map( (respond: any) => {
							return new usuarioActions.SavePermisosSuccess(respond.tipo, action.permisos, action.suscripciones);
						}),
						catchError( error => of(new usuarioActions.SavePermisosFail(error))  )
					);
			})
		);
	
		@Effect()
		getSubscriptionsCatalogo$ = this.actions$.pipe(
			ofType(usuarioActions.LOAD_SUSCRIPCIONES ),
			switchMap( (action: any) => {
				return this.usuarioService.getSubscriptionsUser(action.tipo.id)
					.pipe(
						map( (respuesta: any) => {
							return new usuarioActions.LoadSuscripcionesSuccess(action.tipo, respuesta.suscripciones);
						}),
						catchError( error => of(new usuarioActions.LoadSuscripcionesFail(error))  )
					);
			})
		);

		@Effect()
    actualizaEstatusUsuario$ = this.actions$.pipe(
      ofType( usuarioActions.UPDATE_TYPE_USERS_STATUS ),
      switchMap( (action: any) => {
        return this.usuarioService.actualizaEstatusUsuario(action.tipo)
          .pipe(
              map( respuesta => {
                return new usuarioActions.UpdateTypeUsersStatusSuccess(action.tipo);
              }),
              catchError( error => of(new usuarioActions.UpdateTypeUsersStatusFail(error))  )
          );
      })
    );

		@Effect()
		tipoUsuariosCatalogoVer$ = this.actions$.pipe(
			ofType(usuarioActions.LOAD_TYPE_USERS_CONFIG ),
			switchMap( (action: any) => {
				return this.usuarioService.getTypeUserVer(action.id)
					.pipe(
						map( respuesta => {
							return new usuarioActions.LoadTypeUsersConfigSuccess( respuesta.tiposVer, respuesta.tipos);
						}),
						catchError( error => of(new usuarioActions.LoadTypeUsersConfigFail(error))  )
					);
			})
		);


		@Effect()
		tipoUsuariosCatalogo$ = this.actions$.pipe(
			ofType(usuarioActions.LOAD_TYPE_USER ),
			switchMap( (action: any) => {
				return this.usuarioService.getTypeUserEdit(action.id)
					.pipe(
						map( respuesta => {
							return new usuarioActions.LoadTypeUserSuccess(respuesta.tipo);
						}),
						catchError( error => of(new usuarioActions.LoadTypeUserFail(error))  )
					);
			})
		);

}
