<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" >
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card mb-3">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="p-3">
          <div class="form-group">
            <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize" (change)="cargarCapturas()">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="" class="control-label">Ordenar por</label>
            <select class="form-control custom-select" name="sortTypeProducto" #sortTypeProducto="ngModel" [(ngModel)]="paginacion.sortType" (change)="cargarCapturas()">
              <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
            </select>
          </div>
          <div class="v-options-menu vsm-default">
            <div class="vsm-list">
              <div class="vsm-header">Opciones</div>
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                <ngb-panel class="p-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <span class="vsm-title">Division</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let item of filterDivisiones">
                          <a class="vsm-link">
                            <span class="vsm-title">
                              <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="custom-checkbox custom-control">
                                      <input type="checkbox" id="selectCheckOpcionesDivision{{item.id}}" class="custom-control-input" name="selectCheckOpcionesDivision{{item.id}}" #selectCheckOpcionesDivision{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeDivision()">
                                      <label class="custom-control-label" for="selectCheckOpcionesDivision{{item.id}}">&nbsp;</label>
                                    </div>
                                  </div>
                                  <div class="widget-content-left flex2">
                                    <div class="widget-heading">{{item.nombre}}</div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9" [ngClass]="{'col-lg-12': !filterActive }" >
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="btn-actions-pane-left">
          <div class="row mt-3">
            <div class="form-group pr-4">
              <input type="text" placeholder="Fecha" class="form-control" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)"  [minDate]="minDate"
              [maxDate]="maxDate">
            </div>
            <div class="from-group" >
              <div class="btn-group-lg btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="filter_tipo">
                <label ngbButtonLabel class="btn-outline-primary">
                  <input ngbButton type="radio" [value]="1"> TNC
                </label>
                <label ngbButtonLabel class="btn-outline-primary">
                  <input ngbButton type="radio" [value]="2"> Bajas
                </label>
                <label ngbButtonLabel class="btn-outline-primary">
                  <input ngbButton type="radio" [value]="3"> Vacantes
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-actions-pane-right mr-4">
          ({{paginacion.totalFiltro}} de {{paginacion.totalSize}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchCaptura" id="searchCaptura" #searchCaptura="ngModel" [(ngModel)]="paginacion.textSearch" (ngModelChange)="changeTextSearch()">
                <button class="search-icon" (click)="searchClick()" for="searchCaptura"><span></span></button>
              </div>
              <button class="close" (click)="closeSearch()"></button>
            </div>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <button type="button" class="m-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearch" (click)="removerTextSearch(texto)">
          {{texto}}
          <i class="ml-1 fas fa-times"></i>
        </button>
        <div class="divider" *ngIf="listSearch.length"></div>
        <!-- <div class="card-body text-center"> -->
          <h5 *ngIf="filter_tipo === 1" class="card-title text-center">TNC {{anio}}</h5>
          <h5 *ngIf="filter_tipo === 2" class="card-title text-center">Bajas {{anio}}</h5>
          <h5 *ngIf="filter_tipo === 3" class="card-title text-center">Vacantes {{anio}}</h5>
          <div class="table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th class="text-center" (click)="sort('desCliente')" style="min-width: 200px !important;" > Cliente
                    <span *ngIf="paginacion.sortType === '-desCliente'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+desCliente'" class="fa fa-caret-up"></span> 
                  </th>
                  <th class="text-center" (click)="sort('desServicio')" style="min-width: 360px !important;" > Servicio
                    <span *ngIf="paginacion.sortType === '-desServicio'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+desServicio'" class="fa fa-caret-up"></span> 
                  </th>
                  <th class="text-center" (click)="sort('numeroOrden')" style="min-width: 100px !important;"> No. orden
                    <span *ngIf="paginacion.sortType === '-numeroOrden'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+numeroOrden'" class="fa fa-caret-up"></span> 
                  </th>
                  <th class="text-center" (click)="sort('elementos')" style="min-width: 130px !important;"> No. Elementos
                    <span *ngIf="paginacion.sortType === '-elementos'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+elementos'" class="fa fa-caret-up"></span> 
                  </th>
                  <th class="text-center" (click)="sort('desSucursal')" style="min-width: 180px !important;"> Sucursal
                    <span *ngIf="paginacion.sortType === '-desSucursal'" class="fa fa-caret-down"></span>
                    <span *ngIf="paginacion.sortType === '+desSucursal'" class="fa fa-caret-up"></span>
                  </th>
                  <th *ngFor="let dia of fechas">{{dia.dia | amDateFormat: 'DD/MM' }}</th>
                </tr>
              </thead>
              <tbody *ngFor="let resultado of resultados">
                <tr>
                  <td>{{resultado.desCliente}}</td>
                  <td>{{resultado.desServicio}}</td>
                  <td>{{resultado.numeroOrden}}</td>
                  <td>{{resultado.elementos}}</td>
                  <td>{{resultado.desSucursal}}</td>
                  <td class="text-center"  *ngFor="let dia of fechas">
                    <div  *ngFor="let resul of resultado.resultados">
                      <div [ngClass]="{'bg-warning': resul.falta > 0 && filter_tipo === 1}" *ngIf="dia.dia == resul.captura" (click) = "actulizar(resultado.numeroOrden,resultado.desServicio,resultado.id,resul, content, resul.captura)">
                        <span *ngIf="filter_tipo === 1">
                          {{resul.falta}}
                        </span>
                        <span  class="text-primary" *ngIf="filter_tipo === 2" [ngClass]="{'text-muted':  resul.captura != diaActual}">
                          {{resul.bajas}}
                        </span>
                        <span  class="text-primary" *ngIf="filter_tipo === 3" [ngClass]="{'text-muted':  resul.captura != diaActual}">
                          {{resul.vacantes}}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        <!-- </div> -->
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="card no-shadow bg-transparent text-left">
              <div class="widget-chart-content">
                <ngb-pagination class="mb-2" [boundaryLinks]="true"
                  [collectionSize]="paginacion.totalFiltro" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5" (pageChange)="cargarCapturas(false)">
                  <ng-template ngbPaginationFirst>First</ng-template>
                  <ng-template ngbPaginationLast>Last</ng-template>
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="text-right">
              <button type="button" (click)="crearExcel()" class="btn btn-primary mb-2" *ngIf="identity.permisos.asignacionListEdit && !downloading">
                <i class="fa fa-file-excel-o"></i>&nbsp; Descargar XLSX
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{nombre}}</h4>
  </div>
  <div class="modal-body">
    <form #registerForm="ngForm" (ngSubmit)="guardarResultado()" class="col-lg-12">
      <fieldset class="form-group">
        <div class="row">
          <div class="col-sm-4">
            <label for="fecha" class="control-label">Fecha</label>
          </div>
          <div class="col-sm-8">
            <input type="text" placeholder="Fecha" class="form-control" name="fecha" #fecha="ngModel" bsDatepicker [(ngModel)]="captura.fecha" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" disabled>
          </div>
        </div>
      </fieldset> 
      <fieldset class="form-group">
        <div class="row">
          <div class="col-sm-4">
            <label for="elementos" *ngIf="captura.tipo === 1" class="control-label">Turnos</label>
            <label for="elementos" *ngIf="captura.tipo !== 1" class="control-label">Elementos</label>
          </div>
          <div class="col-sm-8">
            <input type="number" name="elementos" #elementos="ngModel" [(ngModel)]="captura.elementos"  class="form-control" title="Trunos por día." disabled />
          </div>
        </div>
      </fieldset> 
      <fieldset class="form-group"  *ngIf="captura.tipo !== 1">
        <div class="row">
          <div class="col-sm-4">
            <label for="disponibilidad" class="control-label">Vacantes disponibles</label>
          </div>
          <div class="col-sm-8">
            <input type="number" name="disponibilidad" #disponibilidad="ngModel" [(ngModel)]="captura.disponibilidad"  class="form-control" title="Trunos por día." disabled />
          </div>
        </div>
      </fieldset> 
      <fieldset class="form-group required" *ngIf="captura.tipo === 1">
        <div class="row">
          <div class="col-sm-4">
            <label for="resultado" class="control-label">TNC</label>
          </div>
          <div class="col-sm-8">
            <input type="number" id="resultado" name="resultado" #resultado="ngModel" [(ngModel)]="captura.resultado" (blur)="validar()"
              class="form-control" min="0" max="captura.elementos" placeholder="" title="Capture el númerode tnc." required />
            <span *ngIf="!resultado.valid"> El número de tnc es obligatorio.</span>
          </div>
        </div>
      </fieldset>
      <fieldset class="form-group required" *ngIf="captura.tipo === 2">
        <div class="row">
          <div class="col-sm-4">
            <label for="resultado" class="control-label">Bajas</label>
          </div>
          <div class="col-sm-8">
            <input type="number" id="resultado" name="resultado" #resultado="ngModel" [(ngModel)]="captura.resultado" (blur)="validar()"
              class="form-control" min="0" max="captura.elementos" placeholder="" title="Capture el número de bajas." required/>
            <span *ngIf="!resultado.valid"> El número de bajas es obligatorio.</span>
          </div>
        </div>
      </fieldset>
      <fieldset class="form-group required" *ngIf="captura.tipo === 3">
        <div class="row">
          <div class="col-sm-4">
            <label for="resultado" *ngIf="captura.tipo === 3" class="control-label">Vacantes</label>
          </div>
          <div class="col-sm-8">
            <input type="number" id="resultado" name="resultado" #resultado="ngModel" [(ngModel)]="captura.resultado" (blur)="validar()"
              class="form-control" min="0" max="captura.elementos" placeholder="" title="Capture el número de vacantes." required/>
            <span *ngIf="!resultado.valid"> El número de vacantes es obligatorio.</span>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cerrar</button>
    <button type="button" class="btn btn-primary"  (click)="guardarResultado()" [disabled]="!registerForm.form.valid || loading || !identity.permisos.asignacionCapturar">Guardar cambios</button>
  </div>
</ng-template>






