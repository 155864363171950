import * as fromUsuario from '../actions';
import { Catalogo } from 'src/app/models/catalogo';
import { Empresa } from 'src/app/models/empresa';
import { User } from 'src/app/models/user';
import { Contacto } from 'src/app/models/contacto';
import { Oportunidad } from 'src/app/models/oportunidades';
import { Origen } from 'src/app/models/origen-contacto';
import { Industria } from 'src/app/models/industrias';
import { UsoCfdi } from 'src/app/models/uso-cfdi';

export interface ConsutaState {
  cargandoEmpresas: boolean;
  contactosEmpresa: Catalogo[];
  oportunidadesEmpresa: Oportunidad[];
  etapas: Catalogo[];
  industrias: Industria[];
  contactos: Contacto[];
  paises: Catalogo[];
  clasificaciones: Catalogo[];
  empresas: Empresa[];
  usuarios: User[];
  estadosContacto: Catalogo[];
  etapasContacto: Catalogo[];
  origenContacto: Origen[];
  tipoOrigen: Catalogo[];
  oportunidadesContacto: Oportunidad[];
  
  contactosOportunidad: Contacto[];
  oportunidadesGandas: number;
  oportunidadesPerdidas: number;
  usosCfdis: UsoCfdi[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: ConsutaState = {
  cargandoEmpresas: false,
  contactosEmpresa: [],
  oportunidadesEmpresa: [],
  etapas: [],
  contactos: [],
  paises: [],
  clasificaciones: [],
  industrias: [],
  empresas: [],
  usuarios: [],
  estadosContacto: [],
  etapasContacto: [],
  origenContacto: [],
  tipoOrigen: [],
  oportunidadesContacto: [],
  
  contactosOportunidad: [],
  oportunidadesGandas: 0,
  oportunidadesPerdidas: 0,
  usosCfdis: [],
  loaded: false,
  loading: false,
  error: null
};

export function consultasReducer( state = initState, action: fromUsuario.consultasAcciones ): ConsutaState {

  switch ( action.type ) {
    case fromUsuario.CONSULTA_INDUSTRIAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_INDUSTRIAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        industrias: [...action.industrias]
      };
    case fromUsuario.CONSULTA_INDUSTRIAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.CONSULTA_EMPRESAS:
      return {
        ...state,
        cargandoEmpresas: true,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_EMPRESAS_SUCCESS:
      return {
        ...state,
        loading: false,
        cargandoEmpresas: false,
        loaded: true,
        empresas: [...action.empresas]
      };
    case fromUsuario.CONSULTA_EMPRESAS_FAIL:
      return {
        ...state,
        cargandoEmpresas: false,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CONSULTA_USUARIOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_USUARIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        usuarios: [...action.usuarios]
      };
    case fromUsuario.CONSULTA_USUARIOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CONSULTA_CONTACTOS_EMPRESA:
      return {
        ...state,
        cargandoEmpresas: true,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_CONTACTOS_EMPRESA_SUCCESS:
      return {
        ...state,
        cargandoEmpresas: false,
        loading: false,
        loaded: true,
        contactosEmpresa: [...action.contactos]
      };
    case fromUsuario.CONSULTA_CONTACTOS_EMPRESA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_EMPRESA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_EMPRESA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        oportunidadesEmpresa: [...action.oportunidades]
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_EMPRESA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CONSULTA_CONTACTOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_CONTACTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        contactos: [...action.contactos]
      };
    case fromUsuario.CONSULTA_CONTACTOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_CONTACTOS_EMPRESA:
      return {
        ...state,
				contactosEmpresa: []
      };
    case fromUsuario.CONSULTA_ETAPAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_ETAPAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        etapas: [...action.etapas]
      };
    case fromUsuario.CONSULTA_ETAPAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.CONSULTA_PAISES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_PAISES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        paises: [...action.paises]
      };
    case fromUsuario.CONSULTA_PAISES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.CONSULTA_CLASIFICACIONES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_CLASIFICACIONES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        clasificaciones: [...action.clasificaciones]
      };
    case fromUsuario.CONSULTA_CLASIFICACIONES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.CONSULTA_ETAPAS_CONTACTOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_ETAPAS_CONTACTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        etapasContacto: [...action.etapas]
      };
    case fromUsuario.CONSULTA_ETAPAS_CONTACTOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.CONSULTA_ESTADOS_CONTACTOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_ESTADOS_CONTACTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        estadosContacto: [...action.estados]
      };
    case fromUsuario.CONSULTA_ESTADOS_CONTACTOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.CONSULTA_ORIGEN_CONTACTOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_ORIGEN_CONTACTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        origenContacto: [...action.origen]
      };
    case fromUsuario.CONSULTA_ORIGEN_CONTACTOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.CONSULTA_TIPOS_ORIGEN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_TIPOS_ORIGEN_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tipoOrigen: [...action.tipo]
      };
    case fromUsuario.CONSULTA_TIPOS_ORIGEN_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_CONTACTO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_CONTACTO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        oportunidadesContacto: [...action.oportunidades]
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_CONTACTO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
      case fromUsuario.CONSULTA_CONTACTOS_OPORTUNIDAD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_CONTACTOS_OPORTUNIDAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        contactosOportunidad: [...action.contactos]
      };
    case fromUsuario.CONSULTA_CONTACTOS_OPORTUNIDAD_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_EMPRESA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_EMPRESA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        oportunidadesGandas: action.ganados,
        oportunidadesPerdidas: action.perdidos
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_EMPRESA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_CONTACTO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_CONTACTO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        oportunidadesGandas: action.ganados,
        oportunidadesPerdidas: action.perdidos
      };
    case fromUsuario.CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_CONTACTO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CONSULTA_USOS_CFDIS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CONSULTA_USOS_CFDIS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        usosCfdis: [...action.usosCfdis]
      };
    case fromUsuario.CONSULTA_USOS_CFDIS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    default:
      return state;
  }
}
