import { Catalogo } from './catalogo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export class Actividad{
	public id: number;
	public descripcion: string;
	public idUsuario: number;


	constructor(
		obj: DataObjActividad
	){
		this.id = obj && obj.id? obj.id :null;
		this.descripcion = obj && obj.descripcion? obj.descripcion : '';
		this.idUsuario = obj && obj.idUsuario? obj.idUsuario :null;

	}
}
interface DataObjActividad{
	id: number;
	descripcion: string;
	idUsuario: number;
}

export class ActividadMultiple{
	public id: number;
	public descripcion: string;
	public concepto: number;
	public status: boolean;
	public fecRegistro: Date;
	public usuarioRegistro: number;
	public desUsuarioRegistro: string;
	public fecModifico: Date;
	public usuarioModifico: number;
	public desUsuarioModifico: string;
	public contactos: Catalogo[];
	public empresa: number;
	public desEmpresa: string;
	public oportunidades: Catalogo[];
	public resultado: string;
	public fecha: Date;
	public hora: NgbTimeStruct;
	public duracion: NgbTimeStruct;
	constructor(
		obj: DataObjActividadMultiple
	){
		this.id = obj && obj.id ? obj.id : 0;
		this.descripcion = obj && obj.descripcion ? obj.descripcion : '';
		this.concepto = obj && obj.concepto ? obj.concepto : 0;
		this.contactos = obj && obj.contactos ? obj.contactos : [];
		this.empresa = obj && obj.empresa ? obj.empresa : null;
		this.status = obj && obj.status ? obj.status: false;
		this.desEmpresa = obj && obj.desEmpresa ? obj.desEmpresa :'';
		this.oportunidades = obj && obj.oportunidades ? obj.oportunidades : [];
		this.status = obj && obj.status ? obj.status: false;
		this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : new Date ();
		this.usuarioRegistro = obj && obj.usuarioRegistro ? obj.usuarioRegistro : 0;
		this.desUsuarioRegistro = obj && obj.desUsuarioRegistro ? obj.desUsuarioRegistro : '';
		this.fecModifico = obj && obj.fecModifico ? obj.fecModifico : new Date ();
		this.usuarioModifico = obj && obj.usuarioModifico ? obj.usuarioModifico : 0;
		this.desUsuarioModifico = obj && obj.desUsuarioModifico ? obj.desUsuarioModifico: '';
		this.resultado = obj && obj.resultado ? obj.resultado : null;
		this.fecha = obj && obj.fecha ? obj.fecha : null;
		this.hora = obj && obj.hora ? obj.hora : { hour: 0, minute: 0, second: 0 };
		this.duracion = obj && obj.duracion ? obj.duracion : { hour: 0, minute: 0, second: 0 };
	}
}

interface DataObjActividadMultiple{
id: number;
descripcion: string;
concepto: number;
status: boolean;
fecRegistro: Date;
usuarioRegistro: number;
desUsuarioRegistro: string;
fecModifico: Date;
usuarioModifico: number;
desUsuarioModifico: string;
contactos: Catalogo[];
empresa: number;
desEmpresa: string;
oportunidades: Catalogo[];
resultado: string;
fecha: Date;
hora: NgbTimeStruct;
duracion: NgbTimeStruct;
}

export const LlamadaCatalogo: Catalogo[] = [
	{ id: 1, nombre: 'Ocupado', status: true},
	{ id: 2, nombre: 'Contactado', status: true},
	{ id: 4, nombre: 'Dejó un mensaje de voz', status: true},
	{ id: 5, nombre: 'Sin respuesta', status: true},
	{ id: 6, nombre: 'Número incorrecto', status: true}
];

export const ReunionCatalogo: Catalogo[] = [
	{ id: 1, nombre: 'Ninguna', status: true},
	{ id: 2, nombre: 'Programadas', status: true},
	{ id: 3, nombre: 'Completadas', status: true},
	{ id: 4, nombre: 'Reprogramadas', status: true},
	{ id: 5, nombre: 'No asistió', status: true},
	{ id: 6, nombre: 'Canceladas', status: true}
];
