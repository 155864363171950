import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { ReporteAsignacionService } from 'src/app/services/reporte-asignacion.service';

@Injectable()
export class ReporteAsignacionEffects {

  constructor(
    private actions$: Actions,
    public reporteAsignacionService: ReporteAsignacionService
  ) {}

  @Effect()
  cargarReporteAsignacionDia$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_ASIGNACION_DIA ),
    switchMap( (action: any) => {
      return this.reporteAsignacionService.obtenerReporteAsignacionDia(action.fecha)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarReporteAsignacionesDiaSuccess(respuesta.reporte);
            }),
            catchError( error => of(new usuariosActions.CargarReporteAsignacionesDiaFail(error))  )
        );
    })
  );



  @Effect()
  cargarReporteCoberturaOperacional$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_COBERTURA_OPERACIONAL ),
    switchMap( (action: any) => {
      return this.reporteAsignacionService.obtenerReporteCoberturaOperacional(action.fecha)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarReporteCoberturaOperacionalSuccess(respuesta.reporte);
            }),
            catchError( error => of(new usuariosActions.CargarReporteCoberturaOperacionalFail(error))  )
        );
    })
  );

  @Effect()
  cargarReporteCoberturaOperacionalServicio$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_COBERTURA_OPERACIONAL_SERVICIO ),
    switchMap( (action: any) => {
      return this.reporteAsignacionService.obtenerReporteCoberturaOperacionalServicio(action.fecha)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarReporteCoberturaOperacionalServicioSuccess(respuesta.reporte);
            }),
            catchError( error => of(new usuariosActions.CargarReporteCoberturaOperacionalServicioFail(error))  )
        );
    })
  );

  @Effect()
  cargarReporteServicioInicio$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_SERVICIO_INICIO ),
    switchMap( (action: any) => {
      return this.reporteAsignacionService.obtenerReporteServicioInicia(action.fecha)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarReporteServicioInicioSuccess(respuesta.reporte , respuesta.division);
            }),
            catchError( error => of(new usuariosActions.CargarReporteServicioInicioFail(error))  )
        );
    })
  );


  @Effect()
  cargarReporteServicioFinalizo$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_SERVICIO_FINALIZO ),
    switchMap( (action: any) => {
      return this.reporteAsignacionService.obtenerReporteServicioFinaliza(action.fecha)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarReporteServicioFinalizoSuccess(respuesta.reporte , respuesta.division);
            }),
            catchError( error => of(new usuariosActions.CargarReporteServicioFinalizoFail(error))  )
        );
    })
  );

  @Effect()
  cargarReporteBajaVacantes$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_BAJA_VACANTES ),
    switchMap( (action: any) => {
      return this.reporteAsignacionService.obtenerReporteBajasVacantes(action.fecha)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarReporteBajaVacanteSuccess(respuesta.reporte);
            }),
            catchError( error => of(new usuariosActions.CargarReporteBajaVacanteFail(error))  )
        );
    })
  );


}
