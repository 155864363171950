import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mimeFontawesome'
})
export class MimeFontawesomePipe implements PipeTransform {

  transform(mimeType: string): string {
    return this.getFontAwesomeIconFromMIME(mimeType);
  }

  getFontAwesomeIconFromMIME(mimeType: string) {
    // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
    const iconClasses = {
      // Media
      image: 'fas fa-file-image',
      audio: 'fas fa-file-audio',
      video: 'fas fa-file-video',
      // Documents
      'application/pdf': 'fas fa-file-pdf',
      'application/msword': 'fas fa-file-word',
      'application/vnd.ms-word': 'fas fa-file-word',
      'application/vnd.oasis.opendocument.text': 'fas fa-file-word',
      'application/vnd.openxmlformatsfficedocument.wordprocessingml': 'fas fa-file-word',
      'application/vnd.ms-excel': 'fas fa-file-excel',
      'application/vnd.openxmlformatsfficedocument.spreadsheetml': 'fas fa-file-excel',
      'application/vnd.oasis.opendocument.spreadsheet': 'fas fa-file-excel',
      'application/vnd.ms-powerpoint': 'fas fa-file-powerpoint',
      'application/vnd.openxmlformatsfficedocument.presentationml':'file-powerpoint',
      'application/vnd.oasis.opendocument.presentation': 'fas fa-file-powerpoint',
      'text/plain': 'fas fa-file-alt',
      'text/html': 'fas fa-file-code',
      'application/json': 'fas fa-file-code',
      'application/vnd.openxmlformats-officedocument.wordprocessingml': 'fas fa-file-word-o',
      'application/vnd.openxmlformats-officedocument.spreadsheetml': 'fas fa-file-excel-o',
      'application/vnd.openxmlformats-officedocument.presentationml': 'fas fa-file-powerpoint-o',
      // Archives
      'application/gzip': 'fas fa-file-archive',
      'application/zip': 'fas fa-file-archive',
      'application/x-zip-compressed': 'fas fa-file-archive'
    };

    for (const key in iconClasses) {
      if (iconClasses.hasOwnProperty(key)) {
        if (mimeType.search(key) === 0) {
          return iconClasses[key];
        }
      } else {
        return 'fa fa-file';
      }
    }
  }

}
