import { Component, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Reporte, Excel } from 'src/app/models/reporte-actividades';
import { GLOBAL } from 'src/app/models/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ExcelService} from 'src/app/services/excel.service';
import { Fechas } from 'src/app/models/fechas';
import { DatePipe } from '@angular/common';
import { Departamento } from 'src/app/models/departamento';
import { Pagination } from 'src/app/models/pagination';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-reporte-actividades',
  templateUrl: './reporte-actividades.component.html',
  styleUrls: ['./reporte-actividades.component.sass'],
})

export class ReporteActividadesComponent implements OnInit, OnDestroy {
	heading = 'Reporte de actividades';
  	subheading = 'Todas las actividades registradas en el mes';
  	icon = 'pe-7s-note2 icon-gradient bg-happy-fisher';
	public datePipe = new DatePipe("en-US");
	public fechas :Fechas;
	public minDate: Date;
	public maxDate: Date;
	public reportes: Reporte[] = [];
	public excel: Excel[] = [];
	public dates: Date[] = [new Date(), new Date( new Date().getDate() + 7) ];
	public url = '';
	public dias: Fechas;
	public fechaIniPdf;
	public fechaFinPdf;
	public filtro_area: number;
	public identity: Identity;
	public departamentos:Departamento[] = [];
	public paginacion = new Pagination(null);
	public subcriptionIdentity: Subscription = new Subscription();
  	public subcriptionCumplimineto: Subscription = new Subscription();
  constructor(
    private modalService: NgbModal,
    private excelService:ExcelService,
    private store: Store<AppState>,
	) { 
	const fecha = new Date();
	fecha.setDate(fecha.getDate() - 7)
	this.dates = [ fecha, new Date()];
	this.url = GLOBAL.url;
	this.dias = new Fechas(null);
	this.paginacion.sortType = '-id';
	this.identity = new Identity(null);
  }

  ngOnInit() {
	this.minDate = new Date();
	this.maxDate = new Date();
	this.minDate.setDate(this.minDate.getDate() - 31);
	this.maxDate.setDate(this.maxDate.getDate());
	this.subcriptionIdentity = this.store.select('auth').subscribe(
	  (state: AuthState) => {
		this.identity = {...state.identity};
		if (this.identity.departamentos.length > 0){
			this.filtro_area = this.identity.departamentos[0].id;
		}
	  }
	);
	this.subcriptionCumplimineto = this.store.select('cumplimiento').subscribe(
	  (state) => {
		// this.reportes = [...state.reporte];
		this.reportes = [...state.reporte.filter( item => item.actividades.length > 0)];
		this.departamentos = [... state.areas];
		this.excel = [...state.excel];
	  }
	);
  }


  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionCumplimineto.unsubscribe();
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

	fecha(fecha:Date[]){
	  if(fecha && fecha.length === 2){
		const fecha1= this.datePipe.transform(fecha["0"], 'yyyy-MM-dd')
		const fecha2= this.datePipe.transform(fecha["1"], 'yyyy-MM-dd');
		this.fechaIniPdf= this.datePipe.transform(fecha["0"], 'dd/MM/yyyy');
		this.fechaFinPdf= this.datePipe.transform(fecha["1"], 'dd/MM/yyyy');
		this.dias = {fechaInicio: fecha1, fechaFinal: fecha2}
		this.store.dispatch(new usuariosActions.CargarReporteActividades(this.dias));
	  }
	}

	openLarge(content) {
    this.modalService.open(content, {
      size: 'lg'
    });
	}

	exportAsXLSX():void {
	  let excel;
//  const htmlContentWithout=document.getElementById("htmlDiv").innerHTML.replace(/<[^>]*>/g, ' ');
//   const fg = htmlContentWithout.replace(/&nbsp;/g, ' ') ;
	  excel = this.excel.map( item => {
		const obj: any = {};
		obj.Nombre = item.nombre;
		obj.Área = item.area;
		obj.Fecha = item.fecha;
		obj.Hora = item.hora;
		let html = item.actividad.replace(/<br>/g, ' ');
		html = html.replace(/<[^>]*>/g, '');
		let ascii  = html.replace(/&#(\d+);/g, function(match, match2) {return String.fromCharCode(+match2);});
		 ascii  = ascii.replace(/&[\w\d]+\;/g, function(match, match2) {return String.fromCharCode(+match2);});
		// let menor = mayor.replace(/&lt;/g, '<'); 
		// "foo&#39;s bar".
		// let espcacios = enter.replace(/&nbsp;/g, ' ');
		obj.Actividad = ascii ;
		return obj;
	  });
	  this.excelService.exportAsExcelFile(excel, 'Reporte actividades');
	}



  
}