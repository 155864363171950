import * as fromToastr from '../actions/toastr.actions';

export enum TypeAlert {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info'
}

export const TOASTR_CONFIG = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    preventDuplicates: true,
    onclick: null,
    showDuration: '100',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'show',
    hideMethod: 'hide'
};

export interface ToastrState {
    message: string;
    title: string;
    status: any;
    code: any;
    type: TypeAlert;
    config: any;
}

const initState: ToastrState = {
    message: '',
    title: '',
    status: null,
    code: null,
    type: TypeAlert.INFO,
    config: TOASTR_CONFIG
};

export function toastrReducer( state = initState, action: fromToastr.toastrAcciones ): ToastrState {

    switch ( action.type ) {

        case fromToastr.SUCCESS_TOASTR:
            return {
                ...state,
                message: action.message,
                title: action.title,
                status: action.status,
                code: action.code,
                type: TypeAlert.SUCCESS
            };

        case fromToastr.ERROR_TOASTR:
            return {
                ...state,
                message: action.message,
                title: action.title,
                status: action.status,
                code: action.code,
                type: TypeAlert.ERROR
            };

        case fromToastr.WARN_TOASTR:
            return {
                ...state,
                message: action.message,
                title: action.title,
                status: action.status,
                code: action.code,
                type: TypeAlert.WARNING
            };

        case fromToastr.INFO_TOASTR:
            return {
                ...state,
                message: action.message,
                title: action.title,
                status: action.status,
                code: action.code,
                type: TypeAlert.INFO
            };

        case fromToastr.CLEAR_TOASTR:
            return {
                ...state
            };

        default:
            return state;
    }
}
