import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { IndicadoresService } from 'src/app/services/indicadores.service';

@Injectable()
export class IndicadoresEffects {

  constructor(
    private actions$: Actions,
    public indicadoresService: IndicadoresService
  ) {}

  @Effect()
  cargarIndicadores$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_INDICADORES ),
    switchMap( () => {
      return this.indicadoresService.obtenerIndicadores()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarIndicadoresSuccess(respuesta.indicador);
          }),
          catchError( error => of(new usuariosActions.CargarIndicadoresFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusIndicadores$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_INDICADOR_ESTATUS ),
    switchMap( (action: any) => {
      return this.indicadoresService.actualizaEstado(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaIndicadorEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaIndicadorEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaIndicadores$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_INDICADOR ),
    switchMap( (action: any) => {
      return this.indicadoresService.registraIndicador(action.indicador, action.accion,action.usuario)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaIndicadorSuccess(action.indicador);
            }),
            catchError( error => of(new usuariosActions.ActualizaIndicadorFail(error))  )
        );
    })
  );

  @Effect()
  cargarTipoIndicadores$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_TIPO_INDICADOR ),
    switchMap( () => {
      return this.indicadoresService.obtenerTiposIndicador()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarTipoIndicadorSuccess(respuesta.tipo_indicador);
          }),
          catchError( error => of(new usuariosActions.CargarTipoIndicadorFail(error))  )
        );
    })
  );

  @Effect()
  cargarIndicador$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_INDICADOR ),
    switchMap( (action: any) => {
      return this.indicadoresService.obtenerIndicador(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarIndicadorSuccess(respuesta.indicador);
            }),
            catchError( error => of(new usuariosActions.CargarIndicadorFail(error))  )
        );
    })
  );


}
