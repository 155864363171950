import { Component, OnInit, OnDestroy } from '@angular/core';
import { Fechas } from 'src/app/models/fechas';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { IndicadoresService } from 'src/app/services/indicadores.service';
import { Pagination } from 'src/app/models/pagination';
import { ReporteArea, Nombre } from 'src/app/models/tablero-kpi';
import { Subscription } from 'rxjs';
import { Identity } from 'src/app/models/user';
import { AuthState } from 'src/app/store/reducers';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-tablero-kpis-area',
  templateUrl: './tablero-kpis-area.component.html',
  styleUrls: ['./tablero-kpis-area.component.sass']
})
export class TableroKpisAreaComponent implements OnInit, OnDestroy {
  heading = 'Tablero de control KPIs';
  subheading = 'Resultado de las captura de los indicador detallado por área';
  icon = ' pe-7s-graph icon-gradient bg-happy-fisher';
  public dias: Fechas;
  public datePipe = new DatePipe("en-US");
  public indicadores:ReporteArea[] = [];
  public existe: boolean;
  public area: Nombre[] = [];
  public tipo: Nombre[] = [];
  public indicador: Nombre[] = [];
  public filtro_area;
  public filtro_tipo;
  public filtro_indicador;
  public activar_filtro;
  public filtro;
  public form;
  public paginacion = new Pagination(null);
  public identity: Identity;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionTableroKpi: Subscription = new Subscription();
  constructor(
    private indicadoresService :IndicadoresService,
    private toastrService: ToastrService,
    private store: Store<AppState>,
  ) { 
    this.dias = new Fechas(null);
    this.existe = false;
    this.paginacion.sortType = '-id';
    this.filtro = false;
    this.activar_filtro = false;
    this.store.dispatch(new usuariosActions.LimpiarReporteKpiAreas());
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionTableroKpi = this.store.select('tableroKpi').subscribe(
      (state) => {
        this.indicadores = [...state.Reporteareas];
        this.area = [...state.area];
        this.tipo = [...state.tipo];
        this.indicador = [...state.indicador];
        if(this.indicadores.length > 0){
          this.existe = true;
          this.filtro = true;
        }
      }
    );
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionTableroKpi.unsubscribe();
  }


  fecha(fecha){
    
    const fecha1= this.datePipe.transform(fecha["0"], 'yyyy-MM-dd');
		const fecha2= this.datePipe.transform(fecha["1"], 'yyyy-MM-dd');
		this.dias = {fechaInicio: fecha1, fechaFinal: fecha2}
    this.store.dispatch(new usuariosActions.CargarReporteKpiAreas(this.dias));
  }

  sortData(sort) {
    const data = this.indicadores.slice();
    if (!sort.active || sort.direction === '') {
      this.indicadores = data;
      return;
    }

    this.indicadores = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'area': return this.compare(a.area, b.area, isAsc);
        case 'tipo': return this.compare(a.tipo, b.tipo, isAsc);
        case 'indicador': return this.compare(a.indicador, b.indicador, isAsc);
        case 'objetivo': return this.compare(a.objetivo, b.objetivo, isAsc);
        case 'resultado': return this.compare(a.resultado_final, b.resultado_final, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  activarFiltro(){
    if(this.activar_filtro === false){
      this.activar_filtro = true;
      this.filtro = false;
    }
    else{
      this.activar_filtro = false;
      this.filtro = true;
    }
    
  }



}
