import { Action } from '@ngrx/store';
import { Servicio } from 'src/app/models/servicios';

export const CARGAR_SERVICIOS = '[Servicio] Cargar servicio';
export const CARGAR_SERVICIOS_FAIL = '[Servicio] Cargar servicio FAIL';
export const CARGAR_SERVICIOS_SUCCESS = '[Servicio] Cargar servicio SUCCESS';
export const ACTUALIZA_SERVICIO = '[Servicio] Actualiza servicio';
export const ACTUALIZA_SERVICIO_FAIL = '[Servicio] Actualiza servicio FAIL';
export const ACTUALIZA_SERVICIO_SUCCESS = '[Servicio] Actualiza servicio SUCCESS';
export const ACTUALIZA_SERVICIO_ESTATUS = '[Servicio] Actualiza servicio estatus';
export const ACTUALIZA_SERVICIO_ESTATUS_FAIL = '[Servicio] Actualiza servicio estatus FAIL';
export const ACTUALIZA_SERVICIO_ESTATUS_SUCCESS = '[Servicio] Actualiza servicio estatus SUCCESS';

export class CargarServicios implements Action {
  readonly type = CARGAR_SERVICIOS;
}

export class CargarServiciosFail implements Action {
  readonly type = CARGAR_SERVICIOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarServiciosSuccess implements Action {
  readonly type = CARGAR_SERVICIOS_SUCCESS;

  constructor( public servicios: Servicio[] ) {}
}

export class ActualizaServicio implements Action {
  readonly type = ACTUALIZA_SERVICIO;

  constructor( public servicio: Servicio ) { }
}

export class ActualizaServicioFail implements Action {
  readonly type = ACTUALIZA_SERVICIO_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaServicioSuccess implements Action {
  readonly type = ACTUALIZA_SERVICIO_SUCCESS;

  constructor( public servicio: Servicio ) {}
}

export class ActualizaServicioEstatus implements Action {
  readonly type = ACTUALIZA_SERVICIO_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaServicioEstatusFail implements Action {
  readonly type = ACTUALIZA_SERVICIO_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaServicioEstatusSuccess implements Action {
  readonly type = ACTUALIZA_SERVICIO_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export type servicioAcciones = CargarServicios 
                                | CargarServiciosFail 
                                | CargarServiciosSuccess 
                                | ActualizaServicio 
                                | ActualizaServicioFail 
                                | ActualizaServicioSuccess 
                                | ActualizaServicioEstatus 
                                | ActualizaServicioEstatusFail 
                                | ActualizaServicioEstatusSuccess;

