import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Identity } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GENERALES } from 'src/app/models/generales';
import { GLOBAL } from 'src/app/models/global';
import { ArchivoConcepto } from 'src/app/models/archivo';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import * as usuariosActions from 'src/app/store/actions';
import { Oportunidad } from 'src/app/models/oportunidades';
import { Contacto } from 'src/app/models/contacto';

@Component({
  selector: 'app-oportunidad-profile-actions',
  templateUrl: './oportunidad-profile-actions.component.html',
  styleUrls: ['./oportunidad-profile-actions.component.sass']
})
export class OportunidadProfileActionsComponent implements OnInit, OnDestroy {
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subcriptionArchivos: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private subcriptionOportunidad: Subscription = new Subscription();
  private token: string = '';
  public filesToUpload:	File[] = [];
  public textFile: string = '';
  public archivos: ArchivoConcepto[] = [];
  public adjunto:	ArchivoConcepto;
  public url: string = '';
  public id: number = 0;
  public concepto: number = 0;
  public contactos: Contacto[] = [];
  public oportunidad: Oportunidad;
  constructor(
    private authService: AuthService,
		private store: Store<AppState>,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.url = GLOBAL.url;
    this.adjunto = new ArchivoConcepto(null);
    this.store.dispatch(new usuariosActions.LimpiarArchivos());
    this.concepto = 3;
    this.oportunidad = new Oportunidad(null);
    this.contactos = [];
   }

  ngOnInit() {
    this.authService.getToken().subscribe(
      (request) => {
        this.token = request;
      }
    );
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
		    this.identity = {...state.identity};
      }
    );
    this.subcriptionArchivos = this.store.select('actividadArchivos').subscribe(
      (state) => {
        this.archivos = [...state.archivos];
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.contactos = [...state.contactosOportunidad.filter(item => item.status)];
      }
    );
    this.subcriptionOportunidad = this.store.select('oportunidad').subscribe(
      (state) => {
        this.oportunidad = {...state.oportunidad};
      }
    );
    this.route.params.subscribe(
      (params) => {
        this.id = params.id;
        if (this.id > 0) {
          this.store.dispatch(new usuariosActions.CargarArchivos(this.id, this.concepto));
          this.store.dispatch(new usuariosActions.ConsultaContactosOportunidad(this.id));
        }
    });
  }



  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionArchivos.unsubscribe();
    this.subscribeConsulta.unsubscribe();
    this.subcriptionOportunidad.unsubscribe();
  }
  
  fileChangeEvent(event: any) {
    this.filesToUpload = <Array<File>>event.target.files;
    this.adjunto.file = <Array<File>>event.target.files;
    if (this.filesToUpload.length) {
      this.adjunto.tipo = this.filesToUpload[0].type.split('/').pop();
      this.adjunto.size = this.filesToUpload[0].size;
      if (!(this.adjunto.tipo === 'jpeg' || this.adjunto.tipo === 'png' || this.adjunto.tipo === 'gif' || this.adjunto.tipo === 'pdf')) {
        this.adjunto.tipo = '';
        this.textFile = '';
        this.filesToUpload = [] ;
      }
    } else {
      this.adjunto.tipo = '';
      this.filesToUpload = [];
    }
  }


  addFile() {
    if (this.adjunto.nombre !== '' && this.filesToUpload.length && this.adjunto.tipo !== '') {
      const itm = this.archivos.find( item => item.nombre === this.adjunto.nombre && item.tipo === this.adjunto.tipo);
      this.adjunto.folio = this.id;
      this.adjunto.concepto = this.concepto;
      if (!(itm && itm.id)) {
        this.store.dispatch(new usuariosActions.RegistrarArchivo(this.url + '/archivos/', [], this.filesToUpload, this.token, 'files', this.adjunto));
        this.modalService.dismissAll('Close');
      } 
    }
  }
  
  
  deleteFile(file: ArchivoConcepto) {
    this.store.dispatch(new usuariosActions.EliminarArchivo(this.id, file));
  }
  
  open(content) {
    this.textFile = '';
    this.adjunto = new ArchivoConcepto(null); 
    this.modalService.open(content, {
      size: 'lg'
    });
  }

}
