import { NgModule } from '@angular/core';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { RestoreComponent } from './restore/restore.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { NgxLoadingModule } from 'ngx-loading';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
// import { NouisliderModule } from 'ng2-nouislider';
import { SlickCarouselModule } from 'ngx-slick-carousel';


import {HomeComponent} from './home/home.component';
import {ForgotPasswordBoxedComponent} from './forgot-password-boxed/forgot-password-boxed.component';
import {LoginBoxedComponent} from './login-boxed/login-boxed.component';
import {RegisterBoxedComponent} from './register-boxed/register-boxed.component';
import {RegisterComponent} from './register/register.component';


@NgModule({
  declarations: [
    ForgotPasswordComponent,
    LoginComponent,
    RecoveryComponent,
    RestoreComponent,
    HomeComponent,
    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    RegisterBoxedComponent,
    RegisterComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    AppRoutingModule,
    NgxLoadingModule,
    NgSelectModule,
    NgbModule,
    // MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    // NouisliderModule,
    SlickCarouselModule
  ],
  exports: [
    ForgotPasswordComponent,
    LoginComponent,
    RecoveryComponent,
    RestoreComponent,
    HomeComponent,
    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    RegisterBoxedComponent,
    RegisterComponent,
  ]
})
export class PublicModule { }
