import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Indicador } from '../models/indicador';
import { Observable } from 'rxjs';
import { Fechas } from 'src/app/models/fechas';

@Injectable({
  providedIn: 'root'
})
export class IndicadoresService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  registraIndicador(indicadorToRegister: Indicador, accion: number,usuario): Observable<any> {
		const params = JSON.stringify({indicador: indicadorToRegister, accion,usuario});
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/indicador', params, {headers});
  }
  
  obtenerTiposIndicador(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
  
    return this.http.get(this.url + '/indicador/tipo', {headers});
   }


   obtenerIndicadores(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
  
    return this.http.get(this.url + '/indicador', {headers});
   }

   obtenerIndicador(id: number): Observable<any> {
		const headers = new HttpHeaders().set('Content-type', 'application/json');
		
		return this.http.get(this.url + '/indicador/' + id, {headers});
	 }

   actualizaEstado(id): Observable<any> {
    const params = JSON.stringify(id);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.put(this.url + '/indicador', params, {headers});
   }

}