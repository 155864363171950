import {Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../services/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { Identity } from 'src/app/models/user';
import * as usuariosActions from 'src/app/store/actions';
import { ToastrService } from 'ngx-toastr';
import { AuthState } from 'src/app/store/reducers';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styles: []
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  cargando: boolean;
  subscription: Subscription = new Subscription();
  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: false,
  };

  constructor(
    public authService: AuthService,
    public store: Store<AppState>,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.subscription = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.cargando = state.loading;
      }
    );
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }

  onSubmit( data: any ) {
    if (!data.newPassword || !data.passwordRep || data.newPassword !== data.passwordRep) {
      this.toastr.warning('Las contraseñas no coinciden');
      return;
    }
    this.store.dispatch(new usuariosActions.ChangePasswordAuth(data.oldPassword, data.newPassword));
  }

}
