import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Servicio } from '../models/servicios';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }
  
  obtenerServicios(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/servicio', {headers});
  }

  actualizaEstatusServicio(servicio: Servicio): Observable<any> {
    const param = JSON.stringify({servicio});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/servicio/estatus', param, {headers});
  }

  registraServicio(servicio: Servicio): Observable<any> {
    const param = JSON.stringify({servicio});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/servicio', param, {headers});
  }

  obtenerServiciosActivos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
  
    return this.http.get(this.url + '/servicio/activas', {headers});
   }
   
}