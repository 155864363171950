import { Component, OnInit, OnDestroy } from '@angular/core';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';
import { Identity } from 'src/app/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/store/app.reducer';
import { CargaContacto } from 'src/app/models/carga-contactos';
import { Pagination } from 'src/app/models/pagination';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';
import { ContactosService } from 'src/app/services/contactos.service';
import {ExcelService} from 'src/app/services/excel.service';
@Component({
  selector: 'app-carga-contactos',
  templateUrl: './carga-contactos.component.html',
  styleUrls: ['./carga-contactos.component.sass']
})
export class CargaContactosComponent implements OnInit, OnDestroy {
  heading = 'Importación de contactos';
  subheading = '';
  icon = 'pe-7s-id icon-gradient bg-dark';
  public identity: Identity = new Identity(null);
  private subscribeAuth: Subscription = new Subscription();
  public loading = false;
  public files: File[] = [];
  public contactos: CargaContacto[] = [];
  public paginacion = new Pagination(null);
  public listSearch: string[] = [];
  public muestra: CargaContacto[] = [];
  public total = 0;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private contactosService: ContactosService,
    private excelService: ExcelService,
  ) { }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
  }

  validaContacto() {
    return new Promise( (resolve) => {
      const regresa = this.contactos.every( (contacto, index) => {
        return true;
      });
      return resolve(regresa);
    });
  }

  onSubmit() {
    this.validaContacto().then(
      (resultado) => {
        if (resultado) {
          Swal.fire({
            title: '¿Deseas registrar los contactos?',
            showCancelButton: true,
            confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
            cancelButtonColor: MESSAGES.C_BTN_CANCEL,
            confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
            cancelButtonText: MESSAGES.M_BTN_CANCEL,
            focusConfirm: false,
            focusCancel: true,
            animation: true,
            showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
            showLoaderOnConfirm: true,
            preConfirm:  ()  => {
              return new Promise( (resolve)  => {
                this.store.dispatch(new usuariosActions.UpdateCargaContactos(this.contactos));
                resolve(true);
                // this.contactosService.registraCargaContactos(this.contactos).subscribe(
                //   (respuesta) => {
                //     if (respuesta.respond) {
                //       // this.store.dispatch(new usuariosActions.UpdateCargaContactosSuccess());
                //       resolve(respuesta.respond);
                //     } else {
                //       Swal.showValidationMessage('Ocurrió un error en la operación');
                //       resolve(false);
                //     }
                //   }, (error) => {
                //     this.store.dispatch(new usuariosActions.UpdateCargaContactosFail(error));
                //     Swal.showValidationMessage('Ocurrió un error en la operación');
                //     resolve(false);
                //   }
                // );
              });
            },
            allowOutsideClick: false
          }).then(
            (result) => {
              if (result.value) {
                Swal.fire({
                  title: MESSAGES.M_TIT_CONFIRM,
                  text: 'Ejecutando registro de lista.',
                  icon: 'info'
                });
                this.router.navigate(['/auth/contactos']);
              } else {
                Swal.fire({
                  title: MESSAGES.M_TIT_CANCELADO,
                  timer: 1200,
                  showConfirmButton: false,
                  icon: 'error',
                  onOpen: () =>  {}
                }).then(() => {}, () => {});
              }
            }, () => {
              Swal.fire({
                title: MESSAGES.M_TIT_CANCELADO,
                timer: 1200,
                showConfirmButton: false,
                icon: 'error',
                onOpen: () => {}
              }).then( () => {},  () => {});
            }
          );
        }
      }, (error) => { }
    );
  }


  fileChangeEvent(event: FileList) {
    const archivos: File[] = Array.from(event);
    if (archivos && archivos.length) {
      archivos.forEach(element => {
        let existe = false;
        // const mimeType = element.type.split('.').pop();
        const mimeType = element.type.split('.').shift();
        if ( mimeType === 'application/vnd' ) {
          this.files.forEach(item => { if (item.name === element.name) { existe = true; }  });
          if(!existe){
            this.readXLS(element);
          }
        }else{
          this.toastr.warning('El formato del archivo "' + element.name +  '" no es compatible.');
        }
      });
    }
  }

  readXLS(excel: File) {
    if (excel) {
      this.files = [...this.files, excel];
      const fileReader = new FileReader();
      fileReader.onload = (event: any ) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        this.muestra = [];
        this.total = 0;
        workbook.SheetNames.forEach(sheet => {
          const rowObject: any[] = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheet]
          );
          let objCarga: CargaContacto = new CargaContacto(null);
          const lista: CargaContacto[] = rowObject.map( item => {
            objCarga = new CargaContacto(null);
            // tslint:disable-next-line:forin
            for (const field in item) {
              const minuscula = field.toLowerCase();
              switch ( minuscula ) {
                case 'nombre':
                  objCarga.nombre = item[field];
                break;
                case 'apellido':
                  objCarga.apellido = item[field];
                break;
                case 'propietario':
                  objCarga.propietario = item[field];
                break;
                case 'email':
                  objCarga.email = item[field];
                break;
                case 'cargo':
                  objCarga.cargo = item[field];
                break;
                case 'telefono':
                case 'teléfono':
                  objCarga.telefono = item[field];
                break;
                case 'celular':
                  objCarga.celular = item[field];
                break;
                case 'origen':
                case 'origen_contacto':
                  objCarga.origen = item[field];
                break;
                case 'etapa':
                  objCarga.etapa = item[field];
                break;
                case 'estatus':
                  objCarga.estatus = item[field];
                break;
                case 'pais':
                case 'país':
                  objCarga.pais = item[field];
                break;
                case 'codigo_postal':
                  objCarga.codigoPostal = item[field];
                break;
                case 'estado':
                  objCarga.estado = item[field];
                break;
                case 'municipio':
                  objCarga.municipio = item[field];
                break;
                case 'colonia':
                  objCarga.colonia = item[field];
                break;
                case 'calle':
                  objCarga.calle = item[field];
                break;
                case 'empresa':
                  objCarga.empresa = item[field];
                break;
                case 'industria':
                  objCarga.industria = item[field];
                break;
                case 'rfc':
                  objCarga.rfc = item[field];
                break;
                case 'razon_social':
                  objCarga.razonSocial = item[field];
                break;
                case 'f_pais':
                  objCarga.paisDatoFiscal = item[field];
                break;
                case 'f_codigo_postal':
                  objCarga.codigoPostalDatoFiscal = item[field];
                break;
                case 'f_estado':
                  objCarga.estadoDatoFiscal = item[field];
                break;
                case 'f_municipio':
                  objCarga.municipioDatoFiscal = item[field];
                break;
                case 'f_colonia':
                  objCarga.coloniaDatoFiscal = item[field];
                break;
                case 'f_calle':
                  objCarga.calleDatoFiscal = item[field];
                break;
                case 'cumpleaños':
                case 'cumpleanos':
                case 'cumpleanios':
                  objCarga.cumpleanios = item[field];
                break;
                case 'web':
                case 'pagina_web':
                case 'pagina web':
                case 'pagína web':
                case 'pagína':
                case 'pagina':
                  objCarga.paginaWeb = item[field];
                break;
                case 'fundacion':
                case 'fundación':
                  objCarga.fundacion = item[field];
                break;
              }
            }
            if (objCarga.email) {
              if (this.muestra.length < 10) {
                this.muestra.push(objCarga);
              }
              this.total++;
            }
            return objCarga;
          });
          // this.contactos = [...arr, ...lista.filter(item => item.email && item.nombre )];
          this.contactos = [...this.contactos, ...lista.filter(item => item.email)];
        });
      };
      fileReader.readAsBinaryString(excel);
    }
  }

  vaciarLista() {
    this.contactos = [];
    this.muestra = [];
    this.files = [];
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(' ');
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
  }

  removeItem(elemento: CargaContacto) {
    this.contactos = this.contactos.filter( item => JSON.stringify(item) !== JSON.stringify(elemento));
  }

  ejemploExcel() {
    const list = [{
      NOMBRE: 'Mario Fortino',
      APELLIDO: 'Alfonso Moreno Reyes',
      PROPIETARIO: 'Oswaldo Roldan',
      EMAIL: 'cantinflas@ejemplo.com',
      CARGO: 'Actor',
      TELEFONO: '555 555 5555, 333-333-3333',
      CELULAR: '7777777777',
      ORIGEN_CONTACTO: 'Cambaseo',
      ETAPA: 'Prospecto',
      ESTATUS: 'Nuevo',
      PAIS: 'México',
      CODIGO_POSTAL: '1500',
      ESTADO: 'Ciudad de México',
      MUNICIPIO: 'Álvaro Obregón',
      COLONIA: 'Miguel Gaona Armenta',
      CALLE: 'Tejocote #101',
      CUMPLEAÑOS: '1911-08-02',
      EMPRESA: 'Publicidad Organizada S.A.',
      INDUSTRIA: 'Cinematografía',
      PAGINA: 'cantinflas.com',
      FUNDACION: '1936-08-22',
      RFC: 'POS19110812ABC',
      RAZON_SOCIAL: 'Publicidad Organizada S.A. de C.V.',
      F_PAIS: 'México',
      F_CODIGO_POSTAL: '1590',
      F_ESTADO: 'Ciudad de México',
      F_MUNICIPIO: 'Álvaro Obregón',
      F_COLONIA: 'El Rincón',
      F_CALLE: 'Cuahtemoc #4510',
    }];
    this.excelService.exportAsExcelFile(list, 'Ejemplo');
  }


}
