import { SortOrder } from './sort';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Empresa{
	public id: number;
	public nombre: string;
	public industria: number;
	public desIndustria: string;
	public telefonoUno: string;
	public telefonoDos: string;
	public telefonoTres: string;
	public calle: string;
	public colonia: string;
	public pais: number;
	public desPais: string;
	public codigoPostal: string;
	public municipio: number;
	public estado: number;
	public desMunicipio: string;
	public desEstado: string;
	public imagen: string;
	public status: boolean;
	public propietario: number;
	public desPropietario: string;
	public rfc: string;
	public opcDatoFiscal: boolean;
	public direccionDatoFiscal: boolean;
	public razonSocial: string;
  public usoCfdi: string;
  public tipoContribuyente: string;
  public contactoPagar: string;
  public emailContactoPagar: string;
	public paisDatoFiscal: number;
	public desPaisDatoFiscal: string;
	public codigoPostalDatoFiscal: string;
	public municipioDatoFiscal: number;
	public estadoDatoFiscal: number;
	public desMunicipioDatoFiscal: string;
	public desEstadoDatoFiscal: string;
	public calleDatoFiscal: string;
	public coloniaDatoFiscal: string;
  public fecRegistro: Date;
	public fecModificacion: Date;
	public url: string;
	public fundacion: Date;
  public fecFundacion: Date;
  public check: boolean;

	constructor(
		obj: DataObjEmpresa
	) {
		this.id = obj && obj.id ? obj.id : 0
		this.nombre = obj && obj.nombre ? obj.nombre : '';
		this.industria = obj && obj.industria ? obj.industria: 0;
		this.desIndustria = obj && obj.desIndustria ? obj.desIndustria : '';
		this.telefonoUno = obj && obj.telefonoUno ? obj.telefonoUno: '';
		this.telefonoDos = obj && obj.telefonoDos ? obj.telefonoDos: '';
		this.telefonoTres = obj && obj.telefonoTres ? obj.telefonoTres: '';
		this.calle = obj && obj.calle ? obj.calle: '';
		this.colonia = obj && obj.colonia ? obj.colonia : '';
		this.pais = obj && obj.pais ? obj.pais: null;
		this.desPais = obj && obj.desPais ? obj.desPais: '';
		this.codigoPostal = obj && obj.codigoPostal ? obj.codigoPostal : '';
		this.municipio = obj && obj.municipio ? obj.municipio: 0;
		this.estado = obj && obj.estado ? obj.estado : 0;
		this.desMunicipio = obj && obj.desMunicipio ? obj.desMunicipio: '';
		this.desEstado = obj && obj.desEstado ? obj.desEstado : '';
		this.imagen = obj && obj.imagen ? obj.imagen : '';
		this.status = obj && obj.status ? obj.status : false;
		this.propietario = obj && obj.propietario ? obj.propietario : null;
		this.desPropietario = obj && obj.desPropietario ? obj.desPropietario : '';
		this.rfc = obj && obj.rfc ? obj.rfc : '';
		this.opcDatoFiscal = obj && obj.opcDatoFiscal ? obj.opcDatoFiscal : false;
		this.direccionDatoFiscal = obj && obj.direccionDatoFiscal ? obj.direccionDatoFiscal : false;
		this.razonSocial = obj && obj.razonSocial ? obj.razonSocial: '';
    this.usoCfdi = obj && obj.usoCfdi ? obj.usoCfdi : null;
    this.tipoContribuyente = obj && obj.tipoContribuyente ? obj.tipoContribuyente : null;
    this.contactoPagar = obj && obj.contactoPagar ? obj.contactoPagar : null;
    this.emailContactoPagar = obj && obj.emailContactoPagar ? obj.emailContactoPagar : null;
		this.paisDatoFiscal = obj && obj.paisDatoFiscal ? obj.paisDatoFiscal: null;
		this.desPaisDatoFiscal = obj && obj.desPaisDatoFiscal ? obj.desPaisDatoFiscal: '';
		this.codigoPostalDatoFiscal = obj && obj.codigoPostalDatoFiscal ? obj.codigoPostalDatoFiscal : '';
		this.municipioDatoFiscal = obj && obj.municipioDatoFiscal ? obj.municipioDatoFiscal: 0;
		this.estadoDatoFiscal = obj && obj.estadoDatoFiscal ? obj.estadoDatoFiscal : 0;
		this.desMunicipioDatoFiscal = obj && obj.desMunicipioDatoFiscal ? obj.desMunicipioDatoFiscal: '';
		this.desEstadoDatoFiscal = obj && obj.desEstadoDatoFiscal ? obj.desEstadoDatoFiscal : '';
		this.calleDatoFiscal = obj && obj.calleDatoFiscal ? obj.calleDatoFiscal : '';
		this.coloniaDatoFiscal = obj && obj.coloniaDatoFiscal ? obj.coloniaDatoFiscal : '';
    this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
		this.fecModificacion = obj && obj.fecModificacion ? obj.fecModificacion : null;
		this.url = obj && obj.url ? obj.url : '';
		this.fundacion = obj && obj.fundacion ? obj.fundacion : null;
    this.fecFundacion = obj && obj.fecFundacion ? obj.fecFundacion : null;
    this.check = obj && obj.check ? obj.check : false;
  }
}

interface DataObjEmpresa{
  id?: number;
  nombre?: string;
  industria?: number;
  desIndustria?: string;
  telefonoUno?: string;
  telefonoDos?: string;
  telefonoTres?: string;
  calle?: string;
  colonia?: string;
  pais?: number;
  desPais?: string;
  codigoPostal?: string;
  municipio?: number;
  estado?: number;
  desMunicipio?: string;
  desEstado?: string;
  status?: boolean;
  imagen?: string;
  propietario?: number;
  desPropietario?: string;
  rfc?: string;
  opcDatoFiscal?: boolean;
  direccionDatoFiscal?: boolean;
  razonSocial?: string;
  usoCfdi?: string;
  tipoContribuyente?: string;
  contactoPagar?: string;
  emailContactoPagar?: string;
  paisDatoFiscal?: number;
  desPaisDatoFiscal?: string;
  codigoPostalDatoFiscal?: string;
  municipioDatoFiscal?: number;
  estadoDatoFiscal?: number;
  desMunicipioDatoFiscal?: string;
  desEstadoDatoFiscal?: string;
  calleDatoFiscal?: string;
  coloniaDatoFiscal?: string;
  fecRegistro?: Date;
	fecModificacion?: Date;
	url?: string;
	fundacion?: Date;
  fecFundacion?: Date;
  check?: boolean;
}

export const EmpresaSortType: SortOrder[] = [
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+nombre', text: 'Nombres'},
  { id: '-nombre', text: 'Nombres desc'},
  { id: '+desIndustria', text: 'Industria'},
  { id: '-desIndustria', text: 'Industria desc'},
  { id: '+desTipo', text: 'Tipo'},
  { id: '-desTipo', text: 'Tipo desc'},
  { id: '+codigoPostal', text: 'Código postal'},
  { id: '-codigoPostal', text: 'Código postal desc'},
  { id: '+desMunicipio', text: 'Municipio'},
  { id: '-desMunicipio', text: 'Municipio desc'},
  { id: '+desEstado', text: 'Estado'},
  { id: '-desEstado', text: 'Estado desc'},
  { id: '+desPais', text: 'País'},
  { id: '-desPais', text: 'País desc'},
  { id: '+fecRegistro', text: 'Fecha de alta'},
  { id: '-fecRegistro', text: 'Fecha de alta desc'},
  { id: '+fecModificacion', text: 'Fecha de modificación'},
  { id: '-fecModificacion', text: 'Fecha de modificación desc'}
];
