import { Action } from '@ngrx/store';
import { Asignaciones } from 'src/app/models/asignaciones';

export const CARGAR_ASIGNACIONES = '[Asignacion] Cargar asignaciones';
export const CARGAR_ASIGNACIONES_FAIL = '[Asignacion] Cargar asignaciones FAIL';
export const CARGAR_ASIGNACIONES_SUCCESS = '[Asignacion] Cargar asignaciones SUCCESS';
export const CARGAR_ASIGNACION = '[Asignacion] Cargar asignacion';
export const CARGAR_ASIGNACION_FAIL = '[Asignacion] Cargar asignacion FAIL';
export const CARGAR_ASIGNACION_SUCCESS = '[Asignacion] Cargar asignacion SUCCESS';
export const REGISTRAR_ASIGNACION = '[Asignacion] Registrar asignacion';
export const REGISTRAR_ASIGNACION_FAIL = '[Asignacion] Registrar asignacion FAIL';
export const REGISTRAR_ASIGNACION_SUCCESS = '[Asignacion] Registrar asignacion SUCCESS';
export const ELIMINAR_ASIGNACION = '[Asignacion] Actualiza asignacion';
export const ELIMINAR_ASIGNACION_FAIL = '[Asignacion] Actualiza asignacion FAIL';
export const ELIMINAR_ASIGNACION_SUCCESS = '[Asignacion] Actualiza asignacion SUCCESS';
export const CAMBIAR_ESTATUS_ASIGNACION = '[Asignacion] Cambiar estatus asignacion';
export const CAMBIAR_ESTATUS_ASIGNACION_FAIL = '[Asignacion] Cambiar estatus asignacion FAIL';
export const CAMBIAR_ESTATUS_ASIGNACION_SUCCESS = '[Asignacion] Cambiar estatus asignacion SUCCESS';
export const EXISTE_ASIGNACION_FIJO = '[Asignacion] Existe asignacion fijo';
export const EXISTE_ASIGNACION_FIJO_FAIL = '[Asignacion] Existe asignacion fijo FAIL';
export const EXISTE_ASIGNACION_FIJO_SUCCESS = '[Asignacion] Existe asignacion fijo SUCCESS';
export const LIMPIAR_ASIGNACION = '[Asignacion] Limpiar asignacion';
export const LIMPIAR_ASIGNACION_FIJO = '[Asignacion] Limpiar asignacion fijo';

export class CargarAsignaciones implements Action {
  readonly type = CARGAR_ASIGNACIONES;
}

export class CargarAsignacionesFail implements Action {
  readonly type = CARGAR_ASIGNACIONES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarAsignacionesSuccess implements Action {
  readonly type = CARGAR_ASIGNACIONES_SUCCESS;

  constructor( public asignaciones: Asignaciones[] ) {}
}

export class CargarAsignacion implements Action {
  readonly type = CARGAR_ASIGNACION;

  constructor(public id: number) {}
}

export class CargarAsignacionFail implements Action {
  readonly type = CARGAR_ASIGNACION_FAIL;

  constructor( public payload: any ) {}
}

export class CargarAsignacionSuccess implements Action {
  readonly type = CARGAR_ASIGNACION_SUCCESS;

  constructor( public asignacion: Asignaciones ) {}
}

export class RegistrarAsignacion implements Action {
  readonly type = REGISTRAR_ASIGNACION;

  constructor( public asignacion: Asignaciones ) { }
}

export class RegistrarAsignacionFail implements Action {
  readonly type = REGISTRAR_ASIGNACION_FAIL;

  constructor( public payload: any ) {}
}

export class RegistrarAsignacionSuccess implements Action {
  readonly type = REGISTRAR_ASIGNACION_SUCCESS;

  constructor( public asignacion: Asignaciones ) {}
}

export class EliminarAsignacion implements Action {
  readonly type = ELIMINAR_ASIGNACION;

  constructor( public id: number ) { }
}

export class EliminarAsignacionFail implements Action {
  readonly type = ELIMINAR_ASIGNACION_FAIL;

  constructor( public payload: any ) {}
}

export class EliminarAsignacionSuccess implements Action {
  readonly type = ELIMINAR_ASIGNACION_SUCCESS;

  constructor( public id: number ) {}
}

export class CambiarEstatusAginacion implements Action {
    readonly type = CAMBIAR_ESTATUS_ASIGNACION;

    constructor( public asignacion: Asignaciones) { }
}

export class CambiarEstatusAginacionFail implements Action {
    readonly type = CAMBIAR_ESTATUS_ASIGNACION_FAIL;

    constructor( public payload: any ) {}
}

export class CambiarEstatusAginacionSuccess implements Action {
    readonly type = CAMBIAR_ESTATUS_ASIGNACION_SUCCESS;

    constructor( public asignacion: Asignaciones ) {}
}

export class ExisteAsignacionFijo implements Action {
  readonly type = EXISTE_ASIGNACION_FIJO;

  constructor( public asignacion: Asignaciones ) { }
}

export class ExisteAsignacionFijoFail implements Action {
  readonly type = EXISTE_ASIGNACION_FIJO_FAIL;

  constructor( public payload: any ) {}
}

export class ExisteAsignacionFijoSuccess implements Action {
  readonly type = EXISTE_ASIGNACION_FIJO_SUCCESS;

  constructor( public asignacion: Asignaciones ) {}
}

export class LimpiarAsignacion implements Action {
  readonly type = LIMPIAR_ASIGNACION;

  constructor() {}
}

export class LimpiarAsignacionFijo implements Action {
  readonly type = LIMPIAR_ASIGNACION_FIJO;

  constructor() {}
}
export type asignacionAcciones = CargarAsignaciones 
                                | CargarAsignacionesFail 
                                | CargarAsignacionesSuccess 
                                | CargarAsignacion 
                                | CargarAsignacionFail 
                                | CargarAsignacionSuccess 
                                | RegistrarAsignacion 
                                | RegistrarAsignacionFail 
                                | RegistrarAsignacionSuccess 
                                | EliminarAsignacion 
                                | EliminarAsignacionFail 
                                | EliminarAsignacionSuccess 
                                | CambiarEstatusAginacion 
                                | CambiarEstatusAginacionFail 
                                | CambiarEstatusAginacionSuccess 
                                | ExisteAsignacionFijo 
                                | ExisteAsignacionFijoFail 
                                | ExisteAsignacionFijoSuccess 
                                | LimpiarAsignacionFijo
                                | LimpiarAsignacion;

