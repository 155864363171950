import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from 'src/app/models/global';

@Injectable({
  providedIn: 'root'
})
export class ConsultaCatalogossService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }


  obtenerDivisiones(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/catalogo/division', {headers});
  }

  obtenerDepartamentos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/catalogo/departamentos', {headers});
  }

  obtenerSucursales(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/catalogo/sucursales', {headers});
  }

  obtenerColores(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/catalogo/colores', {headers});
  }

  obtenerServicios(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/catalogo/servicios', {headers});
  }

  obtenerClientes(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/catalogo/clientes', {headers});
  }

  obtenerTipoServicios(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/catalogo/tipo-servicios', {headers});
  }


}
