import { Component, OnInit, OnDestroy } from '@angular/core';
import { DivisionBajaVacan } from 'src/app/models/reporte-asignacion';
import { Pagination } from 'src/app/models/pagination';
import { Fechas } from 'src/app/models/fechas';
import { DatePipe } from '@angular/common';
import { Identity } from 'src/app/models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { ExcelService } from 'src/app/services/excel.service';
import * as usuariosActions from 'src/app/store/actions';
import * as moment from 'moment';

@Component({
  selector: 'app-reporte-asignacion-bajas-vacantes',
  templateUrl: './reporte-asignacion-bajas-vacantes.component.html',
  styleUrls: ['./reporte-asignacion-bajas-vacantes.component.sass']
})
export class ReporteAsignacionBajasVacantesComponent implements OnInit, OnDestroy {
  heading = 'Resumen de bajas y vacantes';
  subheading = 'Cambia de opción a Vacantes y Bajas';
  icon = ' pe-7s-note2 icon-gradient bg-happy-fisher';
  public datePipe = new DatePipe("en-US");
  public dates: Date[] = [];
  public anio: string;
  public resultados: DivisionBajaVacan[] = [];
  public resultadoTotal: DivisionBajaVacan = new DivisionBajaVacan (null);
  public paginacion = new Pagination(null);
  public chips = [];
  public removable: boolean;
  public selectable: boolean;
  public loading: boolean;
  public promedioBaja: number;
  public promedioVacante: number;
  public dias: Fechas;
  public filter_tipo: number;
  public identity: Identity;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionReporteAsignacion: Subscription = new Subscription();
  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private excelService: ExcelService,
  ) { 
  const fecha = new Date();
  this.removable = true;
  this.selectable = true;
  fecha.setDate(fecha.getDate() - 15);
  this.paginacion.sortType = '-id'
	this.dates = [ fecha,new Date() ];
	this.dias = new Fechas(null);
  this.filter_tipo = 1;
  this.loading = false;
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionReporteAsignacion = this.store.select('reporteAsignacion').subscribe(
      (state) => {
        this.resultadoTotal = new DivisionBajaVacan (null); 
        this.resultadoTotal.nombre = 'Total';
        let cantidad = 0;
        this.resultados = [...state.bajaVacante];
        this.resultados.find(item => {
          cantidad = cantidad + 1;
          this.resultadoTotal.bajas = this.resultadoTotal.bajas + item.bajas ;
          this.resultadoTotal.porcentajeBaja = this.resultadoTotal.porcentajeBaja + item.porcentajeBaja;
          this.resultadoTotal.elementos = this.resultadoTotal.elementos + item.elementos;
          this.resultadoTotal.servicios = this.resultadoTotal.servicios + item.servicios;
          this.resultadoTotal.vacantes = this.resultadoTotal.vacantes + item.vacantes;
          this.resultadoTotal.porcentajeVacante = this.resultadoTotal.porcentajeVacante + item.porcentajeVacante;
        });
        this.promedioBaja = (this.resultadoTotal.porcentajeBaja / cantidad);
        this.promedioVacante = (this.resultadoTotal.porcentajeVacante / cantidad);
      }
    );
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionReporteAsignacion.unsubscribe();
  }

  fecha(fecha:Date[]){
    if(fecha && fecha.length === 2){
      const fecha1= this.datePipe.transform(fecha["0"], 'yyyy-MM-dd');
      const fecha2= this.datePipe.transform(fecha["1"], 'yyyy-MM-dd');
      const anio1 = this.datePipe.transform(fecha["0"], 'yyyy');
      const anio2 = this.datePipe.transform(fecha["1"], 'yyyy');
      
      

      if(anio1 === anio2){
        this.anio = anio1;
      }else{
        this.anio = (anio1 + '-' + anio2);
      }
      this.dias = {fechaInicio: fecha1, fechaFinal: fecha2};
      this.store.dispatch(new usuariosActions.CargarReporteBajaVacante(this.dias));
    }
  }
    
   


  async crearExcel() {
    this.loading = true;
    await this.valida().then(
      (resultado: boolean) => {
        let baja;
        let vacante

        baja = this.resultados.map( item => {
          const obj: any = {};
          obj.DIVISION= item.nombre;
          obj.SERVICIOS = item.servicios;
          obj.NÚMERO_ELEMENTOS = item.elementos;
          obj.BAJAS = item.bajas;
          obj.PORCENTAJE_BAJA = item.porcentajeBaja;
          return obj;
        });

        vacante = this.resultados.map( item => {
          const obj: any = {};
          obj.DIVISION= item.nombre;
          obj.SERVICIOS = item.servicios;
          obj.NÚMERO_ELEMENTOS = item.elementos;
          obj.VACANATES = item.vacantes;
          obj.PORCENTAJE_VACANTES = item.porcentajeVacante;
          return obj;
        });
        this.excelService.exportAsExcelFileReporteBajaVacante(baja, vacante, 'Resumen de bajas y vacantes');
        this.loading = true;
      this.loading = resultado;
    }, (error) => { }
  );
    
  }


  textSearchChips(){
    if(this.paginacion.textSearch.length > 0){
      this.chips = [...this.paginacion.textSearch.split(' ')];
    }
    
  }

  remove(chips){
    this.chips = [...this.chips.filter(item => item !== chips)];
    this.paginacion.textSearch = this.chips.join(' ');
  }


  valida() {
    const deferred = new Promise((resolve, reject) => {
      if (this.loading) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
    return deferred;
  }

}
