import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { TableroKpiService } from 'src/app/services/tablero-kpi.service';

@Injectable()
export class TableroKpiEffects {

  constructor(
    private actions$: Actions,
    public tableroKpiService: TableroKpiService
  ) {}

  @Effect()
  cargarIndicadores$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_KPI_INDICADORES ),
    switchMap( (action: any) => {
      return this.tableroKpiService.obtenerReporteIndicador(action.dias)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarReporteKpiIndicadoresSuccess(respuesta.indicadores, respuesta.nombre_indicador ,respuesta.tipo);
            }),
            catchError( error => of(new usuariosActions.CargarReporteKpiIndicadoresFail(error))  )
        );
    })
  );

  @Effect()
  cargarAreas$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_KPI_AREAS ),
    switchMap( (action: any) => {
      return this.tableroKpiService.obtenerReporteArea(action.dias)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarReporteKpiAreasSuccess(respuesta.indicadores, respuesta.areas,respuesta.nombre_indicador,respuesta.tipo);
            }),
            catchError( error => of(new usuariosActions.CargarReporteKpiAreasFail(error))  )
        );
    })
  );

  @Effect()
  cargarUsuarios$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_KPI_USUARIOS ),
    switchMap( (action: any) => {
      return this.tableroKpiService.obtenerReporteUsuarios(action.dias)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarReporteKpiUsuarioSuccess(respuesta.indicadores,respuesta.areas,respuesta.nombre_indicador,respuesta.tipo,respuesta.usuarios);
            }),
            catchError( error => of(new usuariosActions.CargarReporteKpiUsuarioFail(error))  )
        );
    })
  );


}
