<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" >
  <div class="col-md-12">
    <div class="main-card mb-3 card">
      <div class="card-header">
        <div class="btn-actions-pane-left">
          <div class="row mt-3">
            <div class="form-group pr-4">
              <input type="text" placeholder="Fecha" class="form-control d-none d-lg-block" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)">
              <input type="text" placeholder="Fecha" class="form-control d-lg-none" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
              [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)">
            </div>
            <div class="search-wrapper pr-3" [class.active]="paginacion.isActive" *ngIf="resultados.length > 0">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchAsignacion" id="searchAsignacion" #searchAsignacion="ngModel" [(ngModel)]="paginacion.textSearch" (blur)="textSearchChips()">
                <button class="search-icon" (click)="paginacion.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = ''; chips = [];"></button>
            </div>
          </div>
        </div>
        <div class="btn-actions-pane-right" *ngIf="resultados.length > 0">
          <span>
            ({{(resultados | anyScope: paginacion.textSearch).length}} de {{(resultados).length}})
          </span>
          <!-- <div class="btn-group">
            <button class="btn-wide btn-icon btn-icon-right btn-pill btn btn-lg" *ngIf="!filterActive" (click)="filterActive = true">
              Filtrar
              <i class="pe-7s-filter btn-icon-wrapper"> </i>
            </button>
          </div> -->
        </div>
      </div>
      <div class="card-subtitle mt-2 ml-3" *ngIf="chips.length > 0">
        <mat-chip-list>
          <mat-chip  *ngFor="let chip of chips" [selectable]="selectable" [removable]="removable"> {{chip}}
            <mat-icon matChipRemove *ngIf="removable" (click)="remove(chip) ">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="divider mt-0 m-0" *ngIf="chips.length > 0"></div>
      <div class="card-body p-0">
        <div class="card-body text-center" *ngIf="resultados.length > 0">
          <h5  class="card-title">Resumen cobertura operacional {{anio}}</h5>
          <div class="table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th class="text-center"> División</th>
                  <th class="text-center"> Número de elementos</th>
                  <th class="text-center"> Turnos</th>
                  <th class="text-center"> TNC's</th>
                  <th class="text-center"> %COP</th>
                  <th class="text-center"> Servicios Fijo</th>
                  <th class="text-center"> Servicios Extraordinarios</th>
                  <th class="text-center"> Servicios Cortesia</th>
                </tr>
              </thead>
              <tbody *ngFor="let resultado of resultados | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize">
                <tr>
                  <td >{{resultado.nombre}}</td>
                  <td >{{resultado.elementos}}</td>
                  <td >{{resultado.turnos}}</td>
                  <td >{{resultado.faltas}}</td>
                  <td [ngClass]="{'bg-success': resultado.cop >= 98.99, 'bg-warning': resultado.cop <= 98.98 && resultado.cop >= 90.1, 'bg-danger': resultado.cop <= 90 && resultado.cop >= 76, 'bg-dark': resultado.cop <= 75.99, 'text-dark': resultado.cop <= 98.98 && resultado.cop >= 90.1, 'text-white': resultado.cop > 98.98 || resultado.cop < 90.1  }">{{resultado.cop}}</td>
                  <td >{{resultado.fijo}}</td>
                  <td >{{resultado.extraordinario}}</td>
                  <td >{{resultado.cortesia}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td> <b>{{resultadoTotal.nombre}}</b></td>
                  <td> <b>{{resultadoTotal.elementos}}</b></td>
                  <td> <b>{{resultadoTotal.turnos}}</b></td>
                  <td> <b>{{resultadoTotal.faltas}}</b></td>
                  <td [ngClass]="{'bg-success': resultadoTotal.cop >= 98.99, 'bg-warning': resultadoTotal.cop <= 98.98 && resultadoTotal.cop >= 90.1, 'bg-danger': resultadoTotal.cop <= 90 && resultadoTotal.cop >= 76, 'bg-dark': resultadoTotal.cop <= 75.99, 'text-dark': resultadoTotal.cop <= 98.98 && resultadoTotal.cop >= 90.1, 'text-white': resultadoTotal.cop > 98.98 || resultadoTotal.cop < 90.1  }" ><b>{{resultadoTotal.cop | number : '1.0-2': 'en-MX' }}</b></td>
                  <td> <b>{{resultadoTotal.fijo}}</b></td>
                  <td> <b>{{resultadoTotal.extraordinario}}</b></td>
                  <td> <b>{{resultadoTotal.cortesia}}</b></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="mt-2" *ngIf="resultados.length > 0">
        <div class="row p-2">
          <div class="col-sm-12">
            <div class="pull-right">
              <button type="button" (click) = "crearExcel()" class="btn btn-primary" [disabled]="loading" *ngIf="identity.permisos.rptCobertura">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card main-card mb-3" *ngIf="resultados.length > 0">
  <!-- <div class="card-header">
     <h5 class="card-title">TNC {{anio}}</h5> 
  </div> -->
  <div class="card-body">
    <div class="row">
      <div class="col-md-6" >
        <apx-chart
        [title]="{
          text: titlePie,
          align: 'left'
        }"
        [series]="seriesPie"
        [chart]="{
          width: 520,
          type: 'pie'
        }"
        [labels]="categories"
        [responsive]="[
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]"
      ></apx-chart>
      </div>
  
      <div class="col-md-6" >
  
        <apx-chart
        [title]="{
          text: '%COP',
          align: 'left'
        }"
        [series]="[
        {
          name: '%COP	',
          data: seriesBar
        }
      ]"
        [chart]="{
          type: 'bar',
          height: 395,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        }"
        [plotOptions]="{
          bar: {
            horizontal: false
          }
        }"
        [xaxis]="{
          type: 'category',
          categories: categories
        }"
        [fill]="{
          opacity: 1
        }"
        [legend]="{
          position: 'right',
          offsetY: 40
        }"
        [responsive] = "[
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ]"
      ></apx-chart>
      </div>
    </div>
    

  </div>
</div>

