import { Action } from '@ngrx/store';
import { UserType, Permisos, Suscripciones } from 'src/app/models/user';

export const LOAD_TYPE_USERS = '[Catalogos] Load type users';
export const LOAD_TYPE_USERS_SUCCESS = '[Catalogos] Load type users success';
export const LOAD_TYPE_USERS_FAIL = '[Catalogos] Load type users fail';
export const LOAD_TYPE_USER = '[Catalogos] Load type user';
export const LOAD_TYPE_USER_SUCCESS = '[Catalogos] Load type user success';
export const LOAD_TYPE_USER_FAIL = '[Catalogos] Load type user fail';
export const LOAD_PERMISOS = '[Catalogos] Load permisos';
export const LOAD_PERMISOS_SUCCESS = '[Catalogos] Load permisos success';
export const LOAD_PERMISOS_FAIL = '[Catalogos] Load permisos fail';
export const SAVE_PERMISOS = '[Catalogos] Save permisos';
export const SAVE_PERMISOS_SUCCESS = '[Catalogos] Save permisos success';
export const SAVE_PERMISOS_FAIL = '[Catalogos] Save permisos fail';
export const LOAD_SUSCRIPCIONES = '[Catalogos] Load suscripciones';
export const LOAD_SUSCRIPCIONES_SUCCESS = '[Catalogos] Load suscripciones success';
export const LOAD_SUSCRIPCIONES_FAIL = '[Catalogos] Load suscripciones fail';
export const SAVE_SUSCRIPCIONES = '[Catalogos] Save suscripciones';
export const SAVE_SUSCRIPCIONES_SUCCESS = '[Catalogos] Save suscripciones success';
export const SAVE_SUSCRIPCIONES_FAIL = '[Catalogos] Save suscripciones fail';
export const UPDATE_TYPE_USERS_STATUS = '[Catalogos] Update type users status';
export const UPDATE_TYPE_USERS_STATUS_SUCCESS = '[Catalogos] Update type users status success';
export const UPDATE_TYPE_USERS_STATUS_FAIL = '[Catalogos] Update type users status fail';
export const LOAD_TYPE_USERS_CONFIG = '[Catalogos] Load type users config';
export const LOAD_TYPE_USERS_CONFIG_SUCCESS = '[Catalogos] Load type users config success';
export const LOAD_TYPE_USERS_CONFIG_FAIL = '[Catalogos] Load type users config fail';


export class LoadTypeUsers implements Action {
    readonly type = LOAD_TYPE_USERS;
}

export class LoadTypeUsersFail implements Action {
    readonly type = LOAD_TYPE_USERS_FAIL;

    constructor( public payload: any ) {}
}

export class LoadTypeUsersSuccess implements Action {
    readonly type = LOAD_TYPE_USERS_SUCCESS;

    constructor(public tipos: UserType[]) {}
}

export class LoadTypeUser implements Action {
  readonly type = LOAD_TYPE_USER;

  constructor(public id: number) {}
}

export class LoadTypeUserFail implements Action {
  readonly type = LOAD_TYPE_USER_FAIL;

  constructor( public payload: any ) {}
}

export class LoadTypeUserSuccess implements Action {
  readonly type = LOAD_TYPE_USER_SUCCESS;

  constructor( public tipo: UserType) {}
}

export class LoadPermisos implements Action {
  readonly type = LOAD_PERMISOS;

  constructor(public id: number) {}
}

export class LoadPermisosFail implements Action {
  readonly type = LOAD_PERMISOS_FAIL;

  constructor( public payload: any ) {}
}

export class LoadPermisosSuccess implements Action {
  readonly type = LOAD_PERMISOS_SUCCESS;

  constructor( public permisos: Permisos) {}
}

export class SavePermisos implements Action {
  readonly type = SAVE_PERMISOS;

  constructor(public tipo: UserType, public permisos: Permisos, public suscripciones: Suscripciones[], public tiposVer: UserType[], public tipos: UserType[]) {}
}

export class SavePermisosFail implements Action {
  readonly type = SAVE_PERMISOS_FAIL;

  constructor( public payload: any ) {}
}

export class SavePermisosSuccess implements Action {
  readonly type = SAVE_PERMISOS_SUCCESS;

  constructor(public tipo: UserType, public permisos: Permisos, public suscripciones: Suscripciones[]) {}
}

export class LoadSuscripciones implements Action {
  readonly type = LOAD_SUSCRIPCIONES;

  constructor(public tipo: UserType) {}
}

export class LoadSuscripcionesFail implements Action {
  readonly type = LOAD_SUSCRIPCIONES_FAIL;

  constructor( public payload: any ) {}
}

export class LoadSuscripcionesSuccess implements Action {
  readonly type = LOAD_SUSCRIPCIONES_SUCCESS;

  constructor(public tipo: UserType, public suscripciones: Suscripciones[]) {}
}

export class UpdateTypeUsersStatus implements Action {
  readonly type = UPDATE_TYPE_USERS_STATUS;

  constructor(public tipo: UserType) {}
}

export class UpdateTypeUsersStatusFail implements Action {
  readonly type = UPDATE_TYPE_USERS_STATUS_FAIL;

  constructor( public payload: any ) {}
}

export class UpdateTypeUsersStatusSuccess implements Action {
  readonly type = UPDATE_TYPE_USERS_STATUS_SUCCESS;

  constructor(public tipo: UserType) {}
}


export class LoadTypeUsersConfig implements Action {
  readonly type = LOAD_TYPE_USERS_CONFIG;

  constructor(public id: number) {}
}

export class LoadTypeUsersConfigFail implements Action {
  readonly type = LOAD_TYPE_USERS_CONFIG_FAIL;

  constructor( public payload: any ) {}
}

export class LoadTypeUsersConfigSuccess implements Action {
  readonly type = LOAD_TYPE_USERS_CONFIG_SUCCESS;

  constructor( public tiposVer: UserType[], public tipos: UserType[]) {}
}

export type catalogosAcciones = 	LoadTypeUsers 
                              	| LoadTypeUsersFail
																| LoadTypeUsersSuccess
 																| LoadPermisos
																| LoadPermisosFail
																| LoadPermisosSuccess
																| SavePermisos
																| SavePermisosFail
																| SavePermisosSuccess
																| LoadSuscripciones
																| LoadSuscripcionesFail
																| LoadSuscripcionesSuccess
                                | UpdateTypeUsersStatus
																| UpdateTypeUsersStatusFail
																| UpdateTypeUsersStatusSuccess
                                | LoadTypeUsersConfig
																| LoadTypeUsersConfigFail
																| LoadTypeUsersConfigSuccess
                                | LoadTypeUser
																| LoadTypeUserFail
																| LoadTypeUserSuccess
                                ;
