import { Component, OnInit, OnDestroy } from '@angular/core';
import { Catalogo, CatalogoSortType } from 'src/app/models/catalogo';
import { CatalogosService } from 'src/app/services/catalogos.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Pagination } from 'src/app/models/pagination';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-paises',
  templateUrl: './paises.component.html',
  styleUrls: ['./paises.component.sass']
})
export class PaisesComponent implements OnInit, OnDestroy {
  heading = 'Detalles del país';
  subheading = 'Países registrandos en el sistema';
  icon = 'pe-7s-notebook icon-gradient bg-dark';
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeTipo: Subscription = new Subscription();
  public pais: Catalogo;
  public paisInicial: Catalogo = new Catalogo(null);
  public paises: Catalogo[];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public filterActive = false;
  public orderType = [...CatalogoSortType];
  constructor(
    private catalogosService: CatalogosService,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) {
    this.pais = new Catalogo(null);
    this.paginacion.sortType = '+nombre';
    this.identity = new Identity(null);
    this.paises = [];
   }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeTipo = this.store.select('pais').subscribe(
      (state) => {
        this.paises = [...state.paises];
      }
    );
    console.log('Inicializa component Tipo empresas...');
    this.store.dispatch(new usuariosActions.CargarPaises());
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeTipo.unsubscribe();
  }


  editarPais(zon: Catalogo) {
    this.pais = {...zon};
    this.paisInicial = {...zon};
  }

  estatusPais(pais: Catalogo) {
    if (pais.id > 0 && pais.id !== 1) {
      this.loading = true;
      this.catalogosService.actualizaEstatusPais(pais).subscribe(
        (response) => {
          this.store.dispatch(new usuariosActions.ActualizaPaisEstatusSuccess(pais.id));
          this.toastr.success('Actualización correctamente.', 'Exito');
          this.loading = false;
        }, (error) => {
          this.toastr.error('Ocurrió un error.', 'Error');
          this.loading = false;
        }
      );
    }
  }

  onSubmit(form) {
    if(this.pais.id !== 1){
      Swal.fire({
        title: '¿Deseas registrar el país?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
        cancelButtonColor: MESSAGES.C_BTN_CANCEL,
        confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
        cancelButtonText: MESSAGES.M_BTN_CANCEL,
        focusConfirm: false,
        focusCancel: true,
        animation: true,
        showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
        showLoaderOnConfirm: true,
        preConfirm:  ()  => {
          return new Promise( (resolve)  => {
            this.catalogosService.registraPais(this.pais).subscribe(
              (respuesta) => {
                if (respuesta.pais) {
                  this.store.dispatch(new usuariosActions.ActualizaPaisSuccess(respuesta.pais));
                  resolve(respuesta.pais);
                } else {
                  Swal.showValidationMessage('Ocurrió un error en la operación');
                  resolve(false);
                }
              }, (error) => {
                this.store.dispatch(new usuariosActions.ActualizaPaisFail(error));
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            );
          });
        },
        allowOutsideClick: false
      }).then(
        (result) => {
          if (result.value) {
            form.reset();
            this.pais = new Catalogo(null);
            this.paisInicial = new Catalogo(null);
            Swal.fire({
              title: MESSAGES.M_TIT_CONFIRM,
              text: 'País registrado.',
              icon: 'success'
            });
          } else {
            Swal.fire({
              title: MESSAGES.M_TIT_CANCELADO,
              timer: 1200,
              showConfirmButton: false,
              icon: 'error',
              onOpen: () =>  {}
            }).then(() => {}, () => {});
          }
        }, () => {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () => {}
          }).then( () => {},  () => {});
        }
      ); 
    }
  }

  limpiar(form) {
    this.pais = new Catalogo(null);
    this.paisInicial = new Catalogo(null);
    form.reset();
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

}
