<div [class]="'app-sidebar sidebar-shadow text-lighter'" (mouseover)="sidebarHover()"
	  (mouseout)="sidebarHover()">
	  <!-- 'app-sidebar sidebar-shadow bg-premium-dark text-lighter' -->
    <!-- 'app-sidebar sidebar-shadow text-dark' -->
    <!-- app-sidebar sidebar-shadow bg-premium text-dark -->
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ml-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content" *ngIf="identity && identity.permisos">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar>
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <div class="vsm-header">Menu principal</div>


            <button type="button" class="btn btn-outline-light d-flexy btn-block mb-2 mr-2 border-0 btn-transition btn-icon btn-icon-only" *ngIf="identity.tipo === 1 || identity.tipo === 5"
              [ngClass]="{'active': tipoSidebar === 1}" (click)="tipoSidebar = 1" title="Menu de administración">
              <i class="btn-icon-wrapper fas fa-cogs"></i> 
              <span class="vsm-title">&nbsp; ADMINISTRACIÓN</span>
            </button>
            <button type="button" class="btn btn-outline-light d-flexy btn-block mb-2 mr-2 border-0 btn-transition btn-icon btn-icon-only" *ngIf="identity.permisos && (identity.permisos.actividadesRegEdit || 
                                                                                        identity.permisos.indicadorCapturar || identity.permisos.asignacionCapturar ||
                                                                                        identity.permisos.asignacionListVer || identity.permisos.asignacionRegVer ||
                                                                                        identity.permisos.indicadorRegVer || identity.permisos.indicadorRegEdit ||
                                                                                        identity.permisos.catDepartamentosEdit || identity.permisos.catDepartamentosVer || 
                                                                                        identity.permisos.catDivisionesEdit || identity.permisos.catDivisionesVer || 
                                                                                        identity.permisos.catSucursalesEdit || identity.permisos.catSucursalesVer || 
                                                                                        identity.permisos.catColoresEdit || identity.permisos.catColoresVer || 
                                                                                        identity.permisos.catServiciosEdit || identity.permisos.catServiciosVer || 
                                                                                        identity.permisos.catClienteEdit || identity.permisos.catClienteVer ||
                                                                                        identity.permisos.catTipoServicioEdit || identity.permisos.catTipoServicioVer ||
                                                                                        identity.permisos.rptCoberturaDia || identity.permisos.rptCobertura || 
                                                                                        identity.permisos.rptServicioCop || identity.permisos.rptBajaVacantes || 
                                                                                        identity.permisos.rptServicioInicia || identity.permisos.rptServicioTermina || 
                                                                                        identity.permisos.rptIndicador || identity.permisos.rptIndicadorArea || 
                                                                                        identity.permisos.rptIndicadorUsuario || identity.permisos.rptActividades)"
             [ngClass]="{'active': tipoSidebar === 2}" (click)="tipoSidebar = 2" title="Menu de SMIE">
             <i class="btn-icon-wrapper fas fa-book"></i> 
             <span class="vsm-title">&nbsp; SMIE</span>
            </button>
            <button type="button" class="btn btn-outline-light d-flexy btn-block mb-2 mr-2 border-0 btn-transition btn-icon btn-icon-only" *ngIf="identity.permisos && (identity.permisos.empresasListEdit || 
                                                                                        identity.permisos.empresasListVer || identity.permisos.contactosListVer || 
                                                                                        identity.permisos.contactosListEdit || identity.permisos.oportunidadesListVer || 
                                                                                        identity.permisos.oportunidadesListEdit|| identity.permisos.catIndustriasVer || 
                                                                                        identity.permisos.catIndustriasEdit || identity.permisos.rptOportunidadPropietarios ||
                                                                                        identity.permisos.catOrigenesVer || identity.permisos.catOrigenesEdit ||
                                                                                        identity.permisos.catPaisesVer || identity.permisos.catPaisesEdit || 
                                                                                        identity.permisos.rptKpiVendedores || identity.permisos.rptCumpleannos || 
                                                                                        identity.permisos.rptOportunidadEtapas )"
              [ngClass]="{'active': tipoSidebar === 3}" (click)="tipoSidebar = 3" title="Menu de CRM">
              <i class="btn-icon-wrapper fas fa-mail-bulk"></i> 
              <span class="vsm-title">&nbsp; CRM</span>
            </button>
            <button type="button" class="btn btn-outline-light d-flexy btn-block mb-2 mr-2 border-0 btn-transition btn-icon btn-icon-only" *ngIf="identity.permisos && (identity.permisos.ticketsVer || identity.permisos.ticketsEdit)"
              [ngClass]="{'active': tipoSidebar === 4}" (click)="tipoSidebar = 4" title="Menu de administración">
              <i class="btn-icon-wrapper fas fa-info-circle"></i> 
              <span class="vsm-title">&nbsp; TICKETS</span>
            </button>
            <div class="divider"></div>
            <div class="vsm-item">
              <a routerLinkActive="active-item" routerLink="/auth/dashboard" class="vsm-link">
                <i class="vsm-icon pe-7s-timer"></i>
                <span class="vsm-title">Dashboard</span>
              </a>
            </div>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter" *ngIf="tipoSidebar === 2 && identity.permisos
              && (identity.permisos.actividadesRegEdit || identity.permisos.indicadorCapturar || identity.permisos.asignacionCapturar)">
              <ngb-panel id="Captura" >
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-eyedropper"></i>
                    <span class="vsm-title">Captura</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <!-- <div class="vsm-item" *ngIf="identity.permisos && (identity.permisos.actividadesRegEdit )">
                        <a routerLinkActive="active-item" routerLink="/auth/actividades/register/0" class="vsm-link">
                          <span class="vsm-title">Actividades</span>
                        </a>
                      </div> -->
                      <div class="vsm-item" *ngIf="identity.permisos && (identity.permisos.actividadesRegEdit )">
                        <a routerLinkActive="active-item" routerLink="/auth/actividades" class="vsm-link">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Actividades</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="identity.permisos && (identity.permisos.indicadorCapturar)">
                        <a routerLinkActive="active-item" routerLink="/auth/indicador/captura/administrativo" class="vsm-link">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Indicadores</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="identity.permisos && (identity.permisos.asignacionCapturar)">
                        <a routerLinkActive="active-item" routerLink="/auth/indicador/captura/operativo" class="vsm-link">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Indicadores operativos</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <div class="vsm-item" *ngIf="tipoSidebar === 1 && identity.permisos && (identity.permisos.usuariosListVer || identity.permisos.usuariosListEdit)">
              <a routerLinkActive="active-item" routerLink="/auth/users" class="vsm-link">
                <i class="vsm-icon pe-7s-users"></i>
                <span class="vsm-title">Usuarios</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="tipoSidebar === 2 && identity.permisos && (identity.permisos.asignacionListVer || identity.permisos.asignacionRegVer)">
              <a routerLinkActive="active-item" routerLink="/auth/asignacion" class="vsm-link">
                <i class="vsm-icon pe-7s-check"></i>
                <span class="vsm-title">Asignación</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="tipoSidebar === 2 && identity.permisos && (identity.permisos.indicadorRegVer || identity.permisos.indicadorRegEdit)">
              <a routerLinkActive="active-item" routerLink="/auth/indicador" class="vsm-link">
                <i class="vsm-icon pe-7s-graph"></i>
                <span class="vsm-title">Indicadores</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="tipoSidebar === 3 && identity.permisos && (identity.permisos.empresasListEdit || identity.permisos.empresasListVer)">
              <a routerLinkActive="active-item" routerLink="/auth/empresas" class="vsm-link">
                <i class="vsm-icon far fa-building"></i>
                <span class="vsm-title">Empresas</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="tipoSidebar === 3 && identity.permisos && (identity.permisos.contactosListVer || identity.permisos.contactosListEdit)">
              <a routerLinkActive="active-item" routerLink="/auth/contactos" class="vsm-link">
                <i class="vsm-icon pe-7s-id"></i>
                <span class="vsm-title">Contactos</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="tipoSidebar === 3 && identity.permisos && (identity.permisos.oportunidadesListVer || identity.permisos.oportunidadesListEdit)">
              <a routerLinkActive="active-item" routerLink="/auth/oportunidades" class="vsm-link">
                <i class="vsm-icon fas fa-hand-holding-usd"></i>
                <span class="vsm-title">Oportunidades</span>
              </a>
            </div>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter" *ngIf="tipoSidebar === 1 && identity.permisos
              && ( identity.permisos.seguimientoCumplimientoEdit || identity.permisos.seguimientoCumplimientoVer)">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-display1"></i>
                    <span class="vsm-title">Control</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <!-- <div class="vsm-item" *ngIf="identity.permisos && (identity.permisos.actividadesListVer || identity.permisos.actividadesListEdit)">
                        <a routerLinkActive="active-item" routerLink="/auth/actividades/" class="vsm-link">
                          <span class="vsm-title">Actividades</span>
                        </a>
                      </div> -->
                      <div class="vsm-item" *ngIf="identity.permisos && (identity.permisos.seguimientoCumplimientoEdit || identity.permisos.seguimientoCumplimientoVer)">
                        <a routerLinkActive="active-item" routerLink="/auth/cumplimiento/" class="vsm-link">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Seguimiento de cumplimiento</span>
                        </a>
                      </div> 
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter" *ngIf="identity.permisos && ( 
              ((tipoSidebar === 1 || tipoSidebar === 2) && (identity.permisos.catDepartamentosEdit || identity.permisos.catDepartamentosVer || 
              identity.permisos.catDivisionesEdit || identity.permisos.catDivisionesVer || 
              identity.permisos.catSucursalesEdit || identity.permisos.catSucursalesVer || 
              identity.permisos.catColoresEdit || identity.permisos.catColoresVer || 
              identity.permisos.catServiciosEdit || identity.permisos.catServiciosVer || 
              identity.permisos.catTipoServicioEdit || identity.permisos.catTipoServicioVer || 
              identity.permisos.catClienteEdit || identity.permisos.catClienteVer)) || 
              ((tipoSidebar === 1 || tipoSidebar === 3) && (
              identity.permisos.catIndustriasVer || identity.permisos.catIndustriasEdit ||
              identity.permisos.catOrigenesVer || identity.permisos.catOrigenesEdit ||
              identity.permisos.catPaisesVer || identity.permisos.catPaisesEdit )))">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon lnr lnr-list"></i>
                    <span class="vsm-title">Catálogos</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item" *ngIf="(tipoSidebar === 1 || tipoSidebar === 2) && identity.permisos && (identity.permisos.catDepartamentosEdit || identity.permisos.catDepartamentosVer)">
                        <a routerLinkActive="active-item" routerLink="/auth/catalogos/areas/" class="vsm-link">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Áreas</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(tipoSidebar === 1 || tipoSidebar === 2) && identity.permisos && (identity.permisos.catDivisionesEdit || identity.permisos.catDivisionesVer)">
                        <a routerLinkActive="active-item" routerLink="/auth/catalogos/divisiones/" class="vsm-link">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Divisiones</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(tipoSidebar === 1 || tipoSidebar === 2) && identity.permisos && (identity.permisos.catSucursalesEdit || identity.permisos.catSucursalesVer)">
                        <a routerLinkActive="active-item" routerLink="/auth/catalogos/sucursales/" class="vsm-link">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Sucursales</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(tipoSidebar === 1 || tipoSidebar === 2) && identity.permisos && (identity.permisos.catColoresEdit || identity.permisos.catColoresVer)">
                        <a routerLinkActive="active-item" routerLink="/auth/catalogos/colores/" class="vsm-link">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Colores</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(tipoSidebar === 1 || tipoSidebar === 2) && identity.permisos && (identity.permisos.catServiciosEdit || identity.permisos.catServiciosVer)">
                        <a routerLinkActive="active-item" routerLink="/auth/catalogos/servicios/" class="vsm-link">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Servicios</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(tipoSidebar === 1 || tipoSidebar === 2) && identity.permisos && (identity.permisos.catClienteEdit || identity.permisos.catClienteVer)">
                        <a routerLinkActive="active-item" routerLink="/auth/catalogos/clientes/" class="vsm-link">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Clientes</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(tipoSidebar === 1 || tipoSidebar === 2) && identity.permisos && (identity.permisos.catTipoServicioEdit || identity.permisos.catTipoServicioVer)">
                        <a routerLinkActive="active-item" routerLink="/auth/catalogos/tipo-servicios/" class="vsm-link">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Tipo de servicios</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(tipoSidebar === 1 || tipoSidebar === 3) && identity.permisos && (identity.permisos.catIndustriasVer || identity.permisos.catIndustriasEdit)">
                        <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/catalogos/industrias']">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Industrias</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(tipoSidebar === 1 || tipoSidebar === 3) && identity.permisos && (identity.permisos.catOrigenesVer || identity.permisos.catOrigenesEdit)">
                        <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/catalogos/origen-contactos']">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Origen contactos</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(tipoSidebar === 1 || tipoSidebar === 3) && identity.permisos && (identity.permisos.catPaisesVer || identity.permisos.catPaisesEdit)">
                        <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/catalogos/paises']">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Países</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <div class="vsm-item" *ngIf="tipoSidebar === 2 && identity.permisos && (identity.permisos.rptIndicador || identity.permisos.rptIndicadorArea || 
                                                              identity.permisos.rptIndicadorUsuario || identity.permisos.rptActividades)">
              <a routerLinkActive="active-item" routerLink="/auth/reportes" class="vsm-link">
                <i class="vsm-icon pe-7s-note2"></i>
                <span class="vsm-title">Reportes</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="tipoSidebar === 3 && identity.permisos && (identity.permisos.rptKpiVendedores || identity.permisos.rptCumpleannos || 
                                                              identity.permisos.rptOportunidadEtapas || identity.permisos.rptOportunidadPropietarios)">
              <a routerLinkActive="active-item" routerLink="/auth/reportes/crm" class="vsm-link">
                <i class="vsm-icon pe-7s-note2"></i>
                <span class="vsm-title">Reportes</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="tipoSidebar === 2 && identity.permisos && (identity.permisos.rptCoberturaDia || identity.permisos.rptCobertura || 
                                                              identity.permisos.rptServicioCop || identity.permisos.rptBajaVacantes || 
                                                              identity.permisos.rptServicioInicia || identity.permisos.rptServicioTermina)">
              <a routerLinkActive="active-item" routerLink="/auth/operaciones" class="vsm-link">
                <i class="vsm-icon pe-7s-note2"></i>
                <span class="vsm-title">Operaciones</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="tipoSidebar === 4 && identity.permisos && (identity.permisos.ticketsVer || identity.permisos.ticketsEdit)">
              <a routerLinkActive="active-item" routerLink="/auth/tickets" class="vsm-link">
                <i class="vsm-icon pe-7s-ticket"></i>
                <span class="vsm-title">Tickets</span>
              </a>
            </div>
            <hr class="d-block d-lg-none">
            <div class="vsm-item d-block d-lg-none">
              <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/change-password']">
                <i class="vsm-icon pe-7s-unlock"></i>
                <span class="vsm-title">Cambiar contraseña</span>
              </a>
            </div>
            <div class="vsm-item d-block d-lg-none">
              <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/setting-profile']">
                <i class="vsm-icon pe-7s-user"></i>
                <span class="vsm-title">Perfil</span>
              </a>
            </div>
            <!-- <div class="vsm-item d-block d-lg-none">
              <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/notificaciones']">
                <i class="vsm-icon pe-7s-bell"></i>
                <span class="vsm-title">Notificaciones</span>
              </a>
            </div> -->
            <div class="vsm-item d-block d-lg-none">
              <a routerLinkActive="active-item" class="vsm-link" (click)="logout()">
                <i class="vsm-icon pe-7s-back-2"></i>
                <span class="vsm-title">Cerrar sesión</span>
              </a>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
