import { Pipe, PipeTransform } from '@angular/core';
import { ReporteUsuario } from '../models/tablero-kpi';

interface ObjFiltroReporteUsuario {
  area: string;
  usuario: string;
  indicador: string;
  tipo: string;
}

@Pipe({
  name: 'filtroIndicadorUsuario'
})
export class FiltroIndicadorUsuarioPipe implements PipeTransform {

  transform(value: ReporteUsuario[], args: ObjFiltroReporteUsuario): any {
    if (!value.length) {
      return [];
    }
    if (!args) {
      return value;
    }
    const listaFiltrada = value.filter( (item: ReporteUsuario) => {
      if (
        (!args.area || (args.area && item.area === args.area)) && 
        (!args.usuario || (args.usuario && item.nombre === args.usuario)) && 
        (!args.tipo || (args.tipo && item.tipo === args.tipo)) && 
        (!args.indicador || (args.indicador && item.indicador === args.indicador))){
        return true;
      } else {
        return false;
      }
    });
    return listaFiltrada;
  }

}
