import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import * as usuarioActions from '../actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { UsuariosService } from '../../services/usuarios.service';
import { Router } from '@angular/router';
import { CargarUsuario, EditarUsuario, EditarUsuarioPerfil, EliminarUsuario } from '../actions';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Location } from '@angular/common';
import { User } from 'src/app/models/user';
@Injectable()
export class UsuarioEffects {

    constructor(
        private actions$: Actions,
        public usuariosService: UsuariosService,
        public router: Router,
        private toastr: ToastrService,
        private authService: AuthService,
        private location: Location,
    ) {}

    @Effect()
    cargarUsuario$ = this.actions$.pipe(
        ofType( usuarioActions.CARGAR_USUARIO ),
        switchMap( (action: CargarUsuario ) => {

            const id = action.id;

            return this.usuariosService.getUser(id)
                .pipe(
                    map( user => new usuarioActions.CargarUsuarioSuccess(user.usuario) ),
                    catchError( error => of(new usuarioActions.CargarUsuarioFail(error))  )
                );
        })
    );

    @Effect()
    editarUsuario$ = this.actions$.pipe(
        ofType( usuarioActions.EDITAR_USUARIO),
        switchMap( (action: EditarUsuario) => {
            return this.usuariosService.registerUser(action.usuario).pipe(
                map( (response) => {
                    const usr: User = action.usuario;
                    this.toastr.success('Usuario actualizado correctamente');
                    const ident = this.authService.printIdentity();
                    if (action.usuario && action.usuario.id === ident.id) {
                        ident.nombre = usr.nombre;
                        ident.apellidos = usr.apellidoPaterno + ' ' + usr.apellidoMaterno;
                        ident.email = usr.email;
                    }
                    // this.location.back();
                    this.router.navigate(['/auth/users']);
                    this.authService.setIdentity(ident);
                    return new usuarioActions.LoadAuthSuccess(ident);
                    // return new usuarioActions.CargarUsuarioSuccess(response.usuario);
                }), catchError( error => of(new usuarioActions.CargarUsuarioFail(error)))
            );
        })
    );

    @Effect()
    eliminaUsuario$ = this.actions$.pipe(
        ofType( usuarioActions.ELIMINAR_USUARIO),
        switchMap( (action: EliminarUsuario) => {
            return this.usuariosService.deleteUser(action.id).pipe(
                map( (response) => {
                    if (response.usuario && response.usuario.id) {
                        this.router.navigate(['/auth/usuarios']);
                    }
                    return [new usuarioActions.EliminarUsuarioSuccess(response.usuario),
                            new usuarioActions.EliminarUsuarios(action.id)
                    ];
                }), catchError( error => of(new usuarioActions.EditarUsuarioFail(error)))
            );
        })
    );

    @Effect()
    editarUsuarioPerfil$ = this.actions$.pipe(
        ofType( usuarioActions.EDITAR_USUARIO_PERFIL),
        switchMap( (action: EditarUsuarioPerfil) => {
            return this.usuariosService.updateUser(action.usuario).pipe(
                map( (response) => {
                    const usr: User = action.usuario;
                    if (action.usuario && action.usuario.id) {
                        this.router.navigate(['/auth/users']);
                    }
                    this.toastr.success('Usuario actualizado correctamente');
                    const ident = this.authService.printIdentity();
                    ident.nombre = usr.nombre;
                    ident.apellidos = usr.apellidoPaterno + ' ' + usr.apellidoMaterno;
                    ident.email = usr.email;
                    this.location.back();
                    this.authService.setIdentity(ident);
                    return new usuarioActions.LoadAuthSuccess(ident);
                    // new usuarioActions.GetAuthAction();
                    // return new usuarioActions.EditarUsuarioPerfilSuccess(response.usuario);
                }), catchError( error => of(new usuarioActions.EditarUsuarioPerfilFail(error)))
            );
        })
    );




}
