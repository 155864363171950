import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { ConsultasService } from 'src/app/services/consultas.service';


@Injectable()
export class ConsultarEffects {

  constructor(
    private actions$: Actions,
    public consultasService: ConsultasService
  ) {}

  @Effect()
  consultarIndustrias$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_INDUSTRIAS ),
    switchMap( () => {
      return this.consultasService.obtenerIndustrias()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaIndustriasSuccess(respuesta.industrias);
          }),
          catchError( error => of(new usuariosActions.ConsultaIndustriasFail(error))  )
        );
    })
  );

  @Effect()
  consultarEmpresas$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_EMPRESAS ),
    switchMap( (action: any) => {
      return this.consultasService.obtenerEmpresas(action.searchText, action.limite)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaEmpresasSuccess(respuesta.empresas);
          }),
          catchError( error => of(new usuariosActions.ConsultaEmpresasFail(error))  )
        );
    })
  );

  @Effect()
  consultarUsuarios$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_USUARIOS ),
    switchMap( () => {
      return this.consultasService.obtenerUsuarios()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaUsuariosSuccess(respuesta.usuarios);
          }),
          catchError( error => of(new usuariosActions.ConsultaUsuariosFail(error))  )
        );
    })
  );

  // @Effect()
  // consultarContactos$ = this.actions$.pipe(
  //   ofType( usuariosActions.CONSULTA_CONTACTOS ),
  //   switchMap( () => {
  //     return this.consultasService.obtenerContactos()
  //       .pipe(
  //         map( (respuesta) => {
  //           return new usuariosActions.ConsultaContactosSuccess(respuesta.contactos);
  //         }),
  //         catchError( error => of(new usuariosActions.ConsultaContactosFail(error))  )
  //       );
  //   })
  // );

  @Effect()
  consultarContactosEmpresa$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_CONTACTOS_EMPRESA ),
    switchMap( (action: any) => {
      return this.consultasService.obtenerContactosEmpresa(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ConsultaContactosEmpresaSuccess(respuesta.contactos);
            }),
            catchError( error => of(new usuariosActions.ConsultaContactosEmpresaFail(error))  )
        );
    })
  );

  // @Effect()
  // consultarOportunidadesEmpresa$ = this.actions$.pipe(
  //   ofType( usuariosActions.CONSULTA_OPORTUNIDADES_EMPRESA ),
  //   switchMap( (action: any) => {
  //     return this.consultasService.obtenerOportunidadesEmpresa(action.id)
  //       .pipe(
  //           map( respuesta => {
  //             return new usuariosActions.ConsultaOportunidadesEmpresaSuccess(respuesta.oportunidades);
  //           }),
  //           catchError( error => of(new usuariosActions.ConsultaOportunidadesEmpresaFail(error))  )
  //       );
  //   })
  // );

  @Effect()
  consultarEtapas$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_ETAPAS ),
    switchMap( () => {
      return this.consultasService.obtenerEtapas()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaEtapasSuccess(respuesta.etapas);
          }),
          catchError( error => of(new usuariosActions.ConsultaEtapasFail(error))  )
        );
    })
  );

  @Effect()
  consultarPaises$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_PAISES ),
    switchMap( () => {
      return this.consultasService.obtenerPaises()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaPaisesSuccess(respuesta.paises);
          }),
          catchError( error => of(new usuariosActions.ConsultaPaisesFail(error))  )
        );
    })
  );

  @Effect()
  consultarClasificaciones$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_CLASIFICACIONES ),
    switchMap( () => {
      return this.consultasService.obtenerClasificaciones()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaClasificacionesSuccess(respuesta.clasificaciones);
          }),
          catchError( error => of(new usuariosActions.ConsultaClasificacionesFail(error))  )
        );
    })
  );

  @Effect()
  consultarEtapasContactos$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_ETAPAS_CONTACTOS ),
    switchMap( () => {
      return this.consultasService.obtenerEtapasContactos()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaEtapasContactosSuccess(respuesta.etapas);
          }),
          catchError( error => of(new usuariosActions.ConsultaEtapasContactosFail(error))  )
        );
    })
  );

  @Effect()
  consultarEstadosContactos$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_ESTADOS_CONTACTOS ),
    switchMap( () => {
      return this.consultasService.obtenerEstadosContactos()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaEstadosContactosSuccess(respuesta.estados);
          }),
          catchError( error => of(new usuariosActions.ConsultaEstadosContactosFail(error))  )
        );
    })
  );

  @Effect()
  consultarOrigenContacto$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_ORIGEN_CONTACTOS ),
    switchMap( () => {
      return this.consultasService.obtenerOrigenContactos()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaOrigenContactosSuccess(respuesta.origen);
          }),
          catchError( error => of(new usuariosActions.ConsultaOrigenContactosFail(error))  )
        );
    })
  );

  @Effect()
  consultarTipoOrigen$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_TIPOS_ORIGEN ),
    switchMap( () => {
      return this.consultasService.obtenerTiposOrigen()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaTiposOrigenSuccess(respuesta.tipos);
          }),
          catchError( error => of(new usuariosActions.ConsultaTiposOrigenFail(error))  )
        );
    })
  );

  // @Effect()
  // consultarOportunidadesContacto$ = this.actions$.pipe(
  //   ofType( usuariosActions.CONSULTA_OPORTUNIDADES_CONTACTO ),
  //   switchMap( (action: any) => {
  //     return this.consultasService.obtenerOportunidadesContacto(action.id)
  //       .pipe(
  //           map( respuesta => {
  //             return new usuariosActions.ConsultaOportunidadesContactoSuccess(respuesta.oportunidades);
  //           }),
  //           catchError( error => of(new usuariosActions.ConsultaOportunidadesContactoFail(error))  )
  //       );
  //   })
  // );

  // @Effect()
  // consultarContactosOportunidad$ = this.actions$.pipe(
  //   ofType( usuariosActions.CONSULTA_CONTACTOS_OPORTUNIDAD ),
  //   switchMap( (action: any) => {
  //     return this.consultasService.obtenerContactosOportunidad(action.id)
  //       .pipe(
  //           map( respuesta => {
  //             return new usuariosActions.ConsultaContactosOportunidadSuccess(respuesta.contactos);
  //           }),
  //           catchError( error => of(new usuariosActions.ConsultaContactosOportunidadFail(error))  )
  //       );
  //   })
  // );


  @Effect()
  consultarOportunidadesGanadasPerdidasEmpresa$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_EMPRESA ),
    switchMap( (action: any) => {
      return this.consultasService.obtenerOportunidadesGanadasPerdidasEmpresa(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ConsultaOportunidadesGanadasPerdidasEmpresaSuccess(respuesta.oportunidades.ganadas, respuesta.oportunidades.perdidas);
            }),
            catchError( error => of(new usuariosActions.ConsultaOportunidadesGanadasPerdidasEmpresaFail(error))  )
        );
    })
  );

  @Effect()
  consultarOportunidadesGanadasPerdidasContacto$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_OPORTUNIDADES_GANADAS_PERDIDAS_CONTACTO ),
    switchMap( (action: any) => {
      return this.consultasService.obtenerOportunidadesGanadasPerdidasContacto(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ConsultaOportunidadesGanadasPerdidasContactoSuccess(respuesta.oportunidades.ganadas, respuesta.oportunidades.perdidas);
            }),
            catchError( error => of(new usuariosActions.ConsultaOportunidadesGanadasPerdidasContactoFail(error))  )
        );
    })
  );

  @Effect()
  consultarUsosCfdis$ = this.actions$.pipe(
    ofType( usuariosActions.CONSULTA_USOS_CFDIS ),
    switchMap( () => {
      return this.consultasService.obtenerUsosCfdis()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ConsultaUsosCfdisSuccess(respuesta.usosCfdis);
          }),
          catchError( error => of(new usuariosActions.ConsultaUsuariosFail(error))  )
        );
    })
  );

}
