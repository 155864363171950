import { Component, OnInit, OnDestroy } from '@angular/core';
import { CoberturaOperacional } from 'src/app/models/reporte-asignacion';
import { Pagination } from 'src/app/models/pagination';
import { Fechas } from 'src/app/models/fechas';
import { DatePipe } from '@angular/common';
import { Identity } from 'src/app/models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import {ExcelService} from 'src/app/services/excel.service';
import * as usuariosActions from 'src/app/store/actions';
import * as moment from 'moment';

@Component({
  selector: 'app-reporte-asignacion',
  templateUrl: './reporte-asignacion.component.html',
  styleUrls: ['./reporte-asignacion.component.sass']
})
export class ReporteAsignacionComponent implements OnInit, OnDestroy {
  heading = 'Resumen de la cobertura operacional';
  subheading = 'Cobertura operacional';
  icon = ' pe-7s-note2 icon-gradient bg-happy-fisher';
  public datePipe = new DatePipe("en-US");
  public dates: Date[] = [];
  public anio: string;
  public resultados: CoberturaOperacional[] = [];
  public resultadoTotal: CoberturaOperacional = new CoberturaOperacional (null);
  public paginacion = new Pagination(null);
  public chips = [];
  public categories = [];
  public seriesBar = [];
  public seriesPie = [];
  public titlePie: string = "TNC's";
  public removable: boolean;
  public selectable: boolean;
  public loading: boolean;
  public dias: Fechas;
  public identity: Identity;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionReporteAsignacion: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private excelService: ExcelService,
  ) {
  const fecha = new Date();
  this.removable = true;
  this.selectable = true;
  fecha.setDate(fecha.getDate() - 15);
  this.paginacion.sortType = '-id'
	this.dates = [ fecha,new Date() ];
	this.dias = new Fechas(null);
  this.loading = false;
   }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionReporteAsignacion = this.store.select('reporteAsignacion').subscribe(
      (state) => {
        let cantidad = 0;
        this.resultadoTotal = new CoberturaOperacional (null);
        this.resultadoTotal.nombre = 'Total';
        this.resultados = [...state.coberturaOperacional];
        this.categories = this.resultados.map(item => {
          return item.nombre 
        });

        this.seriesBar = this.resultados.map(item => {
          return item.cop
        });
        
        this.seriesPie = this.resultados.map(item => {
          return item.faltas
        });

       this.resultados.find(item => {
         cantidad = cantidad + 1;
         this.resultadoTotal.bajas = this.resultadoTotal.bajas + item.bajas ;
         this.resultadoTotal.cop = this.resultadoTotal.cop + item.cop;
         this.resultadoTotal.cortesia = this.resultadoTotal.cortesia + item.cortesia ;
         this.resultadoTotal.elementos = this.resultadoTotal.elementos + item.elementos;
         this.resultadoTotal.extraordinario = this.resultadoTotal.extraordinario + item.extraordinario ;
         this.resultadoTotal.faltas = this.resultadoTotal.faltas + item.faltas;
         this.resultadoTotal.fijo = this.resultadoTotal.fijo + item.fijo ;
         this.resultadoTotal.turnos = this.resultadoTotal.turnos + item.turnos;
         this.resultadoTotal.vacantes = this.resultadoTotal.vacantes + item.vacantes;
        });
        this.resultadoTotal.cop = (this.resultadoTotal.cop / cantidad);
      }
    );
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionReporteAsignacion.unsubscribe();
  }

  fecha(fecha:Date[]){
    if(fecha && fecha.length === 2){
      const fecha1= this.datePipe.transform(fecha["0"], 'yyyy-MM-dd');
      const fecha2= this.datePipe.transform(fecha["1"], 'yyyy-MM-dd');
      const anio1 = this.datePipe.transform(fecha["0"], 'yyyy');
      const anio2 = this.datePipe.transform(fecha["1"], 'yyyy');      

      if(anio1 === anio2){
        this.anio = anio1;
      }else{
        this.anio = (anio1 + '-' + anio2);
      }
      this.dias = {fechaInicio: fecha1, fechaFinal: fecha2};
      this.store.dispatch(new usuariosActions.CargarReporteCoberturaOperacional(this.dias));
    }
  }


  
  async crearExcel() {
    this.loading = true;
    let excel;

    excel = this.resultados.map( item => {
      const obj: any = {};
      obj.DIVISION= item.nombre;
      obj.NÚMERO_ELEMENTOS = item.elementos;
      obj.TURNOS = item.turnos;
      obj.TNCS = item.faltas;
      obj.COP = item.cop;
      obj.SERVICIOS_FIJOS = item.fijo;
      obj.SERVICIOS_EXTRAORDINARIOS = item.extraordinario;
      obj.SERVICIOS_CORTESIA = item.cortesia;
      return obj;
    });
    this.excelService.exportAsExcelFile(excel, 'Resumen de la cobertura operacional');
    this.loading = true;
    
  }




  textSearchChips(){
    if(this.paginacion.textSearch.length > 0){
      this.chips = [...this.paginacion.textSearch.split(' ')];
    }
    
  }

  remove(chips){
    this.chips = [...this.chips.filter(item => item !== chips)];
    this.paginacion.textSearch = this.chips.join(' ');
  }




}
