<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" >
  <div class="col-md-12">
    <div class="main-card mb-3 card">
      <div class="card-header">
			  <div class="row">
          <input type="text" placeholder="Fecha" class="form-control d-none d-lg-block" placement="right" bsDaterangepicker
          [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)" autocomplete="off">
          <input type="text" placeholder="Fecha" class="form-control d-lg-none" bsDaterangepicker
          [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="fecha($event)" autocomplete="off">
        </div>
        <div class="btn-actions-pane-right" *ngIf="filtro == true">
          <button type="button" (click)="activarFiltro()"  class=" btn-icon btn-icon-only btn-pill btn btn-outline-link">
            <i class="fa fa-filter btn-icon-wrapper"></i>
          </button>
        </div>
      </div>
      <div *ngIf= "existe === true">
      <div class="p-3 row">
        <div class="col-md-4" *ngIf="activar_filtro === true">
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="card-header-title font-size-lg text-capitalize font-weight-normal"><b>Filtros</b></div>
              <div class="btn-actions-pane-right">
                <button type="button" (click)="activarFiltro()"  class=" btn-icon btn-icon-only btn-pill btn btn-outline-link">
                  <i class=" ion-md-close btn-icon-wrapper"></i>
                </button>
              </div>
            </div>
            <div class="form-group p-3">
              <div class="form-group">
                <label for="tamaño"><b>Tamaño de la tabla:</b></label>
                <select class="custom-select" id="pageSizeCotizacion" name="pageSizeCotizacion" #pageSizeCotizacion="ngModel" [(ngModel)]="paginacion.pageSize">
                  <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="area"><b>Área:</b></label>
                <ng-select [items]="area"
                    bindLabel="nombre"
                    bindValue="nombre"
                    placeholder="area"
                    [(ngModel)]="filtro_area">
                </ng-select>
              </div>
              <div class="form-group">
                <label for="tipo"><b>Tipo:</b></label>
                <ng-select [items]="tipo"
                    bindLabel="nombre"
                    bindValue="nombre"
                    placeholder="tipo"
                    [(ngModel)]="filtro_tipo">
                </ng-select>
              </div>
            <div class="form-group">
              <label for="indicador"><b>Indicador:</b></label>
              <ng-select [items]="indicador"
                  bindLabel="nombre"
                  bindValue="nombre"
                  placeholder="indicador"
                  [(ngModel)]="filtro_indicador">
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="{'col-md-8':activar_filtro === true, 'col-md-12':activar_filtro === false}">
        <div class="table-responsive form-group">
          <table class="align-middle mb-0 table table-table-striped table-hover table-bordered"  matSort (matSortChange)="sortData($event)">
            <thead>
              <tr>
                <th mat-sort-header="area" class="text-center" >Área</th>
                <th mat-sort-header="tipo" class="text-center" width="100">Tipo</th>
                <th mat-sort-header="indicador" class="text-center">Indicador</th>
                <th mat-sort-header="objetivo" class="text-center " width="90">Objetivo</th>
                <th mat-sort-header="resultado" class="text-center" width="110">%</th>
              </tr>
            </thead>
            <tbody *ngIf="paginacion.pageSize != -1"> 
              <tr *ngFor = "let indicador of indicadores | filtroIndicadorArea: {indicador: filtro_indicador, tipo: filtro_tipo, area: filtro_area} | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType]  | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize">
                <td class="text-left text-muted">{{indicador.area}}</td>
                <td class="text-left text-muted">{{indicador.tipo}}</td>
                <td class="text-left text-muted">{{indicador.indicador}}</td>
                <td class="text-left text-muted" *ngIf= "indicador.unidad === '%' "><b>{{indicador.objetivo}}{{indicador.unidad}}</b></td>
                <td class="text-left text-muted"*ngIf= "indicador.unidad !== '%' "><b>{{indicador.unidad}}{{indicador.objetivo}}</b></td>
                <td class="text-left text-muted">
                  <div class="widget-content p-0 w-100">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left pr-0 pl-0 fsize-1 col-md-7">
                        <b *ngIf= "indicador.unidad === '%' ">{{indicador.resultado_final}}{{indicador.unidad}}</b>
                        <b *ngIf= "indicador.unidad !== '%' ">{{indicador.unidad}}{{indicador.resultado_final}}</b>
                      </div>
                      <div class="widget-content-right w-100 pr-0 pl-0 col-md-5">
                        <div class="progress-bar-xs progress">
                          <div class="progress-bar " role="progressbar" aria-valuenow="54" aria-valuemin="0"
                              aria-valuemax="100" style="width: 100%;" [style.background]="indicador.color_final" ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="paginacion.pageSize == -1"> 
              <tr *ngFor = "let indicador of indicadores | filtroIndicadorArea: {indicador: filtro_indicador, tipo: filtro_tipo, area: filtro_area} ">
                <td class="text-left text-muted">{{indicador.area}}</td>
                <td class="text-left text-muted">{{indicador.tipo}}</td>
                <td class="text-left text-muted">{{indicador.indicador}}</td>
                <td class="text-left text-muted" *ngIf= "indicador.unidad === '%' "><b>{{indicador.objetivo}}{{indicador.unidad}}</b></td>
                <td class="text-left text-muted"*ngIf= "indicador.unidad !== '%' "><b>{{indicador.unidad}}{{indicador.objetivo}}</b></td>
                <td class="text-left text-muted">
                  <div class="widget-content p-0 w-100">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left pr-0 pl-0 fsize-1 col-md-7">
                        <b *ngIf= "indicador.unidad === '%' ">{{indicador.resultado_final}}{{indicador.unidad}}</b>
                        <b *ngIf= "indicador.unidad !== '%' ">{{indicador.unidad}}{{indicador.resultado_final}}</b>
                      </div>
                      <div class="widget-content-right w-100 pr-0 pl-0 col-md-5">
                        <div class="progress-bar-xs progress">
                          <div class="progress-bar " role="progressbar" aria-valuenow="54" aria-valuemin="0"
                              aria-valuemax="100" style="width: 100%;" [style.background]="indicador.color_final" ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6 form-group" *ngIf="paginacion.pageSize != -1">
		      <div class="text-left">
			      <ngb-pagination *ngIf="indicadores" [collectionSize]="(indicadores | anyScope: paginacion.textSearch ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="3">
              <ng-template ngbPaginationFirst>First</ng-template>
              <ng-template ngbPaginationLast>Last</ng-template>
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
              <ng-template ngbPaginationEllipsis>...</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
			      </ngb-pagination>
			    </div>
        </div>
      </div>
		  </div>
    </div>
  </div>
</div>
</div>
