<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <form #registerForm="ngForm" (ngSubmit)="onSubmit(registerForm)" class="col-lg-10">
    <div class="col-sm-10 col-md-8 col-lg-6">
      <div class="main-card mb-3 card">
        <div class="card-header">
          Captura la información del usuario
        </div>
        <div class="card-body">
          <fieldset class="form-group required">
            <label for="usuarioUser" class="control-label">Usuario</label>
            <input type="text" id="usuarioUser" name="usuarioUser" #usuarioUser="ngModel" [(ngModel)]="user.usuario" 
              class="form-control" maxlength="30" minlength="3" [disabled]="user.id>0" placeholder="carlos.lopez" 
              title="Capture el nickname del usuario." (blur)="usuarioLower()" required />
            <span *ngIf="!usuarioUser.valid && usuarioUser.touched"> El nombre de usuario es obligatorio</span>
          </fieldset>
          <fieldset class="form-group required">
            <label for="nombreUser" class="control-label">Nombre</label>
            <input type="text" id="nombreUser" name="nombreUser" #nombreUser="ngModel" [(ngModel)]="user.nombre" 
              class="form-control" maxlength="50" minlength="2" placeholder="Carlos" title="Capture el nombre personal." required />
            <span *ngIf="!nombreUser.valid && nombreUser.touched"> El nombre es obligatorio</span>
          </fieldset>
          <fieldset class="form-group required">
            <label for="apellidoPaternoUser" class="control-label">Apellido Paterno</label>
            <input type="text" id="apellidoPaternoUser" name="apellidoPaternoUser" #apellidoPaternoUser="ngModel" 
              [(ngModel)]="user.apellidoPaterno" class="form-control" maxlength="50" minlength="2" 
              placeholder="Lopez" title="Capture el apellido paterno." required />
            <span *ngIf="!apellidoPaternoUser.valid && apellidoPaternoUser.touched"> El apellido paterno es obligatorio!!</span>
          </fieldset>
          <fieldset class="form-group">
            <label for="apellidoMaternoUser" class="control-label">Apellido Materno</label>
            <input type="text" id="apellidoMaternoUser" name="apellidoMaternoUser" #apellidoMaternoUser="ngModel" 
              [(ngModel)]="user.apellidoMaterno" class="form-control" maxlength="50" placeholder="Perez" title="Capture el apellido materno." />
          </fieldset>
          <fieldset class="form-group required">
            <label for="emailUser" class="control-label">Email</label>
            <input type="email" id="emailUser" name="emailUser" #emailUser="ngModel" [(ngModel)]="user.email" 
              class="form-control" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" maxlength="50"
              placeholder="example@example.com" title="Capture un correo electrónico." required />
            <span *ngIf="!emailUser.valid && emailUser.touched"> El correo electrónico no es correcto</span>
          </fieldset>
          <fieldset class="form-group required" *ngIf="user.id <= 0">
            <label for="passwordUser" class="control-label">Contraseña</label>
            <input type="password" id="passwordUser" name="passwordUser" #passwordUser="ngModel" [(ngModel)]="user.password" 
              class="form-control" maxlength="50" minlength="6" placeholder="******" title="Capture contraseña de 6 a 50 caracteres." required />
            <span *ngIf="!passwordUser.valid && passwordUser.touched"> La contraseña es obligatoria</span>
          </fieldset>
          <fieldset class="form-group required" *ngIf="user.id <= 0">
            <label for="passwordConfirmUser" class="control-label">Confirmar contraseña</label>
            <input type="password" id="passwordConfirmUser" name="passwordConfirmUser" #passwordConfirmUser="ngModel" 
              [(ngModel)]="user.passwordConfirmar" class="form-control" maxlength="50" minlength="6" placeholder="******"
              title="Reingrese la contraseña de 6 a 50 caracteres." required />
            <span *ngIf="!passwordConfirmUser.valid && passwordConfirmUser.touched"> La contraseña es obligatoria</span>
            <span *ngIf="passwordConfirmUser.valid && passwordConfirmUser.touched && user.passwordConfirmar!=user.password"> La contraseña no coincide</span>
          </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-3">
                <label for="tipoUser" class="control-label">Tipo: </label>
              </div>
              <div class="col-sm-9">
                <ng-select [items]="tipos"
                          bindLabel="nombre"
                          bindValue="id"
                          placeholder="Tipo"
                          id="tipoUser"  name="tipoUser" #tipoUser= "ngModel" [(ngModel)]="user.tipo">
                </ng-select>
                <!-- <div *ngFor="let tpUser of tipos; let i = index">
                  <label class="radio-inline">
                  <input type="radio" name="tipoUser{{i}}" [value]="tpUser.id" [(ngModel)]="user.tipo" required> {{tpUser.nombre}}
                  </label >
                </div> -->
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required" *ngIf="user.tipo !== 1 && user.tipo !== 5">
            <div class="row">
              <div class="col-sm-3">
                <label for="areaSelectedUsuario" class="control-label">Área: </label>
              </div>
              <div class="col-sm-9">
                <div class="row">
                  <div class="col-sm-9 form-group mb-0">
                    <ng-select [items]="departamentos"
                          bindLabel="nombre"
                          bindValue="id"
                          placeholder="departamentos"
                          id="areaSelectedUsuario"  name="areaSelectedUsuario" #areaSelectedUsuario= "ngModel" [(ngModel)]="areaSelected">
                    </ng-select>
                  </div>
                  <div class="col-sm-3">
                    <button type="button" class="mr-2 btn-icon btn-icon-only btn btn-outline-primary" (click)="agregarDepartamento()" [disabled] = "!areaSelected ">
                      <i class="fa fa-plus btn-icon-wrapper"> </i>
                    </button> 	
                  </div>	
                </div>
                <div class="main-card mb-3 card no-shadow" *ngIf="user.departamento.length">
                  <ul class="todo-list-wrapper list-group list-group-flush">
                    <li class="list-group-item" *ngFor="let departamento of user.departamento">
                      <div class="todo-indicator bg-primary"></div>
                      <div class="widget-content p-0">
                        <div class="widget-content-wrapper">
                          <div class="widget-content-left">
                            <div class="widget-heading"> {{departamento.nombre}} </div>
                          </div>
                          <div class="widget-content-right widget-content-actions">
                            <button type="button" (click)="deleteArea(departamento.id)" class="border-0 btn-transition btn btn-outline-danger">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </fieldset>
          <!-- <fieldset class="form-group required" *ngIf="user.tipo === 2 || user.tipo === 3">
            <div class="row">
              <div class="col-sm-3">
                <label for="tipoUser" class="control-label">Tipo Division: </label>
              </div>
              <div class="col-sm-9">
                <div *ngFor="let tpDivi of divisiones">
                  <label class="radio-inline">
                    <input type="radio" name="tpDivi.id" [value]="tpDivi.id" [(ngModel)]="user.division" required> {{tpDivi.nombre}}
                  </label>
                </div>
              </div>
            </div>
          </fieldset> -->
          <fieldset class="form-group">
            <div class="row">
              <div class="col-sm-3">
                <label for="clienteSelectedUsuario" class="control-label">Clientes: </label>
              </div>
              <div class="col-sm-9">
                <div class="row">
                  <div class="col-sm-9 form-group mb-0">
                    <ng-select [items]="clientes" 
                          bindLabel="nombre"
                          bindValue="id"
                          placeholder="clientes"
                          id="clienteSelectedUsuario"  name="clienteSelectedUsuario" #clienteSelectedUsuario= "ngModel" [(ngModel)]="clienteSelected">
                    </ng-select>
                  </div>
                  <div class="col-sm-3">
                    <button type="button" class="mr-2 btn-icon btn-icon-only btn btn-outline-primary" (click)="agregarClientes()" [disabled]="!clienteSelected ">
                      <i class="fa fa-plus btn-icon-wrapper"> </i>
                    </button> 	
                  </div>	
                </div>
                <div class="main-card mb-3 card no-shadow" *ngIf="user.clientes.length">
                  <ul class="todo-list-wrapper list-group list-group-flush">
                    <li class="list-group-item" *ngFor="let cliente of user.clientes">
                      <div class="todo-indicator bg-primary"></div>
                      <div class="widget-content p-0">
                        <div class="widget-content-wrapper">
                          <div class="widget-content-left">
                            <div class="widget-heading"> {{cliente.nombre}} </div>
                          </div>
                          <div class="widget-content-right widget-content-actions">
                            <button type="button" (click)="deleteCliente(cliente.id)" class="border-0 btn-transition btn btn-outline-danger">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="grid-menu grid-menu-2col border-top">
          <div class="no-gutters row">
            <div class="col-sm-6" *ngIf="identity.permisos.usuariosListVer">
              <a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger" [routerLink]="['/auth/users']">
                <i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
              </a>
            </div>
            <div class="col-sm-6" *ngIf="identity.permisos && identity.permisos.usuariosRegEdit">
              <button type="submit" class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" *ngIf="!cargando" [disabled]="!registerForm.form.valid || (user | json) === (userInicial | json) || ( user.tipo !== 1 && user.tipo !== 5 ? !user.departamento.length : false)">
                <i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
              </button>
              <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="cargando">
                <span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
                <br>
                Loading...
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>


<!-- <fieldset class="form-group required">
  <label for="cliente" class="control-label">Clientes: </label>
  <ng-select [items]="clientes"
      bindLabel="nombre"
      bindValue="id"
      placeholder="Cliente"
      [(ngModel)]="servicio.cliente" id="cliente"  name="cliente" #cliente= "ngModel" >
  </ng-select>
</fieldset> -->