import { Component, OnInit, OnDestroy } from '@angular/core';
import { Empresa, EmpresaSortType } from 'src/app/models/empresa';
import { EmpresasService } from 'src/app/services/empresa.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Pagination } from 'src/app/models/pagination';
import { MESSAGES } from 'src/app/models/messages';
import {ExcelService} from 'src/app/services/excel.service';
import * as usuariosActions from 'src/app/store/actions';
import { GLOBAL } from 'src/app/models/global';
import { state as stateAnimate, trigger, transition, animate, style } from '@angular/animations';
import { FilterType } from 'src/app/models/user';
import { AnyScopePipe } from 'src/app/pipes/any-scope.pipe';
import { MultiOpcionesSelectedPipe } from 'src/app/pipes/multi-opciones-selected.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.sass'],
  animations: [
    trigger('openClose', [
      // ...
      stateAnimate('open', style({
        height: '200px',
        opacity: 1,
        backgroundColor: 'yellow'
      })),
      stateAnimate('closed', style({
        height: '100px',
        opacity: 0.5,
        backgroundColor: 'green'
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class EmpresasComponent implements OnInit, OnDestroy {
  heading = 'Detalles de las empresas';
  subheading = 'Empresas registrandas en el sistema';
  icon = 'pe-7s-notebook icon-gradient bg-dark';
  public url: string;
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private subscribeEmpresa: Subscription = new Subscription();
  public empresas: Empresa[] = [];
  public loading: boolean;
  public paginacion = new Pagination(null);
  public filterActive = false;
  public orderType = [...EmpresaSortType];
  public version = 1;
  public listSearch: string[] = [];
  public filterIndustrias: FilterType[] = [];
  public filterPropietarios: FilterType[] = [];
  public filterPaises: FilterType[] = [];
  public opcDuplicados = false;
  public duplicados: { empresa: Empresa, duplicados: Empresa[] } = { empresa: new Empresa(null), duplicados: [] };
  public downloading = false;

  constructor(
    private empresasService: EmpresasService,
    private toastr: ToastrService,
    private store: Store<AppState>,
    private excelService: ExcelService,
    private modalService: NgbModal,
  ) {
    this.identity = new Identity(null);
    this.empresas = [];
    this.url = GLOBAL.url;
    this.duplicados = {empresa: new Empresa(null), duplicados: []};
    this.paginacion.sortType = '-fecModificacion';
    this.paginacion.optionSize= [
      { value: 10, description: '10 elementos por hoja' },
      { value: 20, description: '20 elementos por hoja' },
      { value: 50, description: '50 elementos por hoja' }
    ];
   }

   ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
        const date = new Date();
		    this.version = date.getTime();
      }
    );
    this.subscribeEmpresa = this.store.select('empresa').subscribe(
      (state) => {
        this.empresas = [...state.empresas.map(itm => {
          this.duplicados.duplicados.forEach(element => {
            if (itm.id === element.id) {
              itm.check = true;
            }
          });
          return itm;
        })];
        this.paginacion.totalSize = state.total;
        this.paginacion.totalFiltro = state.totalFiltro;
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.filterIndustrias = [...state.industrias.filter(item => item.status).map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        })];
        this.filterPropietarios = [...state.usuarios.map(item => {
          return new FilterType({id: item.id, nombre: item.descripcion, checked: false});
        })];
        this.filterPaises = [...state.paises.filter(item => item.status).map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        })];
      }
    );
    console.log('Inicializa component empresas...');
    this.store.dispatch(new usuariosActions.ConsultaIndustrias());
    this.store.dispatch(new usuariosActions.ConsultaClasificaciones());
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());
    this.store.dispatch(new usuariosActions.ConsultaPaises());
    this.cargarEmpresas();
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeEmpresa.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  eliminarEmpresa(empresa: Empresa){
    Swal.fire({
      title: '¿Deseas eliminar la empresa?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise( (resolve)  => {
          this.empresasService.eliminarEmpresa(empresa).subscribe(
            (response) => {
              if (response.respond) {
                this.store.dispatch(new usuariosActions.EliminarEmpresaSuccess(empresa.id));
                resolve(true);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.EliminarEmpresaFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Eliminado correctamente.',
            icon: 'success'
          });
        } else {
          Swal.close();
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  printCSV() {
    let excel;
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const pipeAnyScope = new AnyScopePipe();
    const pipeMultiOpcionesSelect = new MultiOpcionesSelectedPipe();
    let lista = [...this.empresas];
    lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterPaises, parametro: 'pais'});
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterIndustrias, parametro: 'industria'});
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterPropietarios, parametro: 'propietario'});
    if (lista) {
      excel = lista.map( item => {
        const obj: any = {};
        obj.NOMBRE = item.nombre;
        obj.PROPIETARIO = item.desPropietario;
        obj.INDUSTRIA = item.desIndustria;
        obj.TELEFONO = item.telefonoUno;
        obj.TELEFONO = obj.TELEFONO ? obj.TELEFONO + ', ' + item.telefonoDos : item.telefonoDos;
        obj.TELEFONO = obj.TELEFONO ? obj.TELEFONO + ', ' + item.telefonoTres : item.telefonoTres;
        obj.PAIS = item.desPais;
        obj.CODIGO_POSTAL = item.codigoPostal;
        obj.ESTADO = item.desEstado;
        obj.MUNICIPIO = item.desMunicipio;
        obj.COLONIA = item.colonia;
        obj.CALLE = item.calle;
        obj.RFC = item.rfc;
        obj.RAZON_SOCIAL = item.razonSocial;
        obj.F_PAIS = item.desPaisDatoFiscal;
        obj.F_CODIGO_POSTAL = item.codigoPostalDatoFiscal;
        obj.F_ESTADO = item.desEstadoDatoFiscal;
        obj.F_MUNICIPIO = item.desMunicipioDatoFiscal;
        obj.F_COLONIA = item.coloniaDatoFiscal;
        obj.F_CALLE = item.calleDatoFiscal;
        return obj;
      });
      this.excelService.exportAsExcelFile(excel, 'empresas_' + i);
    }
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.trim().split(' ').filter(itm => itm);
    if (this.paginacion.textSearch.trim() !== this.paginacion.textSearchAnterior) {
      this.cargarEmpresas();
    }
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
    this.cargarEmpresas();
  }

  changeIndustrias() {
    this.filterIndustrias = [...this.filterIndustrias];
    this.cargarEmpresas();
  }

  changePaises() {
    this.filterPaises = [...this.filterPaises];
    this.cargarEmpresas();
  }

  changePropietarios() {
    this.filterPropietarios = [...this.filterPropietarios];
    this.cargarEmpresas();
  }

  cargarEmpresas(reset = true) {
    if (reset) {
      this.paginacion.page = 1;
    }
    const opciones: any = {};
    this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
    opciones.numPage = this.paginacion.page;
    opciones.numLimit = this.paginacion.pageSize;
    opciones.desOrderBy = this.paginacion.sortType;
    opciones.textSearch = this.listSearch.join('%');
    opciones.desIndustria = this.filterIndustrias.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desPais = this.filterPaises.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desPropietario = this.filterPropietarios.filter( itm => itm.checked).map( itm => itm.id).join(',');
    this.store.dispatch(new usuariosActions.CargarEmpresas(opciones));
  }

  printDireccion(empresa: Empresa) {
    let coma = '';
    let direccion = '';
    if (empresa.desPais) {
      direccion += coma + empresa.desPais;
      coma = ', ';
    }
    if (empresa.desEstado) {
      direccion += coma + empresa.desEstado;
      coma = ', ';
    }
    if (empresa.desMunicipio) {
      direccion += coma + empresa.desMunicipio;
      coma = ', ';
    }
    if (empresa.colonia) {
      direccion += coma + empresa.colonia;
      coma = ', ';
    }
    if (empresa.calle) {
      direccion += coma + empresa.calle;
      coma = ', ';
    }
    if (empresa.codigoPostal) {
      direccion += coma + 'C.P. ' + empresa.codigoPostal;
      coma = ', ';
    } 
    if (direccion === '') {
      direccion = 'Sin domicilio';
    }
    return direccion;
  }

  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchEmpresas").focus();
  }

  empresasDuplicados(empresa: Empresa) {
    this.duplicados.empresa = new Empresa(null);
    const item = this.duplicados.duplicados.find( element => element.id === empresa.id);
    if (item && item.id && item.id > 0) {
      this.duplicados.duplicados = this.duplicados.duplicados.filter( item => item.id !== empresa.id );
    } else {
      this.duplicados.duplicados.push({...empresa});
    }
  }

  cancelarDuplicados() {
    this.duplicados = { empresa: new Empresa(null), duplicados: [] };
    this.opcDuplicados = false;
    this.empresas.map( item => {
      item.check = false;
      return item;
    });
  }

  open(content) {
    this.modalService.open(content, {
      size: 'lg'
    });
  }

  empresaDuplicado(empresa: Empresa) {
    this.duplicados.empresa = {...empresa};
  }

  guardarDuplicados() {
    const duplicados =  [...this.duplicados.duplicados.filter(item => item.id !== this.duplicados.empresa.id)];
    this.modalService.dismissAll('Close');
    this.store.dispatch(new usuariosActions.ActualizaEmpresasDuplicados(this.duplicados.empresa, duplicados));
    this.opcDuplicados = false;
    this.duplicados = { empresa: new Empresa(null), duplicados: [] };
  }

  closeSearch() {
    this.paginacion.isActive = false;
    this.paginacion.textSearch = '';
    this.listSearch = [];
    this.cargarEmpresas();
  }

  downloadExcel() {
    this.downloading = true;
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
    const opciones: any = {};
    opciones.numPage = this.paginacion.page;
    opciones.numLimit = this.paginacion.pageSize;
    opciones.desOrderBy = this.paginacion.sortType;
    opciones.textSearch = this.listSearch.join('%');
    opciones.desIndustria = this.filterIndustrias.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desPais = this.filterPaises.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desPropietario = this.filterPropietarios.filter( itm => itm.checked).map( itm => itm.id).join(',');
    this.empresasService.downloadExcelEmpresas(opciones).subscribe( respuesta => {
    // this.downloadService.downloadExcel(this.url + '/contactos/excel', opciones, 'contactos_' + i + '.xls').subscribe( respuesta => {
      // console.log(respuesta);
      // const blob = new Blob([respuesta.blob()], { type : 'application/vnd.ms.excel' });
      // const file = new File([blob], 'excel.xlsx', { type: 'application/vnd.ms.excel' });
      // saveAs(file);
      this.excelService.exportAsExcelFile(respuesta.empresas, 'empresas_' + i);
      this.downloading = false;
      // this.downloading = true;
      // // this.downloadState = respuesta;
      // if (respuesta.state === 'DONE') {
      //   setTimeout( () => {
      //     this.downloading = false;
      //   }, 2000 );
      // }
    }, error => {
      this.downloading = false;
    });
  }

  quitarDuplicado(empresa: Empresa) {
    this.duplicados.duplicados = this.duplicados.duplicados.filter( item => item.id !== empresa.id);
  }

}
