import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { ActividadesMultiplesService } from 'src/app/services/actividades-multiples.service';

@Injectable()
export class ActividadesMultipleEffects {

  constructor(
    private actions$: Actions,
    public actividadesMultiplesService: ActividadesMultiplesService
  ) {}

  @Effect()
  cargarActividadesEmpresa$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ACTIVIDADES_EMPRESA ),
    switchMap( (action: any) => {
      return this.actividadesMultiplesService.obtenerActividadesEmpresa(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarActividadesEmpresaSuccess(respuesta.actividades);
          }),
          catchError( error => of(new usuariosActions.CargarActividadesEmpresaFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusActividades$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_ACTIVIDADES_ESTATUS ),
    switchMap( (action: any) => {
      return this.actividadesMultiplesService.actualizaEstatusActividades(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaActividadesEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaActividadesEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaActividades$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_ACTIVIDADES ),
    switchMap( (action: any) => {
      return this.actividadesMultiplesService.registraActividades(action.actividad)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaActividadesSuccess(action.actividad);
            }),
            catchError( error => of(new usuariosActions.ActualizaActividadesFail(error))  )
        );
    })
  );

  @Effect()
  cargarActividadesContacto$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ACTIVIDADES_CONTACTO ),
    switchMap( (action: any) => {
      return this.actividadesMultiplesService.obtenerActividadesContactos(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarActividadesContactoSuccess(respuesta.actividades);
          }),
          catchError( error => of(new usuariosActions.CargarActividadesContactoFail(error))  )
        );
    })
  );

  @Effect()
  cargarActividadesOportunidad$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ACTIVIDADES_OPORTUNIDAD ),
    switchMap( (action: any) => {
      return this.actividadesMultiplesService.obtenerActividadesOportunidades(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarActividadesOportunidadSuccess(respuesta.actividades);
          }),
          catchError( error => of(new usuariosActions.CargarActividadesOportunidadFail(error))  )
        );
    })
  );

}
