import { SortOrder } from './sort';

export class Sucursal {
	public id: number;
	public nombre: string;
	public division: number;
	public desDivision: string;
	public desEstado: string;
	public usuarioRegistro: number;
	public desUsuarioRegistro: string;
	public usuarioModifico: number;
	public desUsuarioModifico: string;
	public fecRegistro: number;
	public fecModificacion: string;
	public status: boolean;
	constructor(
	  obj: ObjSucursal
	) {
	  this.id = obj && obj.id ? obj.id : 0;
	  this.nombre = obj && obj.nombre ? obj.nombre : '';
	  this.division = obj && obj.division ? obj.division : 0;
	  this.desDivision = obj && obj.desDivision ? obj.desDivision : '';
	  this.desEstado = obj && obj.desEstado ? obj.desEstado : '';
	  this.usuarioRegistro = obj && obj.usuarioRegistro ? obj.usuarioRegistro : 0;
	  this.desUsuarioRegistro = obj && obj.desUsuarioRegistro ? obj.desUsuarioRegistro : '';
	  this.usuarioModifico = obj && obj.usuarioModifico ? obj.usuarioModifico : 0;
	  this.desUsuarioModifico = obj && obj.desUsuarioModifico ? obj.desUsuarioModifico : '';
	  this.status = obj && obj.status ? obj.status : false;
	}
  }
  
  interface ObjSucursal {
	id: number;
	nombre: string;
	division: number;
	desDivision: string;
	desEstado: string;
	usuarioRegistro: number;
	desUsuarioRegistro: string;
	usuarioModifico: number;
	desUsuarioModifico: string;
	fecRegistro: number;
	fecModificacion: string;
	status: boolean;
  }


  export const SucursalSortType: SortOrder[] = [
	{ id: '+id', text: 'ID'},
	{ id: '-id', text: 'ID desc'},
	{ id: '+nombre', text: 'Nombre'},
	{ id: '-nombre', text: 'Nombre desc'},
	{ id: '+desDivision', text: 'Division'},
	{ id: '-desDivision', text: 'Division desc'},
	{ id: '+desEstado', text: 'Estado'},
	{ id: '-desEstado', text: 'Estado desc'}
  ];
  