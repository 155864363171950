import {Routes} from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { RestoreComponent } from './restore/restore.component';
import { NotFoundComponent } from 'src/app/layout/components/not-found/not-found.component';



import { LoginBoxedComponent } from './login-boxed/login-boxed.component';
import { RegisterComponent } from './register/register.component';
import { RegisterBoxedComponent } from './register-boxed/register-boxed.component';
import { ForgotPasswordBoxedComponent } from './forgot-password-boxed/forgot-password-boxed.component';
import { HomeComponent } from './home/home.component';


export const publicRoutes: Routes = [
  { path: '', component: LoginComponent, data: { extraParameter: ''} },
  { path: 'login', component: LoginComponent, data: { extraParameter: ''} },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { extraParameter: ''} },
  { path: 'recovery/key/:id', component: RecoveryComponent, data: { extraParameter: 'pagesMenu'} },
  { path: 'restore', component: RestoreComponent, data: { extraParameter: 'pagesMenu'} },
  { path: 'login-boxed', component: LoginBoxedComponent, data: {extraParameter: ''} },
  { path: 'home', component: HomeComponent, data: {extraParameter: ''} },
  { path: 'register', component: RegisterComponent, data: {extraParameter: ''} },
  { path: 'register-boxed', component: RegisterBoxedComponent, data: {extraParameter: ''} },
  { path: 'forgot-password-boxed', component: ForgotPasswordBoxedComponent, data: {extraParameter: ''} },

  { path: '**', component: LoginComponent }
];
