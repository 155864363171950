import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity } from 'src/app/models/user';
import { Ticket, TicketMensaje, AdjuntosMensaje } from 'src/app/models/ticket';
import { Pagination } from 'src/app/models/pagination';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { ToastrService } from 'ngx-toastr';
import * as usuariosActions from 'src/app/store/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketsService } from 'src/app/services/tickets.services';
import { GLOBAL } from 'src/app/models/global';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';

@Component({
  selector: 'app-details-tickets',
  templateUrl: './details-tickets.component.html',
  styleUrls: ['./details-tickets.component.sass']
})
export class DetailsTicketsComponent implements OnInit, OnDestroy {
  toggleMobileSidebar: any;
  public identity: Identity = new Identity(null);
  private subscriptionAuth: Subscription = new Subscription();
  private subscriptionTickets: Subscription = new Subscription();
  public filterActive = false;
  public paginacion: Pagination = new Pagination(null);
  public ticket: Ticket = new Ticket(null);
  public mensaje: TicketMensaje = new TicketMensaje(null);
  public attachments: any[] = [];
  public url: string;
  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private ticketesService: TicketsService,
  ) { 
    this.url = GLOBAL.url;
    this.store.dispatch(new usuariosActions.LimpiarTicket());
  }

  ngOnInit() {
    this.subscriptionAuth = this.store.select('auth').subscribe( state => {
      this.identity = state.identity;
    });
    this.subscriptionTickets = this.store.select('ticket').subscribe(
      (state) => {
        this.ticket.adjuntos = [];
        this.ticket = {...state.ticket};
        this.ticket.mensajes.find(item => {
          const arr = [...this.ticket.adjuntos, ...item.adjuntos];
          this.ticket.adjuntos = arr;
        });
      }
    );
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        if (id > 0) {
          // this.store.dispatch(new usuariosActions.CargarTicket(id));
          this.store.dispatch(new usuariosActions.ActualizaMensajeTicket(id));
        } 
    });
    console.log('Inicializa component datails-tickets...');
  }

  ngOnDestroy() {
    this.subscriptionAuth.unsubscribe();
    this.subscriptionTickets.unsubscribe();
  }

  agregarMensaje() {
    if(this.ticket && this.ticket.existe && (this.identity.id === this.ticket.usuarioRegistro || this.ticket.status !== 3)){
      this.validarMensaje().then( regresa => {
        if (regresa) {
          this.store.dispatch(new usuariosActions.EnviaMensajeTicket(this.mensaje));
          this.mensaje = new TicketMensaje(null);
        }
      });
    }else{
      this.toastr.warning('No puedes enviar el mensaje.');
    }
  }


  validarMensaje() {
    return new Promise( (resolve, reject) => {
      if (!this.mensaje.mensaje) {
        this.toastr.warning('El mensaje es requerido.');
        resolve(false);
      } else {
        const emisor = this.identity.departamentos.find( ite => ite.id === this.ticket.departamentoEmisor);
        const reseptor = this.identity.departamentos.find( ite => ite.id === this.ticket.departamento);

        this.mensaje.ticket = this.ticket.id;
        if(this.identity.departamentos.length === 1){
          this.identity.departamentos.find(item => {this.mensaje.departamento = item.id} );
        }else if (this.identity.id === this.ticket.usuarioRegistro){
          this.mensaje.departamento = this.ticket.departamentoEmisor;
        }else if (reseptor && reseptor.id){
          this.mensaje.departamento = emisor.id;
        }else if (emisor && emisor.id){
          this.mensaje.departamento = reseptor.id;
        }else{
          this.mensaje.departamento = this.ticket.departamento;
        }
        // this.mensaje.desDepartamento = this.identity.desDepartamento;
        // this.mensaje.desUsuario = this.identity.nombre + ' ' + this.identity.apellidos;
        resolve(true);
      }
    });
  }

  uploadAttachment(event: FileList) {
    const archivos: File[] = Array.from(event);
    if (archivos && archivos.length) {
      let validaImage = true;
      archivos.forEach(element => {
        const mimeType = element.type;
        if (mimeType.match(/image\/*/) == null && mimeType.match(/application\/*/) == null) {
          validaImage = false;
        }
      });
      if (validaImage) {
        const gr = archivos.map( (item) => {
          const otro: AdjuntosMensaje = new AdjuntosMensaje(null);
          const reader = new FileReader();
          reader.readAsDataURL(item);
          reader.onload = (_event) => {
            otro.urlPath = reader.result;
          }
          otro.type = item.type.split('/')[1];
          otro.size = item.size;
          otro.file = item;
          otro.name = item.name;
          otro.fecCreacion = new Date(item.lastModified);
          return otro;
        });
        this.mensaje.adjuntos = [ ...gr, ...this.mensaje.adjuntos ];
      } else {
        this.toastr.warning('El formato de archivo es incorrecto.');
      }
    }
  }

  deleteAttachment(index) {
    this.mensaje.adjuntos.splice(index, 1);
  }

  actualizaMensaje() {
    this.store.dispatch(new usuariosActions.ActualizaMensajeTicket(this.ticket.id));
    // this.store.dispatch(new usuariosActions.CargarTicket(this.ticket.id));
  }

  editarEstado(ticket: Ticket){
    const text = ticket.status === 1 ? '¿Deseas eliminar el ticket?' : '¿Deseas cerrar el ticket?';
    Swal.fire({
      title: text,
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animate__animated animate__bounce' }, hideClass: { popup: 'animate__animated animate__bounce' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.ticketesService.actualizaEstatusTicket(ticket).subscribe(
            (response) => {
              if (response.respond) {
                this.store.dispatch(new usuariosActions.ActualizaTicketEstatusSuccess(ticket.id, ticket.status));
                resolve(true);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaTicketEstatusFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Actualización correctamente.',
            icon: 'success'
          });
          this.router.navigate(['/auth/tickets']);
        } else {
          Swal.close();
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

}
