import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from 'src/app/models/global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getLineContactos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/line/contactos', {headers});
  }

  getLineEmpresas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/line/empresas', {headers});
  }

  getLineOportunidades(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/line/oportunidades', {headers});
  }

  getNumeroActividad(dia:string): Observable<any> {
		const params = JSON.stringify({dia});
		const headers = new HttpHeaders().set('Content-type', 'application/json');
		
		return this.http.post(this.url + '/dashboard/actividades/num_actividades/', params, {headers});
	 }

   getFunnelOportunidades(): Observable<any> {
		const headers = new HttpHeaders().set('Content-type', 'application/json');
		
		return this.http.get(this.url + '/dashboard/oportunidad/funnel', {headers});
	 }

}
