import { Component, OnInit } from '@angular/core';
import { Actividad } from 'src/app/models/actividades';
import { ActividadesService } from 'src/app/services/actividades.service';
import { GLOBAL } from 'src/app/services/global';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Archivo } from 'src/app/models/archivo';
import { MesCorte } from 'src/app/models/mesCorte';
import { Pagination } from 'src/app/models/pagination';
import { GENERALES } from 'src/app/models/generales';

@Component({
  selector: 'app-list-actividades',
  templateUrl: './list-actividades.component.html',
  styleUrls: ['./list-actividades.component.sass']
})
export class ListActividadesComponent implements OnInit {
	heading = 'Actividades';
	subheading = 'Lista de actividades registradas';
	icon = 'pe-7s-id icon-gradient bg-happy-fisher';
	public page: number;
	public actividades: Actividad[] = [];
	public status: string;
	public title: string;
	public url: string;
	public fecha: string;
	public datePipe = new DatePipe("en-US");
	public loading		 = false;
	public orderType: Array<Archivo> = [];
	public paginacion = new Pagination(null);
	public months: MesCorte[] = [];
	public mesSelected = 0;
  constructor(
	private _actividadesService : ActividadesService,
	private _route: ActivatedRoute,
  ) { 
	this.months = [];
	this.page =0;
	this.url = GLOBAL.url;
	this.paginacion.sortType = '-id';
  }

  ngOnInit() {
	this.fecha= this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:ss');
	const mes= this.datePipe.transform(new Date(), 'MM');
	const anio= this.datePipe.transform(new Date(), 'yyyy');
	this.consulta(mes,anio);

	this._actividadesService.getFiltro().subscribe(Response =>{
			this.months = Response.meses;
		},error =>{
			console.log(<any>error);
		});
  }

	changeFecha() {
		if (this.months.length) {
			const index = GENERALES.indexOfObject(this.months, 'id', this.mesSelected);
			if (index > -1) {
				const mes = (this.months[index].mes);
				const anio =(this.months[index].anio);
				this.consulta(mes,anio);
				
			}
		}
	}

	consulta(mes,anio){
		this._actividadesService.getActividadMes(mes,anio).subscribe(Response =>{
			this.actividades = Response.actividad;
		},error =>{
			console.log(<any>error);
		});
	}

	searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchActividad").focus();
  }

  closeSearch() {
    this.paginacion.isActive = false;
    this.paginacion.textSearch = '';
  }

}
