<div class="col-12 mt-3">
	<div class="form-group">
		<div class="row">
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 1}" (click)="filterPermisos = 1; filterSelecionar()" title="Ver y editar todos los permisos">TODOS</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 2}" (click)="filterPermisos = 2; filterSelecionar()" title="Ver y editar todos los permisos de consulta">Consulta</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 3}" (click)="filterPermisos = 3; filterSelecionar()" title="Ver y editar todos los permisosd de registros">Registros</button>
			</div>
			<div class="col-6 col-sm-4">
				<button type="button" class="btn btn-icon btn-wide btn-outline-2x btn-outline-focus d-flexy btn-block mb-2" [ngClass]="{'active': filterPermisos === 4}" (click)="filterPermisos = 4; filterSelecionar()" title="Contestar tickets">Tickets</button>
			</div>
		</div>
	</div>
</div>
<ngb-tabset class="tabs-animated tabs-animated-line" justify="justified">
	<ngb-tab>
		<ng-template ngbTabTitle><span>Permisos</span></ng-template>
		<ng-template ngbTabContent>
			<ul class="todo-list-wrapper list-group list-group-flush">
				<li class="list-group-item">
					<div class="widget-content p-0">
						<div class="widget-content-wrapper">
							<div class="widget-content-left mr-2">
								<div class="custom-checkbox custom-control">
									<input type="checkbox" id="allPermisosForm" name="allPermisosForm" #allPermisosForm="ngModel" [(ngModel)]="todo" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="seleccionar()">
									<label class="custom-control-label" for="allPermisosForm">&nbsp; Seleccionar todo</label>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<hr class="p-0 m-0">
			<div class="scroll-area-lg">
				<div class="scrollbar-container">
					<perfect-scrollbar [autoPropagation]="true">
						<ul class="todo-list-wrapper list-group list-group-flush">
							
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 3">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormTicketsRegEdit" name="permisoFormTicketsRegEdit" #permisoFormTicketsRegEdit="ngModel" [(ngModel)]="permisos.ticketsEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormTicketsRegEdit">&nbsp; Editar detalle de tickets</label>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="list-group-item" *ngIf="filterPermisos === 1 || filterPermisos === 4 || filterPermisos === 2">
								<div class="todo-indicator bg-warning"></div>
								<div class="widget-content p-0">
									<div class="widget-content-wrapper">
										<div class="widget-content-left mr-2">
											<div class="custom-checkbox custom-control">
												<input type="checkbox" id="permisoFormTicketsVer" name="permisoFormTicketsVer" #permisoFormTicketsVer="ngModel" [(ngModel)]="permisos.ticketsVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 5)" (change)="validarTodo()">
												<label class="custom-control-label" for="permisoFormTicketsVer">&nbsp; Ver detalle de tickets</label>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</perfect-scrollbar>
				</div>
			</div>
		</ng-template>
	</ngb-tab>
</ngb-tabset>