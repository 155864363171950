import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from 'src/app/models/global';
import { TipoSolicitud } from '../models/tipo-solicitud';

@Injectable({
  providedIn: 'root'
})
export class TipoSolicitudService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  obtenerTipoSolicitudes(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/tipo-solicitud', {headers});
  }

  actualizaEstatusTipoSolicitud(solicitud: TipoSolicitud): Observable<any> {
    const param = JSON.stringify({solicitud});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/tipo-solicitud/estatus', param, {headers});
  }

  registraTipoSolicitud(solicitud: TipoSolicitud): Observable<any> {
    const param = JSON.stringify({solicitud});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/tipo-solicitud', param, {headers});
  }

}
