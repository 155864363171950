import { SortOrder } from './sort';

export class Asignaciones {
  public id: number;
  public numeroOrden: string;
  public cliente: number;
  public desCliente: string;
  public servicio: number;
  public desServicio: string;
  public localidad: string;
  public sucursal: number;
  public desSucursal: string;
  public fecInicio: Date;
  public fecTermino: Date;
  public opcFin: boolean;
  public tipoServicio: number;
  public desTipoServicio: string;
  public tipoSolicitud: number;
  public desTipoSolicitud: string;
  public division: number;
  public desDivision: string;
  public resultados: Resultados[];
  public elementos: number;
    
  constructor(
    obj: ObjAsignaciones
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.numeroOrden = obj && obj.numeroOrden ? obj.numeroOrden : '';
    this.cliente = obj && obj.cliente ? obj.cliente : null;
    this.desCliente = obj && obj.desCliente ? obj.desCliente : '';
    this.servicio = obj && obj.servicio ? obj.servicio : null;
    this.desServicio = obj && obj.desServicio ? obj.desServicio : '';
    this.localidad = obj && obj.localidad ? obj.localidad : '';
    this.sucursal = obj && obj.sucursal ? obj.sucursal : null;
    this.desSucursal = obj && obj.desSucursal ? obj.desSucursal : '';
    this.fecInicio = obj && obj.fecInicio ? obj.fecInicio : null;
    this.fecTermino = obj && obj.fecTermino ? obj.fecTermino : null;
    this.opcFin = obj && obj.opcFin ? obj.opcFin : false;
    this.tipoServicio = obj && obj.tipoServicio ? obj.tipoServicio : null;
    this.desTipoServicio = obj && obj.desTipoServicio ? obj.desTipoServicio : '';
    this.tipoSolicitud = obj && obj.tipoSolicitud ? obj.tipoSolicitud : null;
    this.desTipoSolicitud = obj && obj.desTipoSolicitud ? obj.desTipoSolicitud : '';
    this.division = obj && obj.division ? obj.division : null;
    this.desDivision = obj && obj.desDivision ? obj.desDivision : '';
    this.resultados = obj && obj.resultados ? obj.resultados : [];
    this.elementos = obj && obj.elementos ? obj.elementos : 0;
  }
}

interface ObjAsignaciones {
  id: number;
  numeroOrden: string;
  cliente: number;
  desCliente: string;
  servicio: number;
  desServicio: string;
  localidad: string;
  sucursal: number;
  desSucursal: string;
  fecInicio: Date;
  fecTermino: Date;
  opcFin: boolean;
  tipoServicio: number;
  desTipoServicio: string;
  tipoSolicitud: number;
  desTipoSolicitud: string;
  division: number;
  desDivision: string;
  resultados: Resultados[];
  elementos: number;
}



export class Resultados {               
  public captura: Date;
  public falta: number;
  public bajas: number;
  public vacantes: number;
  public version: number;
  public elementos: number;
  public turnos: number;
  public disponibilidad: number;
    
  constructor(
    obj: ObjResultados
  ) {
    this.captura = obj && obj.captura ? obj.captura : null;
    this.falta = obj && obj.falta ? obj.falta : 0;
    this.bajas = obj && obj.bajas ? obj.bajas : 0;
    this.vacantes = obj && obj.vacantes ? obj.vacantes : 0;
    this.version = obj && obj.version ? obj.version : 0;
    this.elementos = obj && obj.elementos ? obj.elementos : 0;
    this.turnos = obj && obj.turnos ? obj.turnos : 0;
    this.disponibilidad = obj && obj.disponibilidad ? obj.disponibilidad : 0;
  }
}

interface ObjResultados {
  captura: Date;
  falta: number;
  bajas: number;
  vacantes: number;
  version: number;
  elementos: number;
  turnos: number;
  disponibilidad: number;
}

export class Capturas {    
  public tipo: number;
	public resultado: number;
	public elementos: number;
	public fecha: Date;
  public disponibilidad: number;
  
  constructor(
    obj: ObjCapturas
  ) {
    this.tipo = obj && obj.tipo ? obj.tipo : null;
    this.resultado = obj && obj.resultado ? obj.resultado : 0;
    this.elementos = obj && obj.elementos ? obj.elementos : 0;
    this.fecha = obj && obj.fecha ? obj.fecha : null;
    this.disponibilidad = obj && obj.disponibilidad ? obj.disponibilidad : 0;
  }
}

interface ObjCapturas {
  tipo: number;
  resultado: number;
  elementos: number;
  fecha: Date;
  disponibilidad: number;
}



export const AsignacionesSortType: SortOrder[] = [
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+numeroOrden', text: 'Número de orden'},
  { id: '-numeroOrden', text: 'Número de orden desc'},
  { id: '+desCliente', text: 'Cliente'},
  { id: '-desCliente', text: 'Cliente desc'},
  { id: '+desServicio', text: 'Servicio'},
  { id: '-desServicio', text: 'Servicio desc'},
  { id: '+localidad', text: 'Localidad'},
  { id: '-localidad', text: 'Localidad desc'},
  { id: '+desSucursal', text: 'Sucursal'},
  { id: '-desSucursal', text: 'Sucursal desc'},
  { id: '+fecInicio', text: 'Fecha inicio'},
  { id: '-fecInicio', text: 'Fecha inicio desc'},
  { id: '+fecTermino', text: 'Fecha término'},
  { id: '-fecTermino', text: 'Fecha término desc'},
  { id: '+desTipoServicio', text: 'Tipo de servicio'},
  { id: '-desTipoServicio', text: 'Tipo de servicio desc'},
  { id: '+desTipoSolicitud', text: 'Tipo de solicitud'},
  { id: '-desTipoSolicitud', text: 'Tipo de solicitud desc'},
  { id: '+desDivision', text: 'División'},
  { id: '-desDivision', text: 'División desc'},
  { id: '+elementos', text: 'No. Elementos'},
  { id: '-elementos', text: 'No. Elementos desc'}
];





