import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { ReportesService } from 'src/app/services/reportes.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';

@Injectable()
export class ReportesEffects {

  constructor(
    private actions$: Actions,
    public reportesService: ReportesService,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) {}


  @Effect()
  cargarReporteCumpleannos$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_CUMPLEANNOS ),
    switchMap( (action: any) => {
      return this.reportesService.obtenerReporteCumpleannos(action.fecha, action.opciones)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteCumpleannosSuccess(respuesta.contactos, respuesta.totalFiltro, respuesta.total);
          }),
          catchError( error => of(new usuariosActions.CargarReporteCumpleannosFail(error))  )
        );
    })
	);

  @Effect()
  cargarReporteVentasEtapas$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_VENTAS_ETAPAS ),
    switchMap( (action: any) => {
      return this.reportesService.obtenerReporteVentasEtapas(action.fecha, action.departamento)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteVentasEtapasSuccess(respuesta.oportunidades);
          }),
          catchError( error => of(new usuariosActions.CargarReporteVentasEtapasFail(error))  )
        );
    })
	);

  @Effect()
  cargarReporteVentasPropietarios$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_REPORTE_VENTAS_PROPIETARIOS ),
    switchMap( (action: any) => {
      return this.reportesService.obtenerReporteVentasPropietario(action.fecha, action.departamento)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarReporteVentasPropietariosSuccess(respuesta.reporte);
          }),
          catchError( error => of(new usuariosActions.CargarReporteVentasPropietariosFail(error))  )
        );
    })
	);


}