<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" *ngIf="identity.permisos">
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="p-3">
          <div class="form-group">
            <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize" (change)="cargarEmpresas()">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="" class="control-label">Ordenar por</label>
            <select class="form-control custom-select" name="sortTypeProducto" id="sortTypeProducto" #sortTypeProducto="ngModel" [(ngModel)]="paginacion.sortType" (change)="cargarEmpresas()">
              <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
            </select>
          </div>
          <div class="v-options-menu vsm-default">
            <div class="vsm-list">
              <div class="vsm-header">Opciones</div>
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                <ngb-panel class="p-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <span class="vsm-title">Países</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let item of filterPaises">
                          <a class="vsm-link">
                            <span class="vsm-title">
                              <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="custom-checkbox custom-control">
                                      <input type="checkbox" id="selectCheckOpcionesPaisFilterEmpresa{{item.id}}" class="custom-control-input" name="selectCheckOpcionesPaisFilterEmpresa{{item.id}}" #selectCheckOpcionesPaisFilterEmpresa{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changePaises()">
                                      <label class="custom-control-label" for="selectCheckOpcionesPaisFilterEmpresa{{item.id}}">&nbsp;</label>
                                    </div>
                                  </div>
                                  <div class="widget-content-left flex2">
                                    <div class="widget-heading">{{item.nombre}}</div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                <ngb-panel class="p-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <span class="vsm-title">Industrias</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let item of filterIndustrias">
                          <a class="vsm-link">
                            <span class="vsm-title">
                              <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="custom-checkbox custom-control">
                                      <input type="checkbox" id="selectCheckOpcionesIndustriaFilterEmpresa{{item.id}}" class="custom-control-input" name="selectCheckOpcionesIndustriaFilterEmpresa{{item.id}}" #selectCheckOpcionesIndustriaFilterEmpresa{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeIndustrias()">
                                      <label class="custom-control-label" for="selectCheckOpcionesIndustriaFilterEmpresa{{item.id}}">&nbsp;</label>
                                    </div>
                                  </div>
                                  <div class="widget-content-left flex2">
                                    <div class="widget-heading">{{item.nombre}}</div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" *ngIf="identity.tipo <= 1">
                <ngb-panel class="p-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <span class="vsm-title">Propietarios</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let item of filterPropietarios">
                          <a class="vsm-link">
                            <span class="vsm-title">
                              <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="custom-checkbox custom-control">
                                      <input type="checkbox" id="selectCheckOpcionesPropietarioFilterEmpresa{{item.id}}" class="custom-control-input" name="selectCheckOpcionesPropietarioFilterEmpresa{{item.id}}" #selectCheckOpcionesPropietarioFilterEmpresa{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changePropietarios()">
                                      <label class="custom-control-label" for="selectCheckOpcionesPropietarioFilterEmpresa{{item.id}}">&nbsp;</label>
                                    </div>
                                  </div>
                                  <div class="widget-content-left flex2">
                                    <div class="widget-heading">{{item.nombre}}</div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
        <!-- <h5 class="menu-header-title font-size-md text-capitalize ml-3">
          Filtrar tipos
        </h5> -->
      </div>
      <!-- <div class="card-body p-0" id="collapseExample" [ngbCollapse]="isCollapsed">
        <div class="scroll-area-lg mb-2">
          <section class="scrollbar-container">
            <perfect-scrollbar [autoPropagation]="true">
              <app-user-type-filter (respuesta)="showTipos($event)"></app-user-type-filter>
            </perfect-scrollbar>
          </section>
        </div>
      </div> -->
    </div>
  </div>
  <div class="col-md-9" [ngClass]="{'col-md-12': !filterActive }">
    <div class="main-card mb-3 card card-shadow-primary">
      <div class="card-header">
        <div class="card-header-title">Empresas</div>
        <div class="btn-actions-pane-right mr-4">
          ({{paginacion.totalFiltro}} de {{paginacion.totalSize}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchEmpresas" id="searchEmpresas" #searchEmpresas="ngModel" [(ngModel)]="paginacion.textSearch" (ngModelChange)="changeTextSearch()">
                <button class="search-icon" (click)="searchClick()"><span></span></button>
              </div>
              <button class="close" (click)="closeSearch()"></button>
            </div>
            <button type="button" *ngIf="identity.permisos.empresasRegEdit" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3 mr-3 ml-3" (click)="opcDuplicados = !opcDuplicados;" [ngClass]="{'active': opcDuplicados}">
              <span class="pr-2 align-middle opacity-7">
                <i class="fas fa-people-arrows"></i>
              </span>
              Unificar
            </button>
            <button type="button" *ngIf="identity.permisos.empresasListEdit" [routerLink]="['/auth/empresas/register', 0]" [routerLinkActive]="" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3">
              Registrar nuevo
              <span class="pl-2 align-middle opacity-7">
                <i class="fa fa-angle-right"></i>
              </span>
            </button>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <button type="button" class="m-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearch" (click)="removerTextSearch(texto)">
          {{texto}}
          <i class="ml-1 fas fa-times"></i>
        </button>
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let empresa of empresas">
            <div class="no-gutters row">
              <div class="col-sm-6 col-md-4 col-xl-4">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="custom-checkbox custom-control" *ngIf="opcDuplicados">
                        <input type="checkbox" id="selectCheckDuplicado{{empresa.id}}" class="custom-control-input" name="selectCheckDuplicado{{empresa.id}}" [(ngModel)]="empresa.check" (click)="empresasDuplicados(empresa)">
                        <label class="custom-control-label" for="selectCheckDuplicado{{empresa.id}}">&nbsp;</label>
                      </div>
                      <div class="widget-content-left mr-2">#{{empresa.id}}</div>
                      <div class="widget-content-left mr-3">
                        <img width="42" class="rounded-circle" [src]="url + '/empresas/perfiles/' + empresa.id + '/imagenes/0?v=' + version" alt="Imagen de la empresa">
                      </div>
                      <div class="widget-content-left">
                        <div class="widget-heading"> <a [routerLink]="['/auth/empresas/profile', empresa.id]" class="text-dark"> {{empresa.nombre}} </a></div>
                        <div class="widget-subheading">Industria: {{empresa.desIndustria}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-xl-3">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="widget-subheading">{{printDireccion(empresa)}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-xl-3">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="widget-heading text-secondary">Teléfono: {{empresa.telefonoUno | phone}} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-2 col-xl-2">
                <div class="d-inline-block" ngbDropdown autoClose="outside" placement="left">
                  <button type="button" class="btn-pill btn btn-outline-primary" ngbDropdownToggle>
                    Acciones
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu-xl">
                    <div class="dropdown-menu-header">
                      <div class="dropdown-menu-header-inner bg-sunny-morning">
                        <div class="menu-header-content text-dark">
                          <h5 class="menu-header-title">ACCIONES</h5>
                        </div>
                      </div>
                    </div>
                    <div class="grid-menu grid-menu-xl grid-menu-3col">
                      <div class="no-gutters row">
                        <div class="col-sm-6 col-xl-4" *ngIf="identity.permisos.empresasRegVer || identity.permisos.empresasRegEdit">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary" [routerLink]="['/auth/empresas/register/', empresa.id]" title="Editar empresa">
                            <i class="fa fa-edit btn-icon-wrapper btn-icon-lg mb-3"> </i> Editar
                          </button>
                        </div>
                        <div class="col-sm-6 col-xl-4" *ngIf="identity.permisos.actividadesMultipleEdit || identity.permisos.actividadesMultipleVer">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-dark" [routerLink]="['/auth/empresas/profile/', empresa.id]" title="Ver perfil de la empresa">
                            <i class="far fa-building btn-icon-wrapper btn-icon-lg mb-3"> </i> Perfil
                          </button>
                        </div>
                        <div class="col-sm-6 col-xl-4" *ngIf="identity.permisos.empresasListEdit">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-danger" (click)="eliminarEmpresa(empresa)" title="Presione para eliminar la empresa.">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 fa-trash-alt"></i> Eliminar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="card no-shadow bg-transparent text-left">
              <div class="widget-chart-content">
                <ngb-pagination class="mb-2" [boundaryLinks]="true"
                  (pageChange)="cargarEmpresas(false)"
                  [collectionSize]="paginacion.totalFiltro" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                  <ng-template ngbPaginationFirst>First</ng-template>
                  <ng-template ngbPaginationLast>Last</ng-template>
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="text-right">
              <button type="button" (click)="downloadExcel()" class="btn btn-primary mb-2" *ngIf="identity.permisos.empresasDownload && !opcDuplicados && !downloading">
                <i class="fa fa-file-excel-o"></i>&nbsp; Descargar XLSX
              </button>
              <button type="button" disabled class="btn btn-primary mb-2" *ngIf="downloading">
                <i class="fa fa-file-excel-o"></i>&nbsp; Descargando...
              </button>
              <button type="button" (click)="cancelarDuplicados()" class="btn btn-warning mb-2 mr-3" *ngIf="opcDuplicados">
                <i class="fa fa-file-excel-o"></i>&nbsp; Cancelar unificación
              </button>
              <button type="button" (click)="open(content)" class="btn btn-primary mb-2" *ngIf="opcDuplicados" [disabled]="duplicados.duplicados.length < 2">
                <i class="fa fa-file-excel-o"></i>&nbsp; Unificar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Seleccione la empresa que deseas mantener</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="custom-radio custom-control" *ngFor="let empresa of duplicados.duplicados">
      <input class="custom-control-input" type="radio" id="selectRadioDuplicado{{empresa.id}}" name="selectRadioDuplicado{{empresa.id}}" [value]="empresa.id" [(ngModel)]="duplicados.empresa.id"  (click)="empresaDuplicado(empresa)">
      <label class="custom-control-label col-12" for="selectRadioDuplicado{{empresa.id}}">
        <li class="list-group-item">
          <div class="no-gutters row">
            <div class="col-sm-6">
              <div class="card no-shadow bg-transparent text-left">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="todo-indicator bg-primary"></div>
                    <div class="widget-content-left mr-2">#{{empresa.id}}</div>
                    <div class="widget-content-left mr-3">
                      <img width="42" class="rounded-circle" [src]="url + '/empresas/perfiles/' + empresa.id + '/imagenes/0?v=' + version" alt="Imagen de la empresa">
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading"> <a [routerLink]="['/auth/empresas/profile', empresa.id]" class="text-dark"> {{empresa.nombre}} </a></div>
                      <div class="widget-subheading">Industria: {{empresa.desIndustria}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card no-shadow bg-transparent text-left">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                      <div class="widget-heading text-secondary">Teléfono: {{empresa.telefonoUno | phone}} </div>
                      <div class="widget-subheading">Clase: {{empresa.desClase}}</div>
                      <div class="widget-subheading">{{printDireccion(empresa)}}</div>
                    </div>
                    <div class="widget-content-left" *ngIf="duplicados.duplicados && duplicados.duplicados.length > 2">
                      <button type="button" class="btn btn-sm btn-outline-danger" (click)="quitarDuplicado(empresa)">
                        <i class="fa fa-remove"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="guardarDuplicados()" [disabled]="duplicados.empresa.id === 0" >Registrar cambios</button>
  </div>
</ng-template>