import * as fromUsuario from '../actions/actividades-archivos.actions';
import { ArchivoConcepto } from 'src/app/models/archivo';

export interface ActividadesArchivosState {
  archivos: ArchivoConcepto[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: ActividadesArchivosState = {
  archivos: [],
  loaded: false,
  loading: false,
  error: null
};

// tslint:disable-next-line:max-line-length
export function actividadesArchivosReducer( state = estadoInicial, action: fromUsuario.actividadesArchivosAcciones ): ActividadesArchivosState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_ARCHIVOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_ARCHIVOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        archivos: [...action.archivos]
      };
    case fromUsuario.CARGAR_ARCHIVOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ELIMINAR_ARCHIVO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ELIMINAR_ARCHIVO_SUCCESS:
			state.archivos = [...state.archivos.filter(item => item.id !== action.id)];
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ELIMINAR_ARCHIVO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.REGISTRAR_ARCHIVO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.REGISTRAR_ARCHIVO_SUCCESS:
			state.archivos.push(action.archivo);
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.REGISTRAR_ARCHIVO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
		case fromUsuario.LIMPIAR_ARCHIVOS:
      return {
        ...state,
				archivos: []
      };
    default:
      return state;
  }
}

