<ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
  <ngb-panel class="p-0">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="vsm-link">
        <span class="vsm-title">{{titulo}}</span>
        <i class="vsm-arrow"></i>
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="vsm-dropdown">
        <div class="vsm-list">
          <div class="vsm-item" *ngFor="let item of lista">
            <a class="vsm-link">
              <span class="vsm-title">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                      <div class="custom-checkbox custom-control">
                        <input type="checkbox" id="selectCheckOpcionesFilter{{(textRandom + '' + item.id)}}" class="custom-control-input" name="selectCheckOpcionesFilter{{(textRandom + '' + item.id)}}" [(ngModel)]="item.checked" (change)="actualiza()">
                        <label class="custom-control-label" for="selectCheckOpcionesFilter{{(textRandom + '' + item.id)}}">&nbsp;</label>
                      </div>
                    </div>
                    <div class="widget-content-left flex2">
                      <a (click)="checkedInput(item)" class="widget-heading text-decoration-none text-dark">{{item.nombre}}</a>
                    </div>
                  </div>
                </div>
              </span>
            </a>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
