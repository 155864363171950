import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { Justificacion } from 'src/app/models/cumplimiento';
import { Fechas } from 'src/app/models/fechas';

@Injectable({
  providedIn: 'root'
})
export class CumplimientoService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

	 reportes(dias: Fechas): Observable<any> {
		const params = JSON.stringify({dias});
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this.http.post(this.url + '/cumplimiento/reporte', params, {headers});
	 }

	 cumplimiento(dias: Fechas, opciones: any): Observable<any> {
		const params = JSON.stringify({dias, opciones});
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this.http.post(this.url + '/cumplimiento', params, {headers});
	 }

	 
	 updateUserCumplimiento(param: Justificacion): Observable<any> {
		const params = JSON.stringify(param);
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
	
		return this.http.put(this.url + '/cumplimiento', params , {headers});
	 }

}
