import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-historial-asignacion',
  templateUrl: './historial-asignacion.component.html',
  styleUrls: ['./historial-asignacion.component.sass']
})
export class HistorialAsignacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
