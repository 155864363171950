import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Identity, Permisos, UserType, Suscripciones } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';

@Component({
  selector: 'app-usuario-config-crm',
  templateUrl: './usuario-config-crm.component.html',
  styleUrls: ['./usuario-config-crm.component.scss']
})
export class UsuarioConfigCrmComponent implements OnInit, OnDestroy {
  public identity: Identity;
  private subscribeAuth: Subscription = new Subscription();
  public loading = false;
  public url: string;
  public suscripciones: Suscripciones[] = [];
  public todo = false;
  public todoSuscripciones = false;
  public filterPermisos: number = 1;

  @Input() permisos: Permisos;
  @Input() tipoSelected: UserType;
  @Output() messageEvent = new EventEmitter<Permisos>();
  constructor(
    private store: Store<AppState>,
  ) { 
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;

    // filterPermisos = 1 TODOS
    // filterPermisos = 2 Consulta
    // filterPermisos = 3 Registros
    // filterPermisos = 4 Empresas
    // filterPermisos = 5 Oportunidades
    // filterPermisos = 6 Reportes 
    // filterPermisos = 7 Contactos
    // filterPermisos = 8 Tickets
    // filterPermisos = 9 Catálogos 
    // filterPermisos = 10 Indicadores
    // filterPermisos = 11 Asignaciones
    // filterPermisos = 12 Usuarios
    // filterPermisos = 13 Actividades
    // filterPermisos = 14 Control
  }

  ngOnInit(): void {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.filterSelecionar();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
  }

  sendMessage() {
    this.messageEvent.emit(this.permisos);
  }

  validarTodo() {
    if(this.filterPermisos !== 3 ){
      setTimeout( () => {
        // tslint:disable-next-line:forin
        for (const field in this.permisos) {
          if (field.search('Edit')) {
            if (this.permisos[field]) {
              const atributo = field.substr(0, field.length - 4) + 'Ver';
              if (atributo in this.permisos) {
                this.permisos[atributo] = true;
              }
            }
          }
        }
        const elements = Object.keys(this.permisos);
        this.todo = elements.every( (key) => this.permisos[key]);
      }, 1);
    }
    this.sendMessage();
  }

  validarTodoSuscripciones() {
    this.todoSuscripciones = this.suscripciones.every( (item) => item.checked);
  }


  seleccionar() {
    switch ( this.filterPermisos ) {
      case 1:
        this.seleccionarTodo();
      break;
      case 2:
        this.seleccionarConsulta();
      break;
      case 3:
        this.seleccionarRegistro();
      break;
      case 4:
        this.seleccionarEmpresa();
      break;
      case 5:
        this.seleccionarOportunidad();
      break;
      case 6:
        this.seleccionarReporte();
      break;
      case 7:
        this.seleccionarContacto();
      break;
      case 9:
        this.seleccionarCatalogo();
      break;
    }
  }

  seleccionarTodo() {
    if (this.todo) {
      this.permisos.empresasListEdit = true;
      this.permisos.empresasListVer = true;
      this.permisos.empresasRegEdit = true;
      this.permisos.empresasRegVer = true;
      this.permisos.empresasDownload = true;
      this.permisos.contactosListEdit = true;
      this.permisos.contactosListVer = true;
      this.permisos.contactosRegEdit = true;
      this.permisos.contactosRegVer = true;
      this.permisos.contactosDownload = true;
      this.permisos.oportunidadesListEdit = true;
      this.permisos.oportunidadesListVer = true;
      this.permisos.oportunidadesRegEdit = true;
      this.permisos.oportunidadesRegVer = true;
      this.permisos.oportunidadesDownload = true;
      this.permisos.actividadesMultipleVer = true;
      this.permisos.actividadesMultipleEdit = true;
      this.permisos.catOrigenesEdit = true;
      this.permisos.catOrigenesVer = true;
      this.permisos.catIndustriasEdit = true;
      this.permisos.catIndustriasVer = true;
      this.permisos.catPaisesEdit = true;
      this.permisos.catPaisesVer = true;
      this.permisos.rptKpiVendedores = true;
      this.permisos.rptCumpleannos = true;
      this.permisos.rptOportunidadEtapas = true;
      this.permisos.rptOportunidadPropietarios = true;
    } else {
      this.permisos.empresasListEdit = false;
      this.permisos.empresasListVer = false;
      this.permisos.empresasRegEdit = false;
      this.permisos.empresasRegVer = false;
      this.permisos.empresasDownload = false;
      this.permisos.contactosListEdit = false;
      this.permisos.contactosListVer = false;
      this.permisos.contactosRegEdit = false;
      this.permisos.contactosRegVer = false;
      this.permisos.contactosDownload = false;
      this.permisos.oportunidadesListEdit = false;
      this.permisos.oportunidadesListVer = false;
      this.permisos.oportunidadesRegEdit = false;
      this.permisos.oportunidadesRegVer = false;
      this.permisos.oportunidadesDownload = false;
      this.permisos.actividadesMultipleVer = false;
      this.permisos.actividadesMultipleEdit = false;
      this.permisos.catOrigenesEdit = false;
      this.permisos.catOrigenesVer = false;
      this.permisos.catIndustriasEdit = false;
      this.permisos.catIndustriasVer = false;
      this.permisos.catPaisesEdit = false;
      this.permisos.catPaisesVer = false;
      this.permisos.rptKpiVendedores = false;
      this.permisos.rptCumpleannos = false;
      this.permisos.rptOportunidadEtapas = false;
      this.permisos.rptOportunidadPropietarios = false
    }
  }

  seleccionarConsulta() {
    if (this.todo) {
      this.permisos.empresasListVer = true;
      this.permisos.empresasRegVer = true;
      this.permisos.empresasDownload = true;
      this.permisos.contactosListVer = true;
      this.permisos.contactosRegVer = true;
      this.permisos.contactosDownload = true;
      this.permisos.oportunidadesListVer = true;
      this.permisos.oportunidadesRegVer = true;
      this.permisos.oportunidadesDownload = true;
      this.permisos.actividadesMultipleVer = true;
      this.permisos.catOrigenesVer = true;
      this.permisos.catIndustriasVer = true;
      this.permisos.catPaisesVer = true;
      this.permisos.rptKpiVendedores = true;
      this.permisos.rptCumpleannos = true;
      this.permisos.rptOportunidadEtapas = true;
      this.permisos.rptOportunidadPropietarios = true;
    } else {
      this.permisos.empresasListVer = false;
      this.permisos.empresasRegVer = false;
      this.permisos.empresasDownload = false;
      this.permisos.contactosListVer = false;
      this.permisos.contactosRegVer = false;
      this.permisos.contactosDownload = false;
      this.permisos.oportunidadesListVer = false;
      this.permisos.oportunidadesRegVer = false;
      this.permisos.oportunidadesDownload = false;
      this.permisos.actividadesMultipleVer = false;
      this.permisos.catOrigenesVer = false;
      this.permisos.catIndustriasVer = false;
      this.permisos.catPaisesVer = false;
      this.permisos.rptKpiVendedores = false;
      this.permisos.rptCumpleannos = false;
      this.permisos.rptOportunidadEtapas = false;
      this.permisos.rptCoberturaDia = false;
      this.permisos.rptOportunidadPropietarios = false;
    }
  }


  seleccionarRegistro() {
    if (this.todo) {
      this.permisos.empresasListEdit = true;
      this.permisos.empresasRegEdit = true;
      this.permisos.contactosListEdit = true;
      this.permisos.contactosRegEdit = true;
      this.permisos.oportunidadesListEdit = true;
      this.permisos.oportunidadesRegEdit = true;
      this.permisos.actividadesMultipleEdit = true;
      this.permisos.catOrigenesEdit = true;
      this.permisos.catIndustriasEdit = true;
      this.permisos.catPaisesEdit = true;
    } else {
      this.permisos.empresasListEdit = false;
      this.permisos.empresasRegEdit = false;
      this.permisos.contactosListEdit = false;
      this.permisos.contactosRegEdit = false;
      this.permisos.oportunidadesListEdit = false;
      this.permisos.oportunidadesRegEdit = false;
      this.permisos.actividadesMultipleEdit = false;
      this.permisos.catOrigenesEdit = false;
      this.permisos.catIndustriasEdit = false;
      this.permisos.catPaisesEdit = false;
    }
  }

  seleccionarEmpresa() {
    if (this.todo) {
      this.permisos.empresasListEdit = true;
      this.permisos.empresasListVer = true;
      this.permisos.empresasRegEdit = true;
      this.permisos.empresasRegVer = true;
      this.permisos.empresasDownload = true;
      this.permisos.actividadesMultipleEdit = true;
      this.permisos.actividadesMultipleVer = true;
    } else {
      this.permisos.empresasListEdit = false;
      this.permisos.empresasListVer = false;
      this.permisos.empresasRegEdit = false;
      this.permisos.empresasRegVer = false;
      this.permisos.empresasDownload = false;
      this.permisos.actividadesMultipleEdit = false;
      this.permisos.actividadesMultipleVer = false;
    }
  }

  seleccionarOportunidad() {
    if (this.todo) {
      this.permisos.oportunidadesListEdit = true;
      this.permisos.oportunidadesListVer = true;
      this.permisos.oportunidadesRegEdit = true;
      this.permisos.oportunidadesRegVer = true;
      this.permisos.oportunidadesDownload = true;
      this.permisos.actividadesMultipleEdit = true;
      this.permisos.actividadesMultipleVer = true;
    } else {
      this.permisos.oportunidadesListEdit = false;
      this.permisos.oportunidadesListVer = false;
      this.permisos.oportunidadesRegEdit = false;
      this.permisos.oportunidadesRegVer = false;
      this.permisos.oportunidadesDownload = false;
      this.permisos.actividadesMultipleEdit = false;
      this.permisos.actividadesMultipleVer = false;
    }
  }

  seleccionarReporte() {
    if (this.todo) {
      this.permisos.rptKpiVendedores = true;
      this.permisos.rptCumpleannos = true;
      this.permisos.rptOportunidadEtapas = true;
      this.permisos.rptOportunidadPropietarios = true;
    } else {
      this.permisos.rptKpiVendedores = false;
      this.permisos.rptCumpleannos = false;
      this.permisos.rptOportunidadEtapas = false;
      this.permisos.rptOportunidadPropietarios = false;
    }
  }

  seleccionarContacto() {
    if (this.todo) {
      this.permisos.contactosListEdit = true;
      this.permisos.contactosListVer = true;
      this.permisos.contactosRegEdit = true;
      this.permisos.contactosRegVer = true;
      this.permisos.contactosDownload = true;
      this.permisos.actividadesMultipleEdit = true;
      this.permisos.actividadesMultipleVer = true;
    } else {
      this.permisos.contactosListEdit = false;
      this.permisos.contactosListVer = false;
      this.permisos.contactosRegEdit = false;
      this.permisos.contactosRegVer = false;
      this.permisos.contactosDownload = false;
      this.permisos.actividadesMultipleEdit = false;
      this.permisos.actividadesMultipleVer = false;
    }
  }

  seleccionarCatalogo() {
    if (this.todo) {
      this.permisos.catOrigenesEdit = true;
      this.permisos.catOrigenesVer = true;
      this.permisos.catIndustriasEdit = true;
      this.permisos.catIndustriasVer = true;
      this.permisos.catPaisesEdit = true;
      this.permisos.catPaisesVer = true;
    } else {
      this.permisos.catOrigenesEdit = false;
      this.permisos.catOrigenesVer = false;
      this.permisos.catIndustriasEdit = false;
      this.permisos.catIndustriasVer = false;
      this.permisos.catPaisesEdit = false;
      this.permisos.catPaisesVer = false;
    }
  }



  filterSelecionar(){
    switch ( this.filterPermisos ) {
      case 1:
        this.filterSeleccionarTodo();
      break;
      case 2:
        this.filterSeleccionarConsulta();
      break;
      case 3:
        this.filterSeleccionarRegistro();
      break;
      case 4:
        this.filterSeleccionarEmpresa();
      break;
      case 5:
        this.filterSeleccionarOportunidad();
      break;
      case 6:
        this.filterSeleccionarReporte();
      break;
      case 7:
        this.filterSeleccionarContacto();
      break;
      case 9:
        this.filterSeleccionarCatalogo();
      break;
    }
  }


  filterSeleccionarTodo() {
    if (  this.permisos.empresasListEdit &&
          this.permisos.empresasListVer &&
          this.permisos.empresasRegEdit &&
          this.permisos.empresasRegVer &&
          this.permisos.empresasDownload &&
          this.permisos.contactosListEdit &&
          this.permisos.contactosListVer &&
          this.permisos.contactosRegEdit &&
          this.permisos.contactosRegVer &&
          this.permisos.contactosDownload &&
          this.permisos.oportunidadesListEdit &&
          this.permisos.oportunidadesListVer &&
          this.permisos.oportunidadesRegEdit &&
          this.permisos.oportunidadesRegVer &&
          this.permisos.oportunidadesDownload &&
          this.permisos.actividadesMultipleVer &&
          this.permisos.actividadesMultipleEdit &&
          this.permisos.catOrigenesEdit &&
          this.permisos.catOrigenesVer &&
          this.permisos.catIndustriasEdit &&
          this.permisos.catIndustriasVer &&
          this.permisos.catPaisesEdit &&
          this.permisos.catPaisesVer &&
          this.permisos.rptKpiVendedores &&
          this.permisos.rptCumpleannos &&
          this.permisos.rptOportunidadEtapas &&
          this.permisos.rptOportunidadPropietarios) {
  this.todo = true;
} else {
  this.todo = false;
}
  }

  filterSeleccionarConsulta() {
    if (  this.permisos.empresasListVer &&
          this.permisos.empresasRegVer &&
          this.permisos.empresasDownload &&
          this.permisos.contactosListVer &&
          this.permisos.contactosRegVer &&
          this.permisos.contactosDownload &&
          this.permisos.oportunidadesListVer &&
          this.permisos.oportunidadesRegVer &&
          this.permisos.oportunidadesDownload &&
          this.permisos.actividadesMultipleVer &&
          this.permisos.catOrigenesVer &&
          this.permisos.catIndustriasVer &&
          this.permisos.catPaisesVer &&
          this.permisos.rptKpiVendedores &&
          this.permisos.rptCumpleannos &&
          this.permisos.rptOportunidadEtapas && 
          this.permisos.rptOportunidadPropietarios ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }


  filterSeleccionarRegistro() {
    if (  this.permisos.empresasListEdit &&
          this.permisos.empresasRegEdit &&
          this.permisos.contactosListEdit &&
          this.permisos.contactosRegEdit &&
          this.permisos.oportunidadesListEdit &&
          this.permisos.oportunidadesRegEdit &&
          this.permisos.actividadesMultipleEdit &&
          this.permisos.catOrigenesEdit &&
          this.permisos.catIndustriasEdit &&
          this.permisos.catPaisesEdit ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarEmpresa() {
    if (  this.permisos.empresasListEdit &&
          this.permisos.empresasListVer &&
          this.permisos.empresasRegEdit &&
          this.permisos.empresasRegVer &&
          this.permisos.empresasDownload &&
          this.permisos.actividadesMultipleEdit &&
          this.permisos.actividadesMultipleVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarOportunidad() {
    if (  this.permisos.oportunidadesListEdit &&
          this.permisos.oportunidadesListVer &&
          this.permisos.oportunidadesRegEdit &&
          this.permisos.oportunidadesRegVer &&
          this.permisos.oportunidadesDownload &&
          this.permisos.actividadesMultipleEdit &&
          this.permisos.actividadesMultipleVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarReporte() {
    if (  this.permisos.rptKpiVendedores &&
          this.permisos.rptCumpleannos &&
          this.permisos.rptOportunidadEtapas ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarContacto() {
    if (  this.permisos.contactosListEdit &&
          this.permisos.contactosListVer &&
          this.permisos.contactosRegEdit &&
          this.permisos.contactosRegVer &&
          this.permisos.contactosDownload &&
          this.permisos.actividadesMultipleEdit &&
          this.permisos.actividadesMultipleVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }

  filterSeleccionarCatalogo() {
    if (  this.permisos.catOrigenesEdit &&
          this.permisos.catOrigenesVer &&
          this.permisos.catIndustriasEdit &&
          this.permisos.catIndustriasVer &&
          this.permisos.catPaisesEdit &&
          this.permisos.catPaisesVer ) {
      this.todo = true;
    } else {
      this.todo = false;
    }
  }


}
