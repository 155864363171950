import { Component, OnInit, OnDestroy } from '@angular/core';
import { Origen, OrigenSortType } from 'src/app/models/origen-contacto';
import { Catalogo } from 'src/app/models/catalogo';
import { CatalogosService } from 'src/app/services/catalogos.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Pagination } from 'src/app/models/pagination';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-origen-contactos',
  templateUrl: './origen-contactos.component.html',
  styleUrls: ['./origen-contactos.component.sass']
})
export class OrigenContactosComponent implements OnInit, OnDestroy {
  heading = 'Detalles del origen de los contactos';
  subheading = 'Origenes de contactos registrandos en el sistema';
  icon = 'pe-7s-notebook icon-gradient bg-dark';
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeOrigen: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  public origen: Origen;
  public origenInicial: Origen = new Origen(null);
  public origenes: Origen[] = [];
  public tipos: Catalogo[] = [];
  public loading = false;
  public paginacion: Pagination = new Pagination(null);
  public filterActive = false;
  public orderType = [...OrigenSortType];
  constructor(
    private catalogosService: CatalogosService,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) { 
    this.origen = new Origen(null);
    this.paginacion.sortType = '+nombre';
    this.identity = new Identity(null);
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeOrigen = this.store.select('origenContacto').subscribe(
      (state) => {
        this.origenes = [...state.origenes.filter( itm => itm.id !== 0)];
      }
    );
    // this.subscribeConsulta = this.store.select('consulta').subscribe(
    //   (state) => {
    //     this.tipos = [...state.tipoOrigen.filter(item => item.status && (item.id === 1 || item.id ===2))];
    //   }
    // );
    console.log('Inicializa component Origenes...');
    this.store.dispatch(new usuariosActions.CargarOrigenContactos());
    // this.store.dispatch(new usuariosActions.ConsultaTiposOrigen());
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeOrigen.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }


  editarOrigen(origen: Origen) {
    this.origen = {...origen};
    this.origenInicial = {...origen};
  }

  estatusOrigen(origen: Origen) {
    if (origen.id > 0) {
      this.loading = true;
      this.catalogosService.actualizaEstatusOrigenContacto(origen).subscribe(
        (response) => {
          this.store.dispatch(new usuariosActions.ActualizaOrigenContactoEstatusSuccess(origen.id));
          this.toastr.success('Actualización correctamente.', 'Exito');
          this.loading = false;
        }, (error) => {
          this.toastr.error('Ocurrió un error.', 'Error');
          this.loading = false;
        }
      );
    }
  }

  onSubmit(form) {
    Swal.fire({
      title: '¿Deseas registrar el origen del contacto?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.catalogosService.registraOrigenContacto(this.origen).subscribe(
            (respuesta) => {
              this.store.dispatch(new usuariosActions.ActualizaOrigenContactoSuccess(respuesta.origen));
              resolve(respuesta.origen);
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaOrigenContactoFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          form.reset();
          this.origen = new Origen(null);
          this.origenInicial = new Origen(null);
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'origen del contacto registrado.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  limpiar(form) {
    this.origen = new Origen(null);
    this.origenInicial = new Origen(null);
    form.reset();
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

}
