import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { DivisionesService } from 'src/app/services/divisiones.service';


@Injectable()
export class DivisionEffects {

  constructor(
    private actions$: Actions,
    public divisionService: DivisionesService
  ) {}

  @Effect()
  cargarDivisiones$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_DIVISIONES ),
    switchMap( () => {
      return this.divisionService.getDivisiones()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarDivisionesSuccess(respuesta.division);
          }),
          catchError( error => of(new usuariosActions.CargarDivisionesFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusDivision$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_DIVISION_ESTATUS),
    switchMap( (action: any) => {
      return this.divisionService.updateEstado(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaDivisionEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaDivisionEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaDivision$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_DIVISION),
    switchMap( (action: any) => {
      return this.divisionService.registerDivision(action.division)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaDivisionSuccess(action.division);
            }),
            catchError( error => of(new usuariosActions.ActualizaDivisionFail(error))  )
        );
    })
  );

}
