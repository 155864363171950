import * as fromUI from '../actions/ui.actions';

export interface UiState {
    isLoading: boolean;
    stack: any[];
}

const initState: UiState = {
    isLoading: false,
    stack: []
};

export function uiReducer( state = initState, action: fromUI.loaderAcciones ): UiState {

    switch ( action.type ) {

        case fromUI.ACTIVAR_LOADING:
            state.stack.push('');
            return {
                ...state,
                isLoading: true
            };

        case fromUI.DESACTIVAR_LOADING:
            let loading = state.isLoading;
            // let stack = [];
            state.stack = state.stack.filter(item => { action.url !== item});
            if (!state.stack.length) {
                loading = false;
            }
            return {
                ...state,
                isLoading: loading
            };

        default:
            return state;
    }
}
