<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" *ngIf="identity.permisos">
  <div class="col-sm-10 col-md-5">
    <div class="main-card mb-3 card">
      <div class="card-header">
        Captura la información de la asignacion
      </div>
      <section class="card-body">
        <form #registerForm="ngForm" (ngSubmit)="onSubmit()">
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="numeroOrden" class="control-label">No. Orden</label>
              </div>
              <div class="col-sm-8">
                <input type="number" id="numeroOrden" name="numeroOrden" #numeroOrden="ngModel" [(ngModel)]="asignacion.numeroOrden"
                  class="form-control" minlength="4" maxlength="17" placeholder="" title="Capture el número de orden." (blur)="verficarExistencia()" [disabled]="!identity.permisos.asignacionRegEdit" required />
                <span *ngIf="!numeroOrden.valid && numeroOrden.touched"> El número de orden es obligatorio.</span>
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="clienteSelected" class="control-label">Nombre del cliente: </label>
              </div>
              <div class="col-sm-8">
                <ng-select [items]="clientes"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Nombre del cliente"
                  id="clienteSelected"  name="clienteSelected" #clienteSelected= "ngModel" [(ngModel)]="asignacion.cliente" (blur)="filtrarServicios(asignacion.cliente)" [disabled]="!identity.permisos.asignacionRegEdit"> 
                </ng-select>
              </div>
            </div>
           </fieldset>
           <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="servicioSelected" class="control-label">Servicio: </label>
              </div>
              <div class="col-sm-8">
                <ng-select [items]="serviciosSelecionados"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Servicios"
                  id="servicioSelected"  name="servicioSelected" #servicioSelected= "ngModel" [(ngModel)]="asignacion.servicio" (blur)="verficarExistencia()" [disabled]="!asignacion.cliente || !identity.permisos.asignacionRegEdit" (blur)="filtrarSucursal(asignacion.servicio)" required>
                </ng-select>
              </div>
            </div>
           </fieldset>
           <fieldset class="form-group">
            <div class="row">
              <div class="col-sm-4">
                <label for="localidadSelected" class="control-label">Localidad: </label>
              </div>
              <div class="col-sm-8">
                <input type="text" id="localidadSelected" name="localidadSelected" #localidadSelected="ngModel" [(ngModel)]="asignacion.localidad"
                  class="form-control" placeholder="Localidad" disabled />
                <!-- <ng-select [items]="asignaciones"
                  bindLabel="id"
                  bindValue="id"
                  placeholder="Localidades"
                  id="localidadSelected"  name="localidadSelected" #localidadSelected= "ngModel" [(ngModel)]="asignacion.localidad" disabled>
                </ng-select> -->
              </div>
            </div>
           </fieldset>
           <fieldset class="form-group">
            <div class="row">
              <div class="col-sm-4">
                <label for="sucursalSelected" class="control-label">Sucursal: </label>
              </div>
              <div class="col-sm-8">
                <input type="text" id="sucursalSelected" name="sucursalSelected" #sucursalSelected="ngModel" [(ngModel)]="asignacion.desSucursal"
                  class="form-control" placeholder="Sucursal" disabled />
                <!-- <ng-select [items]="asignaciones"
                  bindLabel="id"
                  bindValue="id"
                  placeholder="Sucursales"
                  id="sucursalSelected"  name="sucursalSelected" #sucursalSelected= "ngModel" [(ngModel)]="asignacion.sucursal" disabled>
                </ng-select> -->
              </div>
            </div>
           </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="fechaInicio" class="control-label">Fecha Inicio</label>
              </div>
              <div class="col-sm-8">
                <input type="text" placeholder="Fecha" class="form-control" name="fechaInicio" #fechaInicio="ngModel" bsDatepicker [(ngModel)]="asignacion.fecInicio" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}"  autocomplete="off" [disabled]="!identity.permisos.asignacionRegEdit">
                <span *ngIf="!fechaInicio.valid && fechaInicio.touched"> La fecha inicio es obligatorio.</span>
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required" *ngIf="!asignacion.opcFin">
            <div class="row">
              <div class="col-sm-4">
                <label for="fechaTermino" class="control-label">Fecha Termina</label>
              </div>
              <div class="col-sm-8">
                <input type="text" placeholder="Fecha" class="form-control" name="fechaTermino" #fechaTermino="ngModel" bsDatepicker [(ngModel)]="asignacion.fecTermino" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}"  autocomplete="off" [disabled]="!identity.permisos.asignacionRegEdit">
                <span *ngIf="!fechaTermino.valid && fechaTermino.touched"> La fecha termino es obligatorio.</span>
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group">
            <div class="row">
              <div class="col-sm-5">
                <label class="control-label" for="opcFin"></label>
              </div>
              <div class="custom-control custom-checkbox  col-sm-7">
                <input type="checkbox" class="custom-control-input" id="opcFin" name="opcFin" #opcFin="ngModel" (blur)="fechaTermino()"  [(ngModel)]="asignacion.opcFin" [disabled]="!identity.permisos.asignacionRegEdit">
                <label class="custom-control-label" for="opcFin">Sin fecha final</label>
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="tipoServicio" class="control-label">Tipo de servicio: </label>
              </div>
              <div class="col-sm-8">
                <ng-select [items]="tipoServicios"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Tipo servicios"
                  id="tipoServicio"  name="tipoServicio" #tipoServicio= "ngModel" [(ngModel)]="asignacion.tipoServicio" (blur)="verficarExistencia()" [disabled]="!identity.permisos.asignacionRegEdit" required>
                </ng-select>
              </div>
            </div>
           </fieldset>
           <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="tipoSolicitud" class="control-label">Tipo de solicitud: </label>
              </div>
              <div class="col-sm-8">
                <ng-select [items]="solicitudes"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="solicitudes"
                  id="tipoSolicitud"  name="tipoSolicitud" #tipoSolicitud= "ngModel" [(ngModel)]="asignacion.tipoSolicitud" (blur)="verficarExistencia()" [disabled]="!identity.permisos.asignacionRegEdit" required>
                </ng-select>
              </div>
            </div>
           </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="elementos" class="control-label">Elementos</label>
              </div>
              <div class="col-sm-8">
                <input type="number" id="elementos" name="elementos" #elementos="ngModel" [(ngModel)]="asignacion.elementos"
                  class="form-control" placeholder="" title="Capture el números de elementos." [disabled]="!identity.permisos.asignacionRegEdit" required />
                <span *ngIf="!elementos.valid && elementos.touched"> El número de elementos es obligatorio</span>
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="turnos" class="control-label">Turnos</label>
              </div>
              <div class="col-sm-8">
                <input type="number" id="turnos" name="turnos" #turnos="ngModel" [(ngModel)]="asignacion.turnos"
                  class="form-control" placeholder="" title="Capture el números de turnos." [disabled]="!identity.permisos.asignacionRegEdit"  required/>
                <span *ngIf="!turnos.valid && turnos.touched"> El número de turnos por días es obligatorio</span>
              </div>
            </div>
          </fieldset> 
        </form>
      </section>
      <div class="grid-menu grid-menu-2col border-top">
        <div class="no-gutters row">
          <div class="col-sm-6">
            <a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger" [routerLink]="['/auth/asignacion']">
              <i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
            </a>
          </div>
          <div class="col-sm-6">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" (click)="onSubmit()" *ngIf="!loading"
            [disabled]="(!registerForm.valid || !identity.permisos.asignacionRegEdit) || ((asignacion | json) === (asignacionInicial | json))" >
              <i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
            </button>
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="loading">
              <span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
              <br>
              Loading...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

